import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText, Divider } from '@mui/material';

const TermsOfService = () => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pt: { xs: 14, sm: 20 },
        pb: { xs: 8, sm: 12 }
      }}
    >
      <Box>
        <Typography variant="h4" gutterBottom>
          Terms of Service
        </Typography>

        <Typography variant="body1" gutterBottom>
          Welcome to Little Big Events! By using our website, you agree to these Terms of Service. Please read them carefully.
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Box>
          <Typography variant="h6" gutterBottom>
            1. Acceptance of Terms
          </Typography>
          <Typography variant="body2" gutterBottom>
            By accessing and using the Little Big Events platform, you agree to comply with and be bound by the following terms and conditions.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h6" gutterBottom>
            2. Service Overview
          </Typography>
          <Typography variant="body2" gutterBottom>
            Little Big Events is a directory and platform designed to connect event planners, parents, and organizers with vendors offering services for kids' parties and other events. We also provide additional services such as market stall listings and holiday camp bookings.
          </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box>
          <Typography variant="h6" gutterBottom>
            3. Vendor and User Responsibilities
          </Typography>
          <List>
            <ListItem>
              <ListItemText>
                a. Vendors must provide accurate, up-to-date information about their services and maintain communication with potential clients.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                b. Users must use the platform in good faith and contact vendors with legitimate event inquiries.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                c. Little Big Events is not liable for the accuracy of vendor information or for any disputes that arise between vendors and clients.
              </ListItemText>
            </ListItem>
          </List>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box>
          <Typography variant="h6" gutterBottom>
            4. Payments and Fees
          </Typography>
          <Typography variant="body2" gutterBottom>
            Little Big Events offers free and paid services. Vendors may opt for a subscription model, pay-per-lead services, or commission-based listings. All fees must be paid on time, and failure to do so may result in suspension or removal from the platform.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h6" gutterBottom>
            5. Intellectual Property
          </Typography>
          <Typography variant="body2" gutterBottom>
            All content on the Little Big Events platform, including text, graphics, logos, and images, is the property of Little Big Events or its vendors and is protected by copyright law. Users and vendors may not use any content without proper authorization.
          </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box>
          <Typography variant="h6" gutterBottom>
            6. Termination
          </Typography>
          <Typography variant="body2" gutterBottom>
            Little Big Events reserves the right to terminate or suspend your access to the platform at any time, without notice, if you breach these Terms of Service.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h6" gutterBottom>
            7. Disclaimer of Warranties
          </Typography>
          <Typography variant="body2" gutterBottom>
            Little Big Events provides its platform "as is" without any warranties of any kind. We do not guarantee that the platform will be error-free or uninterrupted.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h6" gutterBottom>
            8. Limitation of Liability
          </Typography>
          <Typography variant="body2" gutterBottom>
            Little Big Events will not be held liable for any damages arising from your use of the platform, including any direct, indirect, incidental, or consequential damages.
          </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box>
          <Typography variant="h6" gutterBottom>
            9. Changes to Terms
          </Typography>
          <Typography variant="body2" gutterBottom>
            We reserve the right to modify these Terms of Service at any time. We will notify users of significant changes, and continued use of the platform after any changes constitutes your acceptance of the new terms.
          </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box>
          <Typography variant="h6" gutterBottom>
            10. Contact Us
          </Typography>
          <Typography variant="body2" gutterBottom>
            If you have any questions or concerns regarding these Terms of Service, please contact us.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default TermsOfService;
