import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const HowItWorksDetails = ({ intro, howData }) => {
  return (
    <>
      {/* Intro Section */}
      <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
        <Typography variant="body1" sx={{ color: 'text.secondary', marginTop: 0 }}>
          {intro}
        </Typography>
      </Box>
      <Box container spacing={2} sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>

        {/* Cards Section */}
        {howData.map((gig, index) => (
          <Card
            key={index}
            variant="outlined"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              flexGrow: 1,
              padding: 2,
            }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ color: 'text.primary' }}>
                {gig.title}
              </Typography>
              <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                {gig.description}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </>
  );
};

export default HowItWorksDetails;
