import React, { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  Button,
  FormControl,
  InputAdornment,
  Box,
  Typography,
  Modal,
  Autocomplete,
  Chip,
  Checkbox,
  Link,
  useMediaQuery,
  IconButton // Import IconButton for the help icon
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SendIcon from "@mui/icons-material/Send"; // Import the Send icon
import HelpIcon from "@mui/icons-material/Help"; // Import the Help icon
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import "dayjs/locale/en-gb";
import { useNavigate, useLocation } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EventIcon from "@mui/icons-material/Event";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import dayjs from "dayjs"; // Import dayjs
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import BusinessIcon from "@mui/icons-material/Business";
import Paper from "@mui/material/Paper"; // Add this line

import VendorModal from "./VendorModal"; // Import your modal component
// Comprehensive vendor categories for kids-oriented and general events
const vendorCategories = [
  {
    category: "Entertainment & Performers",
    subcategories: [
      "Face Painting",
      "Magicians",
      //"Clowns",
      //"Fire Performers",
      "Balloon Artists",
      //"Drag Performers",
      //"Jugglers",
      //"Stilt Walkers",
      //"Circus Acts",
      //"Bingo Hosts",
      //"Video Game Parties",
      "Pony Rides",
      //"Puppet Shows",
      "Bubble Artists",
      //"Storytellers",
      "Character Actors (e.g., Superheroes, Princesses)",
      //"Mascot Costumes"
    ]
  },
  {
    category: "Venues & Locations",
    subcategories: [
      "Indoor Venues",
      "Outdoor Venues",
      //"Marquees",
      //"Inflatable Structures",
      //"Event Spaces",
      //"Party Rooms",
      //"Adventure Parks",
      //"Community Halls",
      //"Swimming Pool Venues",
      "Soft Play Centers"
    ]
  },
  {
    category: "Catering & Food Services",
    subcategories: [
      "Caterers",
      "Food Trucks",
      //"Mobile Bars",
      "Bartending Services",
      //"Wedding Cakes",
      //"BBQ Catering",
      //"Pizza Vans",
      //"Paella Catering",
      //"Vegan, Kosher & Halal Catering",
      //"Dessert Stations",
      //"Chocolate Fountains",
      "Coffee Vans",
      "Ice Cream Vans",
      "Candy Floss Stalls",
      "Popcorn Machines",
      "Slush Machines",
      //"Cupcake Decorating Stations",
      //"Healthy Kids Snacks",
      "Party Cake",
    ]
  },
  {
    category: "Decor & Styling",
    subcategories: [
      "Decor Services",
      "Floral Arrangements",
      "Balloon Decorations",
      //"Lighting & AV Setup",
      //"Backdrops",
      "Banners & Signs",
      "Flower Walls",
      //"Props & Custom Items",
      //"Confetti",
      "Themed Decorations (e.g., Princess, Superhero, Cartoon Characters)",
      //"Inflatable Obstacle Courses",
      "Party Favors & Goody Bags",
    ]
  },
  {
    category: "Photography & Videography",
    subcategories: [
      "Professional Photographers",
      //"Photo Booths",
      //"Drone Photography",
      //"Event Videographers",
      //"360 Photo Booths",
      //"Themed Photo Sessions",
      //"Instant Polaroid Stations"
    ]
  },
  {
    category: "Music & DJs",
    subcategories: [
      //"DJ Services",
      "Live Bands",
      "Solo Musicians",
      //"Acoustic Acts",
      "Jazz Bands",
      //"Bagpipers",
      //"Classical Guitarists",
      //"Karaoke Hire",
      //"Kids DJs (with Interactive Games)",
      //"Musical Theater Performers"
    ]
  },
  {
    category: "Equipment & Rentals",
    subcategories: [
      //"Audio-Visual Equipment",
      //"Sound Systems",
      //"Lighting Setup",
      //"Stage Rentals",
      //"Furniture Rentals",
      //"Hot Tub Hire",
      //"Silent Disco Equipment",
      //"Projectors & Screens",
      "Bouncy Castles",
      "Inflatable Slides",
      "Soft Play Equipment",
      //"Arcade Game Machines",
      "Carnival Games & Stalls",
      "Mechanical Rides (e.g., Bucking Bronco)",
      "Sports Equipment Rental (e.g., Soccer Goals, Basketball Hoops)"
    ]
  },
  {
    category: "Event Planning & Management",
    subcategories: [
      //"Event Coordinators",
      //"Wedding Planners",
      //"Party Planners",
      //"Celebrant Services",
      "Children's Party Planners",
      "Themed Party Organizers (e.g., Pirate, Princess, Space)",
      //"Corporate Event Planners",
      //"Festival Management",
      //"Charity Event Coordinators"
    ]
  },
  {
    category: "Unique Experiences",
    subcategories: [
      //"Mobile Escape Rooms",
      //"Arcade Game Rentals",
      "Laser Tag",
      //"Sumo Suits",
      //"Cocktail Making Classes",
      //"Wine Tasting",
      //"Prosecco Vans",
      "Bungee Trampolines",
      //"Bubble Tea",
      "Petting Zoo",
      "Science Shows",
      //"Outdoor Movie Screenings",
      //"Mobile Adventure Parks",
      //"Glow-in-the-Dark Parties",
      //"Spa Parties for Kids",
      "Mini-Golf Courses",
      "Craft Workshops",
      "Magic Shows",
      //"Mini-Train Rides"
    ]
  },
  {
    category: "Sports & Outdoor Activities",
    subcategories: [
      "Football Coaches",
      "Table Tennis Coaches",
      //"Other Sports Coaches (e.g., Basketball, Tennis)",
      //"Inflatable Sports Arenas",
      //"Archery Tag",
      //"Rock Climbing Walls",
      //"Zip Lines",
      //"Obstacle Courses",
      //"Water Slides",
      //"Pedal Go-Karts",
      //"Bubble Football",
      //"Camping and Glamping Venues",
      //"Horseback Riding"
    ]
  }/*,
  {
    category: "Health & Safety",
    subcategories: [
      "First Aid Services",
      "Security Staff",
      "Lifeguards for Pool Parties",
      "Event Insurance",
      "Fire Safety Services",
      "Health and Safety Consultants",
      "Crowd Control Barriers"
    ]
  }*/,
  {
    category: "Workshops & Educational Entertainment",
    subcategories: [
      "STEM Workshops",
      "Art & Craft Workshops",
      "Cooking Classes",
      "Dance Workshops",
      //"Drama and Theater Classes",
      "Music Workshops",
      //"Science Shows",
      "Magic Lessons",
      "Yoga for Kids",
      //"Mindfulness Workshops",
      "Animal Handling Workshops"
    ]
  }
];


// Flatten categories into a single array for Autocomplete
const vendors_options = vendorCategories.flatMap(category =>
  category.subcategories.map(subcategory => ({
    title: subcategory,
    category: category.category
  }))
);

const postcodeRegex = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i;

const SearchComponent = () => {
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [isDateValid, setIsDateValid] = useState(true); // Flag to track date validity
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [duration, setDuration] = useState("");
  const [postcode, setPostcode] = useState("");
  const [isPostcodeValid, setIsPostcodeValid] = useState(true);
  const [error, setError] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openHelpModal, setOpenHelpModal] = useState(false); // State for help modal
  const [autocompleteOpen, setAutocompleteOpen] = useState(false); // Control modal visibility

  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm")); // Check if it's mobile view

  // Function to parse query parameters from URL
  const parseQueryParams = () => {
    const params = new URLSearchParams(location.search);
    const dateTime = params.get("date_time");
    const vendors = params.getAll("vendors");
    const duration = params.get("duration");
    const postcode = params.get("postcode");

    if (dateTime) setSelectedDateTime(new Date(dateTime));
    if (vendors.length) setSelectedVendors(vendors);
    if (duration) {
      setDuration(duration);
    }
    if (postcode) {
      setPostcode(postcode);
      setIsPostcodeValid(postcodeRegex.test(postcode));
    }
  };

  useEffect(
    () => {
      parseQueryParams(); // Set initial state based on URL parameters
    },
    [location.search]
  );

  // Handle date change and validity check
  const handleDateTimeChange = newDate => {
    const currentDatePlus7Days = dayjs().add(7, "day");

    if (newDate && dayjs(newDate).isBefore(currentDatePlus7Days)) {
      setIsDateValid(false); // Mark date as invalid
      setError("Please select a date at least 7 days from today.");
    } else {
      setIsDateValid(true); // Mark date as valid
      setError(""); // Clear any date-related errors
    }
    setSelectedDateTime(newDate); // Always set the date, even if it's invalid
  };

  const handleDurationChange = event => {
    setDuration(event.target.value);
    setError("");
  };

  // Handle vendor selection change
  const handleVendorChange = (event, newValue) => {
    setSelectedVendors(newValue);
  };

  const handlePostcodeChange = event => {
    const value = event.target.value.trim();
    setPostcode(value.toUpperCase());
    setIsPostcodeValid(postcodeRegex.test(value));
    if (postcodeRegex.test(value)) {
      setError("");
    }
  };

  const handleSearch = () => {
    const currentDatePlus7Days = dayjs().add(7, "day");

    // Check if a date is selected
    if (!selectedDateTime) {
      setError("Please select a date and time.");
      return;
    }

    // Check if the date is at least 7 days ahead
    if (
      !isDateValid ||
      dayjs(selectedDateTime).isBefore(currentDatePlus7Days)
    ) {
      setError("Please select a date at least 7 days from today.");
      return;
    }

    if (!duration) {
      setError("Please select a duration.");
      return;
    }

    if (selectedVendors.length === 0) {
      setError("Please select at least one vendor.");
      return;
    }

    if (!postcode) {
      setError("Please enter a postcode.");
      return;
    }

    if (!isPostcodeValid) {
      setError("Please enter a valid UK postcode.");
      return;
    }

    if (!postcode.toUpperCase().startsWith("SW")) {
      setOpenModal(true);
      return;
    }

    // If all checks pass, clear errors and proceed with search
    setError("");
    const formattedDateTime = dayjs(selectedDateTime).format(
      "YYYY-MM-DDTHH:mm:ss"
    );

    // Create URL with search parameters
    const params = new URLSearchParams({
      date_time: formattedDateTime,
      duration: duration.replace(" hours", "h").replace(" days", "d"),
      vendors: selectedVendors.map(a => JSON.stringify(a)),
      postcode
    });

    // Redirect with parameters
    navigate(`/results?${params.toString()}`);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Open vendor selection modal
  const handleVendorInputClick = () => {
    setAutocompleteOpen(true);
  };

  // Validate and close vendor selection
  const handleVendorSelectionDone = () => {
    setAutocompleteOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 3,
        px: { xs: 2, sm: 4 }, // Add padding on the sides
        mx: "auto", // Center the Box horizontally
        maxWidth: { xs: "100%", sm: 600 }, // Optional: limit the width
      }}
    >
      <Box sx={{ mb: 1, mt: 1, width: "100%", maxWidth: 350  }}> {/* New Box for top text */}
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            color: "text.primary",
            textAlign: "justify" // Justify text
          }}
        >
          Connect with the Perfect Vendors to Make Your Event Unforgettable!
          </Typography>
      </Box>

      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <FormControl sx={{ width: "100%", maxWidth: 350 }}>
          <DateTimePicker
            label="Event Date and Time"
            value={selectedDateTime}
            onChange={handleDateTimeChange}
            renderInput={params =>
              <TextField
                {...params}
                fullWidth
                InputLabelProps={{ shrink: true }}
                sx={{ marginBottom: 2 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EventIcon />
                    </InputAdornment>
                  )
                }}
              />}
          />
        </FormControl>

        <FormControl sx={{ width: "100%", maxWidth: 350 }}>
          <TextField
            labelId="duration-label"
            value={duration}
            select
            onChange={handleDurationChange}
            label="Event Duration"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccessTimeIcon />
                </InputAdornment>
              )
            }}
          >
            <MenuItem value="1 hour">1 hour</MenuItem>
            <MenuItem value="2 hours">2 hours</MenuItem>
            <MenuItem value="3 hours">3 hours</MenuItem>
            <MenuItem value="4 hours">4 hours</MenuItem>
            <MenuItem value="5 hours">5 hours</MenuItem>
            <MenuItem value="6 hours">6 hours</MenuItem>
            <MenuItem value="7 hours">7 hours</MenuItem>
            <MenuItem value="1 day">1 day</MenuItem>
            <MenuItem value="2 days">2 days</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </TextField>
        </FormControl>

        {/* Vendor Input */}
        <FormControl sx={{ width: "100%", maxWidth: 350 }}>
          <TextField
            label="Event Vendors"
            placeholder="Search vendors"
            onClick={handleVendorInputClick} // Open modal on click
            value={selectedVendors.map(vendor => vendor.title).join(", ")} // Show selected vendors
            readOnly // Make the text field read-only
          />
        </FormControl>

        <FormControl sx={{ width: "100%", maxWidth: 350 }}>
          <TextField
            label="Postcode"
            value={postcode}
            onChange={handlePostcodeChange}
            error={!isPostcodeValid}
            helperText={
              !isPostcodeValid ? "Please enter a valid UK postcode" : ""
            }
            autoComplete="postal-code"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BusinessIcon />
                </InputAdornment>
              )
            }}
          />
        </FormControl>

        {error &&
          <Typography color="error">
            {error}
          </Typography>}

        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          startIcon={<SendIcon />} // Add the Send icon to the button
          sx={{ width: "100%", maxWidth: 350 }}
        >
          Search
        </Button>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ textAlign: "center" }}
        >
          By Submitting your Request, you agree to our&nbsp;
          <Link href="/terms" color="primary">
            Terms & Conditions
          </Link>
          .
        </Typography>

        {/* Render the Vendor Modal */}
        <VendorModal
          autocompleteOpen={autocompleteOpen}
          setAutocompleteOpen={setAutocompleteOpen}
          vendors_options={vendors_options}
          selectedVendors={selectedVendors}
          handleVendorChange={handleVendorChange}
        />

        {/* Optional: Add a Modal for postcode warning */}
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              width: "100vw",
              backgroundColor: "white",
              padding: 2
            }}
          >
            <Typography variant="h6" gutterBottom>
              Invalid Postcode
            </Typography>
            <Typography variant="body1">
              Please enter a valid postcode that starts with "SW".
            </Typography>
            <Button
              onClick={handleCloseModal}
              variant="contained"
              color="primary"
              startIcon={<CloseIcon />}
            >
              Close
            </Button>
          </Box>
        </Modal>

      </LocalizationProvider>
    </Box>
  );
};

export default SearchComponent;
