// Layout.js
import { Outlet } from "react-router-dom";
import * as React from "react";
import { ThemeProvider as MUIThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AppAppBar from "./components/AppAppBar";
import Divider from "@mui/material/Divider";
import Footer from "./components/Footer";
import getMPTheme from "./theme/getMPTheme";
import Box from "@mui/material/Box";

export default function Layout() {
  const [mode, setMode] = React.useState("light");

  const toggleColorMode = () => {
    const newMode = mode === "dark" ? "light" : "dark";
    setMode(newMode);
    localStorage.setItem("themeMode", newMode); // Save the selected mode to localStorage
  };
  
  const MPTheme = createTheme(getMPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  return (
    <div>
      <MUIThemeProvider theme={defaultTheme}>
        <CssBaseline enableColorScheme />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",  // Ensure the container takes full height
          }}
        >
          <AppAppBar toggleColorMode={toggleColorMode} mode={mode} />
          <Box
            id="hero"
            component="main"
            sx={{
              flexGrow: 1, // This ensures that the main content takes the remaining space
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundImage:
                "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)",
              ...defaultTheme.applyStyles("dark", {
                backgroundImage:
                  "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)",
              }),
            }}
          >
            <Outlet /> {/* No need to pass mode here */}
          </Box>
          <Divider />
          <Footer /> {/* The footer stays at the bottom, regardless of content */}
        </Box>
      </MUIThemeProvider>
    </div>
  );
}
