import React, { useState } from 'react';
import { TextField } from '@mui/material';

const EmailInputComponent = ({ onEmailChange }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    // Validate the email format
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = regex.test(newEmail);

    setError(!isValid);

    // Pass the current email and validity status to the parent component
    onEmailChange(newEmail, isValid);
  };

  return (
    <TextField
      label="Enter your email" // Correct label prop for floating label behavior
      variant="outlined" // Ensure variant is set to "outlined"
      type="email" // Email type input
      value={email}
      onChange={handleChange}
      error={error}
      helperText={error ? 'Please enter a valid email address' : ''}
      fullWidth
      autoComplete="email"
      InputLabelProps={{
        shrink: true, // Ensures label stays in correct position even when the field has a value
      }}
    />
  );
};

export default EmailInputComponent;
