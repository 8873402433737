import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  Box,
  Typography,
  Grid2,
  Modal,
  Backdrop,
  Fade,
  Link,
  TextField,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import EmailInputComponent from "./EmailInputComponent";
import axios from "axios";
import SendIcon from "@mui/icons-material/Send";
import DateRangeIcon from "@mui/icons-material/DateRange"; // Icon for Date
import BusinessIcon from "@mui/icons-material/Business"; // Icon for Vendors
import AccessTimeIcon from "@mui/icons-material/AccessTime"; // Icon for Duration
import HomeIcon from "@mui/icons-material/Home"; // Icon for Postcode
import DescriptionIcon from "@mui/icons-material/Description"; // Icon for additional details
import CelebrationIcon from "@mui/icons-material/Celebration"; // Sparkle Icon
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions"; // Smiley Face
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents"; // Trophy for fun

const SearchResults = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const date_time = searchParams.get("date_time");
  const vendors_url = searchParams.get("vendors");
  const vendors = vendors_url.replaceAll("},{", "},|||{").split(",|||").map(a => JSON.parse(a));
  const duration = searchParams.get("duration");
  const postcode = searchParams.get("postcode");

  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState("");
  const [emailError, setEmailError] = useState(""); // State for email error message

  const isValidDateTime = (dateTime) => !isNaN(Date.parse(dateTime));
  const isValidDuration = (duration) => /^\d+(h|d)$/.test(duration);
  const isValidPostcode = (postcode) => /^[A-Z]{1,2}\d[A-Z]?\s?\d[A-Z]{2}$/i.test(postcode);

  useEffect(() => {
    if (
      !isValidDateTime(date_time) ||
      !vendors.length ||
      !isValidDuration(duration) ||
      !isValidPostcode(postcode)
    ) {
      navigate("/");
    } else {
      const timer = setTimeout(() => setLoading(false), 4000);
      return () => clearTimeout(timer);
    }
  }, [date_time, vendors, duration, postcode, navigate]);

  const handleEmailChange = (newEmail, isValid) => {
    setEmail(newEmail);
    setIsEmailValid(isValid);
    setEmailError(""); // Clear error when the user types
  };

  const handleRequest = async () => {
    // Check if email is empty
    if (!email) {
      setEmailError("Email cannot be empty."); // Set error message
      return; // Prevent form submission
    }

    if (
      !isValidDateTime(date_time) ||
      !vendors.length ||
      !isValidDuration(duration) ||
      !isValidPostcode(postcode) ||
      !isEmailValid
    ) {
      navigate("/");
      return;
    }

    const requestData = {
      date_time,
      duration,
      vendors: JSON.stringify(vendors, "<br/>", 4),
      postcode,
      email,
      details,
    };

    try {
      setIsSubmitting(true);
      await axios.post(
        process.env.REACT_APP_BACKEND_ENDPOINT + "/api/requests/send",
        requestData,
        { headers: { "Content-Type": "application/json" } }
      );
      setModalOpen(true);
    } catch (error) {
      console.error("Error submitting request:", error);
      alert("An error occurred while submitting the request. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleModalClose = () => setModalOpen(false);

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pt: { xs: 10, sm: 12 },
        pb: { xs: 8, sm: 12 },
      }}
    >
      {/* Initial Modal with Playful Theme */}
      <Modal
  open={loading}
  closeAfterTransition
  BackdropComponent={Backdrop}
  BackdropProps={{ timeout: 500 }}
>
  <Fade in={loading}>
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        bgcolor: "background.paper",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "center",
        p: 4,
        borderRadius: 2,
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: "primary.main",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: { xs: "1.5rem", sm: "1.8rem" }, // Adjust font size for smaller screens
        }}
      >
        🎈 Looking for the Best Vendors for Your Event! 🎉
      </Typography>

      {/* Responsive Box for Icons and Text */}
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: { xs: "column", sm: "row" }, // Stack icons and text vertically on mobile, horizontally on larger screens
          justifyContent: "center",
          alignItems: "center",
          gap: 2, // Spacing between icons and text
          textAlign: { xs: "center", sm: "left" }, // Center text on small screens, left align on larger screens
        }}
      >
        {/* Icons (Celebration and Trophy) */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
            flexDirection: { xs: "row", sm: "row" }, // Icons stay horizontal in all screen sizes
          }}
        >
          <CelebrationIcon sx={{ color: "#ffca28", fontSize: "2rem" }} />
          <EmojiEventsIcon sx={{ color: "#4caf50", fontSize: "2rem" }} />
        </Box>

        {/* Text */}
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "1rem", sm: "1.2rem" }, // Adjust font size for readability on smaller screens
            mt: { xs: 2, sm: 0 }, // Add top margin on small screens
          }}
        >
          Hold tight while we find the perfect match for your party 🎈🎊
        </Typography>
      </Box>
    </Box>
  </Fade>
</Modal>


      <Box
        sx={{
          display: loading ? "none" : "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 8, sm: 6 },
          pb: { xs: 8, sm: 10 },
          gap: 3,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            fontSize: "clamp(2.5rem, 8vw, 3.5rem)",
          }}
        >
          Your&nbsp;
          <Typography component="span" variant="h1" sx={{ fontSize: "inherit", color: "primary.main" }}>
            Party
          </Typography>
        </Typography>

        <Typography
          sx={{
            textAlign: "justify",
            color: "text.secondary",
            width: { sm: "100%", md: "80%" },
            mb: 2,
          }}
        >
          Great news! We are here to assist you in finding the best providers for your party. <br />
          Kindly review and confirm your event details. <br />
          If everything is correct, please enter your email and submit your request to connect with vendors. <br />
          Should you need to make any changes, please return to{" "}
          <Button color="info" variant="text" size="small" href="/">
            edit your information.
          </Button>
        </Typography>

        <Box
          sx={{
            width: { xs: "100%", sm: "90%", md: "70%" },
            maxWidth: "800px",
            border: "1px solid #ccc",
            borderRadius: 2,
            p: 3,
            bgcolor: "background.paper",
          }}
        >
          <Grid2 container spacing={4}>
            {/* Date and Time */}
            <Grid2 item xs={12} sm={4}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <DateRangeIcon sx={{ mr: 1 }} />
                <Typography variant="h6" gutterBottom>
                  Date & Time Selected:
                </Typography>
              </Box>
              <Typography variant="body1">
                {date_time ? new Date(date_time).toLocaleString() : "None"}
              </Typography>
            </Grid2>

            {/* Vendors */}
            <Grid2 item xs={12} sm={4}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <BusinessIcon sx={{ mr: 1 }} />
                <Typography variant="h6" gutterBottom>
                  Vendors Selected:
                </Typography>
              </Box>
              <Typography variant="body1">
                {vendors.length > 0 ? (
                  <ul style={{ paddingLeft: "1.5rem", marginTop: 0 }}>
                    {vendors.map((vendor, index) => (
                      <li key={index}>
                        <strong>{vendor.title}</strong> ({vendor.category})
                      </li>
                    ))}
                  </ul>
                ) : (
                  "None"
                )}
              </Typography>
            </Grid2>

            {/* Duration */}
            <Grid2 item xs={12} sm={4}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <AccessTimeIcon sx={{ mr: 1 }} />
                <Typography variant="h6" gutterBottom>
                  Duration:
                </Typography>
              </Box>
              <Typography variant="body1">{duration ? duration : "None"}</Typography>
            </Grid2>

            {/* Location Postcode */}
            <Grid2 item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <HomeIcon sx={{ mr: 1 }} />
                <Typography variant="h6" gutterBottom>
                  Location Postcode:
                </Typography>
              </Box>
              <Typography variant="body1">{postcode ? postcode : "None"}</Typography>
            </Grid2>
          </Grid2>
        </Box>

        {/* Additional Details Input */}
        <FormControl
          variant="outlined"
          sx={{
            mt: 3,
            width: { xs: "100%", sm: "90%", md: "70%" },
            maxWidth: "800px",
          }}
        >
          <TextField
            label="Additional Details About Your Event"
            variant="outlined"
            multiline
            placeholder="Let us know any details that will help us assist you better."
            rows={6} // Increase the number of rows for a larger box
            value={details}
            onChange={(e) => setDetails(e.target.value)}
            InputProps={{
              startAdornment: <DescriptionIcon sx={{ mr: 1 }} />,
            }}
          />
          <Typography variant="body2" sx={{ color: "text.secondary", mb: 1 }}>
            Please provide additional details about your event to help us find the best vendors.
          </Typography>
        </FormControl>

        {/* Email Input */}
        <FormControl
          variant="outlined"
          sx={{
            mt: 3,
            width: { xs: "100%", sm: "90%", md: "70%" },
            maxWidth: "800px",
          }}
        >
          <EmailInputComponent onEmailChange={handleEmailChange} />
          <Typography variant="body2" sx={{ color: "text.secondary", mb: 1 }}>
            Please provide your email so we can reach you with the vendors we found for your event.
          </Typography>
          {/* Error message for empty email */}
          {emailError && (
            <Typography variant="body2" sx={{ color: "red", mt: 1 }}>
              {emailError}
            </Typography>
          )}
        </FormControl>

        {/* Submit Button */}
        <Button
          onClick={handleRequest}
          variant="contained"
          color="primary"
          size="large"
          sx={{ width: "100%", maxWidth: 300, mt: 3 }}
          disabled={isSubmitting}
          startIcon={<SendIcon />}
        >
          {isSubmitting ? "Submitting..." : "Submit Your Request"}
        </Button>

        <Typography variant="caption" sx={{ textAlign: "center", mt: 2 }}>
          By submitting your request, you agree to our{" "}
          <Link href="/terms" color="primary">
            Terms & Conditions
          </Link>
          .
        </Typography>
      </Box>

      {/* Submission Confirmation Modal */}
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={modalOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "90%", sm: 400 },  // Full width for small screens
              maxWidth: 500,  // Max width for larger screens
              bgcolor: "background.paper",
              borderRadius: 2,
              boxShadow: 24,
              p: { xs: 3, sm: 4 },  // Adjust padding for mobile vs larger screens
              textAlign: "center",
            }}
          >
            <Typography variant="h6" sx={{ color: "primary.main",  }}>
              🎉 Request Submitted! 🎉
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Your request has been successfully submitted. We will get back to
              you shortly with the best vendors!
            </Typography>
            <Button
              onClick={handleModalClose}
              variant="contained"
              sx={{ mt: 3, backgroundColor: "primary.main" }}
            >
              Close
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};

export default SearchResults;
