import * as React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import AppAppBar from "./AppAppBar";
import Hero from "./Hero";
import LogoCollection from "./LogoCollection";
import Highlights from "./Highlights";
import Pricing from "./Pricing";
import Features from "./Features";
import PerfectEvent from "./PerfectEvent";
import FAQ from "./FAQ";
import getMPTheme from "../theme/getMPTheme";

export default function Home() {
  return (
    <div>
        <Hero/>
        <div>
          {/*<LogoCollection />
          <Divider />*/}
          <PerfectEvent />
          <Divider />
          {/*<Features />
          <Divider />*/}
          <Highlights />          
          <Divider />
          {/*<Pricing />
          <Divider />
          <FAQ id="faq"/>*/}
        </div>
    </div>
  );
}
