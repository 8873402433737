import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import SearchComponent from "./SearchComponent";
import TailorSearchComponent from "./TailorSearchComponent";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { visuallyHidden } from "@mui/utils";
import { gray, brand } from "../theme/themePrimitives";
import { useTheme } from "@mui/system";

const vendorsList = [
  "Face Painter",
  "Magician",
  "Photographer",
  "Balloon Artist",
  "Caterer",
  "Coffee Van",
  "Decor Services",
  "Bouncy Castles",
  "Jazz Band",
  "Venue",
  "Laser Tag",
];

// Custom hook for vendor updates
function useVendorUpdater() {
  const [currentVendorMarketing, setCurrentVendorMarketing] = useState(
    vendorsList[0]
  );
  const vendorIndexRef = useRef(0);

  useEffect(() => {
    const interval = setInterval(() => {
      vendorIndexRef.current =
        (vendorIndexRef.current + 1) % vendorsList.length;
      setCurrentVendorMarketing(vendorsList[vendorIndexRef.current]);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return currentVendorMarketing;
}

// Memoized components to prevent unnecessary re-renders
const MemoizedSearchComponent = React.memo(SearchComponent);
const MemoizedTailorSearchComponent = React.memo(TailorSearchComponent);

export default function Hero() {
  const currentVendorMarketing = useVendorUpdater();
  const [tabIndex, setTabIndex] = useState(0);
  const theme = useTheme();
  const mode = theme.palette.mode;

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(images/background_small.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        pt: { xs: 16, sm: 14 },
        pb: { xs: 8, sm: 10 },
        color: "white"
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor:
            mode === "light"
              ? "rgba(255, 255, 255, 0.8)"
              : "rgba(0, 0, 0, 0.8)", // Conditional background
          borderRadius: 2,
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          p: { xs: 1, sm: 2 },
          maxWidth: { xs: "90%", sm: "400px", md: "500px" },
          margin: "0 auto"
        }}
      >
        <Stack spacing={2} sx={{ alignItems: "center", width: "100%" }}>
          <Typography
            variant="h1"
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              fontSize: "clamp(2rem, 7vw, 2.5rem)",
              color: "text.secondary",
              ...theme.applyStyles("dark", {
                color: "primary.dark"
              }),
            }}
          >
            Little&nbsp;Big&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={theme => ({
                fontSize: "inherit",
                color: "primary.main",
                ...theme.applyStyles("dark", {
                  color: "primary.light"
                })
              })}
            >
              Events
            </Typography>
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              color: "text.primary",
              width: { sm: "100%", md: "80%" }
            }}
          >
            Connecting SW London with The&nbsp;
            <b>
              <u>
                <span
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: "center",
                    fontSize: "clamp(3rem, 10vw, 3.5rem)",
                    color: "text.secondary",
                    ...theme.applyStyles("dark", {
                      color: "primary.dark"
                    })
                  }}
                >
                  BEST
                </span>
              </u>
            </b>
            <br />
            <span
              style={{
                fontWeight: "bold",
                fontSize: "2rem",
                color: brand[400],
                backgroundColor: gray[20],
                padding: "0.2rem 0.5rem",
                borderRadius: "4px"
              }}
            >
              &nbsp;{currentVendorMarketing}
            </span>
            <br />
            &nbsp;For Epic Kids' events!
          </Typography>

          {/* Simple and discreet divider */}
          <Divider sx={{ width: "50%", my: 2 }} />

          {/* Tabs for Search, Tailored Service, and Help */}
          <Tabs value={tabIndex} onChange={handleTabChange} centered>
            <Tab label="Vendors" />
            <Tab label="Tailored" />
            {/*<Tab label="Help" />*/}
          </Tabs>

          {/* Render the component based on selected tab */}
          <Box sx={{ width: "100%", p: 2 }}>
            {tabIndex === 0 &&
              <MemoizedSearchComponent
                htmlFor="email-hero"
                sx={visuallyHidden}
              />}

            {tabIndex === 1 && <MemoizedTailorSearchComponent />}

            {tabIndex === 2 &&
              <Box
                sx={{
                  p: 4,
                  textAlign: "center",
                  borderRadius: 2,
                  backgroundColor: "background.paper",
                  boxShadow: 3
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ color: "text.primary", fontWeight: "bold" }}
                >
                  Need Help?
                </Typography>

                <Typography
                  variant="body1"
                  paragraph
                  sx={{ color: "text.secondary", mb: 2 }}
                >
                  Use the <b>Search Vendors</b> tab to find specific service
                  providers, or try the <b>Tailor Service</b> option to
                  customize a vendor search based on your event needs.
                </Typography>

                <Typography variant="body1" sx={{ color: "text.secondary" }}>
                  For any other questions, feel free to contact us via our
                  support team.
                </Typography>
              </Box>}
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
