import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Modal, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import MessageIcon from '@mui/icons-material/Message';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios'; // Import axios

const ContactForm = () => {
  // Form state
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    message: false,
  });

  const [serverResponse, setServerResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Validate email format
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {
      name: !formData.name.trim(),
      email: !isValidEmail(formData.email),
      message: !formData.message.trim(),
    };

    setFormErrors(errors);

    if (!errors.name && !errors.email && !errors.message) {
      setLoading(true);
      try {
        // Use axios.post to send form data
        const response = await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + '/api/requests/contact', formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          setServerResponse('Message sent successfully!');
          setOpenModal(true); // Open modal on success
        } else {
          setServerResponse(`Error: ${response.data.message || 'Failed to send message'}`);
        }
      } catch (error) {
        setServerResponse(`Error: ${error.response?.data?.message || 'An error occurred'}`);
      } finally {
        setLoading(false);
        setFormData({ name: '', email: '', message: '' });
      }
    }
  };


  // Modal close handler
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          width: '100%',
          maxWidth: 400,
          margin: 'auto',
          padding: 2,
          pt: { xs: 14, sm: 14 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Contact Us
        </Typography>

        {/* Name input */}
        <TextField
          label="Name"
          name="name"
          variant="outlined"
          value={formData.name}
          onChange={handleInputChange}
          error={formErrors.name}
          helperText={formErrors.name && 'Name is required'}
          fullWidth
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
          }}
        />

        {/* Email input */}
        <TextField
          label="Email"
          name="email"
          type="email"
          variant="outlined"
          value={formData.email}
          onChange={handleInputChange}
          error={formErrors.email}
          helperText={formErrors.email && 'Enter a valid email address'}
          fullWidth
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />

        {/* Message input */}
        <TextField
          label="Message"
          name="message"
          variant="outlined"
          multiline
          rows={4}
          value={formData.message}
          onChange={handleInputChange}
          error={formErrors.message}
          helperText={formErrors.message && 'Message is required'}
          fullWidth
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MessageIcon />
              </InputAdornment>
            ),
          }}
        />

        {/* Submit button */}
        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          loading={loading}
          endIcon={<SendIcon />}
        >
          {loading ? 'Sending...' : 'Send Message'}
        </LoadingButton>

        {/* Server Response */}
        {serverResponse && (
          <Typography
            variant="body1"
            align="center"
            color={serverResponse.startsWith('Error') ? 'error' : 'primary'}
          >
            {serverResponse}
          </Typography>
        )}
      </Box>

      {/* Modal to show thank you message */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="thank-you-title"
        aria-describedby="thank-you-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="thank-you-title" variant="h6" component="h2">
            Thank you!
          </Typography>
          <Typography id="thank-you-description" sx={{ mt: 2 }}>
            Thanks for contacting us. We will get back to you soon.
          </Typography>
          <Button
            onClick={handleCloseModal}
            variant="contained"
            color="primary"
            sx={{ mt: 2, display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default ContactForm;
