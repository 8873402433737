import React, { useEffect, useRef, useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  Paper,
  Autocomplete,
  TextField,
  Chip,
  Checkbox,
  Snackbar,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles'; // Import useTheme hook
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseIcon from '@mui/icons-material/Close';

const VendorModal = ({
  autocompleteOpen,
  setAutocompleteOpen,
  vendors_options,
  selectedVendors,
  handleVendorChange,
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const inputRef = useRef(null);
  const paperRef = useRef(null);
  const [scrollTop, setScrollTop] = useState(0);

  const theme = useTheme(); // Get the theme
  const isDarkMode = theme.palette.mode === 'dark'; // Check if dark mode is enabled
  const isDesktop = useMediaQuery('(min-width:600px)');

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleSaveAndClose = () => {
    setSnackbarOpen(true);
    setAutocompleteOpen(false);
  };

  useEffect(() => {
    if (autocompleteOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autocompleteOpen]);

  useEffect(() => {
    const paperElement = paperRef.current;
    if (paperElement) {
      paperElement.scrollTop = scrollTop;

      const handleScroll = () => {
        setScrollTop(paperElement.scrollTop);
      };

      paperElement.addEventListener('scroll', handleScroll);

      return () => {
        paperElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [autocompleteOpen, scrollTop]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const inputValue = event.target.value.trim();
      const filteredOptions = vendors_options.filter((option) =>
        option.title.toLowerCase().includes(inputValue.toLowerCase())
      );

      if (filteredOptions.length > 0) {
        handleVendorChange(null, [...selectedVendors, filteredOptions[0]]);
      } else if (
        inputValue &&
        !vendors_options.some(
          (option) => option.title.toLowerCase() === inputValue.toLowerCase()
        )
      ) {
        handleVendorChange(null, [
          ...selectedVendors,
          { title: inputValue, category: 'Custom' },
        ]);
      }
    }
  };

  return (
    <>
      <Modal open={autocompleteOpen} onClose={() => setAutocompleteOpen(false)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: '100vh',
            width: '100vw',
            backgroundColor: isDarkMode ? 'grey.900' : 'white', // Set background for dark mode
            padding: 2,
            overflow: 'hidden',
          }}
        >
          {/* Close Button */}
          <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
            <IconButton
              onClick={() => setAutocompleteOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="h5" gutterBottom>
            Select Vendors
          </Typography>
          <Typography
            variant="caption"
            color="text.secondary"
          >
            You can type to search for or add any unlisted option, then press 'Enter' to confirm.
          </Typography>

          <Box
            sx={{
              width: '90%',
              flexGrow: 1,
              paddingBottom: '56px',
              overflowY: 'auto',
            }}
            ref={paperRef}
          >
            <Autocomplete
              multiple
              options={vendors_options}
              getOptionLabel={(option) => option.title}
              value={selectedVendors}
              onChange={handleVendorChange}
              disableCloseOnSelect
              groupBy={(option) => option.category}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search vendors"
                  sx={{
                    width: '100%',
                    overflow: 'hidden',
                    backgroundColor: isDarkMode ? 'grey.800' : 'white', // Set background color for dark mode
                    '& .MuiOutlinedInput-root': {
                      minHeight: '56px',
                      padding: '10px',
                      borderColor: isDarkMode
                        ? theme.palette.grey[600]
                        : theme.palette.grey[400], // Adjust border color for dark mode
                    },
                    '& .MuiInputLabel-root': {
                      color: isDarkMode
                        ? theme.palette.grey[400]
                        : theme.palette.text.primary, // Adjust label color
                    },
                    '& .MuiInputBase-input': {
                      color: isDarkMode
                        ? theme.palette.grey[300]
                        : theme.palette.text.primary, // Text color for dark mode
                    },
                  }}
                  inputRef={inputRef}
                  onKeyDown={handleKeyDown}
                />
              )}
              PaperComponent={({ children }) => (
                <Paper
                  sx={{
                    maxHeight: 'calc(100vh - 200px)',
                    overflowY: 'auto',
                    marginTop: '8px',
                    backgroundColor: isDarkMode ? 'grey.800' : 'white', // Dropdown background color for dark mode
                  }}
                >
                  {children}
                </Paper>
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option.title}
                    label={option.title}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon />}
                    checkedIcon={<CheckBoxIcon />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.title}
                </li>
              )}
              filterOptions={(options, { inputValue }) => {
                const filtered = options.filter((option) =>
                  option.title.toLowerCase().includes(inputValue.toLowerCase())
                );
                return filtered;
              }}
              open
              ListboxProps={{
                style: {
                  maxHeight: 'calc(100vh - 250px)',
                  overflowY: 'auto',
                },
              }}
            />
          </Box>

          {isDesktop && (
            <Box
              sx={{
                position: 'fixed',
                bottom: 16,
                left: '50%',
                transform: 'translateX(-50%)',
                width: '100%',
                maxWidth: 300,
                display: 'flex',
                justifyContent: 'center',
                padding: '3px',
                backgroundColor: isDarkMode ? 'grey.900' : 'white', // Adjust background color for dark mode
              }}
            >
              <Button
                onClick={handleSaveAndClose}
                variant="contained"
                color="primary"
                startIcon={<CheckCircleIcon />}
                sx={{ width: '100%', maxWidth: 300 }}
              >
                Save & Close
              </Button>
            </Box>
          )}
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message="Selections saved!"
        autoHideDuration={3000}
      />
    </>
  );
};

export default VendorModal;
