
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/Home';
import SearchResults from './components/SearchResults';
import TermOfServices from './components/TermOfServices';
import PrivacyPolicy from './components/PrivacyPolicy';
import ContactForm from './components/ContactForm';
import ScrollToTop from "./components/ScrollToTop"; // Import ScrollToTop
import HowItWorks from "./components/HowItWorks"
import NotFound from "./components/NotFound"
import FAQ from "./components/FAQ"
import Layout from './Layout';
import ReactGA from 'react-ga4'; // Import react-ga4
import useAnalytics from './components/Analytics'; // Import your tracking hook

const App = () => {
  // Initialize Google Analytics
  useEffect(() => {
    ReactGA.initialize('G-NX21MJTHMK'); // Your Google Analytics Measurement ID
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {/* Route for Layout component */}
        <Route path="/" element={<Layout />}>
          {/* Child routes (Home and About pages) */}
          <Route index element={<Home />} />  {/* This is the default route for "/" */}
          <Route path="results" element={<SearchResults />} />
          <Route path="terms" element={<TermOfServices />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="contact" element={<ContactForm />} />
          <Route path="howitworks" element={<HowItWorks />} />
          <Route path="faq" element={<FAQ />} />

          {/* Catch-all route for 404 */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  );
};

// Wrapper component to handle route tracking
const AnalyticsWrapper = () => {
  useAnalytics(); // This will now work within the Router context
  return null; // No UI component is needed, it just tracks page views
};

export default App;
