import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const FAQ_data = [
  {
    "question": "How can I book a vendor for my event?",
    "answer": "Booking a vendor is easy! Simply search for the date of your event, the duration and poscode and finally select the type of vendor(s) you need. We will then get in touch with the best vendors we found for you and with detailed instructions on how to book directly through our platform.",
  },{
    "question": "What if I need to cancel or reschedule my event?",
    "answer": "You can cancel or reschedule your booking by contacting the vendor directly. Please refer to the vendor's cancellation policy for more details on fees and timelines.",
  },{
    "question": "Can I communicate with vendors before booking?",
    "answer": "Yes! You can message vendors through our platform before making a booking. This allows you to ask questions and get all the details your need before booking.",
  },{
    "question": "How do I leave a review for a vendor?",
    "answer": "After your event, you will receive an email prompt to leave a review for the vendor.",
  },
]
export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 8, sm: 6 },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            fontSize: "clamp(2.5rem, 8vw, 3.5rem)",
            pb: { xs: 2, sm: 2 },
          }}
        >
          FAQ
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          Have questions about how our service works? Find the answers here!
        </Typography>
      </Box>

      <Box sx={{ width: '100%' }}>

        {FAQ_data.map((faq_item, faq_index) => (

          <Accordion
            expanded={expanded === 'panel' + faq_index}
            onChange={handleChange('panel' + faq_index)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={"panel" + faq_index + "d-content"}
              id={"panel" + faq_index + "d-header"}
            >
              <Typography component="h3" variant="subtitle2">
                {faq_item.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' } }}
              >
                {faq_item.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>

        ))}
       
      </Box>
    </Container>
  );
}
