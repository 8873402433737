import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import GroupWorkRoundedIcon from '@mui/icons-material/GroupWorkRounded';
import HandshakeRoundedIcon from '@mui/icons-material/HandshakeRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import TimerRoundedIcon from '@mui/icons-material/TimerRounded';
import { useTheme } from '@mui/system';

const items = [
  {
    icon: <GroupWorkRoundedIcon />,
    title: 'Extensive Vendor Network',
    description:
      'We’ve built a diverse and reliable network of vetted vendors, including entertainers, caterers, decorators, and more. Our connections ensure you have access to top-quality services that fit your event’s specific needs.',
  },
  {
    icon: <HandshakeRoundedIcon />,
    title: 'Personalized Service',
    description:
      'We take the time to understand your unique requirements and preferences. Our personalized approach means we tailor our recommendations to ensure you find the perfect match for your event, making the planning process smooth and enjoyable.',
  },
  {
    icon: <InsightsRoundedIcon />,
    title: 'Expert Guidance',
    description:
      'With deep knowledge of the local event scene in SW London, our team is equipped to guide you through every step of the planning process. We provide insights and tips to help you make informed decisions.',
  },
  {
    icon: <EventAvailableRoundedIcon />,
    title: 'Streamlined Booking Process',
    description:
      'We simplify the logistics of booking vendors by handling all communications and confirmations on your behalf. This allows you to focus on creating an amazing experience while we manage the details.',
  },
  {
    icon: <VerifiedRoundedIcon />,
    title: 'Quality Assurance',
    description:
      'Our rigorous vetting process ensures that all vendors in our network meet our high standards for quality and service. You can trust that every vendor we recommend has a proven track record of excellence.',
  },
  {
    icon: <FavoriteRoundedIcon />,
    title: 'Commitment to Satisfaction',
    description:
      'Your satisfaction is our top priority. We are dedicated to making sure your event is a success and that you have a positive experience throughout the planning process. We’re here to support you every step of the way.',
  },
  {
    icon: <PlaceRoundedIcon />,
    title: 'Local Expertise',
    description:
      'As a local business, we understand the unique needs and preferences of our community. We’re passionate about helping you create memorable events that resonate with the families in SW London.',
  },
  {
    icon: <TimerRoundedIcon />,
    title: 'Time and Stress Savings',
    description:
      'Planning an event can be overwhelming, but with our services, you can save time and reduce stress. Let us handle the connections and logistics so you can enjoy the planning process and focus on the fun!',
  },
];


export default function Highlights() {
  const theme = useTheme();
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'grey.900',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            fontSize: "clamp(3rem, 10vw, 3.5rem)",
            color: "text.dark",
            ...theme.applyStyles("dark", {
              color: "primary.secondary"
            })
          }}
        >
          Why Little Big&nbsp;
          <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: "inherit",
                color: "primary.main"
              })}
            >
               Events? 
            </Typography>
        </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
          Choosing the right partner for your kids' events is crucial, and here’s why Little Big Events is the best choice for connecting vendors and event planners: 
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: '100%',
                  borderColor: 'hsla(220, 25%, 25%, 0.3)',
                  backgroundColor: 'grey.800',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
