import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import HelpIcon from "@mui/icons-material/Help";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import LBEIcon from "./LBEIcon";
import ToggleColorMode from "./ToggleColorMode";
import CloseIcon from "@mui/icons-material/Close"; // For closing the modal

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: "blur(24px)",
  border: "1px solid",
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: "8px 12px",
}));

function AppAppBar({ mode, toggleColorMode }) {
  const [open, setOpen] = useState(false); // State for the drawer
  const [openHelpModal, setOpenHelpModal] = useState(false); // State for Help modal

  const location = useLocation(); // Get the current route path

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleOpenHelpModal = () => {
    setOpenHelpModal(true);
  };

  const handleCloseHelpModal = () => {
    setOpenHelpModal(false);
  };

  // Define the default menu items
  const menuItems = [
    { label: "Home", href: "/" },
    { label: "How It Works?", href: '/howitworks' },
    { label: "Contact Us", href: '/contact' },
    { label: "FAQ", href: '/faq' },
    {/*{ label: "Perfect Events", href: "#perfect-event", display_for: ["/"] },
    { label: "Why US?", href: "#highlights", display_for: ["/"] },
    { label: "FAQ", href: "#faq", display_for: ["/"] },*/}
  ];

  // Filter menu items based on the current path
  const filteredMenuItems = menuItems.filter((item) => {
    if (item.display_for && item.display_for.indexOf(location.pathname) >= 0) {
      return true;
    } else if (item.display_for) {
      return false;
    } else {
      return true;
    }
  });

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
          mt: 5,
        }}
      >
        <Container maxWidth="lg">
          <StyledToolbar variant="dense" disableGutters>
            <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center", px: 0 }}>
              <LBEIcon />
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                {/* Dynamically render the filtered menu items */}
                {filteredMenuItems.map((item, index) => (
                  <Button
                    key={index}
                    variant="text"
                    color="info"
                    size="small"
                    href={item.href}
                  >
                    {item.label}
                  </Button>
                ))}
                {location.pathname === "/" && (
                  <IconButton
                    onClick={handleOpenHelpModal}
                    sx={{
                      fontSize: "1.5rem", // Make the button a bit larger
                      color: "primary.main",
                      marginLeft: 2, // Add some spacing to the left
                    }}
                  >
                    <HelpIcon /> {/* Large help icon */}
                  </IconButton>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 1,
                alignItems: "center",
              }}
            >
              <Box sx={{ ml: 2 }}>
                <ToggleColorMode
                  data-screenshot="toggle-mode"
                  mode={mode}
                  toggleColorMode={toggleColorMode}
                />
              </Box>
            </Box>

            <Box sx={{ display: { sm: "flex", md: "none" } }}>
              <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>

              <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
  <Box sx={{ p: 2, backgroundColor: "background.default", height: "100%" }}>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <IconButton onClick={toggleDrawer(false)}>
        <CloseRoundedIcon />
      </IconButton>
    </Box>

    <Divider sx={{ my: 3 }} />

    {/* Render the filtered menu items inside the drawer */}
    {filteredMenuItems.map((item, index) => (
      <MenuItem
        key={index}
        sx={{
          justifyContent: "flex-start", // Align menu items to the left
          paddingLeft: 0, // Remove default padding
        }}
      >
        <Button
          color="info"
          variant="text"
          size="small"
          href={item.href}
          sx={{
            textAlign: "left", // Ensure text is aligned to the left
            width: "100%", // Make the button take full width
            padding: "8px 16px", // Add padding for better touch target on mobile
          }}
        >
          {item.label}
        </Button>
      </MenuItem>
    ))}

    {/* Spacer to push icons to the bottom */}
    <Box sx={{ flexGrow: 1 }} />

    {/* Move HelpIcon and ToggleColorMode to the bottom of the drawer */}
    <Box
      sx={{
        display: { xs: "flex", md: "none" }, // Only display on mobile
        alignItems: "center",
        justifyContent: "space-between",
        mt: 0, // Add some margin at the top for better spacing from menu items
      }}
    >
      <IconButton
        onClick={handleOpenHelpModal}
        sx={{
          fontSize: "1.5rem",
          color: "primary.main",
        }}
      >
        <HelpIcon />
      </IconButton>

      <ToggleColorMode
        data-screenshot="toggle-mode"
        mode={mode}
        toggleColorMode={toggleColorMode}
      />
    </Box>
  </Box>
</Drawer>

            </Box>
          </StyledToolbar>
        </Container>
      </AppBar>

      {/* Help Modal */}<Modal open={openHelpModal} onClose={handleCloseHelpModal}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            padding: 3,
            width: "95vw", // Adjust width for smaller screens
            maxWidth: 700, // Maximum width for larger screens
            maxHeight: "90vh", // Ensure the modal doesn't exceed 90% of the viewport height
            position: "absolute", // Position absolute to center it
            top: "50%", // Align to the center of the screen vertically
            left: "50%", // Align to the center of the screen horizontally
            transform: "translate(-50%, -50%)", // Center by shifting back by 50% of its width and height
            borderRadius: 2, // Optional: Add some border-radius for rounded corners
            boxShadow: 24, // Optional: Add some shadow for depth
          }}
        >
          {/* Add the scrollable container */}
          <Box
            sx={{
              maxHeight: "80vh", // Restrict content to 80% of viewport height
              overflowY: "auto", // Enable scrolling for the entire content
              width: "100%", // Make sure it takes full width
            }}
          >
            <Typography variant="h6" gutterBottom sx={{ textAlign: "center" }}>
              How to Submit a Request
            </Typography>
            <Typography variant="body1" sx={{ padding: 2 }}>
              If you’ve already set a date for your party and know how you’d like to plan it, simply choose the 'Vendors' tab to find the right providers. If you’re unsure about the details and need assistance designing the perfect kids' event, select the 'Tailored' tab for personalized support.  <br /><hr />
              <b>The Vendors tab</b>
              <br />
              To submit a request, please fill in the event date, duration, vendors, and postcode. Ensure that the selected date is at least 7 days from today and that your postcode starts with "SW" to avoid any issues.
              <hr />
              <b>The Tailored tab</b>
              <br />
              To submit a request, simply populate the text box and submit your custom request. Please specify as much details as possible.
              <br />
            </Typography>
          </Box>

          {/* Keep the button outside the scrollable area */}
          <Button
            onClick={handleCloseHelpModal}
            variant="contained"
            color="primary"
            startIcon={<CloseIcon />}
            sx={{ mt: 2 }} // Add some margin-top for spacing
          >
            Close
          </Button>
        </Box>
      </Modal>


    </>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
