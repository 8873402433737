import * as React from 'react';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

export default function LBEIcon() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ width: '100px', height: '40px', overflow: 'hidden' }} // Fixed size for the Box
    >
      <Link to="/" style={{ display: 'block', width: '100%', height: '100%' }}>
        <img
          src="logo_bar_v2_small.png"
          alt="Little Big Events Logo"
          style={{ width: '100%', height: '100%', objectFit: 'contain', cursor: 'pointer' }}
        />
      </Link>
    </Box>
  );
}



{/*
    <SvgIcon sx={{ height: 50, width: 100, mr: 2 }}>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 width="100%" viewBox="0 0 506 522" enable-background="new 0 0 506 522" >
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M291.000000,1.000002 
	C362.928040,1.000000 434.856049,1.000000 507.000000,1.000000 
	C507.000000,74.687424 507.000000,148.375046 506.650848,222.841614 
	C505.868866,222.454681 505.319183,221.317810 505.021545,220.118393 
	C502.362061,209.402237 499.746979,198.675079 497.067322,187.562912 
	C496.290009,184.784668 495.564209,182.394653 494.905945,179.627350 
	C488.948578,162.180466 482.385254,145.338913 472.935364,129.810089 
	C468.839630,123.079720 464.385712,116.567352 459.955688,109.634018 
	C453.228699,101.174988 446.640503,93.037903 439.918457,84.590622 
	C437.869232,82.032661 436.201233,79.500381 433.997467,77.584351 
	C421.777924,66.960327 409.428101,56.486176 397.029846,45.714443 
	C396.633789,45.367252 396.330811,45.266319 395.748535,45.051846 
	C394.993286,44.586128 394.517273,44.233948 393.884125,43.572350 
	C393.141510,42.987492 392.537659,42.745121 391.973267,42.431770 
	C379.809753,35.678677 367.650635,28.917639 355.270844,21.883224 
	C354.143616,21.336901 353.198395,21.153378 352.334442,20.781162 
	C333.066101,12.479782 313.302216,5.859918 292.434845,2.894417 
	C291.878082,2.815294 291.474823,1.655723 291.000000,1.000002 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M507.000000,302.000000 
	C507.000000,375.591644 507.000000,449.183319 507.000000,523.000000 
	C437.645935,523.000000 368.291626,523.000000 298.146240,522.670227 
	C303.838165,520.561768 310.372253,518.947937 316.790192,516.959351 
	C323.630493,514.839844 330.360992,512.366089 337.454742,510.035645 
	C338.185883,509.973114 338.601715,509.921936 339.289032,509.898193 
	C339.758575,509.632172 339.956665,509.338684 340.377899,509.022369 
	C340.601044,508.999573 341.022614,508.846130 341.287476,508.871094 
	C341.747345,508.610321 341.942352,508.324585 342.526489,508.030823 
	C350.983948,504.486450 359.225403,501.294525 367.084900,497.342865 
	C384.024536,488.825836 399.934235,478.681000 414.331177,465.814087 
	C424.612274,455.863434 434.711517,446.079071 444.424622,435.925293 
	C448.090912,432.092682 450.864197,427.405853 454.317688,423.000549 
	C455.074371,422.295441 455.557922,421.698120 456.355469,420.945923 
	C459.422577,416.971558 462.665100,413.410370 464.841858,409.286591 
	C473.068481,393.701660 481.770630,378.288757 488.743164,362.141510 
	C496.752960,343.592194 502.241302,324.093445 505.078583,303.962189 
	C505.181458,303.232361 506.335724,302.650696 507.000000,302.000000 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M0.999998,216.000000 
	C1.000000,144.406296 1.000000,72.812584 1.000000,1.000000 
	C72.354080,1.000000 143.708374,1.000000 215.851181,1.342030 
	C212.143692,2.787667 207.665222,3.969467 203.148743,4.981592 
	C181.521011,9.828277 160.637329,16.743521 140.650574,27.194962 
	C137.229263,28.957329 134.100159,30.593847 131.103607,32.444939 
	C124.991867,36.220402 118.951538,40.111446 112.530457,43.984978 
	C83.254791,64.286636 59.439713,89.465187 40.007248,119.326622 
	C33.899513,130.784485 27.251209,141.608673 22.147976,153.117950 
	C13.485628,172.654068 6.587537,192.866043 2.916891,214.043503 
	C2.788512,214.784164 1.661541,215.351746 0.999998,216.000000 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M209.000000,523.000000 
	C139.738602,523.000000 70.477203,523.000000 1.000000,523.000000 
	C1.000000,451.979248 1.000000,380.958282 1.332489,309.146118 
	C2.439991,310.773438 3.512853,313.140259 3.942967,315.618683 
	C7.424709,335.681366 14.217561,354.817596 22.371107,373.240021 
	C30.238737,391.016418 40.335720,407.723328 52.428879,423.261078 
	C65.244492,439.727173 79.330124,454.675018 95.349716,468.035736 
	C113.097694,482.837952 132.424026,494.946777 153.558060,504.073761 
	C170.940521,511.580658 188.790527,517.860535 207.565781,521.097656 
	C208.136215,521.196045 208.525986,522.342285 209.000000,523.000000 
z"/>
<path fill="#56B8DA" opacity="1.000000" stroke="none" 
	d="
M341.022614,508.846130 
	C341.022614,508.846130 340.601044,508.999573 340.157349,509.114990 
	C339.418915,509.375610 339.186859,509.589050 339.017548,509.870728 
	C338.601715,509.921936 338.185883,509.973114 337.027161,510.022766 
	C335.129272,510.097382 333.919312,509.975739 332.827393,510.279053 
	C313.049377,515.772339 293.174561,520.676453 272.481354,521.120850 
	C271.641174,521.138855 270.826538,522.346619 270.000000,523.000000 
	C263.645599,523.000000 257.291199,523.000000 250.415909,522.724854 
	C249.581802,522.160217 249.268585,521.870789 248.955368,521.581299 
	C248.636902,522.054199 248.318451,522.527100 248.000000,523.000000 
	C247.250000,523.000000 246.500000,523.000000 245.251541,522.622803 
	C243.906158,521.838501 243.089752,521.166809 242.207596,521.065063 
	C233.000580,520.002869 223.772766,519.117798 214.570435,518.018677 
	C194.298508,515.597351 175.084930,509.255554 156.544998,501.093109 
	C144.719711,495.886841 133.267029,489.704010 122.096748,483.188538 
	C115.287437,479.216736 109.331726,473.781525 103.326752,468.958130 
	C105.302681,468.636139 106.944450,468.359741 110.372742,467.782501 
	C99.644608,462.600586 97.626724,454.354340 98.293022,445.172485 
	C102.996552,451.828857 109.276665,455.323639 116.845192,456.766296 
	C119.811676,457.331757 122.444496,457.070099 124.374939,454.000671 
	C125.499977,453.999908 126.249985,453.999939 127.032455,454.280945 
	C127.352051,455.025848 127.639191,455.489838 127.885193,456.317200 
	C126.098503,459.565033 125.350449,462.329590 127.864220,465.330170 
	C130.292648,468.228790 133.104996,468.485260 136.380936,467.375214 
	C138.103561,466.791473 139.848633,466.274292 141.575409,465.702393 
	C145.612183,464.365417 146.976105,462.078552 144.261444,458.269104 
	C144.362427,458.206116 144.562790,458.334625 144.845779,458.386658 
	C145.370407,458.165955 145.612030,457.893250 146.114197,457.440918 
	C147.764328,456.776001 149.153915,456.290710 150.963348,455.862366 
	C151.946671,455.940186 152.510132,455.961060 153.320221,456.218658 
	C154.990631,458.664886 156.414413,460.874390 157.870239,463.367218 
	C157.987152,464.020111 158.072021,464.389679 157.979736,465.122498 
	C158.226929,468.207123 157.803192,471.531158 159.230576,473.539703 
	C163.995224,480.244232 170.636307,483.184174 178.924744,481.346069 
	C178.533310,482.272064 178.071930,482.797760 177.891281,483.406738 
	C177.272934,485.491364 176.110123,487.719269 176.454865,489.661896 
	C176.639694,490.703430 179.408951,491.635101 181.139694,491.985626 
	C185.630600,492.895172 190.191513,493.460144 194.725998,494.152679 
	C196.520004,494.426636 198.341949,494.999725 200.109543,494.869904 
	C204.578964,494.541504 205.242538,493.165070 203.643600,488.868408 
	C203.194946,487.662781 203.209503,486.284790 203.316711,485.158813 
	C206.260452,488.566833 209.723206,489.489380 213.727829,488.998291 
	C216.810394,488.620300 219.902573,488.320526 223.045990,488.332703 
	C227.103180,497.604218 235.121429,498.694214 243.452057,498.966522 
	C248.954987,499.146362 251.715134,496.699310 253.439102,490.992737 
	C257.514221,490.148315 261.694824,490.160034 264.660248,488.267609 
	C268.901154,485.561096 272.253357,481.462006 276.321045,477.913788 
	C279.701538,474.598145 282.643066,475.648346 285.547241,478.085724 
	C287.828674,480.000488 290.100494,481.926697 292.384857,483.837952 
	C299.471863,489.767334 311.805267,486.973206 315.282593,478.681274 
	C313.799805,478.361908 312.311340,478.041321 310.989868,477.438141 
	C315.089600,472.399200 319.022308,467.642883 323.297974,462.924133 
	C325.251617,463.727966 326.862335,464.494202 328.672668,465.528748 
	C330.624268,467.074371 332.376160,468.351715 334.247986,469.921326 
	C334.917358,470.472961 335.466797,470.732330 336.238037,471.029785 
	C336.761566,471.166901 336.985352,471.357452 337.244720,471.908203 
	C337.709595,472.300079 338.060974,472.423279 338.450317,472.476959 
	C338.488312,472.407410 338.643066,472.441620 338.442474,472.786835 
	C336.689789,473.845428 335.137695,474.558807 331.904510,476.044861 
	C335.979828,477.289337 338.883942,479.076813 341.544678,478.767944 
	C345.123535,478.352448 348.514618,476.319611 351.991882,475.371094 
	C352.598236,477.226837 352.913483,479.730408 353.846771,479.987122 
	C359.287750,481.483704 364.872070,482.461395 370.416138,483.573486 
	C370.658508,483.622131 370.983368,483.275269 371.265869,483.105988 
	C374.870361,480.946320 377.332642,475.739777 375.615173,471.905640 
	C373.768890,467.783997 370.857727,464.146820 368.511139,460.238251 
	C367.537903,458.617188 366.823914,456.840515 366.335938,455.183105 
	C370.158691,457.066315 373.637512,458.901031 377.073273,461.136414 
	C375.753143,467.593018 378.633514,470.351562 384.681946,468.502716 
	C386.930389,467.815399 388.892578,466.191711 391.355835,464.985474 
	C393.775726,465.165833 396.176178,464.744080 397.799622,465.675934 
	C400.948578,467.483429 403.210480,467.184906 405.825134,464.786926 
	C408.734467,462.118622 411.729370,461.927094 413.995667,465.833313 
	C399.934235,478.681000 384.024536,488.825836 367.084900,497.342865 
	C359.225403,501.294525 350.983948,504.486450 342.313049,508.125366 
	C341.425812,508.372406 341.196533,508.578491 341.022614,508.846130 
z"/>
<path fill="#59C2E4" opacity="1.000000" stroke="none" 
	d="
M141.002762,27.166231 
	C160.637329,16.743521 181.521011,9.828277 203.148743,4.981592 
	C207.665222,3.969467 212.143692,2.787667 216.319839,1.342030 
	C240.687561,1.000000 265.375122,1.000000 290.531342,1.000002 
	C291.474823,1.655723 291.878082,2.815294 292.434845,2.894417 
	C313.302216,5.859918 333.066101,12.479782 352.334442,20.781162 
	C353.198395,21.153378 354.143616,21.336901 355.081299,22.238182 
	C352.741425,26.245264 350.371216,29.622475 347.556702,32.999809 
	C344.741180,32.999226 342.369965,32.998516 339.919189,32.655872 
	C337.882355,31.207855 336.040619,29.747417 333.945435,29.064457 
	C328.433136,27.267635 327.762512,25.980003 328.146667,20.349007 
	C328.258453,18.710421 326.389862,16.190477 324.768066,15.349824 
	C322.150757,13.993167 321.235748,16.227697 320.578094,18.587337 
	C319.594574,22.115997 318.213318,25.533787 316.626129,29.057117 
	C314.032867,29.710741 311.845642,30.498972 309.590027,30.860882 
	C304.950256,31.605328 299.578033,30.880079 295.770508,33.013840 
	C289.565186,36.491356 285.448120,33.289391 280.600739,30.964180 
	C278.861298,30.129803 276.802948,29.686296 274.863922,29.589529 
	C266.509003,29.172577 257.698822,33.103951 249.949432,26.769749 
	C249.265564,26.210775 247.764404,26.516815 246.662949,26.625309 
	C238.912735,27.388720 231.165115,28.181240 223.423676,29.028530 
	C222.060303,29.177752 220.729843,29.627691 219.461899,29.920799 
	C219.911835,32.212181 220.233887,33.852234 220.555939,35.492287 
	C215.249268,30.712370 215.322586,30.762745 211.459167,36.386253 
	C210.883545,37.224121 209.202515,37.794361 208.104691,37.694996 
	C203.777191,37.303310 199.375656,35.703300 195.189682,36.185249 
	C185.733109,37.274006 176.388275,39.333187 166.750793,40.997894 
	C166.503616,40.998169 166.009293,40.992081 165.803314,40.673588 
	C162.397827,40.236553 159.198303,40.118008 155.662766,39.909245 
	C152.776993,37.040867 150.393372,34.080910 147.620544,31.546844 
	C145.691376,29.783804 143.227661,28.605669 141.002762,27.166231 
M311.130432,20.582994 
	C311.211090,23.515812 312.901825,24.669420 315.329895,23.392672 
	C316.254761,22.906368 316.242126,20.637171 316.656738,19.180527 
	C314.875763,19.393923 313.094818,19.607319 311.130432,20.582994 
z"/>
<path fill="#58BDDE" opacity="1.000000" stroke="none" 
	d="
M1.000001,284.000000 
	C1.000000,274.312439 1.000000,264.624878 1.364573,254.414215 
	C3.461654,253.916138 5.450426,253.399017 6.876178,254.072983 
	C10.430622,255.753174 12.746267,254.017151 13.528217,251.243225 
	C14.822546,246.651611 18.201456,245.258423 22.156179,244.275345 
	C23.208780,245.032349 24.104259,245.512802 24.999939,246.392914 
	C25.000515,247.857468 25.000889,248.922348 24.784868,250.164551 
	C24.381926,250.857178 24.195377,251.372513 23.721226,252.068329 
	C21.904202,254.168686 20.374779,256.088562 18.536644,258.101990 
	C18.412514,259.458710 18.597097,260.721924 18.945621,262.242188 
	C19.201220,263.089874 19.292873,263.680542 19.193386,264.628235 
	C17.056602,266.743439 14.952252,268.361664 13.229700,270.316376 
	C12.316367,271.352783 11.747439,272.939056 11.596172,274.339020 
	C11.402505,276.131348 11.720266,277.994293 11.946229,279.810822 
	C12.079942,280.885742 12.461802,281.929749 12.851828,283.314270 
	C12.957850,284.100159 12.944121,284.559418 12.821186,285.142090 
	C12.641439,285.627808 12.806792,285.852722 13.452042,286.155762 
	C14.759798,287.445557 15.823550,288.519806 17.048935,289.864288 
	C19.108341,291.354706 21.006115,292.574921 23.010954,293.863953 
	C22.010948,296.385803 21.032974,298.632202 20.222317,300.937408 
	C17.714203,308.069641 22.276953,314.059357 30.275415,313.980560 
	C31.105644,314.764862 31.710293,315.328430 32.159199,315.996613 
	C36.626919,322.647064 39.997440,323.571503 47.544994,320.623535 
	C48.783737,320.139709 50.493637,320.862183 52.183548,321.325256 
	C53.917355,322.064728 55.453384,322.506012 57.237808,323.196594 
	C59.458725,324.545166 61.357872,325.813171 63.418686,326.709412 
	C68.056282,328.726196 72.425003,332.128265 78.345497,330.043640 
	C79.335602,330.250031 79.989632,330.404175 80.337540,330.774719 
	C78.018723,332.994476 76.006042,334.997864 73.611145,336.940308 
	C64.735672,333.784821 56.242416,330.690216 47.749535,327.594482 
	C44.734482,333.977112 42.517113,335.179016 35.567047,333.921295 
	C34.199429,332.912354 33.103596,332.031830 31.953831,330.829773 
	C30.934994,330.591766 29.970087,330.675293 28.748783,330.962769 
	C27.399235,332.759155 26.306078,334.351624 25.043028,336.021606 
	C24.791039,336.188873 24.626005,336.298706 24.641991,336.364655 
	C24.695505,336.585388 24.813732,336.790405 24.955914,337.347382 
	C25.058922,338.621155 25.113194,339.549011 25.128643,340.894043 
	C26.470987,343.011505 27.852154,344.711823 29.233326,346.412140 
	C30.161625,345.606171 31.089920,344.800171 32.276546,343.842896 
	C33.505875,343.062653 34.476871,342.433624 35.858498,341.857483 
	C39.848522,343.628296 43.427917,345.346222 46.656673,347.126007 
	C40.658783,349.837128 40.183750,351.237640 42.996956,357.304565 
	C40.421967,365.248322 43.573799,371.283966 47.699432,377.055908 
	C46.740417,380.134064 45.339787,382.457581 41.937214,382.691528 
	C41.250031,381.922852 40.624939,381.461426 39.681824,380.850769 
	C32.844978,377.346741 31.361031,373.433960 33.763580,366.440430 
	C34.398476,364.592316 34.611034,362.599152 35.208439,359.767731 
	C29.968515,361.050293 25.983332,362.025726 21.737267,362.772003 
	C18.962996,355.894928 15.994308,349.376984 14.049626,342.566681 
	C11.528603,333.737915 9.639033,324.713470 7.818782,315.704315 
	C5.993930,306.672424 4.644008,297.545380 2.946605,288.486145 
	C2.653921,286.924042 1.666506,285.492065 1.000001,284.000000 
M11.623285,261.725647 
	C12.011949,262.049805 12.387450,262.394348 12.809649,262.666473 
	C12.851766,262.693604 13.177923,262.280029 13.372239,262.071014 
	C12.913882,261.831177 12.455524,261.591309 11.623285,261.725647 
z"/>
<path fill="#5DBBDA" opacity="1.000000" stroke="none" 
	d="
M497.118866,187.951141 
	C499.746979,198.675079 502.362061,209.402237 505.021545,220.118393 
	C505.319183,221.317810 505.868866,222.454681 506.650848,223.310272 
	C507.000000,246.687561 507.000000,270.375122 506.625427,294.542419 
	C500.596222,296.952576 494.806976,298.612732 494.549835,306.330139 
	C494.509644,307.537506 492.824127,309.048309 491.537170,309.791504 
	C486.916748,312.459869 481.978851,314.609528 477.518372,317.505707 
	C476.104095,318.423981 475.806458,321.061981 474.794464,323.176941 
	C473.606293,324.619263 472.621460,325.797577 471.342346,327.036926 
	C470.276886,327.670288 469.505737,328.242676 468.051514,329.322021 
	C470.427399,330.657288 472.382568,331.756104 474.532501,332.892456 
	C474.727264,332.929993 474.988434,333.228607 475.085480,333.538544 
	C476.456543,333.575012 477.730591,333.301575 479.187012,332.865967 
	C479.369446,332.703796 479.849304,332.614136 480.004578,332.970520 
	C481.569153,334.542206 482.859436,336.567657 484.413696,336.795929 
	C487.224152,337.208740 488.230560,338.321106 488.007324,340.957367 
	C487.664520,345.005432 487.195404,349.049103 487.033203,353.104767 
	C486.800415,358.924164 486.683105,358.952515 480.976746,357.883606 
	C478.699951,357.457092 474.775391,357.139526 474.187469,358.202637 
	C472.983795,360.379395 472.825348,363.708801 473.490509,366.233124 
	C475.260529,372.950806 473.566406,377.424774 467.004089,380.567444 
	C467.292847,372.829163 467.938232,365.511841 467.724731,358.219604 
	C467.614655,354.460571 465.976624,350.746246 465.142639,346.804169 
	C465.445038,346.335022 465.684235,346.133087 466.291321,345.924866 
	C466.399933,344.907867 466.191101,343.956818 465.743896,342.815430 
	C464.711334,342.041931 463.917145,341.458771 462.992981,340.519897 
	C462.242554,338.534760 460.910706,336.806488 461.120972,335.292450 
	C462.213684,327.426208 458.496552,322.492432 452.087280,318.660309 
	C453.720398,317.514954 455.400055,316.889282 456.776825,315.858856 
	C458.351868,314.679993 459.657715,313.141449 461.487000,311.776001 
	C470.631195,313.702393 472.478210,313.121582 475.850769,307.327148 
	C478.419373,305.179962 480.493317,302.909149 482.994720,301.304993 
	C486.169098,299.269287 487.707001,296.860229 487.124695,293.078186 
	C493.891724,292.827911 493.891724,292.827911 492.837524,286.749908 
	C492.867554,286.789276 492.776703,286.828705 493.167419,286.856750 
	C494.346771,286.100159 495.135345,285.315521 496.055054,284.217712 
	C498.699341,279.711151 501.013947,275.502319 497.167664,270.523438 
	C496.795135,269.782867 496.456970,269.336761 496.007690,268.624878 
	C495.435913,268.027344 494.975342,267.695587 494.311584,267.214050 
	C493.776459,267.005127 493.444489,266.945923 492.949860,266.591919 
	C491.547211,265.828583 490.307251,265.360016 489.075012,264.544586 
	C491.968414,260.893524 496.299377,258.414093 495.783752,252.910889 
	C497.157837,251.208588 498.955139,249.658951 499.118561,247.952850 
	C499.357727,245.455978 498.577393,242.808044 497.934631,240.300583 
	C497.562378,238.848251 496.948273,236.825272 495.853210,236.308105 
	C492.401886,234.678177 491.204132,232.859924 493.530365,229.386246 
	C494.102264,228.532211 494.027771,227.127533 493.970184,225.990204 
	C493.724213,221.135056 493.564972,216.578613 491.671082,211.623886 
	C488.735260,203.943253 493.782806,196.316162 495.898956,188.761703 
	C495.998718,188.405563 496.698517,188.217499 497.118866,187.951141 
M473.394073,347.052490 
	C472.761658,347.746033 472.129242,348.439575 471.496826,349.133118 
	C472.545166,349.242065 473.593475,349.351013 474.641846,349.459930 
	C474.456818,348.667084 474.271790,347.874207 473.394073,347.052490 
M477.355499,343.015442 
	C477.809875,342.912354 478.264221,342.809296 478.718597,342.706238 
	C478.515320,342.344086 478.312073,341.981964 478.108795,341.619812 
	C477.817169,341.912506 477.525574,342.205200 477.355499,343.015442 
z"/>
<path fill="#58BDE0" opacity="1.000000" stroke="none" 
	d="
M0.999998,216.468628 
	C1.661541,215.351746 2.788512,214.784164 2.916891,214.043503 
	C6.587537,192.866043 13.485628,172.654068 22.147976,153.117950 
	C27.251209,141.608673 33.899513,130.784485 40.378754,119.341415 
	C44.254559,119.857292 48.033649,120.174164 48.044910,125.404617 
	C48.111599,126.061050 48.141781,126.323318 47.940063,126.808609 
	C40.741749,131.251144 34.248470,135.906052 31.528622,144.507339 
	C31.109207,145.162979 30.978697,145.610596 30.705364,146.249268 
	C30.383007,146.785339 30.453297,147.068314 30.473053,147.487793 
	C30.062441,148.144608 29.952187,148.602921 29.710743,149.258514 
	C29.425282,149.798096 29.497385,150.083130 29.526611,150.512451 
	C29.174343,151.145798 29.091328,151.577621 28.842672,152.384079 
	C28.494190,155.841400 28.311352,158.924072 28.060745,162.253815 
	C28.052032,162.661179 28.111086,162.821457 28.097979,163.389359 
	C28.500641,174.104706 33.356499,182.621948 39.167458,191.040527 
	C39.548382,191.516678 39.820679,191.745529 40.356140,192.084595 
	C41.087643,192.776215 41.555977,193.357605 42.005028,194.287292 
	C40.990963,195.758896 39.996189,196.882202 38.625244,198.003769 
	C37.496716,198.001022 36.744358,198.000015 35.916176,197.676285 
	C33.229702,194.961914 32.058578,196.803726 31.004799,199.372559 
	C31.001188,200.499130 30.999773,201.250214 30.583984,202.001465 
	C21.256903,202.610245 12.238462,211.724823 12.153366,221.123611 
	C12.107871,226.148499 10.303783,229.655731 6.757971,232.814117 
	C4.663645,234.679596 2.907964,236.925278 0.999998,239.000000 
	C1.000000,231.645752 1.000000,224.291504 0.999998,216.468628 
z"/>
<path fill="#5ABADB" opacity="1.000000" stroke="none" 
	d="
M467.004883,380.998779 
	C473.566406,377.424774 475.260529,372.950806 473.490509,366.233124 
	C472.825348,363.708801 472.983795,360.379395 474.187469,358.202637 
	C474.775391,357.139526 478.699951,357.457092 480.976746,357.883606 
	C486.683105,358.952515 486.800415,358.924164 487.033203,353.104767 
	C487.195404,349.049103 487.664520,345.005432 488.007324,340.957367 
	C488.230560,338.321106 487.224152,337.208740 484.413696,336.795929 
	C482.859436,336.567657 481.569153,334.542206 480.086975,332.726593 
	C480.365356,331.753845 480.716675,331.381409 481.406830,330.914673 
	C483.815674,327.692535 483.135803,325.461121 479.257050,324.049713 
	C477.623993,323.591766 476.310913,323.252380 474.997833,322.912994 
	C475.806458,321.061981 476.104095,318.423981 477.518372,317.505707 
	C481.978851,314.609528 486.916748,312.459869 491.537170,309.791504 
	C492.824127,309.048309 494.509644,307.537506 494.549835,306.330139 
	C494.806976,298.612732 500.596222,296.952576 506.625427,295.011047 
	C507.000000,297.041656 507.000000,299.083344 507.000000,301.562500 
	C506.335724,302.650696 505.181458,303.232361 505.078583,303.962189 
	C502.241302,324.093445 496.752960,343.592194 488.743164,362.141510 
	C481.770630,378.288757 473.068481,393.701660 464.841858,409.286591 
	C462.665100,413.410370 459.422577,416.971558 456.186981,420.644318 
	C453.765930,418.893066 451.827362,417.288605 449.380402,415.263397 
	C451.894501,413.523895 453.943176,412.452148 455.558563,410.922699 
	C460.082764,406.639221 464.699921,402.352722 466.211700,395.582336 
	C467.419006,393.569183 468.382874,391.775146 469.624390,389.882690 
	C470.486053,386.709625 470.256378,386.459473 466.394562,386.177734 
	C466.230591,386.212555 465.912109,386.107849 465.964355,385.804932 
	C466.021698,385.297150 466.026794,385.092255 466.022736,384.500305 
	C466.008636,383.077087 466.003723,382.040985 466.250305,381.003235 
	C466.669525,381.000641 466.837189,380.999695 467.004883,380.998779 
z"/>
<path fill="#56C1E5" opacity="1.000000" stroke="none" 
	d="
M1.000001,284.468628 
	C1.666506,285.492065 2.653921,286.924042 2.946605,288.486145 
	C4.644008,297.545380 5.993930,306.672424 7.818782,315.704315 
	C9.639033,324.713470 11.528603,333.737915 14.049626,342.566681 
	C15.994308,349.376984 18.962996,355.894928 21.744450,363.104034 
	C22.342005,364.443481 22.671495,365.221741 23.036999,366.289673 
	C23.382496,367.052887 23.691984,367.526459 24.054403,368.360046 
	C29.583685,379.436676 34.730427,390.340210 40.662170,400.798462 
	C44.123032,406.900269 48.839542,412.289825 53.045975,418.343445 
	C53.215847,418.996674 53.261833,419.360260 53.455742,419.608124 
	C65.182213,434.599152 77.392090,449.139893 92.445618,460.992340 
	C95.913811,463.723083 99.474495,466.336334 102.992599,469.003662 
	C109.331726,473.781525 115.287437,479.216736 122.096748,483.188538 
	C133.267029,489.704010 144.719711,495.886841 156.544998,501.093109 
	C175.084930,509.255554 194.298508,515.597351 214.570435,518.018677 
	C223.772766,519.117798 233.000580,520.002869 242.207596,521.065063 
	C243.089752,521.166809 243.906158,521.838501 244.876541,522.622803 
	C233.312439,523.000000 221.624878,523.000000 209.468658,523.000000 
	C208.525986,522.342285 208.136215,521.196045 207.565781,521.097656 
	C188.790527,517.860535 170.940521,511.580658 153.558060,504.073761 
	C132.424026,494.946777 113.097694,482.837952 95.349716,468.035736 
	C79.330124,454.675018 65.244492,439.727173 52.428879,423.261078 
	C40.335720,407.723328 30.238737,391.016418 22.371107,373.240021 
	C14.217561,354.817596 7.424709,335.681366 3.942967,315.618683 
	C3.512853,313.140259 2.439991,310.773438 1.332489,308.677429 
	C1.000000,300.979095 1.000000,292.958191 1.000001,284.468628 
z"/>
<path fill="#59BADC" opacity="1.000000" stroke="none" 
	d="
M0.999998,239.466125 
	C2.907964,236.925278 4.663645,234.679596 6.757971,232.814117 
	C10.303783,229.655731 12.107871,226.148499 12.153366,221.123611 
	C12.238462,211.724823 21.256903,202.610245 30.584652,202.401917 
	C30.998796,203.869537 30.997894,204.936920 30.649488,206.057739 
	C26.574085,208.371674 24.785557,211.335312 25.661810,215.975906 
	C26.056341,218.065277 25.494762,220.626404 24.581108,222.611816 
	C22.597193,226.922989 24.188606,232.324051 20.298416,236.066422 
	C19.860228,236.487946 20.618483,238.184631 20.865999,239.280029 
	C21.222494,240.857727 21.619400,242.426315 21.999058,243.998779 
	C18.201456,245.258423 14.822546,246.651611 13.528217,251.243225 
	C12.746267,254.017151 10.430622,255.753174 6.876178,254.072983 
	C5.450426,253.399017 3.461654,253.916138 1.364573,253.945557 
	C1.000000,249.310745 1.000000,244.621490 0.999998,239.466125 
z"/>
<path fill="#56C1E5" opacity="1.000000" stroke="none" 
	d="
M270.468689,523.000000 
	C270.826538,522.346619 271.641174,521.138855 272.481354,521.120850 
	C293.174561,520.676453 313.049377,515.772339 332.827393,510.279053 
	C333.919312,509.975739 335.129272,510.097382 336.711853,510.034119 
	C330.360992,512.366089 323.630493,514.839844 316.790192,516.959351 
	C310.372253,518.947937 303.838165,520.561768 297.677582,522.670227 
	C288.979095,523.000000 279.958221,523.000000 270.468689,523.000000 
z"/>
<path fill="#56C1E5" opacity="1.000000" stroke="none" 
	d="
M248.333313,523.000000 
	C248.318451,522.527100 248.636902,522.054199 248.955368,521.581299 
	C249.268585,521.870789 249.581802,522.160217 249.947510,522.724854 
	C249.555557,523.000000 249.111099,523.000000 248.333313,523.000000 
z"/>
<path fill="#60BFDC" opacity="1.000000" stroke="none" 
	d="
M497.067322,187.562927 
	C496.698517,188.217499 495.998718,188.405563 495.898956,188.761703 
	C493.782806,196.316162 488.735260,203.943253 491.671082,211.623886 
	C493.564972,216.578613 493.724213,221.135056 493.970184,225.990204 
	C494.027771,227.127533 494.102264,228.532211 493.530365,229.386246 
	C491.204132,232.859924 492.401886,234.678177 495.853210,236.308105 
	C496.948273,236.825272 497.562378,238.848251 497.934631,240.300583 
	C498.577393,242.808044 499.357727,245.455978 499.118561,247.952850 
	C498.955139,249.658951 497.157837,251.208588 495.604248,252.582520 
	C493.072235,249.741913 491.026947,247.146957 488.861053,244.256897 
	C487.353180,242.667282 485.919464,241.418579 484.588104,240.068909 
	C481.626801,237.066864 478.716797,234.014313 475.737732,230.920319 
	C475.689636,230.858292 475.784302,230.733047 475.788635,230.333984 
	C475.082550,229.326538 474.372162,228.718140 473.356262,227.961700 
	C468.494965,224.458115 463.765076,221.985275 457.629028,224.820190 
	C456.591125,224.989548 455.908142,225.179993 455.100708,224.991455 
	C454.374786,224.112839 453.827332,223.279053 453.162476,223.171616 
	C450.345428,222.716370 447.709930,222.190857 445.015778,224.550552 
	C443.784271,225.629181 440.807312,224.910599 438.677063,224.612946 
	C438.215149,224.548401 437.483490,222.118759 437.737671,221.928329 
	C439.286530,220.767868 441.054565,219.878860 442.808624,219.020004 
	C444.151398,218.362518 445.581421,217.883102 447.322327,217.152740 
	C449.449402,216.661789 451.226837,216.342560 453.181061,216.200912 
	C453.900085,215.999512 454.442291,215.620514 455.256042,215.005219 
	C459.957123,212.200165 464.386566,209.631393 469.053345,206.939392 
	C469.529083,206.557419 469.767548,206.298645 470.161835,205.742401 
	C478.432922,198.738174 483.167664,189.979568 486.190552,179.946198 
	C489.335876,179.967957 492.087128,179.986298 494.838379,180.004639 
	C495.564209,182.394653 496.290009,184.784668 497.067322,187.562927 
M484.382172,210.374588 
	C479.963226,208.811310 478.546844,212.418701 477.092468,215.072556 
	C475.286163,218.368515 477.303589,222.486511 481.012451,223.943634 
	C485.080505,225.541870 486.517975,222.726837 488.021912,219.747864 
	C490.132446,215.567368 487.180511,213.349350 484.382172,210.374588 
z"/>
<path fill="#5BBDDC" opacity="1.000000" stroke="none" 
	d="
M394.041290,43.881767 
	C394.517273,44.233948 394.993286,44.586128 395.897522,45.226440 
	C396.561584,45.716690 396.827271,45.865395 397.122894,45.960693 
	C409.428101,56.486176 421.777924,66.960327 433.997467,77.584351 
	C436.201233,79.500381 437.869232,82.032661 439.662720,84.848831 
	C437.965302,86.238548 435.207306,86.853447 435.021545,87.917381 
	C434.270508,92.219887 430.654144,91.798859 427.742615,92.764084 
	C423.647797,93.349243 419.816589,94.173058 415.991272,94.593185 
	C416.280151,92.325623 416.563141,90.461761 416.928619,88.359383 
	C417.345459,87.748299 417.679810,87.375717 418.339355,86.933044 
	C420.604462,85.063217 419.779327,84.313171 417.276855,84.007111 
	C413.260895,83.801514 411.596588,81.529640 410.904419,77.926994 
	C410.610626,77.291359 410.326660,76.950371 410.042664,76.609375 
	C409.694946,77.068382 409.347260,77.527382 408.836731,78.183754 
	C408.133118,78.782349 407.592316,79.183563 406.700623,79.361092 
	C402.237030,75.425354 398.124359,71.713310 394.015930,67.648651 
	C394.180481,66.856743 394.340790,66.417442 394.721680,65.895958 
	C394.764526,65.181023 394.586731,64.548271 394.281891,63.948540 
	C394.154816,63.981556 393.897980,64.036148 393.926727,63.697533 
	C393.591431,62.293484 393.227386,61.228043 393.251221,60.107788 
	C397.055023,59.570442 397.220032,57.611507 396.401978,54.738148 
	C395.389984,51.183449 394.808655,47.506119 394.041290,43.881767 
z"/>
<path fill="#64BAD3" opacity="1.000000" stroke="none" 
	d="
M428.006165,93.002724 
	C430.654144,91.798859 434.270508,92.219887 435.021545,87.917381 
	C435.207306,86.853447 437.965302,86.238548 439.796570,85.159027 
	C446.640503,93.037903 453.228699,101.174988 459.702515,109.885208 
	C459.610352,113.000092 459.632599,115.541832 459.425842,117.864273 
	C457.694366,117.007622 456.191895,116.370285 454.689423,115.732948 
	C454.020660,117.701721 453.351898,119.670502 452.296936,121.741684 
	C450.186340,121.837280 447.994293,122.509308 446.821655,121.701500 
	C442.636017,118.818130 439.891693,119.755806 437.560181,123.885590 
	C434.528259,120.684708 431.762329,117.679085 428.906738,114.390244 
	C428.152435,113.781174 427.487823,113.455322 426.871002,113.120148 
	C426.918823,113.110825 426.866943,113.193314 426.903564,112.832306 
	C426.957275,111.989357 426.974365,111.507416 427.336761,110.931198 
	C428.893127,109.854103 430.478577,109.093620 431.225861,107.835365 
	C432.294342,106.036293 432.946136,103.908890 433.323212,101.839226 
	C433.412750,101.347931 431.704590,100.529099 431.018066,99.570786 
	C431.535889,97.958107 431.859009,96.635567 432.182129,95.313034 
	C430.772919,96.204193 429.363708,97.095345 427.724731,97.743111 
	C427.665344,96.000717 427.835754,94.501724 428.006165,93.002724 
z"/>
<path fill="#5DC2E3" opacity="1.000000" stroke="none" 
	d="
M393.884125,43.572346 
	C394.808655,47.506119 395.389984,51.183449 396.401978,54.738148 
	C397.220032,57.611507 397.055023,59.570442 393.261353,60.079411 
	C392.883667,60.105843 392.914612,60.155155 392.842438,59.921059 
	C392.620331,59.387619 392.405121,59.146622 392.003021,58.983040 
	C391.881470,59.002113 391.640137,59.050121 391.688507,58.843338 
	C391.736877,58.636559 391.461151,58.314606 391.461151,58.314606 
	C391.461151,58.314606 391.065216,58.466217 390.703857,58.258930 
	C387.363403,57.796432 384.384308,57.541222 381.302673,57.277233 
	C379.959869,47.134384 378.946991,46.902454 367.987183,45.443123 
	C367.312225,43.053211 366.411987,41.082943 366.047455,39.018112 
	C365.101501,33.659233 362.229462,32.200893 356.805420,33.476074 
	C354.059692,34.121586 350.947540,33.208244 348.001038,32.999687 
	C350.371216,29.622475 352.741425,26.245264 355.301147,22.513100 
	C367.650635,28.917639 379.809753,35.678677 391.973267,42.431770 
	C392.537659,42.745121 393.141510,42.987492 393.884125,43.572346 
z"/>
<path fill="#5DBBDA" opacity="1.000000" stroke="none" 
	d="
M459.654816,118.083580 
	C459.632599,115.541832 459.610352,113.000092 459.841309,110.207153 
	C464.385712,116.567352 468.839630,123.079720 472.935364,129.810089 
	C482.385254,145.338913 488.948578,162.180466 494.905914,179.627350 
	C492.087128,179.986298 489.335876,179.967957 486.158325,179.482758 
	C485.365814,173.266037 485.878540,167.286987 484.451294,161.813858 
	C481.440247,150.267670 473.854736,142.124878 462.959045,136.766418 
	C465.573822,130.457779 464.453918,125.710068 458.702667,122.285461 
	C458.760590,122.152824 459.009644,122.005363 459.230591,121.835754 
	C459.602478,121.330177 459.753418,120.994217 459.826843,120.342606 
	C459.717834,119.379173 459.686310,118.731377 459.654816,118.083580 
z"/>
<path fill="#5DC2E3" opacity="1.000000" stroke="none" 
	d="
M397.029846,45.714439 
	C396.827271,45.865395 396.561584,45.716690 396.176788,45.339981 
	C396.330811,45.266319 396.633789,45.367252 397.029846,45.714439 
z"/>
<path fill="#5CB5D3" opacity="1.000000" stroke="none" 
	d="
M414.331177,465.814087 
	C411.729370,461.927094 408.734467,462.118622 405.825134,464.786926 
	C403.210480,467.184906 400.948578,467.483429 397.799622,465.675934 
	C396.176178,464.744080 393.775726,465.165833 391.418884,464.604828 
	C391.976044,461.085480 393.245789,457.833923 388.992188,455.620605 
	C388.673126,453.828583 388.370667,452.402283 388.408142,450.925385 
	C393.987946,447.234802 396.574249,442.132355 397.448303,435.875244 
	C399.836487,435.874420 401.802856,435.852142 404.156189,435.871460 
	C406.027985,435.608704 407.512756,435.304352 408.998566,435.400635 
	C409.221039,437.133820 409.442535,438.466370 409.636627,439.634125 
	C413.872986,438.730042 417.757996,438.642120 420.892029,437.063446 
	C425.956970,434.512115 430.275085,430.454651 435.374329,428.001251 
	C441.166107,425.214630 443.269348,420.979675 443.344696,415.049866 
	C447.139496,417.770905 450.592041,420.439636 454.044556,423.108398 
	C450.864197,427.405853 448.090912,432.092682 444.424622,435.925293 
	C434.711517,446.079071 424.612274,455.863434 414.331177,465.814087 
z"/>
<path fill="#5FBBD8" opacity="1.000000" stroke="none" 
	d="
M454.317688,423.000549 
	C450.592041,420.439636 447.139496,417.770905 443.293671,414.717102 
	C441.934448,413.553284 440.968506,412.774536 439.845032,411.719421 
	C438.790253,410.963501 437.892975,410.483978 436.621674,410.003296 
	C435.499664,409.999268 434.751617,409.996429 433.958191,409.722107 
	C433.275360,409.299988 432.637878,409.149384 431.548645,408.998779 
	C423.980255,409.439545 416.455261,406.077454 410.014069,411.307129 
	C409.062164,410.131073 408.191193,409.054962 407.159729,407.720947 
	C406.868256,406.879089 406.737244,406.295135 406.729492,405.318176 
	C408.061371,404.251678 409.375275,403.712646 410.451508,402.870056 
	C411.735565,401.864807 412.815948,400.599396 414.379456,399.351807 
	C416.665344,398.738373 419.002075,398.754181 420.372437,397.611267 
	C427.344421,391.796448 433.375824,393.931702 439.276062,399.235352 
	C439.871460,399.770538 440.681915,400.363770 441.411926,400.389984 
	C442.929230,400.444458 444.459503,400.136444 446.281372,399.911072 
	C447.394714,399.263763 448.211182,398.682831 449.240814,398.405090 
	C454.723846,401.209320 458.280090,400.071991 461.316345,395.133484 
	C463.098999,395.427948 464.533600,395.614716 465.968231,395.801483 
	C464.699921,402.352722 460.082764,406.639221 455.558563,410.922699 
	C453.943176,412.452148 451.894501,413.523895 449.380402,415.263397 
	C451.827362,417.288605 453.765930,418.893066 455.873016,420.799194 
	C455.557922,421.698120 455.074371,422.295441 454.317688,423.000549 
z"/>
<path fill="#56C1E5" opacity="1.000000" stroke="none" 
	d="
M341.287476,508.871094 
	C341.196533,508.578491 341.425812,508.372406 341.923889,508.133423 
	C341.942352,508.324585 341.747345,508.610321 341.287476,508.871094 
z"/>
<path fill="#56C1E5" opacity="1.000000" stroke="none" 
	d="
M339.289032,509.898193 
	C339.186859,509.589050 339.418915,509.375610 339.934204,509.137756 
	C339.956665,509.338684 339.758575,509.632172 339.289032,509.898193 
z"/>
<path fill="#5BBEDD" opacity="1.000000" stroke="none" 
	d="
M48.008408,125.010460 
	C48.033649,120.174164 44.254559,119.857292 40.533077,119.015869 
	C59.439713,89.465187 83.254791,64.286636 112.955055,43.995956 
	C118.105598,44.041519 122.262726,44.523792 124.009735,49.721779 
	C121.896545,53.951828 123.890747,56.045265 127.152626,57.862000 
	C128.055527,58.364887 128.377167,59.911392 128.652405,61.104103 
	C125.987328,63.036358 123.762276,65.047501 121.256348,66.607437 
	C116.371910,69.648003 114.166222,73.785423 115.113213,79.893814 
	C115.046761,80.518692 115.027252,80.768738 114.966667,81.334084 
	C114.613693,82.435921 114.301788,83.222466 113.578323,84.004593 
	C111.283661,83.753563 109.400558,83.506935 107.355362,83.252625 
	C107.193275,83.244942 107.000542,82.983826 106.907120,82.632576 
	C105.488556,81.879166 104.163406,81.476997 102.553116,80.942307 
	C101.518913,80.077156 100.769852,79.344528 99.947495,78.371521 
	C99.246300,78.355713 98.618401,78.580284 97.816231,79.057381 
	C97.143066,80.201988 96.644165,81.094086 95.881660,82.200569 
	C95.462402,83.059006 95.306755,83.703049 95.043365,84.550339 
	C94.699486,84.985405 94.417160,85.081406 93.856812,85.143906 
	C93.427872,85.513969 93.230766,85.781723 92.993111,86.348068 
	C93.017418,87.977058 93.082275,89.307457 92.980598,90.966843 
	C89.677879,93.669991 86.541702,96.044159 83.410751,98.414368 
	C83.112679,98.160896 82.697632,97.986717 82.641296,97.732925 
	C81.931877,94.536324 81.017174,90.939468 76.812393,92.245590 
	C72.801659,93.491440 73.865074,96.621155 75.482315,99.538971 
	C75.818634,100.145752 75.457214,101.738251 74.907990,102.097267 
	C74.072624,102.643333 72.678764,102.870377 71.729622,102.572250 
	C70.528076,102.194839 69.619743,100.737648 68.432106,100.495346 
	C66.473877,100.095818 64.015953,99.630371 62.501137,100.487404 
	C61.546455,101.027534 61.923634,103.925278 61.708164,105.766006 
	C61.652355,106.242767 61.614601,106.721642 61.556892,107.320549 
	C56.453320,109.737968 53.302971,113.225487 54.944324,119.415878 
	C55.313919,120.809814 54.990318,122.387550 54.696136,124.090103 
	C52.614876,124.912537 50.823299,125.525543 48.907509,125.911438 
	C48.525002,125.459702 48.266705,125.235085 48.008408,125.010460 
z"/>
<path fill="#5FBFDF" opacity="1.000000" stroke="none" 
	d="
M124.134186,49.394821 
	C122.262726,44.523792 118.105598,44.041519 113.306389,43.965855 
	C118.951538,40.111446 124.991867,36.220402 131.103607,32.444939 
	C134.100159,30.593847 137.229263,28.957329 140.650574,27.194962 
	C143.227661,28.605669 145.691376,29.783804 147.620544,31.546844 
	C150.393372,34.080910 152.776993,37.040867 155.663940,40.130913 
	C156.001144,40.442795 156.003510,40.886108 155.685059,41.012939 
	C151.554993,44.687958 147.928391,48.467209 143.874481,51.712708 
	C140.508575,54.407394 136.639709,56.473846 132.594757,58.592457 
	C131.406143,50.011044 130.487167,48.988350 124.134186,49.394821 
z"/>
<path fill="#12242D" opacity="1.000000" stroke="none" 
	d="
M158.156891,464.759247 
	C158.072021,464.389679 157.987152,464.020111 158.060120,463.124847 
	C158.735016,460.011230 153.688538,456.073975 160.069214,454.935608 
	C160.055939,454.959808 160.039795,454.910156 160.124207,455.110107 
	C160.352173,455.567810 160.548843,455.775146 161.013611,456.109863 
	C161.513489,456.756592 161.798355,457.225555 162.154755,457.938965 
	C162.440720,458.440308 162.655151,458.697235 162.980347,459.061157 
	C163.091125,459.168091 163.086334,459.475983 163.173798,459.770569 
	C163.523071,460.370636 163.784866,460.676086 164.258728,461.133423 
	C164.631226,461.587982 164.791672,461.890656 165.037506,462.479767 
	C165.749207,463.177460 166.375504,463.588745 167.438171,463.999878 
	C172.478241,464.655975 176.019028,463.751617 176.633301,458.249969 
	C176.685364,457.783875 177.570175,457.410736 178.188965,457.397583 
	C177.836975,460.452576 177.364136,463.104370 176.610565,467.330627 
	C183.852264,463.438019 189.741653,460.272308 195.766006,457.034088 
	C199.513138,462.068359 203.405319,467.297546 207.653381,473.004822 
	C208.655350,471.211334 209.273697,470.104553 210.155716,469.242126 
	C211.387115,473.754791 213.820465,473.516174 216.733429,471.473450 
	C217.637115,470.839752 218.367447,469.958893 219.162384,469.544891 
	C219.605789,471.157898 220.063980,472.417145 220.522156,473.676422 
	C221.124084,473.287750 221.726013,472.899078 222.679581,472.457275 
	C225.348877,470.921814 227.666534,469.439423 230.332611,467.905609 
	C234.150314,466.838165 236.571045,461.506042 241.348587,465.623383 
	C245.100769,469.089630 248.712433,472.229218 253.071350,476.018402 
	C253.540070,472.922943 253.760971,471.464203 254.232910,470.282532 
	C255.785095,475.362122 259.214600,476.754059 263.312378,473.994568 
	C263.821442,473.938263 264.023865,473.905731 264.545502,473.800354 
	C270.254181,469.035858 275.643646,464.344238 280.701263,459.941498 
	C285.054047,463.176971 289.487335,466.545044 294.011292,469.786591 
	C295.223999,470.655487 296.964569,472.207947 297.783020,469.016510 
	C300.478851,468.542480 304.008759,472.129700 304.988647,466.675110 
	C305.353119,465.561279 305.703857,464.783356 306.180237,464.347107 
	C307.212250,466.111938 308.118652,467.535156 309.203979,469.119080 
	C309.799408,468.713837 310.215973,468.147888 310.685425,467.617188 
	C310.738342,467.652435 310.644257,467.738037 310.921082,467.669922 
	C311.364838,467.278107 311.531830,466.954437 311.601196,466.580078 
	C311.503571,466.529419 311.491272,466.309814 311.851807,466.429077 
	C313.121307,466.040771 314.460999,465.759277 314.866333,464.987244 
	C316.855652,461.198486 319.685944,461.361603 322.955017,462.886536 
	C319.022308,467.642883 315.089600,472.399200 310.615082,477.576111 
	C302.633972,480.244843 295.231476,482.397827 289.059540,475.118805 
	C288.225037,474.134613 286.980438,473.487823 285.895264,472.728302 
	C280.419647,468.895844 280.416138,468.900879 275.758881,474.292908 
	C269.789886,479.502014 264.293182,484.890930 255.631348,485.113464 
	C250.641388,481.579041 245.750549,478.415253 241.445740,474.591644 
	C238.328964,471.823212 235.964279,471.648682 232.814835,474.229523 
	C229.860504,476.650482 226.615707,478.725952 223.437119,480.861786 
	C220.202698,483.035126 216.901413,484.849091 214.286102,480.352081 
	C208.938644,482.772400 208.089447,482.574158 204.463135,478.567932 
	C203.358551,477.347656 202.142715,476.228027 200.834045,474.757385 
	C199.324921,472.806213 197.779572,471.275055 196.636230,469.486908 
	C194.902115,466.774841 193.197281,466.176422 190.222672,467.985870 
	C185.594315,470.801392 180.662033,473.117279 175.496002,475.615540 
	C168.278488,473.852722 162.430923,470.539642 158.156891,464.759247 
M166.580734,467.843781 
	C165.912125,467.858215 165.243515,467.872650 164.574905,467.887085 
	C164.728348,468.322357 164.881775,468.757629 165.035217,469.192902 
	C165.606201,468.929108 166.177185,468.665314 166.580734,467.843781 
z"/>
<path fill="#55B6D7" opacity="1.000000" stroke="none" 
	d="
M103.326752,468.958099 
	C99.474495,466.336334 95.913811,463.723083 92.445618,460.992340 
	C77.392090,449.139893 65.182213,434.599152 53.455742,419.608124 
	C53.261833,419.360260 53.215847,418.996674 53.410191,418.289734 
	C55.378048,417.183929 57.256969,416.766693 58.652344,415.710571 
	C62.346897,412.914185 64.666733,413.655182 65.704666,418.165741 
	C64.731918,419.683807 64.056648,421.036163 63.381382,422.388489 
	C64.608963,422.473541 65.844269,422.500671 67.061577,422.662842 
	C67.958389,422.782318 68.897575,422.939819 69.702415,423.322906 
	C71.677284,424.263062 73.706749,425.170349 75.485085,426.417633 
	C77.152184,427.586853 78.425354,429.306366 80.052544,430.546692 
	C82.136757,432.135376 84.287407,433.794617 86.683800,434.685059 
	C87.172661,434.866699 88.877739,431.775238 90.193443,430.169922 
	C90.364708,430.147217 90.709328,430.129059 90.953133,430.254272 
	C91.513252,430.815826 91.829559,431.252228 92.129120,432.020874 
	C92.406624,432.920959 92.700874,433.488739 92.946915,434.397003 
	C92.683975,439.110992 94.373833,442.537964 98.000305,445.002441 
	C97.626724,454.354340 99.644608,462.600586 110.372742,467.782501 
	C106.944450,468.359741 105.302681,468.636139 103.326752,468.958099 
z"/>
<path fill="#102532" opacity="1.000000" stroke="none" 
	d="
M337.131195,471.639587 
	C336.985352,471.357452 336.761566,471.166901 336.086914,470.766754 
	C335.185333,470.186768 334.656708,469.907928 334.128052,469.629028 
	C332.376160,468.351715 330.624268,467.074371 329.052979,465.494080 
	C330.802094,465.656311 332.370575,466.121490 333.022308,466.314819 
	C334.644897,465.180847 335.791565,464.492828 336.801575,463.642456 
	C337.860168,462.751251 339.754395,461.506317 339.601654,460.760345 
	C339.004089,457.842010 337.741577,455.059784 336.748535,452.179382 
	C336.770935,452.131805 336.875488,452.128052 336.923309,451.746887 
	C336.324493,446.573730 335.677856,441.781708 335.392700,437.003662 
	C337.257782,437.515839 338.761444,438.014069 340.265076,438.512268 
	C340.655121,438.184113 341.045166,437.855957 341.435211,437.527802 
	C339.852661,435.329651 338.270081,433.131531 336.996796,431.363007 
	C337.840332,429.366119 338.619080,427.522644 339.485565,425.610260 
	C339.573273,425.541382 339.796417,425.529510 339.856323,425.944824 
	C342.749084,437.682587 345.581940,449.005066 348.768341,461.740692 
	C353.524017,455.442474 357.563446,450.092865 361.605286,444.741455 
	C367.087036,447.353577 372.566315,449.967438 379.189331,453.126892 
	C378.428162,450.066132 378.112732,448.797760 377.853088,447.094208 
	C377.916473,445.788818 377.924072,444.918579 378.260620,443.959229 
	C380.093140,438.121521 379.843475,432.517273 377.933350,426.825317 
	C377.237793,424.752686 377.882721,422.230225 377.957825,419.954895 
	C377.999603,419.996765 378.083618,420.080017 378.093445,420.450134 
	C378.702026,422.895020 379.300781,424.969757 379.906311,427.444641 
	C381.390198,432.532959 382.867310,437.221130 384.289612,442.200012 
	C384.491608,442.997406 384.748444,443.504028 385.053162,444.370972 
	C385.769592,446.805328 386.438171,448.879456 387.065369,451.398865 
	C387.207611,453.919281 387.391174,455.994415 387.396515,458.352112 
	C386.876312,458.888519 386.534363,459.142365 386.152161,458.930023 
	C385.816467,456.106171 385.521057,453.748474 385.225616,451.390808 
	C384.463104,451.574341 383.700592,451.757843 382.938080,451.941376 
	C384.000214,454.448212 385.062317,456.955048 386.227905,459.716736 
	C386.220978,460.320740 386.110565,460.669830 385.717651,461.056213 
	C384.948303,461.368408 384.461487,461.643311 383.585541,461.894287 
	C381.169739,461.492218 379.143036,461.113983 377.116364,460.735748 
	C373.637512,458.901031 370.158691,457.066315 366.038330,454.929688 
	C364.193665,455.546722 362.990479,456.465668 361.491608,457.591125 
	C357.420135,461.790894 353.644379,465.784119 349.475525,469.797974 
	C345.602692,470.692963 342.122864,471.567291 338.643066,472.441620 
	C338.643066,472.441620 338.488312,472.407410 338.315430,472.234070 
	C337.805450,471.920349 337.468323,471.779968 337.131195,471.639587 
z"/>
<path fill="#5BBDDC" opacity="1.000000" stroke="none" 
	d="
M361.787323,457.384644 
	C362.990479,456.465668 364.193665,455.546722 365.694458,454.881195 
	C366.823914,456.840515 367.537903,458.617188 368.511139,460.238251 
	C370.857727,464.146820 373.768890,467.783997 375.615173,471.905640 
	C377.332642,475.739777 374.870361,480.946320 371.265869,483.105988 
	C370.983368,483.275269 370.658508,483.622131 370.416138,483.573486 
	C364.872070,482.461395 359.287750,481.483704 353.846771,479.987122 
	C352.913483,479.730408 352.598236,477.226837 351.996399,475.126831 
	C351.995361,474.492432 352.004883,474.003479 352.347046,473.951416 
	C359.142609,469.712463 363.124481,462.484650 361.787323,457.384644 
z"/>
<path fill="#5BBCDF" opacity="1.000000" stroke="none" 
	d="
M203.011597,484.985840 
	C203.209503,486.284790 203.194946,487.662781 203.643600,488.868408 
	C205.242538,493.165070 204.578964,494.541504 200.109543,494.869904 
	C198.341949,494.999725 196.520004,494.426636 194.725998,494.152679 
	C190.191513,493.460144 185.630600,492.895172 181.139694,491.985626 
	C179.408951,491.635101 176.639694,490.703430 176.454865,489.661896 
	C176.110123,487.719269 177.272934,485.491364 177.891281,483.406738 
	C178.071930,482.797760 178.533310,482.272064 178.929260,481.352112 
	C178.992508,480.996033 178.982315,480.984222 179.357483,480.977814 
	C183.254837,479.366364 186.843414,477.890167 190.280014,476.119080 
	C193.542984,474.437439 195.832764,475.488159 197.812668,478.151550 
	C198.526978,479.112457 199.280396,480.044281 200.292328,481.147583 
	C201.382996,482.532471 202.197296,483.759155 203.011597,484.985840 
z"/>
<path fill="#4BABCE" opacity="1.000000" stroke="none" 
	d="
M203.316711,485.158813 
	C202.197296,483.759155 201.382996,482.532471 200.289856,480.762115 
	C200.333298,478.499725 200.655579,476.781006 200.977844,475.062286 
	C202.142715,476.228027 203.358551,477.347656 204.463135,478.567932 
	C208.089447,482.574158 208.938644,482.772400 214.286102,480.352081 
	C216.901413,484.849091 220.202698,483.035126 223.437119,480.861786 
	C226.615707,478.725952 229.860504,476.650482 232.814835,474.229523 
	C235.964279,471.648682 238.328964,471.823212 241.445740,474.591644 
	C245.750549,478.415253 250.641388,481.579041 255.617905,485.466095 
	C254.651489,487.584564 253.348404,489.262329 251.747086,490.805481 
	C249.638733,489.443665 247.828598,488.216431 245.851044,486.690613 
	C244.749634,484.866821 243.891495,483.288452 242.866257,481.827332 
	C240.589691,478.582855 238.007553,477.511078 234.665100,481.135559 
	C230.563782,483.517853 226.777130,485.751923 222.990479,487.985992 
	C219.902573,488.320526 216.810394,488.620300 213.727829,488.998291 
	C209.723206,489.489380 206.260452,488.566833 203.316711,485.158813 
z"/>
<path fill="#5BBCDF" opacity="1.000000" stroke="none" 
	d="
M223.045990,488.332703 
	C226.777130,485.751923 230.563782,483.517853 235.049622,481.141663 
	C237.479752,481.968048 239.224609,482.912689 240.937714,483.911774 
	C242.647858,484.909210 244.326187,485.961182 246.018478,486.989227 
	C247.828598,488.216431 249.638733,489.443665 251.985886,490.820984 
	C252.522903,490.971100 253.001831,490.998352 253.001831,490.998352 
	C251.715134,496.699310 248.954987,499.146362 243.452057,498.966522 
	C235.121429,498.694214 227.103180,497.604218 223.045990,488.332703 
z"/>
<path fill="#5BBEDD" opacity="1.000000" stroke="none" 
	d="
M127.000000,454.000000 
	C126.249985,453.999939 125.499977,453.999908 124.157402,453.744843 
	C119.445549,450.432281 113.602798,448.809387 114.314217,441.844452 
	C118.262512,440.364075 119.500061,437.664215 119.400146,433.939148 
	C121.840744,432.592621 123.922508,431.299988 126.335976,430.005981 
	C127.109985,430.007812 127.552299,430.010986 128.043167,430.329834 
	C128.852814,432.475281 129.664337,433.892761 131.412567,431.000397 
	C133.284500,431.457092 134.746857,431.915039 136.182556,432.750122 
	C134.720367,438.058563 133.284821,442.989899 131.620270,448.167358 
	C130.570511,450.277649 129.749741,452.141815 128.684708,453.994019 
	C128.440445,453.982056 127.951668,454.000000 127.713745,454.000000 
	C127.475830,454.000000 127.000000,454.000000 127.000000,454.000000 
z"/>
<path fill="#5ABADB" opacity="1.000000" stroke="none" 
	d="
M113.996498,441.995178 
	C113.602798,448.809387 119.445549,450.432281 123.782379,453.745667 
	C122.444496,457.070099 119.811676,457.331757 116.845192,456.766296 
	C109.276665,455.323639 102.996552,451.828857 98.293022,445.172485 
	C94.373833,442.537964 92.683975,439.110992 93.293205,434.341644 
	C93.916534,433.898407 94.145355,433.850983 94.671951,433.893616 
	C95.965515,434.685211 96.961296,435.386749 97.976746,436.451782 
	C98.954193,438.014801 99.768837,439.373840 100.895622,440.385101 
	C104.849533,443.933716 109.581848,441.792358 113.996498,441.995178 
z"/>
<path fill="#131E2B" opacity="1.000000" stroke="none" 
	d="
M128.928970,454.005951 
	C129.749741,452.141815 130.570511,450.277649 131.955750,448.346191 
	C134.177582,448.917877 135.834961,449.556854 137.556366,450.549011 
	C139.154037,451.116028 140.882278,451.893066 142.186356,451.442505 
	C146.888916,449.817749 151.448578,447.779449 156.299637,445.800476 
	C156.083115,445.656281 156.287903,445.715179 156.339066,445.838043 
	C157.596771,448.858368 158.838181,451.885498 160.082520,454.911407 
	C153.688538,456.073975 158.735016,460.011230 158.028076,462.841522 
	C156.414413,460.874390 154.990631,458.664886 153.203247,455.903473 
	C152.074265,455.502869 151.308884,455.654175 150.543503,455.805481 
	C149.153915,456.290710 147.764328,456.776001 145.849655,457.455444 
	C145.070648,457.877930 144.816711,458.106293 144.562790,458.334625 
	C144.562790,458.334625 144.362427,458.206116 143.911926,458.269653 
	C141.742554,458.926788 140.033554,459.551056 138.303024,460.108521 
	C132.238007,462.062256 129.862564,460.507782 128.928970,454.005951 
z"/>
<path fill="#4BAAC9" opacity="1.000000" stroke="none" 
	d="
M276.067017,474.176605 
	C280.416138,468.900879 280.419647,468.895844 285.895264,472.728302 
	C286.980438,473.487823 288.225037,474.134613 289.059540,475.118805 
	C295.231476,482.397827 302.633972,480.244843 310.448059,477.858704 
	C312.311340,478.041321 313.799805,478.361908 315.282593,478.681274 
	C311.805267,486.973206 299.471863,489.767334 292.384857,483.837952 
	C290.100494,481.926697 287.828674,480.000488 285.547241,478.085724 
	C282.643066,475.648346 279.701538,474.598145 276.323547,477.536560 
	C276.015686,476.194305 276.041351,475.185455 276.067017,474.176605 
z"/>
<path fill="#122020" opacity="1.000000" stroke="none" 
	d="
M336.726196,452.226990 
	C337.741577,455.059784 339.004089,457.842010 339.601654,460.760345 
	C339.754395,461.506317 337.860168,462.751251 336.801575,463.642456 
	C335.791565,464.492828 334.644897,465.180847 333.022308,466.314819 
	C332.370575,466.121490 330.802094,465.656311 328.853333,465.225769 
	C326.862335,464.494202 325.251617,463.727966 323.297974,462.924164 
	C319.685944,461.361603 316.855652,461.198486 314.866333,464.987244 
	C314.460999,465.759277 313.121307,466.040771 311.959595,466.121033 
	C314.927521,461.408722 318.148132,457.123749 321.146515,453.134491 
	C326.932953,456.400360 332.120056,459.327972 338.528809,462.945099 
	C337.746277,458.292175 337.236237,455.259583 336.726196,452.226990 
z"/>
<path fill="#4BAAC9" opacity="1.000000" stroke="none" 
	d="
M157.979736,465.122498 
	C162.430923,470.539642 168.278488,473.852722 175.541306,475.996033 
	C176.960205,477.931946 177.971268,479.458099 178.982315,480.984222 
	C178.982315,480.984222 178.992508,480.996033 178.987976,480.989990 
	C170.636307,483.184174 163.995224,480.244232 159.230576,473.539703 
	C157.803192,471.531158 158.226929,468.207123 157.979736,465.122498 
z"/>
<path fill="#4BAAC9" opacity="1.000000" stroke="none" 
	d="
M128.684708,453.994019 
	C129.862564,460.507782 132.238007,462.062256 138.303024,460.108521 
	C140.033554,459.551056 141.742554,458.926788 143.810944,458.332642 
	C146.976105,462.078552 145.612183,464.365417 141.575409,465.702393 
	C139.848633,466.274292 138.103561,466.791473 136.380936,467.375214 
	C133.104996,468.485260 130.292648,468.228790 127.864220,465.330170 
	C125.350449,462.329590 126.098503,459.565033 127.902885,455.992554 
	C127.958366,454.869690 127.955017,454.434845 127.951668,454.000000 
	C127.951668,454.000000 128.440445,453.982056 128.684708,453.994019 
z"/>
<path fill="#3D9CC1" opacity="1.000000" stroke="none" 
	d="
M275.758881,474.292908 
	C276.041351,475.185455 276.015686,476.194305 275.987549,477.580383 
	C272.253357,481.462006 268.901154,485.561096 264.660248,488.267609 
	C261.694824,490.160034 257.514221,490.148315 253.439102,490.992737 
	C253.001831,490.998352 252.522903,490.971100 252.284103,490.955597 
	C253.348404,489.262329 254.651489,487.584564 255.968018,485.554138 
	C264.293182,484.890930 269.789886,479.502014 275.758881,474.292908 
z"/>
<path fill="#4BAAC9" opacity="1.000000" stroke="none" 
	d="
M377.073273,461.136414 
	C379.143036,461.113983 381.169739,461.492218 383.763550,462.094391 
	C384.887177,461.885223 385.443665,461.452087 386.000153,461.018982 
	C386.110565,460.669830 386.220978,460.320740 386.224670,459.679016 
	C386.117920,459.386414 386.192413,459.396210 386.192413,459.396210 
	C386.534363,459.142365 386.876312,458.888519 387.643677,458.245178 
	C388.382294,457.232544 388.695557,456.609406 389.008820,455.986267 
	C393.245789,457.833923 391.976044,461.085480 391.048340,464.614380 
	C388.892578,466.191711 386.930389,467.815399 384.681946,468.502716 
	C378.633514,470.351562 375.753143,467.593018 377.073273,461.136414 
z"/>
<path fill="#4BAAC9" opacity="1.000000" stroke="none" 
	d="
M352.004883,474.003479 
	C352.004883,474.003479 351.995361,474.492432 351.990814,474.736725 
	C348.514618,476.319611 345.123535,478.352448 341.544678,478.767944 
	C338.883942,479.076813 335.979828,477.289337 331.904510,476.044861 
	C335.137695,474.558807 336.689789,473.845428 338.442474,472.786835 
	C342.122864,471.567291 345.602692,470.692963 349.625183,470.112091 
	C350.780212,471.604889 351.392548,472.804199 352.004883,474.003479 
z"/>
<path fill="#4BAAC9" opacity="1.000000" stroke="none" 
	d="
M334.247986,469.921326 
	C334.656708,469.907928 335.185333,470.186768 335.865112,470.728668 
	C335.466797,470.732330 334.917358,470.472961 334.247986,469.921326 
z"/>
<path fill="#4BAAC9" opacity="1.000000" stroke="none" 
	d="
M150.963348,455.862396 
	C151.308884,455.654175 152.074265,455.502869 152.956604,455.666748 
	C152.510132,455.961060 151.946671,455.940186 150.963348,455.862396 
z"/>
<path fill="#5ABADB" opacity="1.000000" stroke="none" 
	d="
M127.713745,454.000000 
	C127.955017,454.434845 127.958366,454.869690 127.944016,455.629150 
	C127.639191,455.489838 127.352051,455.025848 127.032455,454.280945 
	C127.000000,454.000000 127.475830,454.000000 127.713745,454.000000 
z"/>
<path fill="#4BAAC9" opacity="1.000000" stroke="none" 
	d="
M144.845779,458.386658 
	C144.816711,458.106293 145.070648,457.877930 145.589111,457.635071 
	C145.612030,457.893250 145.370407,458.165955 144.845779,458.386658 
z"/>
<path fill="#4BAAC9" opacity="1.000000" stroke="none" 
	d="
M337.244720,471.908203 
	C337.468323,471.779968 337.805450,471.920349 338.277466,472.303650 
	C338.060974,472.423279 337.709595,472.300079 337.244720,471.908203 
z"/>
<path fill="#5FBFDF" opacity="1.000000" stroke="none" 
	d="
M347.556702,32.999813 
	C350.947540,33.208244 354.059692,34.121586 356.805420,33.476074 
	C362.229462,32.200893 365.101501,33.659233 366.047455,39.018112 
	C366.411987,41.082943 367.312225,43.053211 367.607544,45.509773 
	C366.085022,46.297626 364.923370,46.642269 363.580505,47.081219 
	C363.169342,47.345093 363.023743,47.569405 362.816101,48.095200 
	C362.079590,48.806637 361.489349,49.271332 360.648193,49.484802 
	C359.932312,48.066628 359.193542,46.932636 359.048462,45.727188 
	C358.301605,39.522018 357.210846,38.418499 351.179443,37.965099 
	C349.710785,37.854694 348.233093,37.563408 346.812164,37.166782 
	C336.297760,34.231899 327.185089,43.973064 329.095886,53.376842 
	C329.564941,55.685158 331.005096,57.796146 331.576660,59.998444 
	C328.433868,59.331463 325.713287,58.663895 322.976135,57.623779 
	C322.305573,55.167522 321.651550,53.083813 321.358398,50.889236 
	C322.479675,48.190159 323.238739,45.601543 324.000885,43.013851 
	C324.583313,41.036446 327.326721,38.952961 324.031891,37.105484 
	C321.024780,35.419338 318.185333,35.233059 315.823883,38.563019 
	C313.735046,41.508553 311.287781,44.199913 308.588806,47.002644 
	C305.454407,47.002167 302.729919,47.002552 299.590454,47.002789 
	C296.785278,46.669086 294.395081,46.335533 291.943970,45.648293 
	C290.336456,44.264091 288.658234,42.221363 287.269165,42.402027 
	C283.776520,42.856277 280.393311,44.251644 277.006012,45.388199 
	C273.644989,46.515938 270.332550,47.788418 266.784485,48.744370 
	C263.351624,46.185841 260.279327,43.633171 256.885193,41.624256 
	C248.825073,36.853642 240.654541,36.634163 235.167740,45.957485 
	C234.001511,47.939201 230.727615,48.670967 228.440033,50.004822 
	C225.621338,51.648380 222.818619,53.319366 219.734833,55.228973 
	C216.639740,55.318649 213.818787,55.157845 210.899078,54.649040 
	C207.770248,53.020016 204.740173,51.738983 201.027100,50.169205 
	C201.027100,52.539700 201.027100,54.307777 200.845520,56.288040 
	C200.153275,56.696461 199.642593,56.892700 198.861267,56.960884 
	C198.060593,56.554596 197.530609,56.276352 197.002625,55.605011 
	C197.113998,53.638817 197.223343,52.065720 197.379929,49.813080 
	C194.681549,50.160198 192.449066,50.694286 190.222488,50.670757 
	C187.493332,50.641914 183.285522,51.134571 182.312347,49.673306 
	C178.980331,44.670101 174.481247,44.914398 169.443985,44.930389 
	C168.472366,43.837593 167.735077,42.924137 166.998932,41.757416 
	C166.999374,41.335304 166.998672,41.166458 166.997986,40.997616 
	C176.388275,39.333187 185.733109,37.274006 195.189682,36.185249 
	C199.375656,35.703300 203.777191,37.303310 208.104691,37.694996 
	C209.202515,37.794361 210.883545,37.224121 211.459167,36.386253 
	C215.322586,30.762745 215.249268,30.712370 220.555939,35.492287 
	C220.233887,33.852234 219.911835,32.212181 219.461899,29.920799 
	C220.729843,29.627691 222.060303,29.177752 223.423676,29.028530 
	C231.165115,28.181240 238.912735,27.388720 246.662949,26.625309 
	C247.764404,26.516815 249.265564,26.210775 249.949432,26.769749 
	C257.698822,33.103951 266.509003,29.172577 274.863922,29.589529 
	C276.802948,29.686296 278.861298,30.129803 280.600739,30.964180 
	C285.448120,33.289391 289.565186,36.491356 295.770508,33.013840 
	C299.578033,30.880079 304.950256,31.605328 309.590027,30.860882 
	C311.845642,30.498972 314.032867,29.710741 317.017029,28.994522 
	C319.883392,28.657013 322.118744,27.872070 324.058289,28.332705 
	C329.437683,29.610294 334.693878,31.406523 339.998779,32.997810 
	C342.369965,32.998516 344.741180,32.999226 347.556702,32.999813 
z"/>
<path fill="#5DC2E3" opacity="1.000000" stroke="none" 
	d="
M339.919189,32.655869 
	C334.693878,31.406523 329.437683,29.610294 324.058289,28.332705 
	C322.118744,27.872070 319.883392,28.657013 317.392120,28.936138 
	C318.213318,25.533787 319.594574,22.115997 320.578094,18.587337 
	C321.235748,16.227697 322.150757,13.993167 324.768066,15.349824 
	C326.389862,16.190477 328.258453,18.710421 328.146667,20.349007 
	C327.762512,25.980003 328.433136,27.267635 333.945435,29.064457 
	C336.040619,29.747417 337.882355,31.207855 339.919189,32.655869 
z"/>
<path fill="#5DC2E3" opacity="1.000000" stroke="none" 
	d="
M311.222137,20.201855 
	C313.094818,19.607319 314.875763,19.393923 316.656738,19.180527 
	C316.242126,20.637171 316.254761,22.906368 315.329895,23.392672 
	C312.901825,24.669420 311.211090,23.515812 311.222137,20.201855 
z"/>
<path fill="#62BFDB" opacity="1.000000" stroke="none" 
	d="
M156.003510,40.886108 
	C156.003510,40.886108 156.001144,40.442795 155.999969,40.221130 
	C159.198303,40.118008 162.397827,40.236553 165.727844,40.762562 
	C165.858322,41.170029 165.711349,41.346184 165.276642,41.305519 
	C161.895782,41.138603 158.949646,41.012356 156.003510,40.886108 
z"/>
<path fill="#62BFDB" opacity="1.000000" stroke="none" 
	d="
M166.750793,40.997894 
	C166.998672,41.166458 166.999374,41.335304 166.748169,41.728584 
	C166.234619,41.750736 165.972977,41.548462 165.711349,41.346184 
	C165.711349,41.346184 165.858322,41.170029 165.933807,41.081055 
	C166.009293,40.992081 166.503616,40.998169 166.750793,40.997894 
z"/>
<path fill="#56BBDD" opacity="1.000000" stroke="none" 
	d="
M126.004272,430.007324 
	C123.922508,431.299988 121.840744,432.592621 119.215378,433.637451 
	C117.463066,432.669617 115.908203,431.057037 115.100243,431.370361 
	C108.462517,433.944611 102.265953,432.369141 95.784607,429.832336 
	C94.674927,428.768524 93.856667,427.829865 92.975876,426.716858 
	C92.791809,426.320801 92.619949,426.149597 92.243805,425.818115 
	C92.046555,425.438812 92.003242,425.270264 91.910461,424.688782 
	C91.563301,421.652344 91.493484,418.973846 90.879593,416.426514 
	C90.529907,414.975494 89.523682,413.425415 88.344757,412.552399 
	C87.841110,412.179443 86.142769,413.419800 84.844688,414.249939 
	C84.257629,416.337402 83.813171,418.101959 82.993164,419.935669 
	C80.313080,420.346497 77.922821,421.241302 75.724098,420.900452 
	C72.413139,420.387177 69.236099,419.009949 66.002136,418.000000 
	C64.666733,413.655182 62.346897,412.914185 58.652344,415.710571 
	C57.256969,416.766693 55.378048,417.183929 53.355335,417.946106 
	C48.839542,412.289825 44.123032,406.900269 40.662170,400.798462 
	C34.730427,390.340210 29.583685,379.436676 24.243481,368.172607 
	C23.920082,367.083405 23.460535,366.541687 23.000988,366.000000 
	C22.671495,365.221741 22.342005,364.443481 22.005333,363.333191 
	C25.983332,362.025726 29.968515,361.050293 35.208439,359.767731 
	C34.611034,362.599152 34.398476,364.592316 33.763580,366.440430 
	C31.361031,373.433960 32.844978,377.346741 39.681904,381.184113 
	C39.999954,382.111115 39.999901,382.555542 40.036266,383.289734 
	C40.382191,384.053345 40.691696,384.527283 41.013046,385.345062 
	C41.680161,386.457123 42.335423,387.225372 43.179703,388.214478 
	C43.908901,388.620850 44.449085,388.806305 45.010841,389.301392 
	C45.052498,389.816895 45.072582,390.022766 44.968201,390.496796 
	C44.207134,391.515442 43.570545,392.265900 42.806885,393.093750 
	C42.463295,393.432220 42.481541,393.686005 42.815357,394.291687 
	C43.923992,396.407990 44.951828,398.165070 46.062477,400.069702 
	C46.145290,400.217194 46.574722,400.382172 46.574722,400.382172 
	C46.574722,400.382172 46.974262,400.116211 47.320450,400.022156 
	C48.918930,399.327179 50.171227,398.726227 51.560783,398.515320 
	C53.651958,401.928375 54.769665,406.313171 60.333321,403.974426 
	C60.890404,403.922638 61.112938,403.898895 61.519775,403.999390 
	C61.907375,404.358185 62.011055,404.630676 62.154049,405.173279 
	C62.578220,405.625549 62.863461,405.845612 63.404388,406.095551 
	C64.095131,406.388275 64.530190,406.651154 65.202896,407.164795 
	C66.660683,407.200867 67.880821,406.986115 69.323288,406.541168 
	C72.708397,403.941772 75.739471,401.357300 79.095497,399.303925 
	C80.540527,398.419800 82.819870,398.034088 84.440788,398.467255 
	C89.038658,399.696045 93.492439,401.463989 98.007111,403.464020 
	C98.012894,405.698700 97.771545,407.530182 98.066963,409.270599 
	C98.672562,412.838501 99.817871,416.160248 104.296333,416.998474 
	C104.860374,417.040131 105.183258,417.052399 105.758904,417.302246 
	C108.336861,418.758972 110.662766,419.959381 113.053177,421.502014 
	C115.410538,427.006622 119.293884,429.791901 125.026695,429.829926 
	C125.352936,429.832062 125.678429,429.945618 126.004272,430.007324 
M96.009323,419.939880 
	C97.466499,419.831665 98.923676,419.723480 100.380852,419.615265 
	C100.441238,419.098267 100.501625,418.581268 100.562012,418.064270 
	C98.907303,417.574738 97.252586,417.085236 95.597862,416.595734 
	C95.506645,417.660248 95.415428,418.724731 96.009323,419.939880 
M39.544121,391.754333 
	C39.812031,391.941895 40.079941,392.129486 40.347851,392.317047 
	C40.176075,392.038727 40.004303,391.760376 39.544121,391.754333 
z"/>
<path fill="#62BEDB" opacity="1.000000" stroke="none" 
	d="
M30.996994,206.004288 
	C30.997894,204.936920 30.998796,203.869537 30.999027,202.401733 
	C30.999773,201.250214 31.001188,200.499130 31.365553,199.319672 
	C33.149670,198.593857 34.570835,198.296432 35.992004,197.999008 
	C36.744358,198.000015 37.496716,198.001022 38.678020,198.347626 
	C39.914997,201.696045 40.876453,204.669983 41.450783,207.716888 
	C41.661839,208.836578 40.957615,210.128769 40.565514,211.664246 
	C39.080811,213.665100 37.701488,215.344025 35.922375,217.030975 
	C34.736778,218.140686 33.950974,219.242355 33.165169,220.344009 
	C34.364201,220.437149 35.563232,220.530289 37.088436,220.687775 
	C38.535946,221.685196 40.023640,222.405182 40.712643,223.589722 
	C43.167767,227.810638 45.499252,225.258240 47.255756,223.331619 
	C50.348339,219.939514 49.708370,216.153336 46.158867,211.848358 
	C48.634377,208.040680 50.842442,204.450119 53.444405,200.879791 
	C57.279678,201.835403 61.772572,201.670929 61.630836,206.920822 
	C61.523907,210.881561 63.866127,211.942093 66.985733,212.633911 
	C68.402390,212.948074 69.766426,213.499557 71.203171,214.306000 
	C73.567413,219.423569 75.882820,224.178528 77.804695,229.100052 
	C73.326942,229.359528 69.204956,229.824417 65.171753,229.414902 
	C62.472473,229.140854 60.963871,229.280655 60.498795,232.160309 
	C52.391750,229.058182 44.958981,231.998047 39.683403,239.861115 
	C38.071625,239.860489 36.756924,239.989822 34.871811,240.175278 
	C35.896309,242.031219 36.515507,243.152939 37.136703,244.592499 
	C37.225910,245.317383 37.313118,245.724457 37.276722,246.361298 
	C37.047287,246.729126 36.941460,246.867188 36.474159,246.943176 
	C31.332211,244.064957 28.742464,249.085632 25.001263,249.987228 
	C25.000889,248.922348 25.000515,247.857468 25.152431,246.076385 
	C26.830542,244.244034 28.225574,242.485931 29.908922,242.142990 
	C33.618340,241.387268 34.503769,239.135941 33.940994,236.030365 
	C33.057087,231.152649 35.128754,225.402985 29.708918,221.736740 
	C28.967241,221.235046 28.900423,219.234543 29.058239,217.996460 
	C29.569965,213.981918 30.331604,209.999237 30.996994,206.004288 
z"/>
<path fill="#62BFDB" opacity="1.000000" stroke="none" 
	d="
M35.838829,334.049744 
	C42.517113,335.179016 44.734482,333.977112 47.749535,327.594482 
	C56.242416,330.690216 64.735672,333.784821 73.468231,337.247986 
	C72.908821,339.132385 72.110107,340.648163 71.192245,342.390076 
	C74.603455,343.602325 79.037773,338.108978 80.507401,344.302490 
	C76.149910,342.469391 73.405144,344.025726 71.678558,347.672729 
	C70.410141,350.351990 68.826256,353.082703 68.437065,355.934387 
	C67.692856,361.387421 64.995834,363.509308 59.598488,364.411346 
	C51.520664,365.761322 48.669193,370.150757 49.668274,376.996460 
	C48.891273,376.995544 48.447292,376.990875 48.003311,376.986237 
	C43.573799,371.283966 40.421967,365.248322 43.391094,357.174652 
	C44.059162,356.515564 44.313343,356.405396 44.916260,356.431671 
	C46.756298,356.642426 48.247597,356.716736 50.086792,356.804230 
	C52.424793,355.463013 54.113659,354.058960 51.090729,351.636322 
	C50.293201,350.569427 49.649670,349.769745 48.964668,348.666016 
	C48.284565,347.929352 47.645935,347.496765 47.007309,347.064178 
	C43.427917,345.346222 39.848522,343.628296 35.865562,341.426727 
	C35.587608,338.645325 35.713219,336.347534 35.838829,334.049744 
z"/>
<path fill="#5DC6E6" opacity="1.000000" stroke="none" 
	d="
M80.858109,344.385223 
	C79.037773,338.108978 74.603455,343.602325 71.192245,342.390076 
	C72.110107,340.648163 72.908821,339.132385 73.850449,337.308899 
	C76.006042,334.997864 78.018723,332.994476 80.602814,330.737579 
	C81.459702,330.689880 81.745171,330.895721 82.297897,331.327087 
	C89.052444,333.319946 90.553329,339.434967 91.511116,344.344818 
	C92.711166,350.496582 96.057358,351.661530 101.330185,352.147461 
	C104.107506,352.874969 106.551666,353.443268 109.120834,354.216187 
	C109.441063,354.686157 109.691963,354.879028 109.828751,355.270020 
	C109.447975,356.356659 109.236992,357.172821 108.827881,358.147186 
	C108.978287,358.875214 109.326836,359.445068 109.894562,360.264465 
	C110.273689,360.667206 110.433632,360.820343 110.527847,361.168640 
	C110.531746,361.632385 110.555817,361.905029 110.322647,362.484711 
	C108.673157,362.514435 107.243568,362.182587 105.795921,361.979431 
	C102.201973,361.475128 98.673782,357.815369 94.612869,361.830322 
	C93.510933,361.887512 92.770340,361.833008 91.973854,361.507629 
	C91.369095,360.814056 90.820213,360.391296 90.048676,359.725220 
	C89.516258,358.670654 89.206505,357.859467 89.127563,356.829529 
	C88.372948,355.124725 87.387505,353.638641 86.240280,352.011810 
	C85.979813,351.470795 85.881119,351.070557 85.798462,350.286865 
	C85.351776,349.389557 84.889069,348.875671 84.523544,348.368225 
	C84.620720,348.374695 84.436089,348.436798 84.534195,348.087341 
	C84.000015,347.191345 83.367722,346.644806 82.496262,345.995697 
	C81.790771,345.390472 81.324440,344.887848 80.858109,344.385223 
z"/>
<path fill="#172831" opacity="1.000000" stroke="none" 
	d="
M51.985779,321.027130 
	C50.493637,320.862183 48.783737,320.139709 47.544994,320.623535 
	C39.997440,323.571503 36.626919,322.647064 32.159199,315.996613 
	C31.710293,315.328430 31.105644,314.764862 30.121597,313.678894 
	C28.843718,311.578735 28.017519,309.952423 27.516817,308.389954 
	C29.502640,307.604919 31.162966,306.756104 33.082069,305.740967 
	C33.503128,305.203369 33.665405,304.832062 33.825611,304.317383 
	C33.823544,304.173981 34.054245,304.003601 34.452713,304.008362 
	C36.290108,304.756744 37.729031,305.500397 39.051033,306.579712 
	C37.841274,309.106873 35.245323,311.507965 38.849258,313.445679 
	C40.383419,314.270508 43.146336,314.677032 44.367958,313.839661 
	C47.187778,311.906891 46.525791,309.076172 44.538837,306.587128 
	C44.334942,306.331726 44.145546,306.064758 43.963257,305.441437 
	C43.416698,304.047089 42.769962,303.050751 42.317383,301.972870 
	C41.728951,300.571411 41.294804,299.105164 40.816750,297.720581 
	C40.839241,297.774323 40.723370,297.786865 40.993999,297.729065 
	C41.513790,297.408752 41.762951,297.146271 42.152504,296.683685 
	C42.719589,296.268188 43.146275,296.052734 44.027874,295.874756 
	C49.440800,296.641357 53.260284,295.647064 53.546829,288.403931 
	C55.045811,290.227234 55.779285,291.119385 56.463764,292.343048 
	C55.597481,295.124756 54.780193,297.574951 53.767399,300.179321 
	C53.348763,300.586975 53.190266,300.875885 53.091454,301.412811 
	C53.086502,301.625366 52.932407,302.021637 52.679573,302.323273 
	C52.466763,308.763458 48.059780,314.795471 51.985779,321.027130 
z"/>
<path fill="#5FBBD8" opacity="1.000000" stroke="none" 
	d="
M30.649490,206.057739 
	C30.331604,209.999237 29.569965,213.981918 29.058239,217.996460 
	C28.900423,219.234543 28.967241,221.235046 29.708918,221.736740 
	C35.128754,225.402985 33.057087,231.152649 33.940994,236.030365 
	C34.503769,239.135941 33.618340,241.387268 29.908922,242.142990 
	C28.225574,242.485931 26.830542,244.244034 25.152233,245.676712 
	C24.104259,245.512802 23.208780,245.032349 22.156178,244.275345 
	C21.619400,242.426315 21.222494,240.857727 20.865999,239.280029 
	C20.618483,238.184631 19.860228,236.487946 20.298416,236.066422 
	C24.188606,232.324051 22.597193,226.922989 24.581108,222.611816 
	C25.494762,220.626404 26.056341,218.065277 25.661810,215.975906 
	C24.785557,211.335312 26.574085,208.371674 30.649490,206.057739 
z"/>
<path fill="#172831" opacity="1.000000" stroke="none" 
	d="
M19.384525,264.271179 
	C19.292873,263.680542 19.201220,263.089874 19.006926,261.848022 
	C18.884642,260.134003 18.864996,259.071228 18.845356,258.008423 
	C20.374779,256.088562 21.904202,254.168686 24.154785,252.111084 
	C28.584396,252.320663 32.292847,252.667984 36.147087,253.297058 
	C37.192085,254.107025 38.091297,254.635193 39.010864,255.517853 
	C40.217632,258.675537 41.404045,261.478790 42.248077,264.257172 
	C38.453117,262.303284 35.127407,260.051147 31.479797,258.618713 
	C30.186073,258.110687 28.108753,259.598022 26.389156,260.174408 
	C27.160233,261.683685 27.537540,263.853912 28.770269,264.588287 
	C34.230934,267.841492 39.937271,270.682312 45.112965,273.750183 
	C41.779106,274.216064 38.886993,274.604156 35.938568,274.642151 
	C32.860630,272.895630 29.839008,271.499207 26.506971,269.981384 
	C23.925882,267.997040 21.655205,266.134094 19.384525,264.271179 
z"/>
<path fill="#56C1E5" opacity="1.000000" stroke="none" 
	d="
M47.699432,377.055908 
	C48.447292,376.990875 48.891273,376.995544 49.950462,377.173340 
	C53.935787,380.285828 57.305912,383.225159 60.872253,386.542358 
	C61.202003,388.554962 61.335529,390.189697 61.128418,392.102203 
	C60.594791,394.129913 60.401810,395.879913 60.286514,397.687622 
	C60.364208,397.745361 60.282013,397.920685 59.992744,398.151550 
	C59.988628,399.617065 60.273785,400.851685 60.559380,402.152039 
	C60.559822,402.217743 60.433998,402.255646 60.299801,402.686005 
	C60.109997,403.411743 60.054386,403.707092 59.998779,404.002441 
	C54.769665,406.313171 53.651958,401.928375 51.595787,398.173889 
	C52.466450,393.905182 51.149681,391.403717 47.924561,389.445587 
	C46.892937,389.105072 45.941101,389.048401 44.989265,388.991760 
	C44.449085,388.806305 43.908901,388.620850 43.183033,387.966431 
	C42.997349,387.497498 43.001373,387.001221 43.328743,386.956360 
	C44.630661,386.363495 45.605206,385.815521 46.579754,385.267548 
	C46.460125,384.999939 46.340500,384.732330 46.220871,384.464752 
	C45.146236,384.644714 44.071602,384.824707 42.747726,385.003662 
	C42.498482,385.002655 42.000000,385.000000 42.000107,384.666504 
	C41.999840,383.888306 41.999462,383.443604 41.999542,382.999451 
	C42.000000,383.000000 41.999306,382.998779 41.999306,382.998779 
	C45.339787,382.457581 46.740417,380.134064 47.699432,377.055908 
z"/>
<path fill="#1B3640" opacity="1.000000" stroke="none" 
	d="
M27.191326,308.326111 
	C28.017519,309.952423 28.843718,311.578735 29.823734,313.506714 
	C22.276953,314.059357 17.714203,308.069641 20.222317,300.937408 
	C21.032974,298.632202 22.010948,296.385803 23.010954,293.863953 
	C21.006115,292.574921 19.108341,291.354706 17.126789,289.469910 
	C18.103436,288.293457 19.123709,287.481262 20.232738,287.333008 
	C22.465952,287.034424 24.747501,287.097412 27.373547,287.014160 
	C28.614025,287.786682 30.302299,288.647034 30.228195,289.310486 
	C29.870945,292.509064 31.765657,292.909851 33.986507,293.361816 
	C31.605904,296.752686 29.075504,299.674194 27.224686,302.976532 
	C26.461508,304.338226 27.158485,306.518280 27.191326,308.326111 
z"/>
<path fill="#122020" opacity="1.000000" stroke="none" 
	d="
M52.183548,321.325256 
	C48.059780,314.795471 52.466763,308.763458 52.739052,302.742615 
	C53.765820,306.024689 54.043354,309.392273 55.380531,312.266968 
	C56.041157,313.687164 58.656342,314.198181 60.387146,315.120605 
	C60.578129,313.525452 60.898876,311.933258 60.935295,310.334564 
	C61.036648,305.885315 61.019943,301.433380 61.288353,296.990540 
	C61.526844,296.998566 62.004135,297.000000 62.058601,297.354736 
	C62.919991,299.451080 63.726917,301.192719 64.618835,303.356812 
	C65.301018,307.172516 65.898209,310.565796 66.378021,314.309326 
	C66.078270,315.620941 65.895905,316.582306 65.393112,317.680908 
	C62.378254,319.527924 59.683834,321.237640 56.989418,322.947327 
	C55.453384,322.506012 53.917355,322.064728 52.183548,321.325256 
z"/>
<path fill="#091318" opacity="1.000000" stroke="none" 
	d="
M82.030640,331.101593 
	C81.745171,330.895721 81.459702,330.689880 80.908951,330.521179 
	C79.989632,330.404175 79.335602,330.250031 78.129822,329.749023 
	C74.761711,329.082794 72.239456,328.379913 71.907761,324.701355 
	C72.327568,323.950714 72.635094,323.465973 73.249008,322.931335 
	C74.521233,321.953644 75.487061,321.025848 76.512154,320.486511 
	C76.616142,321.922028 76.660873,322.969116 76.549744,324.216217 
	C76.676216,324.908508 76.958557,325.400787 77.569443,325.997925 
	C80.553062,325.170685 84.239716,324.962219 85.553505,323.089264 
	C86.710716,321.439545 85.215904,317.929596 84.775108,314.961090 
	C84.302612,314.510681 83.947235,314.340271 83.426926,314.182007 
	C83.261993,314.194153 83.001030,313.990936 82.998962,313.646637 
	C82.318573,312.168243 81.640251,311.034180 81.337868,309.815063 
	C82.473129,309.815552 83.232460,309.901123 84.238289,310.229980 
	C85.152542,310.568085 85.820290,310.662903 86.701279,310.926575 
	C87.608894,311.586548 88.303253,312.077667 89.262527,312.869965 
	C89.944412,316.093018 90.361374,319.014862 90.567177,322.177795 
	C89.206535,324.117065 88.057045,325.815338 86.756248,327.737122 
	C87.451645,328.104401 88.238823,328.520142 89.018906,328.968170 
	C89.011803,329.000427 89.072540,328.974274 89.108063,329.324890 
	C89.149651,329.910370 89.155724,330.145233 88.761124,330.369202 
	C86.250519,330.606049 84.140579,330.853821 82.030640,331.101593 
z"/>
<path fill="#E3B424" opacity="1.000000" stroke="none" 
	d="
M35.567047,333.921295 
	C35.713219,336.347534 35.587608,338.645325 35.454929,341.373871 
	C34.476871,342.433624 33.505875,343.062653 31.938583,343.725433 
	C29.284012,342.665100 27.225737,341.570984 25.167463,340.476868 
	C25.113194,339.549011 25.058922,338.621155 25.118324,337.107849 
	C25.225639,336.329620 25.219276,336.136871 25.212919,335.944092 
	C26.306078,334.351624 27.399235,332.759155 29.110004,331.087524 
	C30.487669,331.056000 31.247721,331.103638 32.007767,331.151306 
	C33.103596,332.031830 34.199429,332.912354 35.567047,333.921295 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M27.009108,287.011230 
	C24.747501,287.097412 22.465952,287.034424 20.232738,287.333008 
	C19.123709,287.481262 18.103436,288.293457 16.965155,289.199646 
	C15.823550,288.519806 14.759798,287.445557 13.449514,285.905243 
	C13.112119,285.299011 13.021256,285.158875 12.930393,285.018707 
	C12.944121,284.559418 12.957850,284.100159 12.961517,282.947296 
	C14.611428,275.500244 16.883305,273.877228 25.110985,273.980713 
	C26.464422,274.629059 27.617319,275.007080 28.883589,275.422302 
	C28.251080,275.917389 27.666094,276.375275 26.755129,277.008667 
	C24.377501,279.180969 20.769087,281.056732 20.602446,283.200043 
	C20.268747,287.491974 24.830782,284.932007 27.001091,286.304535 
	C27.004267,286.540100 27.009108,287.011230 27.009108,287.011230 
z"/>
<path fill="#5EC2E0" opacity="1.000000" stroke="none" 
	d="
M24.910446,273.710358 
	C16.883305,273.877228 14.611428,275.500244 12.841768,282.620697 
	C12.461802,281.929749 12.079942,280.885742 11.946229,279.810822 
	C11.720266,277.994293 11.402505,276.131348 11.596172,274.339020 
	C11.747439,272.939056 12.316367,271.352783 13.229700,270.316376 
	C14.952252,268.361664 17.056602,266.743439 19.193388,264.628235 
	C21.655205,266.134094 23.925882,267.997040 26.622898,270.271027 
	C26.336308,271.691528 25.623379,272.700928 24.910446,273.710358 
z"/>
<path fill="#4791AB" opacity="1.000000" stroke="none" 
	d="
M71.795479,324.967224 
	C72.239456,328.379913 74.761711,329.082794 77.793747,329.696777 
	C72.425003,332.128265 68.056282,328.726196 63.418686,326.709412 
	C61.357872,325.813171 59.458725,324.545166 57.237808,323.196625 
	C59.683834,321.237640 62.378254,319.527924 65.471184,318.148743 
	C65.480713,322.801392 66.251724,326.063934 71.795479,324.967224 
z"/>
<path fill="#65C6E1" opacity="1.000000" stroke="none" 
	d="
M36.001293,253.015289 
	C32.292847,252.667984 28.584396,252.320663 24.442387,251.930588 
	C24.195377,251.372513 24.381926,250.857178 24.784868,250.164551 
	C28.742464,249.085632 31.332211,244.064957 36.654011,247.229279 
	C37.372997,247.991241 37.550663,248.405045 37.537807,249.152893 
	C36.898621,250.663040 36.449955,251.839157 36.001293,253.015289 
z"/>
<path fill="#8CB8A0" opacity="1.000000" stroke="none" 
	d="
M46.656670,347.126007 
	C47.645935,347.496765 48.284565,347.929352 48.577698,348.689636 
	C42.894833,349.896301 42.460377,350.759125 44.567520,356.295227 
	C44.313343,356.405396 44.059162,356.515564 43.410851,356.755676 
	C40.183750,351.237640 40.658783,349.837128 46.656670,347.126007 
z"/>
<path fill="#62BFDB" opacity="1.000000" stroke="none" 
	d="
M25.128641,340.894043 
	C27.225737,341.570984 29.284012,342.665100 31.680252,343.876709 
	C31.089920,344.800171 30.161625,345.606171 29.233326,346.412140 
	C27.852154,344.711823 26.470987,343.011505 25.128641,340.894043 
z"/>
<path fill="#5EC2E0" opacity="1.000000" stroke="none" 
	d="
M18.536642,258.101990 
	C18.864996,259.071228 18.884642,260.134003 18.842983,261.590942 
	C18.597097,260.721924 18.412514,259.458710 18.536642,258.101990 
z"/>
<path fill="#59BADC" opacity="1.000000" stroke="none" 
	d="
M11.810226,261.538574 
	C12.455524,261.591309 12.913882,261.831177 13.372242,262.071014 
	C13.177923,262.280029 12.851766,262.693604 12.809649,262.666473 
	C12.387450,262.394348 12.011949,262.049805 11.810226,261.538574 
z"/>
<path fill="#62BFDB" opacity="1.000000" stroke="none" 
	d="
M31.953835,330.829773 
	C31.247721,331.103638 30.487669,331.056000 29.366398,330.883575 
	C29.970087,330.675293 30.934994,330.591766 31.953835,330.829773 
z"/>
<path fill="#59B9D9" opacity="1.000000" stroke="none" 
	d="
M39.999847,383.000000 
	C39.999901,382.555542 39.999954,382.111115 39.999928,381.333313 
	C40.624939,381.461426 41.250031,381.922852 41.937214,382.691528 
	C41.999306,382.998779 42.000000,383.000000 41.666351,382.999573 
	C40.888416,382.999451 40.444130,382.999725 39.999847,383.000000 
z"/>
<path fill="#5EC2E0" opacity="1.000000" stroke="none" 
	d="
M12.821186,285.142090 
	C13.021256,285.158875 13.112119,285.299011 13.205509,285.689636 
	C12.806792,285.852722 12.641439,285.627808 12.821186,285.142090 
z"/>
<path fill="#62BFDB" opacity="1.000000" stroke="none" 
	d="
M25.043024,336.021606 
	C25.219276,336.136871 25.225639,336.329620 25.069588,336.761932 
	C24.813732,336.790405 24.695505,336.585388 24.641991,336.364655 
	C24.626005,336.298706 24.791039,336.188873 25.043024,336.021606 
z"/>
<path fill="#5DC2E3" opacity="1.000000" stroke="none" 
	d="
M467.004089,380.567444 
	C466.837189,380.999695 466.669525,381.000641 465.971863,381.160400 
	C464.958679,382.222992 464.475494,383.126770 463.678284,384.052063 
	C462.595428,384.395416 461.826569,384.717194 460.750488,384.853760 
	C459.268829,381.723358 458.094391,378.778168 457.020325,375.510071 
	C457.795135,374.461212 458.360565,373.571106 459.163116,373.037903 
	C462.437378,370.862488 464.269470,367.508270 462.428009,364.267639 
	C461.014191,361.779572 457.724091,359.950562 454.875732,358.803192 
	C452.210358,357.729492 449.028595,357.937775 445.992340,357.323364 
	C445.324524,356.697723 444.738434,356.330566 444.010956,355.753418 
	C443.802246,355.216614 443.734924,354.889771 443.683777,354.263794 
	C443.482056,353.642090 443.264099,353.319519 442.868317,352.728790 
	C442.454407,351.640503 442.218292,350.820404 442.113953,349.882263 
	C442.038574,349.571228 441.831451,349.378204 441.460144,348.938843 
	C441.143616,348.123779 440.991333,347.554962 440.985016,346.880737 
	C440.913208,346.611633 440.695435,346.447998 440.326416,346.087463 
	C440.035583,345.419525 439.895996,344.948517 439.722900,344.181763 
	C439.415924,343.613739 439.142456,343.341461 438.630188,342.876129 
	C437.221863,341.543579 436.052399,340.404053 435.108978,338.963104 
	C441.591553,334.311523 452.783203,333.986053 455.492981,340.891510 
	C455.458923,340.991669 455.250549,341.028412 454.946411,341.045227 
	C454.449097,341.734192 454.255951,342.406281 454.128479,343.389465 
	C454.172974,344.332703 454.151703,344.964874 454.160400,345.871826 
	C454.407196,346.433899 454.624023,346.721191 454.959534,347.127075 
	C455.078247,347.245728 455.113007,347.579590 455.247986,347.893433 
	C455.775543,348.419281 456.168060,348.631317 456.875671,348.791504 
	C457.811707,348.872528 458.432709,349.005402 459.299225,349.359894 
	C460.674255,349.030212 461.803741,348.478943 462.963013,347.936066 
	C462.992859,347.944458 462.935486,347.947571 463.165649,347.854004 
	C463.684601,347.562775 463.864166,347.292511 463.915405,346.930206 
	C463.896332,346.910797 463.894806,346.856354 464.176361,346.909729 
	C464.648285,346.979340 464.838654,346.995544 465.028992,347.011719 
	C465.976624,350.746246 467.614655,354.460571 467.724731,358.219604 
	C467.938232,365.511841 467.292847,372.829163 467.004089,380.567444 
z"/>
<path fill="#1B2B35" opacity="1.000000" stroke="none" 
	d="
M488.981659,244.552002 
	C491.026947,247.146957 493.072235,249.741913 495.296997,252.665237 
	C496.299377,258.414093 491.968414,260.893524 489.054718,264.821442 
	C489.017426,265.630096 489.008148,265.815033 488.604736,266.014893 
	C485.803040,267.004547 483.395416,267.979279 480.590698,268.965698 
	C476.462402,268.639130 472.731232,268.300934 469.018494,267.663696 
	C469.050446,267.165405 469.063934,266.966125 469.383240,266.645233 
	C471.786102,264.964661 473.883179,263.405762 476.362579,261.865906 
	C479.661682,260.811218 482.764893,260.055115 485.403351,258.506897 
	C486.455292,257.889648 487.231659,255.209732 486.747528,254.044724 
	C486.318634,253.012604 483.807098,252.052200 482.499054,252.342072 
	C479.652954,252.972794 476.988007,254.420792 474.200409,255.139725 
	C475.403320,252.311966 476.654816,249.879227 478.107330,247.155853 
	C481.866119,246.094162 485.423889,245.323090 488.981659,244.552002 
z"/>
<path fill="#67B8CC" opacity="1.000000" stroke="none" 
	d="
M452.000000,319.001221 
	C458.496552,322.492432 462.213684,327.426208 461.120972,335.292450 
	C460.910706,336.806488 462.242554,338.534760 462.885468,340.627502 
	C462.907990,341.090851 462.694519,341.303680 462.345734,341.172974 
	C460.023712,340.877655 458.050507,340.713043 456.156830,340.179657 
	C457.740234,336.248444 455.901367,334.302338 452.988220,332.574158 
	C453.766907,330.717926 454.998413,329.208832 455.010712,327.689850 
	C455.024506,325.982727 454.401611,323.457184 453.205322,322.771576 
	C451.885986,322.015503 449.223694,322.408691 447.885559,323.351013 
	C447.145172,323.872375 447.721802,326.584717 448.080078,328.227570 
	C448.301453,329.242737 449.228485,330.104034 449.491638,330.967163 
	C442.392944,326.994873 435.576263,323.808441 428.001953,328.574097 
	C428.016571,327.059906 428.031769,325.958160 428.430542,324.872620 
	C434.965881,324.341583 439.704346,321.096527 444.244385,317.202515 
	C447.012512,317.971069 449.506256,318.486145 452.000000,319.001221 
z"/>
<path fill="#59B9D9" opacity="1.000000" stroke="none" 
	d="
M452.087280,318.660309 
	C449.506256,318.486145 447.012512,317.971069 444.413452,316.903900 
	C446.885468,314.737213 449.462738,313.122620 452.313232,311.382202 
	C453.068085,310.923096 453.549713,310.589874 454.392090,310.196991 
	C457.419739,308.502075 459.572235,308.874054 461.080566,311.759155 
	C459.657715,313.141449 458.351868,314.679993 456.776825,315.858856 
	C455.400055,316.889282 453.720398,317.514954 452.087280,318.660309 
z"/>
<path fill="#E7B62A" opacity="1.000000" stroke="none" 
	d="
M481.067993,331.008942 
	C480.716675,331.381409 480.365356,331.753845 479.931671,332.370239 
	C479.849304,332.614136 479.369446,332.703796 478.784424,332.844727 
	C477.129059,333.066650 476.058746,333.147644 474.988434,333.228607 
	C474.988434,333.228607 474.727264,332.929993 474.377014,332.564606 
	C473.230042,330.458099 472.433380,328.717010 471.636688,326.975891 
	C472.621460,325.797577 473.606293,324.619263 474.794464,323.176941 
	C476.310913,323.252380 477.623993,323.591766 479.390320,324.420532 
	C480.251709,326.942932 480.659851,328.975952 481.067993,331.008942 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M486.691193,293.109436 
	C487.707001,296.860229 486.169098,299.269287 482.994720,301.304993 
	C480.493317,302.909149 478.419373,305.179962 475.712219,307.198059 
	C475.268860,307.241425 474.986969,306.990143 475.041748,306.636932 
	C476.836731,304.457001 478.370911,302.351349 480.369843,300.874878 
	C482.810486,299.072174 484.844879,297.559723 483.274261,294.070038 
	C482.616913,292.609436 482.194061,290.808838 482.406982,289.265198 
	C482.577118,288.031616 483.718536,286.140381 484.694977,285.956848 
	C487.726349,285.387115 490.884491,285.492065 493.808014,285.600464 
	C493.340607,286.186707 493.058655,286.507721 492.776733,286.828735 
	C492.776703,286.828705 492.867554,286.789276 492.448853,286.786621 
	C489.470428,287.116821 486.910736,287.449738 483.310486,287.917969 
	C484.455322,289.675995 485.573242,291.392700 486.691193,293.109436 
z"/>
<path fill="#77C4DB" opacity="1.000000" stroke="none" 
	d="
M487.124695,293.078186 
	C485.573242,291.392700 484.455322,289.675995 483.310486,287.917969 
	C486.910736,287.449738 489.470428,287.116821 492.418793,286.747253 
	C493.891724,292.827911 493.891724,292.827911 487.124695,293.078186 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M461.487000,311.776031 
	C459.572235,308.874054 457.419739,308.502075 454.531525,309.859314 
	C456.230896,305.948578 459.175629,307.278534 461.996948,307.997803 
	C462.000000,308.000000 462.004364,308.006104 462.070190,308.370911 
	C464.088135,309.483734 466.040222,310.231720 468.337036,310.936401 
	C470.783508,309.592102 472.885223,308.291138 474.986969,306.990143 
	C474.986969,306.990143 475.268860,307.241425 475.407410,307.370544 
	C472.478210,313.121582 470.631195,313.702393 461.487000,311.776031 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M497.201996,270.817963 
	C501.013947,275.502319 498.699341,279.711151 495.737946,284.370514 
	C494.859711,285.010284 494.429749,285.184113 494.039490,284.965424 
	C496.923401,280.458832 497.425201,275.848175 497.014130,270.978546 
	C497.078156,270.926697 497.201996,270.817963 497.201996,270.817963 
z"/>
<path fill="#67B8CC" opacity="1.000000" stroke="none" 
	d="
M471.342346,327.036926 
	C472.433380,328.717010 473.230042,330.458099 474.182251,332.527039 
	C472.382568,331.756104 470.427399,330.657288 468.051514,329.322021 
	C469.505737,328.242676 470.276886,327.670288 471.342346,327.036926 
z"/>
<path fill="#5ABADB" opacity="1.000000" stroke="none" 
	d="
M473.740417,347.066925 
	C474.271790,347.874207 474.456818,348.667084 474.641846,349.459930 
	C473.593475,349.351013 472.545166,349.242065 471.496826,349.133118 
	C472.129242,348.439575 472.761658,347.746033 473.740417,347.066925 
z"/>
<path fill="#67B8CC" opacity="1.000000" stroke="none" 
	d="
M465.142639,346.804169 
	C464.838654,346.995544 464.648285,346.979340 464.130524,346.551575 
	C463.433594,344.527893 463.064056,342.915802 462.694519,341.303680 
	C462.694519,341.303680 462.907990,341.090851 463.015503,340.983246 
	C463.917145,341.458771 464.711334,342.041931 465.747314,343.188538 
	C465.984039,344.498260 465.978943,345.244537 465.973816,345.990784 
	C465.684235,346.133087 465.445038,346.335022 465.142639,346.804169 
z"/>
<path fill="#67B8CC" opacity="1.000000" stroke="none" 
	d="
M475.085480,333.538544 
	C476.058746,333.147644 477.129059,333.066650 478.601990,333.006897 
	C477.730591,333.301575 476.456543,333.575012 475.085480,333.538544 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M488.998840,266.000000 
	C489.008148,265.815033 489.017426,265.630096 489.046997,265.168304 
	C490.307251,265.360016 491.547211,265.828583 492.910889,266.635559 
	C493.034576,266.973969 492.958130,267.062531 492.600586,267.045044 
	C491.161652,266.685028 490.080231,266.342529 488.998840,266.000000 
z"/>
<path fill="#5ABADB" opacity="1.000000" stroke="none" 
	d="
M466.291321,345.924866 
	C465.978943,345.244537 465.984039,344.498260 465.985718,343.378906 
	C466.191101,343.956818 466.399933,344.907867 466.291321,345.924866 
z"/>
<path fill="#5ABADB" opacity="1.000000" stroke="none" 
	d="
M477.294739,342.756683 
	C477.525574,342.205200 477.817169,341.912506 478.108795,341.619812 
	C478.312073,341.981964 478.515320,342.344086 478.718597,342.706238 
	C478.264221,342.809296 477.809875,342.912354 477.294739,342.756683 
z"/>
<path fill="#77C4DB" opacity="1.000000" stroke="none" 
	d="
M493.999817,285.357910 
	C494.429749,285.184113 494.859711,285.010284 495.606812,284.683655 
	C495.135345,285.315521 494.346771,286.100159 493.167450,286.856750 
	C493.058655,286.507721 493.340607,286.186707 493.802307,285.596375 
	C493.982025,285.326996 493.999817,285.357941 493.999817,285.357910 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M494.514771,267.363831 
	C494.975342,267.695587 495.435913,268.027344 495.958588,268.670776 
	C496.020660,268.982422 495.923889,269.075623 495.644104,269.017517 
	C494.908875,268.640320 494.453461,268.321228 494.133575,267.847687 
	C494.269104,267.693268 494.514771,267.363831 494.514771,267.363831 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M496.069763,268.936523 
	C496.456970,269.336761 496.795135,269.782867 497.167664,270.523438 
	C497.201996,270.817963 497.078156,270.926697 496.805542,270.786072 
	C496.329895,270.122162 496.126892,269.598877 495.923889,269.075623 
	C495.923889,269.075623 496.020660,268.982422 496.069763,268.936523 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M493.073547,266.930359 
	C493.444489,266.945923 493.776459,267.005127 494.311584,267.214050 
	C494.514771,267.363831 494.269104,267.693268 493.928833,267.728302 
	C493.330933,267.572449 493.120789,267.338867 492.958130,267.062531 
	C492.958130,267.062531 493.034576,266.973969 493.073547,266.930359 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M71.154327,213.943390 
	C69.766426,213.499557 68.402390,212.948074 66.985733,212.633911 
	C63.866127,211.942093 61.523907,210.881561 61.630836,206.920822 
	C61.772572,201.670929 57.279678,201.835403 53.214317,200.633743 
	C49.068329,198.224640 45.546318,196.081818 42.024311,193.938995 
	C41.555977,193.357605 41.087643,192.776215 40.277031,191.816956 
	C39.642780,191.223801 39.350807,191.008514 39.058830,190.793243 
	C33.356499,182.621948 28.500641,174.104706 28.214867,163.285492 
	C28.544598,162.449051 28.452797,162.193298 28.128513,162.006744 
	C28.311352,158.924072 28.494190,155.841400 29.059864,152.147430 
	C29.560419,151.127731 29.678141,150.719315 29.795862,150.310898 
	C29.497385,150.083130 29.425282,149.798096 29.958672,149.018738 
	C30.482992,148.150848 30.628199,147.720047 30.773407,147.289261 
	C30.453297,147.068314 30.383007,146.785339 30.956650,146.012482 
	C31.506348,145.156219 31.661938,144.727768 31.817528,144.299332 
	C34.248470,135.906052 40.741749,131.251144 48.193321,126.770294 
	C48.796230,126.385490 48.913979,126.262016 49.031723,126.138550 
	C50.823299,125.525543 52.614876,124.912537 55.117271,124.086037 
	C59.600788,123.504608 63.590469,122.190788 67.094368,122.994598 
	C72.114433,124.146217 74.615135,122.503052 77.721794,118.787498 
	C83.309280,112.104881 90.559341,107.811951 99.625793,108.548981 
	C99.394119,109.682510 99.351158,110.600433 99.308197,111.518356 
	C102.040062,111.587708 104.783913,111.838234 107.499283,111.658470 
	C109.128334,111.550636 110.918953,110.050385 112.275925,110.438438 
	C115.402573,111.332542 116.875290,109.935127 118.250999,107.611153 
	C119.413544,105.647293 120.545837,103.415306 123.722496,105.261902 
	C124.075592,105.754265 124.225632,105.944832 124.368607,106.482513 
	C125.255447,108.568733 125.779945,111.394432 127.111221,111.847015 
	C131.426147,113.313934 131.075867,116.461952 131.124802,119.784889 
	C131.166260,122.600456 131.475784,125.411224 131.629150,128.226151 
	C132.277847,140.131622 132.912537,152.037857 133.217560,163.960968 
	C128.816879,166.124573 125.146057,168.532959 126.186813,174.410034 
	C127.120827,176.170517 128.060196,177.589844 129.052780,179.317688 
	C129.730927,180.078934 130.355850,180.531647 131.212830,180.994873 
	C131.444885,181.005371 131.909088,180.986237 131.912170,181.263397 
	C131.931213,181.724655 131.947174,181.908737 132.030640,182.314438 
	C132.267014,182.812546 132.505890,182.999771 133.188507,183.289337 
	C138.068481,183.399551 142.574677,183.318161 147.542969,183.282333 
	C157.162506,183.126053 166.319962,182.924225 175.839203,182.764526 
	C176.770752,182.441528 177.340530,182.076355 178.065186,181.530411 
	C178.654083,181.172791 179.088074,180.995956 179.817337,180.757294 
	C180.374023,180.404358 180.635437,180.113220 180.869141,179.852997 
	C180.841415,179.883911 180.823746,179.802780 181.114990,179.868881 
	C181.603149,179.959213 181.800049,179.983444 182.055237,180.351379 
	C184.199646,185.517151 186.709244,180.772720 188.944641,181.348999 
	C186.277206,183.385269 183.842941,185.565430 180.992813,186.600449 
	C178.794464,187.398788 176.074097,186.759644 173.585815,186.759644 
	C173.624710,187.011063 173.663589,187.262466 173.702469,187.513885 
	C172.116180,186.685959 170.529892,185.858017 168.943604,185.030090 
	C168.671494,185.400833 168.399384,185.771561 168.127289,186.142303 
	C170.022644,188.588547 166.626175,193.248657 172.862976,194.166519 
	C182.380005,195.567123 191.724487,198.140076 202.077637,200.421387 
	C202.789032,200.303955 205.702560,198.944580 206.730881,199.814255 
	C210.125854,202.685501 213.420868,202.080429 217.304764,201.188507 
	C217.706482,203.322922 217.993027,205.281982 217.859116,207.211868 
	C217.614914,210.731018 216.966797,214.224350 216.773010,217.744186 
	C216.553589,221.729889 216.648376,225.732910 216.391022,229.397278 
	C214.548660,220.030579 209.555222,212.651001 202.750732,207.199463 
	C193.865891,200.081192 183.440033,195.368942 171.679459,195.438385 
	C153.246475,195.547195 134.811783,195.900406 116.386299,196.439728 
	C110.476189,196.612717 105.987938,199.604874 103.622147,205.748627 
	C102.881523,207.676636 102.438416,209.352844 101.802643,211.264343 
	C101.773682,212.658463 101.937370,213.817291 102.073334,215.281891 
	C102.261833,216.152756 102.478065,216.717834 102.678162,217.639862 
	C105.530762,220.842606 108.070076,224.186951 111.375870,226.371292 
	C113.873688,228.021744 115.276154,229.467621 115.375435,232.476898 
	C115.819542,245.939011 116.553726,259.392822 116.886414,272.856964 
	C117.218964,286.314728 117.198814,299.781219 117.215561,313.590027 
	C114.232780,316.129974 111.367706,318.323761 108.226624,320.830872 
	C107.720276,321.927277 107.489937,322.710388 107.259331,323.467896 
	C107.259064,323.442291 107.258858,323.389709 107.013657,323.727356 
	C106.575645,325.421112 106.382835,326.777222 106.054092,328.452942 
	C105.988571,329.448761 106.058968,330.125000 106.052780,331.149017 
	C106.245384,332.385406 106.514587,333.274017 106.780754,334.532227 
	C109.143036,341.891541 113.720337,345.806519 121.789871,345.587311 
	C142.841934,345.015442 163.877487,343.835663 184.845779,343.263062 
	C181.174942,344.424683 177.577957,345.221497 173.601227,346.018250 
	C171.819046,346.341553 170.416611,346.664917 168.678589,346.993530 
	C167.895584,347.007202 167.448151,347.015625 166.539703,347.017395 
	C160.723053,347.045380 154.685104,345.488281 152.136292,353.957977 
	C157.275009,353.957977 162.133011,353.957977 167.440567,353.968262 
	C170.585373,353.986145 173.280609,353.993805 175.983185,354.268555 
	C175.995514,354.713715 176.000519,354.891815 175.625183,355.169403 
	C173.489349,355.487976 171.733856,355.707031 169.517487,355.840698 
	C162.038300,355.820160 155.020004,355.885010 148.000748,355.625000 
	C148.002716,354.866730 148.005630,354.433380 148.214874,353.773102 
	C147.886307,352.344574 147.351395,351.142944 146.816467,349.941284 
	C146.395920,350.097809 145.975372,350.254333 145.554810,350.410828 
	C145.704514,351.602905 145.854202,352.794952 146.001312,354.318207 
	C145.998718,355.090149 145.998703,355.530914 145.754639,356.059692 
	C145.342682,356.106262 145.174774,356.064819 144.694183,355.758331 
	C140.150482,355.012421 135.915924,354.171844 131.690079,354.213501 
	C129.785980,354.232269 127.899246,356.010529 125.703140,357.119934 
	C124.148682,356.834351 122.875053,356.472015 121.647964,355.990967 
	C120.080147,355.376373 118.549866,354.666016 116.940521,353.642090 
	C114.563614,352.210236 112.249596,351.133179 109.951523,350.026428 
	C109.967461,349.996704 109.967560,350.063965 109.816193,349.845886 
	C109.347389,349.482178 109.029953,349.336517 108.796204,349.198669 
	C108.879890,349.206482 108.836029,349.368774 108.724854,349.074951 
	C108.073357,348.521729 107.533028,348.262329 106.755905,347.902679 
	C106.353508,347.511597 106.187912,347.220764 106.006554,346.568237 
	C105.119164,345.335236 104.247536,344.463928 103.356468,343.559448 
	C103.337029,343.526245 103.295166,343.461700 103.451584,343.090637 
	C102.072807,336.745972 99.671356,330.826141 99.296585,324.780609 
	C99.031708,320.507782 101.512276,316.064758 102.790985,311.744690 
	C102.814766,311.791687 102.712105,311.768036 103.077118,311.789734 
	C104.255257,310.814148 105.068382,309.816833 105.907936,308.857239 
	C105.934364,308.894928 105.842697,308.886383 106.174011,308.864319 
	C106.972511,308.426636 107.439690,308.011017 108.133972,307.268829 
	C108.539566,305.533630 108.873703,304.124420 108.868782,302.716431 
	C108.850471,297.477478 108.720726,292.238922 108.959969,287.264740 
	C109.533318,290.131714 109.788445,292.733368 110.023438,295.336823 
	C110.314400,298.560120 111.470688,301.023987 115.812271,301.641296 
	C115.230766,280.002411 114.659164,258.732483 114.065262,236.632339 
	C111.328270,238.293671 109.952950,239.128479 107.862068,240.397644 
	C106.707336,237.820480 105.692291,235.555069 104.696075,233.331696 
	C104.526939,233.396957 103.767487,233.690018 103.003891,233.629272 
	C100.284599,229.808090 97.569435,226.340714 94.784256,222.499847 
	C91.444618,214.462021 92.498329,207.052597 95.736557,199.635162 
	C96.368866,198.186844 95.834862,196.229340 95.978081,194.189178 
	C97.035461,193.453979 98.354256,192.422028 98.807159,192.717468 
	C101.940369,194.761353 102.357155,192.438385 103.394753,190.159088 
	C108.269417,189.182693 112.761658,188.404221 117.602280,187.621124 
	C118.064903,186.283768 118.778366,184.350967 118.169174,183.742630 
	C116.882507,182.457703 114.904243,181.865326 113.182953,180.728378 
	C113.097122,180.303665 113.038033,180.139420 113.237656,179.753662 
	C120.727631,176.004974 126.023331,171.288635 124.319702,162.167160 
	C124.176735,161.401703 124.481392,160.552658 124.550446,159.731598 
	C124.522797,159.720734 124.570824,159.699570 124.849365,159.460724 
	C125.567726,157.817017 126.391060,156.410492 126.384926,155.007599 
	C126.346001,146.088684 126.185173,137.168488 125.884300,128.254807 
	C125.847031,127.150665 124.744133,126.082504 123.913925,124.681076 
	C114.012756,114.931168 101.494644,112.940300 90.866684,119.244186 
	C88.096085,121.303070 85.676003,123.317390 82.910858,125.511818 
	C78.679939,130.394287 76.520576,135.756943 76.214249,142.327728 
	C74.813347,152.417786 79.206680,160.475037 82.937225,168.559937 
	C80.546387,168.518967 78.578041,168.518265 76.628036,168.401993 
	C76.646393,168.286423 76.870895,168.220352 76.729408,167.956238 
	C76.246849,167.495956 75.905777,167.299774 75.385223,166.967651 
	C75.115677,166.411209 75.025612,165.990707 74.844116,165.267181 
	C74.493942,164.638672 74.235191,164.313156 73.893173,163.876511 
	C73.809906,163.765366 73.882034,163.497162 73.874207,163.136780 
	C73.506271,162.200745 73.146164,161.625122 72.582703,160.851318 
	C72.233582,160.114227 72.087807,159.575272 72.154663,158.847260 
	C71.911819,157.768890 71.456337,156.879623 70.814301,155.698273 
	C70.412544,154.288696 70.197350,153.171188 69.989365,151.625656 
	C69.719574,148.810379 69.442574,146.423111 69.104294,143.666718 
	C69.179741,142.823532 69.316460,142.349457 69.748520,141.777405 
	C70.124870,139.782623 70.205872,137.885788 70.292038,135.639801 
	C72.501389,131.398392 72.024673,130.245163 68.010574,130.086014 
	C59.091820,129.732422 51.654152,132.797455 45.746597,140.006531 
	C44.799583,140.501312 44.088432,140.812790 42.947029,141.094528 
	C41.153492,141.195786 39.790211,141.326797 37.992107,141.499603 
	C38.551006,143.670670 38.947807,145.212051 39.371887,146.827927 
	C39.399162,146.902405 39.283646,147.011124 38.913631,147.075562 
	C37.938187,149.734146 37.332756,152.328323 36.761627,155.336395 
	C36.911945,160.349640 37.027958,164.948959 37.123955,169.879517 
	C37.347000,170.797882 37.590061,171.385056 37.910507,172.204849 
	C37.987896,172.437485 38.029648,172.926056 38.050625,173.226608 
	C38.389439,174.011276 38.707275,174.495392 39.242737,175.053955 
	C39.460369,175.128403 39.904354,175.248810 39.902344,175.538361 
	C39.970722,176.399185 40.041111,176.970474 40.180248,177.831940 
	C40.503811,178.417053 40.758625,178.711990 41.248932,179.241150 
	C48.677158,188.775528 57.522213,195.257370 70.062599,195.585358 
	C69.855560,197.329056 69.704346,198.602539 69.332336,200.163635 
	C68.650322,202.176773 68.189102,203.902267 67.412216,206.808746 
	C73.462082,205.230576 78.185387,203.998459 82.929642,203.181503 
	C81.634293,205.715607 80.317986,207.834549 78.974594,210.096039 
	C78.947495,210.238602 78.657410,210.247696 78.277069,210.296814 
	C75.649254,211.545090 73.401794,212.744247 71.154327,213.943390 
M163.649872,191.510406 
	C162.322968,191.101517 160.996048,190.692627 159.669144,190.283737 
	C159.571701,191.023438 159.474258,191.763138 159.376816,192.502823 
	C160.733246,192.399338 162.089691,192.295837 163.649872,191.510406 
M149.118912,348.847473 
	C150.181885,348.709595 151.244873,348.571716 152.307846,348.433868 
	C152.257324,348.102325 152.206787,347.770782 152.156265,347.439240 
	C150.541489,347.439240 148.926712,347.439240 146.539505,347.439240 
	C147.690598,348.158142 148.085510,348.404785 149.118912,348.847473 
M111.455772,231.245438 
	C111.188049,231.058029 110.920319,230.870605 110.652596,230.683197 
	C110.824249,230.961319 110.995895,231.239441 111.455772,231.245438 
z"/>
<path fill="#5FBBD8" opacity="1.000000" stroke="none" 
	d="
M42.005028,194.287292 
	C45.546318,196.081818 49.068329,198.224640 52.820419,200.613495 
	C50.842442,204.450119 48.634377,208.040680 45.821106,211.778061 
	C43.700893,211.730667 42.185890,211.536484 40.670891,211.342285 
	C40.957615,210.128769 41.661839,208.836578 41.450783,207.716888 
	C40.876453,204.669983 39.914997,201.696045 39.054192,198.349335 
	C39.996189,196.882202 40.990963,195.758896 42.005028,194.287292 
z"/>
<path fill="#5FBBD8" opacity="1.000000" stroke="none" 
	d="
M35.916176,197.676285 
	C34.570835,198.296432 33.149670,198.593857 31.367744,198.944183 
	C32.058578,196.803726 33.229702,194.961914 35.916176,197.676285 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M31.528624,144.507339 
	C31.661938,144.727768 31.506348,145.156219 31.099472,145.821442 
	C30.978697,145.610596 31.109207,145.162979 31.528624,144.507339 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M28.060745,162.253815 
	C28.452797,162.193298 28.544598,162.449051 28.287025,162.877838 
	C28.111086,162.821457 28.052032,162.661179 28.060745,162.253815 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M30.473051,147.487793 
	C30.628199,147.720047 30.482992,148.150848 30.089859,148.821426 
	C29.952187,148.602921 30.062441,148.144608 30.473051,147.487793 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M29.526609,150.512451 
	C29.678141,150.719315 29.560419,151.127731 29.225506,151.772797 
	C29.091328,151.577621 29.174343,151.145798 29.526609,150.512451 
z"/>
<path fill="#66BED5" opacity="1.000000" stroke="none" 
	d="
M48.907509,125.911438 
	C48.913979,126.262016 48.796230,126.385490 48.425220,126.547272 
	C48.141781,126.323318 48.111599,126.061050 48.044910,125.404617 
	C48.266705,125.235085 48.525002,125.459702 48.907509,125.911438 
z"/>
<path fill="#5FBBD8" opacity="1.000000" stroke="none" 
	d="
M39.167458,191.040527 
	C39.350807,191.008514 39.642780,191.223801 40.013870,191.706726 
	C39.820679,191.745529 39.548382,191.516678 39.167458,191.040527 
z"/>
<path fill="#B1BD8D" opacity="1.000000" stroke="none" 
	d="
M461.057709,385.038971 
	C461.826569,384.717194 462.595428,384.395416 463.852264,384.286621 
	C464.904144,384.628876 465.468048,384.758148 466.031921,384.887390 
	C466.026794,385.092255 466.021698,385.297150 465.581299,385.803589 
	C462.023254,386.056702 460.453613,387.883209 461.011475,390.667267 
	C461.248932,391.852356 463.619995,393.667145 464.626373,393.440613 
	C467.522949,392.788666 468.074646,390.401794 467.008331,387.691498 
	C466.951752,387.547699 466.983887,387.368988 467.282349,387.278015 
	C468.175568,388.226685 468.761139,389.103912 469.346741,389.981110 
	C468.382874,391.775146 467.419006,393.569183 466.211700,395.582336 
	C464.533600,395.614716 463.098999,395.427948 461.179932,394.827698 
	C457.571808,391.163788 458.297363,388.062073 461.057709,385.038971 
z"/>
<path fill="#E7B62A" opacity="1.000000" stroke="none" 
	d="
M466.974701,387.206543 
	C466.983887,387.368988 466.951752,387.547699 467.008331,387.691498 
	C468.074646,390.401794 467.522949,392.788666 464.626373,393.440613 
	C463.619995,393.667145 461.248932,391.852356 461.011475,390.667267 
	C460.453613,387.883209 462.023254,386.056702 465.529053,386.106506 
	C465.912109,386.107849 466.230591,386.212555 466.460266,386.458710 
	C466.784882,386.872101 466.879791,387.039337 466.974701,387.206543 
z"/>
<path fill="#67B8CC" opacity="1.000000" stroke="none" 
	d="
M481.406799,330.914673 
	C480.659851,328.975952 480.251709,326.942932 479.710297,324.539124 
	C483.135803,325.461121 483.815674,327.692535 481.406799,330.914673 
z"/>
<path fill="#5DBBDA" opacity="1.000000" stroke="none" 
	d="
M467.282349,387.278015 
	C466.879791,387.039337 466.784882,386.872101 466.624237,386.423889 
	C470.256378,386.459473 470.486053,386.709625 469.624390,389.882690 
	C468.761139,389.103912 468.175568,388.226685 467.282349,387.278015 
z"/>
<path fill="#5DBBDA" opacity="1.000000" stroke="none" 
	d="
M466.022736,384.500305 
	C465.468048,384.758148 464.904144,384.628876 464.166321,384.265076 
	C464.475494,383.126770 464.958679,382.222992 465.720337,381.162048 
	C466.003723,382.040985 466.008636,383.077087 466.022736,384.500305 
z"/>
<path fill="#58BDDE" opacity="1.000000" stroke="none" 
	d="
M23.036999,366.289673 
	C23.460535,366.541687 23.920082,367.083405 24.190552,367.812561 
	C23.691984,367.526459 23.382496,367.052887 23.036999,366.289673 
z"/>
<path fill="#17252C" opacity="1.000000" stroke="none" 
	d="
M462.945038,137.172272 
	C473.854736,142.124878 481.440247,150.267670 484.451294,161.813858 
	C485.878540,167.286987 485.365814,173.266037 485.764252,179.479324 
	C483.167664,189.979568 478.432922,198.738174 469.891663,205.813843 
	C469.249115,206.476013 469.032562,206.769302 468.816040,207.062592 
	C464.386566,209.631393 459.957123,212.200165 454.975647,214.990784 
	C453.950470,215.482864 453.477356,215.753082 453.004242,216.023315 
	C451.226837,216.342560 449.449402,216.661789 446.892517,217.099335 
	C442.765106,217.649475 438.839783,215.916794 436.138794,220.004242 
	C435.633087,220.584946 435.402405,220.966293 435.203918,221.292877 
	C435.236145,221.238129 435.323975,221.329926 434.925049,221.235077 
	C431.192627,220.964645 430.585419,219.782043 432.398102,216.782089 
	C435.329742,211.930313 439.851746,210.039841 445.422913,209.008698 
	C449.869415,207.475052 453.910156,205.946960 457.858948,204.336304 
	C457.766998,204.253754 457.859650,204.024643 458.242554,204.011627 
	C459.402100,203.472504 460.178741,202.946396 460.977478,202.281113 
	C461.123199,201.970337 461.289154,201.902435 461.796631,201.847229 
	C462.390106,201.473267 462.684479,201.190262 462.974243,200.943573 
	C462.969604,200.979904 462.910767,200.936295 463.215698,200.916687 
	C463.997894,200.560349 464.475128,200.223602 464.929016,199.921692 
	C464.905731,199.956528 464.841980,199.902145 465.184631,199.805511 
	C467.003326,197.745010 468.479401,195.781128 470.020752,193.627777 
	C470.222107,193.193344 470.421570,193.022339 470.948486,192.828506 
	C471.461578,192.459015 471.710602,192.186279 471.924500,191.949585 
	C471.889374,191.985611 471.819092,191.913559 472.150909,191.777817 
	C474.089020,188.279388 475.822571,184.970551 477.272552,181.541763 
	C479.605988,176.023956 479.904938,170.461517 476.997253,164.604126 
	C475.490051,160.870193 473.977570,157.526215 472.465088,154.182220 
	C471.949219,154.457504 471.433350,154.732788 470.617798,154.853271 
	C464.745026,146.484695 456.725677,143.763489 447.201477,144.350327 
	C442.881287,144.616501 441.126099,146.494049 442.740326,150.844879 
	C442.549866,150.907822 442.198944,151.102325 441.977173,151.219666 
	C441.836548,151.878860 441.917664,152.420700 442.029297,153.326050 
	C441.767609,154.844818 441.475403,156.000046 440.916473,156.918274 
	C439.436554,155.790848 438.223358,154.900391 437.005432,154.005280 
	C437.000671,154.000641 436.991852,153.994980 436.992188,153.549332 
	C436.386780,150.074341 435.781036,147.044998 435.098633,143.593521 
	C435.011993,137.719513 433.790588,132.806412 428.953400,129.514084 
	C428.962555,129.522705 428.975403,129.544281 429.003845,129.154022 
	C428.488739,128.262741 427.945190,127.761726 427.396729,127.216492 
	C427.391785,127.172264 427.317627,127.123108 427.248383,126.760406 
	C426.786621,126.123779 426.394073,125.849869 425.977295,125.507866 
	C425.953064,125.439774 425.817413,125.389786 425.756531,125.064819 
	C425.163818,124.411598 424.631958,124.083351 423.875305,123.512825 
	C416.565033,117.550819 408.516754,116.935005 400.067535,118.473450 
	C401.721436,116.782387 403.137146,114.625595 404.928802,114.246803 
	C408.158142,113.564056 408.911682,111.746559 409.064545,108.845856 
	C409.125671,108.669624 409.159973,108.298141 409.539734,108.301033 
	C410.929016,108.256607 411.938507,108.209290 413.210449,108.349854 
	C414.320404,108.589386 415.167969,108.641029 416.354004,108.862823 
	C419.452606,110.044327 422.212708,111.055672 425.131287,112.308235 
	C425.815521,112.764084 426.341248,112.978699 426.866943,113.193314 
	C426.866943,113.193314 426.918823,113.110825 427.006714,113.362640 
	C427.728546,113.967461 428.362488,114.320465 428.996429,114.673470 
	C431.762329,117.679085 434.528259,120.684708 437.635712,124.228256 
	C439.577454,127.851479 441.177612,130.936783 442.778931,134.333176 
	C443.557098,134.854630 444.334137,135.064987 445.561279,135.298111 
	C451.655945,135.938004 457.300476,136.555130 462.945038,137.172272 
z"/>
<path fill="#182935" opacity="1.000000" stroke="none" 
	d="
M492.600586,267.045044 
	C493.120789,267.338867 493.330933,267.572449 493.793304,267.882721 
	C494.453461,268.321228 494.908875,268.640320 495.644104,269.017517 
	C496.126892,269.598877 496.329895,270.122162 496.741516,270.837891 
	C497.425201,275.848175 496.923401,280.458832 494.039490,284.965454 
	C493.999817,285.357941 493.982025,285.326996 493.987732,285.331116 
	C490.884491,285.492065 487.726349,285.387115 484.694977,285.956848 
	C483.718536,286.140381 482.577118,288.031616 482.406982,289.265198 
	C482.194061,290.808838 482.616913,292.609436 483.274261,294.070038 
	C484.844879,297.559723 482.810486,299.072174 480.369843,300.874878 
	C478.370911,302.351349 476.836731,304.457001 475.041748,306.636932 
	C472.885223,308.291138 470.783508,309.592102 468.313354,310.550659 
	C465.964752,309.474182 463.984558,308.740143 462.004364,308.006104 
	C462.004364,308.006104 462.000000,308.000000 462.045349,307.706726 
	C462.725311,306.690460 463.359924,305.967468 464.310425,305.225220 
	C466.426422,304.137146 468.226532,303.068359 470.282043,301.786316 
	C472.045471,300.038696 473.553467,298.504364 475.351562,297.103516 
	C476.660004,297.482666 477.678375,297.728241 478.696716,297.973846 
	C478.748901,296.222198 479.500031,294.051025 478.740906,292.786407 
	C473.479187,284.020905 466.342377,277.592194 455.412109,275.985535 
	C453.984863,276.766418 452.926697,277.516388 451.594910,278.154297 
	C450.522705,279.033936 449.724182,280.025604 448.461914,281.015961 
	C446.761566,281.031250 445.524933,281.047882 444.424622,280.745026 
	C444.600006,279.059845 448.026276,277.405121 444.199036,275.884338 
	C445.444031,271.252594 446.704712,267.104462 447.943970,262.567261 
	C447.223694,259.469788 446.524872,256.761383 446.190063,253.955170 
	C446.804810,252.294540 447.055542,250.731720 447.306274,249.168900 
	C445.508270,249.135193 443.710236,249.101486 441.685974,248.949524 
	C441.030304,248.580170 440.600952,248.329071 440.064819,247.740891 
	C437.545227,244.763107 435.393738,241.781189 432.598297,239.639893 
	C431.381256,238.707611 428.842316,239.501053 426.903717,239.510742 
	C427.326721,241.557144 427.141449,244.101379 428.314117,245.534210 
	C430.108826,247.727020 432.839905,249.153503 435.298035,251.017059 
	C435.422607,251.128815 435.745270,251.217819 435.772156,251.189331 
	C435.799042,251.160858 435.806976,251.238800 435.927612,251.487183 
	C436.453644,252.116791 436.859039,252.498032 437.326324,253.098694 
	C437.615570,253.708313 437.842896,254.098511 438.239502,254.805817 
	C438.802795,255.384933 439.196838,255.646957 439.726440,255.859985 
	C439.862000,255.810989 440.146912,255.854813 440.225037,256.097931 
	C440.577545,256.764404 440.851898,257.187775 441.053162,257.930664 
	C438.230988,258.276337 435.481934,258.302490 432.803955,258.354614 
	C432.875000,258.380615 432.794342,258.508667 432.676331,258.177551 
	C431.714081,257.563873 430.869812,257.281311 429.620300,257.069183 
	C428.951538,257.215424 428.688049,257.291229 428.210846,257.306763 
	C427.759552,257.026306 427.665741,256.756287 427.932068,256.137238 
	C430.627838,252.055496 429.910187,250.893982 423.674408,248.818634 
	C423.169037,247.957138 423.073730,247.244675 422.706543,246.723831 
	C419.530121,242.217850 421.177216,237.797714 423.830811,234.176880 
	C426.554260,230.460770 430.724579,231.305725 434.783264,232.587769 
	C436.005402,232.973801 438.357819,232.091034 439.182648,231.015259 
	C442.325928,226.915634 446.017883,226.041779 450.520294,228.198288 
	C453.652405,229.698471 455.229889,228.931427 455.225128,225.370422 
	C455.908142,225.179993 456.591125,224.989548 457.632660,225.242798 
	C457.820953,227.148178 457.735107,228.625793 457.462952,230.068268 
	C456.896210,233.071960 457.752991,234.929794 461.175934,235.391830 
	C461.600311,237.559555 462.154510,239.294357 462.284424,241.060364 
	C462.838165,248.588837 459.485809,254.587738 454.553955,259.872528 
	C452.927124,261.615784 451.504242,263.242493 453.888855,265.675873 
	C456.862183,267.180878 459.438446,268.596649 461.868408,270.334595 
	C460.356049,273.309692 462.053406,274.200043 464.146393,274.819702 
	C470.100616,276.582611 476.060760,278.325470 482.026367,280.437927 
	C482.874939,281.777985 483.715729,282.756287 484.556488,283.734619 
	C485.037720,282.830414 485.518951,281.926239 486.380829,280.978790 
	C491.804047,280.163513 492.987183,277.344177 490.942688,272.528015 
	C487.557953,271.111664 484.272888,270.032867 480.987823,268.954041 
	C483.395416,267.979279 485.803040,267.004547 488.604736,266.014893 
	C490.080231,266.342529 491.161652,266.685028 492.600586,267.045044 
M453.470978,239.254547 
	C452.134399,237.615860 450.990173,235.737701 449.377747,234.442444 
	C448.604950,233.821686 446.655426,233.850525 445.771271,234.416687 
	C444.914276,234.965485 444.048248,236.854340 444.376740,237.682358 
	C446.644012,243.397476 449.222931,248.988953 451.846039,254.948090 
	C455.698395,250.188522 455.976044,245.258850 453.470978,239.254547 
z"/>
<path fill="#6FCAE3" opacity="1.000000" stroke="none" 
	d="
M455.100708,224.991455 
	C455.229889,228.931427 453.652405,229.698471 450.520294,228.198288 
	C446.017883,226.041779 442.325928,226.915634 439.182648,231.015259 
	C438.357819,232.091034 436.005402,232.973801 434.783264,232.587769 
	C430.724579,231.305725 426.554260,230.460770 423.830811,234.176880 
	C421.177216,237.797714 419.530121,242.217850 422.706543,246.723831 
	C423.073730,247.244675 423.169037,247.957138 423.588898,249.128494 
	C422.872467,250.120941 421.957581,250.565643 420.774170,250.899902 
	C419.992615,250.552856 419.479584,250.316254 418.989014,249.698914 
	C421.061829,244.660522 418.010254,241.908401 415.234924,238.735107 
	C417.238708,235.369858 419.192169,232.341385 421.424744,229.099701 
	C423.902832,225.428741 426.054626,222.150101 431.174133,223.281967 
	C432.355804,223.543198 433.930267,222.027786 435.323975,221.329926 
	C435.323975,221.329926 435.236145,221.238129 435.536102,221.157501 
	C436.028625,220.652893 436.221222,220.228897 436.413788,219.804901 
	C438.839783,215.916794 442.765106,217.649475 446.542847,217.271057 
	C445.581421,217.883102 444.151398,218.362518 442.808624,219.020004 
	C441.054565,219.878860 439.286530,220.767868 437.737671,221.928329 
	C437.483490,222.118759 438.215149,224.548401 438.677063,224.612946 
	C440.807312,224.910599 443.784271,225.629181 445.015778,224.550552 
	C447.709930,222.190857 450.345428,222.716370 453.162476,223.171616 
	C453.827332,223.279053 454.374786,224.112839 455.100708,224.991455 
z"/>
<path fill="#14232A" opacity="1.000000" stroke="none" 
	d="
M461.110535,234.973267 
	C457.752991,234.929794 456.896210,233.071960 457.462952,230.068268 
	C457.735107,228.625793 457.820953,227.148178 457.987549,225.263885 
	C463.765076,221.985275 468.494965,224.458115 473.495667,228.274887 
	C474.555145,229.401764 475.169708,230.067413 475.784302,230.733047 
	C475.784302,230.733047 475.689636,230.858292 475.802673,231.331085 
	C475.547791,237.215225 475.179932,242.626556 474.429260,248.020386 
	C471.663422,249.337524 469.280426,250.672165 466.895508,251.731537 
	C466.853729,251.277405 466.813812,251.098541 466.868835,250.723892 
	C466.963776,250.528091 467.055237,250.102615 467.322113,249.943604 
	C467.746216,249.198853 467.903442,248.613129 468.036835,248.022308 
	C468.013031,248.017227 468.044128,248.055695 468.355835,247.825867 
	C468.640198,242.947983 468.922668,238.262115 468.363342,233.678955 
	C468.226990,232.561493 465.386719,231.295074 463.669159,231.085770 
	C462.984406,231.002304 461.973145,233.597473 461.110535,234.973267 
z"/>
<path fill="#E7B62A" opacity="1.000000" stroke="none" 
	d="
M484.640381,210.592072 
	C487.180511,213.349350 490.132446,215.567368 488.021912,219.747864 
	C486.517975,222.726837 485.080505,225.541870 481.012451,223.943634 
	C477.303589,222.486511 475.286163,218.368515 477.092468,215.072556 
	C478.546844,212.418701 479.963226,208.811310 484.640381,210.592072 
z"/>
<path fill="#6AC2DB" opacity="1.000000" stroke="none" 
	d="
M474.812073,248.037903 
	C475.179932,242.626556 475.547791,237.215225 475.850769,231.393112 
	C478.716797,234.014313 481.626801,237.066864 484.588104,240.068909 
	C485.919464,241.418579 487.353180,242.667282 488.861053,244.256912 
	C485.423889,245.323090 481.866119,246.094162 477.723450,247.277313 
	C476.363068,247.805557 475.587555,247.921722 474.812073,248.037903 
z"/>
<path fill="#6AC2DB" opacity="1.000000" stroke="none" 
	d="
M475.788635,230.333984 
	C475.169708,230.067413 474.555145,229.401764 473.801147,228.422943 
	C474.372162,228.718140 475.082550,229.326538 475.788635,230.333984 
z"/>
<path fill="#6FCAE3" opacity="1.000000" stroke="none" 
	d="
M453.181061,216.200897 
	C453.477356,215.753082 453.950470,215.482864 454.704041,215.227081 
	C454.442291,215.620514 453.900085,215.999512 453.181061,216.200897 
z"/>
<path fill="#5DBBDA" opacity="1.000000" stroke="none" 
	d="
M469.053345,206.939392 
	C469.032562,206.769302 469.249115,206.476013 469.735840,206.111298 
	C469.767548,206.298645 469.529083,206.557419 469.053345,206.939392 
z"/>
<path fill="#65C6E1" opacity="1.000000" stroke="none" 
	d="
M394.011688,68.001266 
	C398.124359,71.713310 402.237030,75.425354 406.654633,79.611633 
	C406.768738,80.362129 406.577942,80.638390 406.091370,81.082916 
	C405.517670,81.845329 405.239746,82.439468 404.686493,83.177185 
	C403.373596,83.886032 402.336029,84.451286 401.110962,85.087616 
	C400.849182,85.267456 400.690002,85.442871 400.714508,85.474106 
	C400.867981,85.669632 401.062439,85.832977 401.386047,86.230888 
	C401.756531,87.121536 401.984253,87.788269 402.157928,88.884995 
	C403.395630,91.877159 404.687378,94.439346 405.646606,97.078163 
	C402.215607,101.453751 399.117096,105.752708 395.953583,110.265686 
	C395.888580,110.479706 395.711304,110.890434 395.385437,110.921066 
	C394.043457,111.323616 393.027283,111.695534 391.874451,111.940056 
	C391.558441,112.059273 391.379089,112.305885 391.141724,112.690781 
	C390.901947,112.951714 390.812134,113.122681 390.512970,113.421982 
	C389.962463,113.815224 389.713257,114.128456 389.469116,114.366104 
	C389.474243,114.290527 389.323669,114.273575 389.027893,114.341187 
	C388.122894,114.926994 387.513672,115.445183 386.680847,116.001442 
	C386.155334,116.171623 385.970947,116.399040 385.623627,116.807281 
	C385.128052,117.207748 384.913025,117.522705 384.773407,117.836639 
	C384.848816,117.835625 384.817749,117.688011 384.543854,117.757751 
	C384.047272,118.119392 383.824554,118.411308 383.658447,118.713196 
	C383.715027,118.723183 383.682556,118.612953 383.448669,118.676727 
	C382.959564,118.948692 382.672211,119.092987 381.998718,119.122345 
	C381.250519,118.172371 380.330414,116.648506 380.542603,116.469887 
	C383.031982,114.374443 385.691345,112.480919 389.729889,109.473747 
	C385.565674,108.510017 382.282227,107.750130 378.987854,106.622330 
	C377.684998,103.930305 376.393127,101.606194 375.730255,100.413719 
	C377.576721,97.124756 378.890259,94.784973 380.552917,92.448418 
	C381.585876,92.586922 382.269806,92.722198 383.190430,93.125351 
	C384.759277,94.433296 386.037140,95.556389 387.446716,96.478065 
	C388.141174,96.932159 389.079071,97.013885 389.906830,97.264122 
	C389.957458,96.283226 390.294800,95.200516 389.991150,94.345467 
	C389.543304,93.084518 388.638947,91.985695 388.118347,90.582275 
	C389.412048,89.191582 390.516449,88.036430 391.869141,86.807663 
	C392.160370,86.164085 392.203278,85.594124 392.199951,84.649315 
	C392.099243,83.521423 392.044800,82.768387 392.146606,81.802979 
	C391.555634,80.718491 390.808502,79.846390 389.803284,78.843384 
	C389.028290,78.145515 388.511414,77.578552 387.993286,76.646660 
	C388.012299,75.309410 388.032623,74.337082 388.345734,73.206406 
	C389.776917,72.064224 390.915314,71.080399 392.303955,69.946854 
	C393.040009,69.198509 393.525848,68.599884 394.011688,68.001266 
z"/>
<path fill="#12242D" opacity="1.000000" stroke="none" 
	d="
M392.053711,70.096573 
	C390.915314,71.080399 389.776917,72.064224 387.990540,73.162697 
	C386.391479,73.264809 385.440430,73.252274 384.144775,73.064346 
	C382.202545,72.257637 380.604919,71.626328 379.292389,70.748703 
	C382.058289,69.657356 384.539093,68.812317 387.319275,67.787910 
	C389.228699,65.849586 390.343567,64.158707 387.006104,63.043579 
	C387.015930,63.005947 386.941040,62.984890 386.824341,62.671478 
	C385.489563,62.556530 384.271484,62.754990 383.012177,62.947468 
	C382.970947,62.941483 382.933777,63.016048 382.686676,63.017548 
	C382.439575,63.019043 381.981567,63.204700 381.562134,63.200172 
	C371.863953,64.250771 362.818848,66.283340 354.609436,70.855896 
	C350.420685,73.188988 346.637543,76.250275 342.345276,79.046028 
	C339.591064,80.419693 337.162628,81.729721 334.811096,82.668900 
	C335.176727,80.874367 335.465454,79.450691 336.058960,77.856094 
	C339.234039,73.761765 342.104370,69.838356 345.189331,65.788589 
	C345.671051,65.407295 345.758667,65.100960 345.712921,64.506462 
	C345.758911,64.269684 345.935486,63.820751 346.153870,64.053108 
	C347.063873,65.845680 347.741089,67.413155 348.509216,68.934753 
	C348.535126,68.986008 349.477844,68.574409 350.334717,68.312622 
	C351.594788,67.623047 352.513367,66.999413 353.734253,66.222275 
	C359.686615,63.528625 365.392303,61.101742 370.949493,58.372448 
	C372.425079,57.647736 373.444458,55.994091 374.673309,54.766968 
	C372.794922,53.963146 370.765656,52.268951 369.072144,52.555965 
	C366.253876,53.033592 363.650940,54.781731 360.609924,56.010345 
	C359.171692,56.404419 358.080841,56.782990 356.992676,56.805676 
	C357.006317,55.975445 357.017181,55.501102 357.294342,54.869492 
	C358.013214,53.796734 358.465790,52.881241 359.190613,51.825951 
	C359.941589,51.036106 360.420349,50.386066 360.899109,49.736027 
	C361.489349,49.271332 362.079590,48.806637 363.036987,48.051510 
	C363.700195,47.584721 363.819366,47.326664 363.761719,46.986908 
	C364.923370,46.642269 366.085022,46.297626 367.626343,45.886330 
	C378.946991,46.902454 379.959869,47.134384 381.302673,57.277233 
	C384.384308,57.541222 387.363403,57.796432 390.780273,58.373871 
	C391.340698,58.835632 391.481384,58.953640 391.640137,59.050121 
	C391.640137,59.050121 391.881470,59.002113 391.993835,59.253036 
	C392.375702,59.721031 392.645142,59.938095 392.914612,60.155159 
	C392.914612,60.155155 392.883667,60.105843 392.873535,60.134224 
	C393.227386,61.228043 393.591431,62.293484 393.581909,63.754005 
	C389.927765,65.569427 391.082672,67.850853 392.053711,70.096573 
z"/>
<path fill="#63C4E0" opacity="1.000000" stroke="none" 
	d="
M396.018555,110.051651 
	C399.117096,105.752708 402.215607,101.453751 405.874603,97.328323 
	C408.065521,98.245522 409.885529,100.532211 411.127289,96.719185 
	C411.754150,96.295067 412.237274,96.094711 413.065125,95.899200 
	C414.268341,95.601646 415.126862,95.299255 415.985352,94.996872 
	C419.816589,94.173058 423.647797,93.349243 427.742615,92.764084 
	C427.835754,94.501724 427.665344,96.000717 427.896088,97.992752 
	C429.139282,98.944168 429.981323,99.402550 430.823364,99.860931 
	C431.704590,100.529099 433.412750,101.347931 433.323212,101.839226 
	C432.946136,103.908890 432.294342,106.036293 431.225861,107.835365 
	C430.478577,109.093620 428.893127,109.854103 427.041412,110.965851 
	C425.924774,111.418861 425.448792,111.742943 424.972778,112.067017 
	C422.212708,111.055672 419.452606,110.044327 416.265076,108.573975 
	C414.874390,108.130630 413.911224,108.146301 412.948029,108.161972 
	C411.938507,108.209290 410.929016,108.256607 409.296875,108.016228 
	C406.779907,107.977768 404.885529,108.227005 402.756195,108.619080 
	C402.226044,108.965225 401.930908,109.168541 401.543274,109.301643 
	C401.450806,109.231430 401.247467,109.343666 401.191315,109.337067 
	C401.135162,109.330475 401.026031,109.360130 400.682251,109.316849 
	C399.912567,109.437088 399.486664,109.600594 398.676270,109.755836 
	C397.534027,109.848930 396.776306,109.950294 396.018555,110.051651 
M398.460571,108.504555 
	C398.460571,108.504555 398.522766,108.475471 398.460571,108.504555 
z"/>
<path fill="#E3B424" opacity="1.000000" stroke="none" 
	d="
M412.720398,95.894363 
	C412.237274,96.094711 411.754150,96.295067 410.765717,96.671509 
	C408.960754,95.816963 407.515869,94.921867 406.396851,93.722656 
	C404.871735,92.088242 403.595123,90.221901 402.211975,88.455002 
	C401.984253,87.788269 401.756531,87.121536 401.463928,85.986923 
	C401.365570,85.351540 401.332001,85.184044 401.298462,85.016541 
	C402.336029,84.451286 403.373596,83.886032 405.037109,83.091057 
	C405.904388,82.212440 406.145752,81.563545 406.387115,80.914650 
	C406.577942,80.638390 406.768738,80.362129 407.005554,79.835327 
	C407.592316,79.183563 408.133118,78.782349 409.143555,78.297821 
	C410.046906,78.216888 410.480530,78.219261 410.914185,78.221634 
	C411.596588,81.529640 413.260895,83.801514 417.282715,84.377388 
	C417.749969,85.544693 417.882080,86.273918 418.014191,87.003136 
	C417.679810,87.375717 417.345459,87.748299 416.556030,88.435287 
	C414.974091,91.131256 413.847229,93.512810 412.720398,95.894363 
z"/>
<path fill="#66BBCF" opacity="1.000000" stroke="none" 
	d="
M413.065125,95.899193 
	C413.847229,93.512810 414.974091,91.131256 416.473572,88.673798 
	C416.563141,90.461761 416.280151,92.325623 415.991272,94.593185 
	C415.126862,95.299255 414.268341,95.601646 413.065125,95.899193 
z"/>
<path fill="#142931" opacity="1.000000" stroke="none" 
	d="
M392.303955,69.946861 
	C391.082672,67.850853 389.927765,65.569427 393.553162,64.092621 
	C393.897980,64.036148 394.154816,63.981556 394.232849,64.293114 
	C394.374329,65.062492 394.437714,65.520317 394.501099,65.978142 
	C394.340790,66.417442 394.180481,66.856743 394.015930,67.648651 
	C393.525848,68.599884 393.040009,69.198509 392.303955,69.946861 
z"/>
<path fill="#66BBCF" opacity="1.000000" stroke="none" 
	d="
M418.339355,86.933044 
	C417.882080,86.273918 417.749969,85.544693 417.611969,84.445190 
	C419.779327,84.313171 420.604462,85.063217 418.339355,86.933044 
z"/>
<path fill="#66BBCF" opacity="1.000000" stroke="none" 
	d="
M410.904419,77.926994 
	C410.480530,78.219261 410.046906,78.216888 409.306396,78.100449 
	C409.347260,77.527382 409.694946,77.068382 410.042664,76.609375 
	C410.326660,76.950371 410.610626,77.291359 410.904419,77.926994 
z"/>
<path fill="#65C6E1" opacity="1.000000" stroke="none" 
	d="
M394.721680,65.895958 
	C394.437714,65.520317 394.374329,65.062492 394.359924,64.260094 
	C394.586731,64.548271 394.764526,65.181023 394.721680,65.895958 
z"/>
<path fill="#63C4E0" opacity="1.000000" stroke="none" 
	d="
M462.959045,136.766418 
	C457.300476,136.555130 451.655945,135.938004 445.354431,135.018127 
	C444.057587,134.484283 443.417664,134.253189 442.777771,134.022095 
	C441.177612,130.936783 439.577454,127.851479 437.901733,124.423515 
	C439.891693,119.755806 442.636017,118.818130 446.821655,121.701500 
	C447.994293,122.509308 450.186340,121.837280 452.599030,121.891632 
	C455.073120,122.098831 456.858917,122.258469 458.644745,122.418106 
	C464.453918,125.710068 465.573822,130.457779 462.959045,136.766418 
z"/>
<path fill="#E3D060" opacity="1.000000" stroke="none" 
	d="
M458.702667,122.285461 
	C456.858917,122.258469 455.073120,122.098831 452.985229,121.789238 
	C453.351898,119.670502 454.020660,117.701721 454.689423,115.732948 
	C456.191895,116.370285 457.694366,117.007622 459.425812,117.864273 
	C459.686310,118.731377 459.717834,119.379173 459.603210,120.513214 
	C459.307892,121.334755 459.158752,121.670059 459.009644,122.005363 
	C459.009644,122.005363 458.760590,122.152824 458.702667,122.285461 
z"/>
<path fill="#EFE9A2" opacity="1.000000" stroke="none" 
	d="
M431.018066,99.570786 
	C429.981323,99.402550 429.139282,98.944168 428.125854,98.236145 
	C429.363708,97.095345 430.772919,96.204193 432.182129,95.313034 
	C431.859009,96.635567 431.535889,97.958107 431.018066,99.570786 
z"/>
<path fill="#66BBCF" opacity="1.000000" stroke="none" 
	d="
M425.131287,112.308243 
	C425.448792,111.742943 425.924774,111.418861 426.696106,111.060127 
	C426.974365,111.507416 426.957275,111.989357 426.903564,112.832306 
	C426.341248,112.978699 425.815521,112.764084 425.131287,112.308243 
z"/>
<path fill="#66BBCF" opacity="1.000000" stroke="none" 
	d="
M428.906738,114.390244 
	C428.362488,114.320465 427.728546,113.967461 426.958893,113.371964 
	C427.487823,113.455322 428.152435,113.781174 428.906738,114.390244 
z"/>
<path fill="#5BBDDC" opacity="1.000000" stroke="none" 
	d="
M392.842438,59.921059 
	C392.645142,59.938095 392.375702,59.721031 392.115417,59.233967 
	C392.405121,59.146622 392.620331,59.387619 392.842438,59.921059 
z"/>
<path fill="#142931" opacity="1.000000" stroke="none" 
	d="
M391.688507,58.843338 
	C391.481384,58.953640 391.340698,58.835632 391.141632,58.581158 
	C391.065216,58.466217 391.461151,58.314606 391.461151,58.314606 
	C391.461151,58.314606 391.736877,58.636559 391.688507,58.843338 
z"/>
<path fill="#64BAD3" opacity="1.000000" stroke="none" 
	d="
M459.230591,121.835754 
	C459.158752,121.670059 459.307892,121.334755 459.680695,120.828857 
	C459.753418,120.994217 459.602478,121.330177 459.230591,121.835754 
z"/>
<path fill="#60B3CB" opacity="1.000000" stroke="none" 
	d="
M434.003601,409.993591 
	C434.751617,409.996429 435.499664,409.999268 436.701172,410.332581 
	C438.103943,411.107269 439.053223,411.551544 440.002533,411.995789 
	C440.968506,412.774536 441.934448,413.553284 442.951416,414.664795 
	C443.269348,420.979675 441.166107,425.214630 435.374329,428.001251 
	C430.275085,430.454651 425.956970,434.512115 420.892029,437.063446 
	C417.757996,438.642120 413.872986,438.730042 409.636627,439.634125 
	C409.442535,438.466370 409.221039,437.133820 408.987946,435.122437 
	C408.962067,434.258575 408.947784,434.073517 409.187744,433.660767 
	C410.946655,432.915283 412.451324,432.397552 414.132507,432.091095 
	C414.871429,431.875885 415.433838,431.449371 416.223999,430.785767 
	C417.292389,430.347321 418.132996,430.145935 419.185181,430.164673 
	C420.886047,429.214111 422.375336,428.043396 423.934662,426.921326 
	C424.004700,426.969940 423.871460,426.860474 424.240387,426.856689 
	C431.199615,424.304535 434.620819,418.973999 432.381378,414.172699 
	C431.749786,412.818604 429.887238,411.877045 428.382080,411.223877 
	C428.068542,411.087860 426.839142,413.062378 425.881592,414.399628 
	C425.083130,417.092438 424.434814,419.449951 423.522949,422.021698 
	C419.034058,425.440338 414.735382,428.467804 409.014191,427.754303 
	C407.158966,425.866058 405.331299,424.354340 403.472626,422.816956 
	C404.189392,421.487366 404.908325,420.153809 405.620209,418.833282 
	C403.181549,418.510132 401.086060,418.232452 399.240204,417.723633 
	C403.536774,415.503845 407.682404,413.596466 407.320190,407.978821 
	C408.191193,409.054962 409.062164,410.131073 410.014069,411.307129 
	C416.455261,406.077454 423.980255,409.439545 431.738159,409.219666 
	C432.920868,409.624908 433.462219,409.809235 434.003601,409.993591 
z"/>
<path fill="#5CBBDA" opacity="1.000000" stroke="none" 
	d="
M398.990570,417.954773 
	C401.086060,418.232452 403.181549,418.510132 405.620209,418.833282 
	C404.908325,420.153809 404.189392,421.487366 403.472626,422.816956 
	C405.331299,424.354340 407.158966,425.866058 408.651367,427.931763 
	C405.765350,428.450256 403.214600,428.414795 400.378174,428.255798 
	C397.836975,427.794617 395.855621,427.852539 395.979919,431.346466 
	C396.317963,433.098785 396.672211,434.476288 397.026428,435.853790 
	C396.574249,442.132355 393.987946,447.234802 388.167786,450.907379 
	C387.587463,450.939941 387.106750,450.953552 387.106750,450.953552 
	C386.438171,448.879456 385.769592,446.805328 385.241119,444.207642 
	C385.035645,443.092438 384.690033,442.500854 384.344452,441.909271 
	C382.867310,437.221130 381.390198,432.532959 380.087708,427.174438 
	C379.305786,422.163269 380.953888,420.530029 385.427856,420.878937 
	C387.235199,421.019928 389.148224,419.805573 391.388916,419.085083 
	C392.812653,418.897766 393.860626,418.833405 395.055786,418.990845 
	C395.809601,418.926453 396.416260,418.640198 397.330933,418.205627 
	C398.089478,418.023132 398.540039,417.988953 398.990570,417.954773 
z"/>
<path fill="#A99688" opacity="1.000000" stroke="none" 
	d="
M397.448273,435.875244 
	C396.672211,434.476288 396.317963,433.098785 396.347778,431.331665 
	C398.728729,431.140656 401.479218,432.495605 400.663879,428.379364 
	C403.214600,428.414795 405.765350,428.450256 408.678894,428.308228 
	C414.735382,428.467804 419.034058,425.440338 423.794373,422.205811 
	C424.627411,422.490479 424.925476,422.805328 425.117798,423.438629 
	C424.631897,424.791534 424.251678,425.826019 423.871460,426.860474 
	C423.871460,426.860474 424.004700,426.969940 423.605591,426.963531 
	C421.795532,427.952911 420.384583,428.948730 418.973633,429.944519 
	C418.132996,430.145935 417.292389,430.347321 415.935547,430.799683 
	C414.931549,431.327057 414.443787,431.603455 413.955994,431.879822 
	C412.451324,432.397552 410.946655,432.915283 408.826141,433.670258 
	C406.729919,434.548279 405.249573,435.189056 403.769226,435.829834 
	C401.802856,435.852142 399.836487,435.874420 397.448273,435.875244 
z"/>
<path fill="#5297AD" opacity="1.000000" stroke="none" 
	d="
M387.065369,451.398865 
	C387.106750,450.953552 387.587463,450.939941 387.827820,450.957947 
	C388.370667,452.402283 388.673126,453.828583 388.992188,455.620605 
	C388.695557,456.609406 388.382294,457.232544 387.821899,457.962616 
	C387.391174,455.994415 387.207611,453.919281 387.065369,451.398865 
z"/>
<path fill="#6CAFC1" opacity="1.000000" stroke="none" 
	d="
M404.156189,435.871460 
	C405.249573,435.189056 406.729919,434.548279 408.571899,433.897949 
	C408.947784,434.073517 408.962067,434.258575 408.986938,434.721802 
	C407.512756,435.304352 406.027985,435.608704 404.156189,435.871460 
z"/>
<path fill="#132228" opacity="1.000000" stroke="none" 
	d="
M439.756409,344.477509 
	C439.895996,344.948517 440.035583,345.419525 440.342224,346.301208 
	C440.509338,346.711914 440.839020,346.986176 440.839050,346.986176 
	C440.991333,347.554962 441.143616,348.123779 441.472107,349.176666 
	C441.648254,349.660767 441.982208,350.000305 441.982208,350.000305 
	C442.218292,350.820404 442.454407,351.640503 442.856201,353.025879 
	C443.237122,353.915070 443.452362,354.238983 443.667603,354.562927 
	C443.734924,354.889771 443.802246,355.216614 444.006714,356.062866 
	C444.787292,356.915466 445.430664,357.248657 446.074066,357.581879 
	C449.028595,357.937775 452.210358,357.729492 454.875732,358.803192 
	C457.724091,359.950562 461.014191,361.779572 462.428009,364.267639 
	C464.269470,367.508270 462.437378,370.862488 459.163116,373.037903 
	C458.360565,373.571106 457.795135,374.461212 456.703735,375.646393 
	C454.809509,377.035431 453.012329,377.696350 451.942566,378.968811 
	C450.810211,380.315674 450.369873,382.244293 449.293152,383.798523 
	C446.343170,381.670746 446.228546,383.289429 445.934601,385.507751 
	C445.597168,388.054565 444.726196,390.530701 444.206482,392.651062 
	C444.186523,388.843079 444.641144,385.254944 443.741058,382.046875 
	C442.835236,378.818359 443.590149,377.163422 446.158112,375.458832 
	C449.170593,373.459076 451.947021,371.103668 455.111572,368.712646 
	C456.500153,364.938721 453.411041,364.918823 451.535339,364.425171 
	C447.454285,363.351135 443.259552,362.709229 439.054321,361.510315 
	C437.163574,356.364105 435.329803,351.598297 433.496033,346.832458 
	C432.808716,346.873413 432.121399,346.914337 431.434113,346.955261 
	C430.084290,349.276733 428.572235,351.520905 427.420807,353.936920 
	C425.451874,358.068146 423.895996,362.388672 417.832855,361.388672 
	C416.170593,361.114471 414.110016,361.480865 412.639191,362.291595 
	C410.984039,363.203949 407.306824,363.177429 409.299500,367.227356 
	C411.411835,369.476288 413.290558,371.580292 415.471924,373.300568 
	C418.383240,375.596588 419.104767,378.157928 417.138763,381.577820 
	C416.921478,382.205902 416.877991,382.664062 416.663025,383.498322 
	C416.335999,384.683990 416.180542,385.493500 415.891693,386.336731 
	C415.758301,386.370453 415.678894,386.632263 415.678894,386.632263 
	C415.678894,386.632263 415.878632,386.819763 415.694458,387.160583 
	C415.392731,388.002167 415.275238,388.502930 415.022827,389.293518 
	C415.149780,390.122650 415.411652,390.661926 415.686340,391.236633 
	C415.699188,391.272095 415.769257,391.300049 415.908264,391.532745 
	C416.388123,391.768250 416.728943,391.771088 417.343323,391.784821 
	C418.127136,391.615082 418.637329,391.434387 419.386108,391.255310 
	C419.624664,391.256927 419.990082,390.950043 420.280701,390.903473 
	C421.088898,390.588287 421.606445,390.319672 422.361084,390.042969 
	C422.922241,389.929413 423.146759,389.718109 423.291748,389.387573 
	C423.311707,389.374146 423.358307,389.362244 423.666595,389.312042 
	C424.833984,388.703033 425.693085,388.144226 426.723694,387.533417 
	C426.895203,387.481415 427.145111,387.224426 427.421173,387.173676 
	C427.971039,386.875732 428.244873,386.628571 428.838684,386.344238 
	C433.422150,388.483887 437.685577,390.660645 442.278107,392.980042 
	C443.083191,393.111206 443.559204,393.099762 443.973663,393.432953 
	C442.241760,396.633423 443.771149,398.419739 445.984467,399.977417 
	C444.459503,400.136444 442.929230,400.444458 441.411926,400.389984 
	C440.681915,400.363770 439.871460,399.770538 439.276062,399.235352 
	C433.375824,393.931702 427.344421,391.796448 420.372437,397.611267 
	C419.002075,398.754181 416.665344,398.738373 414.039642,399.195770 
	C408.257660,396.359497 406.457458,393.216705 409.084900,387.685577 
	C412.499298,380.497742 411.125702,375.653839 404.318359,371.516174 
	C400.969330,364.314850 401.821686,360.742371 407.803040,357.706421 
	C411.157349,357.181976 414.094604,356.632568 417.372253,356.140015 
	C418.489136,355.808533 419.265656,355.420227 420.299835,354.815002 
	C421.385986,352.726715 422.214508,350.855316 423.195557,348.791138 
	C423.348083,348.598389 423.276428,348.112030 423.337708,347.774902 
	C424.859436,345.257416 426.319855,343.077026 428.120911,340.730286 
	C430.602020,340.130798 432.742462,339.697662 434.882935,339.264557 
	C436.052399,340.404053 437.221863,341.543579 438.683960,343.161865 
	C439.236572,343.919617 439.496490,344.198578 439.756409,344.477509 
M439.506531,395.159515 
	C439.826538,395.098602 440.146545,395.037689 440.466553,394.976776 
	C440.148773,394.904266 439.830994,394.831757 439.506531,395.159515 
z"/>
<path fill="#5DC2E3" opacity="1.000000" stroke="none" 
	d="
M404.466217,371.873596 
	C411.125702,375.653839 412.499298,380.497742 409.084900,387.685577 
	C406.457458,393.216705 408.257660,396.359497 413.644775,399.290649 
	C412.815948,400.599396 411.735565,401.864807 410.451508,402.870056 
	C409.375275,403.712646 408.061371,404.251678 406.521057,405.106934 
	C405.682770,404.697998 405.176178,404.107300 404.663940,403.534149 
	C404.658264,403.551666 404.635376,403.546753 404.559692,403.215454 
	C404.049255,402.376404 403.614563,401.868652 403.144409,401.135742 
	C402.785095,400.606873 402.461304,400.303101 402.033051,399.715210 
	C401.450500,399.087311 400.972412,398.743561 400.473145,398.479797 
	C400.451965,398.559814 400.612274,398.518707 400.526062,398.223785 
	C400.000763,397.516541 399.561707,397.104218 399.013184,396.393097 
	C397.591675,394.425079 396.279633,392.755829 394.911804,390.769287 
	C394.411102,390.040771 393.966248,389.629578 393.608459,389.247223 
	C393.695465,389.276062 393.592926,389.428040 393.706055,388.995728 
	C395.607483,384.007507 397.667084,379.534302 399.089081,374.866852 
	C400.044281,371.731598 401.670654,371.072296 404.466217,371.873596 
z"/>
<path fill="#65C6E1" opacity="1.000000" stroke="none" 
	d="
M449.627319,383.918854 
	C450.369873,382.244293 450.810211,380.315674 451.942566,378.968811 
	C453.012329,377.696350 454.809509,377.035431 456.603363,375.969238 
	C458.094391,378.778168 459.268829,381.723358 460.750488,384.853760 
	C458.297363,388.062073 457.571808,391.163788 460.831909,394.720032 
	C458.280090,400.071991 454.723846,401.209320 449.352448,398.057098 
	C451.991486,392.983246 452.164703,388.488861 449.627319,383.918854 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M449.293152,383.798523 
	C452.164703,388.488861 451.991486,392.983246 449.139282,397.753906 
	C448.211182,398.682831 447.394714,399.263763 446.281372,399.911072 
	C443.771149,398.419739 442.241760,396.633423 443.962769,393.395935 
	C444.013397,393.014282 444.086975,393.037537 444.086975,393.037537 
	C444.726196,390.530701 445.597168,388.054565 445.934601,385.507751 
	C446.228546,383.289429 446.343170,381.670746 449.293152,383.798523 
z"/>
<path fill="#102532" opacity="1.000000" stroke="none" 
	d="
M404.669556,403.516632 
	C405.176178,404.107300 405.682770,404.697998 406.397827,405.499939 
	C406.737244,406.295135 406.868256,406.879089 407.159729,407.720947 
	C407.682404,413.596466 403.536774,415.503845 399.240204,417.723633 
	C398.540039,417.988953 398.089478,418.023132 397.054169,418.138367 
	C395.949127,418.402557 395.428894,418.585785 394.908630,418.769012 
	C393.860626,418.833405 392.812653,418.897766 390.945068,418.993744 
	C386.111511,419.376892 382.097565,419.728455 378.083618,420.080017 
	C378.083618,420.080017 377.999603,419.996765 378.099121,419.603180 
	C379.051666,418.811279 379.872406,418.174225 380.762970,418.053711 
	C386.157410,417.323883 391.601410,416.911865 396.964539,416.010956 
	C403.920227,414.842468 405.807465,411.809875 404.401917,404.968628 
	C404.310120,404.521820 404.572144,404.002319 404.669556,403.516632 
z"/>
<path fill="#5CB5D3" opacity="1.000000" stroke="none" 
	d="
M439.845032,411.719421 
	C439.053223,411.551544 438.103943,411.107269 437.075134,410.333740 
	C437.892975,410.483978 438.790253,410.963501 439.845032,411.719421 
z"/>
<path fill="#5CB5D3" opacity="1.000000" stroke="none" 
	d="
M433.958191,409.722107 
	C433.462219,409.809235 432.920868,409.624908 432.189941,409.219666 
	C432.637878,409.149384 433.275360,409.299988 433.958191,409.722107 
z"/>
<path fill="#66BED5" opacity="1.000000" stroke="none" 
	d="
M99.814507,108.333366 
	C90.559341,107.811951 83.309280,112.104881 77.721794,118.787498 
	C74.615135,122.503052 72.114433,124.146217 67.094368,122.994598 
	C63.590469,122.190788 59.600788,123.504608 55.406960,123.876610 
	C54.990318,122.387550 55.313919,120.809814 54.944324,119.415878 
	C53.302971,113.225487 56.453320,109.737968 61.556892,107.320549 
	C61.614601,106.721642 61.652355,106.242767 61.708164,105.766006 
	C61.923634,103.925278 61.546455,101.027534 62.501137,100.487404 
	C64.015953,99.630371 66.473877,100.095818 68.432106,100.495346 
	C69.619743,100.737648 70.528076,102.194839 71.729622,102.572250 
	C72.678764,102.870377 74.072624,102.643333 74.907990,102.097267 
	C75.457214,101.738251 75.818634,100.145752 75.482315,99.538971 
	C73.865074,96.621155 72.801659,93.491440 76.812393,92.245590 
	C81.017174,90.939468 81.931877,94.536324 82.641296,97.732925 
	C82.697632,97.986717 83.112679,98.160896 83.410751,98.414368 
	C86.541702,96.044159 89.677879,93.669991 93.338890,91.036652 
	C95.875282,92.978737 97.886833,95.179993 100.046753,97.543610 
	C105.792931,94.463989 107.639252,89.392471 107.517448,83.260307 
	C109.400558,83.506935 111.283661,83.753563 113.632919,84.344536 
	C115.533333,86.546188 116.967598,88.403503 118.710388,90.660324 
	C116.685646,90.562042 115.159088,90.487938 113.482872,90.406578 
	C112.530632,94.664574 114.997971,95.024094 117.897423,95.151489 
	C117.911377,95.340477 117.910347,95.719429 117.649406,95.847946 
	C117.175133,96.292526 116.961807,96.608574 116.545624,97.005592 
	C116.095284,97.275169 115.958824,97.524529 115.637177,98.019051 
	C114.471786,101.490417 113.602600,104.777390 112.519920,108.088150 
	C111.784416,108.297890 111.262413,108.483841 110.537231,108.391167 
	C109.886314,108.064194 109.438568,108.015854 108.550156,107.833282 
	C105.344482,107.910492 102.579498,108.121925 99.814507,108.333366 
z"/>
<path fill="#152422" opacity="1.000000" stroke="none" 
	d="
M165.276642,41.305519 
	C165.972977,41.548462 166.234619,41.750736 166.747009,41.981850 
	C167.735077,42.924137 168.472366,43.837593 169.507523,45.348705 
	C169.072021,49.331722 168.338638,52.717083 167.628723,55.994076 
	C169.523605,56.529984 171.807037,57.175789 173.941101,58.166672 
	C173.213974,60.350449 172.636230,62.189148 171.966049,63.667686 
	C169.752548,62.315369 167.539398,60.354279 165.530762,60.545166 
	C162.015320,60.879246 158.625458,62.534676 155.223999,63.301392 
	C156.017670,60.430550 155.214371,60.264130 152.869171,60.926548 
	C151.423096,61.334999 149.634766,60.531803 148.369995,60.183556 
	C153.158707,57.884628 157.784286,55.987545 161.876129,53.282993 
	C163.181625,52.420113 163.070847,49.414490 163.608109,47.389305 
	C161.946243,47.768375 160.042557,47.755714 158.669815,48.603134 
	C155.159958,50.769833 151.805923,53.216637 148.555267,55.762970 
	C147.102768,56.900764 146.047852,58.546116 144.520172,59.928459 
	C143.155396,60.106667 142.082962,60.317528 140.643036,60.625542 
	C132.599777,64.593536 124.907211,68.445816 121.202835,76.708916 
	C121.607933,77.293091 122.013023,77.877258 122.418121,78.461433 
	C123.704262,77.635445 124.990410,76.809456 126.537201,75.932480 
	C127.188904,75.352684 127.579948,74.823875 128.207352,74.042221 
	C130.786499,72.025963 133.129272,70.262535 135.611038,68.912308 
	C136.586380,70.412300 137.422714,71.499107 138.066254,72.802780 
	C136.995621,74.005501 136.117828,74.991348 135.042221,76.268211 
	C133.192291,77.898979 131.434311,79.128799 129.907852,80.599014 
	C125.254906,85.080452 121.416885,85.234093 115.922287,80.704010 
	C115.674774,80.109818 115.417465,79.814400 115.160164,79.518990 
	C114.166222,73.785423 116.371910,69.648003 121.256348,66.607437 
	C123.762276,65.047501 125.987328,63.036358 128.959442,61.080616 
	C130.717590,60.227600 131.855621,59.523102 132.993652,58.818600 
	C136.639709,56.473846 140.508575,54.407394 143.874481,51.712708 
	C147.928391,48.467209 151.554993,44.687958 155.685059,41.012939 
	C158.949646,41.012356 161.895782,41.138603 165.276642,41.305519 
z"/>
<path fill="#65C6E1" opacity="1.000000" stroke="none" 
	d="
M115.912498,81.002792 
	C121.416885,85.234093 125.254906,85.080452 129.907852,80.599014 
	C131.434311,79.128799 133.192291,77.898979 135.371582,76.293076 
	C136.651505,76.395264 137.404282,76.763603 137.989075,77.424118 
	C136.957550,79.442879 136.094009,81.169464 134.867798,82.993164 
	C134.010239,84.402802 133.515350,85.715332 132.992584,87.243118 
	C132.887512,87.740829 132.856644,88.028244 132.490326,88.454483 
	C128.258057,90.210464 124.407532,91.832619 120.616043,93.647079 
	C120.675087,93.839386 120.601738,93.443787 120.309296,93.536240 
	C119.588615,94.052917 119.160385,94.477142 118.519424,94.908989 
	C118.306702,94.916618 117.883469,94.962509 117.883469,94.962509 
	C114.997971,95.024094 112.530632,94.664574 113.482872,90.406578 
	C115.159088,90.487938 116.685646,90.562042 118.710388,90.660324 
	C116.967598,88.403503 115.533333,86.546188 114.044472,84.348938 
	C114.301788,83.222466 114.613693,82.435921 115.192825,81.331558 
	C115.460052,81.013725 115.912498,81.002792 115.912498,81.002792 
z"/>
<path fill="#E3B424" opacity="1.000000" stroke="none" 
	d="
M107.355362,83.252625 
	C107.639252,89.392471 105.792931,94.463989 100.046753,97.543610 
	C97.886833,95.179993 95.875282,92.978737 93.505432,90.707672 
	C93.082275,89.307457 93.017418,87.977058 93.238190,86.263641 
	C93.712097,85.600945 93.900375,85.321266 94.088654,85.041595 
	C94.417160,85.081406 94.699486,84.985405 95.332222,84.355156 
	C95.867638,83.299873 96.006454,82.643028 96.145279,81.986176 
	C96.644165,81.094086 97.143066,80.201988 98.158768,79.092354 
	C99.123978,78.787178 99.572380,78.699539 100.020782,78.611908 
	C100.769852,79.344528 101.518913,80.077156 102.674744,81.280075 
	C104.387848,82.161530 105.694199,82.572678 107.000542,82.983826 
	C107.000542,82.983826 107.193275,83.244942 107.355362,83.252625 
z"/>
<path fill="#16272D" opacity="1.000000" stroke="none" 
	d="
M132.594757,58.592457 
	C131.855621,59.523102 130.717590,60.227600 129.272522,60.955589 
	C128.377167,59.911392 128.055527,58.364887 127.152626,57.862000 
	C123.890747,56.045265 121.896545,53.951828 124.009735,49.721779 
	C130.487167,48.988350 131.406143,50.011044 132.594757,58.592457 
z"/>
<path fill="#66BED5" opacity="1.000000" stroke="none" 
	d="
M106.907120,82.632576 
	C105.694199,82.572678 104.387848,82.161530 102.959885,81.412613 
	C104.163406,81.476997 105.488556,81.879166 106.907120,82.632576 
z"/>
<path fill="#66BED5" opacity="1.000000" stroke="none" 
	d="
M99.947495,78.371521 
	C99.572380,78.699539 99.123978,78.787178 98.333038,78.839836 
	C98.618401,78.580284 99.246300,78.355713 99.947495,78.371521 
z"/>
<path fill="#66BED5" opacity="1.000000" stroke="none" 
	d="
M95.881668,82.200569 
	C96.006454,82.643028 95.867638,83.299873 95.439964,84.151909 
	C95.306755,83.703049 95.462402,83.059006 95.881668,82.200569 
z"/>
<path fill="#66BED5" opacity="1.000000" stroke="none" 
	d="
M115.922287,80.704010 
	C115.912498,81.002792 115.460052,81.013725 115.233894,81.016251 
	C115.027252,80.768738 115.046761,80.518692 115.113213,79.893814 
	C115.417465,79.814400 115.674774,80.109818 115.922287,80.704010 
z"/>
<path fill="#66BED5" opacity="1.000000" stroke="none" 
	d="
M93.856812,85.143906 
	C93.900375,85.321266 93.712097,85.600945 93.278740,85.965042 
	C93.230766,85.781723 93.427872,85.513969 93.856812,85.143906 
z"/>
<path fill="#142931" opacity="1.000000" stroke="none" 
	d="
M339.397827,425.679138 
	C338.619080,427.522644 337.840332,429.366119 336.996796,431.363007 
	C338.270081,433.131531 339.852661,435.329651 341.435211,437.527802 
	C341.045166,437.855957 340.655121,438.184113 340.265076,438.512268 
	C338.761444,438.014069 337.257782,437.515839 335.380066,436.584045 
	C333.456940,432.880646 336.048523,428.200531 331.244202,425.708527 
	C321.525085,427.118011 312.083649,428.724762 302.683441,430.544495 
	C302.322388,430.614380 302.358276,432.734863 302.216858,434.325623 
	C303.187073,442.650665 304.149597,450.549957 305.134399,458.696014 
	C305.201691,459.273499 305.317719,459.577057 305.646820,460.251801 
	C305.877472,461.768585 305.966064,462.887024 306.054626,464.005432 
	C305.703857,464.783356 305.353119,465.561279 304.654816,466.591400 
	C302.420166,464.578278 300.533051,462.312988 298.538605,459.918823 
	C298.270599,460.639801 297.895477,461.648865 297.560089,462.551117 
	C296.976501,460.623138 296.423218,458.795380 295.728516,456.579346 
	C294.733734,452.141876 293.880402,448.092651 293.081085,443.595154 
	C293.100464,440.768311 293.065826,438.389771 293.290222,435.885132 
	C293.336823,434.646576 293.124420,433.534119 292.671844,432.074860 
	C290.831909,431.490814 289.212006,430.958069 287.635864,431.065796 
	C279.427826,431.627014 271.230957,432.351257 262.667664,433.023834 
	C259.875671,434.352020 257.446198,435.675110 254.707458,436.730957 
	C253.828140,435.955505 253.276535,435.028168 252.685196,435.002075 
	C244.136688,434.625031 235.583466,434.275879 227.029358,434.218658 
	C226.109772,434.212494 225.177689,436.072083 224.153839,437.304688 
	C223.997009,437.704437 223.937775,437.865692 223.570160,438.246826 
	C222.208191,446.738861 221.154602,455.011017 220.126144,463.572876 
	C220.092453,464.243774 220.033661,464.624939 219.727814,465.277344 
	C219.371933,466.763458 219.263077,467.978394 219.149948,469.178894 
	C219.145691,469.164459 219.177139,469.191162 219.177139,469.191162 
	C218.367447,469.958893 217.637115,470.839752 216.733429,471.473450 
	C213.820465,473.516174 211.387115,473.754791 210.249237,468.859283 
	C210.625092,465.513733 211.171097,462.795410 212.017303,459.828491 
	C213.803772,451.516083 215.290039,443.452240 216.696472,435.067200 
	C215.254211,433.866821 213.994736,432.503845 212.512360,432.188416 
	C203.960663,430.368683 195.356277,428.796509 186.421112,427.270630 
	C185.847717,428.051178 185.622940,428.701508 185.413498,429.366272 
	C185.428833,429.380646 185.429092,429.422546 185.061020,429.434753 
	C182.339478,429.578461 179.986023,429.709961 177.586380,429.579956 
	C177.500732,429.145844 177.461273,428.973236 177.668518,428.591980 
	C178.094421,428.263733 178.273590,428.144165 178.675842,427.941528 
	C178.739563,427.241119 178.580200,426.623840 178.223358,425.938721 
	C178.025879,425.870880 177.872406,425.482513 177.655487,425.152985 
	C176.691284,424.695251 175.944000,424.567047 175.222626,424.510864 
	C175.248535,424.582886 175.105194,424.636749 174.895645,424.287842 
	C173.678909,423.778687 172.671722,423.618439 171.712067,423.471863 
	C171.759583,423.485565 171.687851,423.553650 171.559448,423.204224 
	C170.505615,422.724426 169.580170,422.594025 168.734192,422.454834 
	C168.813660,422.446075 168.681030,422.535370 168.610901,422.221069 
	C167.694305,421.289307 166.847824,420.671783 166.007004,419.639709 
	C167.282288,418.153809 168.393997,416.609192 169.858078,416.120789 
	C172.115601,415.367676 174.614487,415.338074 177.478470,414.997253 
	C181.423065,414.988037 184.906952,415.118011 188.375549,414.943665 
	C192.588730,414.731873 196.789001,414.263428 201.379227,413.840454 
	C202.795258,413.831451 203.826920,413.888031 205.268997,413.992249 
	C208.450592,414.363586 211.221786,414.687286 213.939880,415.359070 
	C212.041641,418.027649 210.196487,420.348083 207.845215,423.305084 
	C209.523849,423.559784 210.817490,423.756042 212.518036,423.940216 
	C217.961288,424.362427 222.997650,424.796783 228.441803,425.280151 
	C235.843155,425.444855 242.836731,425.560577 250.301331,425.767975 
	C262.085297,425.513763 273.398193,425.167877 285.137451,424.875671 
	C287.884613,424.478058 290.205475,424.026733 293.267120,423.431335 
	C289.914001,419.831390 287.471375,420.988922 284.708038,422.925049 
	C283.590973,422.603333 282.795685,422.294464 281.948944,421.648285 
	C281.264191,419.874817 280.630859,418.438629 279.998779,417.001221 
	C280.000000,417.000000 280.002441,416.997559 280.390930,417.000153 
	C283.136597,416.763702 285.511810,416.232880 287.845367,416.376862 
	C289.752502,416.494507 291.610565,417.415375 293.489227,417.983978 
	C295.536865,418.603699 297.780426,420.062256 299.579590,419.636841 
	C301.782684,419.115906 303.613068,417.018921 304.740906,416.217285 
	C307.408295,415.640839 309.371613,415.104218 311.370911,414.827301 
	C312.543091,414.664917 313.778320,414.864166 314.974579,414.996704 
	C316.052490,415.116119 317.587280,415.915314 318.108185,415.468201 
	C321.171509,412.838715 322.354156,415.139374 323.907318,417.226807 
	C324.664337,418.244202 325.989197,419.704071 326.900818,419.599701 
	C332.424164,418.967255 335.268585,423.442871 339.312531,425.637756 
	C339.347198,425.627258 339.397827,425.679138 339.397827,425.679138 
z"/>
<path fill="#F4BF0B" opacity="1.000000" stroke="none" 
	d="
M211.717117,460.077057 
	C211.171097,462.795410 210.625092,465.513733 209.985565,468.614929 
	C209.273697,470.104553 208.655350,471.211334 207.653381,473.004822 
	C203.405319,467.297546 199.513138,462.068359 195.766006,457.034088 
	C189.741653,460.272308 183.852264,463.438019 176.610565,467.330627 
	C177.364136,463.104370 177.836975,460.452576 178.249146,457.226807 
	C178.188477,456.652832 178.143433,456.293610 178.484665,456.134949 
	C181.236252,447.634033 183.646606,439.291809 186.390350,430.973877 
	C196.222305,432.872467 205.720901,434.746826 215.165649,437.061523 
	C213.980240,445.026917 212.848679,452.551971 211.717117,460.077057 
z"/>
<path fill="#8FC40C" opacity="1.000000" stroke="none" 
	d="
M305.112091,458.449219 
	C304.149597,450.549957 303.187073,442.650665 302.593048,434.209167 
	C312.397308,431.850098 321.833038,430.033264 331.481873,428.633392 
	C333.421783,436.742920 335.148651,444.435486 336.875488,452.128052 
	C336.875488,452.128052 336.770935,452.131805 336.748566,452.179382 
	C337.236237,455.259583 337.746277,458.292175 338.528809,462.945099 
	C332.120056,459.327972 326.932953,456.400360 321.146515,453.134491 
	C318.148132,457.123749 314.927521,461.408722 311.599060,466.001770 
	C311.491272,466.309814 311.503571,466.529419 311.311920,466.627258 
	C310.961609,467.062775 310.802917,467.400391 310.644257,467.738037 
	C310.644257,467.738037 310.738342,467.652435 310.413361,467.672638 
	C309.733948,468.114685 309.379517,468.536499 309.025085,468.958313 
	C308.118652,467.535156 307.212250,466.111938 306.180237,464.347107 
	C305.966064,462.887024 305.877472,461.768585 305.702759,459.996552 
	C305.448456,459.045013 305.280273,458.747131 305.112091,458.449219 
z"/>
<path fill="#EF3B85" opacity="1.000000" stroke="none" 
	d="
M293.027069,444.043427 
	C293.880402,448.092651 294.733734,452.141876 295.658813,457.029358 
	C296.293549,461.582123 296.856537,465.296631 297.419495,469.011139 
	C296.964569,472.207947 295.223999,470.655487 294.011292,469.786591 
	C289.487335,466.545044 285.054047,463.176971 280.701263,459.941498 
	C275.643646,464.344238 270.254181,469.035858 264.497803,473.392639 
	C264.154358,471.685303 264.177887,470.312805 264.394592,468.746521 
	C264.747314,468.029449 264.906891,467.506195 265.376282,467.134308 
	C269.524353,467.633270 274.488678,462.942322 274.945190,458.057129 
	C275.375427,453.453217 277.594116,451.089935 282.181641,451.010315 
	C285.522125,450.952271 288.864471,450.998901 293.027069,450.998901 
	C293.027069,449.522156 293.027069,446.782806 293.027069,444.043427 
z"/>
<path fill="#142931" opacity="1.000000" stroke="none" 
	d="
M178.143433,456.293610 
	C178.143433,456.293610 178.188477,456.652832 178.128296,456.823608 
	C177.570175,457.410736 176.685364,457.783875 176.633301,458.249969 
	C176.019028,463.751617 172.478241,464.655975 167.438278,463.673126 
	C167.005203,462.911011 167.008362,462.475525 166.997101,462.017639 
	C166.982666,461.995239 167.032211,461.975586 167.337799,461.791962 
	C168.071945,460.049774 168.500519,458.491180 168.947113,456.967529 
	C168.965118,457.002441 168.892593,456.972260 169.178528,456.757782 
	C171.237503,452.100891 173.269684,447.740082 174.702103,443.190430 
	C175.861206,439.508881 177.954605,438.259613 181.819473,438.762238 
	C180.554260,444.796173 179.348862,450.544891 178.143433,456.293610 
z"/>
<path fill="#56B8DA" opacity="1.000000" stroke="none" 
	d="
M179.357483,480.977814 
	C177.971268,479.458099 176.960205,477.931946 175.903839,476.025330 
	C180.662033,473.117279 185.594315,470.801392 190.222672,467.985870 
	C193.197281,466.176422 194.902115,466.774841 196.636230,469.486908 
	C197.779572,471.275055 199.324921,472.806213 200.834045,474.757385 
	C200.655579,476.781006 200.333298,478.499725 200.013489,480.603912 
	C199.280396,480.044281 198.526978,479.112457 197.812668,478.151550 
	C195.832764,475.488159 193.542984,474.437439 190.280014,476.119080 
	C186.843414,477.890167 183.254837,479.366364 179.357483,480.977814 
z"/>
<path fill="#D22C77" opacity="1.000000" stroke="none" 
	d="
M160.069229,454.935608 
	C158.838181,451.885498 157.596771,448.858368 156.339066,445.838043 
	C156.287903,445.715179 156.083115,445.656281 156.299637,445.800476 
	C151.448578,447.779449 146.888916,449.817749 142.186356,451.442505 
	C140.882278,451.893066 139.154037,451.116028 137.724701,450.459167 
	C137.991272,449.799622 138.205902,449.669403 138.726532,449.828918 
	C140.151382,449.613037 141.308472,449.147858 142.496201,448.782196 
	C147.629272,447.202087 152.771332,445.651093 158.095276,444.371277 
	C160.094208,447.603027 161.940628,450.533478 163.712692,453.508270 
	C165.556030,456.602722 166.984619,456.584991 168.326050,453.027405 
	C168.765610,453.027039 168.941437,453.030334 169.143250,453.428802 
	C169.077026,454.873383 168.984818,455.922821 168.892609,456.972260 
	C168.892593,456.972260 168.965118,457.002441 168.703278,457.205017 
	C167.971680,458.930237 167.501938,460.452911 167.032196,461.975586 
	C167.032211,461.975586 166.982666,461.995239 166.654114,462.046539 
	C165.867752,462.129669 165.409943,462.161499 164.952118,462.193359 
	C164.791672,461.890656 164.631226,461.587982 164.186813,460.835022 
	C163.630676,460.081818 163.358505,459.778900 163.086334,459.475983 
	C163.086334,459.475983 163.091125,459.168091 162.918701,458.814331 
	C162.525253,458.205200 162.304230,457.949890 162.083221,457.694550 
	C161.798355,457.225555 161.513489,456.756592 160.951553,455.898376 
	C160.535904,455.232208 160.324341,455.032532 160.039795,454.910156 
	C160.039795,454.910156 160.055939,454.959808 160.069229,454.935608 
z"/>
<path fill="#47C6E6" opacity="1.000000" stroke="none" 
	d="
M219.974854,465.006134 
	C220.033661,464.624939 220.092453,464.243774 220.466217,463.389648 
	C221.393845,460.232147 222.006531,457.547577 222.944000,454.993225 
	C225.500931,455.415863 227.733078,455.708282 229.973450,456.445129 
	C229.959442,459.265869 229.937210,461.642181 229.656403,464.217377 
	C227.041199,467.114319 224.684570,469.812347 222.327942,472.510406 
	C221.726013,472.899078 221.124084,473.287750 220.522156,473.676422 
	C220.063980,472.417145 219.605789,471.157898 219.162384,469.544891 
	C219.177139,469.191162 219.145691,469.164459 219.490814,468.979187 
	C219.882233,467.531342 219.928543,466.268738 219.974854,465.006134 
z"/>
<path fill="#172831" opacity="1.000000" stroke="none" 
	d="
M263.005768,474.018372 
	C259.214600,476.754059 255.785095,475.362122 254.035400,470.250458 
	C253.586838,469.941284 253.188431,469.979523 253.231750,469.581116 
	C253.188828,468.124451 253.102600,467.066162 253.149567,466.032593 
	C253.282761,466.057281 253.552887,466.036224 253.911240,466.018982 
	C257.152252,466.327148 260.034882,466.652527 262.938477,467.417023 
	C262.974854,469.910248 262.990295,471.964294 263.005768,474.018372 
z"/>
<path fill="#1B2B35" opacity="1.000000" stroke="none" 
	d="
M297.783020,469.016510 
	C296.856537,465.296631 296.293549,461.582123 295.800262,457.417603 
	C296.423218,458.795380 296.976501,460.623138 297.560089,462.551117 
	C297.895477,461.648865 298.270599,460.639801 298.538605,459.918823 
	C300.533051,462.312988 302.420166,464.578278 304.641113,466.927307 
	C304.008759,472.129700 300.478851,468.542480 297.783020,469.016510 
z"/>
<path fill="#208DB1" opacity="1.000000" stroke="none" 
	d="
M253.188416,469.979553 
	C253.188431,469.979523 253.586838,469.941284 253.784348,469.973389 
	C253.760971,471.464203 253.540070,472.922943 253.071350,476.018402 
	C248.712433,472.229218 245.100769,469.089630 241.818298,465.558289 
	C243.074539,465.115723 244.001602,465.064911 245.228912,465.147400 
	C246.976410,466.241333 248.423660,467.201965 250.066238,468.429199 
	C251.237183,469.123718 252.212799,469.551636 253.188416,469.979553 
z"/>
<path fill="#1589AF" opacity="1.000000" stroke="none" 
	d="
M244.928650,465.014099 
	C244.001602,465.064911 243.074539,465.115723 241.677780,465.231628 
	C236.571045,461.506042 234.150314,466.838165 230.380081,467.568848 
	C230.385559,466.196320 230.691986,465.109100 231.216187,463.757080 
	C238.172424,458.711426 239.331390,458.842102 244.928650,465.014099 
z"/>
<path fill="#252230" opacity="1.000000" stroke="none" 
	d="
M263.312378,473.994568 
	C262.990295,471.964294 262.974854,469.910248 262.959290,466.951965 
	C262.706848,459.971497 262.270844,453.895477 262.264679,447.819000 
	C262.260345,443.545319 262.840729,439.271057 263.174225,435.282471 
	C263.429718,436.234619 263.671021,436.901276 263.837585,438.071838 
	C263.932922,447.369263 264.102997,456.162842 264.211792,465.287109 
	C264.192688,466.062317 264.234833,466.506805 264.164062,467.274323 
	C264.101227,468.045044 264.151337,468.492676 264.201416,468.940338 
	C264.177887,470.312805 264.154358,471.685303 264.178558,473.465515 
	C264.023865,473.905731 263.821442,473.938263 263.312378,473.994568 
z"/>
<path fill="#1999B8" opacity="1.000000" stroke="none" 
	d="
M230.998413,464.021881 
	C230.691986,465.109100 230.385559,466.196320 230.031647,467.620300 
	C227.666534,469.439423 225.348877,470.921814 222.679581,472.457275 
	C224.684570,469.812347 227.041199,467.114319 229.927811,464.214050 
	C230.638000,464.015198 230.818207,464.018555 230.998413,464.021881 
z"/>
<path fill="#142931" opacity="1.000000" stroke="none" 
	d="
M166.664459,468.122681 
	C166.177185,468.665314 165.606201,468.929108 165.035217,469.192902 
	C164.881775,468.757629 164.728348,468.322357 164.574905,467.887085 
	C165.243515,467.872650 165.912125,467.858215 166.664459,468.122681 
z"/>
<path fill="#131E2B" opacity="1.000000" stroke="none" 
	d="
M165.037506,462.479797 
	C165.409943,462.161499 165.867752,462.129669 166.668549,462.068939 
	C167.008362,462.475525 167.005203,462.911011 167.001923,463.673279 
	C166.375504,463.588745 165.749207,463.177460 165.037506,462.479797 
z"/>
<path fill="#122020" opacity="1.000000" stroke="none" 
	d="
M309.203949,469.119049 
	C309.379517,468.536499 309.733948,468.114685 310.360474,467.637390 
	C310.215973,468.147888 309.799408,468.713837 309.203949,469.119049 
z"/>
<path fill="#131E2B" opacity="1.000000" stroke="none" 
	d="
M162.154755,457.938965 
	C162.304230,457.949890 162.525253,458.205200 162.807922,458.707367 
	C162.655151,458.697235 162.440720,458.440308 162.154755,457.938965 
z"/>
<path fill="#131E2B" opacity="1.000000" stroke="none" 
	d="
M160.124207,455.110107 
	C160.324341,455.032532 160.535904,455.232208 160.736572,455.720642 
	C160.548843,455.775146 160.352173,455.567810 160.124207,455.110107 
z"/>
<path fill="#131E2B" opacity="1.000000" stroke="none" 
	d="
M163.173798,459.770569 
	C163.358505,459.778900 163.630676,460.081818 163.974747,460.683136 
	C163.784866,460.676086 163.523071,460.370636 163.173798,459.770569 
z"/>
<path fill="#122020" opacity="1.000000" stroke="none" 
	d="
M310.921082,467.669922 
	C310.802917,467.400391 310.961609,467.062775 311.409546,466.677948 
	C311.531830,466.954437 311.364838,467.278107 310.921082,467.669922 
z"/>
<path fill="#59B9D9" opacity="1.000000" stroke="none" 
	d="
M65.704666,418.165741 
	C69.236099,419.009949 72.413139,420.387177 75.724098,420.900452 
	C77.922821,421.241302 80.313080,420.346497 83.318222,419.996185 
	C85.990204,423.037506 87.961571,426.087463 89.943604,429.402161 
	C89.976906,429.842163 89.999550,430.017395 90.022186,430.192596 
	C88.877739,431.775238 87.172661,434.866699 86.683800,434.685059 
	C84.287407,433.794617 82.136757,432.135376 80.052544,430.546692 
	C78.425354,429.306366 77.152184,427.586853 75.485085,426.417633 
	C73.706749,425.170349 71.677284,424.263062 69.702415,423.322906 
	C68.897575,422.939819 67.958389,422.782318 67.061577,422.662842 
	C65.844269,422.500671 64.608963,422.473541 63.381382,422.388489 
	C64.056648,421.036163 64.731918,419.683807 65.704666,418.165741 
z"/>
<path fill="#E656A2" opacity="1.000000" stroke="none" 
	d="
M89.932938,429.137390 
	C87.961571,426.087463 85.990204,423.037506 83.693779,419.927063 
	C83.813171,418.101959 84.257629,416.337402 85.247055,414.261719 
	C89.184044,413.866638 89.565781,416.331421 88.994148,418.565063 
	C88.168503,421.791290 89.365540,423.652771 91.959923,425.101685 
	C92.003242,425.270264 92.046555,425.438812 92.262009,426.012878 
	C92.561081,426.671082 92.762497,426.828674 93.038406,426.891205 
	C93.856667,427.829865 94.674927,428.768524 95.910019,430.155609 
	C102.186317,436.769440 109.667694,434.537170 116.714027,433.761719 
	C116.733940,439.026733 113.355965,441.049866 108.752464,439.460388 
	C105.190536,438.230530 101.558136,437.204803 97.957062,436.088318 
	C96.961296,435.386749 95.965515,434.685211 94.553223,433.593994 
	C93.473099,432.699127 92.809479,432.193878 92.145866,431.688629 
	C91.829559,431.252228 91.513252,430.815826 90.922516,430.024872 
	C90.504501,429.365448 90.266113,429.187836 89.932938,429.137390 
z"/>
<path fill="#76B0D6" opacity="1.000000" stroke="none" 
	d="
M92.129112,432.020874 
	C92.809479,432.193878 93.473099,432.699127 94.255440,433.503967 
	C94.145355,433.850983 93.916534,433.898407 93.341423,434.001190 
	C92.700874,433.488739 92.406624,432.920959 92.129112,432.020874 
z"/>
<path fill="#76B0D6" opacity="1.000000" stroke="none" 
	d="
M89.943604,429.402161 
	C90.266113,429.187836 90.504501,429.365448 90.678711,429.899658 
	C90.709328,430.129059 90.364708,430.147217 90.193451,430.169922 
	C89.999550,430.017395 89.976906,429.842163 89.943604,429.402161 
z"/>
<path fill="#F0B10E" opacity="1.000000" stroke="none" 
	d="
M377.797333,447.529388 
	C378.112732,448.797760 378.428162,450.066132 379.189331,453.126892 
	C372.566315,449.967438 367.087036,447.353577 361.605286,444.741455 
	C357.563446,450.092865 353.524017,455.442474 348.768341,461.740692 
	C345.581940,449.005066 342.749084,437.682587 340.269165,425.896423 
	C346.440186,424.383484 352.258331,423.334259 358.243866,422.356873 
	C358.686127,422.401642 358.866516,422.256805 359.207397,421.940613 
	C359.617462,421.809082 359.772614,421.731140 360.229065,421.685059 
	C361.200348,421.321960 361.870392,420.927002 362.707184,420.529968 
	C362.873932,420.527924 363.131470,420.315948 363.504089,420.284851 
	C365.297241,419.732880 366.717773,419.212036 368.327606,419.025208 
	C371.207306,427.675995 373.897705,435.992706 376.578613,444.337341 
	C376.569122,444.365234 376.584473,444.422150 376.587006,444.765076 
	C376.992126,445.915131 377.394745,446.722260 377.797333,447.529388 
z"/>
<path fill="#12242D" opacity="1.000000" stroke="none" 
	d="
M404.663910,403.534149 
	C404.572144,404.002319 404.310120,404.521820 404.401917,404.968628 
	C405.807465,411.809875 403.920227,414.842468 396.964539,416.010956 
	C391.601410,416.911865 386.157410,417.323883 380.762970,418.053711 
	C379.872406,418.174225 379.051666,418.811279 378.057373,419.561279 
	C377.882721,422.230225 377.237793,424.752686 377.933350,426.825317 
	C379.843475,432.517273 380.093140,438.121521 377.919861,444.036560 
	C377.028229,444.276031 376.806366,444.349091 376.584473,444.422150 
	C376.584473,444.422150 376.569122,444.365234 376.681366,443.930756 
	C375.214050,437.253265 373.826447,430.953888 372.012512,424.779724 
	C368.840668,413.983734 368.730896,414.015991 380.214020,413.837097 
	C380.998016,413.118561 381.506317,412.521027 382.147247,411.843018 
	C382.279877,411.762543 382.577728,411.849548 383.033081,411.885468 
	C388.110046,411.035278 392.731720,410.149170 397.568268,409.221863 
	C397.436890,408.630554 397.496399,407.850830 397.135956,407.436310 
	C392.993927,402.672546 388.793671,397.959259 384.578522,393.259796 
	C384.413849,393.076172 383.944153,393.166138 383.301514,392.873322 
	C383.031677,390.741455 383.076935,388.864929 383.480774,386.970581 
	C384.415710,386.042633 385.320465,385.192413 385.499908,384.209778 
	C385.871002,382.177185 385.856110,380.074097 386.346954,378.008484 
	C387.789429,378.342316 388.884979,378.667633 389.923706,379.374207 
	C387.059998,384.539398 390.467285,386.929474 393.592926,389.428040 
	C393.592926,389.428040 393.695465,389.276062 393.663513,389.557495 
	C394.076935,390.254822 394.522278,390.670715 394.967621,391.086578 
	C396.279633,392.755829 397.591675,394.425079 399.034851,396.735077 
	C399.648071,397.756805 400.130157,398.137756 400.612274,398.518707 
	C400.612274,398.518707 400.451965,398.559814 400.485962,398.813507 
	C401.059174,399.377899 401.598328,399.688629 402.137482,399.999359 
	C402.461304,400.303101 402.785095,400.606873 403.146667,401.516296 
	C403.668060,402.596893 404.151703,403.071838 404.635376,403.546753 
	C404.635376,403.546753 404.658264,403.551666 404.663910,403.534149 
z"/>
<path fill="#E8E5C6" opacity="1.000000" stroke="none" 
	d="
M359.927795,421.653168 
	C359.772614,421.731140 359.617462,421.809082 359.049255,421.946808 
	C358.425781,422.051514 358.239197,422.144348 358.076447,422.285065 
	C352.258331,423.334259 346.440186,424.383484 340.209229,425.481110 
	C339.796417,425.529510 339.573273,425.541382 339.485535,425.610260 
	C339.397827,425.679138 339.347198,425.627258 339.272156,425.265625 
	C340.057220,423.656952 340.688263,421.641846 341.819702,421.304352 
	C345.476837,420.213531 349.317871,419.739410 353.483765,419.091278 
	C357.096252,418.257355 360.312988,417.359833 363.622253,416.846100 
	C363.520294,418.258545 363.325867,419.287262 363.131470,420.315948 
	C363.131470,420.315948 362.873932,420.527924 362.386719,420.424438 
	C361.242279,420.765015 360.585052,421.209076 359.927795,421.653168 
z"/>
<path fill="#56B8DA" opacity="1.000000" stroke="none" 
	d="
M352.347046,473.951416 
	C351.392548,472.804199 350.780212,471.604889 350.018250,470.091461 
	C353.644379,465.784119 357.420135,461.790894 361.491608,457.591125 
	C363.124481,462.484650 359.142609,469.712463 352.347046,473.951416 
z"/>
<path fill="#122020" opacity="1.000000" stroke="none" 
	d="
M336.923309,451.746887 
	C335.148651,444.435486 333.421783,436.742920 331.562073,428.363739 
	C331.462463,427.098938 331.495728,426.520721 331.529022,425.942535 
	C336.048523,428.200531 333.456940,432.880646 335.018616,436.570068 
	C335.677856,441.781708 336.324493,446.573730 336.923309,451.746887 
z"/>
<path fill="#60B3CB" opacity="1.000000" stroke="none" 
	d="
M378.093445,420.450134 
	C382.097565,419.728455 386.111511,419.376892 390.569305,419.116699 
	C389.148224,419.805573 387.235199,421.019928 385.427856,420.878937 
	C380.953888,420.530029 379.305786,422.163269 380.080933,426.774292 
	C379.300781,424.969757 378.702026,422.895020 378.093445,420.450134 
z"/>
<path fill="#12242D" opacity="1.000000" stroke="none" 
	d="
M386.121185,459.424133 
	C385.062317,456.955048 384.000214,454.448212 382.938080,451.941376 
	C383.700592,451.757843 384.463104,451.574341 385.225616,451.390808 
	C385.521057,453.748474 385.816467,456.106171 386.152161,458.930023 
	C386.192413,459.396210 386.117920,459.386414 386.121185,459.424133 
z"/>
<path fill="#5297AD" opacity="1.000000" stroke="none" 
	d="
M385.717651,461.056244 
	C385.443665,461.452087 384.887177,461.885223 384.152649,462.118286 
	C384.461487,461.643311 384.948303,461.368408 385.717651,461.056244 
z"/>
<path fill="#5297AD" opacity="1.000000" stroke="none" 
	d="
M384.289612,442.200012 
	C384.690033,442.500854 385.035645,443.092438 385.193298,443.847351 
	C384.748444,443.504028 384.491608,442.997406 384.289612,442.200012 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M376.587006,444.765076 
	C376.806366,444.349091 377.028229,444.276031 377.590881,444.125671 
	C377.924072,444.918579 377.916473,445.788818 377.853088,447.094208 
	C377.394745,446.722260 376.992126,445.915131 376.587006,444.765076 
z"/>
<path fill="#56BBDD" opacity="1.000000" stroke="none" 
	d="
M245.851044,486.690613 
	C244.326187,485.961182 242.647858,484.909210 240.937714,483.911774 
	C239.224609,482.912689 237.479752,481.968048 235.364319,480.993408 
	C238.007553,477.511078 240.589691,478.582855 242.866257,481.827332 
	C243.891495,483.288452 244.749634,484.866821 245.851044,486.690613 
z"/>
<path fill="#50B2CE" opacity="1.000000" stroke="none" 
	d="
M126.335976,430.005981 
	C125.678429,429.945618 125.352936,429.832062 125.026695,429.829926 
	C119.293884,429.791901 115.410538,427.006622 113.469482,421.452271 
	C120.877861,421.735718 127.934448,422.411072 135.362793,423.142456 
	C136.455734,423.023468 137.176910,422.848419 138.216431,422.703308 
	C139.208145,423.188660 139.881500,423.644012 140.295090,424.102722 
	C139.726044,424.181396 139.416794,424.256775 139.107544,424.332153 
	C139.465561,424.569305 139.823578,424.806427 140.154327,425.436401 
	C139.836746,426.837006 139.546463,427.844727 139.043594,429.071899 
	C137.957092,430.318573 137.083145,431.345795 136.209213,432.372986 
	C134.746857,431.915039 133.284500,431.457092 131.121887,430.866638 
	C129.612625,430.494171 128.803619,430.254181 127.994614,430.014160 
	C127.552299,430.010986 127.109985,430.007812 126.335976,430.005981 
z"/>
<path fill="#172831" opacity="1.000000" stroke="none" 
	d="
M136.182556,432.750122 
	C137.083145,431.345795 137.957092,430.318573 139.443634,429.015747 
	C142.086319,429.164154 144.116379,429.588165 146.122986,430.387756 
	C143.557312,437.050659 141.015106,443.338043 138.472900,449.625397 
	C138.205902,449.669403 137.991272,449.799622 137.660690,450.105957 
	C135.834961,449.556854 134.177582,448.917877 132.184738,448.100037 
	C133.284821,442.989899 134.720367,438.058563 136.182556,432.750122 
z"/>
<path fill="#76B0D6" opacity="1.000000" stroke="none" 
	d="
M97.976746,436.451782 
	C101.558136,437.204803 105.190536,438.230530 108.752464,439.460388 
	C113.355965,441.049866 116.733940,439.026733 116.714027,433.761719 
	C109.667694,434.537170 102.186317,436.769440 96.201439,430.280762 
	C102.265953,432.369141 108.462517,433.944611 115.100243,431.370361 
	C115.908203,431.057037 117.463066,432.669617 118.856552,433.691345 
	C119.500061,437.664215 118.262512,440.364075 114.314217,441.844452 
	C109.581848,441.792358 104.849533,443.933716 100.895622,440.385101 
	C99.768837,439.373840 98.954193,438.014801 97.976746,436.451782 
z"/>
<path fill="#56BBDD" opacity="1.000000" stroke="none" 
	d="
M128.043182,430.329834 
	C128.803619,430.254181 129.612625,430.494171 130.712311,430.867920 
	C129.664337,433.892761 128.852814,432.475281 128.043182,430.329834 
z"/>
<path fill="#62BFDB" opacity="1.000000" stroke="none" 
	d="
M133.020462,87.027863 
	C133.515350,85.715332 134.010239,84.402802 135.163467,82.948235 
	C136.217651,82.847794 136.613510,82.889389 137.109985,83.082489 
	C137.457001,83.076195 137.703384,82.918396 138.259460,82.607346 
	C140.311768,81.859406 142.054413,81.264725 144.036011,80.952972 
	C148.545364,84.340248 152.406586,83.744164 156.352295,80.377800 
	C157.580353,79.330040 159.713013,79.342552 161.606430,78.970551 
	C161.987259,79.271042 162.087158,79.514214 161.927673,80.148643 
	C159.078842,87.871010 159.616348,88.953423 169.371948,94.131485 
	C170.227493,89.742538 171.022141,85.665939 171.936981,81.395233 
	C172.302780,81.006729 172.481827,80.763367 172.920670,80.373756 
	C177.317184,78.280571 177.849060,74.635422 177.781418,70.126312 
	C177.817123,68.826714 177.790665,68.082649 177.592010,67.138123 
	C177.541199,66.619751 177.662582,66.301849 178.210419,65.989258 
	C182.339966,66.385452 184.449463,65.065575 183.990631,60.683556 
	C183.635834,59.778599 183.290390,59.188747 182.944962,58.598896 
	C182.629974,59.065121 182.314987,59.531349 181.597244,59.998066 
	C179.855194,59.996796 178.515900,59.995045 176.924026,59.793144 
	C175.811127,59.002533 174.950806,58.412064 174.090485,57.821594 
	C171.807037,57.175789 169.523605,56.529984 167.628723,55.994076 
	C168.338638,52.717083 169.072021,49.331722 169.741867,45.528046 
	C174.481247,44.914398 178.980331,44.670101 182.312347,49.673306 
	C183.285522,51.134571 187.493332,50.641914 190.222488,50.670757 
	C192.449066,50.694286 194.681549,50.160198 197.379929,49.813080 
	C197.223343,52.065720 197.113998,53.638817 196.700836,55.771812 
	C192.632492,58.791954 187.905685,60.200878 186.166336,65.990158 
	C190.193497,67.681625 194.121964,69.331642 198.256927,71.189751 
	C199.286942,71.991264 200.110474,72.584686 201.265732,73.164215 
	C202.697815,73.213509 203.798141,73.276703 205.060226,73.675919 
	C206.491776,73.704781 207.761581,73.397636 209.204834,72.836823 
	C209.736908,72.084457 210.095551,71.585747 210.766113,70.935234 
	C211.615555,68.361115 212.153107,65.938812 212.715363,63.347679 
	C212.740082,63.178860 213.020004,62.983696 213.434937,62.991142 
	C216.904892,62.330505 219.959900,61.662418 223.115997,61.356079 
	C224.455215,64.901657 225.693359,68.085495 226.903687,71.552391 
	C227.941742,72.320152 229.007614,72.804848 230.036591,73.620789 
	C229.180740,74.412399 228.404175,74.989677 227.532852,75.305496 
	C226.254425,75.768860 224.908188,76.045212 223.590851,76.401260 
	C224.172516,77.476059 224.826675,78.518929 225.317703,79.633667 
	C225.944458,81.056580 226.439453,82.537529 226.643433,83.996284 
	C225.191147,84.540131 224.086655,85.081154 222.641602,85.775360 
	C221.565292,86.032730 220.829498,86.136917 219.924561,86.015396 
	C219.173508,86.197968 218.591629,86.606239 217.813202,87.258095 
	C216.689224,87.981705 215.761795,88.461739 214.469711,88.970795 
	C212.045792,89.677139 209.986542,90.354462 207.653351,90.845581 
	C201.616028,84.027977 194.809616,80.693558 185.574768,83.873283 
	C178.874283,86.618057 173.663071,90.380386 172.289856,97.993378 
	C171.677139,99.083206 171.348267,100.043671 170.629379,101.003555 
	C168.266052,101.306648 166.292709,101.610321 164.280548,101.593826 
	C164.230194,100.844070 164.218674,100.414490 164.306534,99.869812 
	C164.513855,99.435646 164.418732,99.178421 163.914108,98.939224 
	C163.707687,98.895401 163.285934,98.911430 163.166382,98.565720 
	C160.697189,95.545418 158.347534,92.870827 155.806488,90.087769 
	C155.615067,89.979294 155.177383,89.933762 154.999161,89.607300 
	C150.889526,88.552307 146.958099,87.823784 142.761932,86.828033 
	C139.338287,86.716507 136.179367,86.872185 133.020462,87.027863 
M221.998993,72.639275 
	C222.572647,72.251144 223.146301,71.863022 223.719940,71.474892 
	C223.415207,71.152870 223.110489,70.830841 222.805756,70.508812 
	C222.394608,71.086067 221.983459,71.663322 221.998993,72.639275 
z"/>
<path fill="#6AC8E0" opacity="1.000000" stroke="none" 
	d="
M292.004883,46.001984 
	C294.395081,46.335533 296.785278,46.669086 299.751617,47.298470 
	C301.494690,49.327038 302.661621,51.059772 303.828552,52.792507 
	C303.318329,52.728790 302.808105,52.665073 302.297882,52.601357 
	C300.866882,56.398556 299.435883,60.195751 297.783020,64.184380 
	C297.371643,64.920006 297.182159,65.464195 296.680054,66.077354 
	C296.575836,67.094849 296.784271,68.043365 297.143402,69.328339 
	C297.529816,71.108734 297.765533,72.552689 298.004272,74.309845 
	C298.015381,74.831665 298.023529,75.040283 297.720398,75.381683 
	C296.537109,75.469345 295.665070,75.424232 294.437225,75.385376 
	C293.705811,75.663506 293.330231,75.935394 292.736511,76.410500 
	C291.298889,77.226738 290.079407,77.839752 288.533600,78.520424 
	C287.327972,79.223885 286.448669,79.859695 285.661255,80.629051 
	C285.753204,80.762604 285.619171,80.467323 285.335754,80.561928 
	C284.664917,81.090759 284.277466,81.524986 283.668762,82.032318 
	C283.214722,82.492653 282.981934,82.879883 282.449280,83.466026 
	C281.122681,85.786598 280.095978,87.908264 279.035248,90.037338 
	C279.001221,90.044746 279.028595,90.108795 278.654419,90.182907 
	C274.214050,88.617516 270.147797,86.978020 265.813049,85.032654 
	C264.776428,84.713669 264.008270,84.700539 262.994446,84.893890 
	C262.120483,85.082565 261.492249,85.064774 260.894287,84.592522 
	C260.769592,80.671509 262.007324,78.147736 265.399353,76.710777 
	C266.450409,76.265526 267.139832,74.966568 268.196594,73.834770 
	C268.458405,73.056564 268.518524,72.502060 268.604919,71.583420 
	C268.709412,70.739212 268.787628,70.259148 269.104431,69.672775 
	C269.528168,69.081596 269.713287,68.596710 270.210815,68.036858 
	C271.349060,67.303726 272.174927,66.645576 273.231201,66.227417 
	C273.090820,68.285736 272.720032,70.104057 272.347382,71.922005 
	C274.473846,72.520470 276.583099,73.202606 278.735596,73.696091 
	C281.171295,74.254509 283.451416,74.264526 284.330872,70.982414 
	C285.094452,70.957375 285.531525,70.963333 286.157043,71.165367 
	C286.897736,70.907898 287.449982,70.454353 288.063904,69.663574 
	C288.444977,68.242889 288.764343,67.159454 289.208313,65.720551 
	C289.613312,61.082447 289.893768,56.799812 290.555176,52.380451 
	C291.292389,50.163143 291.648651,48.082565 292.004883,46.001984 
z"/>
<path fill="#5EC2E0" opacity="1.000000" stroke="none" 
	d="
M298.001221,73.996643 
	C297.765533,72.552689 297.529816,71.108734 297.144257,68.955475 
	C296.993805,67.500237 296.993225,66.754311 296.992676,66.008377 
	C297.182159,65.464195 297.371643,64.920006 298.088623,64.011574 
	C300.539246,62.132309 302.462341,60.617287 304.452576,59.383247 
	C304.502960,60.047119 304.486176,60.430000 304.211334,60.969452 
	C304.507019,61.762913 305.060760,62.399803 305.748474,63.364052 
	C305.888855,65.676109 305.895325,67.660797 306.133392,69.957214 
	C308.612518,70.173775 310.860046,70.078613 313.374054,70.058655 
	C314.774506,69.756912 315.908447,69.379967 317.384674,68.913147 
	C318.448639,66.805183 319.170349,64.787102 319.948181,62.489735 
	C320.246216,61.494503 320.488098,60.778557 320.998108,59.908943 
	C321.841705,59.168964 322.417206,58.582653 322.992676,57.996338 
	C325.713287,58.663895 328.433868,59.331463 331.937500,59.999840 
	C333.442108,59.993832 334.163666,59.987011 334.889709,60.331142 
	C334.867493,61.376400 334.840820,62.070709 334.622742,63.058464 
	C332.953796,67.231377 331.476257,71.110840 329.744904,75.209534 
	C327.504547,79.729202 325.636292,84.091736 323.436554,88.280205 
	C322.969299,89.169899 321.293793,89.425018 319.973389,89.667145 
	C317.936584,80.771400 311.345032,77.670837 303.688232,75.234161 
	C301.669647,74.586426 299.835419,74.291534 298.001221,73.996643 
M301.156097,67.492538 
	C301.095306,67.173302 301.034515,66.854065 300.973724,66.534828 
	C300.901306,66.851707 300.828888,67.168594 301.156097,67.492538 
z"/>
<path fill="#5FBBD8" opacity="1.000000" stroke="none" 
	d="
M334.885254,59.980198 
	C334.163666,59.987011 333.442108,59.993832 332.359680,59.999252 
	C331.005096,57.796146 329.564941,55.685158 329.095886,53.376842 
	C327.185089,43.973064 336.297760,34.231899 346.812164,37.166782 
	C348.233093,37.563408 349.710785,37.854694 351.179443,37.965099 
	C357.210846,38.418499 358.301605,39.522018 359.048462,45.727188 
	C359.193542,46.932636 359.932312,48.066628 360.648193,49.484802 
	C360.420349,50.386066 359.941589,51.036106 358.831421,51.814423 
	C356.703522,51.039204 354.782928,50.463280 353.786682,49.173435 
	C350.180176,44.504017 345.249908,45.890530 341.313141,47.180317 
	C336.785461,48.663719 335.352844,53.181396 335.964996,58.296867 
	C335.576294,59.054695 335.230774,59.517448 334.885254,59.980198 
z"/>
<path fill="#6AC2DB" opacity="1.000000" stroke="none" 
	d="
M220.008987,54.978493 
	C222.818619,53.319366 225.621338,51.648380 228.440033,50.004822 
	C230.727615,48.670967 234.001511,47.939201 235.167740,45.957485 
	C240.654541,36.634163 248.825073,36.853642 256.885193,41.624256 
	C260.279327,43.633171 263.351624,46.185841 266.563385,49.016830 
	C266.038513,51.031628 265.520477,52.520920 265.017120,54.433075 
	C265.052612,55.137333 265.073395,55.418728 264.917908,55.716515 
	C264.741669,55.732910 264.648407,55.391411 264.313660,55.183182 
	C261.952148,52.599422 259.907043,50.239227 257.902313,47.845219 
	C253.568665,42.670033 249.107758,42.527893 243.745560,46.606586 
	C240.345352,49.192913 240.359222,52.571594 239.538177,56.157867 
	C233.545685,55.927467 228.014465,55.605251 222.074631,55.204624 
	C221.113678,55.076977 220.561325,55.027733 220.008987,54.978493 
z"/>
<path fill="#69CBE9" opacity="1.000000" stroke="none" 
	d="
M265.002441,54.010208 
	C265.520477,52.520920 266.038513,51.031628 266.777649,49.269875 
	C270.332550,47.788418 273.644989,46.515938 277.006012,45.388199 
	C280.393311,44.251644 283.776520,42.856277 287.269165,42.402027 
	C288.658234,42.221363 290.336456,44.264091 291.943970,45.648293 
	C291.648651,48.082565 291.292389,50.163143 290.241486,52.165005 
	C285.034851,52.743809 280.522919,53.401329 275.908325,53.912033 
	C275.532501,53.881393 275.259338,53.997570 274.659485,54.239799 
	C273.434509,54.300911 272.536163,54.235966 271.159393,54.124161 
	C268.788116,54.054935 266.895294,54.032574 265.002441,54.010208 
z"/>
<path fill="#5FBBD8" opacity="1.000000" stroke="none" 
	d="
M308.998779,47.003510 
	C311.287781,44.199913 313.735046,41.508553 315.823883,38.563019 
	C318.185333,35.233059 321.024780,35.419338 324.031891,37.105484 
	C327.326721,38.952961 324.583313,41.036446 324.000885,43.013851 
	C323.238739,45.601543 322.479675,48.190159 320.983887,50.910233 
	C313.517181,55.390137 313.048309,55.316753 309.838165,49.254200 
	C309.466614,48.552551 309.274231,47.756039 308.998779,47.003510 
z"/>
<path fill="#66BED5" opacity="1.000000" stroke="none" 
	d="
M308.588806,47.002647 
	C309.274231,47.756039 309.466614,48.552551 309.838165,49.254200 
	C313.048309,55.316753 313.517181,55.390137 320.622986,51.021095 
	C321.651550,53.083813 322.305573,55.167522 322.976135,57.623787 
	C322.417206,58.582653 321.841705,59.168964 320.706482,59.722244 
	C314.960541,57.679176 309.878815,52.864243 304.385468,59.102261 
	C302.462341,60.617287 300.539246,62.132309 298.310486,63.820141 
	C299.435883,60.195751 300.866882,56.398556 302.297882,52.601357 
	C302.808105,52.665073 303.318329,52.728790 303.828552,52.792507 
	C302.661621,51.059772 301.494690,49.327038 300.166626,47.298622 
	C302.729919,47.002552 305.454407,47.002167 308.588806,47.002647 
z"/>
<path fill="#5FBBD8" opacity="1.000000" stroke="none" 
	d="
M198.050446,70.981659 
	C194.121964,69.331642 190.193497,67.681625 186.166336,65.990158 
	C187.905685,60.200878 192.632492,58.791954 196.698822,56.164909 
	C197.530609,56.276352 198.060593,56.554596 198.837784,57.365463 
	C199.098358,58.985271 199.111740,60.072460 199.002777,61.477921 
	C198.648529,63.099808 198.416595,64.403427 198.113129,66.144173 
	C198.044556,68.048088 198.047501,69.514877 198.050446,70.981659 
z"/>
<path fill="#60B8D1" opacity="1.000000" stroke="none" 
	d="
M219.734833,55.228973 
	C220.561325,55.027733 221.113678,55.076977 221.938477,55.550591 
	C222.478943,57.648087 222.746918,59.321209 223.014908,60.994331 
	C219.959900,61.662418 216.904892,62.330505 213.354385,62.700981 
	C212.870209,62.003029 212.881531,61.602692 212.878372,60.855888 
	C211.241150,59.370300 209.618423,58.231182 208.144760,56.804359 
	C209.195175,56.010117 210.096512,55.503582 210.997849,54.997044 
	C213.818787,55.157845 216.639740,55.318649 219.734833,55.228973 
z"/>
<path fill="#62BFDB" opacity="1.000000" stroke="none" 
	d="
M210.899078,54.649048 
	C210.096512,55.503582 209.195175,56.010117 207.726501,56.799767 
	C206.608521,57.167561 206.057892,57.252247 205.191956,57.316978 
	C204.250031,57.217003 203.623398,57.136974 202.828003,56.819633 
	C202.115189,56.413502 201.571152,56.244678 201.027100,56.075855 
	C201.027100,54.307777 201.027100,52.539700 201.027100,50.169205 
	C204.740173,51.738983 207.770248,53.020016 210.899078,54.649048 
z"/>
<path fill="#73B6B6" opacity="1.000000" stroke="none" 
	d="
M200.845520,56.288040 
	C201.571152,56.244678 202.115189,56.413502 202.695068,57.114815 
	C201.528976,58.818089 200.327042,59.988869 199.125107,61.159645 
	C199.111740,60.072460 199.098358,58.985271 199.108459,57.493511 
	C199.642593,56.892700 200.153275,56.696461 200.845520,56.288040 
z"/>
<path fill="#52A7C1" opacity="1.000000" stroke="none" 
	d="
M363.580505,47.081223 
	C363.819366,47.326664 363.700195,47.584721 363.183319,47.804771 
	C363.023743,47.569405 363.169342,47.345093 363.580505,47.081223 
z"/>
<path fill="#102129" opacity="1.000000" stroke="none" 
	d="
M146.146454,430.012146 
	C144.116379,429.588165 142.086319,429.164154 139.656219,428.796326 
	C139.546463,427.844727 139.836746,426.837006 140.321945,425.229675 
	C140.529526,424.453186 140.542191,424.276276 140.554871,424.099396 
	C139.881500,423.644012 139.208145,423.188660 137.987640,422.536499 
	C136.623993,422.588593 135.807510,422.837494 134.991028,423.086426 
	C127.934448,422.411072 120.877861,421.735718 113.404976,421.110107 
	C110.662766,419.959381 108.336861,418.758972 105.828560,416.917084 
	C105.615616,415.496887 105.585075,414.718170 105.737923,413.608459 
	C106.322426,411.613281 106.723534,409.949097 107.464081,408.186768 
	C109.886269,404.155670 111.611206,399.974701 114.128670,396.343109 
	C118.896294,389.465607 119.873894,382.589386 115.925049,374.942932 
	C116.817917,371.301910 117.882156,367.920532 117.841446,364.552521 
	C117.799545,361.085999 115.969490,358.831940 111.753998,360.998474 
	C111.212456,360.986786 110.903038,360.979431 110.593582,360.973511 
	C110.433632,360.820343 110.273689,360.667206 109.952423,359.938141 
	C109.823128,358.923950 109.855164,358.485657 110.171143,357.832153 
	C112.637863,356.410248 114.820633,355.203583 117.003403,353.996918 
	C118.549866,354.666016 120.080147,355.376373 121.647964,355.990967 
	C122.875053,356.472015 124.148682,356.834351 125.756172,357.175720 
	C126.110710,357.101990 126.220421,357.210388 126.230476,357.556580 
	C126.259773,358.593475 126.279022,359.284149 126.146591,360.416290 
	C126.016220,367.572906 126.037529,374.288116 125.947083,381.234863 
	C125.781708,381.629303 125.728096,381.792145 125.490814,382.306305 
	C125.630219,388.337372 123.422989,393.126129 120.559868,397.901947 
	C117.904434,402.331360 115.993332,407.206970 113.753693,411.887756 
	C122.341255,415.842621 124.195259,414.925262 125.076851,407.243042 
	C125.165138,406.473755 125.673019,405.752594 126.066956,405.400452 
	C126.451866,408.196075 126.755966,410.600342 127.038795,413.340942 
	C127.670929,414.132172 128.324341,414.587036 129.240250,415.172729 
	C130.669174,415.187714 131.835617,415.071899 133.371613,415.035889 
	C134.483261,415.011566 135.225327,414.907471 136.394684,414.882141 
	C138.550217,415.078247 140.278503,415.195587 142.097382,415.426453 
	C142.188004,415.539978 142.596558,415.632263 142.596558,415.632263 
	C142.596558,415.632263 142.965408,415.427948 143.381897,415.363647 
	C145.201263,415.228729 146.604141,415.158081 148.262024,415.144775 
	C148.674088,415.126740 148.831161,415.051361 149.225296,415.062286 
	C149.623169,415.197906 149.783966,415.247223 149.956787,415.516022 
	C149.968811,415.735535 150.050705,416.168152 150.176575,416.598694 
	C150.424362,417.302277 150.546280,417.575348 150.731415,418.062286 
	C150.778091,418.609253 150.617691,418.843933 150.015656,419.203979 
	C149.529892,420.132904 149.341904,420.838104 149.209259,421.665405 
	C149.264633,421.787567 149.085663,421.987335 148.853668,422.224762 
	C148.494598,422.882568 148.367523,423.302979 148.335846,423.937775 
	C148.413528,424.480774 148.287842,424.760925 147.825195,425.225586 
	C147.464920,425.870911 147.333603,426.283234 147.275482,426.989471 
	C146.947937,428.192963 146.547180,429.102539 146.146454,430.012146 
M136.233322,417.250427 
	C136.308487,420.823883 136.561630,420.989929 142.015259,419.600403 
	C139.603516,418.412109 138.257675,417.749023 136.233322,417.250427 
z"/>
<path fill="#5BC8E9" opacity="1.000000" stroke="none" 
	d="
M107.124641,408.284912 
	C106.723534,409.949097 106.322426,411.613281 105.447899,413.719238 
	C104.667801,415.103271 104.361130,416.045532 104.054459,416.987823 
	C99.817871,416.160248 98.672562,412.838501 98.066963,409.270599 
	C97.771545,407.530182 98.012894,405.698700 98.206116,403.338867 
	C98.400955,402.769318 98.853081,402.639008 98.853081,402.639008 
	C98.853081,402.639008 99.316994,402.717651 99.706650,402.748230 
	C100.692093,402.090515 101.287880,401.402191 101.988922,400.379547 
	C102.328079,399.390289 102.561989,398.735321 102.971970,397.930359 
	C103.271446,397.491455 103.449478,397.241486 103.930588,396.893768 
	C104.003342,395.494141 103.827621,394.231201 103.486732,392.632202 
	C103.197639,391.561584 103.073723,390.826965 102.941055,389.918488 
	C102.885040,389.511841 102.780945,389.308105 102.425079,388.797607 
	C101.683487,387.297424 101.136826,386.133118 100.614166,384.588501 
	C100.679222,383.448761 100.720276,382.689301 101.032440,381.745728 
	C102.371490,380.455292 103.439430,379.348999 104.705673,378.200745 
	C104.903984,378.158813 105.300697,378.075378 105.653908,378.046936 
	C107.325233,376.893524 108.537132,375.593964 109.998672,374.704651 
	C110.978935,374.108185 112.796371,373.468109 113.399529,373.934204 
	C115.301636,375.404022 116.634537,386.875916 115.579399,389.233459 
	C112.741241,395.575043 109.940346,401.933289 107.124641,408.284912 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M102.795898,398.080383 
	C102.561989,398.735321 102.328079,399.390289 101.679825,400.495972 
	C100.615982,401.537018 99.966484,402.127319 99.316986,402.717651 
	C99.316994,402.717651 98.853081,402.639008 98.853081,402.639008 
	C98.853081,402.639008 98.400955,402.769318 98.201958,402.894470 
	C93.492439,401.463989 89.038658,399.696045 84.440788,398.467255 
	C82.819870,398.034088 80.540527,398.419800 79.095497,399.303925 
	C75.739471,401.357300 72.708397,403.941772 68.906746,406.530151 
	C67.167000,406.804199 66.066124,406.859100 64.965240,406.914001 
	C64.530190,406.651154 64.095131,406.388275 63.292770,405.837189 
	C62.622017,405.346313 62.318569,405.143677 62.015121,404.941040 
	C62.011055,404.630676 61.907375,404.358185 61.451180,403.687195 
	C60.943523,402.919037 60.688763,402.587341 60.433998,402.255646 
	C60.433998,402.255646 60.559822,402.217743 60.589359,401.732269 
	C60.506603,400.138092 60.394306,399.029388 60.282013,397.920685 
	C60.282013,397.920685 60.364208,397.745361 60.580765,397.461365 
	C61.021236,395.393036 61.245152,393.608765 61.469063,391.824463 
	C61.335529,390.189697 61.202003,388.554962 61.190132,386.370422 
	C62.850971,384.902435 64.390152,383.984253 65.954247,383.522034 
	C66.020981,386.505615 66.062798,389.033234 66.219772,391.934814 
	C66.667786,394.324249 67.000626,396.339752 67.333466,398.355255 
	C69.382439,397.643494 71.583321,397.199371 73.438583,396.146301 
	C75.378922,395.044983 76.986969,393.358246 78.826294,391.933655 
	C78.912323,391.939941 78.939896,391.769653 79.172203,391.753235 
	C79.727608,391.615875 79.915703,391.384979 80.314651,391.029633 
	C81.120438,390.976685 81.580360,390.938202 82.283279,391.149475 
	C85.395676,392.650208 88.283623,393.860565 91.130531,395.160828 
	C96.701889,397.705414 97.672310,397.099304 98.025146,391.182831 
	C98.543633,393.786285 98.763252,396.142212 99.008461,398.772644 
	C100.275536,398.541046 101.535721,398.310699 102.795898,398.080383 
z"/>
<path fill="#E3D060" opacity="1.000000" stroke="none" 
	d="
M48.004349,389.729431 
	C51.149681,391.403717 52.466450,393.905182 51.458527,397.783875 
	C50.171227,398.726227 48.918930,399.327179 47.082146,399.920929 
	C46.324986,399.916565 46.152321,399.919373 45.979660,399.922180 
	C44.951828,398.165070 43.923992,396.407990 42.917374,394.074158 
	C42.938591,393.497406 42.933956,393.016388 42.933952,393.016388 
	C43.570545,392.265900 44.207134,391.515442 45.334484,390.449036 
	C46.551613,389.998566 47.277981,389.864014 48.004349,389.729431 
z"/>
<path fill="#5BBCDF" opacity="1.000000" stroke="none" 
	d="
M91.910461,424.688782 
	C89.365540,423.652771 88.168503,421.791290 88.994148,418.565063 
	C89.565781,416.331421 89.184044,413.866638 85.389656,413.938843 
	C86.142769,413.419800 87.841110,412.179443 88.344757,412.552399 
	C89.523682,413.425415 90.529907,414.975494 90.879593,416.426514 
	C91.493484,418.973846 91.563301,421.652344 91.910461,424.688782 
z"/>
<path fill="#5BBCDF" opacity="1.000000" stroke="none" 
	d="
M95.666763,419.864563 
	C95.415428,418.724731 95.506645,417.660248 95.597870,416.595734 
	C97.252586,417.085236 98.907303,417.574738 100.562012,418.064270 
	C100.501625,418.581268 100.441238,419.098267 100.380852,419.615265 
	C98.923676,419.723480 97.466499,419.831665 95.666763,419.864563 
z"/>
<path fill="#50B2CE" opacity="1.000000" stroke="none" 
	d="
M104.296326,416.998474 
	C104.361130,416.045532 104.667801,415.103271 105.264503,414.050232 
	C105.585075,414.718170 105.615616,415.496887 105.576508,416.660767 
	C105.183258,417.052399 104.860374,417.040131 104.296326,416.998474 
z"/>
<path fill="#59B9D9" opacity="1.000000" stroke="none" 
	d="
M47.924561,389.445587 
	C47.277981,389.864014 46.551613,389.998566 45.458954,390.180878 
	C45.072582,390.022766 45.052498,389.816895 45.010841,389.301392 
	C45.941101,389.048401 46.892937,389.105072 47.924561,389.445587 
z"/>
<path fill="#59B9D9" opacity="1.000000" stroke="none" 
	d="
M43.001373,387.001221 
	C43.001373,387.001221 42.997349,387.497498 42.994019,387.745544 
	C42.335423,387.225372 41.680161,386.457123 41.262741,385.344849 
	C41.500580,385.000824 42.000000,385.000000 42.000000,385.000000 
	C42.000000,385.000000 42.498482,385.002655 42.748486,385.336365 
	C42.999451,386.113770 43.000412,386.557495 43.001373,387.001221 
z"/>
<path fill="#59B9D9" opacity="1.000000" stroke="none" 
	d="
M39.688324,391.618195 
	C40.004303,391.760376 40.176075,392.038727 40.347855,392.317047 
	C40.079941,392.129486 39.812031,391.941895 39.688324,391.618195 
z"/>
<path fill="#58BDDE" opacity="1.000000" stroke="none" 
	d="
M42.000107,384.666504 
	C42.000000,385.000000 41.500580,385.000824 41.250889,385.001038 
	C40.691696,384.527283 40.382191,384.053345 40.036266,383.289734 
	C40.444130,382.999725 40.888416,382.999451 41.665894,382.999054 
	C41.999462,383.443604 41.999840,383.888306 42.000107,384.666504 
z"/>
<path fill="#59B9D9" opacity="1.000000" stroke="none" 
	d="
M65.202896,407.164795 
	C66.066124,406.859100 67.167000,406.804199 68.684425,406.760315 
	C67.880821,406.986115 66.660683,407.200867 65.202896,407.164795 
z"/>
<path fill="#76B0D6" opacity="1.000000" stroke="none" 
	d="
M92.975876,426.716858 
	C92.762497,426.828674 92.561081,426.671082 92.415955,426.223663 
	C92.619949,426.149597 92.791809,426.320801 92.975876,426.716858 
z"/>
<path fill="#59B9D9" opacity="1.000000" stroke="none" 
	d="
M46.062477,400.069702 
	C46.152321,399.919373 46.324986,399.916565 46.735958,400.014954 
	C46.974262,400.116211 46.574722,400.382172 46.574722,400.382172 
	C46.574722,400.382172 46.145290,400.217194 46.062477,400.069702 
z"/>
<path fill="#59B9D9" opacity="1.000000" stroke="none" 
	d="
M42.806885,393.093750 
	C42.933956,393.016388 42.938591,393.497406 42.836571,393.714966 
	C42.481541,393.686005 42.463295,393.432220 42.806885,393.093750 
z"/>
<path fill="#59B9D9" opacity="1.000000" stroke="none" 
	d="
M62.154053,405.173248 
	C62.318569,405.143677 62.622017,405.346313 63.037079,405.807312 
	C62.863461,405.845612 62.578220,405.625549 62.154053,405.173248 
z"/>
<path fill="#59B9D9" opacity="1.000000" stroke="none" 
	d="
M60.299805,402.686005 
	C60.688763,402.587341 60.943523,402.919037 61.266876,403.562927 
	C61.112938,403.898895 60.890404,403.922638 60.333321,403.974426 
	C60.054386,403.707092 60.109997,403.411743 60.299805,402.686005 
z"/>
<path fill="#65C6E1" opacity="1.000000" stroke="none" 
	d="
M60.887314,232.291397 
	C60.963871,229.280655 62.472473,229.140854 65.171753,229.414902 
	C69.204956,229.824417 73.326942,229.359528 78.081177,229.276001 
	C83.833138,234.526215 88.915070,239.767029 94.202774,245.268951 
	C96.886765,248.235138 99.364975,250.940170 101.864624,253.871399 
	C102.045532,254.361404 102.142448,254.646469 101.770584,254.963623 
	C100.014122,255.174805 98.663887,255.375168 97.188873,255.594040 
	C99.370224,261.205811 102.465897,262.566315 106.282639,260.314331 
	C106.665710,263.101654 106.850250,265.542328 106.807526,268.184143 
	C106.726379,269.262329 106.872482,270.139404 107.101273,271.384521 
	C107.123238,272.498871 107.062508,273.245148 106.691910,273.986694 
	C105.762321,273.985443 105.142601,273.988983 104.426590,273.624969 
	C103.998276,272.588928 103.666260,271.920471 103.299683,270.993561 
	C102.700439,270.085419 102.135742,269.435760 101.388718,268.484344 
	C100.795189,267.970459 100.383980,267.758301 99.926102,267.413971 
	C99.879440,267.281799 99.600487,267.253967 99.531013,267.126648 
	C99.461540,266.999359 99.173973,266.951233 98.914131,266.664246 
	C95.659584,265.585144 92.664871,264.793030 89.844788,263.721802 
	C90.117851,263.015259 90.216278,262.587891 90.651817,262.093292 
	C97.219322,256.040161 95.406326,248.742752 86.760406,246.105667 
	C84.392891,245.826508 82.348221,245.747208 80.272705,245.706100 
	C80.241859,245.744293 80.336777,245.769485 80.236649,245.481049 
	C79.820312,244.945740 79.504105,244.698853 79.112289,244.133026 
	C76.918251,241.772812 74.799812,239.731552 72.372635,237.459030 
	C71.059174,237.227203 70.054443,237.226624 68.642731,237.195724 
	C67.149910,237.142792 66.064064,237.120178 64.894226,236.781479 
	C63.502590,235.074066 62.194950,233.682739 60.887314,232.291397 
z"/>
<path fill="#1A2B2B" opacity="1.000000" stroke="none" 
	d="
M60.498795,232.160309 
	C62.194950,233.682739 63.502590,235.074066 64.970123,237.137787 
	C65.880104,239.281952 66.630196,240.753723 67.189529,242.500778 
	C65.699615,244.514099 64.400452,246.252121 62.758984,248.137451 
	C54.383736,251.166397 54.023952,251.847198 55.901436,260.504730 
	C56.004704,260.980927 55.985355,261.483734 56.001457,262.335999 
	C55.917625,263.176819 55.855423,263.655792 55.522953,263.876617 
	C52.528568,257.226715 52.602108,250.856613 54.691788,244.232147 
	C55.195118,242.636520 53.591179,240.376175 52.956329,238.421509 
	C51.193005,239.213760 49.037289,239.604431 47.739170,240.872803 
	C43.679787,244.839218 45.442394,250.179688 44.645191,254.966019 
	C44.096355,254.921036 43.876862,254.902573 43.634453,254.579926 
	C41.650467,252.456772 39.689400,250.637802 37.728333,248.818848 
	C37.550663,248.405045 37.372997,247.991241 37.015484,247.291336 
	C36.941460,246.867188 37.047287,246.729126 37.574196,246.236420 
	C38.296925,244.893463 38.598579,243.905167 38.949356,242.950043 
	C38.998482,242.983215 38.900650,242.924286 39.140133,242.793854 
	C39.491760,242.330444 39.603901,241.997452 39.759701,241.385468 
	C39.862396,240.734680 39.921432,240.362885 39.980476,239.991089 
	C44.958981,231.998047 52.391750,229.058182 60.498795,232.160309 
z"/>
<path fill="#111B1F" opacity="1.000000" stroke="none" 
	d="
M93.997009,245.007828 
	C88.915070,239.767029 83.833138,234.526215 78.474716,229.109436 
	C75.882820,224.178528 73.567413,219.423569 71.203171,214.306000 
	C73.401794,212.744247 75.649254,211.545090 78.219048,210.665451 
	C80.117409,215.128448 81.693443,219.271927 83.125557,223.745667 
	C82.728104,225.100922 82.474564,226.125900 81.949867,228.247131 
	C83.562370,227.053436 84.278381,226.523392 85.221329,226.206879 
	C86.361061,227.788727 87.273849,229.157043 88.145691,230.941528 
	C89.540352,234.536026 90.997856,237.704697 92.401154,240.897202 
	C92.992050,242.241501 93.468521,243.636093 93.997009,245.007828 
z"/>
<path fill="#E7B62A" opacity="1.000000" stroke="none" 
	d="
M40.565510,211.664246 
	C42.185890,211.536484 43.700893,211.730667 45.553658,211.995163 
	C49.708370,216.153336 50.348339,219.939514 47.255756,223.331619 
	C45.499252,225.258240 43.167767,227.810638 40.712643,223.589722 
	C40.023640,222.405182 38.535946,221.685196 37.060127,220.330078 
	C36.577816,218.946320 36.449989,217.984634 36.322166,217.022934 
	C37.701488,215.344025 39.080811,213.665100 40.565510,211.664246 
z"/>
<path fill="#5FBBD8" opacity="1.000000" stroke="none" 
	d="
M39.683399,239.861115 
	C39.921432,240.362885 39.862396,240.734680 39.532463,241.526947 
	C39.141262,242.273056 39.020958,242.598663 38.900650,242.924286 
	C38.900650,242.924286 38.998482,242.983215 38.692238,243.063797 
	C37.968899,243.521149 37.551804,243.897903 37.134712,244.274658 
	C36.515507,243.152939 35.896309,242.031219 34.871811,240.175278 
	C36.756924,239.989822 38.071625,239.860489 39.683399,239.861115 
z"/>
<path fill="#5FBBD8" opacity="1.000000" stroke="none" 
	d="
M35.922371,217.030975 
	C36.449989,217.984634 36.577816,218.946320 36.733955,220.265717 
	C35.563232,220.530289 34.364201,220.437149 33.165169,220.344009 
	C33.950974,219.242355 34.736778,218.140686 35.922371,217.030975 
z"/>
<path fill="#2D5662" opacity="1.000000" stroke="none" 
	d="
M37.136707,244.592499 
	C37.551804,243.897903 37.968899,243.521149 38.643112,243.030624 
	C38.598579,243.905167 38.296925,244.893463 37.697800,246.006638 
	C37.313118,245.724457 37.225910,245.317383 37.136707,244.592499 
z"/>
<path fill="#162328" opacity="1.000000" stroke="none" 
	d="
M65.929329,383.066040 
	C64.390152,383.984253 62.850971,384.902435 60.993912,385.992554 
	C57.305912,383.225159 53.935787,380.285828 50.283478,377.169617 
	C48.669193,370.150757 51.520664,365.761322 59.598488,364.411346 
	C64.995834,363.509308 67.692856,361.387421 68.437065,355.934387 
	C68.826256,353.082703 70.410141,350.351990 71.678558,347.672729 
	C73.405144,344.025726 76.149910,342.469391 80.507393,344.302490 
	C81.324440,344.887848 81.790771,345.390472 82.577362,346.284210 
	C83.410446,347.262482 83.923271,347.849640 84.436096,348.436798 
	C84.436089,348.436798 84.620720,348.374695 84.582069,348.708984 
	C84.956421,349.585632 85.369423,350.127960 85.782425,350.670288 
	C85.881119,351.070557 85.979813,351.470795 86.282600,352.364563 
	C87.290047,354.254791 88.093399,355.651520 88.896751,357.048279 
	C89.206505,357.859467 89.516258,358.670654 90.075394,360.010529 
	C90.893097,360.952301 91.461418,361.365387 92.029739,361.778503 
	C92.770340,361.833008 93.510933,361.887512 95.017738,361.941254 
	C96.945938,362.040863 98.122612,362.057068 99.267258,362.256775 
	C103.259132,362.953308 107.240608,363.724518 109.064659,368.074890 
	C110.955292,372.584015 107.749008,375.190704 105.300697,378.075378 
	C105.300697,378.075378 104.903984,378.158813 104.347626,378.104797 
	C103.169662,377.372498 102.548065,376.694183 102.060791,375.719452 
	C102.263847,374.795715 102.332565,374.168427 102.426186,373.537537 
	C102.451088,373.533966 102.497307,373.514099 102.816055,373.481323 
	C103.344711,372.893646 103.554619,372.338776 103.747421,371.841522 
	C103.730324,371.899139 103.704788,371.781677 104.003799,371.788391 
	C104.635216,371.512543 104.967621,371.230011 105.300018,370.947449 
	C104.765686,370.706390 104.231346,370.465363 103.742218,370.175781 
	C103.787430,370.127289 103.871544,370.229858 103.734352,369.955383 
	C103.243744,369.487946 102.890335,369.295013 102.394379,369.109131 
	C102.251846,369.116211 102.034035,368.931732 101.817261,368.622284 
	C100.898643,367.918610 100.206673,367.201630 99.493431,367.179810 
	C92.006645,366.950806 85.306206,365.589935 83.138123,356.897003 
	C83.046181,356.528351 82.386909,356.301208 81.949104,355.719727 
	C81.601021,354.954407 81.295776,354.477539 80.863693,353.799622 
	C80.535858,353.347778 80.295250,353.143707 79.827286,352.767517 
	C79.111702,352.339264 78.583832,352.129822 77.986557,351.828857 
	C77.917152,351.737335 77.586395,351.761169 77.586395,351.761169 
	C77.586395,351.761169 77.895508,352.071014 77.895042,352.111572 
	C77.894577,352.152161 77.934517,352.222778 77.590347,352.387054 
	C75.445969,356.535583 73.425606,360.439026 71.922691,364.532440 
	C70.915810,367.274841 69.628937,368.835907 66.551605,369.360260 
	C63.385483,369.899750 60.367123,371.306427 56.989452,372.478912 
	C57.047115,373.511322 57.399429,374.398468 57.788269,375.627563 
	C60.633846,378.225433 63.442902,380.481354 66.171631,382.819763 
	C66.091309,382.902283 65.929329,383.066040 65.929329,383.066040 
z"/>
<path fill="#E7B62A" opacity="1.000000" stroke="none" 
	d="
M44.916260,356.431671 
	C42.460377,350.759125 42.894833,349.896301 48.619167,348.993683 
	C49.649670,349.769745 50.293201,350.569427 50.969990,351.987091 
	C50.581791,354.000427 50.160339,355.395721 49.738892,356.791046 
	C48.247597,356.716736 46.756298,356.642426 44.916260,356.431671 
z"/>
<path fill="#8CB8A0" opacity="1.000000" stroke="none" 
	d="
M50.086788,356.804230 
	C50.160339,355.395721 50.581791,354.000427 51.123978,352.254333 
	C54.113659,354.058960 52.424793,355.463013 50.086788,356.804230 
z"/>
<path fill="#56BBDD" opacity="1.000000" stroke="none" 
	d="
M89.026001,328.935913 
	C88.238823,328.520142 87.451645,328.104401 86.756248,327.737122 
	C88.057045,325.815338 89.206535,324.117065 90.878708,321.971466 
	C91.924706,320.199066 92.681824,318.915985 92.928627,317.541290 
	C93.754913,312.938934 97.080421,310.778259 101.699097,312.279877 
	C100.040298,315.738190 98.490868,318.874603 96.695091,322.326233 
	C96.783241,325.802979 97.117744,328.964508 97.578857,332.270752 
	C97.705475,332.415497 97.681854,332.799377 97.389435,332.952637 
	C97.557625,333.705139 98.018234,334.304413 98.505249,335.040131 
	C98.531654,335.176544 98.269081,335.267578 98.313751,335.600037 
	C98.642525,336.262878 98.926636,336.593231 99.417511,337.184937 
	C100.847908,339.451416 102.071533,341.456573 103.295166,343.461700 
	C103.295166,343.461700 103.337029,343.526245 103.417557,343.823730 
	C103.732208,344.612854 103.966324,345.104553 104.120529,345.954285 
	C104.004166,347.393311 103.967705,348.474304 103.931244,349.555267 
	C104.963638,349.357422 105.996033,349.159576 107.330246,349.037842 
	C108.033394,349.198944 108.434708,349.283844 108.836029,349.368774 
	C108.836029,349.368774 108.879890,349.206482 108.903656,349.460693 
	C109.274139,349.831268 109.620850,349.947601 109.967560,350.063965 
	C109.967560,350.063965 109.967461,349.996704 109.975769,350.219757 
	C109.984085,350.442810 110.066277,350.823975 110.049896,351.186432 
	C110.020760,352.032379 110.008003,352.515869 109.791275,353.128113 
	C109.332474,353.463226 109.135315,353.714783 108.995834,354.011536 
	C106.551666,353.443268 104.107506,352.874969 101.185532,351.828857 
	C98.862282,348.502106 96.040581,345.856171 95.396324,342.757538 
	C94.386703,337.901672 92.589790,333.832306 89.161789,330.380066 
	C89.155724,330.145233 89.149651,329.910370 89.425568,329.386353 
	C90.631470,329.186707 91.555382,329.276215 92.479286,329.365723 
	C91.948906,328.046814 91.201843,327.354004 90.438148,327.335175 
	C89.980865,327.323883 89.497581,328.366791 89.026001,328.935913 
z"/>
<path fill="#64BAD3" opacity="1.000000" stroke="none" 
	d="
M105.653908,378.046936 
	C107.749008,375.190704 110.955292,372.584015 109.064659,368.074890 
	C107.240608,363.724518 103.259132,362.953308 99.267258,362.256775 
	C98.122612,362.057068 96.945938,362.040863 95.379074,361.829590 
	C98.673782,357.815369 102.201973,361.475128 105.795921,361.979431 
	C107.243568,362.182587 108.673157,362.514435 110.527771,362.695190 
	C111.215164,362.880188 111.485756,363.157684 111.704185,363.792236 
	C112.971764,367.835663 114.291496,371.522064 115.611229,375.208496 
	C119.873894,382.589386 118.896294,389.465607 114.128670,396.343109 
	C111.611206,399.974701 109.886269,404.155670 107.464081,408.186768 
	C109.940346,401.933289 112.741241,395.575043 115.579399,389.233459 
	C116.634537,386.875916 115.301636,375.404022 113.399529,373.934204 
	C112.796371,373.468109 110.978935,374.108185 109.998672,374.704651 
	C108.537132,375.593964 107.325233,376.893524 105.653908,378.046936 
z"/>
<path fill="#58BDDE" opacity="1.000000" stroke="none" 
	d="
M88.761124,330.369202 
	C92.589790,333.832306 94.386703,337.901672 95.396324,342.757538 
	C96.040581,345.856171 98.862282,348.502106 100.852371,351.669647 
	C96.057358,351.661530 92.711166,350.496582 91.511116,344.344818 
	C90.553329,339.434967 89.052444,333.319946 82.297897,331.327087 
	C84.140579,330.853821 86.250519,330.606049 88.761124,330.369202 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M110.066277,350.823975 
	C110.066277,350.823975 109.984085,350.442810 109.959839,350.249451 
	C112.249596,351.133179 114.563614,352.210236 116.940514,353.642090 
	C114.820633,355.203583 112.637863,356.410248 109.956497,357.807922 
	C109.457909,357.998871 109.026016,357.988983 109.026016,357.988983 
	C109.236992,357.172821 109.447975,356.356659 110.087006,355.296600 
	C110.676437,354.990143 110.837814,354.927582 111.363312,354.911682 
	C112.722153,354.756592 113.716888,354.554871 115.611679,354.170624 
	C113.208031,352.720032 111.637154,351.772003 110.066277,350.823975 
z"/>
<path fill="#62BFDB" opacity="1.000000" stroke="none" 
	d="
M89.127563,356.829529 
	C88.093399,355.651520 87.290047,354.254791 86.444382,352.505310 
	C87.387505,353.638641 88.372948,355.124725 89.127563,356.829529 
z"/>
<path fill="#58BDDE" opacity="1.000000" stroke="none" 
	d="
M110.999207,354.865051 
	C110.837814,354.927582 110.676437,354.990143 110.256790,355.026123 
	C109.691963,354.879028 109.441063,354.686157 109.120834,354.216187 
	C109.135315,353.714783 109.332474,353.463226 110.004654,353.302246 
	C110.614403,353.853424 110.806801,354.359222 110.999207,354.865051 
z"/>
<path fill="#62BFDB" opacity="1.000000" stroke="none" 
	d="
M84.534203,348.087341 
	C83.923271,347.849640 83.410446,347.262482 82.816528,346.386810 
	C83.367722,346.644806 84.000015,347.191345 84.534203,348.087341 
z"/>
<path fill="#64BAD3" opacity="1.000000" stroke="none" 
	d="
M108.827881,358.147186 
	C109.026016,357.988983 109.457909,357.998871 109.672554,358.023132 
	C109.855164,358.485657 109.823128,358.923950 109.733231,359.688538 
	C109.326836,359.445068 108.978287,358.875214 108.827881,358.147186 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M111.756340,363.435211 
	C111.485756,363.157684 111.215164,362.880188 110.739456,362.392212 
	C110.555817,361.905029 110.531746,361.632385 110.527847,361.168640 
	C110.903038,360.979431 111.212456,360.986786 111.739052,361.406067 
	C111.889626,362.356140 111.822983,362.895660 111.756340,363.435211 
z"/>
<path fill="#64BAD3" opacity="1.000000" stroke="none" 
	d="
M91.973862,361.507629 
	C91.461418,361.365387 90.893097,360.952301 90.298058,360.253906 
	C90.820213,360.391296 91.369095,360.814056 91.973862,361.507629 
z"/>
<path fill="#62BFDB" opacity="1.000000" stroke="none" 
	d="
M85.798447,350.286865 
	C85.369423,350.127960 84.956421,349.585632 84.484894,348.702545 
	C84.889069,348.875671 85.351776,349.389557 85.798447,350.286865 
z"/>
<path fill="#12242D" opacity="1.000000" stroke="none" 
	d="
M35.994881,274.992249 
	C38.886993,274.604156 41.779106,274.216064 45.438919,273.805603 
	C48.867126,274.029602 50.805531,278.932129 54.461205,274.990662 
	C55.540127,274.008667 56.270489,273.045319 57.196083,272.009796 
	C57.391312,271.937622 57.805267,271.893677 58.055771,272.097595 
	C58.898102,273.026031 59.489925,273.750519 60.081749,274.475006 
	C60.317482,274.341431 60.553219,274.207825 60.788952,274.074249 
	C60.527420,272.711212 60.265884,271.348145 60.255901,269.985840 
	C60.675156,269.986176 60.842857,269.985748 61.408581,269.990173 
	C62.868721,269.991364 63.930840,269.987732 64.990372,270.375458 
	C65.325760,272.510529 65.663734,274.254303 65.676117,276.049561 
	C64.711060,276.770630 64.071594,277.440155 63.432133,278.109680 
	C63.919033,278.392853 64.405937,278.675995 64.892838,278.959167 
	C65.262505,277.972382 65.632172,276.985565 66.368851,276.018433 
	C67.221405,276.105011 67.706947,276.171936 68.186737,276.565216 
	C68.785805,277.621399 69.390625,278.351227 69.821487,279.298889 
	C69.104546,280.013092 68.561546,280.509491 67.729202,281.172943 
	C67.298981,283.250885 67.158096,285.161804 66.806244,287.283875 
	C66.426811,288.996155 66.258362,290.497192 65.726479,291.952209 
	C64.183548,291.606171 63.004055,291.306152 61.560760,290.758301 
	C61.199257,289.315887 61.101551,288.121307 61.003845,286.926697 
	C60.670845,286.813782 60.337845,286.700867 60.004841,286.587921 
	C58.840816,288.395782 57.676788,290.203674 56.512760,292.011536 
	C55.779285,291.119385 55.045811,290.227234 53.546829,288.403931 
	C53.260284,295.647064 49.440800,296.641357 44.230740,295.597595 
	C45.633469,293.778503 47.288246,292.273987 49.201607,290.672424 
	C49.949879,290.038666 50.439556,289.501984 50.959564,288.980286 
	C50.989887,288.995270 50.965813,288.932068 51.273304,288.802582 
	C53.336658,287.132141 55.092522,285.591187 56.848385,284.050232 
	C56.653133,283.651154 56.457882,283.252075 56.262627,282.852997 
	C54.508728,283.236969 52.754829,283.620941 50.849808,283.736023 
	C49.799488,282.649200 48.900291,281.831268 47.972771,280.703217 
	C43.961254,278.592804 39.978069,276.792542 35.994881,274.992249 
M59.232632,283.491425 
	C59.523655,283.783783 59.814674,284.076141 60.105694,284.368469 
	C60.309387,284.006958 60.513081,283.645447 60.716785,283.283905 
	C60.262371,283.181396 59.807957,283.078857 59.232632,283.491425 
M44.505497,275.464996 
	C44.505497,275.464996 44.525642,275.510468 44.505497,275.464996 
z"/>
<path fill="#D6D5C5" opacity="1.000000" stroke="none" 
	d="
M56.463764,292.343048 
	C57.676788,290.203674 58.840816,288.395782 60.004841,286.587921 
	C60.337845,286.700867 60.670845,286.813782 61.003845,286.926697 
	C61.101551,288.121307 61.199257,289.315887 61.398560,291.113190 
	C61.350056,293.471436 61.199959,295.226959 61.049866,296.982483 
	C61.019943,301.433380 61.036648,305.885315 60.935295,310.334564 
	C60.898876,311.933258 60.578129,313.525452 60.387146,315.120605 
	C58.656342,314.198181 56.041157,313.687164 55.380531,312.266968 
	C54.043354,309.392273 53.765820,306.024689 52.991890,302.440979 
	C52.932407,302.021637 53.086502,301.625366 53.345085,301.302185 
	C53.723412,300.661041 53.843155,300.343109 53.962906,300.025146 
	C54.780193,297.574951 55.597481,295.124756 56.463764,292.343048 
z"/>
<path fill="#49B3D5" opacity="1.000000" stroke="none" 
	d="
M27.516823,308.389954 
	C27.158485,306.518280 26.461508,304.338226 27.224686,302.976532 
	C29.075504,299.674194 31.605904,296.752686 34.197777,293.297668 
	C34.808117,292.769287 34.989666,292.545258 35.373386,292.194153 
	C36.118931,291.767792 36.568825,291.392700 37.275963,291.036499 
	C37.702488,291.085571 37.871769,291.115784 38.271519,291.347900 
	C38.862232,292.653809 39.418304,293.739258 39.524975,294.867218 
	C39.622143,295.894775 39.231647,296.968475 38.876312,298.295807 
	C37.149094,300.381073 35.601669,302.192322 34.054245,304.003601 
	C34.054245,304.003601 33.823544,304.173981 33.566528,304.487000 
	C33.147434,305.169098 32.985352,305.538177 32.823280,305.907288 
	C31.162966,306.756104 29.502640,307.604919 27.516823,308.389954 
z"/>
<path fill="#54C8E0" opacity="1.000000" stroke="none" 
	d="
M39.056107,298.021851 
	C39.231647,296.968475 39.622143,295.894775 39.524975,294.867218 
	C39.418304,293.739258 38.862232,292.653809 38.509167,291.088928 
	C42.128525,288.860138 45.740696,287.092224 49.666775,285.604156 
	C50.309055,286.899994 50.637432,287.916046 50.965813,288.932068 
	C50.965813,288.932068 50.989887,288.995270 50.670265,289.031677 
	C49.881435,289.635223 49.412224,290.202362 48.943016,290.769470 
	C47.288246,292.273987 45.633469,293.778503 43.775826,295.560150 
	C43.146275,296.052734 42.719589,296.268188 41.873573,296.729675 
	C41.210617,297.246124 40.966995,297.516510 40.723370,297.786865 
	C40.723370,297.786865 40.839241,297.774323 40.521591,297.751007 
	C39.821331,297.825745 39.438721,297.923798 39.056107,298.021851 
z"/>
<path fill="#D93BA0" opacity="1.000000" stroke="none" 
	d="
M43.949425,305.803162 
	C44.145546,306.064758 44.334942,306.331726 44.538837,306.587128 
	C46.525791,309.076172 47.187778,311.906891 44.367958,313.839661 
	C43.146336,314.677032 40.383419,314.270508 38.849258,313.445679 
	C35.245323,311.507965 37.841274,309.106873 39.345314,306.471466 
	C41.154148,305.952789 42.551788,305.877960 43.949425,305.803162 
z"/>
<path fill="#1B3640" opacity="1.000000" stroke="none" 
	d="
M43.963257,305.441437 
	C42.551788,305.877960 41.154148,305.952789 39.462234,306.135803 
	C37.729031,305.500397 36.290108,304.756744 34.452713,304.008362 
	C35.601669,302.192322 37.149094,300.381073 38.876312,298.295837 
	C39.438721,297.923798 39.821331,297.825745 40.499100,297.697266 
	C41.294804,299.105164 41.728951,300.571411 42.317383,301.972870 
	C42.769962,303.050751 43.416698,304.047089 43.963257,305.441437 
z"/>
<path fill="#1B3640" opacity="1.000000" stroke="none" 
	d="
M33.082062,305.740967 
	C32.985352,305.538177 33.147434,305.169098 33.568596,304.630402 
	C33.665405,304.832062 33.503128,305.203369 33.082062,305.740967 
z"/>
<path fill="#1B3640" opacity="1.000000" stroke="none" 
	d="
M40.993999,297.729065 
	C40.966995,297.516510 41.210617,297.246124 41.733177,296.929749 
	C41.762951,297.146271 41.513790,297.408752 40.993999,297.729065 
z"/>
<path fill="#122020" opacity="1.000000" stroke="none" 
	d="
M53.767395,300.179321 
	C53.843155,300.343109 53.723412,300.661041 53.350033,301.089630 
	C53.190266,300.875885 53.348763,300.586975 53.767395,300.179321 
z"/>
<path fill="#D93BA0" opacity="1.000000" stroke="none" 
	d="
M54.112648,275.009308 
	C50.805531,278.932129 48.867126,274.029602 45.880661,273.727783 
	C39.937271,270.682312 34.230934,267.841492 28.770269,264.588287 
	C27.537540,263.853912 27.160233,261.683685 26.389156,260.174408 
	C28.108753,259.598022 30.186073,258.110687 31.479797,258.618713 
	C35.127407,260.051147 38.453117,262.303284 42.261604,264.242737 
	C42.617500,264.253113 42.651596,264.234558 42.730774,264.555481 
	C43.174824,265.109436 43.539703,265.342468 44.053684,265.854980 
	C47.506073,269.092712 50.809361,272.050995 54.112648,275.009308 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M54.461205,274.990662 
	C50.809361,272.050995 47.506073,269.092712 43.937988,265.552612 
	C43.332657,264.725433 42.992126,264.480011 42.651596,264.234558 
	C42.651596,264.234558 42.617500,264.253113 42.603977,264.267578 
	C41.404045,261.478790 40.217632,258.675537 39.318481,255.610413 
	C40.956284,255.193695 42.306828,255.038910 43.657372,254.884125 
	C43.876862,254.902573 44.096355,254.921036 44.818352,255.273926 
	C46.028225,256.881256 46.610222,258.246216 47.465454,259.410492 
	C50.594685,263.670502 53.814903,267.863647 57.000862,272.081970 
	C56.270489,273.045319 55.540127,274.008667 54.461205,274.990662 
z"/>
<path fill="#1F3B3E" opacity="1.000000" stroke="none" 
	d="
M35.938568,274.642151 
	C39.978069,276.792542 43.961254,278.592804 47.707508,280.824219 
	C46.409992,280.832367 45.349407,280.409363 44.147274,279.611237 
	C38.780106,275.505737 32.846737,276.760132 27.081106,276.833160 
	C27.666094,276.375275 28.251080,275.917389 28.883589,275.422302 
	C27.617319,275.007080 26.464422,274.629059 25.110987,273.980713 
	C25.623379,272.700928 26.336308,271.691528 26.933311,270.392456 
	C29.839008,271.499207 32.860630,272.895630 35.938568,274.642151 
z"/>
<path fill="#2D5662" opacity="1.000000" stroke="none" 
	d="
M43.634453,254.579926 
	C42.306828,255.038910 40.956284,255.193695 39.298122,255.255936 
	C38.091297,254.635193 37.192085,254.107025 36.147079,253.297058 
	C36.449955,251.839157 36.898621,250.663040 37.537811,249.152893 
	C39.689400,250.637802 41.650467,252.456772 43.634453,254.579926 
z"/>
<path fill="#59B9D9" opacity="1.000000" stroke="none" 
	d="
M61.128418,392.102203 
	C61.245152,393.608765 61.021236,395.393036 60.503075,397.403625 
	C60.401810,395.879913 60.594791,394.129913 61.128418,392.102203 
z"/>
<path fill="#58BDDE" opacity="1.000000" stroke="none" 
	d="
M43.328743,386.956360 
	C43.000412,386.557495 42.999451,386.113770 42.997726,385.337341 
	C44.071602,384.824707 45.146236,384.644714 46.220871,384.464752 
	C46.340500,384.732330 46.460125,384.999939 46.579754,385.267548 
	C45.605206,385.815521 44.630661,386.363495 43.328743,386.956360 
z"/>
<path fill="#59B9D9" opacity="1.000000" stroke="none" 
	d="
M59.992744,398.151550 
	C60.394306,399.029388 60.506603,400.138092 60.588921,401.666565 
	C60.273785,400.851685 59.988628,399.617065 59.992744,398.151550 
z"/>
<path fill="#172831" opacity="1.000000" stroke="none" 
	d="
M44.288826,279.986328 
	C45.349407,280.409363 46.409992,280.832367 47.735832,281.134338 
	C48.900291,281.831268 49.799488,282.649200 50.657669,283.920563 
	C50.195393,284.690765 49.774136,285.007568 49.352875,285.324341 
	C45.740696,287.092224 42.128525,288.860138 38.278702,290.887024 
	C37.871769,291.115784 37.702488,291.085571 36.967693,291.064636 
	C35.960705,291.464417 35.519222,291.854950 35.077740,292.245483 
	C34.989666,292.545258 34.808117,292.769287 34.321823,292.981750 
	C31.765657,292.909851 29.870945,292.509064 30.228195,289.310486 
	C30.302299,288.647034 28.614025,287.786682 27.373547,287.014160 
	C27.009108,287.011230 27.004267,286.540100 27.330791,286.104980 
	C31.249752,284.723541 34.886417,283.914612 38.417652,282.778107 
	C40.462421,282.120026 42.337429,280.934509 44.288826,279.986328 
M39.544731,286.755524 
	C39.812023,286.942627 40.079315,287.129700 40.346600,287.316803 
	C40.175251,287.039185 40.003899,286.761597 39.544731,286.755524 
z"/>
<path fill="#67B8CC" opacity="1.000000" stroke="none" 
	d="
M66.495407,313.959045 
	C65.898209,310.565796 65.301018,307.172516 65.044632,303.327026 
	C66.225876,302.985870 67.066322,303.096924 68.042892,303.488708 
	C69.150612,304.833221 70.122208,305.897034 71.205292,307.301819 
	C71.597763,310.100098 71.878746,312.557434 72.141556,315.289368 
	C72.339058,316.044312 72.554749,316.524567 72.438187,317.122803 
	C71.403320,319.218903 70.700699,321.197021 69.861572,323.559479 
	C68.612877,319.998169 67.554146,316.978607 66.495407,313.959045 
z"/>
<path fill="#172831" opacity="1.000000" stroke="none" 
	d="
M71.093811,306.960846 
	C70.122208,305.897034 69.150612,304.833221 68.061981,303.095978 
	C67.665878,298.751251 66.000465,296.598755 62.004135,297.000000 
	C62.004135,297.000000 61.526844,296.998566 61.288353,296.990540 
	C61.199959,295.226959 61.350056,293.471436 61.662357,291.361023 
	C63.004055,291.306152 64.183548,291.606171 65.886612,292.260956 
	C68.974709,296.672241 71.539246,300.728790 74.008484,305.123535 
	C72.973396,305.961487 72.033607,306.461151 71.093811,306.960846 
z"/>
<path fill="#52A7C1" opacity="1.000000" stroke="none" 
	d="
M66.378021,314.309326 
	C67.554146,316.978607 68.612877,319.998169 69.861572,323.559479 
	C70.700699,321.197021 71.403320,319.218903 72.497299,317.545898 
	C72.906647,319.561066 72.924629,321.271149 72.942612,322.981201 
	C72.635094,323.465973 72.327568,323.950714 71.907761,324.701355 
	C66.251724,326.063934 65.480713,322.801392 65.791618,318.011475 
	C65.895905,316.582306 66.078270,315.620941 66.378021,314.309326 
z"/>
<path fill="#1F3B3E" opacity="1.000000" stroke="none" 
	d="
M62.058601,297.354736 
	C66.000465,296.598755 67.665878,298.751251 67.925858,302.815277 
	C67.066322,303.096924 66.225876,302.985870 64.959641,302.904572 
	C63.726917,301.192719 62.919991,299.451080 62.058601,297.354736 
z"/>
<path fill="#5BBAD7" opacity="1.000000" stroke="none" 
	d="
M101.808472,311.957947 
	C97.080421,310.778259 93.754913,312.938934 92.928627,317.541290 
	C92.681824,318.915985 91.924706,320.199066 91.089867,321.730408 
	C90.361374,319.014862 89.944412,316.093018 89.234146,312.501587 
	C88.620949,309.584229 88.301048,307.336487 88.385330,305.139771 
	C91.932243,305.244873 95.074966,305.298920 98.188606,305.347717 
	C98.159523,305.342407 98.208176,305.331238 98.433731,305.291626 
	C98.659279,305.252014 98.981140,304.926147 99.295517,304.808899 
	C100.734093,302.788818 101.858284,300.885986 103.148956,298.589172 
	C102.930016,295.776276 102.544586,293.357361 102.099258,290.597717 
	C100.774635,288.018097 99.509911,285.779266 98.596611,283.435669 
	C102.948128,281.496765 104.292297,278.077026 104.522881,273.992554 
	C105.142601,273.988983 105.762321,273.985443 106.844360,274.364502 
	C107.748734,278.831451 108.190781,282.915833 108.632828,287.000214 
	C108.720726,292.238922 108.850471,297.477478 108.868782,302.716431 
	C108.873703,304.124420 108.539566,305.533630 107.821243,307.329102 
	C106.801826,308.106110 106.322266,308.496246 105.842697,308.886383 
	C105.842697,308.886383 105.934364,308.894928 105.595154,308.931580 
	C104.408005,309.901520 103.560059,310.834778 102.712112,311.768036 
	C102.712105,311.768036 102.814766,311.791687 102.550049,311.804718 
	C102.285324,311.817749 101.808472,311.957947 101.808472,311.957947 
z"/>
<path fill="#60B3CB" opacity="1.000000" stroke="none" 
	d="
M76.705597,324.016205 
	C76.660873,322.969116 76.616142,321.922028 76.699684,320.143433 
	C77.592712,316.132599 79.002785,313.588135 83.001030,313.990936 
	C83.001030,313.990936 83.261993,314.194153 83.580917,314.427979 
	C84.230637,314.854889 84.561440,315.048004 84.892235,315.241119 
	C85.215904,317.929596 86.710716,321.439545 85.553505,323.089264 
	C84.239716,324.962219 80.553062,325.170685 77.522346,325.670959 
	C76.999680,324.831482 76.852638,324.423859 76.705597,324.016205 
z"/>
<path fill="#6CBED3" opacity="1.000000" stroke="none" 
	d="
M87.981140,305.088715 
	C88.301048,307.336487 88.620949,309.584229 88.969238,312.200378 
	C88.303253,312.077667 87.608894,311.586548 86.453796,310.683838 
	C85.325966,310.177094 84.658875,310.081879 83.991783,309.986664 
	C83.232460,309.901123 82.473129,309.815552 81.015228,309.698395 
	C79.405304,309.654266 78.493950,309.641724 77.390175,309.354736 
	C76.441620,307.728424 75.685478,306.376556 75.253998,304.883179 
	C77.291527,303.991760 79.004387,303.241882 80.729660,302.486572 
	C79.673660,300.275238 78.858994,298.569244 78.379272,296.939514 
	C81.803177,299.706726 84.892159,302.397736 87.981140,305.088715 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M77.582596,309.629181 
	C78.493950,309.641724 79.405304,309.654266 80.639297,309.783447 
	C81.640251,311.034180 82.318573,312.168243 82.998962,313.646637 
	C79.002785,313.588135 77.592712,316.132599 76.640419,319.754974 
	C75.487061,321.025848 74.521233,321.953644 73.249008,322.931335 
	C72.924629,321.271149 72.906647,319.561066 72.829544,317.427917 
	C72.554749,316.524567 72.339058,316.044312 72.464203,315.191956 
	C74.397545,313.089661 75.990074,311.359406 77.582596,309.629181 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M84.238289,310.229980 
	C84.658875,310.081879 85.325966,310.177094 86.240547,310.515015 
	C85.820290,310.662903 85.152542,310.568085 84.238289,310.229980 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M76.549736,324.216217 
	C76.852638,324.423859 76.999680,324.831482 77.193817,325.566101 
	C76.958557,325.400787 76.676216,324.908508 76.549736,324.216217 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M84.775108,314.961090 
	C84.561440,315.048004 84.230637,314.854889 83.745850,314.415833 
	C83.947235,314.340271 84.302612,314.510681 84.775108,314.961090 
z"/>
<path fill="#B5C115" opacity="1.000000" stroke="none" 
	d="
M44.147278,279.611237 
	C42.337429,280.934509 40.462421,282.120026 38.417652,282.778107 
	C34.886417,283.914612 31.249752,284.723541 27.327618,285.869446 
	C24.830782,284.932007 20.268747,287.491974 20.602446,283.200043 
	C20.769087,281.056732 24.377501,279.180969 26.755129,277.008667 
	C32.846737,276.760132 38.780106,275.505737 44.147278,279.611237 
z"/>
<path fill="#62BFDB" opacity="1.000000" stroke="none" 
	d="
M435.108978,338.963104 
	C432.742462,339.697662 430.602020,340.130798 427.902405,340.507507 
	C427.413269,339.047272 427.454132,337.641266 427.561493,336.240326 
	C427.670258,334.821655 427.844879,333.408051 427.992371,331.616821 
	C427.996887,330.489807 427.999725,329.738190 428.002563,328.986542 
	C435.576263,323.808441 442.392944,326.994873 449.681580,331.222656 
	C451.113373,332.002502 452.008179,332.459686 452.902954,332.916870 
	C455.901367,334.302338 457.740234,336.248444 456.035095,340.274536 
	C455.833801,340.738220 455.527008,340.791351 455.527008,340.791351 
	C452.783203,333.986053 441.591553,334.311523 435.108978,338.963104 
z"/>
<path fill="#DCBD38" opacity="1.000000" stroke="none" 
	d="
M455.492981,340.891510 
	C455.527008,340.791351 455.833801,340.738220 455.955536,340.643311 
	C458.050507,340.713043 460.023712,340.877655 462.345734,341.172974 
	C463.064056,342.915802 463.433594,344.527893 463.848969,346.498169 
	C463.894806,346.856354 463.896332,346.910797 463.659241,346.986755 
	C463.259949,347.357697 463.097717,347.652618 462.935486,347.947571 
	C462.935486,347.947571 462.992859,347.944458 462.621582,347.947021 
	C461.184753,348.345856 460.119232,348.742096 459.053680,349.138306 
	C458.432709,349.005402 457.811707,348.872528 456.703125,348.508575 
	C455.847992,348.044861 455.480499,347.812225 455.113007,347.579590 
	C455.113007,347.579590 455.078247,347.245728 454.949341,346.847839 
	C454.590424,346.165649 454.360443,345.881348 454.130432,345.597046 
	C454.151703,344.964874 454.172974,344.332703 454.374237,343.202759 
	C454.786346,342.146088 455.018433,341.587250 455.250549,341.028412 
	C455.250549,341.028412 455.458923,340.991669 455.492981,340.891510 
z"/>
<path fill="#67B8CC" opacity="1.000000" stroke="none" 
	d="
M459.299225,349.359894 
	C460.119232,348.742096 461.184753,348.345856 462.591736,347.938660 
	C461.803741,348.478943 460.674255,349.030212 459.299225,349.359894 
z"/>
<path fill="#52A7C1" opacity="1.000000" stroke="none" 
	d="
M445.992340,357.323364 
	C445.430664,357.248657 444.787292,356.915466 444.148132,356.272827 
	C444.738434,356.330566 445.324524,356.697723 445.992340,357.323364 
z"/>
<path fill="#67B8CC" opacity="1.000000" stroke="none" 
	d="
M454.946411,341.045227 
	C455.018433,341.587250 454.786346,342.146088 454.308533,342.891663 
	C454.255951,342.406281 454.449097,341.734192 454.946411,341.045227 
z"/>
<path fill="#52A7C1" opacity="1.000000" stroke="none" 
	d="
M443.683777,354.263794 
	C443.452362,354.238983 443.237122,353.915070 443.033997,353.294067 
	C443.264099,353.319519 443.482056,353.642090 443.683777,354.263794 
z"/>
<path fill="#52A7C1" opacity="1.000000" stroke="none" 
	d="
M442.113953,349.882263 
	C441.982208,350.000305 441.648254,349.660767 441.636292,349.422974 
	C441.831451,349.378204 442.038574,349.571228 442.113953,349.882263 
z"/>
<path fill="#67B8CC" opacity="1.000000" stroke="none" 
	d="
M455.247986,347.893433 
	C455.480499,347.812225 455.847992,348.044861 456.388062,348.560425 
	C456.168060,348.631317 455.775543,348.419281 455.247986,347.893433 
z"/>
<path fill="#67B8CC" opacity="1.000000" stroke="none" 
	d="
M463.165649,347.854004 
	C463.097717,347.652618 463.259949,347.357697 463.678345,347.006165 
	C463.864166,347.292511 463.684601,347.562775 463.165649,347.854004 
z"/>
<path fill="#67B8CC" opacity="1.000000" stroke="none" 
	d="
M454.160431,345.871826 
	C454.360443,345.881348 454.590424,346.165649 454.830627,346.729187 
	C454.624023,346.721191 454.407196,346.433899 454.160431,345.871826 
z"/>
<path fill="#52A7C1" opacity="1.000000" stroke="none" 
	d="
M440.984985,346.880737 
	C440.839020,346.986176 440.509338,346.711914 440.493500,346.498169 
	C440.695435,346.447998 440.913208,346.611633 440.984985,346.880737 
z"/>
<path fill="#52A7C1" opacity="1.000000" stroke="none" 
	d="
M439.722900,344.181763 
	C439.496490,344.198578 439.236572,343.919617 438.922821,343.354919 
	C439.142456,343.341461 439.415924,343.613739 439.722900,344.181763 
z"/>
<path fill="#182D3B" opacity="1.000000" stroke="none" 
	d="
M474.429260,248.020386 
	C475.587555,247.921722 476.363068,247.805557 477.522430,247.567932 
	C476.654816,249.879227 475.403320,252.311966 474.130371,255.302826 
	C473.918060,256.023560 473.810486,256.229675 473.436157,256.569489 
	C469.106323,260.340820 462.671631,260.878937 459.482117,266.766876 
	C463.234985,266.766876 466.156219,266.766876 469.077454,266.766876 
	C469.063934,266.966125 469.050446,267.165405 468.692871,267.826294 
	C466.237427,268.862762 464.126038,269.437622 462.014679,270.012451 
	C459.438446,268.596649 456.862183,267.180878 453.914398,265.325012 
	C457.218201,261.400574 460.893555,257.916229 464.560425,254.420532 
	C464.551941,254.409149 464.566498,254.387070 464.783661,254.271332 
	C465.261139,253.913208 465.373871,253.615021 465.165527,253.172852 
	C464.992004,253.084656 465.331848,253.277405 465.562622,253.164078 
	C466.161407,252.702789 466.529419,252.354813 466.897461,252.006836 
	C469.280426,250.672165 471.663422,249.337524 474.429260,248.020386 
z"/>
<path fill="#D93BA0" opacity="1.000000" stroke="none" 
	d="
M473.786194,256.479248 
	C473.810486,256.229675 473.918060,256.023560 474.178955,255.697845 
	C476.988007,254.420792 479.652954,252.972794 482.499054,252.342072 
	C483.807098,252.052200 486.318634,253.012604 486.747528,254.044724 
	C487.231659,255.209732 486.455292,257.889648 485.403351,258.506897 
	C482.764893,260.055115 479.661682,260.811218 476.358337,261.499603 
	C475.243256,259.569214 474.514740,258.024231 473.786194,256.479248 
z"/>
<path fill="#E167B4" opacity="1.000000" stroke="none" 
	d="
M473.436157,256.569489 
	C474.514740,258.024231 475.243256,259.569214 475.976044,261.480530 
	C473.883179,263.405762 471.786102,264.964661 469.383240,266.645233 
	C466.156219,266.766876 463.234985,266.766876 459.482117,266.766876 
	C462.671631,260.878937 469.106323,260.340820 473.436157,256.569489 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M461.868408,270.334595 
	C464.126038,269.437622 466.237427,268.862762 468.674438,268.125336 
	C472.731232,268.300934 476.462402,268.639130 480.590698,268.965698 
	C484.272888,270.032867 487.557953,271.111664 490.653259,272.516998 
	C490.097168,272.732147 489.730865,272.620789 489.336426,272.485535 
	C489.308319,272.461639 489.235138,272.471558 489.027893,272.250458 
	C488.240784,271.899017 487.660950,271.768646 486.954102,271.555725 
	C486.827087,271.473175 486.529205,271.528473 486.181519,271.311737 
	C478.288971,269.557190 470.791077,269.176514 463.397949,271.379974 
	C463.327698,271.826721 463.257446,272.273438 463.187225,272.720184 
	C467.822571,274.022125 472.457916,275.324097 477.424377,276.755798 
	C478.670593,277.127380 479.585663,277.369202 480.761810,277.800415 
	C481.648041,278.109406 482.273193,278.229004 483.009460,278.439331 
	C483.120514,278.530029 483.407288,278.536407 483.570343,278.748901 
	C483.890808,279.261444 484.048218,279.561493 483.840759,279.903748 
	C482.990112,279.989349 482.504364,280.032776 482.018585,280.076202 
	C476.060760,278.325470 470.100616,276.582611 464.146393,274.819702 
	C462.053406,274.200043 460.356049,273.309692 461.868408,270.334595 
z"/>
<path fill="#56B0CF" opacity="1.000000" stroke="none" 
	d="
M428.001953,328.574097 
	C427.999725,329.738190 427.996887,330.489807 427.640656,331.673035 
	C424.541931,333.420380 421.862915,335.645660 419.036682,335.851685 
	C414.048309,336.215332 408.970215,335.348145 403.868652,334.533417 
	C403.248993,329.261719 402.690277,324.452759 402.149841,319.475403 
	C402.168121,319.306976 402.015717,319.004364 402.068115,318.557434 
	C402.042511,315.416962 401.964478,312.723419 401.953125,309.696106 
	C402.003906,308.909180 401.988037,308.456055 402.111084,307.568481 
	C401.868835,303.966766 401.487732,300.799591 401.170105,297.303406 
	C401.154297,296.315155 401.075012,295.655884 401.049500,294.541748 
	C401.049347,290.732117 400.995361,287.377380 401.024536,283.581757 
	C401.137604,280.465546 401.167572,277.790161 401.451721,275.071075 
	C401.877686,275.032135 402.049561,275.036957 402.435547,275.294922 
	C403.132965,277.028625 403.616241,278.509186 403.823669,280.301514 
	C403.691711,286.828247 403.969666,293.043793 403.942871,299.258026 
	C403.914337,305.886108 402.795135,312.787567 410.039093,317.179871 
	C410.563690,318.018951 411.059052,318.558960 411.752991,319.352722 
	C412.626434,320.080750 413.301300,320.555084 414.299133,321.270630 
	C416.752197,321.701294 418.882233,321.890686 421.272827,322.122986 
	C421.689667,322.084991 421.845978,322.004120 422.389648,321.951019 
	C424.499725,322.455475 426.222473,322.932190 427.939026,323.652100 
	C427.975494,324.215179 428.013550,324.535553 428.046997,324.856445 
	C428.031769,325.958160 428.016571,327.059906 428.001953,328.574097 
z"/>
<path fill="#5297AD" opacity="1.000000" stroke="none" 
	d="
M428.430542,324.872620 
	C428.013550,324.535553 427.975494,324.215179 428.191864,323.506378 
	C428.623199,323.027435 428.795502,322.937408 429.322021,322.896301 
	C434.179901,320.882751 434.465393,319.810394 431.338562,316.534973 
	C431.441223,313.806335 431.486633,311.458862 431.621826,308.782898 
	C431.198639,307.611816 430.685669,306.769196 430.103516,305.580994 
	C427.997345,303.275879 425.960388,301.316376 424.026184,299.032745 
	C424.780945,296.766724 425.432983,294.824799 426.290009,293.106659 
	C426.688843,293.898987 426.882690,294.467560 426.982361,295.330017 
	C428.026398,297.560181 429.164551,299.496490 430.240936,301.327698 
	C432.423004,300.203430 433.680206,299.555664 434.976624,299.282288 
	C437.089508,305.003571 439.616638,299.758484 441.955627,300.346924 
	C442.971863,302.525177 443.943390,304.308380 445.106537,306.229309 
	C445.298126,306.367096 445.741638,306.528381 445.757263,306.776184 
	C446.064514,307.407990 446.356171,307.791992 446.749725,308.537476 
	C448.581116,309.768646 450.310577,310.638336 452.040039,311.508057 
	C449.462738,313.122620 446.885468,314.737213 444.139099,316.650421 
	C439.704346,321.096527 434.965881,324.341583 428.430542,324.872620 
z"/>
<path fill="#8CB8A0" opacity="1.000000" stroke="none" 
	d="
M452.988220,332.574158 
	C452.008179,332.459686 451.113373,332.002502 450.028656,331.289856 
	C449.228485,330.104034 448.301453,329.242737 448.080078,328.227570 
	C447.721802,326.584717 447.145172,323.872375 447.885559,323.351013 
	C449.223694,322.408691 451.885986,322.015503 453.205322,322.771576 
	C454.401611,323.457184 455.024506,325.982727 455.010712,327.689850 
	C454.998413,329.208832 453.766907,330.717926 452.988220,332.574158 
z"/>
<path fill="#1B2B35" opacity="1.000000" stroke="none" 
	d="
M452.313232,311.382202 
	C450.310577,310.638336 448.581116,309.768646 447.138092,308.479462 
	C448.980438,307.069885 450.536346,306.079803 452.388855,304.994324 
	C453.103546,304.253418 453.521637,303.607849 454.259033,302.803528 
	C455.723511,299.777863 457.676178,296.949982 457.817200,294.034424 
	C458.010071,290.046356 458.636719,286.592041 461.752625,283.809326 
	C466.394012,288.273254 470.727753,292.621643 475.061462,296.970032 
	C473.553467,298.504364 472.045471,300.038696 470.207947,301.413086 
	C469.179535,298.437714 468.906616,294.287231 464.787537,295.871216 
	C461.491638,297.138641 461.571442,300.875854 463.093231,304.063110 
	C463.302094,304.500610 463.689667,304.852814 463.994537,305.244476 
	C463.359924,305.967468 462.725311,306.690460 462.042297,307.704529 
	C459.175629,307.278534 456.230896,305.948578 454.170807,309.918945 
	C453.549713,310.589874 453.068085,310.923096 452.313232,311.382202 
z"/>
<path fill="#1B2B35" opacity="1.000000" stroke="none" 
	d="
M462.070190,308.370911 
	C463.984558,308.740143 465.964752,309.474182 467.968628,310.593933 
	C466.040222,310.231720 464.088135,309.483734 462.070190,308.370911 
z"/>
<path fill="#16292F" opacity="1.000000" stroke="none" 
	d="
M173.980957,346.018311 
	C177.577957,345.221497 181.174942,344.424683 184.864319,343.278503 
	C184.956696,342.929108 184.914764,342.941925 185.341461,342.958191 
	C191.807053,342.717987 197.247894,341.186951 200.951324,335.898682 
	C200.990814,335.928467 200.892899,335.914215 201.205963,335.894165 
	C201.977905,335.496307 202.436768,335.118500 202.915039,334.802917 
	C202.934448,334.865112 202.806061,334.887756 203.047485,334.792786 
	C203.514771,334.442444 203.740646,334.187103 203.977234,333.966064 
	C203.987976,334.000336 203.938080,333.948578 204.247650,333.902771 
	C204.975494,333.439545 205.393784,333.022186 205.894363,332.377747 
	C206.181747,331.891144 206.304550,331.597229 206.304550,331.244843 
	C206.264038,331.220734 206.255661,331.314636 206.509918,331.239014 
	C206.958786,330.879822 207.153397,330.596252 207.215698,330.253998 
	C207.083405,330.195312 207.325516,330.353943 207.571182,330.265686 
	C207.996628,329.889496 208.176407,329.601532 208.238571,329.259430 
	C208.120972,329.205292 208.328720,329.359833 208.577560,329.276703 
	C209.008881,328.906342 209.191360,328.619141 209.402924,328.354919 
	C209.432007,328.377899 209.501648,328.352417 209.712006,328.278839 
	C210.182373,328.040619 210.373993,327.817291 210.721420,327.420227 
	C211.142105,327.037415 211.338547,326.769592 211.735229,326.383057 
	C212.186813,326.065765 212.370636,325.817230 212.689240,325.374298 
	C213.129547,324.988190 213.294312,324.704071 213.645966,324.206573 
	C214.426666,323.156738 214.947235,322.277832 215.724487,321.183105 
	C217.856934,315.508575 219.732666,310.049774 221.708618,304.181702 
	C221.773514,301.403351 221.738190,299.034271 221.742645,296.263062 
	C221.256622,283.194519 215.411636,273.687683 204.897766,266.795380 
	C212.799194,261.056793 216.218552,253.403458 216.575089,244.089706 
	C216.548203,243.992279 216.706070,243.632584 216.837097,243.173218 
	C216.928162,242.397949 216.888168,242.082016 216.899307,241.325470 
	C216.835815,237.166046 216.721176,233.447220 216.606537,229.728394 
	C216.648376,225.732910 216.553589,221.729889 216.773010,217.744186 
	C216.966797,214.224350 217.614914,210.731018 217.859116,207.211868 
	C217.993027,205.281982 217.706482,203.322922 217.362579,200.840576 
	C218.996994,197.047348 221.245148,196.089691 224.456528,198.803040 
	C225.337616,199.547485 226.790283,199.615479 227.986420,200.435608 
	C228.008804,202.502686 227.671356,204.232620 228.114182,205.732132 
	C229.099792,209.069702 228.643494,213.374191 233.265778,214.687683 
	C233.250763,214.752106 233.118652,214.759491 233.229965,215.014572 
	C233.629089,215.493851 233.916916,215.718033 234.418823,216.025726 
	C234.800797,216.507416 234.968658,216.905579 235.236450,217.629517 
	C235.676346,218.240250 236.016312,218.525177 236.276764,218.767853 
	C236.197266,218.725616 236.309326,218.584671 236.369751,218.870514 
	C236.756561,219.338821 237.082932,219.521286 237.345306,219.681396 
	C237.281296,219.659042 237.367767,219.554626 237.363129,219.835373 
	C237.569092,220.410431 237.779678,220.704758 237.997299,221.406677 
	C237.621658,224.759125 241.708466,229.130585 235.312180,230.255173 
	C235.058395,230.299805 235.104568,232.050659 234.681473,233.110947 
	C233.490631,235.686035 232.629272,238.156662 231.631287,241.105988 
	C231.856094,261.737762 232.217529,281.890869 232.454071,302.439941 
	C232.376434,304.213745 232.423660,305.591614 232.352509,307.425232 
	C232.580948,315.565002 232.927765,323.248962 233.178146,331.361969 
	C233.212234,342.579773 235.753769,344.981262 246.543091,344.525146 
	C251.691635,344.307465 256.840668,344.101990 261.928528,344.220154 
	C261.477356,345.513580 261.087189,346.477814 260.738556,347.339386 
	C263.553497,348.021271 265.999084,348.613678 268.444702,349.206085 
	C268.339355,349.625366 268.233978,350.044617 268.128632,350.463898 
	C264.564392,350.624695 261.000153,350.785461 258.379547,350.903687 
	C255.202057,351.745422 252.870377,352.762726 250.485001,352.909882 
	C245.840912,353.196381 241.158386,352.824219 236.507660,353.050537 
	C232.829971,353.229462 230.716141,351.555939 230.122696,348.203400 
	C229.333618,343.745636 226.865936,343.561829 223.340393,344.744690 
	C223.340393,346.561615 223.340393,348.076050 223.340393,349.114288 
	C216.822342,347.838806 210.383118,345.020081 204.456360,345.852081 
	C198.381866,346.704773 192.808914,351.130188 186.599457,353.989929 
	C182.780258,353.992432 179.378052,353.996948 175.975861,354.001465 
	C173.280609,353.993805 170.585373,353.986145 167.493103,353.622253 
	C167.064285,351.185303 167.032516,349.104675 167.000732,347.024017 
	C167.448151,347.015625 167.895584,347.007202 168.998245,347.071075 
	C170.238190,348.284729 170.822891,349.426117 171.407578,350.567505 
	C171.963013,350.239227 172.518433,349.910980 173.073868,349.582703 
	C173.376221,348.394562 173.678589,347.206451 173.980957,346.018311 
z"/>
<path fill="#F9B404" opacity="1.000000" stroke="none" 
	d="
M107.259605,323.493500 
	C107.489937,322.710388 107.720276,321.927277 108.564682,320.780396 
	C112.727066,319.201660 116.275391,317.986664 120.213699,316.711273 
	C121.070930,312.099762 121.538170,307.548584 122.353912,303.098511 
	C124.418274,305.602936 126.211662,307.955505 127.829414,310.423187 
	C129.649719,313.199860 130.660980,317.317383 133.184891,318.645020 
	C137.726578,321.034027 143.069443,322.124115 148.203751,323.077759 
	C151.265076,323.646393 154.565659,322.891144 157.760513,322.786652 
	C161.117065,322.676849 164.869568,323.574158 167.752533,322.367432 
	C173.968002,319.765778 179.804520,318.964752 186.382111,320.960510 
	C192.384247,322.781616 199.185455,320.477020 205.005646,324.252625 
	C205.004181,324.502319 205.000473,325.001709 204.700592,325.155029 
	C197.814240,330.799835 190.551483,335.631531 182.050568,336.590118 
	C169.117386,338.048523 156.037567,338.206787 142.961823,338.574951 
	C142.661270,337.621063 142.480865,336.975525 142.167175,336.402985 
	C138.248962,329.251648 129.623932,329.101257 124.071503,324.346069 
	C123.736885,324.059479 122.607346,324.700104 121.848038,324.910553 
	C116.927742,326.274567 112.007523,327.638885 107.055466,328.782867 
	C107.023651,328.562622 107.016068,328.117950 107.256714,327.789093 
	C107.453423,326.136139 107.409477,324.812042 107.369308,323.522949 
	C107.373085,323.557953 107.259613,323.493500 107.259605,323.493500 
z"/>
<path fill="#F2F3E4" opacity="1.000000" stroke="none" 
	d="
M216.706055,243.632584 
	C216.706070,243.632584 216.548203,243.992279 216.280090,244.152527 
	C215.486481,244.889801 214.960968,245.466843 214.218552,245.712402 
	C213.306854,242.879883 212.377502,240.417740 211.955246,237.871506 
	C209.162567,221.032074 199.847076,209.585144 183.474228,204.737198 
	C168.194016,200.212769 152.472397,202.867462 136.544189,203.122223 
	C135.151352,203.129837 134.133286,203.237152 132.669785,203.240997 
	C130.089996,203.250488 127.955635,203.363434 125.398193,203.360291 
	C123.157578,203.244858 121.316666,203.058273 119.526909,203.281601 
	C114.313530,203.932159 109.120613,204.746643 103.919670,205.496841 
	C105.987938,199.604874 110.476189,196.612717 116.386299,196.439728 
	C134.811783,195.900406 153.246475,195.547195 171.679459,195.438385 
	C183.440033,195.368942 193.865891,200.081192 202.750732,207.199463 
	C209.555222,212.651001 214.548660,220.030579 216.391022,229.397278 
	C216.721176,233.447220 216.835815,237.166046 216.700195,241.518646 
	C216.535309,242.645813 216.620682,243.139206 216.706055,243.632584 
z"/>
<path fill="#18292E" opacity="1.000000" stroke="none" 
	d="
M227.980652,199.993896 
	C226.790283,199.615479 225.337616,199.547485 224.456528,198.803040 
	C221.245148,196.089691 218.996994,197.047348 217.057800,200.652069 
	C213.420868,202.080429 210.125854,202.685501 206.730881,199.814255 
	C205.702560,198.944580 202.789032,200.303955 202.077637,200.421387 
	C191.724487,198.140076 182.380005,195.567123 172.862976,194.166519 
	C166.626175,193.248657 170.022644,188.588547 168.127289,186.142303 
	C168.399384,185.771561 168.671494,185.400833 168.943604,185.030090 
	C170.529892,185.858017 172.116180,186.685959 173.702469,187.513885 
	C173.663589,187.262466 173.624710,187.011063 173.585815,186.759644 
	C176.074097,186.759644 178.794464,187.398788 180.992813,186.600449 
	C183.842941,185.565430 186.277206,183.385269 188.945251,181.349045 
	C189.000000,181.000000 188.999893,180.998779 189.321701,180.955811 
	C190.762314,180.275223 191.881119,179.637619 193.374939,179.000046 
	C194.500031,179.000092 195.250092,179.000122 196.397903,179.000977 
	C198.175308,179.374847 199.649597,179.551498 200.915222,180.161255 
	C203.675583,181.491135 206.311249,183.077042 209.032669,184.491394 
	C211.635086,185.843872 214.445740,186.865662 216.865570,188.481125 
	C219.652679,190.341827 221.904526,192.382263 224.710388,188.343277 
	C225.365997,187.399536 228.089432,187.254135 229.511551,187.748367 
	C230.230347,187.998169 230.617233,190.580200 230.362747,191.961990 
	C229.859818,194.692902 228.806427,197.322449 227.980652,199.993896 
z"/>
<path fill="#E36D10" opacity="1.000000" stroke="none" 
	d="
M143.020035,338.917206 
	C156.037567,338.206787 169.117386,338.048523 182.050568,336.590118 
	C190.551483,335.631531 197.814240,330.799835 204.988571,325.312683 
	C206.738922,325.894318 207.901413,326.471619 209.144348,327.384521 
	C209.317093,327.930908 209.409378,328.141663 209.501648,328.352417 
	C209.501648,328.352417 209.432007,328.377899 209.125793,328.399292 
	C208.655975,328.733734 208.492355,329.046783 208.328735,329.359833 
	C208.328720,329.359833 208.120972,329.205292 207.964676,329.310364 
	C207.647430,329.728302 207.486465,330.041107 207.325516,330.353943 
	C207.325516,330.353943 207.083405,330.195312 206.941772,330.300781 
	C206.618652,330.709076 206.437149,331.011841 206.255661,331.314636 
	C206.255661,331.314636 206.264038,331.220734 206.043106,331.272308 
	C205.547714,331.729309 205.273254,332.134705 204.819916,332.775208 
	C204.406738,333.323059 204.172409,333.635834 203.938080,333.948578 
	C203.938080,333.948578 203.987976,334.000336 203.707153,334.017822 
	C203.219574,334.319458 203.012802,334.603607 202.806061,334.887756 
	C202.806061,334.887756 202.934448,334.865112 202.606674,334.818176 
	C201.816895,335.152252 201.354889,335.533234 200.892883,335.914185 
	C200.892899,335.914215 200.990814,335.928467 200.597656,335.957428 
	C195.107925,338.304901 190.011337,340.623413 184.914764,342.941925 
	C184.914764,342.941925 184.956696,342.929108 184.938156,342.913696 
	C163.877487,343.835663 142.841934,345.015442 121.789871,345.587311 
	C113.720337,345.806519 109.143036,341.891541 107.097816,334.679901 
	C109.744591,335.607239 112.010963,337.585419 114.408531,337.759796 
	C123.923431,338.451782 133.479324,338.580048 143.020035,338.917206 
z"/>
<path fill="#41CCEF" opacity="1.000000" stroke="none" 
	d="
M69.453178,141.875381 
	C69.316460,142.349457 69.179741,142.823532 68.963715,144.088562 
	C68.879005,147.229889 68.873596,149.580246 68.613373,152.218307 
	C68.280220,154.321213 68.201874,156.136429 67.759247,158.014648 
	C64.110916,160.064255 61.372398,162.980927 56.724670,161.851471 
	C52.445400,160.811569 47.714989,167.493454 49.035835,171.770523 
	C49.402878,172.959045 48.385895,174.574997 47.720547,175.817169 
	C45.620281,173.081406 43.803345,170.523331 41.968403,167.566971 
	C40.976757,161.117569 40.003113,155.066437 39.076180,148.680664 
	C39.176476,147.901031 39.230061,147.456085 39.283646,147.011124 
	C39.283646,147.011124 39.399162,146.902405 39.654854,146.636856 
	C40.941654,145.127243 41.972759,143.883194 43.274879,142.881546 
	C43.326561,144.727234 42.767258,146.378723 42.985863,147.919891 
	C43.194279,149.389191 44.213875,150.743439 45.003880,152.396011 
	C45.495163,153.064392 45.859421,153.485031 46.286942,154.095200 
	C46.350208,154.284744 46.683998,154.500870 46.683998,154.500870 
	C46.683998,154.500870 47.073948,154.422623 47.368076,154.513397 
	C48.277260,154.629562 48.892315,154.654938 49.798183,154.655045 
	C50.343006,154.353821 50.597012,154.077866 50.882973,153.874298 
	C50.914925,153.946671 50.808464,153.829620 51.056263,153.750641 
	C51.477772,153.383163 51.651482,153.094696 51.800003,152.845535 
	C51.774811,152.884842 51.763054,152.792206 51.934776,152.785583 
	C52.194477,152.709732 52.366177,152.622543 52.355850,152.574417 
	C52.312420,152.372009 52.214596,152.181274 52.181648,151.586395 
	C52.162785,150.120865 52.098953,149.055222 52.328957,147.834930 
	C55.031433,145.321533 59.821247,143.931381 56.448402,138.887848 
	C60.587437,138.497955 64.396408,138.306946 68.208000,138.442566 
	C68.516197,139.544479 68.821777,140.319778 68.966232,141.141342 
	C68.805099,141.187622 68.658051,141.626144 68.658051,141.626144 
	C68.658051,141.626144 68.975609,141.965836 69.105377,141.998032 
	C69.235153,142.030228 69.453178,141.875381 69.453178,141.875381 
z"/>
<path fill="#F7C40E" opacity="1.000000" stroke="none" 
	d="
M76.371574,141.888428 
	C76.520576,135.756943 78.679939,130.394287 82.894897,125.986145 
	C83.224007,127.762642 83.224007,129.244934 83.224007,131.875381 
	C85.335464,130.474152 86.539246,129.675293 87.959457,128.603912 
	C94.624977,122.056999 108.431999,119.617149 115.383469,124.860336 
	C117.327721,127.438843 119.218353,129.678543 120.801956,132.091156 
	C119.865860,132.805115 119.284950,133.418259 118.600037,133.875702 
	C113.430359,137.328400 108.975807,142.479233 101.482796,137.672455 
	C99.115234,136.153656 94.727646,137.363007 91.364548,137.923691 
	C87.189499,138.619766 83.118301,139.938721 78.849113,140.870850 
	C78.207932,141.138306 77.719887,141.523514 77.017250,141.883240 
	C76.802666,141.857773 76.371574,141.888428 76.371574,141.888428 
z"/>
<path fill="#192C35" opacity="1.000000" stroke="none" 
	d="
M108.959976,287.264740 
	C108.190781,282.915833 107.748734,278.831451 107.154236,274.369263 
	C107.062508,273.245148 107.123238,272.498871 107.256531,271.163269 
	C107.230995,269.710358 107.132889,268.846680 107.034782,267.983032 
	C106.850250,265.542328 106.665710,263.101654 106.296288,259.974976 
	C104.799873,257.843628 103.488350,256.398193 102.176819,254.952789 
	C102.142448,254.646469 102.045532,254.361404 101.904236,253.464447 
	C102.653061,251.470093 103.383705,250.108871 104.459969,248.822144 
	C107.144356,246.388702 106.939705,245.015198 104.318207,241.826965 
	C101.439735,238.326233 98.767349,234.656067 96.343307,231.059662 
	C98.790306,232.034103 100.899170,233.008591 103.008026,233.983093 
	C103.767487,233.690018 104.526939,233.396957 104.696075,233.331696 
	C105.692291,235.555069 106.707336,237.820480 107.862068,240.397644 
	C109.952950,239.128479 111.328270,238.293671 114.065262,236.632339 
	C114.659164,258.732483 115.230766,280.002411 115.812271,301.641296 
	C111.470688,301.023987 110.314400,298.560120 110.023438,295.336823 
	C109.788445,292.733368 109.533318,290.131714 108.959976,287.264740 
z"/>
<path fill="#E9810C" opacity="1.000000" stroke="none" 
	d="
M76.214249,142.327728 
	C76.371574,141.888428 76.802666,141.857773 77.098206,142.204086 
	C78.249214,144.040344 79.104683,145.530289 79.960144,147.349945 
	C79.974274,148.119003 79.988426,148.558365 80.043793,149.363312 
	C82.916946,157.811890 87.973000,164.389816 95.167114,168.750793 
	C104.426949,174.364044 116.299408,168.556793 123.474197,159.939407 
	C124.089684,159.843384 124.333893,159.792908 124.578094,159.742447 
	C124.481392,160.552658 124.176735,161.401703 124.319702,162.167160 
	C126.023331,171.288635 120.727631,176.004974 113.110703,179.399551 
	C110.020622,177.539917 107.382156,176.562881 104.921410,179.945435 
	C104.716728,179.955795 104.306885,179.949478 103.937469,179.821106 
	C102.024635,179.545044 100.481216,179.397354 98.722595,179.130814 
	C98.507393,179.011948 98.016106,179.032196 97.752296,178.894623 
	C96.905907,178.703537 96.323334,178.650009 95.541832,178.343460 
	C94.940590,177.953354 94.538261,177.816269 93.922943,177.450607 
	C91.978477,176.108337 90.247025,174.994644 88.343102,173.611862 
	C87.813530,173.124496 87.456421,172.906235 87.035080,172.490051 
	C86.806755,172.039627 86.574432,171.903870 86.163132,171.603241 
	C85.472267,170.782623 84.892159,170.243622 84.237366,169.762756 
	C84.162682,169.820908 84.342194,169.760818 84.283333,169.492996 
	C83.935326,169.026749 83.646187,168.828339 83.344894,168.623535 
	C83.332741,168.617157 83.359703,168.600220 83.359703,168.600220 
	C79.206680,160.475037 74.813347,152.417786 76.214249,142.327728 
z"/>
<path fill="#152023" opacity="1.000000" stroke="none" 
	d="
M155.177383,89.933762 
	C155.177383,89.933762 155.615067,89.979294 155.869080,90.417221 
	C158.083374,94.229713 160.043655,97.604263 161.999329,101.383240 
	C161.915802,103.322151 161.836884,104.856628 161.680939,107.889069 
	C158.352402,104.963776 156.093567,102.978592 153.546936,100.815826 
	C150.772980,99.562958 148.327606,97.710953 145.791962,97.577820 
	C140.866608,97.319221 135.833786,97.679909 130.959854,98.480453 
	C128.572220,98.872627 126.440819,100.824860 124.033211,102.125259 
	C123.795891,102.259773 123.641258,102.359673 123.655716,102.416779 
	C123.706963,102.619247 123.815926,102.807114 123.945541,103.337509 
	C124.060249,104.116219 124.132439,104.557938 124.037003,104.926186 
	C123.869370,104.852722 123.519440,104.960114 123.519440,104.960114 
	C120.545837,103.415306 119.413544,105.647293 118.250999,107.611153 
	C116.875290,109.935127 115.402573,111.332542 112.275925,110.438438 
	C110.918953,110.050385 109.128334,111.550636 107.499283,111.658470 
	C104.783913,111.838234 102.040062,111.587708 99.308197,111.518356 
	C99.351158,110.600433 99.394119,109.682510 99.625793,108.548981 
	C102.579498,108.121925 105.344482,107.910492 108.793213,108.051193 
	C109.898102,108.492149 110.319260,108.580971 110.740410,108.669800 
	C111.262413,108.483841 111.784416,108.297890 112.865677,107.905228 
	C114.261086,104.410568 115.097237,101.122612 115.933388,97.834656 
	C115.958824,97.524529 116.095284,97.275169 116.800964,96.870819 
	C117.476219,96.343201 117.693283,96.031319 117.910355,95.719437 
	C117.910347,95.719429 117.911377,95.340477 117.897423,95.151489 
	C117.883469,94.962509 118.306702,94.916618 118.855026,94.880203 
	C119.802811,94.377129 120.202278,93.910461 120.601746,93.443787 
	C120.601738,93.443787 120.675087,93.839386 121.005310,93.754295 
	C132.315765,89.702759 143.557266,88.103127 155.177383,89.933762 
z"/>
<path fill="#D78D15" opacity="1.000000" stroke="none" 
	d="
M122.005417,302.997437 
	C121.538170,307.548584 121.070930,312.099762 120.196167,316.419922 
	C118.970184,315.207275 118.151733,314.225555 117.333275,313.243866 
	C117.198814,299.781219 117.218964,286.314728 116.886414,272.856964 
	C116.553726,259.392822 115.819542,245.939011 115.375435,232.476898 
	C115.276154,229.467621 113.873688,228.021744 111.375870,226.371292 
	C108.070076,224.186951 105.530762,220.842606 103.001610,217.451080 
	C104.954414,217.187180 106.679382,217.201416 108.153221,217.816956 
	C110.094902,218.627869 111.733513,220.526749 113.690559,220.863068 
	C117.747360,221.560257 119.187531,224.060043 119.623108,227.606445 
	C119.883316,229.725067 119.918106,231.871368 120.029793,234.467682 
	C120.009766,238.954269 120.014175,242.978424 119.992340,247.458374 
	C120.287979,251.619705 120.609863,255.325272 120.925003,259.489563 
	C120.937241,263.633759 120.956215,267.319244 120.963608,271.470276 
	C120.977623,276.621033 121.003235,281.306274 121.008186,286.455383 
	C121.326828,292.278656 121.666122,297.638062 122.005417,302.997437 
z"/>
<path fill="#0A97C2" opacity="1.000000" stroke="none" 
	d="
M41.986404,167.965256 
	C43.803345,170.523331 45.620281,173.081406 47.874367,176.117432 
	C52.970730,181.215271 57.953484,185.210510 65.320465,185.454651 
	C70.077339,185.425552 74.506439,185.177139 79.202629,185.181610 
	C82.928314,186.723541 85.117882,185.604248 86.329773,182.080444 
	C86.780830,184.124725 86.897919,186.084274 86.908356,188.259598 
	C86.602409,188.745544 86.339790,188.920868 85.720291,189.036255 
	C84.938156,189.616211 84.449593,190.161255 83.711502,190.955231 
	C82.208046,191.871216 80.633698,192.275375 79.802101,193.288925 
	C79.142883,194.092392 79.393990,195.642746 78.906830,197.045547 
	C75.566231,198.111755 72.559677,198.993881 69.553131,199.876007 
	C69.704346,198.602539 69.855560,197.329056 70.062599,195.585358 
	C57.522213,195.257370 48.677158,188.775528 41.184036,178.952057 
	C40.626266,178.133118 40.368885,177.837433 40.111500,177.541763 
	C40.041111,176.970474 39.970722,176.399185 39.926781,175.117447 
	C40.630951,172.259750 41.308678,170.112503 41.986404,167.965256 
z"/>
<path fill="#112026" opacity="1.000000" stroke="none" 
	d="
M79.240883,196.861465 
	C79.393990,195.642746 79.142883,194.092392 79.802101,193.288925 
	C80.633698,192.275375 82.208046,191.871216 83.981812,190.890869 
	C85.005722,190.052170 85.509789,189.526764 86.013855,189.001358 
	C86.339790,188.920868 86.602409,188.745544 87.196808,188.143463 
	C88.655167,186.850845 89.718399,185.890106 91.108986,184.793365 
	C92.307335,182.758255 93.178329,180.859146 94.476509,178.889282 
	C95.182716,178.744507 95.461739,178.670486 95.740753,178.596481 
	C96.323334,178.650009 96.905907,178.703537 97.827789,179.025940 
	C98.167084,179.294815 98.556198,179.455292 98.556198,179.455292 
	C98.556198,179.455292 98.937805,179.249680 98.937805,179.249680 
	C100.481216,179.397354 102.024635,179.545044 103.872711,180.195374 
	C103.290421,182.423492 102.390144,184.142273 101.520027,185.876175 
	C100.526749,187.855515 100.152855,189.650620 103.012321,190.357025 
	C102.357155,192.438385 101.940369,194.761353 98.807159,192.717468 
	C98.354256,192.422028 97.035461,193.453979 95.631226,194.080612 
	C94.431534,194.194260 93.717979,194.098984 93.000015,193.624008 
	C92.443687,192.287491 91.891769,191.330658 91.339844,190.373810 
	C90.947411,190.810638 90.554985,191.247452 90.162567,191.684265 
	C91.109612,192.457657 92.056648,193.231033 92.776466,194.275635 
	C90.445198,195.704254 87.875587,196.336517 89.403069,199.936630 
	C90.029030,201.411926 89.890076,203.970367 88.977188,205.209641 
	C84.867256,210.788986 89.047310,215.080673 90.754333,219.770630 
	C91.142174,220.836212 91.641838,221.861069 92.121330,223.236877 
	C93.052246,225.059692 93.951851,226.550293 94.558365,228.165955 
	C93.395393,227.692627 92.250725,227.279480 91.701691,226.464890 
	C88.829178,222.202972 86.227531,217.754807 83.268898,213.556610 
	C82.231331,212.084351 80.445709,211.139282 79.001678,209.953491 
	C80.317986,207.834549 81.634293,205.715607 83.073074,202.888794 
	C83.425797,200.962418 83.656044,199.743912 83.722336,198.192261 
	C82.394814,197.583588 81.231232,197.308060 79.862381,196.982910 
	C79.657112,196.933258 79.240883,196.861465 79.240883,196.861465 
z"/>
<path fill="#F4A406" opacity="1.000000" stroke="none" 
	d="
M142.961823,338.574951 
	C133.479324,338.580048 123.923431,338.451782 114.408531,337.759796 
	C112.010963,337.585419 109.744591,335.607239 107.100861,334.310303 
	C106.514587,333.274017 106.245384,332.385406 106.261078,330.992981 
	C106.726418,329.993835 106.906853,329.498474 107.087296,329.003113 
	C112.007523,327.638885 116.927742,326.274567 121.848038,324.910553 
	C122.607346,324.700104 123.736885,324.059479 124.071503,324.346069 
	C129.623932,329.101257 138.248962,329.251648 142.167175,336.402985 
	C142.480865,336.975525 142.661270,337.621063 142.961823,338.574951 
z"/>
<path fill="#3DBFE1" opacity="1.000000" stroke="none" 
	d="
M133.552353,163.943802 
	C132.912537,152.037857 132.277847,140.131622 131.629150,128.226151 
	C131.475784,125.411224 131.166260,122.600456 131.124802,119.784889 
	C131.075867,116.461952 131.426147,113.313934 127.111221,111.847015 
	C125.779945,111.394432 125.255447,108.568733 124.656181,106.603539 
	C127.302055,107.593163 129.653290,108.808876 132.197083,110.355354 
	C132.919174,112.539993 133.834259,114.379204 133.905426,116.250511 
	C134.113052,121.710159 133.656616,127.207108 134.063690,132.644272 
	C134.658554,140.589432 135.778412,148.495285 136.544403,155.267700 
	C140.919250,154.500427 144.943283,153.794693 148.968735,153.497498 
	C149.164474,156.410019 148.575729,158.231918 145.507385,157.982773 
	C142.359299,157.727142 140.961975,159.233994 140.873337,162.359070 
	C140.782257,165.569641 140.426605,168.772110 140.219925,171.980255 
	C140.112778,173.643524 140.077393,175.311417 139.595367,176.999390 
	C137.798920,177.013718 136.416656,177.005859 134.733124,176.970291 
	C134.031143,176.926010 133.630447,176.909439 133.085770,176.517242 
	C133.210266,174.072952 133.478729,172.004288 133.829498,169.510193 
	C133.791977,167.371109 133.672165,165.657455 133.552353,163.943802 
z"/>
<path fill="#F7C40E" opacity="1.000000" stroke="none" 
	d="
M120.054222,234.005219 
	C119.918106,231.871368 119.883316,229.725067 119.623108,227.606445 
	C119.187531,224.060043 117.747360,221.560257 113.690559,220.863068 
	C111.733513,220.526749 110.094902,218.627869 108.153221,217.816956 
	C106.679382,217.201416 104.954414,217.187180 103.017746,217.094147 
	C102.478065,216.717834 102.261833,216.152756 102.300644,215.053192 
	C102.368896,213.355484 102.182098,212.192276 101.995300,211.029053 
	C102.438416,209.352844 102.881523,207.676636 103.622147,205.748627 
	C109.120613,204.746643 114.313530,203.932159 119.526909,203.281601 
	C121.316666,203.058273 123.157578,203.244858 125.128418,203.546448 
	C123.335266,204.495224 121.388802,205.141724 119.065979,205.913223 
	C124.730400,214.177444 122.338928,222.951813 121.909492,231.512375 
	C121.866356,232.372177 120.699539,233.175629 120.054222,234.005219 
z"/>
<path fill="#0A97C2" opacity="1.000000" stroke="none" 
	d="
M135.034409,176.998016 
	C136.416656,177.005859 137.798920,177.013718 140.062286,177.030518 
	C149.542862,177.023041 158.142365,177.022461 166.741745,176.986206 
	C177.845001,176.939407 179.789825,175.242783 181.349396,164.600098 
	C182.202759,165.647690 183.181549,166.849243 184.354523,168.325073 
	C184.274826,171.075226 184.000931,173.551086 183.655365,176.118988 
	C183.583725,176.211029 183.705688,176.409882 183.458344,176.581299 
	C183.057831,177.114746 182.904648,177.476807 182.804352,177.843903 
	C182.857269,177.848953 182.789948,177.766693 182.549530,177.871429 
	C182.144150,178.276367 181.979156,178.576553 181.856293,178.850510 
	C181.898407,178.824265 181.799149,178.822937 181.571579,178.911682 
	C181.059097,179.195587 180.885681,179.463043 180.823746,179.802780 
	C180.823746,179.802780 180.841415,179.883911 180.587326,179.935837 
	C180.062851,180.264893 179.792465,180.542007 179.522064,180.819122 
	C179.088074,180.995956 178.654083,181.172791 177.729828,181.567047 
	C176.652176,182.097107 176.064804,182.409744 175.477432,182.722382 
	C166.319962,182.924225 157.162506,183.126053 147.242859,182.981903 
	C144.251846,182.431870 141.996002,181.908112 139.800003,182.093445 
	C135.808884,182.430252 134.492615,180.688965 135.034409,176.998016 
z"/>
<path fill="#E0E6E7" opacity="1.000000" stroke="none" 
	d="
M78.974586,210.096039 
	C80.445709,211.139282 82.231331,212.084351 83.268898,213.556610 
	C86.227531,217.754807 88.829178,222.202972 91.701691,226.464890 
	C92.250725,227.279480 93.395393,227.692627 94.582840,228.499405 
	C95.268677,229.491791 95.636925,230.275757 96.005173,231.059723 
	C98.767349,234.656067 101.439735,238.326233 104.318207,241.826965 
	C106.939705,245.015198 107.144356,246.388702 104.335968,248.518463 
	C98.639786,242.268661 93.413208,236.397018 88.186630,230.525360 
	C87.273849,229.157043 86.361061,227.788727 85.163773,225.892288 
	C84.342674,224.714584 83.806084,224.065002 83.269485,223.415421 
	C81.693443,219.271927 80.117409,215.128448 78.599396,210.616333 
	C78.657410,210.247696 78.947495,210.238602 78.974586,210.096039 
z"/>
<path fill="#F2F3E4" opacity="1.000000" stroke="none" 
	d="
M115.329834,124.521545 
	C108.431999,119.617149 94.624977,122.056999 87.637955,128.599548 
	C86.895340,127.901428 86.206474,126.658379 86.572365,126.018211 
	C87.932831,123.638000 89.640129,121.456024 91.217209,119.199623 
	C101.494644,112.940300 114.012756,114.931168 123.811462,124.948669 
	C123.121407,126.116570 122.124039,127.341621 121.537804,127.169159 
	C119.394356,126.538597 117.387894,125.442368 115.329834,124.521545 
z"/>
<path fill="#DCF2F2" opacity="1.000000" stroke="none" 
	d="
M68.205376,138.115936 
	C64.396408,138.306946 60.587437,138.497955 56.093922,138.894165 
	C52.577759,141.384354 49.746140,143.669357 46.685539,145.968292 
	C46.456562,145.982224 45.997330,146.002792 45.977730,145.576752 
	C45.916985,144.014420 45.875843,142.878098 45.886257,141.516479 
	C45.937813,141.291168 46.038525,140.839981 46.057838,140.583954 
	C46.045589,140.159683 46.014027,139.991455 45.982464,139.823242 
	C51.654152,132.797455 59.091820,129.732422 68.010574,130.086014 
	C72.024673,130.245163 72.501389,131.398392 70.179504,135.926788 
	C69.443001,137.080597 68.824188,137.598267 68.205376,138.115936 
z"/>
<path fill="#65E7FC" opacity="1.000000" stroke="none" 
	d="
M148.001694,355.949860 
	C155.020004,355.885010 162.038300,355.820160 169.811829,356.111389 
	C173.719833,356.150848 176.872635,355.834198 180.446838,355.470642 
	C182.553635,355.490356 184.238998,355.557007 186.004242,355.840668 
	C186.744659,355.876373 187.405212,355.695038 188.369675,355.428040 
	C189.087646,355.394806 189.501678,355.447235 189.929749,355.847992 
	C189.957153,357.128021 189.970535,358.059692 189.516281,358.989380 
	C171.355667,359.347870 153.662674,359.708405 135.725830,359.824524 
	C134.001297,359.732971 132.520615,359.885803 130.668930,360.029114 
	C129.559036,359.971588 128.820175,359.923584 128.059631,359.547852 
	C128.037369,358.563660 128.036774,357.907196 128.359192,357.181854 
	C129.117844,357.057190 129.553497,357.001404 130.213867,357.190186 
	C131.627808,357.281494 132.817047,357.128235 134.453461,357.032227 
	C137.606964,357.086273 140.313293,357.083130 143.243378,357.292480 
	C144.652023,357.173492 145.836868,356.841949 147.272491,356.380371 
	C147.682739,356.150177 147.842224,356.050018 148.001694,355.949860 
z"/>
<path fill="#67B8CC" opacity="1.000000" stroke="none" 
	d="
M92.090027,222.904678 
	C91.641838,221.861069 91.142174,220.836212 90.754333,219.770630 
	C89.047310,215.080673 84.867256,210.788986 88.977188,205.209641 
	C89.890076,203.970367 90.029030,201.411926 89.403069,199.936630 
	C87.875587,196.336517 90.445198,195.704254 92.774628,194.273438 
	C93.000000,194.000000 93.004433,194.003693 93.004433,194.003693 
	C93.717979,194.098984 94.431534,194.194260 95.491943,194.398132 
	C95.834862,196.229340 96.368866,198.186844 95.736557,199.635162 
	C92.498329,207.052597 91.444618,214.462021 94.440201,222.555664 
	C93.474113,222.958206 92.782074,222.931442 92.090027,222.904678 
z"/>
<path fill="#CD9429" opacity="1.000000" stroke="none" 
	d="
M115.383469,124.860344 
	C117.387894,125.442368 119.394356,126.538597 121.537804,127.169159 
	C122.124039,127.341621 123.121407,126.116570 124.032578,125.265259 
	C124.744133,126.082504 125.847031,127.150665 125.884300,128.254807 
	C126.185173,137.168488 126.346001,146.088684 126.384926,155.007599 
	C126.391060,156.410492 125.567726,157.817017 124.709808,159.277084 
	C124.291702,159.332306 123.986794,158.990417 124.006142,158.563934 
	C124.047134,156.429855 124.068779,154.722260 124.104118,152.547241 
	C124.099388,148.661209 124.457909,145.186188 123.956963,141.839813 
	C123.451920,138.466110 122.093506,135.220169 121.108986,131.918243 
	C119.218353,129.678543 117.327721,127.438843 115.383469,124.860344 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M94.049316,178.960022 
	C93.178329,180.859146 92.307335,182.758255 91.154251,184.426590 
	C91.461060,180.332397 87.935486,180.520157 85.772362,178.915070 
	C84.398903,177.751755 83.200462,176.867493 81.885681,175.723389 
	C81.228233,174.949509 80.687126,174.435486 80.044891,173.605469 
	C78.832413,171.698837 77.721046,170.108200 76.609688,168.517563 
	C78.578041,168.518265 80.546387,168.518967 82.937225,168.559937 
	C83.359703,168.600220 83.332741,168.617157 83.387924,168.899261 
	C83.742798,169.374527 84.042496,169.567673 84.342194,169.760818 
	C84.342194,169.760818 84.162682,169.820908 84.213120,170.115784 
	C84.933662,170.902069 85.603775,171.393463 86.273880,171.884857 
	C86.574432,171.903870 86.806755,172.039627 87.189972,172.772064 
	C87.777916,173.461655 88.146744,173.671310 88.515564,173.880951 
	C90.247025,174.994644 91.978477,176.108337 93.893295,177.770294 
	C94.067543,178.532394 94.058434,178.746216 94.049316,178.960022 
z"/>
<path fill="#0F303A" opacity="1.000000" stroke="none" 
	d="
M166.539703,347.017395 
	C167.032516,349.104675 167.064285,351.185303 167.043549,353.611969 
	C162.133011,353.957977 157.275009,353.957977 152.136292,353.957977 
	C154.685104,345.488281 160.723053,347.045380 166.539703,347.017395 
z"/>
<path fill="#3DBFE1" opacity="1.000000" stroke="none" 
	d="
M83.886292,198.525421 
	C83.656044,199.743912 83.425797,200.962418 83.052124,202.473633 
	C78.185387,203.998459 73.462082,205.230576 67.412216,206.808746 
	C68.189102,203.902267 68.650322,202.176773 69.332336,200.163635 
	C72.559677,198.993881 75.566231,198.111755 78.906830,197.045547 
	C79.240883,196.861465 79.657112,196.933258 80.023994,197.299988 
	C81.556015,197.952957 82.721161,198.239197 83.886292,198.525421 
z"/>
<path fill="#249EC0" opacity="1.000000" stroke="none" 
	d="
M41.968403,167.566971 
	C41.308678,170.112503 40.630951,172.259750 39.928787,174.827911 
	C39.904354,175.248810 39.460369,175.128403 39.236069,174.752869 
	C38.684391,173.893570 38.357021,173.409805 38.029648,172.926056 
	C38.029648,172.926056 37.987896,172.437485 37.941414,171.880142 
	C37.644611,170.731293 37.394287,170.139801 37.143967,169.548294 
	C37.027958,164.948959 36.911945,160.349640 36.900818,154.999786 
	C37.680286,152.504623 38.354874,150.759979 39.029465,149.015320 
	C40.003113,155.066437 40.976757,161.117569 41.968403,167.566971 
z"/>
<path fill="#E5A318" opacity="1.000000" stroke="none" 
	d="
M103.394753,190.159088 
	C100.152855,189.650620 100.526749,187.855515 101.520027,185.876175 
	C102.390144,184.142273 103.290421,182.423492 104.242126,180.323761 
	C104.306885,179.949478 104.716728,179.955795 105.145264,180.188675 
	C106.980186,181.282410 108.386581,182.143265 109.886055,183.380646 
	C112.404060,185.046677 114.828979,186.336212 117.253891,187.625748 
	C112.761658,188.404221 108.269417,189.182693 103.394753,190.159088 
z"/>
<path fill="#14232A" opacity="1.000000" stroke="none" 
	d="
M183.705688,176.409882 
	C183.705688,176.409882 183.583725,176.211029 183.966034,175.879791 
	C184.901566,173.558014 185.839737,171.581848 185.914734,169.573456 
	C186.018341,166.799240 187.092514,165.730865 189.851562,166.012787 
	C189.950439,165.957474 190.175781,165.981125 190.084335,166.240967 
	C190.064774,166.669800 190.136642,166.838791 190.176086,167.339600 
	C190.087326,168.110947 190.031006,168.550430 189.775360,169.147736 
	C189.720459,169.869843 189.864899,170.434174 190.150360,171.344696 
	C190.652145,173.404175 191.012894,175.117462 191.080399,177.028900 
	C190.191406,178.484299 189.595657,179.741531 188.999893,180.998779 
	C188.999893,180.998779 189.000000,181.000000 188.999390,180.999939 
	C186.709244,180.772720 184.199646,185.517151 182.021667,180.052521 
	C181.886261,179.214249 181.842712,179.018600 181.799149,178.822937 
	C181.799149,178.822937 181.898407,178.824265 182.103607,178.751709 
	C182.469177,178.374985 182.629562,178.070847 182.789948,177.766693 
	C182.789948,177.766693 182.857269,177.848953 183.075714,177.695282 
	C183.431351,177.164352 183.568512,176.787125 183.705688,176.409882 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M76.628036,168.401993 
	C77.721046,170.108200 78.832413,171.698837 79.688416,173.636292 
	C77.995674,173.279648 76.558281,172.576187 74.959686,171.570770 
	C72.573502,166.829742 70.348518,162.390701 68.123528,157.951645 
	C68.201874,156.136429 68.280220,154.321213 68.894638,152.225128 
	C69.614525,151.980728 69.798347,152.017197 69.982162,152.053665 
	C70.197350,153.171188 70.412544,154.288696 70.794083,156.029114 
	C71.287636,157.446777 71.614838,158.241562 71.942039,159.036331 
	C72.087807,159.575272 72.233582,160.114227 72.627457,161.189926 
	C73.211052,162.316849 73.546539,162.907013 73.882034,163.497162 
	C73.882034,163.497162 73.809906,163.765366 73.949097,164.194702 
	C74.370697,164.939423 74.653122,165.254807 74.935547,165.570190 
	C75.025612,165.990707 75.115677,166.411209 75.510956,167.254364 
	C76.167755,167.858109 76.519325,168.039230 76.870895,168.220352 
	C76.870895,168.220352 76.646393,168.286423 76.628036,168.401993 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M101.699097,312.279877 
	C101.808472,311.957947 102.285324,311.817749 102.526260,311.757721 
	C101.512276,316.064758 99.031708,320.507782 99.296585,324.780609 
	C99.671356,330.826141 102.072807,336.745972 103.451576,343.090637 
	C102.071533,341.456573 100.847908,339.451416 99.375793,336.850403 
	C98.841232,335.925568 98.555161,335.596588 98.269081,335.267578 
	C98.269081,335.267578 98.531654,335.176544 98.497437,334.751831 
	C98.202759,333.817841 97.942307,333.308624 97.681854,332.799377 
	C97.681854,332.799377 97.705475,332.415497 97.582886,331.797058 
	C97.287354,328.122772 97.114395,325.066864 96.941437,322.010986 
	C98.490868,318.874603 100.040298,315.738190 101.699097,312.279877 
z"/>
<path fill="#2D5662" opacity="1.000000" stroke="none" 
	d="
M133.747208,169.935638 
	C133.478729,172.004288 133.210266,174.072952 133.059113,176.893311 
	C132.753983,178.758759 132.331543,179.872498 131.909088,180.986237 
	C131.909088,180.986237 131.444885,181.005371 131.172577,180.682022 
	C130.266693,179.908829 129.633118,179.458984 128.999557,179.009155 
	C128.060196,177.589844 127.120827,176.170517 126.510696,174.343353 
	C129.142365,172.602203 131.444778,171.268921 133.747208,169.935638 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M117.215561,313.590027 
	C118.151733,314.225555 118.970184,315.207275 119.806175,316.480286 
	C116.275391,317.986664 112.727066,319.201660 108.840683,320.467102 
	C111.367706,318.323761 114.232780,316.129974 117.215561,313.590027 
z"/>
<path fill="#1F3B3E" opacity="1.000000" stroke="none" 
	d="
M133.829498,169.510193 
	C131.444778,171.268921 129.142365,172.602203 126.516052,174.002197 
	C125.146057,168.532959 128.816879,166.124573 133.217560,163.960968 
	C133.672165,165.657455 133.791977,167.371109 133.829498,169.510193 
z"/>
<path fill="#EFE9A2" opacity="1.000000" stroke="none" 
	d="
M90.866684,119.244186 
	C89.640129,121.456024 87.932831,123.638000 86.572365,126.018211 
	C86.206474,126.658379 86.895340,127.901428 87.421516,128.872070 
	C86.539246,129.675293 85.335464,130.474152 83.224007,131.875381 
	C83.224007,129.244934 83.224007,127.762642 83.239960,125.806030 
	C85.676003,123.317390 88.096085,121.303070 90.866684,119.244186 
z"/>
<path fill="#F5D026" opacity="1.000000" stroke="none" 
	d="
M117.602280,187.621124 
	C114.828979,186.336212 112.404060,185.046677 110.125572,183.144135 
	C111.251236,182.017029 112.230461,181.502945 113.209694,180.988846 
	C114.904243,181.865326 116.882507,182.457703 118.169174,183.742630 
	C118.778366,184.350967 118.064903,186.283768 117.602280,187.621124 
z"/>
<path fill="#208DB1" opacity="1.000000" stroke="none" 
	d="
M131.912170,181.263397 
	C132.331543,179.872498 132.753983,178.758759 133.203094,177.268951 
	C133.630447,176.909439 134.031143,176.926010 134.733124,176.970306 
	C134.492615,180.688965 135.808884,182.430252 139.800003,182.093445 
	C141.996002,181.908112 144.251846,182.431870 146.780792,182.936340 
	C142.574677,183.318161 138.068481,183.399551 133.115997,183.069733 
	C132.511795,182.373047 132.276276,182.184479 131.963150,182.092834 
	C131.947174,181.908737 131.931213,181.724655 131.912170,181.263397 
z"/>
<path fill="#367483" opacity="1.000000" stroke="none" 
	d="
M92.121338,223.236877 
	C92.782074,222.931442 93.474113,222.958206 94.510208,222.929169 
	C97.569435,226.340714 100.284599,229.808090 103.003891,233.629272 
	C100.899170,233.008591 98.790306,232.034103 96.343307,231.059662 
	C95.636925,230.275757 95.268677,229.491791 94.875946,228.374359 
	C93.951851,226.550293 93.052246,225.059692 92.121338,223.236877 
z"/>
<path fill="#102129" opacity="1.000000" stroke="none" 
	d="
M129.989151,356.945648 
	C129.553497,357.001404 129.117844,357.057190 128.060822,357.117340 
	C127.033119,357.151245 126.626770,357.180817 126.220421,357.210388 
	C126.220421,357.210388 126.110710,357.101990 126.057678,357.046234 
	C127.899246,356.010529 129.785980,354.232269 131.690079,354.213501 
	C135.915924,354.171844 140.150482,355.012421 144.420715,355.867432 
	C143.979828,356.521027 143.499710,356.800476 143.019608,357.079956 
	C140.313293,357.083130 137.606964,357.086273 134.309265,356.707428 
	C132.474960,356.532166 131.232056,356.738922 129.989151,356.945648 
z"/>
<path fill="#69CBE9" opacity="1.000000" stroke="none" 
	d="
M132.004532,110.024582 
	C129.653290,108.808876 127.302055,107.593163 124.663239,106.256424 
	C124.225632,105.944832 124.075592,105.754265 123.722496,105.261902 
	C123.519440,104.960114 123.869370,104.852722 124.436401,104.910995 
	C128.331970,104.335297 131.660522,103.701340 134.817322,103.372665 
	C134.107544,105.446922 133.569504,107.215897 132.895966,109.191040 
	C132.548981,109.655144 132.297012,109.864273 132.004532,110.024582 
z"/>
<path fill="#F69907" opacity="1.000000" stroke="none" 
	d="
M113.182953,180.728378 
	C112.230461,181.502945 111.251236,182.017029 110.032486,182.767639 
	C108.386581,182.143265 106.980186,181.282410 105.349945,180.178314 
	C107.382156,176.562881 110.020622,177.539917 112.851982,179.621048 
	C113.038033,180.139420 113.097122,180.303665 113.182953,180.728378 
z"/>
<path fill="#B3E8ED" opacity="1.000000" stroke="none" 
	d="
M45.834702,141.741791 
	C45.875843,142.878098 45.916985,144.014420 45.979118,145.575363 
	C46.000107,146.000000 46.002571,145.996521 45.775528,146.096100 
	C45.410297,146.802979 45.272114,147.410294 45.101818,148.429459 
	C45.005424,149.943634 44.941143,151.045944 44.876858,152.148254 
	C44.213875,150.743439 43.194279,149.389191 42.985863,147.919891 
	C42.767258,146.378723 43.326561,144.727234 43.333931,142.631393 
	C43.207081,141.800659 43.292183,141.462463 43.377289,141.124283 
	C44.088432,140.812790 44.799583,140.501312 45.746597,140.006531 
	C46.014027,139.991455 46.045589,140.159683 45.882874,140.648056 
	C45.599400,141.049545 45.422485,141.183899 45.434639,141.204010 
	C45.549633,141.394363 45.696800,141.565277 45.834702,141.741791 
z"/>
<path fill="#54C8E0" opacity="1.000000" stroke="none" 
	d="
M180.025421,355.517578 
	C176.872635,355.834198 173.719833,356.150848 170.272705,356.196777 
	C171.733856,355.707031 173.489349,355.487976 176.021301,355.261047 
	C177.873642,355.341370 178.949539,355.429474 180.025421,355.517578 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M42.947033,141.094528 
	C43.292183,141.462463 43.207081,141.800659 43.062923,142.388992 
	C41.972759,143.883194 40.941654,145.127243 39.627579,146.562378 
	C38.947807,145.212051 38.551006,143.670670 37.992107,141.499603 
	C39.790211,141.326797 41.153492,141.195786 42.947033,141.094528 
z"/>
<path fill="#0F303A" opacity="1.000000" stroke="none" 
	d="
M180.446838,355.470642 
	C178.949539,355.429474 177.873642,355.341370 176.401642,355.161560 
	C176.000519,354.891815 175.995514,354.713715 175.983185,354.268524 
	C179.378052,353.996948 182.780258,353.992432 186.599762,354.225647 
	C187.017075,354.463348 187.009811,354.934509 186.731262,355.095490 
	C186.276611,355.378876 186.100494,355.501251 185.924362,355.623657 
	C184.238998,355.557007 182.553635,355.490356 180.446838,355.470642 
z"/>
<path fill="#18292E" opacity="1.000000" stroke="none" 
	d="
M163.548004,191.851379 
	C162.089691,192.295837 160.733246,192.399338 159.376816,192.502823 
	C159.474258,191.763138 159.571701,191.023438 159.669144,190.283737 
	C160.996048,190.692627 162.322968,191.101517 163.548004,191.851379 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M68.208000,138.442566 
	C68.824188,137.598267 69.443001,137.080597 70.174347,136.275940 
	C70.205872,137.885788 70.124870,139.782623 69.748520,141.777405 
	C69.453178,141.875381 69.235153,142.030228 69.224609,141.794830 
	C69.214066,141.559433 69.127357,141.095062 69.127357,141.095062 
	C68.821777,140.319778 68.516197,139.544479 68.208000,138.442566 
z"/>
<path fill="#0F303A" opacity="1.000000" stroke="none" 
	d="
M173.601227,346.018250 
	C173.678589,347.206451 173.376221,348.394562 173.073868,349.582703 
	C172.518433,349.910980 171.963013,350.239227 171.407578,350.567505 
	C170.822891,349.426117 170.238190,348.284729 169.333832,347.065796 
	C170.416611,346.664917 171.819046,346.341553 173.601227,346.018250 
z"/>
<path fill="#0F303A" opacity="1.000000" stroke="none" 
	d="
M148.799652,348.749451 
	C148.085510,348.404785 147.690598,348.158142 146.539505,347.439240 
	C148.926712,347.439240 150.541489,347.439240 152.156265,347.439240 
	C152.206787,347.770782 152.257324,348.102325 152.307846,348.433868 
	C151.244873,348.571716 150.181885,348.709595 148.799652,348.749451 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M39.076180,148.680664 
	C38.354874,150.759979 37.680286,152.504623 36.866512,154.585876 
	C37.332756,152.328323 37.938187,149.734146 38.913631,147.075562 
	C39.230061,147.456085 39.176476,147.901031 39.076180,148.680664 
z"/>
<path fill="#0F303A" opacity="1.000000" stroke="none" 
	d="
M146.003891,353.987030 
	C145.854202,352.794952 145.704514,351.602905 145.554810,350.410828 
	C145.975372,350.254333 146.395920,350.097809 146.816467,349.941284 
	C147.351395,351.142944 147.886307,352.344574 147.880493,353.770508 
	C146.894470,353.992188 146.449188,353.989624 146.003891,353.987030 
z"/>
<path fill="#49B3D5" opacity="1.000000" stroke="none" 
	d="
M107.028419,348.961700 
	C105.996033,349.159576 104.963638,349.357422 103.931244,349.555267 
	C103.967705,348.474304 104.004166,347.393311 104.373825,346.085449 
	C105.145454,346.215668 105.583893,346.572815 106.022324,346.929962 
	C106.187912,347.220764 106.353508,347.511597 106.773842,348.142029 
	C107.028587,348.481628 107.028419,348.961700 107.028419,348.961700 
z"/>
<path fill="#D78D15" opacity="1.000000" stroke="none" 
	d="
M101.802643,211.264343 
	C102.182098,212.192276 102.368896,213.355484 102.328384,214.747406 
	C101.937370,213.817291 101.773682,212.658463 101.802643,211.264343 
z"/>
<path fill="#54C8E0" opacity="1.000000" stroke="none" 
	d="
M143.243378,357.292480 
	C143.499710,356.800476 143.979828,356.521027 144.733398,356.132446 
	C145.174774,356.064819 145.342682,356.106262 146.020538,356.175232 
	C146.694214,356.305328 146.857971,356.407867 147.021729,356.510437 
	C145.836868,356.841949 144.652023,357.173492 143.243378,357.292480 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M69.989365,151.625656 
	C69.798347,152.017197 69.614525,151.980728 69.149445,151.937439 
	C68.873596,149.580246 68.879005,147.229889 69.024994,144.457672 
	C69.442574,146.423111 69.719574,148.810379 69.989365,151.625656 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M103.077126,311.789734 
	C103.560059,310.834778 104.408005,309.901520 105.568726,308.893890 
	C105.068382,309.816833 104.255257,310.814148 103.077126,311.789734 
z"/>
<path fill="#F4A406" opacity="1.000000" stroke="none" 
	d="
M107.365532,323.487946 
	C107.409477,324.812042 107.453423,326.136139 107.049561,327.765869 
	C106.601761,328.071533 106.190018,328.133362 106.190018,328.133362 
	C106.382835,326.777222 106.575645,325.421112 107.058731,323.730957 
	C107.349014,323.396942 107.365532,323.487946 107.365532,323.487946 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M106.006561,346.568237 
	C105.583893,346.572815 105.145454,346.215668 104.453728,345.727386 
	C103.966324,345.104553 103.732208,344.612854 103.436996,343.856934 
	C104.247536,344.463928 105.119164,345.335236 106.006561,346.568237 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M72.154663,158.847260 
	C71.614838,158.241562 71.287636,157.446777 70.980652,156.321167 
	C71.456337,156.879623 71.911819,157.768890 72.154663,158.847260 
z"/>
<path fill="#102129" opacity="1.000000" stroke="none" 
	d="
M147.272491,356.380371 
	C146.857971,356.407867 146.694214,356.305328 146.264572,356.087219 
	C145.998703,355.530914 145.998718,355.090149 146.001312,354.318207 
	C146.449188,353.989624 146.894470,353.992188 147.674149,353.997375 
	C148.005630,354.433380 148.002716,354.866730 148.000748,355.625000 
	C147.842224,356.050018 147.682739,356.150177 147.272491,356.380371 
z"/>
<path fill="#1F3B3E" opacity="1.000000" stroke="none" 
	d="
M129.052780,179.317688 
	C129.633118,179.458984 130.266693,179.908829 130.940521,180.671509 
	C130.355850,180.531647 129.730927,180.078934 129.052780,179.317688 
z"/>
<path fill="#192C35" opacity="1.000000" stroke="none" 
	d="
M111.311661,231.381500 
	C110.995895,231.239441 110.824249,230.961319 110.652596,230.683197 
	C110.920319,230.870605 111.188049,231.058029 111.311661,231.381500 
z"/>
<path fill="#E36D10" opacity="1.000000" stroke="none" 
	d="
M106.054092,328.452942 
	C106.190018,328.133362 106.601761,328.071533 106.808914,328.094727 
	C107.016068,328.117950 107.023651,328.562622 107.055473,328.782867 
	C106.906853,329.498474 106.726418,329.993835 106.337677,330.645233 
	C106.058968,330.125000 105.988571,329.448761 106.054092,328.452942 
z"/>
<path fill="#0D3543" opacity="1.000000" stroke="none" 
	d="
M175.839203,182.764526 
	C176.064804,182.409744 176.652176,182.097107 177.574921,181.747833 
	C177.340530,182.076355 176.770752,182.441528 175.839203,182.764526 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M38.050629,173.226608 
	C38.357021,173.409805 38.684391,173.893570 39.018436,174.678406 
	C38.707275,174.495392 38.389439,174.011276 38.050629,173.226608 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M106.174019,308.864319 
	C106.322266,308.496246 106.801826,308.106110 107.594131,307.655670 
	C107.439690,308.011017 106.972511,308.426636 106.174019,308.864319 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M37.123955,169.879517 
	C37.394287,170.139801 37.644611,170.731293 37.864025,171.647507 
	C37.590061,171.385056 37.347000,170.797882 37.123955,169.879517 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M107.330246,349.037842 
	C107.028419,348.961700 107.028587,348.481628 107.010651,348.242310 
	C107.533028,348.262329 108.073357,348.521729 108.724854,349.074951 
	C108.434708,349.283844 108.033394,349.198944 107.330246,349.037842 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M73.874207,163.136780 
	C73.546539,162.907013 73.211052,162.316849 72.830811,161.388092 
	C73.146164,161.625122 73.506271,162.200745 73.874207,163.136780 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M74.844116,165.267181 
	C74.653122,165.254807 74.370697,164.939423 74.032364,164.305847 
	C74.235191,164.313156 74.493942,164.638672 74.844116,165.267181 
z"/>
<path fill="#CD9429" opacity="1.000000" stroke="none" 
	d="
M124.550446,159.731598 
	C124.333893,159.792908 124.089684,159.843384 123.580307,159.740784 
	C123.493927,159.337891 123.717804,159.138794 123.986794,158.990417 
	C123.986794,158.990417 124.291702,159.332306 124.431259,159.515930 
	C124.570824,159.699570 124.522797,159.720734 124.550446,159.731598 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M107.369308,323.522949 
	C107.365532,323.487946 107.349014,323.396942 107.303940,323.393311 
	C107.258858,323.389709 107.259064,323.442291 107.259338,323.467896 
	C107.259613,323.493500 107.373085,323.557953 107.369308,323.522949 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M109.816193,349.845886 
	C109.620850,349.947601 109.274139,349.831268 108.819977,349.452881 
	C109.029953,349.336517 109.347389,349.482178 109.816193,349.845886 
z"/>
<path fill="#0D3543" opacity="1.000000" stroke="none" 
	d="
M179.817337,180.757294 
	C179.792465,180.542007 180.062851,180.264893 180.615051,179.904922 
	C180.635437,180.113220 180.374023,180.404358 179.817337,180.757294 
z"/>
<path fill="#2D5662" opacity="1.000000" stroke="none" 
	d="
M132.030640,182.314438 
	C132.276276,182.184479 132.511795,182.373047 132.742233,182.878113 
	C132.505890,182.999771 132.267014,182.812546 132.030640,182.314438 
z"/>
<path fill="#0D3543" opacity="1.000000" stroke="none" 
	d="
M181.571579,178.911682 
	C181.842712,179.018600 181.886261,179.214249 181.963379,179.708786 
	C181.800049,179.983444 181.603149,179.959213 181.114990,179.868881 
	C180.885681,179.463043 181.059097,179.195587 181.571579,178.911682 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M40.180248,177.831940 
	C40.368885,177.837433 40.626266,178.133118 40.948547,178.717865 
	C40.758625,178.711990 40.503811,178.417053 40.180248,177.831940 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M76.729408,167.956238 
	C76.519325,168.039230 76.167755,167.858109 75.690437,167.390289 
	C75.905777,167.299774 76.246849,167.495956 76.729408,167.956238 
z"/>
<path fill="#5CA00A" opacity="1.000000" stroke="none" 
	d="
M461.497498,201.938232 
	C461.289154,201.902435 461.123199,201.970337 460.620789,202.361496 
	C459.447876,203.062256 458.653748,203.543457 457.859650,204.024643 
	C457.859650,204.024643 457.766998,204.253754 457.455933,204.416016 
	C453.107178,205.704544 449.069550,206.830826 444.619751,207.934082 
	C443.110962,207.984634 442.014343,208.058197 440.576294,208.063126 
	C439.396301,208.289749 438.557709,208.585007 437.370697,208.785751 
	C434.943085,208.773621 432.863831,208.855988 430.466827,208.806717 
	C427.805603,207.719803 425.462128,206.764542 423.092316,205.606903 
	C423.065948,205.404541 423.003937,205.001129 422.983398,204.607880 
	C422.958527,203.952164 422.954224,203.689697 423.078644,203.199921 
	C423.071381,202.021896 422.935425,201.071167 423.166016,200.041840 
	C426.372345,198.960312 429.212128,197.957382 432.441223,197.028458 
	C439.565247,198.680405 446.301544,200.837418 453.393402,197.020416 
	C457.679047,194.700867 461.695374,192.563324 465.472290,190.064011 
	C468.153595,188.289719 470.511841,186.027222 473.054230,184.224518 
	C473.284027,184.910675 473.473877,185.354935 473.639038,186.152618 
	C473.015900,188.308548 472.417480,190.111053 471.819092,191.913559 
	C471.819092,191.913559 471.889374,191.985611 471.633148,191.991135 
	C471.146088,192.306198 470.915253,192.615738 470.684418,192.925262 
	C470.421570,193.022339 470.222107,193.193344 469.730774,193.807739 
	C467.864380,196.085495 466.353180,197.993820 464.841980,199.902145 
	C464.841980,199.902145 464.905731,199.956528 464.620575,199.897568 
	C463.860535,200.204498 463.385651,200.570389 462.910767,200.936295 
	C462.910767,200.936295 462.969604,200.979904 462.678833,201.034256 
	C462.091187,201.371811 461.794342,201.655029 461.497498,201.938232 
z"/>
<path fill="#162225" opacity="1.000000" stroke="none" 
	d="
M399.060791,109.764099 
	C399.486664,109.600594 399.912567,109.437088 400.718994,109.514809 
	C401.218262,109.726547 401.336975,109.697052 401.455658,109.667572 
	C401.386261,109.559608 401.316864,109.451645 401.247467,109.343674 
	C401.247467,109.343666 401.450806,109.231430 401.996765,109.475372 
	C403.182098,109.759743 403.821503,109.800179 404.776245,109.737518 
	C406.395538,109.430305 407.699463,109.226196 409.003418,109.022079 
	C408.911682,111.746559 408.158142,113.564056 404.928802,114.246803 
	C403.137146,114.625595 401.721436,116.782387 399.804749,118.666061 
	C398.252441,119.598404 397.031616,120.002472 395.413086,120.309845 
	C393.991180,120.788857 392.966949,121.364548 391.583618,121.967270 
	C390.170197,123.027893 389.115845,124.061485 388.037933,125.056702 
	C388.014374,125.018318 388.092651,125.062866 387.753540,125.123596 
	C387.008698,125.690620 386.602997,126.196907 386.049652,126.983902 
	C385.051666,129.142044 384.201294,131.019485 383.290222,133.302780 
	C382.686035,136.738419 382.142548,139.768219 381.167358,142.851074 
	C380.156708,142.936081 379.577728,142.968033 378.617004,142.976685 
	C377.489777,141.302902 376.744324,139.652420 375.999451,137.668915 
	C375.999481,136.892899 375.998932,136.449890 376.004608,135.625168 
	C376.554596,133.451202 377.098358,131.658936 377.687195,129.718063 
	C376.043823,129.108521 374.531036,128.547409 372.969635,127.636322 
	C371.186157,124.921539 369.272369,122.663002 367.781830,120.153084 
	C366.849854,118.583771 366.555664,116.635689 365.975281,114.857559 
	C368.139252,114.929024 370.369629,114.705338 372.444733,115.171944 
	C373.917480,115.503098 375.180756,116.765999 376.404053,117.631592 
	C376.270569,117.650169 376.521576,117.552032 376.411255,117.906464 
	C377.183624,119.175514 378.066345,120.090126 378.975555,120.997498 
	C379.002014,120.990273 378.998474,121.045006 379.072876,121.352066 
	C382.175171,122.691750 382.899017,122.205566 383.682556,118.612953 
	C383.682556,118.612953 383.715027,118.723183 383.940247,118.654266 
	C384.382874,118.286240 384.600311,117.987129 384.817749,117.688019 
	C384.817749,117.688011 384.848816,117.835625 385.044189,117.734230 
	C385.461121,117.329147 385.682617,117.025459 385.904144,116.721771 
	C385.970947,116.399040 386.155334,116.171623 387.042480,115.999664 
	C388.193024,115.397728 388.758362,114.835655 389.323669,114.273575 
	C389.323669,114.273575 389.474243,114.290527 389.799377,114.344673 
	C390.354462,114.046532 390.584381,113.694252 390.814270,113.341965 
	C390.812134,113.122681 390.901947,112.951714 391.389465,112.644447 
	C391.800476,112.329033 391.905823,112.198242 392.011139,112.067459 
	C393.027283,111.695534 394.043457,111.323616 395.762726,110.945297 
	C397.330841,110.547302 398.195831,110.155701 399.060791,109.764099 
z"/>
<path fill="#F6E0EA" opacity="1.000000" stroke="none" 
	d="
M395.810791,120.406540 
	C397.031616,120.002472 398.252441,119.598404 399.736023,119.001724 
	C408.516754,116.935005 416.565033,117.550819 423.948486,123.824524 
	C424.770111,124.715584 425.293762,125.052681 425.817413,125.389786 
	C425.817413,125.389786 425.953064,125.439774 426.053802,125.871498 
	C426.542236,126.576523 426.929932,126.849815 427.317627,127.123108 
	C427.317627,127.123108 427.391785,127.172264 427.441467,127.592300 
	C427.985901,128.522980 428.480652,129.033646 428.975403,129.544281 
	C428.975403,129.544281 428.962555,129.522705 428.932495,129.924271 
	C433.300690,140.637558 433.196869,141.093597 424.699280,147.153900 
	C420.656281,145.428741 420.888000,142.698776 422.649109,139.569763 
	C424.230621,139.315506 425.443848,139.137131 426.742737,138.946182 
	C426.012360,135.336044 424.707825,134.136261 421.638794,137.000488 
	C420.555115,136.866562 419.831635,136.786331 418.908752,136.453003 
	C414.930634,133.095184 416.280243,130.797501 420.244232,129.189865 
	C416.022491,124.981209 411.332245,123.409157 405.964844,123.912354 
	C403.333893,124.158997 400.727631,124.668762 397.758057,125.025215 
	C396.596191,125.364937 395.786407,125.738899 394.723999,126.113907 
	C394.303925,126.133171 394.136475,126.151390 393.956604,125.750305 
	C393.965332,124.220558 393.986450,123.110130 394.202087,121.762184 
	C394.867950,121.151962 395.339386,120.779251 395.810791,120.406540 
z"/>
<path fill="#122020" opacity="1.000000" stroke="none" 
	d="
M430.784607,208.938370 
	C432.863831,208.855988 434.943085,208.773621 437.718689,208.812729 
	C439.249268,208.666733 440.083496,208.399261 440.917694,208.131775 
	C442.014343,208.058197 443.110962,207.984634 444.611572,208.198425 
	C445.016113,208.661957 445.016632,208.838104 445.017120,209.014252 
	C439.851746,210.039841 435.329742,211.930313 432.398102,216.782089 
	C430.585419,219.782043 431.192627,220.964645 434.925049,221.235077 
	C433.930267,222.027786 432.355804,223.543198 431.174133,223.281967 
	C426.054626,222.150101 423.902832,225.428741 421.208649,228.743393 
	C419.215790,228.000259 417.718048,227.400223 416.354706,226.462524 
	C417.536652,224.440994 418.812256,222.849640 419.547211,221.038895 
	C419.995667,219.933975 419.612274,218.491440 419.602478,217.200531 
	C418.489044,217.652161 417.030426,217.801529 416.323303,218.608994 
	C413.610565,221.706787 411.139008,225.015732 408.283020,228.050064 
	C408.129883,225.750351 408.271149,223.646454 408.447998,221.558685 
	C408.483582,221.574799 408.487122,221.496765 408.763672,221.474365 
	C409.138550,221.127914 409.236847,220.803879 409.390137,220.487946 
	C409.445099,220.496048 409.378693,220.406891 409.707123,220.406189 
	C410.282318,219.853409 410.529114,219.301315 410.795593,218.795898 
	C410.815308,218.842545 410.714355,218.851212 410.971893,218.735199 
	C411.403931,218.295471 411.578400,217.971725 411.779785,217.729370 
	C411.806732,217.810745 411.636414,217.830429 411.931885,217.664383 
	C412.430573,217.102417 412.633728,216.706512 412.775604,215.995880 
	C411.511475,212.117432 410.308655,208.553726 409.283752,204.825989 
	C409.682739,204.407913 409.800934,204.112671 409.825562,203.766876 
	C409.834839,203.757523 409.806976,203.744263 410.182892,203.808929 
	C412.027954,204.229492 413.497101,204.585403 415.001892,204.986206 
	C415.037506,205.031097 415.110596,205.119415 415.079529,205.478943 
	C417.370148,207.941544 419.691864,210.044586 422.233459,212.424561 
	C424.847351,213.390411 427.241364,214.079346 429.635376,214.768295 
	C430.018463,212.824982 430.401520,210.881683 430.784607,208.938370 
z"/>
<path fill="#8CBF0A" opacity="1.000000" stroke="none" 
	d="
M453.021698,197.047714 
	C446.301544,200.837418 439.565247,198.680405 432.267761,196.745972 
	C428.336243,192.739166 424.967529,189.088837 421.823303,185.206253 
	C425.109344,182.339478 428.170868,179.704971 431.512665,176.920822 
	C432.891937,175.198441 433.990936,173.625687 435.354370,171.832962 
	C437.473602,166.793747 439.328400,161.974503 441.183197,157.155258 
	C441.475403,156.000046 441.767609,154.844818 442.146973,153.029037 
	C442.222443,151.946426 442.210663,151.524384 442.198914,151.102325 
	C442.198944,151.102325 442.549866,150.907822 443.065186,150.822479 
	C445.077606,150.529266 446.574707,150.321381 448.057587,150.339310 
	C448.043365,150.565109 448.058685,151.017288 447.820435,151.271088 
	C446.532867,152.770432 444.762817,153.904053 444.549225,155.279373 
	C442.962250,165.499161 437.227966,173.630737 431.866943,182.024216 
	C431.182983,183.095062 431.586365,185.970566 432.537628,186.791595 
	C437.837860,191.366257 442.950592,196.534424 450.779144,196.441269 
	C451.524139,196.432404 452.274078,196.835754 453.021698,197.047714 
z"/>
<path fill="#F0F2E2" opacity="1.000000" stroke="none" 
	d="
M448.071808,150.113510 
	C446.574707,150.321381 445.077606,150.529266 443.255646,150.759537 
	C441.126099,146.494049 442.881287,144.616501 447.201477,144.350327 
	C456.725677,143.763489 464.745026,146.484695 470.690063,155.141922 
	C471.351624,156.087570 471.641174,156.589767 471.904053,157.475372 
	C471.926941,158.877228 471.976471,159.895660 472.088501,161.118881 
	C472.272308,161.585312 472.445282,161.806610 472.718262,162.355743 
	C472.764923,163.463043 472.763153,164.202087 472.445862,164.949463 
	C471.501678,164.953949 470.873077,164.950089 470.058838,164.643524 
	C467.909454,161.575760 465.945679,158.810715 463.740173,155.802002 
	C458.993164,151.938721 454.013367,149.663712 448.071808,150.113510 
z"/>
<path fill="#F14E94" opacity="1.000000" stroke="none" 
	d="
M425.052216,147.240555 
	C433.196869,141.093597 433.300690,140.637558 428.923340,129.915649 
	C433.790588,132.806412 435.011993,137.719513 435.036987,144.038971 
	C434.695892,148.542313 434.339783,152.178070 433.666565,155.927948 
	C431.888184,157.684967 430.426910,159.327835 428.578247,160.948547 
	C424.434357,159.701096 426.546600,156.998840 426.545135,154.745667 
	C426.543427,152.178604 429.577332,148.920273 425.052216,147.240555 
z"/>
<path fill="#2F3234" opacity="1.000000" stroke="none" 
	d="
M433.983704,155.813812 
	C434.339783,152.178070 434.695892,148.542313 435.113647,144.461121 
	C435.781036,147.044998 436.386780,150.074341 436.797607,153.725037 
	C436.481293,154.843033 436.359863,155.339676 436.238434,155.836319 
	C436.445190,155.872971 436.651947,155.909637 436.858734,155.946289 
	C436.909210,155.300842 436.959686,154.655396 437.010162,154.009949 
	C438.223358,154.900391 439.436554,155.790848 440.916473,156.918274 
	C439.328400,161.974503 437.473602,166.793747 435.233887,171.482208 
	C432.982178,170.901306 431.115387,170.451157 429.220978,169.638702 
	C430.790161,164.788849 432.386932,160.301331 433.983704,155.813812 
z"/>
<path fill="#102213" opacity="1.000000" stroke="none" 
	d="
M472.150879,191.777817 
	C472.417480,190.111053 473.015900,188.308548 473.794037,185.829437 
	C474.100311,184.693375 474.226868,184.233917 474.409912,183.712372 
	C474.466431,183.650284 474.446625,183.476685 474.493561,183.137192 
	C474.468262,182.580948 474.396057,182.364182 474.264343,182.119049 
	C474.204834,182.090698 474.168732,182.217484 474.516510,182.098312 
	C475.480499,176.390259 476.096710,170.801392 476.783142,165.158524 
	C476.853394,165.104538 476.991943,164.994064 476.991943,164.994064 
	C479.904938,170.461517 479.605988,176.023956 477.272552,181.541763 
	C475.822571,184.970551 474.089020,188.279388 472.150879,191.777817 
z"/>
<path fill="#102213" opacity="1.000000" stroke="none" 
	d="
M471.930756,157.091980 
	C471.641174,156.589767 471.351624,156.087570 470.989777,155.296707 
	C471.433350,154.732788 471.949219,154.457504 472.465088,154.182220 
	C473.977570,157.526215 475.490051,160.870193 476.997253,164.604126 
	C476.991943,164.994064 476.853394,165.104538 476.557617,164.924973 
	C475.494995,163.454178 474.728149,162.162933 473.895966,160.560318 
	C473.197327,159.196625 472.564026,158.144302 471.930756,157.091980 
z"/>
<path fill="#102213" opacity="1.000000" stroke="none" 
	d="
M445.422913,209.008698 
	C445.016632,208.838104 445.016113,208.661957 445.023743,208.221451 
	C449.069550,206.830826 453.107178,205.704544 457.547852,204.498566 
	C453.910156,205.946960 449.869415,207.475052 445.422913,209.008698 
z"/>
<path fill="#66BBCF" opacity="1.000000" stroke="none" 
	d="
M409.064545,108.845856 
	C407.699463,109.226196 406.395538,109.430305 404.619141,109.428886 
	C403.761536,108.974319 403.376343,108.725281 402.991180,108.476242 
	C404.885529,108.227005 406.779907,107.977768 408.917114,108.013336 
	C409.159973,108.298141 409.125671,108.669624 409.064545,108.845856 
z"/>
<path fill="#102213" opacity="1.000000" stroke="none" 
	d="
M465.184631,199.805511 
	C466.353180,197.993820 467.864380,196.085495 469.665527,193.997192 
	C468.479401,195.781128 467.003326,197.745010 465.184631,199.805511 
z"/>
<path fill="#66BBCF" opacity="1.000000" stroke="none" 
	d="
M413.210449,108.349854 
	C413.911224,108.146301 414.874390,108.130630 415.926544,108.403824 
	C415.167969,108.641029 414.320404,108.589386 413.210449,108.349854 
z"/>
<path fill="#64BAD3" opacity="1.000000" stroke="none" 
	d="
M442.778931,134.333176 
	C443.417664,134.253189 444.057587,134.484283 444.904297,134.995361 
	C444.334137,135.064987 443.557098,134.854630 442.778931,134.333176 
z"/>
<path fill="#102213" opacity="1.000000" stroke="none" 
	d="
M458.242554,204.011627 
	C458.653748,203.543457 459.447876,203.062256 460.598663,202.500671 
	C460.178741,202.946396 459.402100,203.472504 458.242554,204.011627 
z"/>
<path fill="#F14E94" opacity="1.000000" stroke="none" 
	d="
M429.003845,129.154022 
	C428.480652,129.033646 427.985901,128.522980 427.446411,127.636520 
	C427.945190,127.761726 428.488739,128.262741 429.003845,129.154022 
z"/>
<path fill="#F14E94" opacity="1.000000" stroke="none" 
	d="
M425.756531,125.064812 
	C425.293762,125.052681 424.770111,124.715584 424.173279,124.066788 
	C424.631958,124.083351 425.163818,124.411598 425.756531,125.064812 
z"/>
<path fill="#2F3234" opacity="1.000000" stroke="none" 
	d="
M441.977173,151.219666 
	C442.210663,151.524384 442.222443,151.946426 442.116516,152.665497 
	C441.917664,152.420700 441.836548,151.878860 441.977173,151.219666 
z"/>
<path fill="#102213" opacity="1.000000" stroke="none" 
	d="
M463.215698,200.916687 
	C463.385651,200.570389 463.860535,200.204498 464.643860,199.862732 
	C464.475128,200.223602 463.997894,200.560349 463.215698,200.916687 
z"/>
<path fill="#F14E94" opacity="1.000000" stroke="none" 
	d="
M427.248383,126.760406 
	C426.929932,126.849815 426.542236,126.576523 426.078033,125.939590 
	C426.394073,125.849869 426.786621,126.123779 427.248383,126.760406 
z"/>
<path fill="#102213" opacity="1.000000" stroke="none" 
	d="
M470.948486,192.828506 
	C470.915253,192.615738 471.146088,192.306198 471.668274,191.955109 
	C471.710602,192.186279 471.461578,192.459015 470.948486,192.828506 
z"/>
<path fill="#102213" opacity="1.000000" stroke="none" 
	d="
M461.796631,201.847244 
	C461.794342,201.655029 462.091187,201.371811 462.683441,200.997925 
	C462.684479,201.190262 462.390106,201.473267 461.796631,201.847244 
z"/>
<path fill="#122020" opacity="1.000000" stroke="none" 
	d="
M436.138794,220.004242 
	C436.221222,220.228897 436.028625,220.652893 435.503876,221.212265 
	C435.402405,220.966293 435.633087,220.584946 436.138794,220.004242 
z"/>
<path fill="#3ACAE2" opacity="1.000000" stroke="none" 
	d="
M427.715729,256.436340 
	C427.665741,256.756287 427.759552,257.026306 428.430481,257.501282 
	C429.245117,257.792572 429.626434,257.829041 430.339996,258.009033 
	C431.379578,258.271301 432.086975,258.389984 432.794342,258.508667 
	C432.794342,258.508667 432.875000,258.380615 433.022430,258.624878 
	C435.962616,260.251526 438.895569,261.422760 441.471069,263.132416 
	C442.356506,263.720184 442.349304,265.652618 442.749908,266.970764 
	C441.436432,267.046082 440.055420,267.419769 438.822205,267.140594 
	C435.187500,266.317719 431.611389,265.236237 427.595612,264.126953 
	C425.449890,264.081573 423.720917,264.165161 421.596191,264.187683 
	C420.224915,264.276764 419.252960,264.458466 418.273041,264.571045 
	C415.668152,264.870300 412.515320,265.666107 412.205841,261.763367 
	C411.894379,257.835510 414.707336,256.992004 418.077057,256.963440 
	C420.447998,256.943390 422.815430,256.511536 425.501038,256.289978 
	C426.450317,256.356415 427.083008,256.396362 427.715729,256.436340 
z"/>
<path fill="#47C6E6" opacity="1.000000" stroke="none" 
	d="
M475.351562,297.103546 
	C470.727753,292.621643 466.394012,288.273254 461.734436,283.810120 
	C461.408569,283.695374 461.374695,283.689636 461.287170,283.431824 
	C460.897644,282.991058 460.595642,282.808105 460.271362,282.683228 
	C460.249023,282.741333 460.373505,282.740234 460.275146,282.446045 
	C459.851959,281.906677 459.527161,281.661469 459.111023,281.103912 
	C457.940216,279.179230 456.860687,277.566925 455.781189,275.954620 
	C466.342377,277.592194 473.479187,284.020905 478.740906,292.786407 
	C479.500031,294.051025 478.748901,296.222198 478.696716,297.973846 
	C477.678375,297.728241 476.660004,297.482666 475.351562,297.103546 
z"/>
<path fill="#183441" opacity="1.000000" stroke="none" 
	d="
M434.937439,298.907928 
	C433.680206,299.555664 432.423004,300.203430 430.240936,301.327698 
	C429.164551,299.496490 428.026398,297.560181 427.352356,295.298889 
	C430.169495,293.679138 432.853912,292.754181 434.802246,291.007935 
	C438.203003,287.959778 441.150726,284.406219 444.288330,281.064514 
	C445.524933,281.047882 446.761566,281.031250 448.531403,281.205017 
	C449.064636,281.395416 449.074829,281.798584 449.081970,282.106689 
	C449.369781,282.940247 449.650421,283.465729 450.012238,284.426270 
	C450.023804,286.918976 449.954163,288.976654 449.650452,291.346436 
	C449.334198,292.974182 449.252014,294.289856 449.240295,295.879364 
	C449.039093,296.823975 448.767395,297.494720 448.371368,297.738403 
	C447.527924,294.874237 446.808838,292.437164 445.814484,289.762024 
	C444.607666,289.433685 443.676086,289.343475 442.460632,289.313110 
	C441.112152,290.228577 440.047607,291.084229 438.691589,292.118225 
	C437.245911,294.500366 436.091675,296.704132 434.937439,298.907928 
M444.245880,285.543274 
	C444.057953,285.811737 443.870026,286.080200 443.682098,286.348663 
	C443.960968,286.176544 444.239838,286.004395 444.245880,285.543274 
z"/>
<path fill="#E167B4" opacity="1.000000" stroke="none" 
	d="
M453.636047,239.619171 
	C455.976044,245.258850 455.698395,250.188522 451.846039,254.948090 
	C449.222931,248.988953 446.644012,243.397476 444.376740,237.682358 
	C444.048248,236.854340 444.914276,234.965485 445.771271,234.416687 
	C446.655426,233.850525 448.604950,233.821686 449.377747,234.442444 
	C450.990173,235.737701 452.134399,237.615860 453.636047,239.619171 
z"/>
<path fill="#88C6D8" opacity="1.000000" stroke="none" 
	d="
M444.424622,280.744995 
	C441.150726,284.406219 438.203003,287.959778 434.802246,291.007935 
	C432.853912,292.754181 430.169495,293.679138 427.446533,295.005005 
	C426.882690,294.467560 426.688843,293.898987 426.271729,292.859192 
	C426.048462,292.387939 426.003601,291.892578 426.018341,291.565277 
	C426.016510,290.799713 425.999908,290.361450 426.131134,289.686951 
	C426.910858,288.778625 427.542786,288.106506 428.511688,287.325684 
	C433.740997,283.831085 438.633301,280.445221 443.754761,276.943176 
	C444.060822,276.674011 444.137787,276.520996 444.214722,276.368011 
	C448.026276,277.405121 444.600006,279.059845 444.424622,280.744995 
z"/>
<path fill="#C8B93E" opacity="1.000000" stroke="none" 
	d="
M464.568878,254.431885 
	C460.893555,257.916229 457.218201,261.400574 453.517303,265.235779 
	C451.504242,263.242493 452.927124,261.615784 454.553955,259.872528 
	C459.485809,254.587738 462.838165,248.588837 462.284424,241.060364 
	C462.154510,239.294357 461.600311,237.559555 461.175903,235.391815 
	C461.973145,233.597473 462.984406,231.002304 463.669159,231.085770 
	C465.386719,231.295074 468.226990,232.561493 468.363342,233.678955 
	C468.922668,238.262115 468.640198,242.947983 468.330261,247.410187 
	C468.705109,242.254135 470.256012,237.075745 465.196442,233.236984 
	C463.701141,240.836823 462.313049,247.891663 460.749939,255.080978 
	C460.591919,255.546829 460.609009,255.878204 460.626099,256.209595 
	C460.783630,255.837219 460.941132,255.464859 461.451843,255.047089 
	C462.726349,254.811752 463.647614,254.621826 464.568878,254.431885 
z"/>
<path fill="#B1BD8D" opacity="1.000000" stroke="none" 
	d="
M449.884521,291.034332 
	C449.954163,288.976654 450.023804,286.918976 450.074341,284.102936 
	C449.728424,282.829254 449.401642,282.313904 449.074829,281.798584 
	C449.074829,281.798584 449.064636,281.395416 448.995148,281.206360 
	C449.724182,280.025604 450.522705,279.033936 451.739014,278.471313 
	C456.788849,285.721283 457.603790,292.891052 453.866577,300.202362 
	C452.375977,298.309357 451.145355,296.667084 449.891724,294.622437 
	C449.873993,293.158173 449.879272,292.096252 449.884521,291.034332 
z"/>
<path fill="#E5686A" opacity="1.000000" stroke="none" 
	d="
M435.173492,250.905289 
	C432.839905,249.153503 430.108826,247.727020 428.314117,245.534210 
	C427.141449,244.101379 427.326721,241.557144 426.903717,239.510742 
	C428.842316,239.501053 431.381256,238.707611 432.598297,239.639893 
	C435.393738,241.781189 437.545227,244.763107 440.007019,248.117950 
	C438.639648,249.634323 437.223297,250.436554 435.806976,251.238800 
	C435.806976,251.238800 435.799042,251.160858 435.803070,250.977539 
	C435.698883,250.554489 435.590668,250.314758 435.482422,250.075027 
	C435.379425,250.351776 435.276459,250.628525 435.173492,250.905289 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M444.199036,275.884338 
	C444.137787,276.520996 444.060822,276.674011 443.577789,276.080627 
	C443.039001,274.763397 442.906311,274.192596 442.737732,273.303528 
	C441.135498,271.955536 439.569153,270.925812 437.909515,269.558472 
	C436.182007,268.307495 434.574188,267.342041 432.905945,266.495758 
	C431.307373,265.684814 429.646057,264.997589 428.012329,264.255951 
	C431.611389,265.236237 435.187500,266.317719 438.822205,267.140594 
	C440.055420,267.419769 441.436432,267.046082 442.749908,266.970764 
	C442.349304,265.652618 442.356506,263.720184 441.471069,263.132416 
	C438.895569,261.422760 435.962616,260.251526 432.951355,258.598877 
	C435.481934,258.302490 438.230988,258.276337 441.301483,258.039429 
	C441.795776,257.881561 441.968597,257.934479 442.243042,258.168549 
	C442.515167,258.579742 442.735535,258.742157 443.171936,259.133057 
	C444.880585,260.604889 446.423004,261.780609 447.965424,262.956360 
	C446.704712,267.104462 445.444031,271.252594 444.199036,275.884338 
z"/>
<path fill="#122020" opacity="1.000000" stroke="none" 
	d="
M454.126526,300.453094 
	C457.603790,292.891052 456.788849,285.721283 452.012695,278.583344 
	C452.926697,277.516388 453.984863,276.766418 455.412109,275.985535 
	C456.860687,277.566925 457.940216,279.179230 459.188293,281.414246 
	C459.695740,282.271362 460.034637,282.505798 460.373505,282.740234 
	C460.373505,282.740234 460.249023,282.741333 460.331787,282.961670 
	C460.734589,283.351196 461.054626,283.520416 461.374695,283.689636 
	C461.374695,283.689636 461.408569,283.695374 461.426758,283.694580 
	C458.636719,286.592041 458.010071,290.046356 457.817200,294.034424 
	C457.676178,296.949982 455.723511,299.777863 454.189270,302.741211 
	C453.800201,302.837585 453.657806,302.716217 453.686218,302.422974 
	C453.851959,301.570862 453.989258,301.011963 454.126526,300.453094 
z"/>
<path fill="#E5368F" opacity="1.000000" stroke="none" 
	d="
M464.310425,305.225220 
	C463.689667,304.852814 463.302094,304.500610 463.093231,304.063110 
	C461.571442,300.875854 461.491638,297.138641 464.787537,295.871216 
	C468.906616,294.287231 469.179535,298.437714 469.952515,301.626343 
	C468.226532,303.068359 466.426422,304.137146 464.310425,305.225220 
z"/>
<path fill="#E167B4" opacity="1.000000" stroke="none" 
	d="
M443.005737,258.836914 
	C442.735535,258.742157 442.515167,258.579742 442.192505,257.849487 
	C441.409210,256.851105 440.778046,256.352966 440.146912,255.854813 
	C440.146912,255.854813 439.862000,255.810989 439.629639,255.584320 
	C439.314514,255.182129 439.231812,255.006607 439.091370,254.542694 
	C438.443878,253.795975 437.854156,253.337631 437.264404,252.879272 
	C436.859039,252.498032 436.453644,252.116791 435.927612,251.487183 
	C437.223297,250.436554 438.639648,249.634323 440.113770,248.455017 
	C440.600952,248.329071 441.030304,248.580170 441.684753,249.156281 
	C441.909821,249.481277 442.004669,249.884186 442.025024,250.205139 
	C442.495880,250.909470 442.946381,251.292831 443.417236,251.650314 
	C443.437592,251.624435 443.502472,251.613373 443.408203,251.964325 
	C442.682373,254.464172 446.813141,256.838135 443.005737,258.836914 
z"/>
<path fill="#274248" opacity="1.000000" stroke="none" 
	d="
M427.932068,256.137238 
	C427.083008,256.396362 426.450317,256.356415 425.339905,255.985947 
	C423.589020,254.107086 422.315857,252.558716 421.042664,251.010345 
	C421.957581,250.565643 422.872467,250.120941 423.872894,249.366394 
	C429.910187,250.893982 430.627838,252.055496 427.932068,256.137238 
z"/>
<path fill="#A2B529" opacity="1.000000" stroke="none" 
	d="
M484.205627,279.861542 
	C484.048218,279.561493 483.890808,279.261444 484.013947,278.627197 
	C488.653229,278.704620 490.581116,276.985291 489.364563,272.509399 
	C489.730865,272.620789 490.097168,272.732147 490.752930,272.854523 
	C492.987183,277.344177 491.804047,280.163513 486.213074,280.904938 
	C485.664703,280.874329 485.302795,280.932556 485.126404,280.712402 
	C484.643555,280.300110 484.337128,280.107941 484.085205,279.937012 
	C484.139709,279.958221 484.205658,279.861542 484.205627,279.861542 
z"/>
<path fill="#A99688" opacity="1.000000" stroke="none" 
	d="
M443.171936,259.133057 
	C446.813141,256.838135 442.682373,254.464172 443.657501,252.107605 
	C444.609375,252.617599 445.217712,253.335281 445.826050,254.052979 
	C446.524872,256.761383 447.223694,259.469788 447.943970,262.567261 
	C446.423004,261.780609 444.880585,260.604889 443.171936,259.133057 
z"/>
<path fill="#183441" opacity="1.000000" stroke="none" 
	d="
M446.190063,253.955170 
	C445.217712,253.335281 444.609375,252.617599 443.751770,251.756638 
	C443.502472,251.613373 443.437592,251.624435 443.346436,251.361877 
	C442.838440,250.694275 442.421539,250.289230 442.004669,249.884186 
	C442.004669,249.884186 441.909821,249.481277 441.911011,249.274536 
	C443.710236,249.101486 445.508270,249.135193 447.306274,249.168900 
	C447.055542,250.731720 446.804810,252.294540 446.190063,253.955170 
z"/>
<path fill="#1B2B35" opacity="1.000000" stroke="none" 
	d="
M485.302795,280.932556 
	C485.302795,280.932556 485.664703,280.874329 485.832458,280.948181 
	C485.518951,281.926239 485.037720,282.830414 484.556488,283.734619 
	C483.715729,282.756287 482.874939,281.777985 482.026367,280.437927 
	C482.504364,280.032776 482.990112,279.989349 483.840759,279.903748 
	C484.205658,279.861542 484.139709,279.958221 484.237183,280.172852 
	C484.657379,280.569183 484.980072,280.750854 485.302795,280.932556 
z"/>
<path fill="#A99688" opacity="1.000000" stroke="none" 
	d="
M440.225037,256.097931 
	C440.778046,256.352966 441.409210,256.851105 442.090881,257.668335 
	C441.968597,257.934479 441.795776,257.881561 441.374573,257.719910 
	C440.851898,257.187775 440.577545,256.764404 440.225037,256.097931 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M432.676331,258.177551 
	C432.086975,258.389984 431.379578,258.271301 430.349304,257.792511 
	C430.026398,257.432404 430.025543,256.998779 430.025543,256.998779 
	C430.869812,257.281311 431.714081,257.563873 432.676331,258.177551 
z"/>
<path fill="#A99688" opacity="1.000000" stroke="none" 
	d="
M437.326324,253.098694 
	C437.854156,253.337631 438.443878,253.795975 438.817108,254.469391 
	C438.423767,254.619186 438.246979,254.553940 438.070221,254.488708 
	C437.842896,254.098511 437.615570,253.708313 437.326324,253.098694 
z"/>
<path fill="#E167B4" opacity="1.000000" stroke="none" 
	d="
M435.298035,251.017059 
	C435.276459,250.628525 435.379425,250.351776 435.482422,250.075027 
	C435.590668,250.314758 435.698883,250.554489 435.776184,251.006012 
	C435.745270,251.217819 435.422607,251.128815 435.298035,251.017059 
z"/>
<path fill="#274248" opacity="1.000000" stroke="none" 
	d="
M429.620300,257.069183 
	C430.025543,256.998779 430.026398,257.432404 430.017090,257.648926 
	C429.626434,257.829041 429.245117,257.792572 428.644165,257.561584 
	C428.688049,257.291229 428.951538,257.215424 429.620300,257.069183 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M438.239502,254.805817 
	C438.246979,254.553940 438.423767,254.619186 438.874817,254.757751 
	C439.231812,255.006607 439.314514,255.182129 439.494080,255.633301 
	C439.196838,255.646957 438.802795,255.384933 438.239502,254.805817 
z"/>
<path fill="#182A30" opacity="1.000000" stroke="none" 
	d="
M416.220337,226.800201 
	C417.718048,227.400223 419.215790,228.000259 420.929565,228.956604 
	C419.192169,232.341385 417.238708,235.369858 414.965363,238.936859 
	C410.959747,243.696823 407.274048,247.918259 403.282837,252.258453 
	C402.741913,252.733139 402.506439,253.089050 402.277161,253.358994 
	C402.283295,253.273026 402.444244,253.334778 402.162170,253.401779 
	C401.699738,253.793533 401.519440,254.118286 401.344482,254.354553 
	C401.349854,254.266052 401.515198,254.330109 401.187500,254.385147 
	C400.535126,254.734482 400.210388,255.028763 399.779633,255.596466 
	C399.393677,256.583008 399.113708,257.296112 398.435669,258.003174 
	C394.894470,257.974091 393.538422,256.611237 394.927307,253.592117 
	C396.161316,253.172852 397.353638,252.790634 397.954803,251.973495 
	C404.098419,243.622910 410.145935,235.201691 416.220337,226.800201 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M420.774170,250.899902 
	C422.315857,252.558716 423.589020,254.107086 425.023315,255.959488 
	C422.815430,256.511536 420.447998,256.943390 418.077057,256.963440 
	C414.707336,256.992004 411.894379,257.835510 412.205841,261.763367 
	C412.515320,265.666107 415.668152,264.870300 418.273041,264.571045 
	C419.252960,264.458466 420.224915,264.276764 421.473755,264.515259 
	C417.390717,265.648956 413.034424,266.393951 408.435669,267.000977 
	C407.817993,266.232880 407.442780,265.602783 407.069489,264.530579 
	C406.951538,262.031555 406.831696,259.974609 406.851196,257.584534 
	C408.998871,254.814270 411.007172,252.377167 413.270081,250.002258 
	C413.680847,249.985977 413.836975,249.907486 414.404541,249.892899 
	C416.199493,249.997757 417.583008,250.038712 418.966522,250.079666 
	C419.479584,250.316254 419.992615,250.552856 420.774170,250.899902 
z"/>
<path fill="#88EEF8" opacity="1.000000" stroke="none" 
	d="
M413.015472,249.940063 
	C411.007172,252.377167 408.998871,254.814270 406.510742,257.579407 
	C404.535004,256.943756 403.039154,255.980057 401.564850,254.843643 
	C401.586426,254.670914 401.515198,254.330109 401.515198,254.330109 
	C401.515198,254.330109 401.349854,254.266052 401.615662,254.272186 
	C402.069061,253.963806 402.256653,253.649292 402.444244,253.334778 
	C402.444244,253.334778 402.283295,253.273026 402.586945,253.248215 
	C403.123138,252.862152 403.355713,252.500900 403.588318,252.139679 
	C407.274048,247.918259 410.959747,243.696823 414.915039,239.273621 
	C418.010254,241.908401 421.061829,244.660522 418.989014,249.698914 
	C417.583008,250.038712 416.199493,249.997757 414.301697,249.758530 
	C413.530121,249.686859 413.272797,249.813477 413.015472,249.940063 
z"/>
<path fill="#F8CD0D" opacity="1.000000" stroke="none" 
	d="
M464.560425,254.420517 
	C463.647614,254.621826 462.726349,254.811752 461.409332,255.009521 
	C461.013580,255.017349 460.924988,254.946487 460.924988,254.946487 
	C462.313049,247.891663 463.701141,240.836823 465.196442,233.236984 
	C470.256012,237.075745 468.705109,242.254135 468.018555,247.640015 
	C468.044128,248.055695 468.013031,248.017227 467.788849,248.202484 
	C467.394836,248.959381 467.225037,249.530991 467.055237,250.102600 
	C467.055237,250.102615 466.963776,250.528091 466.564301,250.818848 
	C465.887146,251.832214 465.609497,252.554810 465.331848,253.277405 
	C465.331848,253.277405 464.992004,253.084656 464.899200,253.240967 
	C464.726440,253.727219 464.646484,254.057144 464.566498,254.387070 
	C464.566498,254.387070 464.551941,254.409149 464.560425,254.420517 
z"/>
<path fill="#C8B93E" opacity="1.000000" stroke="none" 
	d="
M465.562622,253.164078 
	C465.609497,252.554810 465.887146,251.832214 466.469360,251.014648 
	C466.813812,251.098541 466.853729,251.277405 466.895538,251.731567 
	C466.529419,252.354813 466.161407,252.702789 465.562622,253.164078 
z"/>
<path fill="#C8B93E" opacity="1.000000" stroke="none" 
	d="
M467.322083,249.943588 
	C467.225037,249.530991 467.394836,248.959381 467.812653,248.207581 
	C467.903442,248.613129 467.746216,249.198853 467.322083,249.943588 
z"/>
<path fill="#6AC8E0" opacity="1.000000" stroke="none" 
	d="
M380.203857,92.445190 
	C378.890259,94.784973 377.576721,97.124756 375.730255,100.413719 
	C376.393127,101.606194 377.684998,103.930305 378.672729,106.818268 
	C377.063599,109.670609 375.758636,111.959091 374.164673,114.065933 
	C372.538452,112.722008 371.201294,111.559738 369.729614,110.091385 
	C369.211273,109.576538 368.827484,109.367783 368.158447,109.119835 
	C366.924164,108.515198 365.975159,107.949753 364.834839,107.048027 
	C361.826904,105.984085 359.010254,105.256432 355.913574,104.585388 
	C355.089752,104.497307 354.545990,104.352608 353.927917,104.035461 
	C353.566528,103.940086 353.279419,104.017159 352.603943,104.175407 
	C351.162140,104.221863 350.108795,104.187141 348.874603,103.912651 
	C347.781403,103.970573 346.869019,104.268265 345.599182,104.687279 
	C344.493896,104.853432 343.746063,104.898270 342.699158,104.698570 
	C340.340942,104.037361 338.291718,103.561249 336.220367,103.218819 
	C333.534607,102.774811 330.838623,102.267456 328.131348,102.150513 
	C327.242218,102.112106 325.828735,103.028664 325.531494,103.832588 
	C325.269867,104.540154 326.101288,105.792419 326.694702,106.630959 
	C327.167664,107.299240 328.009491,107.706459 328.687561,108.229561 
	C328.468933,108.701111 328.250305,109.172661 328.031647,109.644211 
	C326.127808,108.750443 324.223969,107.856674 322.156311,106.711647 
	C321.873138,106.299957 321.753754,106.139519 321.705353,105.573105 
	C321.791260,103.798187 321.806213,102.429237 322.076416,100.882034 
	C322.549316,100.135185 322.766968,99.566574 323.398834,99.012741 
	C327.026886,99.122772 331.427948,102.678215 332.739197,96.159592 
	C335.162811,96.356323 337.198517,96.491402 339.569458,96.612572 
	C340.229736,96.311935 340.554810,96.025215 340.872650,95.783249 
	C340.865387,95.828003 340.792969,95.773438 341.149170,95.829590 
	C342.337036,95.257034 343.168701,94.628334 344.195923,93.769455 
	C345.283417,93.071617 346.175385,92.603943 347.378540,92.142868 
	C348.820465,91.698868 349.951111,91.248268 351.515747,90.758759 
	C354.223083,90.260269 356.514465,89.874786 358.766541,89.327591 
	C366.341400,87.487076 373.611145,87.599106 380.203857,92.445190 
z"/>
<path fill="#112026" opacity="1.000000" stroke="none" 
	d="
M380.552917,92.448425 
	C373.611145,87.599106 366.341400,87.487076 358.766541,89.327591 
	C356.514465,89.874786 354.223083,90.260269 351.551025,90.465485 
	C351.770599,88.859543 352.388855,87.507950 353.340149,85.982300 
	C359.789551,84.800575 365.905914,83.792908 372.404968,82.814468 
	C373.542206,82.698456 374.296783,82.553215 375.366211,82.575958 
	C376.783356,83.183914 377.885651,83.623894 379.168884,84.332886 
	C381.003601,85.040688 382.657471,85.479462 384.311310,85.918236 
	C384.246643,84.593323 384.182007,83.268410 383.984863,81.694855 
	C383.143311,80.839127 382.434235,80.232025 381.824524,79.225082 
	C383.088440,77.824364 384.252960,76.823502 385.696899,75.887619 
	C386.649078,76.305588 387.321838,76.658585 387.994568,77.011581 
	C388.511414,77.578552 389.028290,78.145515 389.900024,79.132706 
	C390.833405,80.373749 391.411896,81.194557 391.990356,82.015358 
	C392.044800,82.768387 392.099243,83.521423 392.021790,84.931030 
	C391.800232,86.018837 391.710541,86.450066 391.620850,86.881287 
	C390.516449,88.036430 389.412048,89.191582 387.771057,90.590187 
	C385.807495,91.508255 384.380615,92.182869 382.953705,92.857483 
	C382.269806,92.722198 381.585876,92.586922 380.552917,92.448425 
z"/>
<path fill="#66BBCF" opacity="1.000000" stroke="none" 
	d="
M374.453705,114.247574 
	C375.758636,111.959091 377.063599,109.670609 378.683655,107.186188 
	C382.282227,107.750130 385.565674,108.510017 389.729889,109.473747 
	C385.691345,112.480919 383.031982,114.374443 380.542603,116.469887 
	C380.330414,116.648506 381.250519,118.172371 381.838623,119.431969 
	C381.021271,120.210091 380.009857,120.627541 378.998474,121.044998 
	C378.998474,121.045006 379.002014,120.990273 378.852966,120.712112 
	C377.976440,119.473320 377.249023,118.512680 376.521576,117.552032 
	C376.521576,117.552032 376.270569,117.650169 376.333191,117.353951 
	C375.748444,116.121017 375.101074,115.184303 374.453705,114.247574 
z"/>
<path fill="#66BBCF" opacity="1.000000" stroke="none" 
	d="
M402.157928,88.884995 
	C403.595123,90.221901 404.871735,92.088242 406.396851,93.722656 
	C407.515869,94.921867 408.960754,95.816963 410.621979,96.895271 
	C409.885529,100.532211 408.065521,98.245522 406.207092,97.251678 
	C404.687378,94.439346 403.395630,91.877159 402.157928,88.884995 
z"/>
<path fill="#66BBCF" opacity="1.000000" stroke="none" 
	d="
M383.190430,93.125351 
	C384.380615,92.182869 385.807495,91.508255 387.581726,90.825729 
	C388.638947,91.985695 389.543304,93.084518 389.991150,94.345467 
	C390.294800,95.200516 389.957458,96.283226 389.906830,97.264122 
	C389.079071,97.013885 388.141174,96.932159 387.446716,96.478065 
	C386.037140,95.556389 384.759277,94.433296 383.190430,93.125351 
z"/>
<path fill="#66BBCF" opacity="1.000000" stroke="none" 
	d="
M387.993286,76.646652 
	C387.321838,76.658585 386.649078,76.305588 385.508362,75.653458 
	C384.856689,74.649467 384.673035,73.944611 384.489380,73.239746 
	C385.440430,73.252274 386.391479,73.264809 387.697693,73.321045 
	C388.032623,74.337082 388.012299,75.309410 387.993286,76.646652 
z"/>
<path fill="#111B1F" opacity="1.000000" stroke="none" 
	d="
M379.072876,121.352058 
	C380.009857,120.627541 381.021271,120.210091 382.192719,119.483017 
	C382.672211,119.092987 382.959564,118.948692 383.448669,118.676727 
	C382.899017,122.205566 382.175171,122.691750 379.072876,121.352058 
z"/>
<path fill="#66BBCF" opacity="1.000000" stroke="none" 
	d="
M392.146576,81.802979 
	C391.411896,81.194557 390.833405,80.373749 390.158142,79.263611 
	C390.808502,79.846390 391.555634,80.718491 392.146576,81.802979 
z"/>
<path fill="#17252C" opacity="1.000000" stroke="none" 
	d="
M398.676270,109.755844 
	C398.195831,110.155701 397.330841,110.547302 396.088562,110.914665 
	C395.711304,110.890434 395.888580,110.479706 395.953552,110.265678 
	C396.776306,109.950294 397.534027,109.848930 398.676270,109.755844 
z"/>
<path fill="#17252C" opacity="1.000000" stroke="none" 
	d="
M389.027893,114.341187 
	C388.758362,114.835655 388.193024,115.397728 387.266052,115.961594 
	C387.513672,115.445183 388.122894,114.926994 389.027893,114.341187 
z"/>
<path fill="#66BBCF" opacity="1.000000" stroke="none" 
	d="
M406.091370,81.082916 
	C406.145752,81.563545 405.904388,82.212440 405.312439,82.947472 
	C405.239746,82.439468 405.517670,81.845329 406.091370,81.082916 
z"/>
<path fill="#66BBCF" opacity="1.000000" stroke="none" 
	d="
M391.869141,86.807663 
	C391.710541,86.450066 391.800232,86.018837 392.068054,85.305893 
	C392.203278,85.594124 392.160370,86.164085 391.869141,86.807663 
z"/>
<path fill="#66BBCF" opacity="1.000000" stroke="none" 
	d="
M401.110962,85.087616 
	C401.332001,85.184044 401.365570,85.351540 401.321228,85.763008 
	C401.062439,85.832977 400.867981,85.669632 400.714508,85.474106 
	C400.690002,85.442871 400.849182,85.267456 401.110962,85.087616 
z"/>
<path fill="#17252C" opacity="1.000000" stroke="none" 
	d="
M384.543884,117.757751 
	C384.600311,117.987129 384.382874,118.286240 383.883667,118.644287 
	C383.824554,118.411308 384.047272,118.119392 384.543884,117.757751 
z"/>
<path fill="#17252C" opacity="1.000000" stroke="none" 
	d="
M385.623627,116.807281 
	C385.682617,117.025459 385.461121,117.329147 384.968811,117.735245 
	C384.913025,117.522705 385.128052,117.207748 385.623627,116.807281 
z"/>
<path fill="#17252C" opacity="1.000000" stroke="none" 
	d="
M390.512970,113.421982 
	C390.584381,113.694252 390.354462,114.046532 389.794281,114.420258 
	C389.713257,114.128456 389.962463,113.815224 390.512970,113.421982 
z"/>
<path fill="#66BBCF" opacity="1.000000" stroke="none" 
	d="
M391.874451,111.940063 
	C391.905823,112.198242 391.800476,112.329033 391.447449,112.506165 
	C391.379089,112.305885 391.558441,112.059273 391.874451,111.940063 
z"/>
<path fill="#60B3CB" opacity="1.000000" stroke="none" 
	d="
M332.351349,96.097954 
	C331.427948,102.678215 327.026886,99.122772 323.171631,99.014740 
	C322.226959,99.011108 321.923767,99.010849 321.463409,98.603325 
	C320.929108,95.461143 320.552002,92.716820 320.174927,89.972496 
	C321.293793,89.425018 322.969299,89.169899 323.436554,88.280205 
	C325.636292,84.091736 327.504547,79.729202 330.042297,75.332642 
	C331.711823,75.827049 332.830109,76.417595 334.115082,77.219757 
	C334.772552,77.629929 335.263397,77.828476 335.754211,78.027023 
	C335.465454,79.450691 335.176727,80.874367 334.801239,82.738602 
	C334.714478,83.179169 334.811218,83.284065 334.462402,83.431915 
	C332.130615,87.556023 327.294678,91.130669 332.351349,96.097954 
z"/>
<path fill="#EFAE17" opacity="1.000000" stroke="none" 
	d="
M342.671021,78.982391 
	C346.637543,76.250275 350.420685,73.188988 354.609436,70.855896 
	C362.818848,66.283340 371.863953,64.250771 381.681366,63.300926 
	C382.580048,63.485897 382.817963,63.355846 382.933777,63.016048 
	C382.933777,63.016048 382.970947,62.941483 383.397064,62.998974 
	C384.862457,63.032608 385.901764,63.008747 386.941040,62.984886 
	C386.941040,62.984890 387.015930,63.005947 386.977631,63.396736 
	C385.989594,65.265839 385.039825,66.744148 383.744568,68.340294 
	C381.936432,68.596542 380.473846,68.734962 378.908875,68.733704 
	C378.553772,68.732468 378.301025,68.870903 377.682678,69.123085 
	C375.540283,69.460106 373.763458,69.683372 371.821411,69.730385 
	C371.164246,69.776756 370.672272,69.999390 369.874176,70.354706 
	C368.697021,70.670944 367.826050,70.854500 366.713104,70.878281 
	C363.644562,72.111969 360.817993,73.505432 357.629639,74.944633 
	C354.821381,76.266449 352.374847,77.542526 349.564453,78.920303 
	C347.843384,79.555344 346.515015,80.366005 345.117584,80.510811 
	C344.362976,80.589020 343.490387,79.528938 342.671021,78.982391 
z"/>
<path fill="#52A7C1" opacity="1.000000" stroke="none" 
	d="
M336.008209,58.001785 
	C335.352844,53.181396 336.785461,48.663719 341.313141,47.180317 
	C345.249908,45.890530 350.180176,44.504017 353.786682,49.173435 
	C354.782928,50.463280 356.703522,51.039204 358.559174,51.954224 
	C358.465790,52.881241 358.013214,53.796734 356.934845,54.877655 
	C353.817047,55.649429 351.325043,56.255772 348.631775,57.067032 
	C347.937561,57.844849 347.444611,58.417744 346.595795,59.000565 
	C344.213928,60.017010 342.187958,61.023533 340.153015,61.676476 
	C340.600555,59.689724 341.057068,58.056561 341.886475,55.089344 
	C339.096741,56.471535 337.552490,57.236660 336.008209,58.001785 
z"/>
<path fill="#142931" opacity="1.000000" stroke="none" 
	d="
M349.928345,78.818604 
	C352.374847,77.542526 354.821381,76.266449 357.992065,75.001816 
	C360.739716,74.324257 362.763184,73.635254 365.219421,72.960312 
	C370.103943,72.314590 374.555603,71.654800 379.007294,70.995010 
	C380.604919,71.626328 382.202545,72.257637 384.144775,73.064346 
	C384.673035,73.944611 384.856689,74.649467 385.228882,75.588486 
	C384.252960,76.823502 383.088440,77.824364 381.441040,79.139343 
	C376.627289,78.754517 372.296417,78.055580 367.730469,77.301720 
	C367.495422,77.246796 367.041840,77.412170 366.654297,77.386559 
	C360.826874,79.192978 355.387024,81.025009 349.950806,82.453262 
	C349.945740,80.972527 349.937042,79.895561 349.928345,78.818604 
z"/>
<path fill="#2EBCE5" opacity="1.000000" stroke="none" 
	d="
M360.957306,55.994839 
	C363.650940,54.781731 366.253876,53.033592 369.072144,52.555965 
	C370.765656,52.268951 372.794922,53.963146 374.673309,54.766968 
	C373.444458,55.994091 372.425079,57.647736 370.949493,58.372448 
	C365.392303,61.101742 359.686615,63.528625 353.439819,65.993729 
	C352.480194,65.581131 352.117249,65.243584 351.860168,64.612442 
	C352.117920,63.931458 352.269745,63.544075 352.681519,63.069435 
	C353.353546,62.409344 353.765625,61.836506 354.381653,61.035660 
	C355.461517,60.275585 356.337433,59.743519 357.555267,59.164734 
	C358.917236,58.076950 359.937286,57.035892 360.957306,55.994839 
z"/>
<path fill="#49B3D5" opacity="1.000000" stroke="none" 
	d="
M340.161987,62.030056 
	C342.187958,61.023533 344.213928,60.017010 346.582855,59.361610 
	C346.595703,61.082077 346.265594,62.451412 345.935486,63.820747 
	C345.935486,63.820751 345.758911,64.269684 345.507172,64.637329 
	C345.019318,65.264305 344.925720,65.567627 344.974670,65.914948 
	C342.104370,69.838356 339.234039,73.761765 336.058960,77.856102 
	C335.263397,77.828476 334.772552,77.629929 334.073242,76.811302 
	C335.198090,72.464752 336.531464,68.738281 338.142853,64.858589 
	C339.001251,63.813595 339.581635,62.921825 340.161987,62.030056 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M349.564423,78.920296 
	C349.937042,79.895561 349.945740,80.972527 349.678467,82.576462 
	C348.950684,83.727325 348.498871,84.351227 347.717468,85.113205 
	C345.575531,86.827484 343.763153,88.403694 341.586853,89.998856 
	C336.615387,90.678772 335.574127,89.585220 334.811218,83.284065 
	C334.811218,83.284065 334.714478,83.179169 334.724304,83.109467 
	C337.162628,81.729721 339.591064,80.419693 342.345276,79.046021 
	C343.490387,79.528938 344.362976,80.589020 345.117584,80.510811 
	C346.515015,80.366005 347.843384,79.555344 349.564423,78.920296 
z"/>
<path fill="#2D5662" opacity="1.000000" stroke="none" 
	d="
M346.153870,64.053108 
	C346.265594,62.451412 346.595703,61.082077 346.938751,59.351692 
	C347.444611,58.417744 347.937561,57.844849 349.026337,57.108383 
	C350.774139,58.324081 351.926086,59.703342 352.836639,61.262505 
	C352.537323,62.013832 352.479431,62.585258 352.421570,63.156689 
	C352.269745,63.544075 352.117920,63.931458 351.600403,64.812241 
	C350.820892,66.329941 350.407074,67.354256 349.993225,68.378563 
	C349.477844,68.574409 348.535126,68.986008 348.509216,68.934753 
	C347.741089,67.413155 347.063873,65.845680 346.153870,64.053108 
z"/>
<path fill="#142122" opacity="1.000000" stroke="none" 
	d="
M379.292389,70.748703 
	C374.555603,71.654800 370.103943,72.314590 365.371277,72.696098 
	C365.711914,71.957893 366.333466,71.497971 366.955048,71.038040 
	C367.826050,70.854500 368.697021,70.670944 370.187256,70.413933 
	C371.199860,70.195869 371.593231,70.051262 371.986633,69.906647 
	C373.763458,69.683372 375.540283,69.460106 377.934875,69.172279 
	C378.705536,69.029617 378.858368,68.951500 379.011230,68.873383 
	C380.473846,68.734962 381.936432,68.596542 384.114594,68.375870 
	C385.560059,68.184837 386.289978,68.076065 387.019897,67.967285 
	C384.539093,68.812317 382.058289,69.657356 379.292389,70.748703 
z"/>
<path fill="#367483" opacity="1.000000" stroke="none" 
	d="
M353.078064,61.082600 
	C351.926086,59.703342 350.774139,58.324081 349.227600,56.903465 
	C351.325043,56.255772 353.817047,55.649429 356.668549,55.034924 
	C357.017181,55.501102 357.006317,55.975445 357.042297,57.144470 
	C357.130554,58.296589 357.171936,58.754025 357.213318,59.211456 
	C356.337433,59.743519 355.461517,60.275585 354.107971,60.978165 
	C353.446228,61.126652 353.262146,61.104626 353.078064,61.082600 
z"/>
<path fill="#AF7D35" opacity="1.000000" stroke="none" 
	d="
M387.319275,67.787910 
	C386.289978,68.076065 385.560059,68.184837 384.460083,68.258041 
	C385.039825,66.744148 385.989594,65.265839 386.967804,63.434372 
	C390.343567,64.158707 389.228699,65.849586 387.319275,67.787910 
z"/>
<path fill="#4791AB" opacity="1.000000" stroke="none" 
	d="
M357.555267,59.164734 
	C357.171936,58.754025 357.130554,58.296589 357.039551,57.500359 
	C358.080841,56.782990 359.171692,56.404419 360.609924,56.010338 
	C359.937286,57.035892 358.917236,58.076950 357.555267,59.164734 
z"/>
<path fill="#4791AB" opacity="1.000000" stroke="none" 
	d="
M350.334717,68.312622 
	C350.407074,67.354256 350.820892,66.329941 351.494507,65.105835 
	C352.117249,65.243584 352.480194,65.581131 353.137573,66.147232 
	C352.513367,66.999413 351.594788,67.623047 350.334717,68.312622 
z"/>
<path fill="#AF7D35" opacity="1.000000" stroke="none" 
	d="
M386.824341,62.671478 
	C385.901764,63.008747 384.862457,63.032608 383.438293,63.004959 
	C384.271484,62.754990 385.489563,62.556530 386.824341,62.671478 
z"/>
<path fill="#2D5662" opacity="1.000000" stroke="none" 
	d="
M345.189331,65.788589 
	C344.925720,65.567627 345.019318,65.264305 345.461182,64.874107 
	C345.758667,65.100960 345.671051,65.407295 345.189331,65.788589 
z"/>
<path fill="#AF7D35" opacity="1.000000" stroke="none" 
	d="
M382.686676,63.017548 
	C382.817963,63.355846 382.580048,63.485897 382.100830,63.305450 
	C381.981567,63.204700 382.439575,63.019043 382.686676,63.017548 
z"/>
<path fill="#17252C" opacity="1.000000" stroke="none" 
	d="
M402.756195,108.619080 
	C403.376343,108.725281 403.761536,108.974319 404.303772,109.531982 
	C403.821503,109.800179 403.182098,109.759743 402.089233,109.545578 
	C401.930908,109.168541 402.226044,108.965225 402.756195,108.619080 
z"/>
<path fill="#17252C" opacity="1.000000" stroke="none" 
	d="
M401.191315,109.337074 
	C401.316864,109.451645 401.386261,109.559608 401.455658,109.667572 
	C401.336975,109.697052 401.218262,109.726547 401.062805,109.558090 
	C401.026031,109.360130 401.135162,109.330475 401.191315,109.337074 
z"/>
<path fill="#66BBCF" opacity="1.000000" stroke="none" 
	d="
M398.491669,108.490013 
	C398.522766,108.475471 398.460571,108.504555 398.491669,108.490013 
z"/>
<path fill="#6CAFC1" opacity="1.000000" stroke="none" 
	d="
M424.240387,426.856689 
	C424.251678,425.826019 424.631897,424.791534 425.431793,423.309204 
	C426.690582,421.344604 427.670105,419.886566 428.334961,418.296936 
	C429.266418,416.069885 429.440796,414.032562 426.031799,414.064270 
	C426.839142,413.062378 428.068542,411.087860 428.382080,411.223877 
	C429.887238,411.877045 431.749786,412.818604 432.381378,414.172699 
	C434.620819,418.973999 431.199615,424.304535 424.240387,426.856689 
z"/>
<path fill="#739896" opacity="1.000000" stroke="none" 
	d="
M425.881592,414.399597 
	C429.440796,414.032562 429.266418,416.069885 428.334961,418.296936 
	C427.670105,419.886566 426.690582,421.344604 425.537476,422.990784 
	C424.925476,422.805328 424.627411,422.490479 424.057922,421.991516 
	C424.434814,419.449951 425.083130,417.092438 425.881592,414.399597 
z"/>
<path fill="#6CAFC1" opacity="1.000000" stroke="none" 
	d="
M419.185181,430.164673 
	C420.384583,428.948730 421.795532,427.952911 423.535553,426.914917 
	C422.375336,428.043396 420.886047,429.214111 419.185181,430.164673 
z"/>
<path fill="#6CAFC1" opacity="1.000000" stroke="none" 
	d="
M414.132507,432.091095 
	C414.443787,431.603455 414.931549,431.327057 415.707794,431.036774 
	C415.433838,431.449371 414.871429,431.875885 414.132507,432.091095 
z"/>
<path fill="#6CAFC1" opacity="1.000000" stroke="none" 
	d="
M400.378174,428.255798 
	C401.479218,432.495605 398.728729,431.140656 396.364014,430.956848 
	C395.855621,427.852539 397.836975,427.794617 400.378174,428.255798 
z"/>
<path fill="#60B3CB" opacity="1.000000" stroke="none" 
	d="
M395.055786,418.990845 
	C395.428894,418.585785 395.949127,418.402557 396.746155,418.286652 
	C396.416260,418.640198 395.809601,418.926453 395.055786,418.990845 
z"/>
<path fill="#58BDE0" opacity="1.000000" stroke="none" 
	d="
M403.929657,334.996185 
	C408.970215,335.348145 414.048309,336.215332 419.036682,335.851685 
	C421.862915,335.645660 424.541931,333.420380 427.638977,332.048401 
	C427.844879,333.408051 427.670258,334.821655 427.561493,336.240326 
	C427.454132,337.641266 427.413269,339.047272 427.561768,340.673889 
	C426.319855,343.077026 424.859436,345.257416 423.165405,347.808777 
	C422.857361,348.267426 422.702820,348.386627 422.722717,348.437225 
	C422.799133,348.631409 422.931519,348.803558 423.043030,348.983917 
	C422.214508,350.855316 421.385986,352.726715 419.961487,354.799713 
	C418.587646,355.361938 417.809753,355.722565 417.031860,356.083191 
	C414.094604,356.632568 411.157349,357.181976 407.491089,357.335144 
	C404.893402,356.577423 403.024719,356.215912 401.046448,355.738770 
	C400.871948,355.282562 400.696106,355.038300 400.518188,354.502014 
	C400.133911,353.279083 399.640717,352.444427 398.971680,351.413544 
	C398.202759,350.861755 397.609711,350.506195 397.006317,349.736237 
	C396.970947,348.495148 396.945892,347.668427 396.942871,346.496643 
	C398.612976,343.774963 400.260986,341.398376 402.271301,338.929169 
	C403.035065,337.757629 403.436493,336.678711 403.793060,335.438568 
	C403.748199,335.277344 403.929657,334.996185 403.929657,334.996185 
z"/>
<path fill="#69CBE9" opacity="1.000000" stroke="none" 
	d="
M401.156067,355.854370 
	C403.024719,356.215912 404.893402,356.577423 407.074036,357.310242 
	C401.821686,360.742371 400.969330,364.314850 404.318359,371.516174 
	C401.670654,371.072296 400.044281,371.731598 399.089081,374.866852 
	C397.667084,379.534302 395.607483,384.007507 393.706055,388.995728 
	C390.467285,386.929474 387.059998,384.539398 390.130676,379.101074 
	C390.983826,376.969055 391.573181,375.491425 392.402344,373.765381 
	C394.928864,368.907654 397.215607,364.298309 399.730743,359.483215 
	C400.358124,358.136444 400.757080,356.995392 401.156067,355.854370 
z"/>
<path fill="#DCBD38" opacity="1.000000" stroke="none" 
	d="
M409.200592,366.911560 
	C407.306824,363.177429 410.984039,363.203949 412.639191,362.291595 
	C414.110016,361.480865 416.170593,361.114471 417.832855,361.388672 
	C423.895996,362.388672 425.451874,358.068146 427.420807,353.936920 
	C428.572235,351.520905 430.084290,349.276733 431.434113,346.955261 
	C432.121399,346.914337 432.808716,346.873413 433.496033,346.832458 
	C435.329803,351.598297 437.163574,356.364105 438.967010,361.594849 
	C438.936646,362.059753 438.762054,362.228821 438.462952,362.059143 
	C436.360321,358.237366 434.556854,354.585266 432.100006,349.610138 
	C430.516693,352.574646 429.707794,354.067657 428.918732,355.571045 
	C427.274353,358.704071 425.640411,361.842621 423.544983,364.967957 
	C419.333710,365.279541 415.579620,365.602142 411.544739,365.961487 
	C410.576141,366.302704 409.888367,366.607147 409.200592,366.911560 
z"/>
<path fill="#E5A318" opacity="1.000000" stroke="none" 
	d="
M409.299500,367.227356 
	C409.888367,366.607147 410.576141,366.302704 411.744049,366.255798 
	C415.150391,369.000885 418.076630,371.488434 420.977570,374.394409 
	C420.573425,378.667419 420.194550,382.522003 419.722565,387.324097 
	C422.049011,386.120453 423.032074,385.611816 424.063934,385.399811 
	C424.457916,386.166656 424.803101,386.636902 424.936218,387.320129 
	C424.268829,388.142792 423.813568,388.752502 423.358276,389.362244 
	C423.358307,389.362244 423.311707,389.374146 423.044403,389.370667 
	C422.430115,389.471710 422.212402,389.699677 422.123993,390.051056 
	C421.606445,390.319672 421.088898,390.588287 420.170074,390.768951 
	C419.561737,390.871918 419.354645,391.062836 419.147522,391.253723 
	C418.637329,391.434387 418.127136,391.615082 417.172668,391.603027 
	C416.427917,391.207031 416.108185,391.170288 415.769257,391.300049 
	C415.769257,391.300049 415.699188,391.272095 415.769501,390.941986 
	C415.612457,390.075806 415.385071,389.539734 415.157715,389.003662 
	C415.275238,388.502930 415.392731,388.002167 415.776550,387.099426 
	C416.042816,386.697418 416.025085,386.303040 416.025085,386.303040 
	C416.180542,385.493500 416.335999,384.683990 416.871399,383.351746 
	C417.271759,382.355286 417.292175,381.881561 417.312592,381.407837 
	C419.104767,378.157928 418.383240,375.596588 415.471924,373.300568 
	C413.290558,371.580292 411.411835,369.476288 409.299500,367.227356 
z"/>
<path fill="#E28614" opacity="1.000000" stroke="none" 
	d="
M425.148315,387.107147 
	C424.803101,386.636902 424.457916,386.166656 424.379211,385.226746 
	C431.128754,380.922394 440.455475,384.433899 441.999268,391.550568 
	C441.978363,392.151245 441.963715,392.494354 441.949036,392.837433 
	C437.685577,390.660645 433.422150,388.483887 428.543030,386.354980 
	C427.666656,386.676727 427.405884,386.950562 427.145111,387.224426 
	C427.145111,387.224426 426.895203,387.481415 426.382568,387.384003 
	C425.629395,387.226776 425.388855,387.166962 425.148315,387.107147 
z"/>
<path fill="#DCBD38" opacity="1.000000" stroke="none" 
	d="
M439.023956,361.975220 
	C443.259552,362.709229 447.454285,363.351135 451.535339,364.425171 
	C453.411041,364.918823 456.500153,364.938721 454.764557,368.717224 
	C450.058258,366.713715 448.431793,371.314941 445.140289,371.934814 
	C444.592468,371.552826 444.318054,371.285400 444.200073,370.688171 
	C445.632904,367.803406 444.858032,366.230286 442.242126,365.087158 
	C440.918518,364.508728 439.911957,363.204773 438.762054,362.228821 
	C438.762054,362.228821 438.936646,362.059753 439.023956,361.975220 
z"/>
<path fill="#CD9429" opacity="1.000000" stroke="none" 
	d="
M445.413666,372.049408 
	C448.431793,371.314941 450.058258,366.713715 454.478821,368.907074 
	C451.947021,371.103668 449.170593,373.459076 446.158112,375.458832 
	C443.590149,377.163422 442.835236,378.818359 443.741058,382.046875 
	C444.641144,385.254944 444.186523,388.843079 444.206482,392.651062 
	C444.086975,393.037537 444.013397,393.014282 444.024292,393.051300 
	C443.559204,393.099762 443.083191,393.111206 442.278107,392.980042 
	C441.963715,392.494354 441.978363,392.151245 442.251587,391.211975 
	C442.653717,388.848816 443.475769,386.761902 442.820190,385.371826 
	C440.352020,380.138245 441.077698,375.816620 445.413666,372.049408 
z"/>
<path fill="#AF7D35" opacity="1.000000" stroke="none" 
	d="
M424.936218,387.320129 
	C425.388855,387.166962 425.629395,387.226776 426.211060,387.436005 
	C425.693085,388.144226 424.833984,388.703033 423.666565,389.312042 
	C423.813568,388.752502 424.268829,388.142792 424.936218,387.320129 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M439.509888,394.959381 
	C439.830994,394.831757 440.148773,394.904266 440.466553,394.976776 
	C440.146545,395.037689 439.826538,395.098602 439.509888,394.959381 
z"/>
<path fill="#5297AD" opacity="1.000000" stroke="none" 
	d="
M417.372253,356.140015 
	C417.809753,355.722565 418.587646,355.361938 419.703857,355.016602 
	C419.265656,355.420227 418.489136,355.808533 417.372253,356.140015 
z"/>
<path fill="#AF7D35" opacity="1.000000" stroke="none" 
	d="
M417.138763,381.577820 
	C417.292175,381.881561 417.271759,382.355286 417.042938,382.975586 
	C416.877991,382.664062 416.921478,382.205902 417.138763,381.577820 
z"/>
<path fill="#AF7D35" opacity="1.000000" stroke="none" 
	d="
M415.022827,389.293518 
	C415.385071,389.539734 415.612457,390.075806 415.756653,390.906525 
	C415.411652,390.661926 415.149780,390.122650 415.022827,389.293518 
z"/>
<path fill="#AF7D35" opacity="1.000000" stroke="none" 
	d="
M419.386108,391.255310 
	C419.354645,391.062836 419.561737,390.871918 419.879456,390.815521 
	C419.990082,390.950043 419.624664,391.256927 419.386108,391.255310 
z"/>
<path fill="#5297AD" opacity="1.000000" stroke="none" 
	d="
M423.195557,348.791138 
	C422.931519,348.803558 422.799133,348.631409 422.722717,348.437225 
	C422.702820,348.386627 422.857361,348.267426 423.104126,348.145874 
	C423.276428,348.112030 423.348083,348.598389 423.195557,348.791138 
z"/>
<path fill="#AF7D35" opacity="1.000000" stroke="none" 
	d="
M415.908264,391.532745 
	C416.108185,391.170288 416.427917,391.207031 416.899109,391.592102 
	C416.728943,391.771088 416.388123,391.768250 415.908264,391.532745 
z"/>
<path fill="#AF7D35" opacity="1.000000" stroke="none" 
	d="
M422.361084,390.042969 
	C422.212402,389.699677 422.430115,389.471710 423.024414,389.384094 
	C423.146759,389.718109 422.922241,389.929413 422.361084,390.042969 
z"/>
<path fill="#AF7D35" opacity="1.000000" stroke="none" 
	d="
M427.421173,387.173676 
	C427.405884,386.950562 427.666656,386.676727 428.223022,386.392120 
	C428.244873,386.628571 427.971039,386.875732 427.421173,387.173676 
z"/>
<path fill="#AF7D35" opacity="1.000000" stroke="none" 
	d="
M415.891693,386.336731 
	C416.025085,386.303040 416.042816,386.697418 415.960724,386.758606 
	C415.878632,386.819763 415.678894,386.632263 415.678894,386.632263 
	C415.678894,386.632263 415.758301,386.370453 415.891693,386.336731 
z"/>
<path fill="#102532" opacity="1.000000" stroke="none" 
	d="
M400.526062,398.223785 
	C400.130157,398.137756 399.648071,397.756805 399.144287,397.033844 
	C399.561707,397.104218 400.000763,397.516541 400.526062,398.223785 
z"/>
<path fill="#102532" opacity="1.000000" stroke="none" 
	d="
M402.033051,399.715210 
	C401.598328,399.688629 401.059174,399.377899 400.507141,398.733521 
	C400.972412,398.743561 401.450500,399.087311 402.033051,399.715210 
z"/>
<path fill="#69CBE9" opacity="1.000000" stroke="none" 
	d="
M394.911804,390.769287 
	C394.522278,390.670715 394.076935,390.254822 393.576508,389.528687 
	C393.966248,389.629578 394.411102,390.040771 394.911804,390.769287 
z"/>
<path fill="#102532" opacity="1.000000" stroke="none" 
	d="
M404.559692,403.215454 
	C404.151703,403.071838 403.668060,402.596893 403.182129,401.741455 
	C403.614563,401.868652 404.049255,402.376404 404.559692,403.215454 
z"/>
<path fill="#112026" opacity="1.000000" stroke="none" 
	d="
M115.637177,98.019051 
	C115.097237,101.122612 114.261086,104.410568 113.079178,107.881439 
	C113.602600,104.777390 114.471786,101.490417 115.637177,98.019051 
z"/>
<path fill="#112026" opacity="1.000000" stroke="none" 
	d="
M117.649414,95.847954 
	C117.693283,96.031319 117.476219,96.343201 117.003815,96.789856 
	C116.961807,96.608574 117.175133,96.292526 117.649414,95.847954 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M110.537231,108.391159 
	C110.319260,108.580971 109.898102,108.492149 109.233887,108.185425 
	C109.438568,108.015854 109.886314,108.064194 110.537231,108.391159 
z"/>
<path fill="#182D3B" opacity="1.000000" stroke="none" 
	d="
M177.719254,70.681854 
	C177.849060,74.635422 177.317184,78.280571 172.649811,80.172012 
	C171.699951,79.522995 171.347336,78.978447 171.149277,78.249069 
	C171.303818,78.064232 171.743332,77.866722 171.998535,77.743797 
	C172.293396,76.790985 172.333038,75.961105 172.211670,74.819504 
	C168.620300,69.679565 166.981918,69.608337 162.616364,74.283081 
	C161.952972,74.977257 161.485458,75.494522 160.795715,75.708008 
	C158.537552,75.184128 156.207108,74.224747 154.525650,74.894554 
	C150.777344,76.387688 147.353256,78.694687 143.797043,80.670044 
	C142.054413,81.264725 140.311768,81.859406 138.011444,82.578217 
	C137.453735,82.702347 137.009354,82.930977 137.009354,82.930977 
	C136.613510,82.889389 136.217651,82.847794 135.526123,82.851120 
	C136.094009,81.169464 136.957550,79.442879 138.316696,77.320831 
	C141.306000,75.515778 143.799713,74.106171 146.642548,72.592850 
	C149.432831,71.259315 151.873962,70.029480 154.668213,68.717522 
	C156.819992,68.066345 158.618683,67.497292 160.878235,66.939072 
	C164.874664,66.134285 168.410248,65.318649 171.885803,64.363319 
	C171.825806,64.223618 172.058487,64.027847 172.058487,64.027847 
	C172.636230,62.189148 173.213974,60.350449 173.941101,58.166672 
	C174.950806,58.412064 175.811127,59.002533 176.929504,60.168610 
	C177.386353,62.490795 177.585159,64.237366 177.783966,65.983940 
	C177.662582,66.301849 177.541199,66.619751 177.275421,67.397026 
	C177.327103,68.798218 177.523178,69.740036 177.719254,70.681854 
z"/>
<path fill="#78CD6E" opacity="1.000000" stroke="none" 
	d="
M144.812500,59.961121 
	C146.047852,58.546116 147.102768,56.900764 148.555267,55.762970 
	C151.805923,53.216637 155.159958,50.769833 158.669815,48.603134 
	C160.042557,47.755714 161.946243,47.768375 163.608109,47.389305 
	C163.070847,49.414490 163.181625,52.420113 161.876129,53.282993 
	C157.784286,55.987545 153.158707,57.884628 147.970551,60.147980 
	C146.404953,60.121338 145.608734,60.041229 144.812500,59.961121 
z"/>
<path fill="#E5A318" opacity="1.000000" stroke="none" 
	d="
M126.276550,75.983467 
	C124.990410,76.809456 123.704262,77.635445 122.418121,78.461433 
	C122.013023,77.877258 121.607933,77.293091 121.202835,76.708916 
	C124.907211,68.445816 132.599777,64.593536 141.003952,60.736149 
	C145.411423,63.912155 140.350906,63.482075 139.674484,65.075653 
	C138.082092,66.279556 136.806732,67.392303 135.516998,68.515862 
	C135.502625,68.526672 135.472046,68.499115 135.472046,68.499115 
	C133.129272,70.262535 130.786499,72.025963 127.947800,74.067978 
	C127.060089,74.892204 126.668320,75.437836 126.276550,75.983467 
z"/>
<path fill="#1A2B2B" opacity="1.000000" stroke="none" 
	d="
M139.991501,64.984512 
	C140.350906,63.482075 145.411423,63.912155 141.371429,60.639000 
	C142.082962,60.317528 143.155396,60.106667 144.520172,59.928463 
	C145.608734,60.041229 146.404953,60.121338 147.600647,60.237022 
	C149.634766,60.531803 151.423096,61.334999 152.869171,60.926548 
	C155.214371,60.264130 156.017670,60.430550 155.026520,63.485703 
	C154.165009,64.370644 153.542038,64.736183 152.571655,65.136292 
	C150.161682,66.137566 148.099075,67.104286 145.835236,67.850998 
	C144.866028,68.251274 144.098053,68.871544 143.250885,69.428467 
	C143.171707,69.365112 143.313171,69.219780 143.042206,69.296188 
	C142.540085,69.659081 142.308945,69.945564 142.077850,70.181396 
	C142.077896,70.130737 142.177216,70.150681 141.805374,70.117943 
	C140.354813,70.853668 139.276077,71.622131 138.237122,72.431740 
	C138.276871,72.472878 138.259064,72.585907 138.259064,72.585907 
	C137.422714,71.499107 136.586380,70.412300 135.611038,68.912308 
	C135.472046,68.499115 135.502625,68.526672 135.865204,68.563202 
	C137.482346,67.394653 138.736923,66.189583 139.991501,64.984512 
z"/>
<path fill="#C8B93E" opacity="1.000000" stroke="none" 
	d="
M152.919052,65.101723 
	C153.542038,64.736183 154.165009,64.370644 154.985474,63.820793 
	C158.625458,62.534676 162.015320,60.879246 165.530762,60.545166 
	C167.539398,60.354279 169.752548,62.315369 171.966049,63.667686 
	C172.058487,64.027847 171.825806,64.223618 171.449707,64.418434 
	C167.521545,65.384918 163.969452,66.156578 160.417374,66.928238 
	C158.618683,67.497292 156.819992,68.066345 154.530289,68.342865 
	C153.665863,67.067467 153.292450,66.084595 152.919052,65.101723 
z"/>
<path fill="#73B6B6" opacity="1.000000" stroke="none" 
	d="
M146.293427,72.696564 
	C143.799713,74.106171 141.306000,75.515778 138.484680,77.028664 
	C137.404282,76.763603 136.651505,76.395264 135.569397,76.002060 
	C136.117828,74.991348 136.995621,74.005501 138.066254,72.802780 
	C138.259064,72.585907 138.276871,72.472878 138.572144,72.392654 
	C139.970673,71.591850 141.073944,70.871269 142.177216,70.150681 
	C142.177216,70.150681 142.077896,70.130737 142.371368,70.129501 
	C142.880966,69.825432 143.097061,69.522606 143.313171,69.219780 
	C143.313171,69.219780 143.171707,69.365112 143.578522,69.388542 
	C144.614120,69.194153 145.242889,68.976334 145.916458,69.250305 
	C146.071976,70.726921 146.182693,71.711739 146.293427,72.696564 
z"/>
<path fill="#1A2B2B" opacity="1.000000" stroke="none" 
	d="
M126.537201,75.932480 
	C126.668320,75.437836 127.060089,74.892204 127.711426,74.320816 
	C127.579948,74.823875 127.188904,75.352684 126.537201,75.932480 
z"/>
<path fill="#112026" opacity="1.000000" stroke="none" 
	d="
M154.999161,89.607300 
	C143.557266,88.103127 132.315765,89.702759 120.946266,93.561981 
	C124.407532,91.832619 128.258057,90.210464 132.885925,88.629456 
	C136.784424,88.145477 139.905563,87.620361 143.026688,87.095253 
	C146.958099,87.823784 150.889526,88.552307 154.999161,89.607300 
z"/>
<path fill="#52A7C1" opacity="1.000000" stroke="none" 
	d="
M142.761932,86.828033 
	C139.905563,87.620361 136.784424,88.145477 133.267700,88.495613 
	C132.856644,88.028244 132.887512,87.740829 132.992584,87.243118 
	C136.179367,86.872185 139.338287,86.716507 142.761932,86.828033 
z"/>
<path fill="#112026" opacity="1.000000" stroke="none" 
	d="
M120.309296,93.536247 
	C120.202278,93.910461 119.802811,94.377129 119.067749,94.872574 
	C119.160385,94.477142 119.588615,94.052917 120.309296,93.536247 
z"/>
<path fill="#102532" opacity="1.000000" stroke="none" 
	d="
M353.088074,419.027679 
	C349.317871,419.739410 345.476837,420.213531 341.819702,421.304352 
	C340.688263,421.641846 340.057220,423.656952 339.237488,425.276123 
	C335.268585,423.442871 332.424164,418.967255 326.900818,419.599701 
	C325.989197,419.704071 324.664337,418.244202 323.907318,417.226807 
	C322.354156,415.139374 321.171509,412.838715 318.108185,415.468201 
	C317.587280,415.915314 316.052490,415.116119 314.974579,414.996704 
	C313.778320,414.864166 312.543091,414.664917 311.370911,414.827301 
	C309.371613,415.104218 307.408295,415.640839 304.740906,416.217285 
	C303.613068,417.018921 301.782684,419.115906 299.579590,419.636841 
	C297.780426,420.062256 295.536865,418.603699 293.489227,417.983978 
	C291.610565,417.415375 289.752502,416.494507 287.845367,416.376862 
	C285.511810,416.232880 283.136597,416.763702 280.343933,416.743927 
	C279.576416,416.182861 279.246460,415.878174 278.899536,415.594147 
	C278.891632,415.587708 278.691406,415.816162 278.580902,415.935059 
	C279.053101,416.290863 279.525330,416.646667 279.997559,417.002441 
	C280.630859,418.438629 281.264191,419.874817 281.481232,421.683533 
	C268.668579,422.171173 256.272156,422.286224 243.416840,422.373047 
	C236.355621,422.325989 229.753311,422.307220 222.695816,422.170837 
	C218.877579,422.758087 214.827942,419.800598 212.111130,423.952332 
	C210.817490,423.756042 209.523849,423.559784 207.845215,423.305084 
	C210.196487,420.348083 212.041641,418.027649 214.146103,415.221405 
	C214.658035,414.514984 214.856613,414.253326 215.238739,413.795563 
	C215.624023,413.522369 215.771759,413.404327 216.324173,413.399963 
	C218.125732,413.624756 219.522614,413.735901 221.231476,414.138214 
	C227.999344,414.621643 234.454987,414.924561 240.911362,414.941223 
	C242.948441,414.946472 244.987488,414.185089 247.194931,413.639404 
	C247.650070,413.394867 247.853241,413.199493 248.439270,412.988770 
	C254.941864,412.905853 260.978912,412.752289 267.461365,412.684784 
	C270.925018,412.712921 273.943237,412.655029 277.400208,412.711334 
	C280.540497,412.697937 283.242035,412.570343 286.200745,412.528442 
	C286.632568,412.567535 286.807190,412.521027 287.370972,412.552063 
	C288.811554,412.568237 289.863007,412.506897 291.338379,412.570770 
	C292.917084,412.551849 294.071808,412.407715 295.533936,412.324982 
	C296.255737,412.350739 296.670135,412.315155 297.521729,412.433167 
	C306.640564,412.406219 315.322205,412.225647 324.438110,412.112671 
	C326.914642,412.110077 328.956848,412.039886 331.456177,412.002716 
	C335.267609,411.942261 338.622009,411.848785 342.234436,411.844788 
	C342.668976,411.889679 342.845490,411.845062 343.388794,411.876373 
	C344.500763,411.939941 345.245972,411.927582 346.323273,412.020691 
	C347.105835,412.043549 347.556305,411.960938 348.330048,411.923096 
	C349.082062,411.904541 349.510773,411.841217 350.190704,411.980225 
	C350.625885,412.293793 350.809845,412.405060 350.999084,412.856750 
	C352.021484,414.485687 353.038666,415.774200 353.934692,417.309509 
	C353.571716,418.046783 353.329895,418.537231 353.088074,419.027679 
M273.544098,415.754791 
	C273.811951,415.942230 274.079803,416.129669 274.347626,416.317139 
	C274.175903,416.039001 274.004181,415.760834 273.544098,415.754791 
z"/>
<path fill="#FD367E" opacity="1.000000" stroke="none" 
	d="
M264.277008,466.951263 
	C264.234833,466.506805 264.192688,466.062317 264.423279,464.981384 
	C264.822021,462.566772 264.947968,460.788605 265.315002,458.681946 
	C265.382477,454.229431 265.208893,450.105438 265.027710,445.539825 
	C264.068115,439.908875 266.473602,438.283508 271.338989,438.914093 
	C273.445496,439.187073 275.655182,439.220978 277.750305,438.908905 
	C282.159393,438.252258 286.523743,437.295410 291.147125,436.321503 
	C291.935577,436.124542 292.483368,436.067871 293.031189,436.011200 
	C293.065826,438.389771 293.100464,440.768311 293.081085,443.595154 
	C293.027069,446.782806 293.027069,449.522156 293.027069,450.998901 
	C288.864471,450.998901 285.522125,450.952271 282.181641,451.010315 
	C277.594116,451.089935 275.375427,453.453217 274.945190,458.057129 
	C274.488678,462.942322 269.524353,467.633270 265.179199,467.111176 
	C264.672302,466.936707 264.277008,466.951263 264.277008,466.951263 
z"/>
<path fill="#38C1E8" opacity="1.000000" stroke="none" 
	d="
M229.965210,456.000702 
	C227.733078,455.708282 225.500931,455.415863 222.973907,454.626373 
	C222.869110,453.423096 223.059189,452.716858 223.589478,451.866882 
	C224.100494,447.523132 224.271317,443.323120 224.311676,438.843719 
	C224.080307,438.385193 223.979416,438.206055 223.878525,438.026947 
	C223.937775,437.865692 223.997009,437.704437 224.463242,437.320435 
	C225.274857,437.185547 225.679474,437.273376 226.277435,437.499084 
	C226.766510,437.779907 227.078491,437.843872 227.840576,437.797546 
	C237.083511,438.441711 245.892593,439.117126 254.790863,440.237549 
	C254.859329,442.794098 254.838577,444.905579 254.463196,447.158295 
	C253.876984,452.334625 253.645386,457.369781 253.440369,462.689087 
	C253.357666,463.331055 253.248398,463.688873 252.853668,463.754517 
	C252.376633,458.739594 252.028320,454.016296 252.044815,449.294281 
	C252.057266,445.736145 251.081696,444.443451 247.334656,445.677795 
	C242.411316,447.299622 237.623154,447.850861 235.075790,441.593231 
	C234.786865,440.883514 232.951004,440.803558 231.832443,440.431580 
	C231.651337,441.656158 231.366180,442.876587 231.316696,444.106445 
	C231.260681,445.499268 231.621155,446.928528 231.435410,448.292175 
	C231.082809,450.880432 230.469971,453.433197 229.965210,456.000702 
z"/>
<path fill="#FD367E" opacity="1.000000" stroke="none" 
	d="
M177.421799,428.800659 
	C177.461273,428.973236 177.500732,429.145844 177.385925,429.762634 
	C176.899872,430.842102 176.568100,431.477386 175.957870,432.242981 
	C175.423370,433.572205 175.167313,434.771088 174.903412,436.257812 
	C174.670731,437.066528 174.445847,437.587372 174.047546,438.138977 
	C173.792816,438.252197 173.628830,438.359589 173.644836,438.412781 
	C173.706284,438.616699 173.825562,438.803192 173.914108,439.285767 
	C173.633255,440.066376 173.361526,440.557434 172.775909,440.899323 
	C171.233078,439.919586 169.629456,439.350037 168.838287,438.214447 
	C164.822998,432.451172 158.588501,432.493927 152.790115,432.440918 
	C149.114563,432.407288 148.845810,431.175598 149.368546,428.407349 
	C149.919098,425.491669 150.460129,422.574219 151.379410,419.667847 
	C160.309586,422.719025 168.865692,425.759857 177.421799,428.800659 
z"/>
<path fill="#17252C" opacity="1.000000" stroke="none" 
	d="
M253.139145,464.046692 
	C253.248398,463.688873 253.357666,463.331055 253.771729,462.462769 
	C254.323624,456.973907 254.570724,451.995483 254.817841,447.017090 
	C254.838577,444.905579 254.859329,442.794098 254.879944,439.898132 
	C254.925446,438.408539 254.971069,437.703400 255.016693,436.998230 
	C257.446198,435.675110 259.875671,434.352020 262.722473,433.450867 
	C263.190613,434.155518 263.241425,434.438202 263.304993,434.811829 
	C263.317810,434.902832 263.160034,434.997040 263.160034,434.997040 
	C262.840729,439.271057 262.260345,443.545319 262.264679,447.819000 
	C262.270844,453.895477 262.706848,459.971497 262.938354,466.512817 
	C260.034882,466.652527 257.152252,466.327148 253.842743,465.685547 
	C253.323654,464.928436 253.231400,464.487579 253.139145,464.046692 
z"/>
<path fill="#367483" opacity="1.000000" stroke="none" 
	d="
M243.875748,422.401306 
	C256.272156,422.286224 268.668579,422.171173 281.532684,422.020874 
	C282.795685,422.294464 283.590973,422.603333 284.662109,423.241425 
	C284.862335,423.987762 284.786713,424.404877 284.711121,424.821991 
	C273.398193,425.167877 262.085297,425.513763 250.211212,425.449280 
	C247.725281,424.159698 245.800522,423.280487 243.875748,422.401306 
z"/>
<path fill="#1A2627" opacity="1.000000" stroke="none" 
	d="
M173.089783,441.048462 
	C173.361526,440.557434 173.633255,440.066376 174.066238,439.089844 
	C174.227509,438.604401 174.220978,438.108215 174.220978,438.108215 
	C174.445847,437.587372 174.670731,437.066528 175.152313,436.014160 
	C175.684769,434.359314 175.960526,433.235992 176.236298,432.112640 
	C176.568100,431.477386 176.899872,430.842102 177.432098,430.024170 
	C179.986023,429.709961 182.339478,429.578461 185.171265,429.689697 
	C185.785385,430.271484 185.921188,430.610565 186.056976,430.949615 
	C183.646606,439.291809 181.236252,447.634033 178.484680,456.134949 
	C179.348862,450.544891 180.554260,444.796173 181.819473,438.762238 
	C177.954605,438.259613 175.861206,439.508881 174.702103,443.190430 
	C173.269684,447.740082 171.237503,452.100891 169.178528,456.757782 
	C168.984818,455.922821 169.077026,454.873383 169.371765,453.153992 
	C170.746124,448.672211 171.917953,444.860321 173.089783,441.048462 
z"/>
<path fill="#E8E5C6" opacity="1.000000" stroke="none" 
	d="
M186.390350,430.973877 
	C185.921188,430.610565 185.785385,430.271484 185.539337,429.677490 
	C185.429092,429.422546 185.428833,429.380646 185.644684,429.134155 
	C186.163605,428.305237 186.466675,427.722870 186.769745,427.140472 
	C195.356277,428.796509 203.960663,430.368683 212.512360,432.188416 
	C213.994736,432.503845 215.254211,433.866821 216.473801,435.189514 
	C215.960464,435.962463 215.589966,436.291809 215.219482,436.621185 
	C205.720901,434.746826 196.222305,432.872467 186.390350,430.973877 
z"/>
<path fill="#E4BDCF" opacity="1.000000" stroke="none" 
	d="
M263.174225,435.282471 
	C263.160034,434.997040 263.317810,434.902832 263.735931,434.733063 
	C273.740051,433.849396 283.326050,433.135529 292.912048,432.421631 
	C293.124420,433.534119 293.336823,434.646576 293.290192,435.885132 
	C292.483368,436.067871 291.935577,436.124542 290.688751,436.337952 
	C281.297241,436.852448 272.604797,437.210205 263.912323,437.567963 
	C263.671021,436.901276 263.429718,436.234619 263.174225,435.282471 
z"/>
<path fill="#D6E7CD" opacity="1.000000" stroke="none" 
	d="
M331.244202,425.708527 
	C331.495728,426.520721 331.462463,427.098938 331.348999,427.946777 
	C321.833038,430.033264 312.397308,431.850098 302.585388,433.783386 
	C302.358276,432.734863 302.322388,430.614380 302.683441,430.544495 
	C312.083649,428.724762 321.525085,427.118011 331.244202,425.708527 
z"/>
<path fill="#B3E8ED" opacity="1.000000" stroke="none" 
	d="
M254.707458,436.730988 
	C254.971069,437.703400 254.925446,438.408539 254.790741,439.453125 
	C245.892593,439.117126 237.083511,438.441711 227.647369,437.642578 
	C226.730392,437.334076 226.418320,437.281494 226.084106,437.361176 
	C225.679474,437.273376 225.274857,437.185547 224.560822,437.081970 
	C225.177689,436.072083 226.109772,434.212494 227.029358,434.218658 
	C235.583466,434.275879 244.136688,434.625031 252.685196,435.002075 
	C253.276535,435.028168 253.828140,435.955505 254.707458,436.730988 
z"/>
<path fill="#E4BDCF" opacity="1.000000" stroke="none" 
	d="
M177.668518,428.591980 
	C168.865692,425.759857 160.309586,422.719025 151.289413,419.598450 
	C150.825333,419.518738 150.608643,419.597809 150.564880,419.429077 
	C150.521103,419.260315 150.313416,418.980255 150.313416,418.980255 
	C150.617691,418.843933 150.778091,418.609253 151.021271,417.895874 
	C153.024475,417.817383 154.801071,418.119263 156.605225,418.430206 
	C156.632782,418.439270 156.690475,418.433105 156.940536,418.679321 
	C161.020737,420.128815 164.850876,421.332092 168.681030,422.535400 
	C168.681030,422.535370 168.813660,422.446075 168.980423,422.674713 
	C169.994080,423.120117 170.840973,423.336884 171.687851,423.553650 
	C171.687851,423.553650 171.759583,423.485565 171.996521,423.710632 
	C173.190704,424.169373 174.147949,424.403076 175.105194,424.636780 
	C175.105194,424.636749 175.248535,424.582886 175.482849,424.775696 
	C176.435562,425.139832 177.153992,425.311157 177.872406,425.482513 
	C177.872406,425.482513 178.025879,425.870880 178.218445,426.275024 
	C178.424927,427.127655 178.438843,427.576111 178.452759,428.024597 
	C178.273590,428.144165 178.094421,428.263733 177.668518,428.591980 
z"/>
<path fill="#5297AD" opacity="1.000000" stroke="none" 
	d="
M243.416840,422.373047 
	C245.800522,423.280487 247.725281,424.159698 249.740173,425.357605 
	C242.836731,425.560577 235.843155,425.444855 228.205750,425.014282 
	C226.091599,423.895782 224.621307,423.092133 223.151016,422.288452 
	C229.753311,422.307220 236.355621,422.325989 243.416840,422.373047 
z"/>
<path fill="#102129" opacity="1.000000" stroke="none" 
	d="
M177.010986,415.001556 
	C174.614487,415.338074 172.115601,415.367676 169.858078,416.120789 
	C168.393997,416.609192 167.282288,418.153809 165.613571,419.616638 
	C162.373138,419.483093 159.531799,418.958099 156.690475,418.433105 
	C156.690475,418.433105 156.632782,418.439270 156.698502,418.055298 
	C156.806229,417.417328 156.848251,417.163300 157.113586,416.711670 
	C158.243790,415.849731 159.150696,415.185455 160.466583,414.392761 
	C162.585571,414.118713 164.295547,413.973083 166.252792,413.893707 
	C166.662628,413.908051 166.825195,413.856110 167.403839,413.876770 
	C169.219650,413.878265 170.619370,413.807159 172.259796,413.805145 
	C172.658066,413.819092 172.815628,413.763916 173.343658,413.792725 
	C174.470169,413.946320 175.226227,414.015900 176.113022,414.279541 
	C176.462784,414.702942 176.718521,414.878937 177.010986,415.001556 
z"/>
<path fill="#12242D" opacity="1.000000" stroke="none" 
	d="
M223.570160,438.246826 
	C223.979416,438.206055 224.080307,438.385193 224.187439,439.254883 
	C223.878876,443.967194 223.564087,447.988922 223.249298,452.010651 
	C223.059189,452.716858 222.869110,453.423096 222.649109,454.496155 
	C222.006531,457.547577 221.393845,460.232147 220.441101,463.099945 
	C221.154602,455.011017 222.208191,446.738861 223.570160,438.246826 
z"/>
<path fill="#252230" opacity="1.000000" stroke="none" 
	d="
M292.671844,432.074860 
	C283.326050,433.135529 273.740051,433.849396 263.723145,434.642090 
	C263.241425,434.438202 263.190613,434.155518 263.084961,433.445801 
	C271.230957,432.351257 279.427826,431.627014 287.635864,431.065796 
	C289.212006,430.958069 290.831909,431.490814 292.671844,432.074860 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M215.165649,437.061523 
	C215.589966,436.291809 215.960464,435.962463 216.553619,435.510742 
	C215.290039,443.452240 213.803772,451.516083 212.017303,459.828491 
	C212.848679,452.551971 213.980240,445.026917 215.165649,437.061523 
z"/>
<path fill="#367483" opacity="1.000000" stroke="none" 
	d="
M222.695816,422.170837 
	C224.621307,423.092133 226.091599,423.895782 227.797943,424.965302 
	C222.997650,424.796783 217.961288,424.362427 212.518036,423.940216 
	C214.827942,419.800598 218.877579,422.758087 222.695816,422.170837 
z"/>
<path fill="#0D3543" opacity="1.000000" stroke="none" 
	d="
M177.478470,414.997253 
	C176.718521,414.878937 176.462784,414.702942 176.452515,414.118378 
	C177.113663,413.555634 177.566071,413.348083 178.396637,413.266907 
	C179.820877,413.309998 180.866928,413.226715 182.331451,413.311401 
	C184.529663,413.383698 186.309402,413.288025 188.519821,413.290100 
	C191.620056,413.350586 194.289597,413.313293 197.342285,413.422241 
	C198.815247,413.680969 199.905060,413.793488 200.994858,413.906006 
	C196.789001,414.263428 192.588730,414.731873 188.375549,414.943665 
	C184.906952,415.118011 181.423065,414.988037 177.478470,414.997253 
z"/>
<path fill="#1F3B3E" opacity="1.000000" stroke="none" 
	d="
M285.137451,424.875671 
	C284.786713,424.404877 284.862335,423.987762 284.983887,423.254272 
	C287.471375,420.988922 289.914001,419.831390 293.267120,423.431335 
	C290.205475,424.026733 287.884613,424.478058 285.137451,424.875671 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M156.940536,418.679321 
	C159.531799,418.958099 162.373138,419.483093 165.607895,420.031189 
	C166.847824,420.671783 167.694305,421.289307 168.610901,422.221100 
	C164.850876,421.332092 161.020737,420.128815 156.940536,418.679321 
z"/>
<path fill="#111A1E" opacity="1.000000" stroke="none" 
	d="
M215.001190,413.950684 
	C214.856613,414.253326 214.658035,414.514984 214.199188,414.873291 
	C211.221786,414.687286 208.450592,414.363586 205.357224,413.820892 
	C205.035034,413.601898 205.080475,413.219025 205.471832,413.250488 
	C206.617935,413.106262 207.372696,412.930542 208.512512,412.881775 
	C210.248077,413.051605 211.598602,413.094513 213.149078,413.338348 
	C213.899750,413.676453 214.450470,413.813568 215.001190,413.950684 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M205.080475,413.219055 
	C205.080475,413.219025 205.035034,413.601898 204.946808,413.773254 
	C203.826920,413.888031 202.795258,413.831451 201.379227,413.840454 
	C199.905060,413.793488 198.815247,413.680969 197.542023,413.146698 
	C199.932571,412.889648 202.506516,413.054352 205.080475,413.219055 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M178.675842,427.941528 
	C178.438843,427.576111 178.424927,427.127655 178.415924,426.342834 
	C178.580200,426.623840 178.739563,427.241119 178.675842,427.941528 
z"/>
<path fill="#12242D" opacity="1.000000" stroke="none" 
	d="
M219.727814,465.277344 
	C219.928543,466.268738 219.882233,467.531342 219.495056,468.993622 
	C219.263077,467.978394 219.371933,466.763458 219.727814,465.277344 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M171.559448,423.204224 
	C170.840973,423.336884 169.994080,423.120117 168.900970,422.683472 
	C169.580170,422.594025 170.505615,422.724426 171.559448,423.204224 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M174.895645,424.287842 
	C174.147949,424.403076 173.190704,424.169373 171.949005,423.696930 
	C172.671722,423.618439 173.678909,423.778687 174.895645,424.287842 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M177.655487,425.152985 
	C177.153992,425.311157 176.435562,425.139832 175.456940,424.703674 
	C175.944000,424.567047 176.691284,424.695251 177.655487,425.152985 
z"/>
<path fill="#1A2627" opacity="1.000000" stroke="none" 
	d="
M186.421112,427.270630 
	C186.466675,427.722870 186.163605,428.305237 185.629364,429.119751 
	C185.622940,428.701508 185.847717,428.051178 186.421112,427.270630 
z"/>
<path fill="#1B2B35" opacity="1.000000" stroke="none" 
	d="
M305.134399,458.696014 
	C305.280273,458.747131 305.448456,459.045013 305.560669,459.598175 
	C305.317719,459.577057 305.201691,459.273499 305.134399,458.696014 
z"/>
<path fill="#D22C77" opacity="1.000000" stroke="none" 
	d="
M264.164062,467.274323 
	C264.277008,466.951263 264.672302,466.936707 264.869385,466.959839 
	C264.906891,467.506195 264.747314,468.029449 264.394592,468.746521 
	C264.151337,468.492676 264.101227,468.045044 264.164062,467.274323 
z"/>
<path fill="#1A2627" opacity="1.000000" stroke="none" 
	d="
M167.337784,461.791962 
	C167.501938,460.452911 167.971680,458.930237 168.685257,457.170105 
	C168.500519,458.491180 168.071945,460.049774 167.337784,461.791962 
z"/>
<path fill="#F84086" opacity="1.000000" stroke="none" 
	d="
M150.608643,419.597809 
	C150.608643,419.597809 150.825333,419.518738 150.915329,419.588135 
	C150.460129,422.574219 149.919098,425.491669 149.368546,428.407349 
	C148.845810,431.175598 149.114563,432.407288 152.790115,432.440918 
	C158.588501,432.493927 164.822998,432.451172 168.838287,438.214447 
	C169.629456,439.350037 171.233078,439.919586 172.775909,440.899323 
	C171.917953,444.860321 170.746124,448.672211 169.345779,452.758850 
	C168.941437,453.030334 168.765610,453.027039 168.068268,452.748291 
	C167.335907,451.192291 167.206512,449.891785 166.899155,448.634857 
	C165.933456,444.685638 161.922699,442.682037 157.910110,444.089508 
	C152.771332,445.651093 147.629272,447.202087 142.496201,448.782196 
	C141.308472,449.147858 140.151382,449.613037 138.726532,449.828918 
	C141.015106,443.338043 143.557312,437.050659 146.122986,430.387726 
	C146.547180,429.102539 146.947937,428.192963 147.544922,426.781982 
	C147.845490,425.851257 147.949829,425.421936 148.054153,424.992584 
	C148.287842,424.760925 148.413528,424.480774 148.611832,423.729248 
	C148.890198,422.866638 148.987930,422.426971 149.085663,421.987335 
	C149.085663,421.987335 149.264633,421.787567 149.476379,421.360321 
	C149.994949,420.487976 150.301788,420.042908 150.608643,419.597809 
z"/>
<path fill="#FC4B94" opacity="1.000000" stroke="none" 
	d="
M158.095276,444.371277 
	C161.922699,442.682037 165.933456,444.685638 166.899155,448.634857 
	C167.206512,449.891785 167.335907,451.192291 167.804504,452.751953 
	C166.984619,456.584991 165.556030,456.602722 163.712692,453.508270 
	C161.940628,450.533478 160.094208,447.603027 158.095276,444.371277 
z"/>
<path fill="#2EC9F5" opacity="1.000000" stroke="none" 
	d="
M252.853668,463.754517 
	C253.231400,464.487579 253.323654,464.928436 253.484406,465.702759 
	C253.552887,466.036224 253.282761,466.057281 252.888947,466.222900 
	C251.620407,466.979889 250.745651,467.571228 249.870911,468.162598 
	C248.423660,467.201965 246.976410,466.241333 245.228912,465.147400 
	C239.331390,458.842102 238.172424,458.711426 231.216187,463.757080 
	C230.818207,464.018555 230.638000,464.015198 230.186401,464.015167 
	C229.937210,461.642181 229.959442,459.265869 229.973450,456.445129 
	C230.469971,453.433197 231.082809,450.880432 231.435410,448.292175 
	C231.621155,446.928528 231.260681,445.499268 231.316696,444.106445 
	C231.366180,442.876587 231.651337,441.656158 231.832443,440.431580 
	C232.951004,440.803558 234.786865,440.883514 235.075790,441.593231 
	C237.623154,447.850861 242.411316,447.299622 247.334656,445.677795 
	C251.081696,444.443451 252.057266,445.736145 252.044815,449.294281 
	C252.028320,454.016296 252.376633,458.739594 252.853668,463.754517 
z"/>
<path fill="#38C1E8" opacity="1.000000" stroke="none" 
	d="
M250.066223,468.429199 
	C250.745651,467.571228 251.620407,466.979889 252.755768,466.198212 
	C253.102600,467.066162 253.188828,468.124451 253.231750,469.581146 
	C252.212799,469.551636 251.237183,469.123718 250.066223,468.429199 
z"/>
<path fill="#D22C77" opacity="1.000000" stroke="none" 
	d="
M263.837585,438.071838 
	C272.604797,437.210205 281.297241,436.852448 290.448090,436.478210 
	C286.523743,437.295410 282.159393,438.252258 277.750305,438.908905 
	C275.655182,439.220978 273.445496,439.187073 271.338989,438.914093 
	C266.473602,438.283508 264.068115,439.908875 265.011536,446.004669 
	C265.026581,450.944214 265.050262,454.977325 265.073944,459.010437 
	C264.947968,460.788605 264.822021,462.566772 264.484558,464.650665 
	C264.102997,456.162842 263.932922,447.369263 263.837585,438.071838 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M353.483765,419.091278 
	C353.329895,418.537231 353.571716,418.046783 354.311005,417.277008 
	C357.121460,416.273315 359.434418,415.548981 361.747406,414.824615 
	C361.660675,414.541412 361.573914,414.258179 361.487183,413.974976 
	C359.340790,413.974976 357.194366,413.974976 355.053131,413.709351 
	C355.078796,413.090088 355.099304,412.736420 355.122192,412.214417 
	C355.124573,412.046051 355.007385,411.730438 355.458374,411.829712 
	C359.308411,412.066681 362.707458,412.204315 366.256287,412.499207 
	C366.907928,412.542908 367.409760,412.429352 368.197144,412.226807 
	C369.657532,412.001801 370.832336,411.865753 372.393433,411.850372 
	C373.845917,411.890228 374.912079,411.809418 376.368225,411.811218 
	C377.813080,411.994812 378.867920,412.095886 379.924347,412.490784 
	C379.930054,413.175781 379.934204,413.566956 379.938324,413.958130 
	C368.730896,414.015991 368.840668,413.983734 372.012512,424.779724 
	C373.826447,430.953888 375.214050,437.253265 376.690857,443.902863 
	C373.897705,435.992706 371.207306,427.675995 368.365723,418.687683 
	C367.790314,416.143738 368.544342,412.427246 364.530334,415.860046 
	C363.994293,416.031006 363.756378,416.231049 363.570465,416.498840 
	C363.556152,416.487762 363.529755,416.462341 363.529755,416.462341 
	C360.312988,417.359833 357.096252,418.257355 353.483765,419.091278 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M364.762207,415.810333 
	C368.544342,412.427246 367.790314,416.143738 368.176392,418.353638 
	C366.717773,419.212036 365.297241,419.732880 363.504089,420.284851 
	C363.325867,419.287262 363.520294,418.258545 363.622253,416.846100 
	C363.529755,416.462341 363.556152,416.487762 363.812012,416.601074 
	C364.299347,416.413055 364.530792,416.111694 364.762207,415.810333 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M360.229065,421.685059 
	C360.585052,421.209076 361.242279,420.765015 362.219971,420.426483 
	C361.870392,420.927002 361.200348,421.321960 360.229065,421.685059 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M358.243866,422.356873 
	C358.239197,422.144348 358.425781,422.051514 358.794342,422.000366 
	C358.866516,422.256805 358.686127,422.401642 358.243866,422.356873 
z"/>
<path fill="#20B4DE" opacity="1.000000" stroke="none" 
	d="
M379.922791,412.196930 
	C378.867920,412.095886 377.813080,411.994812 376.131409,411.608276 
	C374.338776,411.458405 373.172974,411.594086 372.007172,411.729736 
	C370.832336,411.865753 369.657532,412.001801 368.265869,412.111877 
	C368.049072,412.085907 368.051422,411.818054 367.873993,411.642578 
	C367.115265,411.589386 366.533966,411.711700 365.959473,411.970551 
	C365.966278,412.107117 366.106476,412.341949 366.106476,412.341949 
	C362.707458,412.204315 359.308411,412.066681 355.407471,411.505341 
	C355.091949,409.301300 355.278290,407.520905 355.618439,404.271637 
	C353.272888,405.956757 351.716278,407.075104 350.162170,407.837646 
	C349.760345,406.317505 349.458557,405.103119 348.922211,404.003113 
	C348.410706,402.954010 347.654694,402.024078 347.423279,401.023376 
	C352.857635,400.904236 357.431702,399.903137 360.407288,394.903046 
	C365.351685,390.099152 366.102844,385.478088 362.087402,380.914825 
	C359.833466,378.353424 359.203613,376.032349 360.309204,372.946381 
	C361.071503,372.264923 361.522644,371.632080 362.272095,370.964844 
	C363.060089,370.610718 363.549774,370.291046 364.385315,370.073120 
	C365.371277,371.274719 366.567322,372.380707 366.555176,373.473328 
	C366.503052,378.169708 370.296112,379.362335 373.346619,380.940369 
	C374.232117,381.398438 377.394257,379.501373 377.360168,379.398773 
	C375.308502,373.220795 381.325775,369.421906 381.856689,364.520325 
	C382.264771,369.986725 382.601990,374.998871 382.908508,380.447083 
	C382.959290,382.918213 383.040741,384.953278 383.122192,386.988373 
	C383.076935,388.864929 383.031677,390.741455 383.116638,393.207306 
	C383.023834,399.814240 382.800751,405.831909 382.577698,411.849548 
	C382.577728,411.849548 382.279877,411.762543 381.791565,411.784637 
	C380.843109,411.936798 380.382965,412.066864 379.922791,412.196930 
M375.715729,405.185883 
	C373.903198,405.288818 372.090668,405.391754 370.278137,405.494690 
	C370.167542,406.017059 370.056915,406.539429 369.946320,407.061768 
	C371.196106,407.684174 372.607239,408.987579 373.651062,408.740295 
	C374.727631,408.485291 375.446716,406.721069 375.715729,405.185883 
z"/>
<path fill="#142931" opacity="1.000000" stroke="none" 
	d="
M383.033051,411.885468 
	C382.800751,405.831909 383.023834,399.814240 383.431763,393.462616 
	C383.944153,393.166138 384.413849,393.076172 384.578522,393.259796 
	C388.793671,397.959259 392.993927,402.672546 397.135956,407.436310 
	C397.496399,407.850830 397.436890,408.630554 397.568268,409.221863 
	C392.731720,410.149170 388.110046,411.035278 383.033051,411.885468 
z"/>
<path fill="#111A1E" opacity="1.000000" stroke="none" 
	d="
M383.480774,386.970581 
	C383.040741,384.953278 382.959290,382.918213 383.163574,380.262146 
	C384.299561,379.094116 385.149780,378.547058 386.000000,378.000000 
	C385.856110,380.074097 385.871002,382.177185 385.499908,384.209778 
	C385.320465,385.192413 384.415710,386.042633 383.480774,386.970581 
z"/>
<path fill="#111A1E" opacity="1.000000" stroke="none" 
	d="
M392.162567,374.013794 
	C391.573181,375.491425 390.983826,376.969055 390.187500,378.719788 
	C388.884979,378.667633 387.789429,378.342316 386.356140,377.672119 
	C386.673035,376.225494 387.327698,375.123718 388.400848,374.016144 
	C389.933716,374.011475 391.048157,374.012634 392.162567,374.013794 
z"/>
<path fill="#102532" opacity="1.000000" stroke="none" 
	d="
M379.924347,412.490784 
	C380.382965,412.066864 380.843109,411.936798 381.658936,411.865112 
	C381.506317,412.521027 380.998016,413.118561 380.214020,413.837097 
	C379.934204,413.566956 379.930054,413.175781 379.924347,412.490784 
z"/>
<path fill="#172831" opacity="1.000000" stroke="none" 
	d="
M135.362793,423.142456 
	C135.807510,422.837494 136.623993,422.588593 137.669281,422.506531 
	C137.176910,422.848419 136.455734,423.023468 135.362793,423.142456 
z"/>
<path fill="#5BBEDD" opacity="1.000000" stroke="none" 
	d="
M140.295090,424.102722 
	C140.542191,424.276276 140.529526,424.453186 140.349228,424.836853 
	C139.823578,424.806427 139.465561,424.569305 139.107544,424.332153 
	C139.416794,424.256775 139.726044,424.181396 140.295090,424.102722 
z"/>
<path fill="#152023" opacity="1.000000" stroke="none" 
	d="
M172.573669,97.864021 
	C173.663071,90.380386 178.874283,86.618057 185.850586,84.146553 
	C191.597549,85.120567 196.178391,86.584869 197.488892,92.245529 
	C195.607269,92.281700 194.139175,92.190384 192.691040,92.320824 
	C187.049973,92.828979 182.730209,97.739418 183.805786,102.688271 
	C184.887817,105.552925 185.990158,108.048264 187.118256,110.945648 
	C188.711731,113.386353 190.279434,115.425011 191.495667,117.664734 
	C189.403000,118.260223 187.660965,118.650970 185.920792,119.049873 
	C182.822372,119.760139 181.527100,121.589851 182.105515,125.018677 
	C182.366699,127.377060 182.694656,129.563980 183.530487,131.535583 
	C184.228439,133.181961 186.134140,134.468277 186.422256,136.104675 
	C187.012573,139.457321 186.728531,142.954178 187.104080,146.358627 
	C187.280731,147.960159 188.150421,149.485260 188.851196,151.381470 
	C189.145950,152.434631 189.295547,153.151321 189.274445,154.131409 
	C189.040054,154.594116 188.976364,154.793427 188.681702,155.295776 
	C187.089508,158.076889 189.864227,162.905838 184.137909,162.551453 
	C183.777725,161.301422 183.539413,160.471970 183.506958,159.614456 
	C183.712814,159.586411 183.860641,159.974731 184.074585,159.805237 
	C183.975479,159.153732 183.662415,158.671722 183.139862,157.911407 
	C182.280930,155.795013 182.150742,153.022995 180.873978,152.313080 
	C178.208633,150.831055 174.971649,150.210876 171.892670,149.751831 
	C171.088211,149.631897 169.158325,151.424377 169.178558,152.305618 
	C169.332748,159.020966 165.169998,159.630035 159.840912,158.900543 
	C158.109650,156.418335 156.618149,154.212296 154.991074,151.684967 
	C154.910873,150.907608 154.966263,150.451553 155.224930,149.809937 
	C155.183426,148.756912 154.938675,147.889450 154.486267,146.652771 
	C154.290192,145.169128 154.301743,144.054688 154.394012,142.834259 
	C154.569931,142.454407 154.493195,142.226654 154.094177,141.809570 
	C153.866547,141.404800 153.789246,141.235397 153.833740,140.728989 
	C154.049759,139.926147 154.143997,139.460327 154.583420,138.853958 
	C155.251617,137.005493 155.877213,135.290070 155.835190,133.591171 
	C155.745285,129.956390 155.138992,126.334045 155.056091,122.699493 
	C154.929337,117.142342 157.063995,114.600098 162.869064,113.339767 
	C163.324768,115.127434 163.663116,116.563507 163.750244,118.250961 
	C162.400894,121.618240 163.944717,123.417915 166.760651,123.860901 
	C170.454788,124.442047 171.621658,121.943947 171.935211,118.424316 
	C172.794510,115.347458 174.159790,112.604843 174.153091,109.865585 
	C174.143311,105.863792 173.148575,101.864426 172.573669,97.864021 
z"/>
<path fill="#142122" opacity="1.000000" stroke="none" 
	d="
M214.834381,88.941772 
	C215.761795,88.461739 216.689224,87.981705 218.102356,87.264984 
	C219.089951,86.765900 219.591843,86.503502 220.093719,86.241104 
	C220.829498,86.136917 221.565292,86.032730 222.912964,85.842682 
	C225.395248,85.613403 227.265656,85.469994 229.511780,85.331535 
	C237.518402,85.523483 241.995316,90.280777 245.249252,96.829941 
	C248.004578,93.821930 250.271378,91.347252 252.940674,88.893219 
	C254.888687,88.620377 256.434204,88.326881 257.986572,88.423492 
	C258.663055,91.505592 259.332703,94.197571 259.697266,97.016998 
	C258.575623,97.766144 257.759064,98.387840 256.619263,99.112823 
	C255.924301,99.740784 255.552582,100.265450 255.199036,100.851364 
	C255.217224,100.912598 255.114655,100.988777 254.744843,101.077614 
	C253.932449,102.112579 252.874847,103.320274 253.147797,103.962090 
	C254.826172,107.908463 253.395676,111.066864 251.180908,114.559402 
	C251.462677,117.094376 251.652756,119.187492 251.540344,121.328033 
	C251.517761,122.226730 251.797684,123.078011 252.107697,123.946136 
	C252.137802,123.962975 252.169571,123.901764 252.111755,124.180847 
	C252.149963,124.623711 252.246002,124.787483 252.283325,125.262985 
	C252.816925,126.679131 253.409256,127.783562 253.963013,129.182953 
	C253.879395,129.671051 253.834366,129.864166 253.447357,130.043152 
	C252.259094,129.277802 251.417999,128.520630 250.564926,127.777184 
	C249.874237,127.175224 249.169174,126.589737 248.302551,125.708069 
	C248.002853,125.239647 247.871063,125.060097 247.794891,124.551682 
	C247.782043,123.787689 247.713562,123.352554 247.747482,122.571274 
	C247.834641,121.504662 247.819397,120.784203 247.891815,119.786621 
	C247.913116,119.327446 247.846771,119.145393 247.803375,118.593338 
	C247.795364,117.482895 247.764404,116.742455 247.840088,115.802399 
	C247.946732,115.602791 247.868729,115.154259 247.863129,114.745132 
	C245.391968,113.963531 242.930923,113.556877 240.459335,113.230003 
	C238.667999,112.993088 236.862686,112.861877 235.011658,112.274399 
	C234.759674,110.233612 234.559601,108.601967 234.459900,106.563919 
	C234.575058,97.966988 232.150574,95.884911 224.154297,97.386940 
	C222.396606,97.717110 220.711029,98.431145 218.831635,98.885010 
	C218.508743,99.075287 218.346451,99.348969 218.164398,99.787827 
	C218.012054,100.173355 217.820190,100.255852 217.136353,100.155663 
	C215.012970,99.971413 213.322266,99.832008 212.111618,99.732185 
	C210.555786,97.172104 217.731110,94.343147 211.181091,92.731361 
	C212.496902,91.282410 213.665649,90.112091 214.834381,88.941772 
z"/>
<path fill="#6CBED3" opacity="1.000000" stroke="none" 
	d="
M252.538177,88.872574 
	C250.271378,91.347252 248.004578,93.821930 245.249252,96.829941 
	C241.995316,90.280777 237.518402,85.523483 229.326508,85.057129 
	C228.174088,84.516327 227.582657,84.254898 226.991226,83.993469 
	C226.439453,82.537529 225.944458,81.056580 225.317703,79.633667 
	C224.826675,78.518929 224.172516,77.476059 223.590851,76.401260 
	C224.908188,76.045212 226.254425,75.768860 227.532852,75.305496 
	C228.404175,74.989677 229.180740,74.412399 230.024963,73.544914 
	C230.050247,73.137794 230.030975,72.990112 230.358551,73.014038 
	C231.124680,73.052147 231.563232,73.066330 232.248688,73.300278 
	C235.445023,73.987091 237.428864,73.029633 237.969269,69.523529 
	C238.398880,68.147186 238.766632,67.098854 239.394913,65.988266 
	C239.768845,65.269798 239.882278,64.613586 240.020538,63.528557 
	C240.070007,62.237617 240.094650,61.375484 240.493866,60.536129 
	C241.521851,60.905579 242.175278,61.252254 242.837082,61.952534 
	C245.832626,74.051636 248.811859,75.572548 260.820496,70.473755 
	C262.110901,71.048737 263.073578,71.573387 264.170654,72.317383 
	C264.773621,72.694031 265.242188,72.851318 266.097290,73.069366 
	C266.737549,73.190598 266.991272,73.251076 267.316589,73.479172 
	C267.568817,73.815834 267.771027,73.953064 267.994843,74.058472 
	C267.139832,74.966568 266.450409,76.265526 265.399353,76.710777 
	C262.007324,78.147736 260.769592,80.671509 260.607239,84.725235 
	C257.705963,86.499123 255.122070,87.685852 252.538177,88.872574 
z"/>
<path fill="#111A1E" opacity="1.000000" stroke="none" 
	d="
M211.034012,93.010002 
	C217.731110,94.343147 210.555786,97.172104 212.111618,99.732185 
	C213.322266,99.832008 215.012970,99.971413 216.977753,100.496017 
	C217.229462,103.227844 217.207062,105.574463 217.080246,108.189056 
	C216.927719,108.642624 216.879578,108.828217 216.720795,109.131470 
	C216.513229,109.600052 216.643097,109.850655 217.116928,110.415527 
	C217.947632,115.940857 214.365585,115.796242 211.054626,115.576691 
	C213.267929,109.872932 212.341125,107.541931 207.029724,104.674911 
	C206.282364,102.982010 205.553070,101.641945 204.615433,100.184845 
	C204.407089,100.067795 203.964203,99.888145 203.891479,99.568878 
	C201.849716,96.906387 199.880661,94.563156 197.911606,92.219925 
	C196.178391,86.584869 191.597549,85.120567 186.216980,84.150352 
	C194.809616,80.693558 201.616028,84.027977 207.732422,91.199356 
	C209.068298,92.162895 210.051163,92.586449 211.034012,93.010002 
z"/>
<path fill="#12242D" opacity="1.000000" stroke="none" 
	d="
M242.828690,61.598930 
	C242.175278,61.252254 241.521851,60.905579 240.190857,60.316406 
	C235.832001,59.941021 232.150757,59.808132 228.469498,59.675243 
	C228.624771,62.478886 228.780029,65.282524 228.875275,68.383011 
	C228.852142,69.079697 228.889023,69.479538 228.660767,70.028641 
	C227.907593,70.541710 227.419540,70.905518 226.931519,71.269333 
	C225.693359,68.085495 224.455215,64.901657 223.115997,61.356075 
	C222.746918,59.321209 222.478943,57.648087 222.347107,55.628998 
	C228.014465,55.605251 233.545685,55.927467 239.985657,56.242596 
	C246.592926,56.532997 252.291428,56.830486 258.248505,57.340614 
	C259.700287,58.074097 260.893494,58.594944 261.736267,59.335434 
	C258.808929,59.814465 256.232056,60.073849 253.282135,60.279755 
	C249.548981,60.683826 246.188843,61.141380 242.828690,61.598930 
z"/>
<path fill="#E3B424" opacity="1.000000" stroke="none" 
	d="
M199.002777,61.477921 
	C200.327042,59.988869 201.528976,58.818089 202.863831,57.352127 
	C203.623398,57.136974 204.250031,57.217003 205.351379,57.638817 
	C208.181686,59.054520 210.537277,60.128441 212.892853,61.202354 
	C212.881531,61.602692 212.870209,62.003029 212.939453,62.693531 
	C213.020004,62.983696 212.740082,63.178860 212.390289,63.522686 
	C211.511719,66.273354 210.982956,68.680199 210.454193,71.087044 
	C210.095551,71.585747 209.736908,72.084457 208.854523,72.787460 
	C207.186676,73.107796 206.042572,73.223839 204.898483,73.339890 
	C203.798141,73.276703 202.697815,73.213509 201.116516,72.801468 
	C199.818588,70.204086 199.001617,67.955559 198.184647,65.707039 
	C198.416595,64.403427 198.648529,63.099808 199.002777,61.477921 
z"/>
<path fill="#578FA1" opacity="1.000000" stroke="none" 
	d="
M172.289856,97.993378 
	C173.148575,101.864426 174.143311,105.863792 174.153091,109.865585 
	C174.159790,112.604843 172.794510,115.347458 171.590347,118.535172 
	C168.759247,118.654381 166.380356,118.326981 164.001480,117.999588 
	C163.663116,116.563507 163.324768,115.127434 162.961823,112.887314 
	C162.947678,108.389130 162.958145,104.695000 163.016663,100.651047 
	C163.138458,99.837952 163.212189,99.374687 163.285919,98.911430 
	C163.285934,98.911430 163.707687,98.895401 163.915619,99.191391 
	C164.151413,99.653221 164.179276,99.819061 164.207153,99.984909 
	C164.218674,100.414490 164.230194,100.844070 164.328766,101.981544 
	C165.832153,110.697845 165.914871,110.774117 171.558701,109.166321 
	C171.380005,106.461823 171.199692,103.732979 171.019379,101.004143 
	C171.348267,100.043671 171.677139,99.083206 172.289856,97.993378 
z"/>
<path fill="#5BBAD7" opacity="1.000000" stroke="none" 
	d="
M171.816803,81.589348 
	C171.022141,85.665939 170.227493,89.742538 169.371948,94.131485 
	C159.616348,88.953423 159.078842,87.871010 162.244720,80.361053 
	C165.749008,80.680298 168.782898,81.134819 171.816803,81.589348 
z"/>
<path fill="#6FCAE3" opacity="1.000000" stroke="none" 
	d="
M144.036011,80.952972 
	C147.353256,78.694687 150.777344,76.387688 154.525650,74.894554 
	C156.207108,74.224747 158.537552,75.184128 160.784332,76.070450 
	C161.140625,77.447685 161.286087,78.158676 161.431549,78.869675 
	C159.713013,79.342552 157.580353,79.330040 156.352295,80.377800 
	C152.406586,83.744164 148.545364,84.340248 144.036011,80.952972 
z"/>
<path fill="#67B8CC" opacity="1.000000" stroke="none" 
	d="
M170.629379,101.003555 
	C171.199692,103.732979 171.380005,106.461823 171.558701,109.166321 
	C165.914871,110.774117 165.832153,110.697845 164.367584,102.301712 
	C166.292709,101.610321 168.266052,101.306648 170.629379,101.003555 
z"/>
<path fill="#274248" opacity="1.000000" stroke="none" 
	d="
M161.606430,78.970551 
	C161.286087,78.158676 161.140625,77.447685 161.006561,76.374237 
	C161.485458,75.494522 161.952972,74.977257 162.733093,74.629143 
	C165.695404,76.010162 168.345062,77.222031 170.994736,78.433899 
	C171.347336,78.978447 171.699951,79.522995 172.323425,80.269279 
	C172.481827,80.763367 172.302780,81.006729 171.936981,81.395233 
	C168.782898,81.134819 165.749008,80.680298 162.398056,80.013367 
	C162.087158,79.514214 161.987259,79.271042 161.606430,78.970551 
z"/>
<path fill="#5FBBD8" opacity="1.000000" stroke="none" 
	d="
M178.210419,65.989258 
	C177.585159,64.237366 177.386353,62.490795 177.182068,60.368759 
	C178.515900,59.995045 179.855194,59.996796 181.786621,60.218910 
	C182.919159,60.625736 183.459579,60.812202 184.000000,60.998665 
	C184.449463,65.065575 182.339966,66.385452 178.210419,65.989258 
z"/>
<path fill="#112026" opacity="1.000000" stroke="none" 
	d="
M163.166382,98.565720 
	C163.212189,99.374687 163.138458,99.837952 162.775482,100.645477 
	C162.486267,100.989746 162.003922,100.978821 162.003922,100.978821 
	C160.043655,97.604263 158.083374,94.229713 156.060486,90.525696 
	C158.347534,92.870827 160.697189,95.545418 163.166382,98.565720 
z"/>
<path fill="#73B6B6" opacity="1.000000" stroke="none" 
	d="
M198.113129,66.144173 
	C199.001617,67.955559 199.818588,70.204086 200.784775,72.815361 
	C200.110474,72.584686 199.286942,71.991264 198.256927,71.189751 
	C198.047501,69.514877 198.044556,68.048088 198.113129,66.144173 
z"/>
<path fill="#73B6B6" opacity="1.000000" stroke="none" 
	d="
M210.766113,70.935234 
	C210.982956,68.680199 211.511719,66.273354 212.365570,63.691505 
	C212.153107,65.938812 211.615555,68.361115 210.766113,70.935234 
z"/>
<path fill="#60B8D1" opacity="1.000000" stroke="none" 
	d="
M211.181091,92.731361 
	C210.051163,92.586449 209.068298,92.162895 208.006378,91.385559 
	C209.986542,90.354462 212.045792,89.677139 214.469711,88.970795 
	C213.665649,90.112091 212.496902,91.282410 211.181091,92.731361 
z"/>
<path fill="#60B8D1" opacity="1.000000" stroke="none" 
	d="
M226.643433,83.996284 
	C227.582657,84.254898 228.174088,84.516327 228.950775,85.052170 
	C227.265656,85.469994 225.395248,85.613403 223.253510,85.689499 
	C224.086655,85.081154 225.191147,84.540131 226.643433,83.996284 
z"/>
<path fill="#1685C9" opacity="1.000000" stroke="none" 
	d="
M226.903687,71.552399 
	C227.419540,70.905518 227.907593,70.541710 228.918945,70.260132 
	C229.638504,71.224953 229.834732,72.107536 230.030975,72.990112 
	C230.030975,72.990112 230.050247,73.137794 230.061874,73.213669 
	C229.007614,72.804848 227.941742,72.320152 226.903687,71.552399 
z"/>
<path fill="#60B8D1" opacity="1.000000" stroke="none" 
	d="
M221.785645,72.439926 
	C221.983459,71.663322 222.394608,71.086067 222.805756,70.508812 
	C223.110489,70.830841 223.415207,71.152870 223.719940,71.474899 
	C223.146301,71.863022 222.572647,72.251144 221.785645,72.439926 
z"/>
<path fill="#73B6B6" opacity="1.000000" stroke="none" 
	d="
M205.060226,73.675911 
	C206.042572,73.223839 207.186676,73.107796 208.681076,73.041115 
	C207.761581,73.397636 206.491776,73.704781 205.060226,73.675911 
z"/>
<path fill="#5FBFDF" opacity="1.000000" stroke="none" 
	d="
M183.990631,60.683556 
	C183.459579,60.812202 182.919159,60.625736 182.189362,60.218430 
	C182.314987,59.531349 182.629974,59.065121 182.944962,58.598896 
	C183.290390,59.188747 183.635834,59.778599 183.990631,60.683556 
z"/>
<path fill="#60B8D1" opacity="1.000000" stroke="none" 
	d="
M219.924561,86.015396 
	C219.591843,86.503502 219.089951,86.765900 218.298920,87.021408 
	C218.591629,86.606239 219.173508,86.197968 219.924561,86.015396 
z"/>
<path fill="#5FBBD8" opacity="1.000000" stroke="none" 
	d="
M177.781418,70.126312 
	C177.523178,69.740036 177.327103,68.798218 177.447632,67.597496 
	C177.790665,68.082649 177.817123,68.826714 177.781418,70.126312 
z"/>
<path fill="#52A7C1" opacity="1.000000" stroke="none" 
	d="
M137.109985,83.082489 
	C137.009354,82.930977 137.453735,82.702347 137.701752,82.731476 
	C137.703384,82.918396 137.457001,83.076195 137.109985,83.082489 
z"/>
<path fill="#67B8CC" opacity="1.000000" stroke="none" 
	d="
M164.306534,99.869812 
	C164.179276,99.819061 164.151413,99.653221 164.122040,99.235214 
	C164.418732,99.178421 164.513855,99.435646 164.306534,99.869812 
z"/>
<path fill="#111B1F" opacity="1.000000" stroke="none" 
	d="
M260.002380,96.889549 
	C259.332703,94.197571 258.663055,91.505592 258.343597,88.257507 
	C265.761688,86.293663 271.904755,89.027145 278.041260,92.109161 
	C278.090637,92.207199 278.359222,92.218941 278.359222,92.218941 
	C278.359222,92.218941 278.010742,91.992027 278.120270,91.747551 
	C278.496063,91.038315 278.762329,90.573563 279.028595,90.108795 
	C279.028595,90.108795 279.001221,90.044746 279.375244,89.942001 
	C280.749237,87.648544 281.749207,85.457832 282.749176,83.267120 
	C282.981934,82.879883 283.214722,82.492653 283.987030,81.984146 
	C284.890778,81.397690 285.254974,80.932510 285.619171,80.467331 
	C285.619171,80.467323 285.753204,80.762604 286.033295,80.722748 
	C287.162231,79.939514 288.011047,79.196136 288.859894,78.452766 
	C290.079407,77.839752 291.298889,77.226738 293.095947,76.407333 
	C294.046692,75.926994 294.419861,75.653061 294.793060,75.379120 
	C295.665070,75.424232 296.537109,75.469345 298.120605,75.512024 
	C300.512268,75.535393 302.192413,75.561203 303.872589,75.587021 
	C311.345032,77.670837 317.936584,80.771400 319.973389,89.667145 
	C320.552002,92.716820 320.929108,95.461143 321.364471,98.861984 
	C321.555542,100.032425 321.688354,100.546349 321.821167,101.060280 
	C321.806213,102.429237 321.791260,103.798187 321.393555,105.439606 
	C319.618866,104.515251 318.226929,103.318436 316.834991,102.121628 
	C316.220886,103.417053 315.606781,104.712479 314.656128,106.004837 
	C313.870667,106.002586 313.421722,106.003403 312.862366,105.574402 
	C312.653717,103.100243 312.555481,101.055893 312.550354,98.615524 
	C311.720520,95.074371 311.578613,91.318787 309.702698,88.918365 
	C306.375000,84.660248 292.205841,85.002991 288.533508,89.054169 
	C287.505951,90.187721 287.532593,92.276878 286.926697,94.193939 
	C288.731415,94.893776 290.685150,95.327362 292.880249,95.836906 
	C293.233185,96.359993 293.344696,96.807114 293.296082,97.636948 
	C291.867645,100.099884 290.949921,101.784363 292.408112,104.625107 
	C294.589233,108.874153 292.924103,112.384987 288.954956,113.795029 
	C288.196106,113.027946 287.590240,112.522705 286.721252,111.758911 
	C283.283356,111.009293 280.108582,110.518242 276.064850,109.892776 
	C276.259369,108.375992 276.352448,106.352310 276.821136,104.419701 
	C277.174896,102.960930 278.009094,101.618668 278.629456,100.224541 
	C276.964355,99.859322 275.299255,99.494095 273.433777,99.077278 
	C272.980499,98.853424 272.865234,98.614708 272.847076,97.937729 
	C270.376495,95.200081 267.910004,93.294678 264.597290,97.078072 
	C262.794617,97.101372 261.398499,96.995461 260.002380,96.889549 
z"/>
<path fill="#172D33" opacity="1.000000" stroke="none" 
	d="
M262.086700,59.115791 
	C260.893494,58.594944 259.700287,58.074097 258.611450,57.202309 
	C260.693329,56.364712 262.670868,55.878059 264.648407,55.391411 
	C264.648407,55.391411 264.741669,55.732910 265.338593,55.767319 
	C267.836273,55.258160 269.737061,54.714592 271.637817,54.171024 
	C272.536163,54.235966 273.434509,54.300911 274.920227,54.310051 
	C275.675415,54.189110 275.843201,54.123978 276.010956,54.058846 
	C280.522919,53.401329 285.034851,52.743809 289.860535,52.301735 
	C289.893768,56.799812 289.613312,61.082447 288.931458,65.460999 
	C287.359161,63.011818 286.711273,58.911968 284.890289,58.299385 
	C282.388153,57.457664 278.997650,59.256721 275.751221,59.925232 
	C275.514618,59.926201 275.073212,60.096436 274.721375,59.967987 
	C272.934692,59.933441 271.499847,60.027348 269.660461,59.884560 
	C266.866180,59.470509 264.476440,59.293152 262.086700,59.115791 
z"/>
<path fill="#EAB923" opacity="1.000000" stroke="none" 
	d="
M261.736267,59.335434 
	C264.476440,59.293152 266.866180,59.470509 269.514618,60.313873 
	C269.529877,63.378071 269.286438,65.776268 268.869934,68.282722 
	C268.753204,68.853683 268.809540,69.316383 268.865875,69.779083 
	C268.787628,70.259148 268.709412,70.739212 268.357971,71.641785 
	C267.804871,72.480042 267.524933,72.895798 267.244995,73.311554 
	C266.991272,73.251076 266.737549,73.190598 265.994965,72.826187 
	C265.016174,72.380852 264.526184,72.239441 264.036224,72.098030 
	C263.073578,71.573387 262.110901,71.048737 260.619995,70.236816 
	C258.545197,69.793625 256.998688,69.637718 255.050262,69.441299 
	C254.580460,66.374130 254.117798,63.353680 253.655151,60.333229 
	C256.232056,60.073849 258.808929,59.814465 261.736267,59.335434 
z"/>
<path fill="#0D6EA4" opacity="1.000000" stroke="none" 
	d="
M275.987793,59.924263 
	C278.997650,59.256721 282.388153,57.457664 284.890289,58.299385 
	C286.711273,58.911968 287.359161,63.011818 288.806885,65.816467 
	C288.764343,67.159454 288.444977,68.242889 287.726379,69.631737 
	C286.877655,69.890343 286.428162,69.843544 285.828003,69.483170 
	C284.736206,65.001007 282.086761,63.393486 277.767059,63.855057 
	C277.362122,63.451527 277.193024,63.176147 277.021912,62.560181 
	C276.640747,61.476048 276.314270,60.700153 275.987793,59.924263 
z"/>
<path fill="#66BED5" opacity="1.000000" stroke="none" 
	d="
M277.076569,62.868423 
	C277.193024,63.176147 277.362122,63.451527 277.815308,64.222572 
	C279.609375,67.240761 279.185516,71.620712 284.004364,71.013412 
	C283.451416,74.264526 281.171295,74.254509 278.735596,73.696091 
	C276.583099,73.202606 274.473846,72.520470 272.347382,71.922005 
	C272.720032,70.104057 273.090820,68.285736 273.282715,65.892090 
	C273.824768,64.505463 274.545654,63.694164 275.567139,62.885521 
	C276.270691,62.881596 276.673615,62.875011 277.076569,62.868423 
z"/>
<path fill="#111A1E" opacity="1.000000" stroke="none" 
	d="
M277.991882,92.011124 
	C271.904755,89.027145 265.761688,86.293663 258.336761,87.867393 
	C256.434204,88.326881 254.888687,88.620377 252.940674,88.893227 
	C255.122070,87.685852 257.705963,86.499123 260.576935,85.179688 
	C261.492249,85.064774 262.120483,85.082565 263.268463,85.160805 
	C264.552673,85.260330 265.317108,85.299423 266.081573,85.338516 
	C270.147797,86.978020 274.214050,88.617516 278.654419,90.182907 
	C278.762329,90.573563 278.496063,91.038315 278.114899,91.751602 
	C278.000000,92.000122 277.991882,92.011124 277.991882,92.011124 
z"/>
<path fill="#1799C0" opacity="1.000000" stroke="none" 
	d="
M284.330872,70.982414 
	C279.185516,71.620712 279.609375,67.240761 277.998413,64.383072 
	C282.086761,63.393486 284.736206,65.001007 285.828827,69.777054 
	C285.976379,70.579430 285.972473,70.774361 285.968567,70.969292 
	C285.531525,70.963333 285.094452,70.957375 284.330872,70.982414 
z"/>
<path fill="#6CBED3" opacity="1.000000" stroke="none" 
	d="
M275.266541,62.882866 
	C274.545654,63.694164 273.824768,64.505463 273.052368,65.652084 
	C272.174927,66.645576 271.349060,67.303726 269.995972,68.031509 
	C269.468750,68.101143 269.042999,68.174461 269.043030,68.174461 
	C269.286438,65.776268 269.529877,63.378071 269.919128,60.550564 
	C271.499847,60.027348 272.934692,59.933441 274.695923,60.317371 
	C275.103699,61.491096 275.185120,62.186981 275.266541,62.882866 
z"/>
<path fill="#111A1E" opacity="1.000000" stroke="none" 
	d="
M282.449280,83.466019 
	C281.749207,85.457832 280.749237,87.648544 279.409271,89.934601 
	C280.095978,87.908264 281.122681,85.786598 282.449280,83.466019 
z"/>
<path fill="#66BED5" opacity="1.000000" stroke="none" 
	d="
M303.688232,75.234161 
	C302.192413,75.561203 300.512268,75.535393 298.431885,75.379242 
	C298.023529,75.040283 298.015381,74.831665 298.004242,74.309845 
	C299.835419,74.291534 301.669647,74.586426 303.688232,75.234161 
z"/>
<path fill="#66BED5" opacity="1.000000" stroke="none" 
	d="
M296.680054,66.077347 
	C296.993225,66.754311 296.993805,67.500237 296.993530,68.619034 
	C296.784271,68.043365 296.575836,67.094849 296.680054,66.077347 
z"/>
<path fill="#111A1E" opacity="1.000000" stroke="none" 
	d="
M288.533600,78.520424 
	C288.011047,79.196136 287.162231,79.939514 285.941345,80.589188 
	C286.448669,79.859695 287.327972,79.223885 288.533600,78.520424 
z"/>
<path fill="#739896" opacity="1.000000" stroke="none" 
	d="
M267.316589,73.479172 
	C267.524933,72.895798 267.804871,72.480042 268.331696,72.005920 
	C268.518524,72.502060 268.458405,73.056564 268.196594,73.834778 
	C267.771027,73.953064 267.568817,73.815834 267.316589,73.479172 
z"/>
<path fill="#66BED5" opacity="1.000000" stroke="none" 
	d="
M286.157043,71.165367 
	C285.972473,70.774361 285.976379,70.579430 285.979431,70.090622 
	C286.428162,69.843544 286.877655,69.890343 287.664703,69.968979 
	C287.449982,70.454353 286.897736,70.907898 286.157043,71.165367 
z"/>
<path fill="#739896" opacity="1.000000" stroke="none" 
	d="
M268.869934,68.282722 
	C269.042999,68.174461 269.468750,68.101143 269.683594,68.106491 
	C269.713287,68.596710 269.528168,69.081596 269.104431,69.672775 
	C268.809540,69.316383 268.753204,68.853683 268.869934,68.282722 
z"/>
<path fill="#111A1E" opacity="1.000000" stroke="none" 
	d="
M285.335754,80.561935 
	C285.254974,80.932510 284.890778,81.397690 284.208313,81.911041 
	C284.277466,81.524986 284.664917,81.090759 285.335754,80.561935 
z"/>
<path fill="#6CBED3" opacity="1.000000" stroke="none" 
	d="
M265.813049,85.032654 
	C265.317108,85.299423 264.552673,85.260330 263.514160,84.954330 
	C264.008270,84.700539 264.776428,84.713669 265.813049,85.032654 
z"/>
<path fill="#66BED5" opacity="1.000000" stroke="none" 
	d="
M294.437225,75.385368 
	C294.419861,75.653061 294.046692,75.926994 293.314087,76.204102 
	C293.330231,75.935394 293.705811,75.663506 294.437225,75.385368 
z"/>
<path fill="#E7B62A" opacity="1.000000" stroke="none" 
	d="
M304.452576,59.383247 
	C309.878815,52.864243 314.960541,57.679176 320.438354,59.875916 
	C320.488098,60.778557 320.246216,61.494503 319.572937,62.549213 
	C318.441864,64.926331 317.742126,66.964676 317.042419,69.003021 
	C315.908447,69.379967 314.774506,69.756912 313.260254,69.733871 
	C310.553925,69.437752 308.227875,69.541618 305.901794,69.645493 
	C305.895325,67.660797 305.888855,65.676109 305.783325,63.098713 
	C305.279266,61.941639 304.874329,61.377262 304.469421,60.812881 
	C304.486176,60.430000 304.502960,60.047119 304.452576,59.383247 
z"/>
<path fill="#091318" opacity="1.000000" stroke="none" 
	d="
M337.864807,65.011810 
	C336.531464,68.738281 335.198090,72.464752 333.906586,76.599678 
	C332.830109,76.417595 331.711823,75.827049 330.296143,75.113403 
	C331.476257,71.110840 332.953796,67.231377 334.864380,63.282780 
	C336.153229,63.813038 337.009033,64.412422 337.864807,65.011810 
z"/>
<path fill="#2D5662" opacity="1.000000" stroke="none" 
	d="
M338.142853,64.858589 
	C337.009033,64.412422 336.153229,63.813038 335.055786,62.989334 
	C334.840820,62.070709 334.867493,61.376400 334.889709,60.331146 
	C335.230774,59.517448 335.576294,59.054695 335.964996,58.296864 
	C337.552490,57.236660 339.096741,56.471535 341.886475,55.089344 
	C341.057068,58.056561 340.600555,59.689724 340.153015,61.676468 
	C339.581635,62.921825 339.001251,63.813595 338.142853,64.858589 
z"/>
<path fill="#66BED5" opacity="1.000000" stroke="none" 
	d="
M317.384674,68.913147 
	C317.742126,66.964676 318.441864,64.926331 319.516846,62.828503 
	C319.170349,64.787102 318.448639,66.805183 317.384674,68.913147 
z"/>
<path fill="#66BED5" opacity="1.000000" stroke="none" 
	d="
M306.133392,69.957214 
	C308.227875,69.541618 310.553925,69.437752 312.993774,69.658661 
	C310.860046,70.078613 308.612518,70.173775 306.133392,69.957214 
z"/>
<path fill="#66BED5" opacity="1.000000" stroke="none" 
	d="
M304.211334,60.969452 
	C304.874329,61.377262 305.279266,61.941639 305.649353,62.771351 
	C305.060760,62.399803 304.507019,61.762913 304.211334,60.969452 
z"/>
<path fill="#66BED5" opacity="1.000000" stroke="none" 
	d="
M300.956299,67.489014 
	C300.828888,67.168594 300.901306,66.851707 300.973724,66.534828 
	C301.034515,66.854065 301.095306,67.173302 300.956299,67.489014 
z"/>
<path fill="#77C4DB" opacity="1.000000" stroke="none" 
	d="
M264.313660,55.183178 
	C262.670868,55.878059 260.693329,56.364712 258.352844,56.989670 
	C252.291428,56.830486 246.592926,56.532997 240.446930,56.150780 
	C240.359222,52.571594 240.345352,49.192913 243.745560,46.606586 
	C249.107758,42.527893 253.568665,42.670033 257.902313,47.845219 
	C259.907043,50.239227 261.952148,52.599422 264.313660,55.183178 
z"/>
<path fill="#77C4DB" opacity="1.000000" stroke="none" 
	d="
M271.159393,54.124161 
	C269.737061,54.714592 267.836273,55.258160 265.514832,55.750923 
	C265.073395,55.418728 265.052612,55.137333 265.017120,54.433075 
	C266.895294,54.032574 268.788116,54.054935 271.159393,54.124161 
z"/>
<path fill="#6AC2DB" opacity="1.000000" stroke="none" 
	d="
M275.908325,53.912029 
	C275.843201,54.123978 275.675415,54.189110 275.246887,54.183998 
	C275.259338,53.997570 275.532501,53.881393 275.908325,53.912029 
z"/>
<path fill="#73B6B6" opacity="1.000000" stroke="none" 
	d="
M212.878372,60.855888 
	C210.537277,60.128441 208.181686,59.054520 205.666687,57.658768 
	C206.057892,57.252247 206.608521,57.167561 207.577423,57.087467 
	C209.618423,58.231182 211.241150,59.370300 212.878372,60.855888 
z"/>
<path fill="#17ABD3" opacity="1.000000" stroke="none" 
	d="
M178.018478,413.140564 
	C177.566071,413.348083 177.113663,413.555634 176.321777,413.924347 
	C175.226227,414.015900 174.470169,413.946320 173.242645,413.683258 
	C172.458923,413.359833 172.208237,413.441925 172.019104,413.736053 
	C170.619370,413.807159 169.219650,413.878265 167.301971,413.757904 
	C166.457291,413.422852 166.197784,413.509827 166.005524,413.827423 
	C164.295547,413.973083 162.585571,414.118713 160.141235,414.267303 
	C158.946320,414.205750 158.485764,414.141266 157.770874,413.826294 
	C155.673096,413.805420 153.829681,414.035004 151.639084,414.425049 
	C150.842850,414.822510 150.393814,415.059509 149.944763,415.296509 
	C149.783966,415.247223 149.623169,415.197906 149.122940,414.922302 
	C148.524704,414.826508 148.265869,414.956970 148.007034,415.087402 
	C146.604141,415.158081 145.201263,415.228729 143.151062,415.261505 
	C142.338120,415.253357 142.172455,415.283112 142.006775,415.312897 
	C140.278503,415.195587 138.550217,415.078247 136.180786,414.688721 
	C134.693771,414.596405 133.847916,414.776245 133.002045,414.956116 
	C131.835617,415.071899 130.669174,415.187714 129.142075,414.904724 
	C128.522141,413.997162 128.262878,413.488434 128.058228,412.620972 
	C130.058365,406.944611 131.431030,406.387238 136.412582,408.499786 
	C137.757767,409.070282 139.350159,409.057861 140.829071,409.312988 
	C140.932953,407.799561 141.286255,406.249146 141.069077,404.783295 
	C140.860809,403.377563 139.992020,402.082184 139.535568,400.698059 
	C138.686859,398.124481 138.455780,395.746979 142.036163,395.238281 
	C142.361954,395.527985 142.607986,395.588226 142.853226,395.585175 
	C142.895599,395.584686 142.969330,395.315918 142.970108,395.170746 
	C142.984741,392.447998 142.990540,389.725220 143.209686,386.997528 
	C143.421219,386.992615 143.843857,386.972046 143.934509,387.426514 
	C144.093872,390.020325 144.346207,392.172882 144.193420,394.296295 
	C143.867157,398.830597 145.546326,401.090637 150.374191,400.852905 
	C155.139877,400.618225 159.926743,400.646484 164.699005,400.767975 
	C168.173294,400.856415 170.318359,399.362183 171.567062,396.405457 
	C173.299591,397.640625 174.737427,398.948944 176.422501,399.496796 
	C178.854050,400.287262 181.464996,400.525909 183.766296,401.262360 
	C180.875229,401.960663 178.217270,402.396576 174.757523,402.964020 
	C177.300720,404.574493 179.161880,405.753082 180.671112,406.957153 
	C177.623337,407.203766 176.646988,408.420410 178.002960,411.256805 
	C178.009338,412.065033 178.013901,412.602783 178.018478,413.140564 
M163.102219,407.398651 
	C161.775452,408.481812 160.448685,409.564941 159.121902,410.648071 
	C159.708313,411.183746 160.294724,411.719391 160.881134,412.255066 
	C161.780396,410.795288 162.679672,409.335510 163.102219,407.398651 
M169.899750,408.769440 
	C170.698410,409.034241 171.497070,409.299042 172.295746,409.563812 
	C172.353989,409.048157 172.412231,408.532471 172.470474,408.016785 
	C171.502762,408.103394 170.535049,408.190002 169.899750,408.769440 
z"/>
<path fill="#21AFD6" opacity="1.000000" stroke="none" 
	d="
M141.956146,394.987396 
	C138.455780,395.746979 138.686859,398.124481 139.535568,400.698059 
	C139.992020,402.082184 140.860809,403.377563 141.069077,404.783295 
	C141.286255,406.249146 140.932953,407.799561 140.829071,409.312988 
	C139.350159,409.057861 137.757767,409.070282 136.412582,408.499786 
	C131.431030,406.387238 130.058365,406.944611 127.822212,412.623779 
	C127.531586,412.985382 127.060074,413.004578 127.060074,413.004578 
	C126.755966,410.600342 126.451866,408.196075 126.164833,404.999512 
	C126.241936,403.111420 126.301956,402.015625 126.523407,400.709351 
	C126.556702,399.685699 126.428566,398.872528 126.229767,397.604797 
	C126.215698,394.090607 126.272301,391.031036 126.432610,387.875671 
	C126.673164,387.493408 126.616295,387.246643 126.306946,386.656921 
	C126.276367,385.501953 126.304535,384.729736 126.495071,383.822388 
	C126.843513,383.470093 126.987785,383.228027 127.497131,382.969727 
	C132.673325,381.901703 137.468781,380.648560 139.747406,387.037170 
	C139.931641,387.553680 141.182587,387.689728 141.954269,388.439148 
	C141.965836,390.912903 141.960999,392.950165 141.956146,394.987396 
z"/>
<path fill="#163643" opacity="1.000000" stroke="none" 
	d="
M126.332703,383.957520 
	C126.304535,384.729736 126.276367,385.501953 126.236534,386.887268 
	C126.224869,387.500366 126.328918,387.971436 126.328918,387.971436 
	C126.272301,391.031036 126.215698,394.090607 126.129730,397.957092 
	C126.187569,399.482574 126.274780,400.201202 126.361984,400.919830 
	C126.301956,402.015625 126.241936,403.111420 126.084030,404.608154 
	C125.673019,405.752594 125.165138,406.473755 125.076851,407.243042 
	C124.195259,414.925262 122.341255,415.842621 113.753693,411.887756 
	C115.993332,407.206970 117.904434,402.331360 120.559868,397.901947 
	C123.422989,393.126129 125.630219,388.337372 125.616402,382.635559 
	C126.061348,383.061493 126.197029,383.509521 126.332703,383.957520 
z"/>
<path fill="#111A1E" opacity="1.000000" stroke="none" 
	d="
M111.704185,363.792236 
	C111.822983,362.895660 111.889626,362.356140 111.971207,361.408997 
	C115.969490,358.831940 117.799545,361.085999 117.841446,364.552521 
	C117.882156,367.920532 116.817917,371.301910 115.925049,374.942932 
	C114.291496,371.522064 112.971764,367.835663 111.704185,363.792236 
z"/>
<path fill="#21AFD6" opacity="1.000000" stroke="none" 
	d="
M126.058838,381.003296 
	C126.037529,374.288116 126.016220,367.572906 126.342133,360.422852 
	C126.689354,359.987946 127.075554,360.053345 127.056396,360.515869 
	C127.021324,367.650238 127.005417,374.322083 126.756798,380.992432 
	C126.524094,380.990997 126.058838,381.003296 126.058838,381.003296 
z"/>
<path fill="#172831" opacity="1.000000" stroke="none" 
	d="
M136.572571,417.168152 
	C138.257675,417.749023 139.603516,418.412109 142.015259,419.600403 
	C136.561630,420.989929 136.308487,420.823883 136.572571,417.168152 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M156.890259,416.909271 
	C156.848251,417.163300 156.806229,417.417328 156.670944,418.046265 
	C154.801071,418.119263 153.024475,417.817383 150.958038,417.681946 
	C150.546280,417.575348 150.424362,417.302277 150.513855,416.552551 
	C151.529312,415.725372 152.333374,415.374817 153.425491,414.973419 
	C154.103333,414.949890 154.493149,414.977203 154.954224,415.303406 
	C155.647079,416.037933 156.268661,416.473602 156.890259,416.909271 
z"/>
<path fill="#54C8E0" opacity="1.000000" stroke="none" 
	d="
M127.075554,360.053345 
	C127.075554,360.053345 126.689354,359.987946 126.493805,359.981384 
	C126.279022,359.284149 126.259773,358.593475 126.230476,357.556580 
	C126.626770,357.180817 127.033119,357.151245 127.737823,357.186218 
	C128.036774,357.907196 128.037369,358.563660 127.808792,359.598145 
	C127.411606,360.001862 127.243576,360.027618 127.075554,360.053345 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M127.038795,413.340942 
	C127.060074,413.004578 127.531586,412.985382 127.767609,412.982544 
	C128.262878,413.488434 128.522141,413.997162 128.879578,414.773895 
	C128.324341,414.587036 127.670929,414.132172 127.038795,413.340942 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M133.371613,415.035858 
	C133.847916,414.776245 134.693771,414.596405 135.753525,414.609985 
	C135.225327,414.907471 134.483261,415.011566 133.371613,415.035858 
z"/>
<path fill="#0D3543" opacity="1.000000" stroke="none" 
	d="
M153.137451,415.024292 
	C152.333374,415.374817 151.529312,415.725372 150.387970,416.122040 
	C150.050705,416.168152 149.968811,415.735535 149.956787,415.516022 
	C150.393814,415.059509 150.842850,414.822510 151.919678,414.626160 
	C152.744125,414.785980 152.940781,414.905121 153.137451,415.024292 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M125.947083,381.234863 
	C126.058838,381.003296 126.524094,380.990997 126.767715,381.320923 
	C127.037643,382.087585 127.063950,382.524292 127.090256,382.961029 
	C126.987785,383.228027 126.843513,383.470093 126.495071,383.822388 
	C126.197029,383.509521 126.061348,383.061493 125.800079,382.284241 
	C125.728096,381.792145 125.781708,381.629303 125.947083,381.234863 
z"/>
<path fill="#172831" opacity="1.000000" stroke="none" 
	d="
M150.564880,419.429077 
	C150.301788,420.042908 149.994949,420.487976 149.421005,421.238159 
	C149.341904,420.838104 149.529892,420.132904 150.015656,419.203979 
	C150.313416,418.980255 150.521103,419.260315 150.564880,419.429077 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M148.262024,415.144775 
	C148.265869,414.956970 148.524704,414.826508 148.885880,414.835999 
	C148.831161,415.051361 148.674088,415.126740 148.262024,415.144775 
z"/>
<path fill="#0D3543" opacity="1.000000" stroke="none" 
	d="
M142.097382,415.426453 
	C142.172455,415.283112 142.338120,415.253357 142.734589,415.325806 
	C142.965408,415.427948 142.596558,415.632263 142.596558,415.632263 
	C142.596558,415.632263 142.188004,415.539978 142.097382,415.426453 
z"/>
<path fill="#172831" opacity="1.000000" stroke="none" 
	d="
M148.853668,422.224762 
	C148.987930,422.426971 148.890198,422.866638 148.516449,423.514832 
	C148.367523,423.302979 148.494598,422.882568 148.853668,422.224762 
z"/>
<path fill="#172831" opacity="1.000000" stroke="none" 
	d="
M147.825195,425.225586 
	C147.949829,425.421936 147.845490,425.851257 147.471725,426.488098 
	C147.333603,426.283234 147.464920,425.870911 147.825195,425.225586 
z"/>
<path fill="#0D3543" opacity="1.000000" stroke="none" 
	d="
M102.971970,397.930359 
	C101.535721,398.310699 100.275536,398.541046 99.008461,398.772644 
	C98.763252,396.142212 98.543633,393.786285 97.966858,391.146606 
	C97.609703,390.862854 97.634033,390.726562 97.713425,390.322693 
	C98.725266,388.268799 99.657722,386.618805 100.590179,384.968811 
	C101.136826,386.133118 101.683487,387.297424 102.363640,388.981262 
	C102.529358,389.788666 102.680244,389.985870 102.949799,390.092377 
	C103.073723,390.826965 103.197639,391.561584 103.385376,393.031494 
	C103.526825,394.854675 103.604477,395.942535 103.682121,397.030426 
	C103.449478,397.241486 103.271446,397.491455 102.971970,397.930359 
z"/>
<path fill="#162328" opacity="1.000000" stroke="none" 
	d="
M100.614174,384.588501 
	C99.657722,386.618805 98.725266,388.268799 97.486542,390.007141 
	C93.010040,380.163696 93.010040,380.163696 99.925583,376.304749 
	C100.236786,378.442261 100.499062,380.186035 100.761337,381.929840 
	C100.720276,382.689301 100.679222,383.448761 100.614174,384.588501 
z"/>
<path fill="#0D3543" opacity="1.000000" stroke="none" 
	d="
M101.032440,381.745728 
	C100.499062,380.186035 100.236786,378.442261 99.939255,376.312744 
	C99.904007,375.927063 99.931129,375.933533 100.262627,375.973450 
	C101.038239,376.014191 101.482346,376.015045 101.926460,376.015900 
	C102.548065,376.694183 103.169662,377.372498 104.149315,378.146729 
	C103.439430,379.348999 102.371490,380.455292 101.032440,381.745728 
z"/>
<path fill="#56BBDD" opacity="1.000000" stroke="none" 
	d="
M103.930588,396.893768 
	C103.604477,395.942535 103.526825,394.854675 103.550537,393.367493 
	C103.827621,394.231201 104.003342,395.494141 103.930588,396.893768 
z"/>
<path fill="#56BBDD" opacity="1.000000" stroke="none" 
	d="
M99.706642,402.748230 
	C99.966484,402.127319 100.615982,401.537018 101.574570,400.830292 
	C101.287880,401.402191 100.692093,402.090515 99.706642,402.748230 
z"/>
<path fill="#56BBDD" opacity="1.000000" stroke="none" 
	d="
M102.941055,389.918488 
	C102.680244,389.985870 102.529358,389.788666 102.558578,389.317108 
	C102.780945,389.308105 102.885040,389.511841 102.941055,389.918488 
z"/>
<path fill="#CD9429" opacity="1.000000" stroke="none" 
	d="
M99.890335,375.919067 
	C93.010040,380.163696 93.010040,380.163696 97.407158,390.411041 
	C97.634033,390.726562 97.609703,390.862854 97.667984,390.899109 
	C97.672310,397.099304 96.701889,397.705414 91.130531,395.160828 
	C88.283623,393.860565 85.395676,392.650208 82.525917,390.940613 
	C86.076164,391.646912 89.613914,392.853271 93.182922,393.958801 
	C94.907860,394.493103 96.950180,395.107788 95.220909,391.569641 
	C94.293770,386.556671 90.909821,382.295258 93.440079,377.029297 
	C95.868721,375.081879 98.104073,373.414673 100.119774,371.514404 
	C100.520020,371.137085 100.078186,369.866455 100.355453,368.943298 
	C101.135498,368.896454 101.584763,368.914093 102.034035,368.931732 
	C102.034035,368.931732 102.251846,369.116211 102.565933,369.355896 
	C103.210533,369.807007 103.541039,370.018433 103.871536,370.229858 
	C103.871544,370.229858 103.787430,370.127289 103.711441,370.434692 
	C103.658562,371.088623 103.681679,371.435150 103.704788,371.781677 
	C103.704788,371.781677 103.730324,371.899139 103.510818,371.992645 
	C103.026642,372.562134 102.761971,373.038116 102.497299,373.514099 
	C102.497307,373.514099 102.451088,373.533966 102.154633,373.635681 
	C101.215828,374.469452 100.573471,375.201477 99.931122,375.933533 
	C99.931129,375.933533 99.904007,375.927063 99.890335,375.919067 
z"/>
<path fill="#F69907" opacity="1.000000" stroke="none" 
	d="
M95.279716,391.961914 
	C96.950180,395.107788 94.907860,394.493103 93.182922,393.958801 
	C89.613914,392.853271 86.076164,391.646912 82.282928,390.690857 
	C81.580360,390.938202 81.120438,390.976685 80.195892,390.881256 
	C79.467484,391.088104 79.203697,391.428894 78.939896,391.769653 
	C78.939896,391.769653 78.912323,391.939941 78.474365,391.941040 
	C75.747589,393.137604 73.362175,394.182495 71.197624,395.571533 
	C69.104065,396.915009 68.257607,396.394409 68.361282,393.922485 
	C71.491600,392.131653 74.638565,390.891632 77.068260,388.813110 
	C80.891220,385.542755 83.992348,386.635712 87.644768,388.989502 
	C89.894089,390.439087 92.715279,391.001312 95.279716,391.961914 
z"/>
<path fill="#CD9429" opacity="1.000000" stroke="none" 
	d="
M68.056282,394.097290 
	C68.257607,396.394409 69.104065,396.915009 71.197624,395.571533 
	C73.362175,394.182495 75.747589,393.137604 78.388329,391.934784 
	C76.986969,393.358246 75.378922,395.044983 73.438583,396.146301 
	C71.583321,397.199371 69.382439,397.643494 67.333466,398.355255 
	C67.000626,396.339752 66.667786,394.324249 66.455582,391.621826 
	C67.030472,389.273712 67.484726,387.612518 68.188911,386.226379 
	C68.311317,389.033417 68.183800,391.565338 68.056282,394.097290 
z"/>
<path fill="#162328" opacity="1.000000" stroke="none" 
	d="
M67.938995,385.951324 
	C67.484726,387.612518 67.030472,389.273712 66.340408,391.247864 
	C66.062798,389.033234 66.020981,386.505615 65.954247,383.522034 
	C65.929329,383.066040 66.091309,382.902283 66.436356,382.918091 
	C67.218521,383.285126 67.655655,383.636414 68.048477,384.313812 
	C67.982437,385.077057 67.960716,385.514191 67.938995,385.951324 
z"/>
<path fill="#CD9429" opacity="1.000000" stroke="none" 
	d="
M79.172211,391.753235 
	C79.203697,391.428894 79.467484,391.088104 79.850029,390.895691 
	C79.915703,391.384979 79.727608,391.615875 79.172211,391.753235 
z"/>
<path fill="#111A1E" opacity="1.000000" stroke="none" 
	d="
M67.380287,242.225479 
	C66.630196,240.753723 65.880104,239.281952 65.054115,237.453888 
	C66.064064,237.120178 67.149910,237.142792 68.960381,237.393280 
	C70.683792,237.644180 71.682587,237.667236 72.681374,237.690292 
	C74.799812,239.731552 76.918251,241.772812 79.186363,244.440704 
	C79.669617,245.301392 80.003197,245.535446 80.336777,245.769485 
	C80.336777,245.769485 80.241859,245.744293 80.190918,246.186646 
	C80.806274,248.253983 81.472572,249.878967 81.982300,251.689621 
	C81.825722,251.875275 81.356323,251.995636 81.075188,252.119904 
	C80.566620,252.573044 80.339180,252.901901 80.034103,253.449677 
	C79.763855,253.943726 79.499748,254.088028 78.750137,254.069031 
	C75.646179,254.112366 72.947411,254.206039 72.137589,250.583771 
	C72.709053,248.581100 73.943665,246.068069 73.263779,245.337997 
	C71.835442,243.804276 69.401451,243.207108 67.380287,242.225479 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M88.145691,230.941528 
	C93.413208,236.397018 98.639786,242.268661 103.990356,248.443985 
	C103.383705,250.108871 102.653061,251.470093 101.882797,253.238251 
	C99.364975,250.940170 96.886765,248.235138 94.202782,245.268951 
	C93.468521,243.636093 92.992050,242.241501 92.401154,240.897202 
	C90.997856,237.704697 89.540352,234.536026 88.145691,230.941528 
z"/>
<path fill="#131E2B" opacity="1.000000" stroke="none" 
	d="
M87.083252,246.305527 
	C95.406326,248.742752 97.219322,256.040161 90.232277,261.907928 
	C88.008736,261.373199 86.541847,260.956573 85.277092,260.232422 
	C88.137482,257.316193 91.108162,254.758942 86.958275,250.556885 
	C87.010239,248.825836 87.046745,247.565674 87.083252,246.305527 
z"/>
<path fill="#172831" opacity="1.000000" stroke="none" 
	d="
M85.074959,260.539978 
	C86.541847,260.956573 88.008736,261.373199 89.895164,261.975159 
	C90.216278,262.587891 90.117851,263.015259 89.527237,263.830109 
	C88.685036,265.481964 88.335022,266.746307 87.618507,268.171509 
	C84.334190,268.771637 81.422791,269.422607 78.494339,269.511536 
	C77.694435,269.535828 76.843262,267.871704 76.237465,266.765869 
	C76.573090,265.954224 76.686882,265.359100 76.862480,264.765381 
	C76.924294,264.766815 76.807137,264.806305 77.126907,264.813263 
	C77.931725,264.465759 78.416763,264.111298 79.043724,263.450989 
	C81.148750,262.276733 83.111855,261.408356 85.074959,260.539978 
z"/>
<path fill="#5BBAD7" opacity="1.000000" stroke="none" 
	d="
M101.770584,254.963623 
	C103.488350,256.398193 104.799873,257.843628 106.097748,259.628357 
	C102.465897,262.566315 99.370224,261.205811 97.188873,255.594040 
	C98.663887,255.375168 100.014122,255.174805 101.770584,254.963623 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M104.426590,273.624939 
	C104.292297,278.077026 102.948128,281.496765 98.481651,283.051086 
	C95.950981,281.399597 93.886696,280.028015 92.196793,278.683350 
	C97.575211,280.329407 98.672462,279.612488 99.750809,274.342804 
	C100.808983,275.084442 101.550186,275.709686 102.636902,276.626434 
	C102.922691,274.423828 103.128464,272.837921 103.334236,271.252014 
	C103.666260,271.920471 103.998276,272.588928 104.426590,273.624939 
z"/>
<path fill="#182935" opacity="1.000000" stroke="none" 
	d="
M86.760406,246.105667 
	C87.046745,247.565674 87.010239,248.825836 86.603210,250.562225 
	C84.868088,251.193619 83.503479,251.348801 82.138870,251.503967 
	C81.472572,249.878967 80.806274,248.253983 80.221764,246.148438 
	C82.348221,245.747208 84.392891,245.826508 86.760406,246.105667 
z"/>
<path fill="#163643" opacity="1.000000" stroke="none" 
	d="
M87.985008,268.010681 
	C88.335022,266.746307 88.685036,265.481964 89.352608,264.109253 
	C92.664871,264.793030 95.659584,265.585144 98.638916,266.719604 
	C98.437622,267.082611 98.251686,267.103241 97.720352,267.089233 
	C96.914520,267.048157 96.454079,267.041718 95.619873,267.031921 
	C92.825745,267.355957 90.405380,267.683319 87.985008,268.010681 
z"/>
<path fill="#12242D" opacity="1.000000" stroke="none" 
	d="
M103.299683,270.993561 
	C103.128464,272.837921 102.922691,274.423828 102.636902,276.626434 
	C101.550186,275.709686 100.808983,275.084442 99.591568,274.226135 
	C98.930855,273.778625 98.861671,273.531799 98.931824,272.877502 
	C98.969536,272.001556 98.983238,271.500763 98.998215,270.626434 
	C98.993881,269.506012 98.988281,268.759125 99.145706,267.829681 
	C99.308739,267.647095 99.600487,267.253967 99.600487,267.253967 
	C99.600487,267.253967 99.879440,267.281799 100.105286,267.723267 
	C100.744446,268.371857 101.157745,268.578949 101.571045,268.786072 
	C102.135742,269.435760 102.700439,270.085419 103.299683,270.993561 
z"/>
<path fill="#5BBAD7" opacity="1.000000" stroke="none" 
	d="
M106.807526,268.184143 
	C107.132889,268.846680 107.230995,269.710358 107.173843,270.795258 
	C106.872482,270.139404 106.726379,269.262329 106.807526,268.184143 
z"/>
<path fill="#1A2B2B" opacity="1.000000" stroke="none" 
	d="
M72.372635,237.459030 
	C71.682587,237.667236 70.683792,237.644180 69.367355,237.423584 
	C70.054443,237.226624 71.059174,237.227203 72.372635,237.459030 
z"/>
<path fill="#12242D" opacity="1.000000" stroke="none" 
	d="
M98.065742,267.123871 
	C98.251686,267.103241 98.437622,267.082611 98.898758,267.006622 
	C99.173973,266.951233 99.461540,266.999359 99.531013,267.126648 
	C99.600487,267.253967 99.308739,267.647095 98.960312,267.716278 
	C98.374985,267.610199 98.192940,267.389679 98.065742,267.123871 
z"/>
<path fill="#163643" opacity="1.000000" stroke="none" 
	d="
M101.388725,268.484344 
	C101.157745,268.578949 100.744446,268.371857 100.151955,267.855438 
	C100.383980,267.758301 100.795189,267.970459 101.388725,268.484344 
z"/>
<path fill="#182935" opacity="1.000000" stroke="none" 
	d="
M80.236649,245.481049 
	C80.003197,245.535446 79.669617,245.301392 79.261963,244.759644 
	C79.504105,244.698853 79.820312,244.945740 80.236649,245.481049 
z"/>
<path fill="#D9BF20" opacity="1.000000" stroke="none" 
	d="
M57.196091,272.009796 
	C53.814903,267.863647 50.594685,263.670502 47.465454,259.410492 
	C46.610222,258.246216 46.028225,256.881256 45.147690,255.300446 
	C45.442394,250.179688 43.679787,244.839218 47.739170,240.872803 
	C49.037289,239.604431 51.193005,239.213760 52.956329,238.421509 
	C53.591179,240.376175 55.195118,242.636520 54.691788,244.232147 
	C52.602108,250.856613 52.528568,257.226715 55.556690,263.867554 
	C55.860695,264.116608 55.810123,264.070190 55.806793,264.371887 
	C56.095360,265.180115 56.387257,265.686584 56.626652,266.209290 
	C56.574150,266.225525 56.578289,266.335388 56.634571,266.691101 
	C57.062325,268.662445 57.433796,270.278076 57.805267,271.893677 
	C57.805267,271.893677 57.391312,271.937622 57.196091,272.009796 
z"/>
<path fill="#77C4DB" opacity="1.000000" stroke="none" 
	d="
M67.189529,242.500778 
	C69.401451,243.207108 71.835442,243.804276 73.263779,245.337997 
	C73.943665,246.068069 72.709053,248.581100 71.813416,250.695374 
	C68.496117,256.060150 65.707092,261.016022 62.626472,265.912659 
	C61.952915,265.746857 61.570953,265.640289 61.178829,265.119568 
	C61.001743,262.481476 60.834824,260.257477 60.815086,257.657532 
	C61.675274,254.184448 62.388287,251.087296 63.101295,247.990158 
	C64.400452,246.252121 65.699615,244.514099 67.189529,242.500778 
z"/>
<path fill="#1B3640" opacity="1.000000" stroke="none" 
	d="
M62.758987,248.137451 
	C62.388287,251.087296 61.675274,254.184448 60.508320,257.778717 
	C58.710613,259.508606 57.366848,260.741394 56.023083,261.974152 
	C55.985355,261.483734 56.004704,260.980927 55.901436,260.504730 
	C54.023952,251.847198 54.383736,251.166397 62.758987,248.137451 
z"/>
<path fill="#172831" opacity="1.000000" stroke="none" 
	d="
M56.001453,262.335999 
	C57.366848,260.741394 58.710613,259.508606 60.361145,258.154663 
	C60.834824,260.257477 61.001743,262.481476 61.123558,265.564667 
	C61.055824,267.611023 61.033188,268.798187 61.010559,269.985352 
	C60.842857,269.985748 60.675156,269.986176 60.112755,269.687103 
	C58.671471,268.370209 57.624882,267.352814 56.578293,266.335388 
	C56.578289,266.335388 56.574150,266.225525 56.682991,265.895538 
	C56.464596,265.067108 56.137360,264.568634 55.810127,264.070190 
	C55.810123,264.070190 55.860695,264.116608 55.826958,264.125671 
	C55.855423,263.655792 55.917625,263.176819 56.001453,262.335999 
z"/>
<path fill="#2D5662" opacity="1.000000" stroke="none" 
	d="
M39.140137,242.793854 
	C39.020958,242.598663 39.141262,242.273056 39.488808,241.805939 
	C39.603901,241.997452 39.491760,242.330444 39.140137,242.793854 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M83.125565,223.745667 
	C83.806084,224.065002 84.342674,224.714584 84.936829,225.678757 
	C84.278381,226.523392 83.562370,227.053436 81.949867,228.247131 
	C82.474564,226.125900 82.728104,225.100922 83.125565,223.745667 
z"/>
<path fill="#C8B93E" opacity="1.000000" stroke="none" 
	d="
M57.284103,372.333649 
	C60.367123,371.306427 63.385483,369.899750 66.551605,369.360260 
	C69.628937,368.835907 70.915810,367.274841 71.922691,364.532440 
	C73.425606,360.439026 75.445969,356.535583 77.955719,352.731140 
	C78.848503,353.191010 79.031761,353.471069 79.136734,354.047180 
	C78.041618,356.254761 77.024788,358.166229 75.796005,360.359009 
	C74.340050,363.678223 73.096054,366.716125 71.462357,369.810669 
	C66.712112,370.908722 62.351559,371.950134 57.820641,372.820251 
	C57.650272,372.648926 57.284103,372.333649 57.284103,372.333649 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M101.817261,368.622284 
	C101.584763,368.914093 101.135498,368.896454 99.918724,368.833679 
	C94.802292,368.460602 90.453362,368.132660 86.099770,367.850739 
	C86.095108,367.896759 86.187141,367.887482 86.153603,367.529541 
	C84.803596,364.719330 83.487129,362.267029 82.168716,359.871338 
	C82.166786,359.927948 82.235428,359.837830 82.255035,359.623322 
	C82.274635,359.408813 82.137192,359.000580 82.181046,358.665680 
	C82.015892,357.689911 81.806892,357.049072 81.696396,356.308197 
	C81.794907,356.208191 81.991943,356.008148 81.991943,356.008148 
	C82.386909,356.301208 83.046181,356.528351 83.138123,356.897003 
	C85.306206,365.589935 92.006645,366.950806 99.493431,367.179810 
	C100.206673,367.201630 100.898643,367.918610 101.817261,368.622284 
z"/>
<path fill="#CD9429" opacity="1.000000" stroke="none" 
	d="
M68.092781,383.987701 
	C67.655655,383.636414 67.218521,383.285126 66.516678,382.835571 
	C63.442902,380.481354 60.633846,378.225433 57.868340,375.515930 
	C57.911888,375.062317 58.081158,374.845886 58.366898,374.935852 
	C59.374649,375.368073 60.096661,375.710388 61.038181,376.271515 
	C61.833195,376.669891 62.408703,376.849426 62.984955,377.020630 
	C62.985695,377.012299 62.963993,377.040039 63.111855,377.341553 
	C64.870735,379.757935 66.481758,381.872803 68.092781,383.987701 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M104.003799,371.788391 
	C103.681679,371.435150 103.658562,371.088623 103.666229,370.483215 
	C104.231346,370.465363 104.765686,370.706390 105.300018,370.947449 
	C104.967621,371.230011 104.635216,371.512543 104.003799,371.788391 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M100.262619,375.973450 
	C100.573471,375.201477 101.215828,374.469452 102.129730,373.639282 
	C102.332565,374.168427 102.263847,374.795715 102.060791,375.719452 
	C101.482346,376.015045 101.038239,376.014191 100.262619,375.973450 
z"/>
<path fill="#C8B93E" opacity="1.000000" stroke="none" 
	d="
M57.831818,375.173950 
	C57.399429,374.398468 57.047115,373.511322 56.989452,372.478912 
	C57.284103,372.333649 57.650272,372.648926 57.800755,373.130829 
	C57.994541,374.023773 58.037849,374.434845 58.081158,374.845886 
	C58.081158,374.845886 57.911888,375.062317 57.831818,375.173950 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M102.816055,373.481323 
	C102.761971,373.038116 103.026642,372.562134 103.527924,371.935028 
	C103.554619,372.338776 103.344711,372.893646 102.816055,373.481323 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M79.215019,353.751099 
	C79.031761,353.471069 78.848503,353.191010 78.299881,352.566895 
	C77.934517,352.222778 77.894577,352.152161 77.937698,352.076630 
	C77.980827,352.001099 78.055969,351.920349 78.055969,351.920349 
	C78.583832,352.129822 79.111702,352.339264 79.713776,352.899170 
	C79.787994,353.249573 79.561646,353.513397 79.475037,353.572906 
	C79.388420,353.632416 79.215019,353.751099 79.215019,353.751099 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M80.990540,354.000671 
	C81.295776,354.477539 81.601021,354.954407 81.949104,355.719727 
	C81.991943,356.008148 81.794907,356.208191 81.488503,356.203247 
	C81.182098,356.198334 80.768227,355.984680 80.720673,355.739136 
	C80.579910,355.174469 80.486694,354.855408 80.543137,354.402832 
	C80.692780,354.269348 80.990532,354.000671 80.990540,354.000671 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M79.901505,353.117920 
	C80.295250,353.143707 80.535858,353.347778 80.863693,353.799622 
	C80.990532,354.000671 80.692780,354.269348 80.339386,354.292664 
	C79.737297,354.105164 79.595848,353.837616 79.561646,353.513397 
	C79.561646,353.513397 79.787994,353.249573 79.901505,353.117920 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M77.938171,352.036072 
	C77.895508,352.071014 77.586395,351.761169 77.586395,351.761169 
	C77.586395,351.761169 77.917152,351.737335 77.986557,351.828857 
	C78.055969,351.920349 77.980827,352.001099 77.938171,352.036072 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M103.734344,369.955383 
	C103.541039,370.018433 103.210533,369.807007 102.708473,369.348816 
	C102.890335,369.295013 103.243744,369.487946 103.734344,369.955383 
z"/>
<path fill="#49B3D5" opacity="1.000000" stroke="none" 
	d="
M111.363312,354.911682 
	C110.806801,354.359222 110.614403,353.853424 110.208626,353.173462 
	C110.008003,352.515869 110.020760,352.032379 110.049896,351.186432 
	C111.637154,351.772003 113.208031,352.720032 115.611679,354.170624 
	C113.716888,354.554871 112.722153,354.756592 111.363312,354.911682 
z"/>
<path fill="#49B3D5" opacity="1.000000" stroke="none" 
	d="
M96.695091,322.326233 
	C97.114395,325.066864 97.287354,328.122772 97.456276,331.652344 
	C97.117744,328.964508 96.783241,325.802979 96.695091,322.326233 
z"/>
<path fill="#49B3D5" opacity="1.000000" stroke="none" 
	d="
M89.018898,328.968170 
	C89.497581,328.366791 89.980865,327.323883 90.438148,327.335175 
	C91.201843,327.354004 91.948906,328.046814 92.479286,329.365723 
	C91.555382,329.276215 90.631470,329.186707 89.390053,329.035736 
	C89.072540,328.974274 89.011803,329.000427 89.018898,328.968170 
z"/>
<path fill="#49B3D5" opacity="1.000000" stroke="none" 
	d="
M97.389435,332.952637 
	C97.942307,333.308624 98.202759,333.817841 98.471031,334.615417 
	C98.018234,334.304413 97.557625,333.705139 97.389435,332.952637 
z"/>
<path fill="#49B3D5" opacity="1.000000" stroke="none" 
	d="
M98.313751,335.600037 
	C98.555161,335.596588 98.841232,335.925568 99.169029,336.589111 
	C98.926636,336.593231 98.642525,336.262878 98.313751,335.600037 
z"/>
<path fill="#3DBFE1" opacity="1.000000" stroke="none" 
	d="
M98.907814,273.252655 
	C98.861671,273.531799 98.930855,273.778625 99.274605,274.109802 
	C98.672462,279.612488 97.575211,280.329407 92.148880,278.706940 
	C91.726585,278.703552 91.671082,278.798157 91.421844,278.500671 
	C89.434158,277.939423 87.695724,277.675690 85.634262,277.305450 
	C84.861824,277.219147 84.412407,277.239380 83.552643,277.145935 
	C81.464066,277.163513 79.785820,277.294800 77.732880,277.375549 
	C74.903931,277.910339 72.449684,278.495697 69.995445,279.081055 
	C69.390625,278.351227 68.785805,277.621399 68.174065,276.557129 
	C68.167145,276.222656 68.154350,276.205231 68.470047,276.069977 
	C75.497810,270.748535 83.320007,270.041077 91.658867,270.557098 
	C92.615089,270.836884 93.302094,270.891510 94.303802,271.083313 
	C95.288177,271.321014 95.957840,271.421509 96.629303,271.502686 
	C96.631096,271.483398 96.661217,271.494049 96.818329,271.732788 
	C97.619560,272.398560 98.263687,272.825592 98.907814,273.252655 
z"/>
<path fill="#152023" opacity="1.000000" stroke="none" 
	d="
M88.385330,305.139771 
	C84.892159,302.397736 81.803177,299.706726 78.198898,296.664337 
	C74.128128,293.232849 70.572670,290.152771 67.017212,287.072693 
	C67.158096,285.161804 67.298981,283.250885 67.938843,281.305115 
	C68.437828,281.270233 68.849915,281.546509 68.942780,281.643005 
	C69.035652,281.739532 69.182175,281.963806 68.796249,282.138367 
	C68.033096,286.822113 71.638519,285.413727 74.166718,286.226257 
	C77.233635,287.921997 80.151947,289.210297 82.800552,290.917114 
	C86.660095,293.404297 90.325562,296.192627 94.245865,299.012634 
	C94.883888,299.255402 95.349815,299.338104 95.678268,299.740967 
	C93.897827,300.363129 92.254883,300.665070 90.611938,300.967041 
	C90.444206,301.341583 90.276474,301.716125 90.108749,302.090668 
	C92.811729,303.178101 95.514709,304.265564 98.217690,305.352997 
	C95.074966,305.298920 91.932243,305.244873 88.385330,305.139771 
z"/>
<path fill="#182935" opacity="1.000000" stroke="none" 
	d="
M62.918076,265.971893 
	C65.707092,261.016022 68.496117,256.060150 71.609314,250.992676 
	C72.947411,254.206039 75.646179,254.112366 78.620956,254.361435 
	C77.888596,255.767624 76.871407,256.848938 75.540894,258.044708 
	C72.982834,260.726074 70.484283,263.118622 68.566383,265.910126 
	C66.732491,268.579346 68.365906,269.380707 70.959076,269.312439 
	C70.616379,270.418091 70.307854,271.207092 69.698624,272.178558 
	C68.983391,273.642456 68.568871,274.923828 68.154350,276.205231 
	C68.154350,276.205231 68.167145,276.222656 68.179817,276.230774 
	C67.706947,276.171936 67.221405,276.105011 66.367935,276.019043 
	C66.000000,276.000000 66.001701,275.998047 66.001709,275.998047 
	C65.663734,274.254303 65.325760,272.510529 65.034149,270.037933 
	C65.921402,267.184723 65.618736,265.801392 62.918076,265.971893 
z"/>
<path fill="#77C4DB" opacity="1.000000" stroke="none" 
	d="
M66.806244,287.283875 
	C70.572670,290.152771 74.128128,293.232849 77.863960,296.588104 
	C78.858994,298.569244 79.673660,300.275238 80.729660,302.486572 
	C79.004387,303.241882 77.291527,303.991760 75.048630,304.819855 
	C74.518608,304.898041 74.103775,304.785309 74.103775,304.785309 
	C71.539246,300.728790 68.974709,296.672241 66.250046,292.307007 
	C66.258362,290.497192 66.426811,288.996155 66.806244,287.283875 
z"/>
<path fill="#111A1E" opacity="1.000000" stroke="none" 
	d="
M69.821487,279.298889 
	C72.449684,278.495697 74.903931,277.910339 78.028152,277.541962 
	C80.453079,277.592468 82.208038,277.426056 83.962990,277.259613 
	C84.412407,277.239380 84.861824,277.219147 85.644409,277.568481 
	C86.006607,278.287720 86.035645,278.637390 85.610550,278.994843 
	C82.225937,279.002625 79.295464,279.002625 76.009735,279.002625 
	C78.792542,280.698273 81.150467,282.135040 83.132858,283.497131 
	C79.492126,282.864410 76.226929,282.306335 72.640617,281.609497 
	C71.162971,281.496033 70.006447,281.521271 68.849915,281.546509 
	C68.849915,281.546509 68.437828,281.270233 68.228195,281.138062 
	C68.561546,280.509491 69.104546,280.013092 69.821487,279.298889 
z"/>
<path fill="#6CAFC1" opacity="1.000000" stroke="none" 
	d="
M49.666779,285.604156 
	C49.774136,285.007568 50.195393,284.690765 50.808792,284.189453 
	C52.754829,283.620941 54.508728,283.236969 56.262627,282.852997 
	C56.457882,283.252075 56.653133,283.651154 56.848385,284.050232 
	C55.092522,285.591187 53.336658,287.132141 51.273300,288.802582 
	C50.637432,287.916046 50.309055,286.899994 49.666779,285.604156 
z"/>
<path fill="#1B3640" opacity="1.000000" stroke="none" 
	d="
M62.626476,265.912659 
	C65.618736,265.801392 65.921402,267.184723 65.036728,269.646606 
	C63.930840,269.987732 62.868721,269.991364 61.408577,269.990173 
	C61.033188,268.798187 61.055824,267.611023 61.133728,265.978760 
	C61.570953,265.640289 61.952915,265.746857 62.626476,265.912659 
z"/>
<path fill="#1A2B2B" opacity="1.000000" stroke="none" 
	d="
M56.634575,266.691101 
	C57.624882,267.352814 58.671471,268.370209 59.861206,269.686371 
	C60.265884,271.348145 60.527420,272.711212 60.788952,274.074249 
	C60.553219,274.207825 60.317482,274.341431 60.081749,274.475006 
	C59.489925,273.750519 58.898102,273.026031 58.055771,272.097595 
	C57.433796,270.278076 57.062325,268.662445 56.634575,266.691101 
z"/>
<path fill="#172831" opacity="1.000000" stroke="none" 
	d="
M66.000916,275.999390 
	C65.632172,276.985565 65.262505,277.972382 64.892838,278.959167 
	C64.405937,278.675995 63.919033,278.392853 63.432133,278.109680 
	C64.071594,277.440155 64.711060,276.770630 65.676117,276.049561 
	C66.001701,275.998047 66.000000,276.000000 66.000916,275.999390 
z"/>
<path fill="#172831" opacity="1.000000" stroke="none" 
	d="
M59.293087,283.233887 
	C59.807957,283.078857 60.262371,283.181396 60.716782,283.283936 
	C60.513081,283.645447 60.309387,284.006958 60.105694,284.368469 
	C59.814674,284.076141 59.523655,283.783783 59.293087,283.233887 
z"/>
<path fill="#6CAFC1" opacity="1.000000" stroke="none" 
	d="
M49.201607,290.672424 
	C49.412224,290.202362 49.881435,289.635223 50.639942,289.016724 
	C50.439556,289.501984 49.949879,290.038666 49.201607,290.672424 
z"/>
<path fill="#1F3B3E" opacity="1.000000" stroke="none" 
	d="
M44.515572,275.487732 
	C44.525642,275.510468 44.505497,275.464996 44.515572,275.487732 
z"/>
<path fill="#1B3640" opacity="1.000000" stroke="none" 
	d="
M35.373390,292.194153 
	C35.519222,291.854950 35.960705,291.464417 36.710449,291.045746 
	C36.568825,291.392700 36.118931,291.767792 35.373390,292.194153 
z"/>
<path fill="#172831" opacity="1.000000" stroke="none" 
	d="
M42.730770,264.555481 
	C42.992126,264.480011 43.332657,264.725433 43.788887,265.273193 
	C43.539703,265.342468 43.174824,265.109436 42.730770,264.555481 
z"/>
<path fill="#1B3640" opacity="1.000000" stroke="none" 
	d="
M39.688637,286.619751 
	C40.003899,286.761597 40.175251,287.039185 40.346603,287.316803 
	C40.079315,287.129700 39.812023,286.942627 39.688637,286.619751 
z"/>
<path fill="#1F3B3E" opacity="1.000000" stroke="none" 
	d="
M74.008484,305.123535 
	C74.103775,304.785309 74.518608,304.898041 74.723976,304.961365 
	C75.685478,306.376556 76.441620,307.728424 77.390182,309.354736 
	C75.990074,311.359406 74.397545,313.089661 72.482376,314.917297 
	C71.878746,312.557434 71.597763,310.100098 71.205292,307.301819 
	C72.033607,306.461151 72.973396,305.961487 74.008484,305.123535 
z"/>
<path fill="#12242D" opacity="1.000000" stroke="none" 
	d="
M83.508392,283.571777 
	C81.150467,282.135040 78.792542,280.698273 76.009735,279.002625 
	C79.295464,279.002625 82.225937,279.002625 86.072159,278.999451 
	C88.548958,278.930237 90.110016,278.864197 91.671082,278.798157 
	C91.671082,278.798157 91.726585,278.703552 91.774498,278.679993 
	C93.886696,280.028015 95.950981,281.399597 98.130219,283.155823 
	C99.509911,285.779266 100.774635,288.018097 101.835136,290.817505 
	C100.414528,292.581970 99.198151,293.785828 97.822250,294.698120 
	C95.420464,292.036224 93.178207,289.665894 90.812355,286.960876 
	C88.352264,285.568512 86.015762,284.510834 83.654404,283.508514 
	C83.629539,283.563873 83.508400,283.571777 83.508392,283.571777 
z"/>
<path fill="#12242D" opacity="1.000000" stroke="none" 
	d="
M98.188606,305.347717 
	C95.514709,304.265564 92.811729,303.178101 90.108749,302.090668 
	C90.276474,301.716125 90.444206,301.341583 90.611938,300.967041 
	C92.254883,300.665070 93.897827,300.363129 95.813934,299.596985 
	C96.321899,298.989838 96.576256,298.930237 97.085304,298.837555 
	C97.538536,298.452332 97.756630,298.183533 97.989868,297.688080 
	C98.005020,297.461456 98.090645,297.015350 98.412643,296.965515 
	C100.150597,297.604858 101.566544,298.294006 102.982483,298.983154 
	C101.858284,300.885986 100.734093,302.788818 99.168076,304.702698 
	C98.553558,304.919556 98.380867,305.125397 98.208176,305.331238 
	C98.208176,305.331238 98.159523,305.342407 98.188606,305.347717 
z"/>
<path fill="#152023" opacity="1.000000" stroke="none" 
	d="
M103.148972,298.589172 
	C101.566544,298.294006 100.150597,297.604858 98.311516,296.636322 
	C97.919510,295.901184 97.950638,295.445465 97.981773,294.989716 
	C99.198151,293.785828 100.414528,292.581970 101.895027,291.158264 
	C102.544586,293.357361 102.930016,295.776276 103.148972,298.589172 
z"/>
<path fill="#152023" opacity="1.000000" stroke="none" 
	d="
M98.433731,305.291626 
	C98.380867,305.125397 98.553558,304.919556 98.853699,304.819946 
	C98.981140,304.926147 98.659279,305.252014 98.433731,305.291626 
z"/>
<path fill="#C8B93E" opacity="1.000000" stroke="none" 
	d="
M464.783661,254.271332 
	C464.646484,254.057144 464.726440,253.727219 465.072723,253.329178 
	C465.373871,253.615021 465.261139,253.913208 464.783661,254.271332 
z"/>
<path fill="#D5E30C" opacity="1.000000" stroke="none" 
	d="
M489.336426,272.485535 
	C490.581116,276.985291 488.653229,278.704620 483.850891,278.414673 
	C483.407288,278.536407 483.120514,278.530029 482.817139,278.135498 
	C481.842773,277.697632 481.171783,277.654327 480.500763,277.611023 
	C479.585663,277.369202 478.670593,277.127380 477.292419,276.449890 
	C474.285278,274.859100 471.741211,273.704010 469.197144,272.548920 
	C469.260315,272.208771 469.323517,271.868622 469.386688,271.528473 
	C475.100861,271.528473 480.815033,271.528473 486.529205,271.528473 
	C486.529205,271.528473 486.827087,271.473175 487.114349,271.848480 
	C488.012787,272.306396 488.623962,272.388977 489.235138,272.471558 
	C489.235138,272.471558 489.308319,272.461639 489.336426,272.485535 
z"/>
<path fill="#A2B529" opacity="1.000000" stroke="none" 
	d="
M486.181519,271.311737 
	C480.815033,271.528473 475.100861,271.528473 469.386688,271.528473 
	C469.323517,271.868622 469.260315,272.208771 469.197144,272.548920 
	C471.741211,273.704010 474.285278,274.859100 476.961304,276.320129 
	C472.457916,275.324097 467.822571,274.022125 463.187225,272.720184 
	C463.257446,272.273438 463.327698,271.826721 463.397949,271.379974 
	C470.791077,269.176514 478.288971,269.557190 486.181519,271.311737 
z"/>
<path fill="#A2B529" opacity="1.000000" stroke="none" 
	d="
M489.027893,272.250458 
	C488.623962,272.388977 488.012787,272.306396 487.241364,271.931030 
	C487.660950,271.768646 488.240784,271.899017 489.027893,272.250458 
z"/>
<path fill="#A2B529" opacity="1.000000" stroke="none" 
	d="
M480.761810,277.800415 
	C481.171783,277.654327 481.842773,277.697632 482.706085,278.044769 
	C482.273193,278.229004 481.648041,278.109406 480.761810,277.800415 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M401.908997,339.021820 
	C400.260986,341.398376 398.612976,343.774963 396.589844,346.545654 
	C395.582245,347.907532 394.949768,348.875275 393.969910,349.856201 
	C392.748749,349.578583 391.874969,349.287750 390.925171,348.663269 
	C389.565674,346.139099 388.282166,343.948578 387.278320,341.580505 
	C389.279633,340.248230 391.001251,339.093506 392.996948,337.785583 
	C393.481689,337.067047 393.692261,336.501709 394.117157,335.641296 
	C394.499664,333.600189 394.667847,331.854156 395.126678,329.791138 
	C395.417328,323.531250 395.417328,317.588287 395.417328,311.645294 
	C395.174683,311.645905 394.932068,311.646515 394.689423,311.647125 
	C394.689423,315.568054 394.689423,319.488983 394.531921,323.003906 
	C394.239319,319.105652 394.083740,315.613983 393.972778,312.120941 
	C393.550964,298.844421 393.143585,285.567444 392.730774,271.928009 
	C393.068298,266.452881 390.469543,262.977722 386.760742,260.055847 
	C388.153473,256.020355 390.390625,253.571701 394.580505,253.381485 
	C394.597931,253.390442 394.611237,253.427292 394.611237,253.427292 
	C393.538422,256.611237 394.894470,257.974091 398.647034,258.257568 
	C399.495605,259.344879 399.734711,260.171661 399.731659,261.269775 
	C399.655670,264.692047 399.821808,267.842957 399.593262,271.010010 
	C396.136688,271.248840 394.916595,272.866699 394.963867,275.948608 
	C395.083557,283.757172 394.948456,291.569580 395.055878,299.378510 
	C395.075897,300.832672 395.750824,302.277863 396.155975,303.853394 
	C398.229126,303.055786 399.594055,302.530640 400.919312,302.352295 
	C401.243805,304.467041 401.608002,306.235016 401.972198,308.002960 
	C401.988037,308.456055 402.003906,308.909180 401.718384,310.006470 
	C401.616577,313.435242 401.816132,316.219788 402.015717,319.004364 
	C402.015717,319.004364 402.168121,319.306976 402.115723,319.964203 
	C402.158325,325.802795 402.253326,330.984131 402.272461,336.438416 
	C402.100769,337.481506 402.004913,338.251648 401.908997,339.021820 
M395.764526,306.870758 
	C395.638275,306.585663 395.512024,306.300568 395.385742,306.015472 
	C395.410187,306.362976 395.434662,306.710510 395.764526,306.870758 
z"/>
<path fill="#5FBFDF" opacity="1.000000" stroke="none" 
	d="
M426.003601,291.892578 
	C426.003601,291.892578 426.048462,292.387939 426.066742,292.635437 
	C425.432983,294.824799 424.780945,296.766724 423.609833,298.982971 
	C416.980408,298.737915 414.751587,300.439209 413.048828,306.131622 
	C411.763763,310.427643 414.510468,312.921051 416.300354,315.888367 
	C415.598267,316.530121 415.226349,317.073029 414.495422,317.692474 
	C413.403778,317.754974 412.671204,317.740906 411.757568,317.523865 
	C411.054291,317.174164 410.532074,317.027466 410.009857,316.880768 
	C402.795135,312.787567 403.914337,305.886108 403.942871,299.258026 
	C403.969666,293.043793 403.691711,286.828247 404.137543,280.301605 
	C405.148285,279.992981 405.569305,279.996033 406.020813,280.398590 
	C406.573944,288.983612 411.509216,292.732880 419.572052,291.123962 
	C420.684113,290.902039 421.847626,290.938232 423.193604,291.127380 
	C424.267792,291.564087 425.135681,291.728333 426.003601,291.892578 
z"/>
<path fill="#091318" opacity="1.000000" stroke="none" 
	d="
M416.630524,315.789490 
	C414.510468,312.921051 411.763763,310.427643 413.048828,306.131622 
	C414.751587,300.439209 416.980408,298.737915 423.507080,299.307098 
	C425.960388,301.316376 427.997345,303.275879 429.849396,305.776184 
	C430.287018,307.248413 430.909515,308.179871 431.532013,309.111359 
	C431.486633,311.458862 431.441223,313.806335 431.044312,316.752411 
	C430.117767,319.183136 429.542786,321.015259 428.967804,322.847412 
	C428.795502,322.937408 428.623199,323.027435 428.198059,323.263184 
	C426.222473,322.932190 424.499725,322.455475 422.280640,321.816071 
	C421.526978,321.795593 421.269653,321.937836 421.012299,322.080078 
	C418.882233,321.890686 416.752197,321.701294 414.569580,321.013184 
	C417.984192,319.267639 421.451294,318.020844 425.088867,316.571960 
	C425.747894,315.864685 426.236511,315.359589 426.903229,314.837830 
	C427.167755,314.741272 427.340729,314.633514 427.325500,314.586243 
	C427.259491,314.380768 427.138672,314.192963 427.033081,313.586548 
	C427.040802,312.680542 427.246399,312.073944 427.034485,311.711090 
	C425.550720,309.170135 424.138733,306.550781 422.295288,304.289459 
	C421.956238,303.873505 418.619507,304.928864 418.601227,305.383087 
	C418.489838,308.146149 418.938263,310.931763 419.075287,313.962646 
	C418.479584,314.796356 418.008423,315.377258 417.321716,315.856079 
	C417.106201,315.753937 416.630524,315.789490 416.630524,315.789490 
z"/>
<path fill="#182935" opacity="1.000000" stroke="none" 
	d="
M400.958984,302.005493 
	C399.594055,302.530640 398.229126,303.055786 396.155975,303.853394 
	C395.750824,302.277863 395.075897,300.832672 395.055878,299.378510 
	C394.948456,291.569580 395.083557,283.757172 394.963867,275.948608 
	C394.916595,272.866699 396.136688,271.248840 399.599670,271.388855 
	C400.399658,272.872681 400.798615,273.993744 401.197571,275.114807 
	C401.167572,277.790161 401.137604,280.465546 400.775726,283.896149 
	C400.627777,288.099792 400.811737,291.548218 400.995667,294.996613 
	C401.075012,295.655884 401.154297,296.315155 401.141907,297.740906 
	C401.019836,299.673431 400.989410,300.839478 400.958984,302.005493 
z"/>
<path fill="#65C6E1" opacity="1.000000" stroke="none" 
	d="
M405.990356,279.999084 
	C405.569305,279.996033 405.148285,279.992981 404.413361,279.989807 
	C403.616241,278.509186 403.132965,277.028625 402.440857,274.860535 
	C402.288727,272.726471 402.345459,271.279968 402.589233,269.545044 
	C402.816986,267.859528 402.857697,266.462433 403.120117,264.841614 
	C404.583710,264.736145 405.825653,264.854431 407.067566,264.972687 
	C407.442780,265.602783 407.817993,266.232880 408.608093,267.287689 
	C410.218018,268.748657 411.413055,269.784912 412.225006,270.489014 
	C410.006714,273.872742 407.998535,276.935913 405.990356,279.999084 
z"/>
<path fill="#182D3B" opacity="1.000000" stroke="none" 
	d="
M417.537292,315.958191 
	C418.008423,315.377258 418.479584,314.796356 419.368683,314.129150 
	C421.497253,314.953308 423.207825,315.863708 424.918427,316.774078 
	C421.451294,318.020844 417.984192,319.267639 414.246613,320.771912 
	C413.301300,320.555084 412.626434,320.080750 411.878174,319.017731 
	C411.849365,318.195007 411.893982,317.960938 411.938599,317.726868 
	C412.671204,317.740906 413.403778,317.754974 414.846222,317.770996 
	C416.216461,317.167999 416.876862,316.563110 417.537292,315.958191 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M402.348328,336.165497 
	C402.253326,330.984131 402.158325,325.802795 402.097473,320.132629 
	C402.690277,324.452759 403.248993,329.261719 403.868652,334.533417 
	C403.929657,334.996185 403.748199,335.277344 403.549896,335.553040 
	C403.017151,335.941010 402.682739,336.053253 402.348328,336.165497 
z"/>
<path fill="#102129" opacity="1.000000" stroke="none" 
	d="
M402.402161,269.833466 
	C402.345459,271.279968 402.288727,272.726471 402.226715,274.607361 
	C402.049561,275.036957 401.877686,275.032135 401.451691,275.071075 
	C400.798615,273.993744 400.399658,272.872681 399.994354,271.372742 
	C399.821808,267.842957 399.655670,264.692047 399.892700,261.546509 
	C400.816376,262.681152 401.336884,263.810364 401.554932,265.156006 
	C401.635681,266.859436 402.018921,268.346436 402.402161,269.833466 
z"/>
<path fill="#102129" opacity="1.000000" stroke="none" 
	d="
M400.919312,302.352295 
	C400.989410,300.839478 401.019836,299.673431 401.078430,298.069885 
	C401.487732,300.799591 401.868835,303.966766 402.111084,307.568481 
	C401.608002,306.235016 401.243805,304.467041 400.919312,302.352295 
z"/>
<path fill="#102129" opacity="1.000000" stroke="none" 
	d="
M401.049500,294.541748 
	C400.811737,291.548218 400.627777,288.099792 400.692627,284.337006 
	C400.995361,287.377380 401.049347,290.732117 401.049500,294.541748 
z"/>
<path fill="#102129" opacity="1.000000" stroke="none" 
	d="
M402.068115,318.557434 
	C401.816132,316.219788 401.616577,313.435242 401.651733,310.340271 
	C401.964478,312.723419 402.042511,315.416962 402.068115,318.557434 
z"/>
<path fill="#4791AB" opacity="1.000000" stroke="none" 
	d="
M411.757568,317.523865 
	C411.893982,317.960938 411.849365,318.195007 411.679565,318.764038 
	C411.059052,318.558960 410.563690,318.018951 410.039093,317.179871 
	C410.532074,317.027466 411.054291,317.174164 411.757568,317.523865 
z"/>
<path fill="#182D3B" opacity="1.000000" stroke="none" 
	d="
M421.272827,322.122986 
	C421.269653,321.937836 421.526978,321.795593 421.893311,321.788300 
	C421.845978,322.004120 421.689667,322.084991 421.272827,322.122986 
z"/>
<path fill="#6CAFC1" opacity="1.000000" stroke="none" 
	d="
M434.976624,299.282288 
	C436.091675,296.704132 437.245911,294.500366 439.056274,292.149353 
	C440.844086,293.042877 441.975769,294.083557 443.120728,295.457764 
	C443.212585,296.225952 443.291168,296.660675 443.255127,297.395813 
	C443.075195,298.118683 443.009888,298.541046 442.727478,299.073975 
	C442.283020,299.418793 442.083221,299.674561 441.910919,299.951874 
	C439.616638,299.758484 437.089508,305.003571 434.976624,299.282288 
z"/>
<path fill="#182D3B" opacity="1.000000" stroke="none" 
	d="
M429.322021,322.896301 
	C429.542786,321.015259 430.117767,319.183136 430.987030,317.133545 
	C434.465393,319.810394 434.179901,320.882751 429.322021,322.896301 
z"/>
<path fill="#122020" opacity="1.000000" stroke="none" 
	d="
M452.092255,305.089722 
	C450.536346,306.079803 448.980438,307.069885 447.036163,308.117981 
	C446.356171,307.791992 446.064514,307.407990 445.833435,306.335083 
	C445.919312,304.762634 445.944611,303.879089 445.989197,302.750122 
	C446.008423,302.504700 446.040161,302.013336 446.323120,302.017090 
	C446.794647,302.027161 446.983185,302.033508 447.240570,302.391296 
	C448.874390,303.497070 450.439392,304.251404 452.026184,305.026917 
	C452.048004,305.048065 452.092255,305.089722 452.092255,305.089722 
z"/>
<path fill="#2D5662" opacity="1.000000" stroke="none" 
	d="
M441.955627,300.346924 
	C442.083221,299.674561 442.283020,299.418793 442.974152,299.296997 
	C443.914581,300.616547 444.391235,301.823639 444.883575,303.413269 
	C444.904480,304.561066 444.909729,305.326294 444.914978,306.091553 
	C443.943390,304.308380 442.971863,302.525177 441.955627,300.346924 
z"/>
<path fill="#182D3B" opacity="1.000000" stroke="none" 
	d="
M431.621826,308.782898 
	C430.909515,308.179871 430.287018,307.248413 429.918640,306.121765 
	C430.685669,306.769196 431.198639,307.611816 431.621826,308.782898 
z"/>
<path fill="#1B2B35" opacity="1.000000" stroke="none" 
	d="
M445.106567,306.229309 
	C444.909729,305.326294 444.904480,304.561066 445.159546,303.402893 
	C445.603210,303.005157 445.786560,303.000336 445.969940,302.995544 
	C445.944611,303.879089 445.919312,304.762634 445.817810,306.087280 
	C445.741638,306.528381 445.298126,306.367096 445.106567,306.229309 
z"/>
<path fill="#122020" opacity="1.000000" stroke="none" 
	d="
M453.869965,302.899902 
	C453.521637,303.607849 453.103546,304.253418 452.388855,304.994354 
	C452.092255,305.089722 452.048004,305.048065 452.122375,304.755798 
	C452.683746,303.881073 453.170776,303.298645 453.657806,302.716217 
	C453.657806,302.716217 453.800201,302.837585 453.869965,302.899902 
z"/>
<path fill="#12242D" opacity="1.000000" stroke="none" 
	d="
M391.001160,348.996918 
	C391.874969,349.287750 392.748749,349.578583 394.311523,349.855957 
	C395.672546,349.945251 396.344604,350.047943 397.016632,350.150635 
	C397.609711,350.506195 398.202759,350.861755 398.947693,351.799622 
	C399.536102,353.218079 399.972717,354.054199 400.409302,354.890320 
	C400.696106,355.038300 400.871948,355.282562 401.046448,355.738770 
	C400.757080,356.995392 400.358124,358.136444 399.484192,359.162964 
	C398.036163,358.717896 397.063049,358.387329 396.028259,357.760071 
	C395.645203,356.973969 395.323822,356.484467 394.913513,355.642395 
	C392.627808,352.060394 389.393097,352.422455 386.933441,354.098114 
	C385.351532,355.175781 384.944489,357.978180 384.010559,360.467590 
	C384.012817,363.399170 384.110260,365.875153 383.992859,368.340942 
	C383.858368,371.165009 385.090729,372.303223 387.876526,372.250305 
	C387.933228,373.076477 387.957825,373.549225 387.982422,374.021973 
	C387.327698,375.123718 386.673035,376.225494 386.009186,377.663635 
	C385.149780,378.547058 384.299561,379.094116 383.194275,379.826111 
	C382.601990,374.998871 382.264771,369.986725 381.941376,364.068695 
	C381.841614,360.783203 381.728027,358.403595 381.628448,355.723969 
	C380.773438,354.671387 379.904419,353.918823 378.793030,352.901306 
	C377.030579,352.786682 375.510498,352.937012 373.609283,353.200958 
	C372.179840,353.432007 371.131592,353.549469 369.631042,353.529297 
	C364.127533,353.623962 359.076294,353.856262 353.558563,354.024658 
	C327.725677,353.991028 302.359283,354.021271 277.089539,353.699249 
	C278.677277,352.537384 280.168365,351.727783 281.702728,350.894684 
	C281.436188,345.783264 277.826965,345.325378 274.382263,346.320679 
	C273.214722,346.657990 272.910065,349.981781 272.211060,351.940857 
	C272.555634,352.054321 272.900208,352.167786 273.244781,352.281219 
	C272.148926,352.939484 271.053070,353.597748 269.554932,354.386658 
	C262.769806,354.737305 256.386963,354.957275 249.750610,355.081757 
	C249.330017,355.054810 249.162933,355.123352 248.533356,355.113403 
	C231.734940,355.184113 215.399033,355.333313 198.715530,355.354614 
	C197.890198,355.314911 197.412445,355.403137 196.535141,355.403412 
	C194.778229,355.373901 193.420853,355.432404 191.713409,355.356598 
	C190.880798,355.314758 190.398254,355.407227 189.915710,355.499695 
	C189.501678,355.447235 189.087646,355.394806 188.116486,355.263580 
	C187.376190,355.101349 187.192993,355.017914 187.009811,354.934509 
	C187.009811,354.934509 187.017075,354.463348 187.016754,354.227631 
	C192.808914,351.130188 198.381866,346.704773 204.456360,345.852081 
	C210.383118,345.020081 216.822342,347.838806 223.340393,349.114288 
	C223.340393,348.076050 223.340393,346.561615 223.340393,344.744690 
	C226.865936,343.561829 229.333618,343.745636 230.122696,348.203400 
	C230.716141,351.555939 232.829971,353.229462 236.507660,353.050537 
	C241.158386,352.824219 245.840912,353.196381 250.485001,352.909882 
	C252.870377,352.762726 255.202057,351.745422 258.379547,350.903687 
	C261.000153,350.785461 264.564392,350.624695 268.128632,350.463898 
	C268.233978,350.044617 268.339355,349.625366 268.444702,349.206085 
	C265.999084,348.613678 263.553497,348.021271 260.738556,347.339386 
	C261.087189,346.477814 261.477356,345.513580 262.220154,344.057495 
	C264.092163,342.030060 265.611603,340.494507 267.366364,338.626892 
	C267.738800,333.534210 267.875885,328.773529 268.259277,324.288605 
	C268.677032,326.045013 268.848511,327.525726 269.016052,329.337463 
	C269.015503,330.109985 269.018890,330.551422 269.018219,331.369415 
	C269.016418,332.499146 269.018677,333.252289 269.017700,334.353638 
	C269.267151,335.591492 269.519775,336.481171 269.772400,337.370850 
	C270.515320,336.913910 271.258270,336.456940 272.351562,335.914551 
	C273.805634,332.206573 275.412598,328.650391 275.901123,324.946686 
	C276.738983,318.594116 277.897949,317.471161 284.362854,319.009705 
	C287.483826,320.680817 290.241913,322.341003 293.057678,324.375854 
	C294.613159,330.956146 291.646820,335.666931 285.973785,335.984161 
	C283.582184,336.117889 280.749603,335.981628 281.246613,339.420746 
	C281.726654,342.742615 284.522034,342.246704 286.779572,341.518341 
	C289.825348,340.535645 292.843903,338.107391 295.730927,338.312775 
	C300.065247,338.621094 302.020813,338.019928 301.600677,333.351562 
	C301.529327,332.558990 302.563202,331.666931 303.406067,330.930908 
	C304.730865,331.503632 305.736053,331.966309 306.727234,332.372681 
	C306.713226,332.316376 306.623932,332.390503 306.737732,332.661804 
	C307.570282,333.618256 308.289062,334.303375 309.124634,335.194641 
	C309.433502,335.658722 309.671722,335.862671 310.175110,336.231995 
	C310.980560,336.608704 311.567078,336.766052 312.474243,336.979706 
	C314.544098,337.686462 316.293243,338.336945 318.509827,339.280518 
	C319.328918,339.681396 319.680573,339.789185 320.016418,340.029541 
	C320.000641,340.162079 320.369324,340.315216 320.369324,340.315216 
	C320.369324,340.315216 320.707825,340.140594 320.798706,340.335083 
	C320.889557,340.529602 321.075623,340.916504 321.108368,341.210632 
	C321.819214,341.564575 322.497345,341.624420 323.404480,341.684662 
	C323.633545,341.685059 324.009796,341.946411 324.077820,342.271637 
	C324.889862,343.390564 325.633820,344.184265 326.684937,345.305634 
	C325.140472,345.797180 323.869141,346.201782 322.513367,346.633270 
	C327.539307,354.499176 335.267212,352.185120 341.833588,351.585083 
	C344.427460,351.348083 346.614716,346.661041 349.223022,343.885345 
	C349.797241,343.809479 350.135620,343.848541 350.885864,343.908508 
	C352.533173,343.606079 353.768677,343.282715 355.137573,342.865356 
	C355.270996,342.771393 355.584229,342.863159 355.963318,342.892029 
	C357.233368,342.588867 358.124329,342.256836 359.310608,341.757385 
	C361.299744,341.017212 362.993622,340.444427 365.022400,339.872009 
	C365.911804,339.547211 366.466278,339.222015 367.379364,338.715637 
	C369.486755,338.884644 371.235535,339.234833 373.176392,339.750671 
	C373.528992,339.969635 373.689453,340.022949 373.943726,340.323059 
	C374.361298,340.661530 374.685120,340.753204 375.059204,340.902771 
	C375.109467,340.960663 375.235901,340.873932 375.501801,340.998596 
	C376.519501,341.360474 377.271301,341.597687 378.021118,342.123108 
	C378.022675,342.603516 378.026215,342.795654 378.258728,343.230530 
	C379.614166,344.403320 380.629852,345.537140 381.889648,346.221985 
	C384.715088,347.757782 387.006073,351.261597 391.001160,348.996918 
M355.203918,349.928558 
	C355.958191,349.108368 356.712463,348.288208 357.466736,347.468018 
	C356.498047,346.725708 355.612549,345.743103 354.518066,345.364258 
	C354.104767,345.221191 352.557678,346.630707 352.661041,347.058899 
	C352.914337,348.108459 353.827423,348.998749 355.203918,349.928558 
M367.669983,345.582367 
	C368.257507,344.854919 368.845032,344.127502 369.432556,343.400055 
	C368.758118,343.118103 368.083649,342.836182 367.409210,342.554260 
	C367.310760,343.477692 367.212311,344.401093 367.669983,345.582367 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M227.986420,200.435608 
	C228.806427,197.322449 229.859818,194.692902 230.362747,191.961990 
	C230.617233,190.580200 230.230347,187.998169 229.511551,187.748367 
	C228.089432,187.254135 225.365997,187.399536 224.710388,188.343277 
	C221.904526,192.382263 219.652679,190.341827 216.865570,188.481125 
	C214.445740,186.865662 211.635086,185.843872 209.032669,184.491394 
	C206.311249,183.077042 203.675583,181.491135 200.915222,180.161255 
	C199.649597,179.551498 198.175308,179.374847 196.392792,178.691010 
	C195.976883,178.174011 195.963837,177.967773 196.255142,177.638245 
	C198.682007,177.631760 200.804520,177.748550 203.118484,178.107315 
	C203.872482,178.567047 204.435028,178.784805 205.024261,179.392792 
	C209.014069,185.308838 214.485809,180.432602 219.179565,181.499390 
	C220.239090,181.740204 222.364578,177.290909 224.286621,174.845978 
	C225.481155,174.081238 226.388489,173.471115 227.629425,172.979523 
	C233.684189,176.823975 239.364685,176.293213 245.347260,172.994263 
	C246.119980,173.098236 246.563614,173.143097 246.946320,173.506531 
	C245.923798,174.552917 244.962204,175.280746 243.631348,176.026154 
	C239.958267,176.963791 240.757980,178.943283 241.978394,181.343506 
	C241.988449,182.074615 241.994690,182.492645 241.756866,183.001526 
	C241.341858,183.381668 241.170898,183.670959 240.681580,184.047012 
	C238.923141,185.256668 237.483063,186.379562 235.719513,187.682739 
	C233.677902,191.928513 231.959763,195.994019 230.088531,200.471771 
	C231.050552,205.463745 232.165680,210.043488 233.280807,214.623245 
	C228.643494,213.374191 229.099792,209.069702 228.114182,205.732132 
	C227.671356,204.232620 228.008804,202.502686 227.986420,200.435608 
z"/>
<path fill="#E28614" opacity="1.000000" stroke="none" 
	d="
M233.265778,214.687683 
	C232.165680,210.043488 231.050552,205.463745 230.292480,200.722076 
	C232.660187,203.869141 234.545670,207.262344 236.705307,210.471024 
	C242.857849,219.612106 253.344360,220.713409 261.327820,213.040634 
	C262.977722,211.454956 264.021301,209.223724 265.715332,207.703384 
	C267.013794,206.538040 268.896271,206.023499 270.549957,205.609467 
	C269.975433,208.227295 269.369965,210.455154 268.516998,212.889221 
	C267.790222,214.012070 267.310944,214.928696 266.578796,215.984528 
	C265.869232,216.733124 265.412537,217.342529 264.681641,218.048996 
	C263.883362,218.706299 263.359314,219.266525 262.494629,219.910034 
	C254.727036,225.837906 247.072388,226.097244 239.033844,220.681091 
	C238.370041,220.140274 237.868912,219.847443 237.367767,219.554626 
	C237.367767,219.554626 237.281296,219.659042 237.269073,219.405487 
	C236.941025,218.962830 236.625168,218.773758 236.309326,218.584671 
	C236.309326,218.584671 236.197266,218.725616 236.210663,218.425095 
	C235.861557,217.850967 235.499054,217.577347 235.136536,217.303741 
	C234.968658,216.905579 234.800797,216.507416 234.322586,215.758377 
	C233.714386,215.191513 233.416519,214.975510 233.118652,214.759491 
	C233.118652,214.759491 233.250763,214.752106 233.265778,214.687683 
z"/>
<path fill="#E36D10" opacity="1.000000" stroke="none" 
	d="
M267.131042,338.958923 
	C265.611603,340.494507 264.092163,342.030060 262.281128,343.728333 
	C256.840668,344.101990 251.691635,344.307465 246.543091,344.525146 
	C235.753769,344.981262 233.212234,342.579773 233.392532,331.243347 
	C234.135452,330.464508 234.567566,330.233307 235.237167,330.259888 
	C237.488266,332.892334 239.233093,336.927368 241.569839,337.305634 
	C246.897125,338.167969 252.514572,337.237732 258.392853,337.036377 
	C261.126556,335.992798 263.481537,334.948669 266.060608,333.904022 
	C266.284698,333.903473 266.729950,333.852356 266.818237,334.271362 
	C266.981384,336.113251 267.056183,337.536072 267.131042,338.958923 
z"/>
<path fill="#EDECCB" opacity="1.000000" stroke="none" 
	d="
M231.767929,240.627289 
	C232.629272,238.156662 233.490631,235.686035 235.089294,233.251129 
	C244.157211,233.081711 252.487839,232.876572 260.985596,232.816406 
	C261.152710,232.961411 261.580078,233.075394 261.580078,233.075394 
	C261.580078,233.075394 262.018005,233.013336 262.178711,233.154388 
	C262.339386,233.295441 262.766602,233.314407 262.810181,233.229370 
	C262.853760,233.144318 262.717346,233.278183 262.916016,233.578033 
	C263.778076,236.258942 264.441467,238.640030 265.043640,241.443115 
	C264.955261,243.803680 264.928162,245.742233 264.602783,247.745636 
	C263.911072,247.884262 263.517700,247.958069 263.082031,247.608490 
	C262.957062,245.303680 262.874329,243.422272 262.791504,241.538773 
	C260.154694,241.877274 258.243347,242.122635 256.554169,242.113541 
	C258.535095,240.773392 260.293884,239.687698 262.052673,238.602005 
	C261.939392,238.071365 261.826080,237.540710 261.712769,237.010071 
	C252.342514,238.288177 242.972275,239.566299 233.375732,240.794403 
	C232.688934,240.705383 232.228439,240.666336 231.767929,240.627289 
z"/>
<path fill="#EFEFD7" opacity="1.000000" stroke="none" 
	d="
M204.686707,267.079437 
	C215.411636,273.687683 221.256622,283.194519 221.370270,296.338257 
	C219.705963,296.842834 218.453812,296.870026 217.111847,296.526520 
	C214.769211,285.065918 209.672134,275.992981 199.043304,270.492279 
	C199.337158,268.907867 199.649872,267.660614 200.309021,266.329865 
	C201.999207,266.524048 203.342957,266.801758 204.686707,267.079437 
z"/>
<path fill="#D99E1F" opacity="1.000000" stroke="none" 
	d="
M231.631287,241.105988 
	C232.228439,240.666336 232.688934,240.705383 233.705185,241.068390 
	C235.655991,244.450455 233.438293,250.269058 239.968536,249.444489 
	C232.786041,252.665634 231.386719,255.910782 235.007050,261.135803 
	C235.411880,261.563202 235.670059,261.780670 235.928101,262.421143 
	C235.950562,264.254059 235.973175,265.664001 235.983368,267.439362 
	C236.984940,269.233093 237.998932,270.661377 238.709717,272.296387 
	C233.283356,274.291016 234.941818,278.597412 235.089859,282.136658 
	C235.127426,283.034515 236.949875,283.857758 237.818863,285.054443 
	C233.797195,291.328552 234.596680,295.202393 239.844635,297.282104 
	C238.394745,297.977051 237.194519,298.455078 235.692352,299.004272 
	C234.453278,300.064941 233.516113,301.054443 232.578949,302.043976 
	C232.217529,281.890869 231.856094,261.737762 231.631287,241.105988 
z"/>
<path fill="#1A2627" opacity="1.000000" stroke="none" 
	d="
M260.818451,232.671417 
	C252.487839,232.876572 244.157211,233.081711 235.418793,233.146667 
	C235.104568,232.050659 235.058395,230.299805 235.312180,230.255173 
	C241.708466,229.130585 237.621658,224.759125 238.298935,221.389496 
	C238.794525,220.952820 238.995514,220.940948 239.196518,220.929077 
	C247.072388,226.097244 254.727036,225.837906 262.560364,219.978149 
	C262.966675,219.962997 263.099487,220.097900 263.007507,220.471771 
	C262.216492,224.787567 261.517456,228.729492 260.818451,232.671417 
z"/>
<path fill="#D78D15" opacity="1.000000" stroke="none" 
	d="
M204.897766,266.795380 
	C203.342957,266.801758 201.999207,266.524048 200.436890,265.985565 
	C204.957352,259.164459 209.696411,252.604172 214.435471,246.043884 
	C214.960968,245.466843 215.486481,244.889801 216.306976,244.249954 
	C216.218552,253.403458 212.799194,261.056793 204.897766,266.795380 
z"/>
<path fill="#D99E1F" opacity="1.000000" stroke="none" 
	d="
M234.999664,330.002075 
	C234.567566,330.233307 234.135452,330.464508 233.488968,330.814331 
	C232.927765,323.248962 232.580948,315.565002 232.604187,307.425354 
	C233.476517,306.992584 233.978790,307.015503 234.750824,307.254852 
	C235.669525,307.690460 236.318466,307.909637 236.974518,308.353699 
	C236.981613,308.578552 237.023346,309.026794 236.856873,309.367767 
	C236.483307,311.196838 236.276215,312.684906 236.050537,314.609375 
	C236.075912,316.533417 236.119873,318.021149 235.974579,319.790100 
	C235.568390,322.622223 235.351456,325.173096 235.102356,328.103027 
	C235.046661,328.988739 235.023163,329.495392 234.999664,330.002075 
z"/>
<path fill="#C97624" opacity="1.000000" stroke="none" 
	d="
M221.608398,304.591003 
	C219.732666,310.049774 217.856934,315.508575 215.597885,321.076294 
	C215.214569,321.185242 214.960754,320.972321 215.033569,320.610046 
	C216.097565,315.891144 217.088715,311.534485 218.141937,306.846802 
	C218.865814,305.981201 219.527634,305.446625 220.427872,304.871063 
	C220.973633,304.723969 221.287659,304.644287 221.608398,304.591003 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M185.341461,342.958191 
	C190.011337,340.623413 195.107925,338.304901 200.558167,335.927612 
	C197.247894,341.186951 191.807053,342.717987 185.341461,342.958191 
z"/>
<path fill="#E3D060" opacity="1.000000" stroke="none" 
	d="
M221.708618,304.181702 
	C221.287659,304.644287 220.973633,304.723969 220.390594,304.495422 
	C219.143814,301.739563 218.172745,299.318390 217.201691,296.897217 
	C218.453812,296.870026 219.705963,296.842834 221.330475,296.740417 
	C221.738190,299.034271 221.773514,301.403351 221.708618,304.181702 
z"/>
<path fill="#E7B62A" opacity="1.000000" stroke="none" 
	d="
M234.481079,307.038422 
	C233.978790,307.015503 233.476517,306.992584 232.722565,306.969543 
	C232.423660,305.591614 232.376434,304.213745 232.454071,302.439941 
	C233.516113,301.054443 234.453278,300.064941 235.649826,299.411713 
	C235.433167,302.178131 234.957138,304.608276 234.481079,307.038422 
z"/>
<path fill="#C97624" opacity="1.000000" stroke="none" 
	d="
M215.341187,321.292053 
	C214.947235,322.277832 214.426666,323.156738 213.538330,324.101135 
	C213.170578,324.166565 212.955811,323.955109 213.095978,323.686646 
	C213.811020,322.602905 214.385895,321.787598 214.960754,320.972321 
	C214.960754,320.972321 215.214569,321.185242 215.341187,321.292053 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M239.033844,220.681091 
	C238.995514,220.940948 238.794525,220.952820 238.291901,220.981873 
	C237.779678,220.704758 237.569092,220.410431 237.363129,219.835373 
	C237.868912,219.847443 238.370041,220.140274 239.033844,220.681091 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M204.247650,333.902771 
	C204.172409,333.635834 204.406738,333.323059 205.021454,332.811218 
	C205.401855,332.612122 205.812057,332.604797 205.812073,332.604797 
	C205.393784,333.022186 204.975494,333.439545 204.247650,333.902771 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M201.205963,335.894165 
	C201.354889,335.533234 201.816895,335.152252 202.587265,334.755981 
	C202.436768,335.118500 201.977905,335.496307 201.205963,335.894165 
z"/>
<path fill="#C97624" opacity="1.000000" stroke="none" 
	d="
M205.894348,332.377747 
	C205.812057,332.604797 205.401855,332.612122 205.200317,332.576111 
	C205.273254,332.134705 205.547714,331.729309 206.083618,331.296417 
	C206.304550,331.597229 206.181747,331.891144 205.894348,332.377747 
z"/>
<path fill="#D78D15" opacity="1.000000" stroke="none" 
	d="
M216.837097,243.173218 
	C216.620682,243.139206 216.535309,242.645813 216.649048,241.959259 
	C216.888168,242.082016 216.928162,242.397949 216.837097,243.173218 
z"/>
<path fill="#C97624" opacity="1.000000" stroke="none" 
	d="
M211.534988,326.501801 
	C211.338547,326.769592 211.142105,327.037415 210.596725,327.278564 
	C210.247803,327.251892 209.988159,326.983734 210.118378,326.783630 
	C210.452911,326.332336 210.699799,326.134216 211.122803,326.120453 
	C211.256302,326.251709 211.534988,326.501801 211.534988,326.501801 
z"/>
<path fill="#C97624" opacity="1.000000" stroke="none" 
	d="
M213.278214,324.272034 
	C213.294312,324.704071 213.129547,324.988190 212.565948,325.238068 
	C212.240356,325.246307 211.983566,324.983459 212.109802,324.780457 
	C212.435486,324.323181 212.675415,324.115753 212.955811,323.955109 
	C212.955811,323.955109 213.170578,324.166565 213.278214,324.272034 
z"/>
<path fill="#C97624" opacity="1.000000" stroke="none" 
	d="
M212.363647,325.382538 
	C212.370636,325.817230 212.186813,326.065765 211.735229,326.383057 
	C211.534988,326.501801 211.256302,326.251709 211.251587,325.918793 
	C211.449997,325.333191 211.695557,325.132385 211.983566,324.983459 
	C211.983566,324.983459 212.240356,325.246307 212.363647,325.382538 
z"/>
<path fill="#C97624" opacity="1.000000" stroke="none" 
	d="
M209.712006,328.278839 
	C209.409378,328.141663 209.317093,327.930908 209.375061,327.367645 
	C209.525314,327.015137 209.988159,326.983734 209.988159,326.983734 
	C209.988159,326.983734 210.247803,327.251892 210.372498,327.393555 
	C210.373993,327.817291 210.182373,328.040619 209.712006,328.278839 
z"/>
<path fill="#C97624" opacity="1.000000" stroke="none" 
	d="
M208.577576,329.276703 
	C208.492355,329.046783 208.655975,328.733734 209.096710,328.376282 
	C209.191360,328.619141 209.008881,328.906342 208.577576,329.276703 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M203.047485,334.792786 
	C203.012802,334.603607 203.219574,334.319458 203.696411,333.983521 
	C203.740646,334.187103 203.514771,334.442444 203.047485,334.792786 
z"/>
<path fill="#C97624" opacity="1.000000" stroke="none" 
	d="
M207.571182,330.265686 
	C207.486465,330.041107 207.647430,329.728302 208.082275,329.364502 
	C208.176407,329.601532 207.996628,329.889496 207.571182,330.265686 
z"/>
<path fill="#C97624" opacity="1.000000" stroke="none" 
	d="
M206.509918,331.239014 
	C206.437149,331.011841 206.618652,330.709076 207.074081,330.359497 
	C207.153397,330.596252 206.958786,330.879822 206.509918,331.239014 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M233.229965,215.014572 
	C233.416519,214.975510 233.714386,215.191513 234.108490,215.674866 
	C233.916916,215.718033 233.629089,215.493851 233.229965,215.014572 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M235.236465,217.629517 
	C235.499054,217.577347 235.861557,217.850967 236.290161,218.467346 
	C236.016312,218.525177 235.676346,218.240250 235.236465,217.629517 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M236.369751,218.870514 
	C236.625168,218.773758 236.941025,218.962830 237.333084,219.427826 
	C237.082932,219.521286 236.756561,219.338821 236.369751,218.870514 
z"/>
<path fill="#FABC04" opacity="1.000000" stroke="none" 
	d="
M122.353912,303.098511 
	C121.666122,297.638062 121.326828,292.278656 121.337601,286.549316 
	C123.672607,288.926422 125.787498,291.589966 127.617859,294.436432 
	C132.876175,302.613861 140.088791,307.187958 150.023376,307.395935 
	C150.298889,309.499420 150.557739,311.212952 150.976654,313.266663 
	C152.868469,314.196899 154.581207,315.211792 156.335815,315.290253 
	C160.218048,315.463928 164.121323,315.166840 168.282227,315.294434 
	C170.608597,316.293274 172.668762,317.059387 175.362839,318.061249 
	C175.610443,316.422791 175.810562,315.098541 176.145721,313.721130 
	C176.280762,313.667999 176.415298,313.925201 176.727402,313.826477 
	C177.372681,313.453644 177.705856,313.179565 178.119904,312.797211 
	C178.200745,312.688934 178.470947,312.683624 178.860046,312.786499 
	C184.193176,313.921661 189.277313,316.204956 194.045944,315.670929 
	C200.062027,314.997131 205.041382,316.139771 209.848907,319.289795 
	C208.138046,321.054047 206.572571,322.528503 205.007111,324.002930 
	C199.185455,320.477020 192.384247,322.781616 186.382111,320.960510 
	C179.804520,318.964752 173.968002,319.765778 167.752533,322.367432 
	C164.869568,323.574158 161.117065,322.676849 157.760513,322.786652 
	C154.565659,322.891144 151.265076,323.646393 148.203751,323.077759 
	C143.069443,322.124115 137.726578,321.034027 133.184891,318.645020 
	C130.660980,317.317383 129.649719,313.199860 127.829414,310.423187 
	C126.211662,307.955505 124.418274,305.602936 122.353912,303.098511 
M163.540283,316.507690 
	C163.540283,316.507690 163.483536,316.533142 163.540283,316.507690 
z"/>
<path fill="#E96D0B" opacity="1.000000" stroke="none" 
	d="
M205.005646,324.252625 
	C206.572571,322.528503 208.138046,321.054047 210.110474,319.094452 
	C211.639847,316.735107 212.762268,314.860901 214.067413,312.706360 
	C215.526733,310.676605 216.803299,308.927216 218.079865,307.177826 
	C217.088715,311.534485 216.097565,315.891144 215.033569,320.610046 
	C214.385895,321.787598 213.811020,322.602905 213.095978,323.686646 
	C212.675415,324.115753 212.435486,324.323181 212.109802,324.780457 
	C211.695557,325.132385 211.449997,325.333191 211.118088,325.787537 
	C210.699799,326.134216 210.452911,326.332336 210.118378,326.783630 
	C209.988159,326.983734 209.525314,327.015137 209.294601,327.032043 
	C207.901413,326.471619 206.738922,325.894318 205.288452,325.159363 
	C205.000473,325.001709 205.004181,324.502319 205.005646,324.252625 
z"/>
<path fill="#FBCE07" opacity="1.000000" stroke="none" 
	d="
M214.218552,245.712402 
	C209.696411,252.604172 204.957352,259.164459 200.090439,266.069061 
	C199.649872,267.660614 199.337158,268.907867 199.027069,270.921387 
	C198.163467,273.564972 197.694382,275.889526 196.277954,277.147156 
	C195.464676,277.869263 192.277191,277.460541 191.607315,276.524261 
	C189.176834,273.127350 185.926437,273.051361 182.365311,272.926117 
	C180.286392,272.853027 178.142899,272.287567 176.186432,271.533661 
	C171.744110,269.821869 167.365372,267.930878 163.037796,265.944916 
	C161.883896,265.415344 161.008102,264.279785 159.599731,263.076508 
	C162.770569,262.068451 164.889328,261.394836 167.343414,260.565094 
	C169.574844,259.948700 171.537552,259.668121 173.354706,258.994720 
	C178.930923,256.928345 180.954330,253.742081 180.759003,248.185776 
	C185.963394,244.671860 185.942307,238.278564 181.059631,233.398926 
	C179.747543,231.691010 178.487213,230.298096 177.077423,228.588867 
	C174.264206,227.486160 171.600449,226.699753 168.487579,225.897202 
	C165.034637,225.899261 162.030838,225.917480 158.848618,225.773224 
	C158.114685,225.742432 157.559158,225.874100 156.591141,225.989929 
	C154.108078,226.268890 152.037521,226.563690 149.757507,226.999069 
	C149.372009,227.420609 149.195953,227.701553 148.993454,228.451569 
	C148.969040,235.951874 148.971085,242.983109 148.758606,250.214752 
	C148.680740,251.269226 148.817383,252.123276 148.977295,253.312927 
	C149.007004,254.096985 149.013458,254.545425 148.669739,255.009567 
	C147.701157,255.871582 147.082764,256.717896 146.464355,257.564209 
	C147.617081,257.386108 148.769821,257.208008 149.990189,257.351624 
	C150.703964,258.711304 151.350098,259.749298 151.996582,261.212585 
	C150.996216,264.281372 150.661926,267.587036 148.843079,269.417725 
	C145.900223,272.379730 141.673599,274.031860 138.550598,276.857697 
	C134.285187,280.717224 130.524567,280.035675 125.834961,276.445251 
	C127.416420,274.513580 128.913452,272.685059 130.748474,270.950989 
	C132.938553,270.781372 134.913010,270.797180 136.564774,270.074951 
	C137.032776,269.870331 136.844421,266.424683 136.468719,266.326111 
	C134.671463,265.854370 132.703110,265.938690 130.802948,266.013062 
	C130.230011,266.035461 129.680695,266.661346 128.745789,267.026550 
	C125.905739,268.363098 123.440468,269.683929 120.975197,271.004730 
	C120.956215,267.319244 120.937241,263.633759 121.191872,259.191895 
	C122.359474,254.829636 123.253464,251.223755 124.380569,247.679138 
	C124.613693,247.740387 124.986053,248.046570 125.045761,248.389694 
	C127.837349,252.561096 128.947922,252.966843 132.277710,251.093353 
	C133.317841,250.607239 134.088089,250.282288 135.194641,249.927307 
	C136.060242,249.509628 136.589523,249.121994 137.237762,248.446045 
	C136.208771,245.847717 135.060837,243.537689 133.970673,241.343933 
	C132.099350,241.933548 130.589996,242.975586 129.730637,242.585526 
	C125.072571,240.471359 124.286179,243.589691 123.020386,246.874222 
	C121.734558,246.942688 120.876572,246.972641 120.018585,247.002594 
	C120.014175,242.978424 120.009766,238.954269 120.029785,234.467667 
	C120.699539,233.175629 121.866356,232.372177 121.909492,231.512375 
	C122.338928,222.951813 124.730400,214.177444 119.065979,205.913223 
	C121.388802,205.141724 123.335266,204.495224 125.551498,203.662567 
	C127.955635,203.363434 130.089996,203.250488 132.914215,203.408081 
	C134.709015,203.526382 135.813995,203.374146 136.918961,203.221909 
	C152.472397,202.867462 168.194016,200.212769 183.474228,204.737198 
	C199.847076,209.585144 209.162567,221.032074 211.955246,237.871506 
	C212.377502,240.417740 213.306854,242.879883 214.218552,245.712402 
M206.212311,228.881836 
	C204.762878,228.104797 203.021240,226.433472 201.941055,226.786438 
	C200.705185,227.190277 199.616425,229.313934 199.340988,230.847839 
	C199.204742,231.606552 201.668564,233.662582 201.879791,233.521423 
	C203.514145,232.429123 204.885330,230.943054 206.212311,228.881836 
z"/>
<path fill="#F7C40E" opacity="1.000000" stroke="none" 
	d="
M136.544189,203.122223 
	C135.813995,203.374146 134.709015,203.526382 133.359619,203.511536 
	C134.133286,203.237152 135.151352,203.129837 136.544189,203.122223 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M189.321701,180.955811 
	C189.595657,179.741531 190.191406,178.484299 191.317230,177.041855 
	C192.288239,177.030975 192.729172,177.205276 193.108597,177.782700 
	C193.031357,178.457214 193.015640,178.728607 192.999924,179.000000 
	C191.881119,179.637619 190.762314,180.275223 189.321701,180.955811 
z"/>
<path fill="#14232A" opacity="1.000000" stroke="none" 
	d="
M193.374939,179.000031 
	C193.015640,178.728607 193.031357,178.457214 193.458954,177.807693 
	C194.564148,177.540237 195.257462,177.650879 195.950775,177.761520 
	C195.963837,177.967773 195.976883,178.174011 195.995026,178.690201 
	C195.250092,179.000122 194.500031,179.000092 193.374939,179.000031 
z"/>
<path fill="#34C9EF" opacity="1.000000" stroke="none" 
	d="
M67.759247,158.014648 
	C70.348518,162.390701 72.573502,166.829742 74.878204,171.922333 
	C73.622444,174.234146 72.270027,175.879135 70.955475,177.553833 
	C68.954109,180.103531 66.978966,182.673813 64.992691,185.235352 
	C57.953484,185.210510 52.970730,181.215271 48.157692,176.295105 
	C48.385895,174.574997 49.402878,172.959045 49.035835,171.770523 
	C47.714989,167.493454 52.445400,160.811569 56.724670,161.851471 
	C61.372398,162.980927 64.110916,160.064255 67.759247,158.014648 
z"/>
<path fill="#6FCAE3" opacity="1.000000" stroke="none" 
	d="
M46.914516,145.954346 
	C49.746140,143.669357 52.577759,141.384354 55.763863,139.093033 
	C59.821247,143.931381 55.031433,145.321533 51.970573,147.718002 
	C49.849049,147.635605 48.379742,147.515457 46.935478,147.035156 
	C46.945187,146.434753 46.929852,146.194550 46.914516,145.954346 
z"/>
<path fill="#EDFAF5" opacity="1.000000" stroke="none" 
	d="
M46.910435,147.395325 
	C48.379742,147.515457 49.849049,147.635605 51.676739,147.872650 
	C52.098953,149.055222 52.162785,150.120865 52.042007,151.766571 
	C51.857403,152.346619 51.763054,152.792206 51.763054,152.792206 
	C51.763054,152.792206 51.774811,152.884842 51.563957,152.940140 
	C51.171551,153.273499 50.990005,153.551559 50.808464,153.829620 
	C50.808464,153.829620 50.914925,153.946671 50.615944,153.945190 
	C50.047100,154.189240 49.777237,154.434784 49.507370,154.680313 
	C48.892315,154.654938 48.277260,154.629562 47.251244,154.392990 
	C46.658989,154.035675 46.453457,153.943634 46.223679,153.905655 
	C45.859421,153.485031 45.495163,153.064392 45.003880,152.396011 
	C44.941143,151.045944 45.005424,149.943634 45.274033,148.441223 
	C45.478363,148.041107 45.826302,148.063828 46.039131,148.004089 
	C46.538136,147.841248 46.757629,147.658234 46.910435,147.395325 
z"/>
<path fill="#6FCAE3" opacity="1.000000" stroke="none" 
	d="
M49.798187,154.655045 
	C49.777237,154.434784 50.047100,154.189240 50.583992,153.872803 
	C50.597012,154.077866 50.343006,154.353821 49.798187,154.655045 
z"/>
<path fill="#B3E8ED" opacity="1.000000" stroke="none" 
	d="
M46.286945,154.095200 
	C46.453457,153.943634 46.658989,154.035675 46.957115,154.302216 
	C47.073948,154.422623 46.683998,154.500870 46.683998,154.500870 
	C46.683998,154.500870 46.350208,154.284744 46.286945,154.095200 
z"/>
<path fill="#6FCAE3" opacity="1.000000" stroke="none" 
	d="
M51.056259,153.750641 
	C50.990005,153.551559 51.171551,153.273499 51.589146,152.900833 
	C51.651482,153.094696 51.477772,153.383163 51.056259,153.750641 
z"/>
<path fill="#6FCAE3" opacity="1.000000" stroke="none" 
	d="
M51.934776,152.785583 
	C51.763054,152.792206 51.857403,152.346619 51.997044,152.166443 
	C52.214596,152.181274 52.312420,152.372009 52.355850,152.574417 
	C52.366177,152.622543 52.194477,152.709732 51.934776,152.785583 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M68.966232,141.141342 
	C69.127357,141.095062 69.214066,141.559433 69.094833,141.762634 
	C68.975609,141.965836 68.658051,141.626144 68.658051,141.626144 
	C68.658051,141.626144 68.805099,141.187622 68.966232,141.141342 
z"/>
<path fill="#F9BA02" opacity="1.000000" stroke="none" 
	d="
M120.801956,132.091156 
	C122.093506,135.220169 123.451920,138.466110 123.956963,141.839813 
	C124.457909,145.186188 124.099388,148.661209 123.745255,152.594940 
	C121.232399,155.046204 119.104401,158.606079 116.949570,158.622406 
	C109.680672,158.677429 102.154282,160.943649 95.119682,156.269180 
	C90.506844,153.203964 85.070251,151.378448 80.002579,148.997726 
	C79.988426,148.558365 79.974274,148.119003 80.178757,147.124695 
	C79.932343,144.709366 79.467293,142.848984 79.002243,140.988617 
	C83.118301,139.938721 87.189499,138.619766 91.364548,137.923691 
	C94.727646,137.363007 99.115234,136.153656 101.482796,137.672455 
	C108.975807,142.479233 113.430359,137.328400 118.600037,133.875702 
	C119.284950,133.418259 119.865860,132.805115 120.801956,132.091156 
z"/>
<path fill="#FAB204" opacity="1.000000" stroke="none" 
	d="
M78.849113,140.870850 
	C79.467293,142.848984 79.932343,144.709366 80.178772,146.794983 
	C79.104683,145.530289 78.249214,144.040344 77.312790,142.229553 
	C77.719887,141.523514 78.207932,141.138306 78.849113,140.870850 
z"/>
<path fill="#FAB204" opacity="1.000000" stroke="none" 
	d="
M80.043793,149.363312 
	C85.070251,151.378448 90.506844,153.203964 95.119682,156.269180 
	C102.154282,160.943649 109.680672,158.677429 116.949570,158.622406 
	C119.104401,158.606079 121.232399,155.046204 123.731552,153.062363 
	C124.068779,154.722260 124.047134,156.429855 124.006142,158.563934 
	C123.717804,159.138794 123.493927,159.337891 123.209045,159.786346 
	C116.299408,168.556793 104.426949,174.364044 95.167114,168.750793 
	C87.973000,164.389816 82.916946,157.811890 80.043793,149.363312 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M86.163124,171.603241 
	C85.603775,171.393463 84.933662,170.902069 84.287804,170.057648 
	C84.892159,170.243622 85.472267,170.782623 86.163124,171.603241 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M84.283333,169.492996 
	C84.042496,169.567673 83.742798,169.374527 83.400070,168.905640 
	C83.646187,168.828339 83.935326,169.026749 84.283333,169.492996 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M88.343094,173.611862 
	C88.146744,173.671310 87.777916,173.461655 87.254204,172.970001 
	C87.456421,172.906235 87.813530,173.124496 88.343094,173.611862 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M94.476501,178.889282 
	C94.058434,178.746216 94.067543,178.532394 94.106300,177.998871 
	C94.538261,177.816269 94.940590,177.953354 95.541832,178.343460 
	C95.461739,178.670486 95.182716,178.744507 94.476501,178.889282 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M98.722595,179.130814 
	C98.937805,179.249680 98.556198,179.455292 98.556198,179.455292 
	C98.556198,179.455292 98.167084,179.294815 98.091599,179.163513 
	C98.016106,179.032196 98.507393,179.011948 98.722595,179.130814 
z"/>
<path fill="#D2EEEF" opacity="1.000000" stroke="none" 
	d="
M134.989075,103.067383 
	C131.660522,103.701340 128.331970,104.335297 124.604027,104.984451 
	C124.132439,104.557938 124.060249,104.116219 124.097694,103.129967 
	C124.202698,102.413956 124.198074,102.242493 124.193443,102.071022 
	C126.440819,100.824860 128.572220,98.872627 130.959854,98.480453 
	C135.833786,97.679909 140.866608,97.319221 145.791962,97.577820 
	C148.327606,97.710953 150.772980,99.562958 153.547211,101.120346 
	C153.772537,101.995461 153.709808,102.388474 153.233368,102.749023 
	C150.923355,102.864616 149.027054,103.012657 146.668579,103.071648 
	C142.467300,103.010857 138.728180,103.039124 134.989075,103.067383 
z"/>
<path fill="#111A1E" opacity="1.000000" stroke="none" 
	d="
M153.647079,102.781479 
	C153.709808,102.388474 153.772537,101.995461 153.834991,101.297928 
	C156.093567,102.978592 158.352402,104.963776 161.680939,107.889069 
	C161.836884,104.856628 161.915802,103.322151 161.999329,101.383247 
	C162.003922,100.978821 162.486267,100.989746 162.727448,100.995308 
	C162.958145,104.695000 162.947678,108.389130 162.844482,112.535721 
	C157.063995,114.600098 154.929337,117.142342 155.056091,122.699493 
	C155.138992,126.334045 155.745285,129.956390 155.835190,133.591171 
	C155.877213,135.290070 155.251617,137.005493 154.486816,138.395020 
	C154.675781,126.302956 150.903488,114.578949 153.647079,102.781479 
M154.509186,108.151474 
	C154.828140,108.090622 155.147095,108.029778 155.466049,107.968925 
	C155.149323,107.896286 154.832581,107.823647 154.509186,108.151474 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M124.033211,102.125259 
	C124.198074,102.242493 124.202698,102.413956 124.055176,102.792969 
	C123.815926,102.807114 123.706963,102.619247 123.655716,102.416779 
	C123.641258,102.359673 123.795891,102.259773 124.033211,102.125259 
z"/>
<path fill="#FAC604" opacity="1.000000" stroke="none" 
	d="
M120.963608,271.470276 
	C123.440468,269.683929 125.905739,268.363098 128.780884,267.320068 
	C129.147034,267.988098 129.103317,268.378418 129.149963,269.015320 
	C129.486313,269.504639 129.732330,269.747345 130.113754,270.193054 
	C130.249176,270.395996 130.410477,270.856537 130.410477,270.856537 
	C128.913452,272.685059 127.416420,274.513580 125.834961,276.445251 
	C130.524567,280.035675 134.285187,280.717224 138.550598,276.857697 
	C141.673599,274.031860 145.900223,272.379730 148.843079,269.417725 
	C150.661926,267.587036 150.996216,264.281372 152.457397,261.129578 
	C157.614578,260.654602 162.311340,260.687927 167.008102,260.721252 
	C164.889328,261.394836 162.770569,262.068451 159.599731,263.076508 
	C161.008102,264.279785 161.883896,265.415344 163.037796,265.944916 
	C167.365372,267.930878 171.744110,269.821869 176.186432,271.533661 
	C178.142899,272.287567 180.286392,272.853027 182.365311,272.926117 
	C185.926437,273.051361 189.176834,273.127350 191.607315,276.524261 
	C192.277191,277.460541 195.464676,277.869263 196.277954,277.147156 
	C197.694382,275.889526 198.163467,273.564972 199.045914,271.258545 
	C209.672134,275.992981 214.769211,285.065918 217.111847,296.526550 
	C218.172745,299.318390 219.143814,301.739563 220.152161,304.536377 
	C219.527634,305.446625 218.865814,305.981201 218.141937,306.846802 
	C216.803299,308.927216 215.526733,310.676605 213.634018,312.709442 
	C205.637024,313.480804 199.592331,311.068787 194.907623,305.146515 
	C193.331100,303.153534 191.075760,301.697540 189.101883,299.578613 
	C189.069717,297.766479 189.066360,296.371765 189.284256,294.731323 
	C189.002472,293.324371 188.499451,292.163177 187.769165,290.775696 
	C187.030731,289.359100 186.519531,288.168793 185.996063,286.615540 
	C184.926529,285.200806 183.869263,284.149048 182.447113,283.025879 
	C173.628952,278.803192 164.544418,280.440338 155.671677,279.970428 
	C151.205124,279.733856 149.842270,281.559357 149.972046,285.784088 
	C150.189163,292.851776 150.015762,299.931427 150.006714,307.006042 
	C140.088791,307.187958 132.876175,302.613861 127.617859,294.436432 
	C125.787498,291.589966 123.672607,288.926422 121.358261,286.085449 
	C121.003235,281.306274 120.977623,276.621033 120.963608,271.470276 
z"/>
<path fill="#F7C40E" opacity="1.000000" stroke="none" 
	d="
M119.992340,247.458374 
	C120.876572,246.972641 121.734558,246.942688 123.191223,246.948212 
	C124.004112,247.141510 124.123299,247.352905 124.147446,247.617874 
	C123.253464,251.223755 122.359474,254.829636 121.198616,258.733154 
	C120.609863,255.325272 120.287979,251.619705 119.992340,247.458374 
z"/>
<path fill="#2EBCE5" opacity="1.000000" stroke="none" 
	d="
M65.320465,185.454651 
	C66.978966,182.673813 68.954109,180.103531 70.955475,177.553833 
	C72.270027,175.879135 73.622444,174.234146 75.039398,172.224304 
	C76.558281,172.576187 77.995674,173.279648 79.789536,173.952286 
	C80.687126,174.435486 81.228233,174.949509 81.715973,176.078796 
	C81.479965,178.816513 81.297348,180.938980 80.924576,183.278290 
	C80.134796,183.973007 79.535156,184.450867 78.935532,184.928741 
	C74.506439,185.177139 70.077339,185.425552 65.320465,185.454651 
z"/>
<path fill="#1589AF" opacity="1.000000" stroke="none" 
	d="
M85.947380,179.194107 
	C87.935486,180.520157 91.461060,180.332397 90.826904,184.562592 
	C89.718399,185.890106 88.655167,186.850845 87.303467,187.927704 
	C86.897919,186.084274 86.780830,184.124725 86.329224,182.080139 
	C85.994705,181.995102 85.986122,181.987000 85.990311,181.637573 
	C85.978790,180.590118 85.963089,179.892120 85.947380,179.194107 
z"/>
<path fill="#1589AF" opacity="1.000000" stroke="none" 
	d="
M79.202621,185.181595 
	C79.535156,184.450867 80.134796,183.973007 81.279572,183.255890 
	C83.211853,182.673431 84.598984,182.330215 85.986122,181.987000 
	C85.986122,181.987000 85.994705,181.995102 85.995262,181.995422 
	C85.117882,185.604248 82.928314,186.723541 79.202621,185.181595 
z"/>
<path fill="#1589AF" opacity="1.000000" stroke="none" 
	d="
M85.720291,189.036255 
	C85.509789,189.526764 85.005722,190.052170 84.231339,190.641937 
	C84.449593,190.161255 84.938156,189.616211 85.720291,189.036255 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M93.001846,194.002197 
	C92.056648,193.231033 91.109612,192.457657 90.162567,191.684265 
	C90.554985,191.247452 90.947411,190.810638 91.339844,190.373810 
	C91.891769,191.330658 92.443687,192.287491 93.000015,193.624008 
	C93.004433,194.003693 93.000000,194.000000 93.001846,194.002197 
z"/>
<path fill="#15262B" opacity="1.000000" stroke="none" 
	d="
M83.722343,198.192261 
	C82.721161,198.239197 81.556015,197.952957 80.229263,197.349640 
	C81.231232,197.308060 82.394814,197.583588 83.722343,198.192261 
z"/>
<path fill="#4BDAFA" opacity="1.000000" stroke="none" 
	d="
M153.711945,141.065994 
	C153.789246,141.235397 153.866547,141.404800 154.021271,142.040314 
	C154.098694,142.506454 154.313309,142.940262 154.313309,142.940262 
	C154.301743,144.054688 154.290192,145.169128 154.356812,146.970337 
	C154.323471,148.099014 154.211960,148.540909 153.847565,149.146545 
	C153.385330,149.863281 153.175995,150.416245 152.619873,150.984985 
	C151.171173,151.696823 150.069244,152.392883 148.967316,153.088943 
	C144.943283,153.794693 140.919250,154.500427 136.544403,155.267700 
	C135.778412,148.495285 134.658554,140.589432 134.063690,132.644272 
	C133.656616,127.207108 134.113052,121.710159 133.905426,116.250511 
	C133.834259,114.379204 132.919174,112.539993 132.197083,110.355354 
	C132.297012,109.864273 132.548981,109.655144 133.332733,109.195305 
	C138.939301,108.954437 144.195602,109.485756 147.422379,103.998474 
	C148.233490,104.952522 148.986374,105.746635 149.081543,106.612999 
	C149.822861,113.361893 150.285385,120.144089 151.143112,126.876610 
	C151.749741,131.638214 152.838455,136.338394 153.711945,141.065994 
z"/>
<path fill="#32CCF1" opacity="1.000000" stroke="none" 
	d="
M148.968735,153.497498 
	C150.069244,152.392883 151.171173,151.696823 152.630493,151.450989 
	C153.042007,154.773895 153.096100,157.646561 153.090332,160.863937 
	C154.327499,162.072037 155.624512,162.935440 157.217407,164.096512 
	C161.463638,164.729630 165.633087,166.740952 169.289642,162.681259 
	C171.462936,160.105591 173.068512,156.284241 175.391586,155.782074 
	C177.644653,155.295044 180.634720,158.217438 183.301117,159.642502 
	C183.539413,160.471970 183.777725,161.301422 184.041367,162.963882 
	C184.097916,165.214844 184.129135,166.632828 184.160339,168.050797 
	C183.181549,166.849243 182.202759,165.647690 181.349396,164.600098 
	C179.789825,175.242783 177.845001,176.939407 166.741745,176.986206 
	C158.142365,177.022461 149.542862,177.023041 140.476486,177.008347 
	C140.077393,175.311417 140.112778,173.643524 140.219925,171.980255 
	C140.426605,168.772110 140.782257,165.569641 140.873337,162.359070 
	C140.961975,159.233994 142.359299,157.727142 145.507385,157.982773 
	C148.575729,158.231918 149.164474,156.410019 148.968735,153.497498 
z"/>
<path fill="#0D3543" opacity="1.000000" stroke="none" 
	d="
M184.354538,168.325073 
	C184.129135,166.632828 184.097916,165.214844 184.163239,163.384430 
	C189.864227,162.905838 187.089508,158.076889 188.838287,155.480698 
	C189.376892,155.670944 189.616852,155.874405 189.969635,156.367035 
	C190.142258,157.801514 190.291016,158.841919 190.273224,160.260925 
	C190.166901,161.450958 190.227142,162.262390 190.232803,163.452942 
	C190.036392,164.577408 189.894531,165.322754 189.752670,166.068085 
	C187.092514,165.730865 186.018341,166.799240 185.914734,169.573456 
	C185.839737,171.581848 184.901566,173.558014 184.037674,175.787750 
	C184.000931,173.551086 184.274826,171.075226 184.354538,168.325073 
z"/>
<path fill="#0D3543" opacity="1.000000" stroke="none" 
	d="
M183.458344,176.581299 
	C183.568512,176.787125 183.431351,177.164352 183.022812,177.690216 
	C182.904648,177.476807 183.057831,177.114746 183.458344,176.581299 
z"/>
<path fill="#0D3543" opacity="1.000000" stroke="none" 
	d="
M182.549530,177.871429 
	C182.629562,178.070847 182.469177,178.374985 182.061478,178.777954 
	C181.979156,178.576553 182.144150,178.276367 182.549530,177.871429 
z"/>
<path fill="#B3E8ED" opacity="1.000000" stroke="none" 
	d="
M46.935478,147.035156 
	C46.757629,147.658234 46.538136,147.841248 46.084721,147.660080 
	C45.945847,146.916031 45.974213,146.456284 46.002571,145.996521 
	C46.002571,145.996521 46.000107,146.000000 45.998718,146.001404 
	C45.997330,146.002792 46.456562,145.982224 46.685539,145.968292 
	C46.929852,146.194550 46.945187,146.434753 46.935478,147.035156 
z"/>
<path fill="#41CCEF" opacity="1.000000" stroke="none" 
	d="
M45.886253,141.516479 
	C45.696800,141.565277 45.549633,141.394363 45.434639,141.204010 
	C45.422485,141.183899 45.599400,141.049545 45.863560,140.904083 
	C46.038525,140.839981 45.937813,141.291168 45.886253,141.516479 
z"/>
<path fill="#26BAE4" opacity="1.000000" stroke="none" 
	d="
M127.497131,382.969727 
	C127.063950,382.524292 127.037643,382.087585 127.000420,381.322388 
	C127.005417,374.322083 127.021324,367.650238 127.056396,360.515869 
	C127.243576,360.027618 127.411606,360.001862 127.830460,359.925842 
	C128.820175,359.923584 129.559036,359.971588 130.917023,360.258972 
	C133.013992,360.355225 134.491852,360.212097 135.969696,360.068939 
	C153.662674,359.708405 171.355667,359.347870 189.855072,359.005798 
	C191.113007,359.018433 191.564529,359.012604 192.482819,359.008331 
	C202.636337,359.003998 212.323090,358.998077 221.946655,359.355164 
	C220.135590,360.816956 218.269196,361.769257 216.675751,363.058838 
	C215.294373,364.176788 214.222336,365.677002 212.988770,367.409180 
	C212.859344,368.333527 212.754745,368.856964 212.439682,369.057892 
	C209.516556,368.196869 206.803894,367.658356 203.927551,367.002228 
	C203.519287,367.281769 203.274719,367.678925 203.018661,368.044312 
	C203.007172,368.012482 203.074219,368.003448 202.864136,368.147125 
	C202.441238,368.552521 202.228409,368.814240 202.076019,369.053711 
	C202.136459,369.031464 202.184280,369.151093 201.740234,369.094971 
	C195.744675,367.610992 190.163849,366.286133 184.653030,364.715546 
	C181.504333,363.818146 178.867111,363.899567 176.996185,366.978882 
	C177.006790,366.996124 176.967575,366.986664 176.725983,367.015625 
	C176.484390,367.044586 175.999908,367.090332 175.575897,367.062714 
	C173.736084,367.060638 172.320282,367.086212 170.535675,367.097778 
	C169.673553,367.080688 169.180252,367.077576 168.237274,367.071167 
	C161.532013,367.078857 155.275955,367.140472 149.021011,367.081207 
	C145.493454,367.047821 143.959595,368.611298 144.017151,372.171539 
	C144.096893,377.102814 143.914185,382.038330 143.843857,386.972046 
	C143.843857,386.972046 143.421219,386.992615 143.209259,386.529358 
	C142.997299,380.825409 142.997299,375.584686 142.997299,370.343994 
	C142.644150,370.343689 142.291000,370.343414 141.937851,370.343109 
	C141.937851,376.229584 141.937851,382.116089 141.937851,388.002563 
	C141.182587,387.689728 139.931641,387.553680 139.747406,387.037170 
	C137.468781,380.648560 132.673325,381.901703 127.497131,382.969727 
z"/>
<path fill="#54C8E0" opacity="1.000000" stroke="none" 
	d="
M135.725830,359.824524 
	C134.491852,360.212097 133.013992,360.355225 131.288055,360.268524 
	C132.520615,359.885803 134.001297,359.732971 135.725830,359.824524 
z"/>
<path fill="#54C8E0" opacity="1.000000" stroke="none" 
	d="
M130.213867,357.190186 
	C131.232056,356.738922 132.474960,356.532166 133.862076,356.650208 
	C132.817047,357.128235 131.627808,357.281494 130.213867,357.190186 
z"/>
<path fill="#54C8E0" opacity="1.000000" stroke="none" 
	d="
M192.016037,359.006775 
	C191.564529,359.012604 191.113007,359.018433 190.322708,359.007812 
	C189.970535,358.059692 189.957153,357.128021 189.929749,355.848022 
	C190.398254,355.407227 190.880798,355.314758 191.708893,355.708313 
	C192.041641,357.131805 192.028839,358.069305 192.016037,359.006775 
z"/>
<path fill="#54C8E0" opacity="1.000000" stroke="none" 
	d="
M186.731262,355.095490 
	C187.192993,355.017914 187.376190,355.101349 187.812561,355.349243 
	C187.405212,355.695038 186.744659,355.876373 186.004242,355.840668 
	C186.100494,355.501251 186.276611,355.378876 186.731262,355.095490 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M85.990311,181.637573 
	C84.598984,182.330215 83.211853,182.673431 81.469719,183.039047 
	C81.297348,180.938980 81.479965,178.816513 81.832306,176.338638 
	C83.200462,176.867493 84.398903,177.751755 85.772362,178.915070 
	C85.963089,179.892120 85.978790,180.590118 85.990311,181.637573 
z"/>
<path fill="#41C6EE" opacity="1.000000" stroke="none" 
	d="
M189.851562,166.012787 
	C189.894531,165.322754 190.036392,164.577408 190.513123,163.380554 
	C190.711945,161.913467 190.575867,160.897888 190.439774,159.882309 
	C190.291016,158.841919 190.142258,157.801514 190.186676,156.211761 
	C190.523148,155.159546 190.666428,154.656677 191.160004,153.984314 
	C193.378113,153.860718 195.255829,153.801575 197.111542,153.976151 
	C200.210266,154.267685 202.626770,153.707184 203.337097,150.016754 
	C204.109833,150.012482 204.551483,150.012802 204.994507,150.429993 
	C206.674149,156.826950 208.352402,162.807037 209.686798,168.790710 
	C204.855301,169.224991 200.367661,169.655685 195.496552,170.064346 
	C193.580048,170.011292 192.047028,169.980270 190.414276,169.689697 
	C190.201279,169.283386 190.087997,169.136658 189.974701,168.989929 
	C190.031006,168.550430 190.087326,168.110947 190.328705,167.247070 
	C190.401093,166.542175 190.288437,166.261642 190.175781,165.981125 
	C190.175781,165.981125 189.950439,165.957474 189.851562,166.012787 
z"/>
<path fill="#208DB1" opacity="1.000000" stroke="none" 
	d="
M190.513992,169.949265 
	C192.047028,169.980270 193.580048,170.011292 195.283112,170.311920 
	C194.767014,171.744232 194.080856,172.906906 193.394714,174.069595 
	C194.506165,174.686996 195.555328,175.628098 196.741653,175.856354 
	C199.452118,176.377853 202.229660,176.550552 204.737244,177.020386 
	C203.972015,177.405655 203.449509,177.635498 202.927017,177.865326 
	C200.804520,177.748550 198.682007,177.631760 196.255142,177.638245 
	C195.257462,177.650879 194.564148,177.540237 193.520477,177.404587 
	C192.729172,177.205276 192.288239,177.030975 191.610474,176.843704 
	C191.012894,175.117462 190.652145,173.404175 190.260590,171.074738 
	C190.324524,170.288803 190.419250,170.119034 190.513992,169.949265 
z"/>
<path fill="#0D3543" opacity="1.000000" stroke="none" 
	d="
M190.414276,169.689697 
	C190.419250,170.119034 190.324524,170.288803 190.119568,170.728531 
	C189.864899,170.434174 189.720459,169.869843 189.775360,169.147736 
	C190.087997,169.136658 190.201279,169.283386 190.414276,169.689697 
z"/>
<path fill="#208DB1" opacity="1.000000" stroke="none" 
	d="
M190.084335,166.240967 
	C190.288437,166.261642 190.401093,166.542175 190.361130,166.915222 
	C190.136642,166.838791 190.064774,166.669800 190.084335,166.240967 
z"/>
<path fill="#45D4F6" opacity="1.000000" stroke="none" 
	d="
M147.059464,103.872002 
	C144.195602,109.485756 138.939301,108.954437 133.468231,108.989136 
	C133.569504,107.215897 134.107544,105.446922 134.817322,103.372665 
	C138.728180,103.039124 142.467300,103.010857 146.637558,103.247749 
	C147.068710,103.512901 147.059464,103.872002 147.059464,103.872002 
z"/>
<path fill="#DCF2F2" opacity="1.000000" stroke="none" 
	d="
M45.775528,146.096100 
	C45.974213,146.456284 45.945847,146.916031 45.871895,147.719818 
	C45.826302,148.063828 45.478363,148.041107 45.306145,148.029358 
	C45.272114,147.410294 45.410297,146.802979 45.775528,146.096100 
z"/>
<path fill="#9BCA03" opacity="1.000000" stroke="none" 
	d="
M453.393402,197.020416 
	C452.274078,196.835754 451.524139,196.432404 450.779144,196.441269 
	C442.950592,196.534424 437.837860,191.366257 432.537628,186.791595 
	C431.586365,185.970566 431.182983,183.095062 431.866943,182.024216 
	C437.227966,173.630737 442.962250,165.499161 444.549225,155.279373 
	C444.762817,153.904053 446.532867,152.770432 448.154358,151.360687 
	C448.821594,152.624054 449.325043,154.178177 448.929810,155.453857 
	C448.002563,158.446564 446.165924,161.221100 445.629639,164.249054 
	C445.268738,166.286758 445.802094,169.330444 447.167145,170.646393 
	C449.851776,173.234497 453.338745,175.025711 456.598236,176.971664 
	C459.544861,178.730820 462.641937,180.355606 465.359863,176.088501 
	C466.219696,176.257202 466.706665,176.376144 467.385803,176.609680 
	C468.039886,176.444519 468.501801,176.164749 469.087280,175.672760 
	C469.448212,175.235291 469.685974,175.010483 470.302124,174.823883 
	C471.434814,174.795990 472.189606,174.730362 473.088074,175.059174 
	C473.544098,177.708237 473.856415,179.962860 474.168732,182.217484 
	C474.168732,182.217484 474.204834,182.090698 474.005646,182.133820 
	C473.523499,182.356552 473.240540,182.536163 472.959442,183.042099 
	C472.968079,183.585846 472.974854,183.803284 472.988983,184.010620 
	C472.996307,184.000504 473.014252,183.982605 473.014282,183.982605 
	C470.511841,186.027222 468.153595,188.289719 465.472290,190.064011 
	C461.695374,192.563324 457.679047,194.700867 453.393402,197.020416 
z"/>
<path fill="#60B10D" opacity="1.000000" stroke="none" 
	d="
M421.598816,185.438507 
	C424.967529,189.088837 428.336243,192.739166 431.878418,196.671967 
	C429.212128,197.957382 426.372345,198.960312 422.874573,199.947876 
	C421.525330,199.488586 420.834106,199.044647 420.051544,198.290253 
	C418.417389,194.664276 416.874603,191.348724 415.637451,187.911072 
	C417.828308,187.005447 419.713562,186.221970 421.598816,185.438507 
z"/>
<path fill="#8CBF0A" opacity="1.000000" stroke="none" 
	d="
M430.466827,208.806717 
	C430.401520,210.881683 430.018463,212.824982 429.635376,214.768295 
	C427.241364,214.079346 424.847351,213.390411 422.241364,212.021332 
	C422.392487,209.497208 422.755585,207.653229 423.118652,205.809265 
	C425.462128,206.764542 427.805603,207.719803 430.466827,208.806717 
z"/>
<path fill="#8FC40C" opacity="1.000000" stroke="none" 
	d="
M423.092285,205.606903 
	C422.755585,207.653229 422.392487,209.497208 422.021484,211.744415 
	C419.691864,210.044586 417.370148,207.941544 415.460693,205.400558 
	C417.900757,204.532318 419.928558,204.102005 422.072449,203.936600 
	C422.460297,204.468063 422.732117,204.734604 423.003937,205.001129 
	C423.003937,205.001129 423.065948,205.404541 423.092285,205.606903 
z"/>
<path fill="#122020" opacity="1.000000" stroke="none" 
	d="
M421.956360,203.671677 
	C419.928558,204.102005 417.900757,204.532318 415.491760,205.041016 
	C415.110596,205.119415 415.037506,205.031097 415.187408,204.696320 
	C416.939178,202.441254 418.541046,200.520981 420.142914,198.600708 
	C420.834106,199.044647 421.525330,199.488586 422.507996,200.026489 
	C422.935425,201.071167 423.071381,202.021896 422.819580,203.218079 
	C422.273346,203.532928 422.114838,203.602295 421.956360,203.671677 
z"/>
<path fill="#102213" opacity="1.000000" stroke="none" 
	d="
M440.576294,208.063141 
	C440.083496,208.399261 439.249268,208.666733 438.067078,208.907242 
	C438.557709,208.585007 439.396301,208.289749 440.576294,208.063141 
z"/>
<path fill="#A2B529" opacity="1.000000" stroke="none" 
	d="
M474.353424,183.774475 
	C474.226868,184.233917 474.100311,184.693375 473.818726,185.476013 
	C473.473877,185.354935 473.284027,184.910675 473.054199,184.224503 
	C473.014252,183.982605 472.996307,184.000504 473.337463,183.979828 
	C473.903564,183.897583 474.128510,183.836029 474.353424,183.774475 
z"/>
<path fill="#8CBF0A" opacity="1.000000" stroke="none" 
	d="
M422.072449,203.936600 
	C422.114838,203.602295 422.273346,203.532928 422.690887,203.445404 
	C422.954224,203.689697 422.958527,203.952164 422.983398,204.607895 
	C422.732117,204.734604 422.460297,204.468063 422.072449,203.936600 
z"/>
<path fill="#18262D" opacity="1.000000" stroke="none" 
	d="
M378.998779,143.000000 
	C379.577728,142.968033 380.156708,142.936081 381.415802,142.936859 
	C382.739166,143.191010 383.382416,143.412430 384.220886,143.877197 
	C384.960968,144.409042 385.505859,144.697540 386.092224,145.277466 
	C386.498566,146.016907 386.863373,146.464920 387.208893,147.273438 
	C387.352081,148.081497 387.514618,148.529053 387.543030,149.392166 
	C387.056244,152.881119 386.703522,155.954544 386.171112,159.202332 
	C385.933289,159.695770 385.875153,160.014801 385.518005,160.509827 
	C384.649689,162.102600 384.080322,163.519409 383.218750,165.120224 
	C382.296967,166.214661 381.667419,167.125092 380.816650,168.229752 
	C381.382263,169.976562 382.169067,171.529160 382.970428,173.048553 
	C382.985016,173.015335 382.916138,173.038101 382.973846,173.373810 
	C384.943909,175.575348 386.856262,177.441208 388.815369,179.276627 
	C388.862152,179.246185 388.751648,179.230545 388.912354,179.511230 
	C390.384583,180.498367 391.696106,181.204834 393.193298,182.215790 
	C393.549744,182.685654 393.720581,182.851028 393.657532,183.345245 
	C391.839172,184.480164 390.210846,185.212387 388.685394,186.117828 
	C387.634644,186.741486 386.726379,187.605148 385.754456,188.361542 
	C386.643219,189.406387 387.395233,190.623779 388.449738,191.459503 
	C390.539062,193.115463 392.795410,194.560730 394.734985,196.318054 
	C393.396973,197.390945 391.850861,198.015747 391.305420,199.130280 
	C389.899689,202.002579 388.936737,205.091583 387.489319,207.988754 
	C385.454559,206.587708 383.727325,205.291931 381.946014,203.654236 
	C380.820374,201.377060 382.924591,197.113693 377.823303,197.704437 
	C372.732819,195.195435 367.905396,192.759613 362.882050,190.834976 
	C357.230408,188.669571 351.455353,186.774582 345.626526,185.146667 
	C343.004211,184.414322 340.007416,184.008896 337.376740,184.501968 
	C327.710083,186.313797 318.117767,188.528870 308.514191,190.667236 
	C304.591064,191.540771 301.570709,190.561310 298.662079,187.613083 
	C296.287140,185.205765 293.212067,183.375336 290.188782,181.778992 
	C289.023010,181.163437 286.275116,181.317764 285.736847,182.120117 
	C283.207733,185.889893 281.196259,190.006927 278.631348,194.117981 
	C276.011139,198.066910 278.151489,204.229706 272.013641,205.562302 
	C271.680145,200.133850 273.316864,194.534454 267.747009,191.180817 
	C267.794006,191.185242 267.750946,191.269287 267.782867,190.908417 
	C264.209656,187.386566 260.604553,184.225601 257.000061,180.659180 
	C257.334198,178.169342 257.667725,176.084976 258.332153,173.924973 
	C260.044403,172.252136 261.425720,170.654953 263.122375,169.203293 
	C271.319946,175.570892 276.115234,175.707047 286.296783,170.058044 
	C287.927429,170.243729 289.237305,170.360107 290.547180,170.476471 
	C289.609833,169.624817 288.672516,168.773163 287.835785,167.573059 
	C288.288177,165.844574 288.639954,164.464523 289.144348,162.737427 
	C289.271576,161.175125 289.246155,159.959839 289.348877,158.562256 
	C289.476959,158.379959 289.721069,158.007172 290.012726,158.247314 
	C290.818451,160.257523 291.332520,162.027573 291.976410,164.244614 
	C293.281708,163.279510 294.153229,162.635147 295.162964,162.307281 
	C298.544983,166.360214 301.788757,170.096680 305.018127,174.211395 
	C303.901794,176.117844 303.029846,177.906860 301.651917,179.122147 
	C298.368896,182.017685 298.635376,185.307266 302.559418,187.241150 
	C306.535461,189.200653 310.259796,187.680786 312.139160,183.573242 
	C312.712738,182.319550 313.761353,180.650040 314.881012,180.383591 
	C321.428192,178.825531 326.447601,175.226593 330.577576,169.949036 
	C331.215424,170.364761 331.609955,170.694885 332.150421,171.226501 
	C332.532898,171.674210 332.815704,171.842865 333.106018,171.921036 
	C333.067291,171.908096 333.128510,171.854034 333.091125,172.260498 
	C332.446228,174.281204 331.648834,175.854950 331.293060,177.522888 
	C331.022827,178.789703 330.973541,180.331070 331.482513,181.452225 
	C331.792908,182.135956 333.418976,182.692490 334.329834,182.543961 
	C336.889618,182.126602 339.385040,181.314285 342.338837,180.544952 
	C342.294495,179.845215 342.193237,178.247498 342.515137,176.668961 
	C350.255432,178.785233 357.294739,178.093079 364.396790,174.523071 
	C365.506226,173.983032 366.272400,173.504715 367.018311,173.014008 
	C366.998016,173.001617 367.024567,173.041336 367.361389,172.976440 
	C372.509033,169.952332 376.081268,166.134399 376.903015,160.012848 
	C379.364197,159.053757 381.462891,158.239944 383.311157,157.523254 
	C381.802490,152.442413 380.400635,147.721207 378.998779,143.000000 
z"/>
<path fill="#111B1F" opacity="1.000000" stroke="none" 
	d="
M374.164673,114.065933 
	C375.101074,115.184303 375.748444,116.121017 376.466675,117.335373 
	C375.180756,116.765999 373.917480,115.503098 372.444733,115.171944 
	C370.369629,114.705338 368.139252,114.929024 365.975281,114.857559 
	C366.555664,116.635689 366.849854,118.583771 367.781830,120.153084 
	C369.272369,122.663002 371.186157,124.921539 372.807922,127.774429 
	C372.694855,128.262512 372.371948,128.539276 372.141602,128.294861 
	C369.949066,126.003044 367.986847,123.955650 365.981384,121.560966 
	C364.541840,120.139862 363.265137,118.834335 361.724945,118.039154 
	C358.218567,116.228828 354.582855,114.668991 351.013672,112.613983 
	C351.217224,110.674744 351.406769,109.128891 351.583923,107.684021 
	C345.068665,105.525734 340.806519,107.872124 337.286255,113.579247 
	C339.147217,113.786621 340.705292,113.960251 341.999664,114.454521 
	C338.606995,116.205315 335.414001,117.513466 332.372498,119.110138 
	C330.465942,120.110985 328.787109,121.545624 326.991150,122.428665 
	C324.911835,118.318062 322.197632,114.811165 322.743286,110.415436 
	C325.138855,110.624146 327.421021,111.099892 329.327759,110.458862 
	C333.977203,108.895767 338.452728,106.815262 342.998260,104.943100 
	C343.746063,104.898270 344.493896,104.853432 345.915649,104.755280 
	C347.411560,104.518776 348.233490,104.335594 349.055420,104.152420 
	C350.108795,104.187141 351.162140,104.221863 352.845337,104.302643 
	C353.650818,104.301781 353.826538,104.254845 354.002228,104.207909 
	C354.545990,104.352608 355.089752,104.497307 356.169739,104.867104 
	C359.479340,105.856239 362.252716,106.620270 365.026123,107.384308 
	C365.975159,107.949753 366.924164,108.515198 368.327209,109.396172 
	C369.142212,109.940292 369.503143,110.168877 369.864105,110.397461 
	C371.201294,111.559738 372.538452,112.722008 374.164673,114.065933 
z"/>
<path fill="#132228" opacity="1.000000" stroke="none" 
	d="
M378.617004,142.976685 
	C380.400635,147.721207 381.802490,152.442413 383.311157,157.523254 
	C381.462891,158.239944 379.364197,159.053757 376.717224,159.784363 
	C375.807770,158.862213 375.446533,158.023254 374.800232,157.050964 
	C373.978027,156.641235 373.440857,156.364853 372.864807,155.741714 
	C370.895325,154.221542 368.964691,153.048126 367.018799,151.567566 
	C367.018158,150.645721 367.032776,150.031021 367.331665,149.217621 
	C368.522736,148.076218 369.429565,147.133484 370.700470,146.161835 
	C372.126678,143.422180 373.188782,140.711456 374.542358,138.001221 
	C375.222168,138.001801 375.610535,138.001862 375.998901,138.001923 
	C376.744324,139.652420 377.489777,141.302902 378.617004,142.976685 
z"/>
<path fill="#E167B4" opacity="1.000000" stroke="none" 
	d="
M393.968994,126.169617 
	C394.136475,126.151390 394.303925,126.133171 394.718597,126.353706 
	C394.965851,126.592461 394.976501,127.073265 394.850891,127.288017 
	C394.507874,127.761971 394.244537,127.960083 393.718140,128.191422 
	C393.229828,128.462021 393.009247,128.687836 392.658813,129.067230 
	C392.257874,129.346359 392.086304,129.560547 391.654724,129.871796 
	C390.581757,130.265289 389.817596,130.600800 388.816162,131.042618 
	C388.373322,131.419754 388.167755,131.690598 387.950500,131.944641 
	C387.938782,131.927841 387.900879,131.908844 387.565247,131.945557 
	C385.936737,132.287140 384.643829,132.592026 383.350922,132.896912 
	C384.201294,131.019485 385.051666,129.142044 386.337646,126.841820 
	C387.213104,125.966980 387.652863,125.514923 388.092651,125.062866 
	C388.092651,125.062866 388.014374,125.018318 388.328125,125.020920 
	C388.836609,125.014885 389.031372,125.006233 389.207458,125.351570 
	C390.375671,130.047806 392.274597,127.055244 393.968994,126.169617 
z"/>
<path fill="#EF3B85" opacity="1.000000" stroke="none" 
	d="
M383.290222,133.302765 
	C384.643829,132.592026 385.936737,132.287140 387.547668,132.302612 
	C386.249054,136.175858 381.735382,138.718414 384.025635,143.633850 
	C383.382416,143.412430 382.739166,143.191010 381.847504,142.883820 
	C382.142548,139.768219 382.686035,136.738419 383.290222,133.302765 
z"/>
<path fill="#111B1F" opacity="1.000000" stroke="none" 
	d="
M372.856567,128.124405 
	C374.531036,128.547409 376.043823,129.108521 377.687195,129.718063 
	C377.098358,131.658936 376.554596,133.451202 375.698608,135.623550 
	C374.978790,135.996719 374.571228,135.989807 374.092529,135.740601 
	C373.983582,135.334274 373.945770,135.170242 374.000610,134.636383 
	C373.519531,132.357468 372.945740,130.448380 372.371948,128.539276 
	C372.371948,128.539276 372.694855,128.262512 372.856567,128.124405 
z"/>
<path fill="#17252C" opacity="1.000000" stroke="none" 
	d="
M389.226105,124.997589 
	C389.031372,125.006233 388.836609,125.014885 388.351685,125.059303 
	C389.115845,124.061485 390.170197,123.027893 391.667114,122.062241 
	C392.109680,122.130180 392.276550,122.318893 392.111450,122.569466 
	C391.039581,123.545891 390.132843,124.271744 389.226105,124.997589 
z"/>
<path fill="#111B1F" opacity="1.000000" stroke="none" 
	d="
M376.411255,117.906464 
	C377.249023,118.512680 377.976440,119.473320 378.826477,120.719345 
	C378.066345,120.090126 377.183624,119.175514 376.411255,117.906464 
z"/>
<path fill="#17252C" opacity="1.000000" stroke="none" 
	d="
M395.413086,120.309845 
	C395.339386,120.779251 394.867950,121.151962 393.904419,121.756454 
	C393.033691,122.098450 392.655121,122.208672 392.276550,122.318893 
	C392.276550,122.318893 392.109680,122.130180 392.026184,122.035210 
	C392.966949,121.364548 393.991180,120.788857 395.413086,120.309845 
z"/>
<path fill="#18262D" opacity="1.000000" stroke="none" 
	d="
M374.163696,135.982880 
	C374.571228,135.989807 374.978790,135.996719 375.692352,136.005249 
	C375.998932,136.449890 375.999481,136.892899 375.999481,137.668915 
	C375.610535,138.001862 375.222168,138.001801 374.456360,137.670959 
	C374.107147,136.887772 374.135406,136.435318 374.163696,135.982880 
z"/>
<path fill="#17252C" opacity="1.000000" stroke="none" 
	d="
M387.753540,125.123596 
	C387.652863,125.514923 387.213104,125.966980 386.485291,126.561119 
	C386.602997,126.196907 387.008698,125.690620 387.753540,125.123596 
z"/>
<path fill="#FB418F" opacity="1.000000" stroke="none" 
	d="
M384.220886,143.877197 
	C381.735382,138.718414 386.249054,136.175858 387.883301,132.265900 
	C387.900879,131.908844 387.938782,131.927841 388.172302,131.821075 
	C388.621735,131.454987 388.837585,131.195663 389.053436,130.936340 
	C389.817596,130.600800 390.581757,130.265289 391.612518,130.284790 
	C391.436981,133.084793 390.846741,135.513840 390.581573,137.977875 
	C389.893921,144.368073 392.118988,146.032227 398.374786,144.404938 
	C402.494873,143.333206 406.743011,142.755875 410.931091,141.943008 
	C414.345795,141.280273 418.016388,141.002258 419.108124,136.706100 
	C419.831635,136.786331 420.555115,136.866562 421.657990,137.433655 
	C422.118530,138.495560 422.199707,139.070602 422.280884,139.645660 
	C420.888000,142.698776 420.656281,145.428741 424.699280,147.153900 
	C429.577332,148.920273 426.543427,152.178604 426.545135,154.745667 
	C426.546600,156.998840 424.434357,159.701096 428.432556,161.248291 
	C425.910706,164.417267 423.464661,167.694168 420.315948,170.019684 
	C414.236328,174.509872 407.925262,177.335876 399.648682,174.698502 
	C393.539673,172.751831 389.567169,168.678467 385.044922,164.552032 
	C385.305115,162.888748 385.561066,161.611298 385.817017,160.333847 
	C385.875153,160.014801 385.933289,159.695770 386.494019,159.076279 
	C388.965881,155.630432 390.309784,152.441605 387.677124,148.976624 
	C387.514618,148.529053 387.352081,148.081497 387.368164,147.074432 
	C387.048035,146.005295 386.549377,145.495667 386.050720,144.986023 
	C385.505859,144.697540 384.960968,144.409042 384.220886,143.877197 
z"/>
<path fill="#FC4B94" opacity="1.000000" stroke="none" 
	d="
M418.908752,136.453003 
	C418.016388,141.002258 414.345795,141.280273 410.931091,141.943008 
	C406.743011,142.755875 402.494873,143.333206 398.374786,144.404938 
	C392.118988,146.032227 389.893921,144.368073 390.581573,137.977875 
	C390.846741,135.513840 391.436981,133.084793 391.921326,130.226807 
	C392.086304,129.560547 392.257874,129.346359 392.883698,128.977997 
	C393.573029,128.619843 393.788391,128.390640 393.935242,128.097122 
	C394.244537,127.960083 394.507874,127.761971 395.165619,127.224525 
	C396.440735,126.317345 397.275482,125.688408 398.110199,125.059464 
	C400.727631,124.668762 403.333893,124.158997 405.964844,123.912354 
	C411.332245,123.409157 416.022491,124.981209 420.244232,129.189865 
	C416.280243,130.797501 414.930634,133.095184 418.908752,136.453003 
z"/>
<path fill="#E4BDCF" opacity="1.000000" stroke="none" 
	d="
M392.111450,122.569473 
	C392.655121,122.208672 393.033691,122.098450 393.709930,121.993958 
	C393.986450,123.110130 393.965332,124.220558 393.956604,125.750305 
	C392.274597,127.055244 390.375671,130.047806 389.207458,125.351578 
	C390.132843,124.271744 391.039581,123.545891 392.111450,122.569473 
z"/>
<path fill="#FC4B94" opacity="1.000000" stroke="none" 
	d="
M422.649109,139.569763 
	C422.199707,139.070602 422.118530,138.495560 422.018188,137.487335 
	C424.707825,134.136261 426.012360,135.336044 426.742737,138.946182 
	C425.443848,139.137131 424.230621,139.315506 422.649109,139.569763 
z"/>
<path fill="#E4BDCF" opacity="1.000000" stroke="none" 
	d="
M397.758057,125.025223 
	C397.275482,125.688408 396.440735,126.317345 395.291260,127.009773 
	C394.976501,127.073265 394.965851,126.592461 394.971252,126.352661 
	C395.786407,125.738899 396.596191,125.364937 397.758057,125.025223 
z"/>
<path fill="#18262D" opacity="1.000000" stroke="none" 
	d="
M420.051544,198.290253 
	C418.541046,200.520981 416.939178,202.441254 415.151794,204.651428 
	C413.497101,204.585403 412.027954,204.229492 410.188538,203.440735 
	C409.818298,202.088348 409.818298,201.168839 409.818298,200.428589 
	C406.293854,201.661728 402.980713,202.820953 399.281006,203.982391 
	C395.601715,204.238403 394.441437,202.951706 395.560822,199.679153 
	C395.961334,198.508347 395.841766,197.159637 396.128967,195.533356 
	C397.587372,195.023499 398.876404,194.872406 400.519287,194.883850 
	C402.122772,194.727402 403.372375,194.408401 404.621948,194.089386 
	C403.990479,192.727234 403.358978,191.365097 402.609009,189.625305 
	C401.130676,186.896606 401.128998,185.384354 404.485016,185.129440 
	C407.344940,184.912216 410.169159,184.224152 412.866333,184.072845 
	C411.896942,185.183121 410.962036,185.879837 410.266815,186.764847 
	C408.914459,188.486404 406.410858,190.572433 406.706329,192.039902 
	C407.226776,194.624695 409.490448,196.858490 411.670593,200.242538 
	C413.228607,195.046890 414.280212,191.540039 415.331818,188.033188 
	C416.874603,191.348724 418.417389,194.664276 420.051544,198.290253 
z"/>
<path fill="#E0E6E7" opacity="1.000000" stroke="none" 
	d="
M416.354706,226.462524 
	C410.145935,235.201691 404.098419,243.622910 397.954803,251.973495 
	C397.353638,252.790634 396.161316,253.172852 394.927307,253.592117 
	C394.611237,253.427292 394.597931,253.390442 394.457214,253.027863 
	C395.532623,249.439651 396.748779,246.214035 398.146057,242.866119 
	C398.327240,242.743820 398.401886,242.313080 398.312683,242.255524 
	C398.223480,242.197968 398.357880,242.362335 398.573059,242.242737 
	C399.036316,241.871933 399.143127,241.570648 399.370789,241.071930 
	C399.793915,240.361267 399.955017,239.797958 400.423523,239.073212 
	C403.346405,235.356476 405.961914,231.801178 408.577454,228.245880 
	C411.139008,225.015732 413.610565,221.706787 416.323303,218.608994 
	C417.030426,217.801529 418.489044,217.652161 419.602478,217.200531 
	C419.612274,218.491440 419.995667,219.933975 419.547211,221.038895 
	C418.812256,222.849640 417.536652,224.440994 416.354706,226.462524 
z"/>
<path fill="#62BEDB" opacity="1.000000" stroke="none" 
	d="
M399.667542,203.980164 
	C402.980713,202.820953 406.293854,201.661728 409.818298,200.428589 
	C409.818298,201.168839 409.818298,202.088348 409.812622,203.376068 
	C409.806976,203.744263 409.834839,203.757523 409.585999,203.881378 
	C409.260071,204.333481 409.182953,204.661743 409.105835,204.990005 
	C410.308655,208.553726 411.511475,212.117432 412.488983,216.174591 
	C412.054596,217.055511 411.845490,217.442963 411.636414,217.830429 
	C411.636414,217.830429 411.806732,217.810745 411.528564,217.853699 
	C411.071716,218.214859 410.893036,218.533035 410.714355,218.851212 
	C410.714355,218.851212 410.815308,218.842545 410.544403,218.923218 
	C409.975250,219.471558 409.676971,219.939224 409.378693,220.406891 
	C409.378693,220.406891 409.445099,220.496048 409.180481,220.591187 
	C408.659210,220.896286 408.516296,221.166428 408.487122,221.496765 
	C408.487122,221.496765 408.483582,221.574799 408.170624,221.730576 
	C405.316864,225.469559 402.776062,229.052765 400.118774,232.316391 
	C399.477417,231.615982 398.952576,231.235153 398.330383,230.505341 
	C398.152618,229.437424 398.072205,228.718506 398.262573,227.776001 
	C403.056213,219.894424 405.129059,212.118546 399.667542,203.980164 
z"/>
<path fill="#112026" opacity="1.000000" stroke="none" 
	d="
M400.235291,232.635986 
	C402.776062,229.052765 405.316864,225.469559 408.135040,221.714462 
	C408.271149,223.646454 408.129883,225.750351 408.283020,228.050049 
	C405.961914,231.801178 403.346405,235.356476 400.349121,238.723236 
	C399.918243,238.301178 399.869171,238.067657 399.862030,237.481873 
	C399.950134,235.931854 399.996277,234.734100 400.155579,233.325165 
	C400.268707,233.113983 400.235291,232.635986 400.235291,232.635986 
z"/>
<path fill="#112026" opacity="1.000000" stroke="none" 
	d="
M409.707123,220.406189 
	C409.676971,219.939224 409.975250,219.471558 410.524689,218.876556 
	C410.529114,219.301315 410.282318,219.853409 409.707123,220.406189 
z"/>
<path fill="#112026" opacity="1.000000" stroke="none" 
	d="
M411.931885,217.664383 
	C411.845490,217.442963 412.054596,217.055511 412.550293,216.489319 
	C412.633728,216.706512 412.430573,217.102417 411.931885,217.664383 
z"/>
<path fill="#112026" opacity="1.000000" stroke="none" 
	d="
M410.971893,218.735199 
	C410.893036,218.533035 411.071716,218.214859 411.501648,217.772324 
	C411.578400,217.971725 411.403931,218.295471 410.971893,218.735199 
z"/>
<path fill="#112026" opacity="1.000000" stroke="none" 
	d="
M408.763672,221.474365 
	C408.516296,221.166428 408.659210,220.896286 409.125488,220.583069 
	C409.236847,220.803879 409.138550,221.127914 408.763672,221.474365 
z"/>
<path fill="#18262D" opacity="1.000000" stroke="none" 
	d="
M409.283752,204.825989 
	C409.182953,204.661743 409.260071,204.333481 409.576721,203.890732 
	C409.800934,204.112671 409.682739,204.407913 409.283752,204.825989 
z"/>
<path fill="#A7D10C" opacity="1.000000" stroke="none" 
	d="
M464.987000,176.038757 
	C462.641937,180.355606 459.544861,178.730820 456.598236,176.971664 
	C453.338745,175.025711 449.851776,173.234497 447.167145,170.646393 
	C445.802094,169.330444 445.268738,166.286758 445.629639,164.249054 
	C446.165924,161.221100 448.002563,158.446564 448.929810,155.453857 
	C449.325043,154.178177 448.821594,152.624054 448.392639,151.106873 
	C448.058685,151.017288 448.043365,150.565109 448.057587,150.339310 
	C454.013367,149.663712 458.993164,151.938721 463.709534,156.115189 
	C463.941925,157.090866 463.963196,157.509689 463.621307,157.831909 
	C462.444244,158.904694 460.678131,160.526718 460.981506,161.164886 
	C462.280670,163.897736 464.189728,166.340637 465.877380,168.904724 
	C465.878387,168.920197 465.908844,168.915298 465.655396,168.982590 
	C465.263397,169.351303 465.124817,169.652725 464.772583,170.242310 
	C464.701630,172.366562 464.844330,174.202667 464.987000,176.038757 
z"/>
<path fill="#122A35" opacity="1.000000" stroke="none" 
	d="
M415.637451,187.911072 
	C414.280212,191.540039 413.228607,195.046890 411.670593,200.242538 
	C409.490448,196.858490 407.226776,194.624695 406.706329,192.039902 
	C406.410858,190.572433 408.914459,188.486404 410.266815,186.764847 
	C410.962036,185.879837 411.896942,185.183121 412.973938,184.049957 
	C413.224335,183.698593 413.347107,183.882050 413.663086,183.800140 
	C414.308868,183.444519 414.638702,183.170792 414.941620,182.920013 
	C414.914703,182.942963 414.896881,182.874542 415.286072,182.910217 
	C418.427246,180.927719 421.179291,178.909515 423.942688,176.910843 
	C423.954041,176.930374 423.912292,176.906937 424.366455,176.931030 
	C426.957855,176.993576 429.095123,177.032028 431.232391,177.070465 
	C428.170868,179.704971 425.109344,182.339478 421.823303,185.206238 
	C419.713562,186.221970 417.828308,187.005447 415.637451,187.911072 
z"/>
<path fill="#18262D" opacity="1.000000" stroke="none" 
	d="
M431.512665,176.920837 
	C429.095123,177.032028 426.957855,176.993576 424.487183,176.653442 
	C424.521729,175.878113 424.889709,175.404465 425.405640,174.851654 
	C425.744629,174.640167 425.872833,174.462524 425.977600,174.126038 
	C426.016968,174.012558 426.242279,173.929199 426.408020,173.828430 
	C426.773651,173.549728 426.907928,173.331360 426.975281,173.031281 
	C426.974030,172.989990 427.056427,172.983994 427.368286,172.927902 
	C428.148682,172.017792 428.617279,171.163773 429.073730,170.204758 
	C429.061615,170.099747 429.248566,170.001022 429.248566,170.001022 
	C431.115387,170.451157 432.982178,170.901306 434.969482,171.702209 
	C433.990936,173.625687 432.891937,175.198441 431.512665,176.920837 
z"/>
<path fill="#E3D060" opacity="1.000000" stroke="none" 
	d="
M463.984497,157.928513 
	C463.963196,157.509689 463.941925,157.090866 463.951263,156.358856 
	C465.945679,158.810715 467.909454,161.575760 470.031921,165.032379 
	C470.541840,167.487762 470.893066,169.251556 470.901733,171.027695 
	C470.332550,171.058563 470.105927,171.077103 469.625122,170.925797 
	C468.548584,170.171768 467.726227,169.587601 466.901611,168.579498 
	C465.927704,164.746536 464.956085,161.337524 463.984497,157.928513 
z"/>
<path fill="#F9F7CC" opacity="1.000000" stroke="none" 
	d="
M471.244293,171.015350 
	C470.893066,169.251556 470.541840,167.487762 470.217529,165.335098 
	C470.873077,164.950089 471.501678,164.953949 472.682556,164.998047 
	C473.234802,165.038300 473.714081,165.060837 473.785583,165.482910 
	C473.687378,167.862122 473.517700,169.819244 473.124725,171.752457 
	C472.349060,171.490814 471.796692,171.253082 471.244293,171.015350 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M473.714081,165.060837 
	C473.714081,165.060837 473.234802,165.038300 472.998108,164.989716 
	C472.763153,164.202087 472.764923,163.463043 472.676941,162.144897 
	C472.463898,161.293732 472.276825,161.076492 472.026001,160.914108 
	C471.976471,159.895660 471.926941,158.877228 471.904053,157.475372 
	C472.564026,158.144302 473.197327,159.196625 473.864410,160.984741 
	C473.836853,162.833984 473.775452,163.947403 473.714081,165.060837 
z"/>
<path fill="#F9F7CC" opacity="1.000000" stroke="none" 
	d="
M472.088501,161.118881 
	C472.276825,161.076492 472.463898,161.293732 472.628540,161.776657 
	C472.445282,161.806610 472.272308,161.585312 472.088501,161.118881 
z"/>
<path fill="#F42578" opacity="1.000000" stroke="none" 
	d="
M425.257690,174.930817 
	C424.889709,175.404465 424.521729,175.878113 424.033020,176.629333 
	C423.912292,176.906937 423.954041,176.930374 423.595856,176.968689 
	C421.814606,177.698669 420.391510,178.390350 418.778015,179.194000 
	C418.348663,179.492188 418.174744,179.726639 417.904846,180.221863 
	C417.575592,180.491730 417.407379,180.549103 416.961914,180.260498 
	C411.625122,179.078033 406.284607,176.532608 402.520386,182.747498 
	C402.014618,183.582581 400.221313,183.637833 398.778625,183.819031 
	C396.985870,183.394714 395.438629,183.205551 393.891418,183.016403 
	C393.720581,182.851028 393.549744,182.685654 393.125702,181.865997 
	C391.498871,180.551331 390.125244,179.890930 388.751648,179.230545 
	C388.751648,179.230545 388.862152,179.246185 388.846130,178.908417 
	C386.858795,176.726456 384.887482,174.882278 382.916138,173.038101 
	C382.916138,173.038101 382.985016,173.015335 382.974854,172.690796 
	C382.322388,170.922684 381.680115,169.479095 381.037842,168.035522 
	C381.667419,167.125092 382.296967,166.214661 383.472229,165.131805 
	C384.358856,164.952209 384.699768,164.945053 385.040710,164.937881 
	C389.567169,168.678467 393.539673,172.751831 399.648682,174.698502 
	C407.925262,177.335876 414.236328,174.509872 420.315948,170.019684 
	C423.464661,167.694168 425.910706,164.417267 428.819946,161.270447 
	C430.426910,159.327835 431.888184,157.684967 433.666595,155.927948 
	C432.386932,160.301331 430.790161,164.788849 429.220978,169.638702 
	C429.248566,170.001022 429.061615,170.099747 428.780762,170.277649 
	C428.018707,171.298370 427.537567,172.141174 427.056427,172.983994 
	C427.056427,172.983994 426.974030,172.989990 426.760376,173.076630 
	C426.259705,173.344849 426.158203,173.600159 426.242279,173.929199 
	C426.242279,173.929199 426.016968,174.012558 425.777863,174.127960 
	C425.251801,174.393295 425.158112,174.622452 425.257690,174.930817 
z"/>
<path fill="#17252C" opacity="1.000000" stroke="none" 
	d="
M437.005432,154.005295 
	C436.959686,154.655396 436.909210,155.300842 436.858734,155.946289 
	C436.651947,155.909637 436.445190,155.872971 436.238434,155.836319 
	C436.359863,155.339676 436.481293,154.843033 436.797302,154.170685 
	C436.991852,153.994980 437.000671,154.000641 437.005432,154.005295 
z"/>
<path fill="#A2B529" opacity="1.000000" stroke="none" 
	d="
M473.785583,165.482910 
	C473.775452,163.947403 473.836853,162.833984 473.929779,161.296112 
	C474.728149,162.162933 475.494995,163.454178 476.487366,164.978958 
	C476.096710,170.801392 475.480499,176.390259 474.516510,182.098312 
	C473.856415,179.962860 473.544098,177.708237 473.084595,174.765564 
	C473.074310,173.310471 473.211151,172.543411 473.348022,171.776367 
	C473.517700,169.819244 473.687378,167.862122 473.785583,165.482910 
z"/>
<path fill="#A2B529" opacity="1.000000" stroke="none" 
	d="
M472.957581,182.715790 
	C473.240540,182.536163 473.523499,182.356552 474.065125,182.162170 
	C474.396057,182.364182 474.468262,182.580948 474.130341,182.924103 
	C473.465973,182.938919 473.211761,182.827347 472.957581,182.715790 
z"/>
<path fill="#5CA00A" opacity="1.000000" stroke="none" 
	d="
M472.959442,183.042084 
	C473.211761,182.827347 473.465973,182.938919 474.083405,183.263596 
	C474.446625,183.476685 474.466431,183.650284 474.409912,183.712372 
	C474.128510,183.836029 473.903564,183.897583 473.330139,183.989929 
	C472.974854,183.803284 472.968079,183.585846 472.959442,183.042084 
z"/>
<path fill="#142931" opacity="1.000000" stroke="none" 
	d="
M406.020844,280.398590 
	C407.998535,276.935913 410.006714,273.872742 412.225006,270.489014 
	C411.413055,269.784912 410.218018,268.748657 408.850525,267.425659 
	C413.034424,266.393951 417.390717,265.648956 421.869446,264.576355 
	C423.720917,264.165161 425.449890,264.081573 427.595612,264.126953 
	C429.646057,264.997589 431.307373,265.684814 432.905945,266.495758 
	C434.574188,267.342041 436.182007,268.307495 437.490112,269.731262 
	C431.354431,271.392273 425.544861,272.542877 419.389191,273.780212 
	C416.827911,275.731262 414.137848,277.283478 412.614166,279.602142 
	C411.934479,280.636444 413.427979,283.309265 414.372589,284.993042 
	C414.529633,285.272980 417.050476,284.507812 418.277893,283.850281 
	C421.222961,282.272644 424.048462,280.471893 427.133026,279.062225 
	C427.620789,282.053650 427.897736,284.744019 428.174683,287.434387 
	C427.542786,288.106506 426.910858,288.778625 425.810059,289.705475 
	C424.556580,290.258453 423.771973,290.556702 422.987335,290.854950 
	C421.847626,290.938232 420.684113,290.902039 419.572052,291.123962 
	C411.509216,292.732880 406.573944,288.983612 406.020844,280.398590 
z"/>
<path fill="#1B2B35" opacity="1.000000" stroke="none" 
	d="
M460.275146,282.446045 
	C460.034637,282.505798 459.695740,282.271362 459.279602,281.726624 
	C459.527161,281.661469 459.851959,281.906677 460.275146,282.446045 
z"/>
<path fill="#1B2B35" opacity="1.000000" stroke="none" 
	d="
M461.287170,283.431824 
	C461.054626,283.520416 460.734589,283.351196 460.354126,282.903564 
	C460.595642,282.808105 460.897644,282.991058 461.287170,283.431824 
z"/>
<path fill="#C8B93E" opacity="1.000000" stroke="none" 
	d="
M453.686218,302.422974 
	C453.170776,303.298645 452.683746,303.881073 452.100586,304.734619 
	C450.439392,304.251404 448.874390,303.497070 447.446259,302.109619 
	C447.864899,300.374207 448.146729,299.271851 448.444214,298.158020 
	C448.459869,298.146545 448.495697,298.165497 448.495697,298.165497 
	C448.767395,297.494720 449.039093,296.823975 449.431580,295.740112 
	C449.552368,295.327026 449.914734,295.024811 449.914734,295.024811 
	C451.145355,296.667084 452.375977,298.309357 453.866577,300.202362 
	C453.989258,301.011963 453.851959,301.570862 453.686218,302.422974 
z"/>
<path fill="#1B3640" opacity="1.000000" stroke="none" 
	d="
M445.989197,302.750122 
	C445.786560,303.000336 445.603210,303.005157 445.143860,303.020325 
	C444.391235,301.823639 443.914581,300.616547 443.191254,299.186462 
	C443.009888,298.541046 443.075195,298.118683 443.563416,297.261780 
	C444.687469,294.551514 445.388580,292.275818 446.089722,290.000092 
	C446.808838,292.437164 447.527924,294.874237 448.371368,297.738403 
	C448.495697,298.165497 448.459869,298.146545 448.114258,298.277649 
	C447.192505,299.610260 446.616333,300.811798 446.040161,302.013336 
	C446.040161,302.013336 446.008423,302.504700 445.989197,302.750122 
z"/>
<path fill="#2D5662" opacity="1.000000" stroke="none" 
	d="
M445.814453,289.762024 
	C445.388580,292.275818 444.687469,294.551514 443.678040,296.961304 
	C443.291168,296.660675 443.212585,296.225952 443.324493,295.174988 
	C443.258148,292.790222 443.001343,291.021729 442.744537,289.253235 
	C443.676086,289.343475 444.607666,289.433685 445.814453,289.762024 
z"/>
<path fill="#5297AD" opacity="1.000000" stroke="none" 
	d="
M442.460632,289.313080 
	C443.001343,291.021729 443.258148,292.790222 443.311218,294.841492 
	C441.975769,294.083557 440.844086,293.042877 439.347717,291.971008 
	C440.047607,291.084229 441.112152,290.228577 442.460632,289.313080 
z"/>
<path fill="#1B3640" opacity="1.000000" stroke="none" 
	d="
M444.382294,285.687744 
	C444.239838,286.004395 443.960968,286.176544 443.682098,286.348663 
	C443.870026,286.080200 444.057953,285.811737 444.382294,285.687744 
z"/>
<path fill="#1B3640" opacity="1.000000" stroke="none" 
	d="
M449.891724,294.622437 
	C449.914734,295.024811 449.552368,295.327026 449.361084,295.466278 
	C449.252014,294.289856 449.334198,292.974182 449.650452,291.346436 
	C449.879272,292.096252 449.873993,293.158173 449.891724,294.622437 
z"/>
<path fill="#182935" opacity="1.000000" stroke="none" 
	d="
M449.081970,282.106689 
	C449.401642,282.313904 449.728424,282.829254 449.993164,283.667908 
	C449.650421,283.465729 449.369781,282.940247 449.081970,282.106689 
z"/>
<path fill="#1A2B2B" opacity="1.000000" stroke="none" 
	d="
M428.511719,287.325684 
	C427.897736,284.744019 427.620789,282.053650 427.383911,278.834595 
	C429.328308,277.470612 431.232605,276.635284 433.460571,275.820618 
	C434.202789,275.905731 434.621307,275.970215 435.303101,276.282959 
	C437.678101,275.852844 439.789825,275.174438 441.977814,274.291290 
	C442.231781,273.835419 442.471649,273.680481 442.773621,273.621796 
	C442.906311,274.192596 443.039001,274.763397 443.348633,276.196777 
	C438.633301,280.445221 433.740997,283.831085 428.511719,287.325684 
z"/>
<path fill="#5297AD" opacity="1.000000" stroke="none" 
	d="
M423.193604,291.127380 
	C423.771973,290.556702 424.556580,290.258453 425.662292,289.941711 
	C425.999908,290.361450 426.016510,290.799713 426.018341,291.565277 
	C425.135681,291.728333 424.267792,291.564087 423.193604,291.127380 
z"/>
<path fill="#F8CD0D" opacity="1.000000" stroke="none" 
	d="
M461.056091,255.054932 
	C460.941132,255.464859 460.783630,255.837219 460.626099,256.209595 
	C460.609009,255.878204 460.591919,255.546829 460.749939,255.080978 
	C460.924988,254.946487 461.013580,255.017349 461.056091,255.054932 
z"/>
<path fill="#C8B93E" opacity="1.000000" stroke="none" 
	d="
M435.039825,276.034668 
	C434.621307,275.970215 434.202789,275.905731 433.487061,275.477905 
	C434.392242,272.030670 432.607178,271.768646 430.295227,272.098938 
	C426.771271,272.602448 423.254913,273.159302 419.735321,273.693481 
	C425.544861,272.542877 431.354431,271.392273 437.583374,270.068909 
	C439.569153,270.925812 441.135498,271.955536 442.737732,273.303528 
	C442.471649,273.680481 442.231781,273.835419 441.581909,274.356781 
	C439.086426,275.096222 437.063141,275.565430 435.039825,276.034668 
z"/>
<path fill="#A99688" opacity="1.000000" stroke="none" 
	d="
M442.025024,250.205139 
	C442.421539,250.289230 442.838440,250.694275 443.326111,251.387756 
	C442.946381,251.292831 442.495880,250.909470 442.025024,250.205139 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M485.126404,280.712402 
	C484.980072,280.750854 484.657379,280.569183 484.182678,280.151611 
	C484.337128,280.107941 484.643555,280.300110 485.126404,280.712402 
z"/>
<path fill="#6CBED3" opacity="1.000000" stroke="none" 
	d="
M401.857391,264.939575 
	C401.336884,263.810364 400.816376,262.681152 400.134857,261.275208 
	C399.734711,260.171661 399.495605,259.344879 399.045135,258.263672 
	C399.113708,257.296112 399.393677,256.583008 400.195679,255.522018 
	C400.992950,255.121552 401.268097,255.068954 401.543274,255.016373 
	C403.039154,255.980057 404.535004,256.943756 406.371338,257.912537 
	C406.831696,259.974609 406.951538,262.031555 407.069489,264.530579 
	C405.825653,264.854431 404.583710,264.736145 402.860718,264.807129 
	C402.205566,264.977509 402.031494,264.958557 401.857391,264.939575 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M403.282837,252.258453 
	C403.355713,252.500900 403.123138,252.862152 402.580811,253.334183 
	C402.506439,253.089050 402.741913,252.733139 403.282837,252.258453 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M402.162170,253.401779 
	C402.256653,253.649292 402.069061,253.963806 401.610291,254.360687 
	C401.519440,254.118286 401.699738,253.793533 402.162170,253.401779 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M401.564850,254.843643 
	C401.268097,255.068954 400.992950,255.121552 400.301697,255.248611 
	C400.210388,255.028763 400.535126,254.734482 401.187500,254.385147 
	C401.515198,254.330109 401.586426,254.670914 401.564850,254.843643 
z"/>
<path fill="#6FCAE3" opacity="1.000000" stroke="none" 
	d="
M413.270081,250.002274 
	C413.272797,249.813477 413.530121,249.686859 413.890259,249.694626 
	C413.836975,249.907486 413.680847,249.985977 413.270081,250.002274 
z"/>
<path fill="#162225" opacity="1.000000" stroke="none" 
	d="
M322.459137,110.174461 
	C322.197632,114.811165 324.911835,118.318062 326.649902,122.599335 
	C325.203430,125.654831 324.083252,128.182709 322.703308,130.900238 
	C321.691101,134.041992 320.938721,136.994095 320.054321,140.388443 
	C320.166260,144.144470 320.410156,147.458252 320.308472,150.625992 
	C318.617523,149.363281 317.272156,148.246628 315.926819,147.129959 
	C315.284546,148.753571 314.642273,150.377182 313.675568,151.998779 
	C312.918610,152.002777 312.486115,152.008774 311.932190,151.566467 
	C311.866364,147.406235 311.921967,143.694275 312.312744,139.777191 
	C313.093323,138.390335 313.878967,137.223282 313.930573,136.024628 
	C314.361084,126.022346 314.656494,116.014252 314.992676,106.007912 
	C315.606781,104.712479 316.220886,103.417053 316.834991,102.121628 
	C318.226929,103.318436 319.618866,104.515251 321.322601,105.845566 
	C321.753754,106.139519 321.873138,106.299957 322.086792,107.033775 
	C322.273773,108.462929 322.366455,109.318695 322.459137,110.174461 
z"/>
<path fill="#111A1E" opacity="1.000000" stroke="none" 
	d="
M334.462402,83.431915 
	C335.574127,89.585220 336.615387,90.678772 341.856567,90.241409 
	C344.015869,91.022095 345.541595,91.579185 347.067322,92.136276 
	C346.175385,92.603943 345.283417,93.071617 343.880096,93.790993 
	C342.510132,94.619614 341.651550,95.196518 340.792969,95.773438 
	C340.792969,95.773438 340.865387,95.828003 340.553284,95.834976 
	C339.905579,96.103462 339.569916,96.364983 339.234253,96.626488 
	C337.198517,96.491402 335.162811,96.356323 332.739197,96.159592 
	C327.294678,91.130669 332.130615,87.556023 334.462402,83.431915 
z"/>
<path fill="#67B8CC" opacity="1.000000" stroke="none" 
	d="
M322.743286,110.415436 
	C322.366455,109.318695 322.273773,108.462929 322.250610,107.285034 
	C324.223969,107.856674 326.127808,108.750443 328.031647,109.644211 
	C328.250305,109.172661 328.468933,108.701111 328.687561,108.229561 
	C328.009491,107.706459 327.167664,107.299240 326.694702,106.630959 
	C326.101288,105.792419 325.269867,104.540154 325.531494,103.832588 
	C325.828735,103.028664 327.242218,102.112106 328.131348,102.150513 
	C330.838623,102.267456 333.534607,102.774811 336.220367,103.218819 
	C338.291718,103.561249 340.340942,104.037361 342.699158,104.698563 
	C338.452728,106.815262 333.977203,108.895767 329.327759,110.458862 
	C327.421021,111.099892 325.138855,110.624146 322.743286,110.415436 
M332.958130,105.648682 
	C333.431854,105.418610 333.905609,105.188538 334.379333,104.958458 
	C334.190338,104.739197 333.873291,104.308281 333.832062,104.333221 
	C333.393555,104.598518 332.998871,104.936195 332.958130,105.648682 
z"/>
<path fill="#182D3B" opacity="1.000000" stroke="none" 
	d="
M347.378540,92.142868 
	C345.541595,91.579185 344.015869,91.022095 342.220459,90.222450 
	C343.763153,88.403694 345.575531,86.827484 348.084167,85.155212 
	C350.189331,85.424873 351.598206,85.790611 353.007111,86.156349 
	C352.388855,87.507950 351.770599,88.859543 351.117065,90.504402 
	C349.951111,91.248268 348.820465,91.698868 347.378540,92.142868 
z"/>
<path fill="#67B8CC" opacity="1.000000" stroke="none" 
	d="
M364.834839,107.048027 
	C362.252716,106.620270 359.479340,105.856239 356.449768,104.810486 
	C359.010254,105.256432 361.826904,105.984085 364.834839,107.048027 
z"/>
<path fill="#67B8CC" opacity="1.000000" stroke="none" 
	d="
M348.874603,103.912643 
	C348.233490,104.335594 347.411560,104.518776 346.273132,104.633957 
	C346.869019,104.268265 347.781403,103.970573 348.874603,103.912643 
z"/>
<path fill="#67B8CC" opacity="1.000000" stroke="none" 
	d="
M341.149170,95.829582 
	C341.651550,95.196518 342.510132,94.619614 343.684540,94.021164 
	C343.168701,94.628334 342.337036,95.257034 341.149170,95.829582 
z"/>
<path fill="#67B8CC" opacity="1.000000" stroke="none" 
	d="
M322.076416,100.882034 
	C321.688354,100.546349 321.555542,100.032425 321.521667,99.259842 
	C321.923767,99.010849 322.226959,99.011108 322.757385,98.999962 
	C322.766968,99.566574 322.549316,100.135185 322.076416,100.882034 
z"/>
<path fill="#67B8CC" opacity="1.000000" stroke="none" 
	d="
M339.569458,96.612579 
	C339.569916,96.364983 339.905579,96.103462 340.560547,95.790222 
	C340.554810,96.025215 340.229736,96.311935 339.569458,96.612579 
z"/>
<path fill="#67B8CC" opacity="1.000000" stroke="none" 
	d="
M369.729614,110.091377 
	C369.503143,110.168877 369.142212,109.940292 368.612457,109.435364 
	C368.827484,109.367783 369.211273,109.576538 369.729614,110.091377 
z"/>
<path fill="#67B8CC" opacity="1.000000" stroke="none" 
	d="
M353.927917,104.035461 
	C353.826538,104.254845 353.650818,104.301781 353.233734,104.221466 
	C353.279419,104.017159 353.566528,103.940086 353.927917,104.035461 
z"/>
<path fill="#43CBD2" opacity="1.000000" stroke="none" 
	d="
M353.340149,85.982292 
	C351.598206,85.790611 350.189331,85.424873 348.413757,85.017128 
	C348.498871,84.351227 348.950684,83.727325 349.674835,82.980232 
	C355.387024,81.025009 360.826874,79.192978 366.755951,77.484970 
	C367.245178,77.609001 367.652344,77.624001 367.652344,77.624001 
	C367.652344,77.624001 367.965546,77.356644 367.965546,77.356644 
	C372.296417,78.055580 376.627289,78.754517 381.341675,79.539200 
	C382.434235,80.232025 383.143311,80.839127 383.820007,81.975334 
	C382.187775,83.024254 380.587891,83.544052 378.987976,84.063866 
	C377.885651,83.623894 376.783356,83.183914 375.244873,82.326553 
	C373.879913,82.201187 372.951111,82.493202 372.022308,82.785233 
	C365.905914,83.792908 359.789551,84.800575 353.340149,85.982292 
z"/>
<path fill="#52A7C1" opacity="1.000000" stroke="none" 
	d="
M379.168884,84.332886 
	C380.587891,83.544052 382.187775,83.024254 383.952515,82.223969 
	C384.182007,83.268410 384.246643,84.593323 384.311310,85.918236 
	C382.657471,85.479462 381.003601,85.040688 379.168884,84.332886 
z"/>
<path fill="#52A7C1" opacity="1.000000" stroke="none" 
	d="
M372.404968,82.814453 
	C372.951111,82.493202 373.879913,82.201187 374.930054,82.158569 
	C374.296783,82.553215 373.542206,82.698456 372.404968,82.814453 
z"/>
<path fill="#AF7D35" opacity="1.000000" stroke="none" 
	d="
M366.713104,70.878273 
	C366.333466,71.497971 365.711914,71.957893 364.938477,72.682037 
	C362.763184,73.635254 360.739716,74.324257 358.353821,74.956070 
	C360.817993,73.505432 363.644562,72.111969 366.713104,70.878273 
z"/>
<path fill="#AF7D35" opacity="1.000000" stroke="none" 
	d="
M371.821411,69.730385 
	C371.593231,70.051262 371.199860,70.195869 370.493408,70.281250 
	C370.672272,69.999390 371.164246,69.776756 371.821411,69.730385 
z"/>
<path fill="#AF7D35" opacity="1.000000" stroke="none" 
	d="
M378.908875,68.733704 
	C378.858368,68.951500 378.705536,69.029617 378.300476,69.058533 
	C378.301025,68.870903 378.553772,68.732468 378.908875,68.733704 
z"/>
<path fill="#52A7C1" opacity="1.000000" stroke="none" 
	d="
M367.730469,77.301720 
	C367.965546,77.356644 367.652344,77.624001 367.652344,77.624001 
	C367.652344,77.624001 367.245178,77.609001 367.143494,77.510590 
	C367.041840,77.412170 367.495422,77.246796 367.730469,77.301720 
z"/>
<path fill="#4791AB" opacity="1.000000" stroke="none" 
	d="
M352.836639,61.262501 
	C353.262146,61.104626 353.446228,61.126652 353.903992,61.206177 
	C353.765625,61.836506 353.353546,62.409344 352.681519,63.069435 
	C352.479431,62.585258 352.537323,62.013832 352.836639,61.262501 
z"/>
<path fill="#69CBE9" opacity="1.000000" stroke="none" 
	d="
M397.006317,349.736267 
	C396.344604,350.047943 395.672546,349.945251 394.658905,349.842773 
	C394.949768,348.875275 395.582245,347.907532 396.567780,346.890747 
	C396.945892,347.668427 396.970947,348.495148 397.006317,349.736267 
z"/>
<path fill="#56B0CF" opacity="1.000000" stroke="none" 
	d="
M402.272461,336.438416 
	C402.682739,336.053253 403.017151,335.941010 403.594757,335.714294 
	C403.436493,336.678711 403.035065,337.757629 402.271332,338.929169 
	C402.004913,338.251648 402.100769,337.481506 402.272461,336.438416 
z"/>
<path fill="#16292F" opacity="1.000000" stroke="none" 
	d="
M400.518188,354.502014 
	C399.972717,354.054199 399.536102,353.218079 399.123535,351.995850 
	C399.640717,352.444427 400.133911,353.279083 400.518188,354.502014 
z"/>
<path fill="#091318" opacity="1.000000" stroke="none" 
	d="
M396.089966,358.056732 
	C397.063049,358.387329 398.036163,358.717896 399.255798,359.368713 
	C397.215607,364.298309 394.928864,368.907654 392.402344,373.765411 
	C391.048157,374.012634 389.933716,374.011475 388.400879,374.016144 
	C387.957825,373.549225 387.933228,373.076477 387.919464,371.872833 
	C388.586212,368.732727 389.242126,366.323517 390.117096,363.665100 
	C392.167145,361.614349 393.998138,359.812836 395.892761,358.031830 
	C395.956360,358.052307 396.089966,358.056732 396.089966,358.056732 
z"/>
<path fill="#FBCB06" opacity="1.000000" stroke="none" 
	d="
M438.462952,362.059143 
	C439.911957,363.204773 440.918518,364.508728 442.242126,365.087158 
	C444.858032,366.230286 445.632904,367.803406 443.824829,370.672058 
	C438.663025,367.394928 434.387695,368.638885 429.913849,371.560394 
	C427.928345,372.856934 425.326813,373.210083 423.004028,373.569885 
	C423.336182,370.425171 423.669189,367.702057 424.002197,364.978912 
	C425.640411,361.842621 427.274353,358.704071 428.918732,355.571045 
	C429.707794,354.067657 430.516693,352.574646 432.100006,349.610138 
	C434.556854,354.585266 436.360321,358.237366 438.462952,362.059143 
z"/>
<path fill="#F8CD0D" opacity="1.000000" stroke="none" 
	d="
M423.544983,364.967957 
	C423.669189,367.702057 423.336182,370.425171 422.670227,373.570984 
	C421.892517,373.987793 421.447693,373.981873 421.002869,373.975983 
	C418.076630,371.488434 415.150391,369.000885 412.024841,366.219055 
	C415.579620,365.602142 419.333710,365.279541 423.544983,364.967957 
z"/>
<path fill="#FCBE0A" opacity="1.000000" stroke="none" 
	d="
M420.977570,374.394409 
	C421.447693,373.981873 421.892517,373.987793 422.671112,373.992554 
	C425.326813,373.210083 427.928345,372.856934 429.913849,371.560394 
	C434.387695,368.638885 438.663025,367.394928 443.668396,371.001862 
	C444.318054,371.285400 444.592468,371.552826 445.140289,371.934814 
	C441.077698,375.816620 440.352020,380.138245 442.820190,385.371826 
	C443.475769,386.761902 442.653717,388.848816 442.257812,390.954376 
	C440.455475,384.433899 431.128754,380.922394 424.330414,384.930115 
	C423.032074,385.611816 422.049011,386.120453 419.722565,387.324097 
	C420.194550,382.522003 420.573425,378.667419 420.977570,374.394409 
z"/>
<path fill="#D93BA0" opacity="1.000000" stroke="none" 
	d="
M171.149277,78.249069 
	C168.345062,77.222031 165.695404,76.010162 162.928986,74.452225 
	C166.981918,69.608337 168.620300,69.679565 172.112030,75.155289 
	C172.030045,76.490768 171.886688,77.178741 171.743332,77.866722 
	C171.743332,77.866722 171.303818,78.064232 171.149277,78.249069 
z"/>
<path fill="#93B68F" opacity="1.000000" stroke="none" 
	d="
M146.642548,72.592857 
	C146.182693,71.711739 146.071976,70.726921 145.950317,69.076546 
	C145.939392,68.410995 146.036484,68.070999 146.036484,68.070999 
	C148.099075,67.104286 150.161682,66.137566 152.571655,65.136292 
	C153.292450,66.084595 153.665863,67.067467 154.177185,68.424995 
	C151.873962,70.029480 149.432831,71.259315 146.642548,72.592857 
z"/>
<path fill="#152422" opacity="1.000000" stroke="none" 
	d="
M160.878235,66.939072 
	C163.969452,66.156578 167.521545,65.384918 171.509720,64.558136 
	C168.410248,65.318649 164.874664,66.134285 160.878235,66.939072 
z"/>
<path fill="#274248" opacity="1.000000" stroke="none" 
	d="
M171.998535,77.743797 
	C171.886688,77.178741 172.030045,76.490768 172.273041,75.467003 
	C172.333038,75.961105 172.293396,76.790985 171.998535,77.743797 
z"/>
<path fill="#152422" opacity="1.000000" stroke="none" 
	d="
M139.674484,65.075661 
	C138.736923,66.189583 137.482346,67.394653 135.879562,68.552383 
	C136.806732,67.392303 138.082092,66.279556 139.674484,65.075661 
z"/>
<path fill="#152422" opacity="1.000000" stroke="none" 
	d="
M141.805374,70.117950 
	C141.073944,70.871269 139.970673,71.591850 138.532379,72.351517 
	C139.276077,71.622131 140.354813,70.853668 141.805374,70.117950 
z"/>
<path fill="#152422" opacity="1.000000" stroke="none" 
	d="
M145.835236,67.850998 
	C146.036484,68.070999 145.939392,68.410995 145.905518,68.584747 
	C145.242889,68.976334 144.614120,69.194153 143.657715,69.451889 
	C144.098053,68.871544 144.866028,68.251274 145.835236,67.850998 
z"/>
<path fill="#152422" opacity="1.000000" stroke="none" 
	d="
M143.042206,69.296188 
	C143.097061,69.522606 142.880966,69.825432 142.371323,70.180153 
	C142.308945,69.945564 142.540085,69.659081 143.042206,69.296188 
z"/>
<path fill="#1EBBE2" opacity="1.000000" stroke="none" 
	d="
M347.006165,401.041748 
	C347.654694,402.024078 348.410706,402.954010 348.922211,404.003113 
	C349.458557,405.103119 349.760345,406.317505 350.143646,408.192505 
	C350.061615,409.861389 350.000549,410.819641 349.939514,411.777924 
	C349.510773,411.841217 349.082062,411.904541 348.168640,411.732727 
	C347.119690,411.636810 346.555420,411.776001 345.991180,411.915222 
	C345.245972,411.927582 344.500763,411.939941 343.289856,411.740753 
	C342.541595,411.604584 342.258972,411.679962 341.976379,411.755341 
	C338.622009,411.848785 335.267609,411.942261 331.224731,411.780151 
	C329.282837,409.367950 328.029449,407.211334 326.776093,405.054688 
	C326.358978,405.099518 325.941895,405.144348 325.524811,405.189178 
	C325.024078,407.148987 324.523376,409.108826 323.591858,411.064026 
	C321.255371,410.974091 319.349670,410.888824 317.352905,410.799500 
	C317.352905,408.686310 317.352905,407.229523 317.352905,404.905273 
	C313.575317,412.121765 307.721619,411.398865 302.029663,410.682617 
	C301.563202,408.636108 301.056366,406.930573 300.549500,405.225037 
	C299.526642,406.407227 298.538818,407.622345 297.469238,408.760620 
	C296.694214,409.585327 295.810852,410.308197 294.636902,411.069824 
	C293.170197,411.523956 292.042328,411.984772 290.914459,412.445557 
	C289.863007,412.506897 288.811554,412.568237 287.242432,412.410645 
	C286.512421,412.048431 286.292725,411.917572 286.110992,411.465240 
	C286.986115,409.773529 287.815826,408.415741 289.316071,405.960693 
	C283.945618,405.960693 279.466583,405.960693 275.011414,405.591064 
	C277.701721,403.821747 280.368164,402.422058 283.458160,401.020325 
	C285.578674,401.011169 287.275665,401.004089 289.202271,401.204041 
	C290.236633,401.053680 291.185974,400.863159 291.822418,400.311584 
	C295.713287,396.939484 304.577423,395.714966 309.050812,398.372559 
	C310.765472,402.592651 313.144470,402.654236 316.363037,399.974731 
	C317.459290,398.951965 318.226715,397.987183 319.392761,397.004791 
	C323.563934,396.922577 327.344635,396.976227 331.103607,396.715912 
	C332.129456,396.644867 333.089935,395.629944 334.308105,395.037262 
	C334.535828,395.026306 334.991455,395.007843 335.151794,395.308228 
	C336.842926,396.438721 338.373749,397.268799 340.032379,398.440460 
	C341.766754,399.209839 343.373322,399.637634 345.121338,400.258667 
	C345.483734,400.694733 345.750580,400.866150 346.299561,400.979034 
	C346.535736,400.992004 347.006195,401.041748 347.006165,401.041748 
z"/>
<path fill="#1CB7DF" opacity="1.000000" stroke="none" 
	d="
M181.023041,406.931641 
	C179.161880,405.753082 177.300720,404.574493 174.757523,402.964020 
	C178.217270,402.396576 180.875229,401.960663 184.017212,401.262329 
	C184.668518,400.999603 184.835785,400.999207 185.142517,401.256165 
	C186.185196,401.669037 187.088394,401.824493 188.357239,401.971832 
	C190.182022,401.245270 191.641174,400.526825 193.384064,399.837830 
	C193.828400,399.969116 193.989014,400.070984 194.210037,400.256866 
	C194.270462,400.340912 194.539108,400.451294 194.539108,400.451294 
	C194.539108,400.451294 194.822800,400.343323 195.186066,400.255310 
	C196.046188,400.160553 196.543076,400.153778 197.237946,400.322815 
	C198.260422,400.330536 199.084900,400.162445 200.349991,399.998169 
	C204.439072,399.825439 208.454590,400.901337 211.426025,397.005249 
	C214.599686,396.363037 217.360535,395.721649 220.131393,395.432129 
	C220.755463,397.191528 221.369507,398.599060 221.998566,400.231445 
	C222.013580,400.456299 222.051437,400.905365 222.260300,401.161163 
	C223.312119,401.629028 224.155090,401.841125 225.053833,402.408783 
	C225.404572,404.165833 225.699539,405.567322 225.771698,407.173859 
	C225.322021,407.930939 225.095154,408.482941 224.758759,409.140076 
	C224.523376,409.563324 224.620956,409.810455 225.206482,410.226318 
	C227.291214,410.301056 229.111404,410.136047 231.215088,410.099609 
	C232.331543,410.816406 233.164505,411.404602 233.636185,412.007019 
	C231.822433,412.449738 230.369949,412.878235 228.567810,413.398560 
	C227.507111,413.411072 226.796051,413.331757 225.958405,413.027985 
	C224.858307,412.964294 223.884796,413.125061 222.585724,413.402405 
	C221.813278,413.628326 221.366394,413.737671 220.919510,413.847046 
	C219.522614,413.735901 218.125732,413.624756 216.202362,413.250000 
	C215.432266,412.686890 215.188660,412.387390 215.196320,411.807678 
	C216.841141,408.155945 215.503067,406.811249 211.675140,406.989624 
	C209.897812,407.530304 208.537155,408.086243 207.176514,408.642212 
	C208.127274,409.437592 209.078033,410.233032 209.886108,411.246216 
	C209.204773,411.894257 208.666122,412.324554 208.127457,412.754822 
	C207.372696,412.930542 206.617935,413.106262 205.471832,413.250519 
	C202.506516,413.054352 199.932571,412.889648 197.158875,413.000488 
	C194.289597,413.313293 191.620056,413.350586 188.322174,413.052795 
	C185.766891,412.859650 183.839935,413.001556 181.912994,413.143433 
	C180.866928,413.226715 179.820877,413.309998 178.396637,413.266907 
	C178.013901,412.602783 178.009338,412.065033 178.387878,411.261536 
	C180.067780,410.463745 181.364578,409.931671 182.661377,409.399597 
	C182.115265,408.576935 181.569153,407.754272 181.023041,406.931641 
z"/>
<path fill="#1DB4DC" opacity="1.000000" stroke="none" 
	d="
M233.997467,411.992798 
	C233.164505,411.404602 232.331543,410.816406 231.216034,409.717499 
	C229.287170,408.460785 227.640839,407.714783 225.994507,406.968781 
	C225.699539,405.567322 225.404572,404.165833 225.520874,402.395020 
	C230.991104,402.196716 236.050461,402.479675 241.108719,402.461212 
	C242.393326,402.456512 243.674423,401.493134 244.970261,400.978149 
	C244.983383,400.985992 244.981018,400.967194 245.300110,400.944519 
	C246.417160,400.286041 247.215118,399.650238 248.374710,398.955688 
	C251.961380,396.074860 255.199158,398.577576 258.660339,398.956665 
	C259.253662,399.358521 259.618286,399.677399 260.034607,400.268188 
	C260.726379,400.693542 261.366486,400.846954 262.401855,400.997955 
	C263.847900,400.972626 264.898651,400.949707 266.141968,401.156830 
	C266.887817,401.588379 267.441071,401.789886 268.031921,402.281921 
	C268.379303,403.048431 268.689026,403.524475 268.985657,404.383331 
	C268.320343,407.377014 267.668152,409.987885 267.015961,412.598755 
	C260.978912,412.752289 254.941864,412.905853 248.211655,412.761353 
	C246.350327,412.623596 245.182175,412.783905 243.617340,413.029846 
	C242.144608,413.065674 241.068527,413.015900 239.824692,412.756104 
	C239.300262,410.566620 238.943588,408.587158 238.529617,406.289764 
	C234.364899,405.388641 235.690048,410.120026 233.997467,411.992798 
z"/>
<path fill="#17ABD3" opacity="1.000000" stroke="none" 
	d="
M274.987549,405.960693 
	C279.466583,405.960693 283.945618,405.960693 289.316071,405.960693 
	C287.815826,408.415741 286.986115,409.773529 286.069611,411.624817 
	C285.982849,412.118317 285.943542,412.442780 285.943542,412.442780 
	C283.242035,412.570343 280.540497,412.697937 277.377594,412.388062 
	C275.615082,409.969238 274.313965,407.987885 273.342010,406.000793 
	C274.109955,405.983551 274.548767,405.972137 274.987549,405.960693 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M301.989319,411.023621 
	C307.721619,411.398865 313.575317,412.121765 317.352905,404.905273 
	C317.352905,407.229523 317.352905,408.686310 317.352905,410.799500 
	C319.349670,410.888824 321.255371,410.974091 323.584564,411.307739 
	C324.008057,411.556122 324.003845,412.045074 324.003845,412.045074 
	C315.322205,412.225647 306.640564,412.406219 297.809265,412.298279 
	C299.102814,411.681061 300.546082,411.352325 301.989319,411.023621 
z"/>
<path fill="#1EBBE2" opacity="1.000000" stroke="none" 
	d="
M273.012848,406.006561 
	C274.313965,407.987885 275.615082,409.969238 276.938843,412.273865 
	C273.943237,412.655029 270.925018,412.712921 267.461365,412.684784 
	C267.668152,409.987885 268.320343,407.377014 269.354919,404.384979 
	C270.829163,404.671387 271.920990,405.338989 273.012848,406.006561 
z"/>
<path fill="#32CCF1" opacity="1.000000" stroke="none" 
	d="
M302.029663,410.682617 
	C300.546082,411.352325 299.102814,411.681061 297.372070,412.144653 
	C296.670135,412.315155 296.255737,412.350739 295.511047,412.122864 
	C295.082703,411.606171 295.014374,411.345215 294.975800,411.076477 
	C295.810852,410.308197 296.694214,409.585327 297.469238,408.760620 
	C298.538818,407.622345 299.526642,406.407227 300.549500,405.225037 
	C301.056366,406.930573 301.563202,408.636108 302.029663,410.682617 
z"/>
<path fill="#1999B8" opacity="1.000000" stroke="none" 
	d="
M350.190704,411.980225 
	C350.000549,410.819641 350.061615,409.861389 350.141174,408.548279 
	C351.716278,407.075104 353.272888,405.956757 355.618439,404.271637 
	C355.278290,407.520905 355.091949,409.301300 354.956482,411.406067 
	C355.007385,411.730438 355.124573,412.046051 354.777161,412.109985 
	C353.957428,412.235291 353.485138,412.296661 352.673523,412.285583 
	C351.887421,412.314240 351.440643,412.415283 350.993835,412.516327 
	C350.809845,412.405060 350.625885,412.293793 350.190704,411.980225 
z"/>
<path fill="#1999B8" opacity="1.000000" stroke="none" 
	d="
M324.438110,412.112671 
	C324.003845,412.045074 324.008057,411.556122 324.015350,411.312378 
	C324.523376,409.108826 325.024078,407.148987 325.524811,405.189178 
	C325.941895,405.144348 326.358978,405.099518 326.776093,405.054688 
	C328.029449,407.211334 329.282837,409.367950 330.767639,411.747131 
	C328.956848,412.039886 326.914642,412.110077 324.438110,412.112671 
z"/>
<path fill="#111A1E" opacity="1.000000" stroke="none" 
	d="
M239.992447,412.966125 
	C241.068527,413.015900 242.144608,413.065674 243.876297,413.209595 
	C245.363144,413.460663 246.194382,413.617615 247.025620,413.774536 
	C244.987488,414.185089 242.948441,414.946472 240.911362,414.941223 
	C234.454987,414.924561 227.999344,414.621643 221.231476,414.138184 
	C221.366394,413.737671 221.813278,413.628326 222.972351,413.537964 
	C224.484695,413.455475 225.284851,413.353943 226.085007,413.252441 
	C226.796051,413.331757 227.507111,413.411072 228.998108,413.558289 
	C233.182861,413.406189 236.587646,413.186157 239.992447,412.966125 
z"/>
<path fill="#142931" opacity="1.000000" stroke="none" 
	d="
M350.999084,412.856750 
	C351.440643,412.415283 351.887421,412.314240 352.757446,412.556396 
	C353.803131,413.258087 354.425537,413.616547 355.047974,413.974976 
	C357.194366,413.974976 359.340790,413.974976 361.487183,413.974976 
	C361.573914,414.258179 361.660675,414.541412 361.747406,414.824615 
	C359.434418,415.548981 357.121460,416.273315 354.432159,417.030212 
	C353.038666,415.774200 352.021484,414.485687 350.999084,412.856750 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M294.636902,411.069824 
	C295.014374,411.345215 295.082703,411.606171 295.203644,412.061493 
	C294.071808,412.407715 292.917084,412.551849 291.338379,412.570801 
	C292.042328,411.984772 293.170197,411.523956 294.636902,411.069824 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M247.194931,413.639435 
	C246.194382,413.617615 245.363144,413.460663 244.272949,413.123962 
	C245.182175,412.783905 246.350327,412.623596 247.746109,412.690735 
	C247.853241,413.199493 247.650070,413.394867 247.194931,413.639435 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M214.945053,412.087860 
	C215.188660,412.387390 215.432266,412.686890 215.797699,413.136322 
	C215.771759,413.404327 215.624023,413.522369 215.238739,413.795563 
	C214.450470,413.813568 213.899750,413.676453 213.313690,413.088867 
	C213.833923,412.454895 214.389481,412.271362 214.945053,412.087860 
z"/>
<path fill="#142931" opacity="1.000000" stroke="none" 
	d="
M279.998779,417.001221 
	C279.525330,416.646667 279.053101,416.290863 278.580902,415.935059 
	C278.691406,415.816162 278.891632,415.587708 278.899536,415.594147 
	C279.246460,415.878174 279.576416,416.182861 279.955444,416.741333 
	C280.002441,416.997559 280.000000,417.000000 279.998779,417.001221 
z"/>
<path fill="#142931" opacity="1.000000" stroke="none" 
	d="
M273.688293,415.618744 
	C274.004181,415.760834 274.175903,416.039001 274.347626,416.317139 
	C274.079803,416.129669 273.811951,415.942230 273.688293,415.618744 
z"/>
<path fill="#1999B8" opacity="1.000000" stroke="none" 
	d="
M346.323273,412.020691 
	C346.555420,411.776001 347.119690,411.636810 347.845337,411.687958 
	C347.556305,411.960938 347.105835,412.043549 346.323273,412.020691 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M286.200745,412.528442 
	C285.943542,412.442780 285.982849,412.118317 286.024231,411.958740 
	C286.292725,411.917572 286.512421,412.048431 286.853271,412.333130 
	C286.807190,412.521027 286.632568,412.567535 286.200745,412.528442 
z"/>
<path fill="#1999B8" opacity="1.000000" stroke="none" 
	d="
M342.234436,411.844788 
	C342.258972,411.679962 342.541595,411.604584 342.923096,411.664825 
	C342.845490,411.845062 342.668976,411.889679 342.234436,411.844788 
z"/>
<path fill="#EF3B85" opacity="1.000000" stroke="none" 
	d="
M265.315002,458.681946 
	C265.050262,454.977325 265.026581,450.944214 265.019104,446.446259 
	C265.208893,450.105438 265.382477,454.229431 265.315002,458.681946 
z"/>
<path fill="#208DB1" opacity="1.000000" stroke="none" 
	d="
M254.463196,447.158295 
	C254.570724,451.995483 254.323624,456.973907 253.745163,462.178589 
	C253.645386,457.369781 253.876984,452.334625 254.463196,447.158295 
z"/>
<path fill="#47C6E6" opacity="1.000000" stroke="none" 
	d="
M223.589478,451.866882 
	C223.564087,447.988922 223.878876,443.967194 224.317902,439.534302 
	C224.271317,443.323120 224.100494,447.523132 223.589478,451.866882 
z"/>
<path fill="#47C6E6" opacity="1.000000" stroke="none" 
	d="
M226.277435,437.499084 
	C226.418320,437.281494 226.730392,437.334076 227.213501,437.673859 
	C227.078491,437.843872 226.766510,437.779907 226.277435,437.499084 
z"/>
<path fill="#D22C77" opacity="1.000000" stroke="none" 
	d="
M175.957855,432.242981 
	C175.960526,433.235992 175.684769,434.359314 175.160126,435.726318 
	C175.167313,434.771088 175.423370,433.572205 175.957855,432.242981 
z"/>
<path fill="#D22C77" opacity="1.000000" stroke="none" 
	d="
M174.047546,438.138977 
	C174.220978,438.108215 174.227509,438.604401 174.075378,438.800293 
	C173.825562,438.803192 173.706284,438.616699 173.644836,438.412781 
	C173.628830,438.359589 173.792816,438.252197 174.047546,438.138977 
z"/>
<path fill="#0D3543" opacity="1.000000" stroke="none" 
	d="
M157.113571,416.711670 
	C156.268661,416.473602 155.647079,416.037933 155.275421,415.196716 
	C156.358658,414.553040 157.191940,414.314911 158.025223,414.076782 
	C158.485764,414.141266 158.946320,414.205750 159.732224,414.395691 
	C159.150696,415.185455 158.243790,415.849731 157.113571,416.711670 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M166.252792,413.893707 
	C166.197784,413.509827 166.457291,413.422852 166.885895,413.685303 
	C166.825195,413.856110 166.662628,413.908051 166.252792,413.893707 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M172.259796,413.805145 
	C172.208237,413.441925 172.458923,413.359833 172.872177,413.599243 
	C172.815628,413.763916 172.658066,413.819092 172.259796,413.805145 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M182.331451,413.311401 
	C183.839935,413.001556 185.766891,412.859650 187.891479,412.955078 
	C186.309402,413.288025 184.529663,413.383698 182.331451,413.311401 
z"/>
<path fill="#24CFF1" opacity="1.000000" stroke="none" 
	d="
M215.196320,411.807678 
	C214.389481,412.271362 213.833923,412.454895 213.113739,412.887909 
	C211.598602,413.094513 210.248077,413.051605 208.512512,412.881775 
	C208.666122,412.324554 209.204773,411.894257 210.166107,411.051208 
	C211.089783,409.427246 211.590790,408.216064 212.091797,407.004883 
	C215.503067,406.811249 216.841141,408.155945 215.196320,411.807678 
z"/>
<path fill="#102532" opacity="1.000000" stroke="none" 
	d="
M355.053131,413.709351 
	C354.425537,413.616547 353.803131,413.258087 353.096741,412.628845 
	C353.485138,412.296661 353.957428,412.235291 354.774780,412.278320 
	C355.099304,412.736420 355.078796,413.090088 355.053131,413.709351 
z"/>
<path fill="#1999B8" opacity="1.000000" stroke="none" 
	d="
M372.393433,411.850403 
	C373.172974,411.594086 374.338776,411.458405 375.741425,411.525696 
	C374.912079,411.809418 373.845917,411.890228 372.393433,411.850403 
z"/>
<path fill="#E8E5C6" opacity="1.000000" stroke="none" 
	d="
M364.530334,415.860046 
	C364.530792,416.111694 364.299347,416.413055 363.826355,416.612122 
	C363.756378,416.231049 363.994293,416.031006 364.530334,415.860046 
z"/>
<path fill="#102532" opacity="1.000000" stroke="none" 
	d="
M367.980347,412.200867 
	C367.409760,412.429352 366.907928,412.542908 366.256287,412.499207 
	C366.106476,412.341949 365.966278,412.107117 366.305542,412.065948 
	C367.113647,411.955872 367.582550,411.886963 368.051422,411.818054 
	C368.051422,411.818054 368.049072,412.085907 367.980347,412.200867 
z"/>
<path fill="#2EBCE5" opacity="1.000000" stroke="none" 
	d="
M381.614441,356.023987 
	C381.728027,358.403595 381.841614,360.783203 381.870483,363.614441 
	C381.325775,369.421906 375.308502,373.220795 377.360168,379.398773 
	C377.394257,379.501373 374.232117,381.398438 373.346619,380.940369 
	C370.296112,379.362335 366.503052,378.169708 366.555176,373.473328 
	C366.567322,372.380707 365.371277,371.274719 364.323669,369.735657 
	C362.901245,368.559448 361.886230,367.822449 360.901733,367.055939 
	C360.932220,367.026459 360.995697,367.081879 360.847778,366.871399 
	C360.437164,366.442749 360.174438,366.224579 359.966461,366.033142 
	C360.021179,366.059875 359.968048,366.169464 359.822388,365.853851 
	C357.779602,365.039124 355.882416,364.540009 353.537506,364.083801 
	C350.397949,364.140717 347.706116,364.154724 345.277985,363.887390 
	C347.599792,363.265778 349.657898,362.925568 351.716034,362.585327 
	C347.240448,361.775269 342.309540,360.109589 338.381592,361.429260 
	C333.734039,362.990662 332.065765,362.174591 331.371979,357.821381 
	C345.450195,357.438782 359.144745,357.254120 372.836792,356.955170 
	C375.278320,356.901855 377.709473,356.374573 380.389465,356.055756 
	C380.633484,356.045380 381.123688,356.022339 381.123688,356.022339 
	C381.123688,356.022339 381.614441,356.023987 381.614441,356.023987 
z"/>
<path fill="#17ABD3" opacity="1.000000" stroke="none" 
	d="
M359.998077,372.994965 
	C359.203613,376.032349 359.833466,378.353424 362.087402,380.914825 
	C366.102844,385.478088 365.351685,390.099152 360.336243,394.846680 
	C359.933777,394.869019 359.780945,394.773071 359.931396,394.456116 
	C364.356476,386.969421 363.287567,382.199097 356.519104,379.088043 
	C354.279999,378.058838 351.809448,377.539154 349.459229,376.743652 
	C347.829010,376.191833 346.223633,375.566681 344.206970,374.827667 
	C347.324402,370.827118 350.425751,372.488098 353.598846,373.113220 
	C355.559692,373.499573 357.711029,372.919006 359.832001,372.828278 
	C359.887146,372.884033 359.998077,372.994965 359.998077,372.994965 
z"/>
<path fill="#26A5CE" opacity="1.000000" stroke="none" 
	d="
M347.423279,401.023376 
	C347.006195,401.041748 346.535736,400.992004 346.157593,400.785278 
	C345.558716,400.336182 345.292175,400.165131 344.979889,400.065430 
	C343.373322,399.637634 341.766754,399.209839 340.105530,398.356445 
	C340.050842,397.930817 340.195770,397.761627 340.602692,397.839233 
	C347.736359,399.673035 354.070770,399.086670 359.780945,394.773071 
	C359.780945,394.773071 359.933777,394.869019 360.004822,394.925385 
	C357.431702,399.903137 352.857635,400.904236 347.423279,401.023376 
z"/>
<path fill="#1999B8" opacity="1.000000" stroke="none" 
	d="
M376.018982,405.401489 
	C375.446716,406.721069 374.727631,408.485291 373.651062,408.740295 
	C372.607239,408.987579 371.196106,407.684174 369.946320,407.061768 
	C370.056915,406.539429 370.167542,406.017059 370.278137,405.494690 
	C372.090668,405.391754 373.903198,405.288818 376.018982,405.401489 
z"/>
<path fill="#17ABD3" opacity="1.000000" stroke="none" 
	d="
M360.871246,367.085419 
	C361.886230,367.822449 362.901245,368.559448 363.977844,369.633911 
	C363.549774,370.291046 363.060089,370.610718 362.244690,370.935699 
	C361.919006,370.940979 361.860046,370.886932 361.853027,370.551758 
	C361.521088,369.172852 361.196167,368.129120 360.871246,367.085419 
z"/>
<path fill="#17ABD3" opacity="1.000000" stroke="none" 
	d="
M361.946411,370.970093 
	C361.522644,371.632080 361.071503,372.264923 360.309204,372.946381 
	C359.998077,372.994965 359.887146,372.884033 359.956482,372.569519 
	C360.637268,371.798981 361.248657,371.342957 361.860046,370.886932 
	C361.860046,370.886932 361.919006,370.940979 361.946411,370.970093 
z"/>
<path fill="#1999B8" opacity="1.000000" stroke="none" 
	d="
M367.873993,411.642578 
	C367.582550,411.886963 367.113647,411.955872 366.298706,411.929382 
	C366.533966,411.711700 367.115265,411.589386 367.873993,411.642578 
z"/>
<path fill="#3DBFE1" opacity="1.000000" stroke="none" 
	d="
M203.964203,99.888145 
	C203.964203,99.888145 204.407089,100.067795 204.599060,100.595215 
	C205.709976,106.445557 203.963409,110.849190 198.588608,111.850426 
	C194.963501,112.525719 190.938568,111.054573 187.092499,110.543610 
	C185.990158,108.048264 184.887817,105.552925 184.188751,102.645149 
	C190.181549,98.369232 195.963043,96.147011 202.526138,100.017105 
	C202.863007,100.215744 203.478455,99.941971 203.964203,99.888145 
z"/>
<path fill="#53D1F0" opacity="1.000000" stroke="none" 
	d="
M203.006012,150.021362 
	C202.626770,153.707184 200.210266,154.267685 197.111542,153.976151 
	C195.255829,153.801575 193.378113,153.860718 190.937958,153.908401 
	C190.075043,153.845871 189.768219,153.801224 189.445160,153.868027 
	C189.295547,153.151321 189.145950,152.434631 188.982635,150.926407 
	C188.901306,144.765411 188.833679,139.395950 189.195404,134.018341 
	C197.755280,134.150375 202.964920,140.383881 203.006012,150.021362 
z"/>
<path fill="#D2EEEF" opacity="1.000000" stroke="none" 
	d="
M183.506958,159.614456 
	C180.634720,158.217438 177.644653,155.295044 175.391586,155.782074 
	C173.068512,156.284241 171.462936,160.105591 168.829437,162.674500 
	C164.384125,163.182465 160.652832,163.490662 156.921524,163.798843 
	C155.624512,162.935440 154.327499,162.072037 153.437088,160.794235 
	C154.282822,160.214554 154.721939,160.049286 155.521454,159.896988 
	C157.281464,159.665543 158.681076,159.421127 160.080673,159.176727 
	C165.169998,159.630035 169.332748,159.020966 169.178558,152.305618 
	C169.158325,151.424377 171.088211,149.631897 171.892670,149.751831 
	C174.971649,150.210876 178.208633,150.831055 180.873978,152.313080 
	C182.150742,153.022995 182.280930,155.795013 183.122650,158.235840 
	C183.496826,159.217300 183.678741,159.596024 183.860641,159.974731 
	C183.860641,159.974731 183.712814,159.586411 183.506958,159.614456 
z"/>
<path fill="#D2EEEF" opacity="1.000000" stroke="none" 
	d="
M182.392242,124.753967 
	C181.527100,121.589851 182.822372,119.760139 185.920792,119.049873 
	C187.660965,118.650970 189.403000,118.260223 191.868835,117.689575 
	C195.563599,117.083748 198.533691,116.654137 201.697815,116.468399 
	C202.580765,117.474014 203.269699,118.235771 203.933929,119.357773 
	C203.965530,120.428207 204.021851,121.138397 203.809067,121.852409 
	C203.363937,121.890862 203.187897,121.925499 202.671463,121.841400 
	C201.386429,121.753151 200.441772,121.783653 199.289993,121.638748 
	C198.591064,121.592369 198.099258,121.721390 197.227798,121.808136 
	C194.140717,122.867805 191.433273,123.969742 188.330353,125.073883 
	C186.592484,125.088234 185.250107,125.100395 183.658752,125.029465 
	C183.070602,124.882248 182.731430,124.818108 182.392242,124.753967 
z"/>
<path fill="#D2EEEF" opacity="1.000000" stroke="none" 
	d="
M203.891479,99.568878 
	C203.478455,99.941971 202.863007,100.215744 202.526138,100.017105 
	C195.963043,96.147011 190.181549,98.369232 184.209076,102.275833 
	C182.730209,97.739418 187.049973,92.828979 192.691040,92.320824 
	C194.139175,92.190384 195.607269,92.281700 197.488892,92.245529 
	C199.880661,94.563156 201.849716,96.906387 203.891479,99.568878 
z"/>
<path fill="#6CBED3" opacity="1.000000" stroke="none" 
	d="
M147.422379,103.998474 
	C147.059464,103.872002 147.068710,103.512901 147.099731,103.336800 
	C149.027054,103.012657 150.923355,102.864616 153.233368,102.749023 
	C150.903488,114.578949 154.675781,126.302956 154.141632,138.535553 
	C154.143997,139.460327 154.049759,139.926147 153.833740,140.728989 
	C152.838455,136.338394 151.749741,131.638214 151.143112,126.876610 
	C150.285385,120.144089 149.822861,113.361893 149.081543,106.612999 
	C148.986374,105.746635 148.233490,104.952522 147.422379,103.998474 
z"/>
<path fill="#091318" opacity="1.000000" stroke="none" 
	d="
M201.503799,116.224533 
	C198.533691,116.654137 195.563599,117.083748 192.220322,117.488510 
	C190.279434,115.425011 188.711731,113.386353 187.118256,110.945648 
	C190.938568,111.054573 194.963501,112.525719 198.588608,111.850426 
	C203.963409,110.849190 205.709976,106.445557 204.807404,100.712265 
	C205.553070,101.641945 206.282364,102.982010 207.003693,105.065750 
	C205.642059,109.532639 206.541748,114.444054 201.503799,116.224533 
z"/>
<path fill="#0D3543" opacity="1.000000" stroke="none" 
	d="
M182.105515,125.018677 
	C182.731430,124.818108 183.070602,124.882248 183.722427,125.379646 
	C185.507385,127.717354 186.979706,129.621796 188.422546,131.845154 
	C188.517395,132.784866 188.641724,133.405670 188.766068,134.026474 
	C188.833679,139.395950 188.901306,144.765411 188.837494,150.589935 
	C188.150421,149.485260 187.280731,147.960159 187.104080,146.358627 
	C186.728531,142.954178 187.012573,139.457321 186.422256,136.104675 
	C186.134140,134.468277 184.228439,133.181961 183.530487,131.535583 
	C182.694656,129.563980 182.366699,127.377060 182.105515,125.018677 
z"/>
<path fill="#274248" opacity="1.000000" stroke="none" 
	d="
M163.750244,118.250961 
	C166.380356,118.326981 168.759247,118.654381 171.483002,118.870926 
	C171.621658,121.943947 170.454788,124.442047 166.760651,123.860901 
	C163.944717,123.417915 162.400894,121.618240 163.750244,118.250961 
z"/>
<path fill="#3DBFE1" opacity="1.000000" stroke="none" 
	d="
M155.161057,159.884018 
	C154.721939,160.049286 154.282822,160.214554 153.496948,160.449539 
	C153.096100,157.646561 153.042007,154.773895 152.977280,151.435211 
	C153.175995,150.416245 153.385330,149.863281 154.054550,149.258530 
	C154.629257,149.504532 154.798325,149.767456 155.021667,149.995483 
	C154.966263,150.451553 154.910873,150.907608 154.912628,152.117783 
	C155.033539,155.209259 155.097305,157.546646 155.161057,159.884018 
z"/>
<path fill="#111A1E" opacity="1.000000" stroke="none" 
	d="
M155.521454,159.896988 
	C155.097305,157.546646 155.033539,155.209259 155.048218,152.439072 
	C156.618149,154.212296 158.109650,156.418335 159.840912,158.900543 
	C158.681076,159.421127 157.281464,159.665543 155.521454,159.896988 
z"/>
<path fill="#111A1E" opacity="1.000000" stroke="none" 
	d="
M155.224915,149.809937 
	C154.798325,149.767456 154.629257,149.504532 154.307449,149.094757 
	C154.211960,148.540909 154.323471,148.099014 154.564453,147.339569 
	C154.938675,147.889450 155.183426,148.756912 155.224915,149.809937 
z"/>
<path fill="#208DB1" opacity="1.000000" stroke="none" 
	d="
M189.274445,154.131409 
	C189.768219,153.801224 190.075043,153.845871 190.587677,154.077896 
	C190.666428,154.656677 190.523148,155.159546 190.162811,155.817688 
	C189.616852,155.874405 189.376892,155.670944 189.069275,155.177643 
	C188.976364,154.793427 189.040054,154.594116 189.274445,154.131409 
z"/>
<path fill="#0D3543" opacity="1.000000" stroke="none" 
	d="
M184.074585,159.805237 
	C183.678741,159.596024 183.496826,159.217300 183.332123,158.514145 
	C183.662415,158.671722 183.975479,159.153732 184.074585,159.805237 
z"/>
<path fill="#111A1E" opacity="1.000000" stroke="none" 
	d="
M154.394012,142.834259 
	C154.313309,142.940262 154.098694,142.506454 154.171600,142.275711 
	C154.493195,142.226654 154.569931,142.454407 154.394012,142.834259 
z"/>
<path fill="#111A1E" opacity="1.000000" stroke="none" 
	d="
M248.470490,125.996941 
	C249.169174,126.589737 249.874237,127.175224 250.564926,127.777184 
	C251.417999,128.520630 252.259094,129.277802 253.508453,130.421387 
	C254.005966,133.011658 254.100388,135.209595 254.427048,137.535889 
	C254.835388,138.110245 255.011490,138.556213 254.824448,139.079224 
	C250.786789,143.730972 250.186356,148.799332 251.846191,154.327530 
	C252.293854,155.818512 252.251541,157.456604 252.164459,158.958649 
	C251.581497,158.695358 251.268188,158.500580 250.927338,157.997375 
	C250.850769,157.488373 250.801727,157.287842 250.791626,156.699829 
	C250.213989,153.162781 248.152451,152.178864 245.357712,153.164825 
	C240.366074,154.925903 238.380798,152.526978 237.680237,147.629944 
	C237.528122,145.796921 237.341629,144.390839 237.269073,142.606384 
	C237.195602,139.820465 237.008209,137.412903 236.858246,134.760254 
	C236.895676,134.515182 236.648865,134.085144 236.648666,133.688660 
	C236.646332,132.499207 236.644196,131.706238 237.115295,130.864273 
	C241.140289,130.524994 244.692047,130.234711 248.346893,129.879013 
	C248.631332,129.608978 248.645355,129.390594 248.405731,128.767502 
	C248.369781,127.583344 248.420135,126.790138 248.470490,125.996941 
z"/>
<path fill="#71F0F7" opacity="1.000000" stroke="none" 
	d="
M251.842834,121.280617 
	C251.652756,119.187492 251.462677,117.094376 251.555099,114.421021 
	C253.233459,113.494400 254.629333,113.148010 256.330902,112.725746 
	C256.138580,110.821503 255.960770,109.060936 256.130371,107.181671 
	C260.599701,101.843758 265.659515,100.529907 271.827759,103.640137 
	C272.485748,104.283432 273.040466,104.687943 273.659119,105.517944 
	C273.686340,107.664688 273.649597,109.385933 273.262665,111.107956 
	C272.297363,112.398827 271.682220,113.688934 270.617432,114.989624 
	C266.977875,115.562073 263.059692,113.338318 260.796295,117.443092 
	C259.457275,119.871452 258.128601,122.305550 256.460083,124.738113 
	C255.454697,124.728355 254.784546,124.717552 253.905243,124.537949 
	C253.187241,124.213356 252.678406,124.057564 252.169571,123.901764 
	C252.169571,123.901764 252.137802,123.962975 252.187515,123.680740 
	C252.105759,122.692535 251.974304,121.986580 251.842834,121.280617 
z"/>
<path fill="#78F1F6" opacity="1.000000" stroke="none" 
	d="
M216.831421,109.013809 
	C216.879578,108.828217 216.927719,108.642624 217.405304,108.104309 
	C221.510956,100.947975 230.371017,99.849007 234.359543,106.970314 
	C234.559601,108.601967 234.759674,110.233612 235.057068,112.663406 
	C237.843445,114.406914 240.532501,115.352280 243.038086,116.632065 
	C239.862747,116.951591 236.698608,117.555939 233.917770,116.782425 
	C229.287903,115.494591 225.033600,115.089912 220.407471,116.896049 
	C217.286942,118.114365 213.765579,118.305977 210.291382,118.610046 
	C210.150757,117.522339 210.142242,116.781456 210.355194,116.038399 
	C210.576630,116.036232 211.004547,115.922043 211.004547,115.922043 
	C214.365585,115.796242 217.947632,115.940857 217.156754,110.154778 
	C216.996750,109.324211 216.914078,109.169014 216.831421,109.013809 
z"/>
<path fill="#3BE8FA" opacity="1.000000" stroke="none" 
	d="
M210.423523,118.956863 
	C213.765579,118.305977 217.286942,118.114365 220.407471,116.896049 
	C225.033600,115.089912 229.287903,115.494591 233.917770,116.782425 
	C236.698608,117.555939 239.862747,116.951591 243.436951,116.574356 
	C244.826950,116.119858 245.634598,116.057480 246.657410,115.992378 
	C246.872559,115.989655 247.302277,116.044548 247.302277,116.044548 
	C247.302277,116.044548 247.733429,116.002014 247.733429,116.002014 
	C247.764404,116.742455 247.795364,117.482895 247.665344,118.701660 
	C247.604294,119.474579 247.704224,119.769157 247.804169,120.063736 
	C247.819397,120.784203 247.834641,121.504662 247.474411,122.653091 
	C246.701920,123.684387 246.304901,124.287704 245.489655,124.918716 
	C243.394394,124.995178 241.717377,125.043930 239.867889,124.789139 
	C238.146362,123.240913 236.690765,121.851395 235.029938,120.779968 
	C230.832611,118.072174 223.609299,118.917778 221.285095,122.261284 
	C218.097275,126.847137 215.525742,127.290672 210.947174,123.767624 
	C210.584198,122.991920 210.359634,122.469055 210.202942,121.574677 
	C210.321732,120.454391 210.372635,119.705620 210.423523,118.956863 
z"/>
<path fill="#3DBFE1" opacity="1.000000" stroke="none" 
	d="
M254.114395,124.706741 
	C254.784546,124.717552 255.454697,124.728355 256.751465,124.830986 
	C257.576904,124.960968 257.775696,124.999130 257.993744,125.397644 
	C260.139709,128.822128 262.187622,131.945007 264.416107,134.933243 
	C266.497192,137.723770 266.846191,143.663757 264.465057,144.320206 
	C259.015198,145.822693 258.980194,149.750977 259.050476,154.346634 
	C259.042847,155.124390 259.042358,155.569000 258.778870,156.006317 
	C258.166229,156.005264 257.816559,156.011536 257.099731,155.826172 
	C256.494537,153.756638 256.256470,151.878738 256.089294,149.565033 
	C255.953354,146.802841 255.746567,144.476440 255.622467,141.834442 
	C255.532623,140.679962 255.360107,139.841080 255.187592,139.002182 
	C255.011490,138.556213 254.835388,138.110245 254.796692,137.295319 
	C254.623260,134.246918 254.312424,131.567459 254.001587,128.888000 
	C253.409256,127.783562 252.816925,126.679131 252.578201,125.218460 
	C253.326004,124.810394 253.720200,124.758568 254.114395,124.706741 
z"/>
<path fill="#D2EEEF" opacity="1.000000" stroke="none" 
	d="
M234.459900,106.563919 
	C230.371017,99.849007 221.510956,100.947975 217.509705,107.836334 
	C217.207062,105.574463 217.229462,103.227844 217.410461,100.540863 
	C217.820190,100.255852 218.012054,100.173355 218.413147,99.681976 
	C218.785172,99.263428 218.888718,99.115921 218.992249,98.968414 
	C220.711029,98.431145 222.396606,97.717110 224.154297,97.386940 
	C232.150574,95.884911 234.575058,97.966988 234.459900,106.563919 
z"/>
<path fill="#D2EEEF" opacity="1.000000" stroke="none" 
	d="
M271.724487,103.401360 
	C265.659515,100.529907 260.599701,101.843758 255.837494,106.884422 
	C255.169693,104.800171 255.142166,102.894478 255.114655,100.988777 
	C255.114655,100.988777 255.217224,100.912598 255.477173,100.690071 
	C256.138916,99.981537 256.540680,99.495529 256.942474,99.009529 
	C257.759064,98.387840 258.575623,97.766144 259.697266,97.016998 
	C261.398499,96.995461 262.794617,97.101372 264.914917,97.158699 
	C268.055298,97.509918 270.471436,97.909729 272.887604,98.309540 
	C272.865234,98.614708 272.980499,98.853424 273.495544,99.472702 
	C273.794495,100.957527 273.831299,101.995323 273.505249,103.049362 
	C272.669739,103.177521 272.197113,103.289436 271.724487,103.401360 
z"/>
<path fill="#1999B8" opacity="1.000000" stroke="none" 
	d="
M240.040344,125.092690 
	C241.717377,125.043930 243.394394,124.995178 245.795532,124.945786 
	C246.926163,124.923607 247.332718,124.902077 247.739258,124.880539 
	C247.871063,125.060097 248.002853,125.239647 248.302551,125.708069 
	C248.420135,126.790138 248.369781,127.583344 248.289612,128.946503 
	C248.259796,129.516464 248.243805,129.944412 248.243805,129.944412 
	C244.692047,130.234711 241.140289,130.524994 236.932434,130.769135 
	C236.116119,130.495483 235.999329,130.247513 235.978439,129.612213 
	C237.367355,127.861130 238.703857,126.476913 240.040344,125.092690 
z"/>
<path fill="#9EECF4" opacity="1.000000" stroke="none" 
	d="
M246.442245,115.995102 
	C245.634598,116.057480 244.826950,116.119858 243.620422,116.239937 
	C240.532501,115.352280 237.843445,114.406914 235.108994,113.072540 
	C236.862686,112.861877 238.667999,112.993088 240.459335,113.230003 
	C242.930923,113.556877 245.391968,113.963531 247.637024,114.794189 
	C247.091751,115.499947 246.766998,115.747528 246.442245,115.995102 
z"/>
<path fill="#091318" opacity="1.000000" stroke="none" 
	d="
M254.744843,101.077614 
	C255.142166,102.894478 255.169693,104.800171 255.490082,107.003120 
	C255.960770,109.060936 256.138580,110.821503 256.330902,112.725746 
	C254.629333,113.148010 253.233459,113.494400 251.463409,113.979179 
	C253.395676,111.066864 254.826172,107.908463 253.147797,103.962090 
	C252.874847,103.320274 253.932449,102.112579 254.744843,101.077614 
z"/>
<path fill="#112026" opacity="1.000000" stroke="none" 
	d="
M253.963013,129.182953 
	C254.312424,131.567459 254.623260,134.246918 254.564453,137.166946 
	C254.100388,135.209595 254.005966,133.011658 253.850449,130.435516 
	C253.834366,129.864166 253.879395,129.671051 253.963013,129.182953 
z"/>
<path fill="#3DBFE1" opacity="1.000000" stroke="none" 
	d="
M251.540344,121.328033 
	C251.974304,121.986580 252.105759,122.692535 252.157410,123.663895 
	C251.797684,123.078011 251.517761,122.226730 251.540344,121.328033 
z"/>
<path fill="#24CFF1" opacity="1.000000" stroke="none" 
	d="
M247.794891,124.551682 
	C247.332718,124.902077 246.926163,124.923607 246.213745,124.918083 
	C246.304901,124.287704 246.701920,123.684387 247.372025,122.999245 
	C247.713562,123.352554 247.782043,123.787689 247.794891,124.551682 
z"/>
<path fill="#41E1FC" opacity="1.000000" stroke="none" 
	d="
M253.905243,124.537949 
	C253.720200,124.758568 253.326004,124.810394 252.636932,124.906746 
	C252.246002,124.787483 252.149963,124.623711 252.111755,124.180847 
	C252.678406,124.057564 253.187241,124.213356 253.905243,124.537949 
z"/>
<path fill="#1999B8" opacity="1.000000" stroke="none" 
	d="
M246.657410,115.992386 
	C246.766998,115.747528 247.091751,115.499947 247.642609,115.203308 
	C247.868729,115.154259 247.946732,115.602791 247.840088,115.802399 
	C247.733429,116.002014 247.302277,116.044548 247.302277,116.044548 
	C247.302277,116.044548 246.872559,115.989655 246.657410,115.992386 
z"/>
<path fill="#091318" opacity="1.000000" stroke="none" 
	d="
M256.619263,99.112823 
	C256.540680,99.495529 256.138916,99.981537 255.459000,100.628830 
	C255.552582,100.265450 255.924301,99.740784 256.619263,99.112823 
z"/>
<path fill="#111A1E" opacity="1.000000" stroke="none" 
	d="
M218.831635,98.885010 
	C218.888718,99.115921 218.785172,99.263428 218.432892,99.516785 
	C218.346451,99.348969 218.508743,99.075287 218.831635,98.885010 
z"/>
<path fill="#1999B8" opacity="1.000000" stroke="none" 
	d="
M247.891815,119.786621 
	C247.704224,119.769157 247.604294,119.474579 247.642395,119.071671 
	C247.846771,119.145393 247.913116,119.327446 247.891815,119.786621 
z"/>
<path fill="#739896" opacity="1.000000" stroke="none" 
	d="
M253.282135,60.279755 
	C254.117798,63.353680 254.580460,66.374130 255.050262,69.441299 
	C256.998688,69.637718 258.545197,69.793625 260.292236,70.186470 
	C248.811859,75.572548 245.832626,74.051636 242.837082,61.952534 
	C246.188843,61.141380 249.548981,60.683826 253.282135,60.279755 
z"/>
<path fill="#1685C9" opacity="1.000000" stroke="none" 
	d="
M228.935287,68.086166 
	C228.780029,65.282524 228.624771,62.478886 228.469498,59.675243 
	C232.150757,59.808132 235.832001,59.941021 239.816284,60.293633 
	C240.094650,61.375484 240.070007,62.237617 239.820007,63.662346 
	C239.352341,64.440750 239.156525,64.692711 238.603714,64.985474 
	C235.111877,66.022125 232.023575,67.054146 228.935287,68.086166 
z"/>
<path fill="#21AFD6" opacity="1.000000" stroke="none" 
	d="
M228.875275,68.383003 
	C232.023575,67.054146 235.111877,66.022125 238.629272,65.253738 
	C239.083725,65.695076 239.109055,65.872795 239.134399,66.050522 
	C238.766632,67.098854 238.398880,68.147186 237.638580,69.613426 
	C235.497955,71.047722 233.749878,72.064117 232.001801,73.080513 
	C231.563232,73.066330 231.124680,73.052147 230.358551,73.014038 
	C229.834732,72.107536 229.638504,71.224953 229.184082,70.110886 
	C228.889023,69.479538 228.852142,69.079697 228.875275,68.383003 
z"/>
<path fill="#59BADC" opacity="1.000000" stroke="none" 
	d="
M232.248688,73.300278 
	C233.749878,72.064117 235.497955,71.047722 237.576721,69.941437 
	C237.428864,73.029633 235.445023,73.987091 232.248688,73.300278 
z"/>
<path fill="#59BADC" opacity="1.000000" stroke="none" 
	d="
M239.394913,65.988266 
	C239.109055,65.872795 239.083725,65.695076 239.032806,65.249092 
	C239.156525,64.692711 239.352341,64.440750 239.795197,64.091156 
	C239.882278,64.613586 239.768845,65.269798 239.394913,65.988266 
z"/>
<path fill="#739896" opacity="1.000000" stroke="none" 
	d="
M264.170654,72.317383 
	C264.526184,72.239441 265.016174,72.380852 265.608459,72.765442 
	C265.242188,72.851318 264.773621,72.694031 264.170654,72.317383 
z"/>
<path fill="#142122" opacity="1.000000" stroke="none" 
	d="
M211.054626,115.576691 
	C211.004547,115.922043 210.576630,116.036232 210.065689,116.225784 
	C207.689392,117.276062 205.824020,118.136803 203.958649,118.997528 
	C203.269699,118.235771 202.580765,117.474014 201.697815,116.468399 
	C206.541748,114.444054 205.642059,109.532639 207.021759,105.418579 
	C212.341125,107.541931 213.267929,109.872932 211.054626,115.576691 
z"/>
<path fill="#142122" opacity="1.000000" stroke="none" 
	d="
M216.720795,109.131470 
	C216.914078,109.169014 216.996750,109.324211 217.039581,109.740173 
	C216.643097,109.850655 216.513229,109.600052 216.720795,109.131470 
z"/>
<path fill="#57E3FB" opacity="1.000000" stroke="none" 
	d="
M293.456238,97.254227 
	C293.344696,96.807114 293.233185,96.359993 292.654297,95.596809 
	C291.810547,95.052780 291.434113,94.824814 291.455048,94.352600 
	C296.562134,93.439064 301.271881,92.769783 306.121521,92.289406 
	C306.489960,92.711479 306.765625,92.852791 307.390137,93.007767 
	C308.416351,94.078362 309.140808,95.043434 309.676147,96.303741 
	C309.664307,99.034218 309.882355,101.467331 310.010773,103.905159 
	C310.328918,109.945427 310.606110,115.987854 310.515137,122.024315 
	C302.340637,121.946899 298.848572,115.743584 294.277466,110.509064 
	C293.949463,105.787857 293.702850,101.521042 293.456238,97.254227 
z"/>
<path fill="#D2EEEF" opacity="1.000000" stroke="none" 
	d="
M309.865234,96.008514 
	C309.140808,95.043434 308.416351,94.078362 307.254700,92.813286 
	C306.588470,92.275261 306.309875,92.137672 305.981628,92.100510 
	C301.271881,92.769783 296.562134,93.439064 291.126282,94.356247 
	C289.292023,94.378662 288.183868,94.153175 287.075684,93.927689 
	C287.532593,92.276878 287.505951,90.187721 288.533508,89.054169 
	C292.205841,85.002991 306.375000,84.660248 309.702698,88.918365 
	C311.578613,91.318787 311.720520,95.074371 312.290466,98.480148 
	C311.246674,97.830040 310.555939,96.919281 309.865234,96.008514 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M293.296112,97.636948 
	C293.702850,101.521042 293.949463,105.787857 294.153503,110.965630 
	C294.093262,115.682968 294.025177,119.489906 294.067627,123.295616 
	C294.181732,133.531921 294.343109,143.767685 294.162323,153.815338 
	C293.558899,150.246231 293.256714,146.867096 293.003693,143.484314 
	C292.557495,137.519257 292.141266,131.551956 291.712524,125.585594 
	C291.298828,125.465904 290.885132,125.346207 290.471436,125.226517 
	C289.644897,126.490982 288.818329,127.755447 287.661255,128.975403 
	C287.111542,128.893356 286.892365,128.855820 286.820374,128.441360 
	C287.245819,124.699913 287.524017,121.335396 287.820770,117.621140 
	C287.748810,116.818275 287.658325,116.365166 287.681763,115.876160 
	C287.795715,115.840271 288.014404,115.936539 288.240417,115.766876 
	C288.680298,115.083771 288.894165,114.570320 289.108002,114.056870 
	C292.924103,112.384987 294.589233,108.874153 292.408112,104.625107 
	C290.949921,101.784363 291.867645,100.099884 293.296112,97.636948 
z"/>
<path fill="#88EEF8" opacity="1.000000" stroke="none" 
	d="
M309.676147,96.303741 
	C310.555939,96.919281 311.246674,97.830040 312.197327,98.876175 
	C312.555481,101.055893 312.653717,103.100243 312.755981,106.031586 
	C312.606506,112.622520 312.453033,118.326447 312.083008,124.004036 
	C311.575653,123.986214 311.285034,123.998871 310.991547,123.683907 
	C310.958923,122.911240 310.929352,122.470322 310.899780,122.029411 
	C310.606110,115.987854 310.328918,109.945427 310.010773,103.905159 
	C309.882355,101.467331 309.664307,99.034218 309.676147,96.303741 
z"/>
<path fill="#091318" opacity="1.000000" stroke="none" 
	d="
M312.299591,124.030373 
	C312.453033,118.326447 312.606506,112.622520 312.866394,106.461411 
	C313.421722,106.003403 313.870667,106.002586 314.656128,106.004837 
	C314.656494,116.014252 314.361084,126.022346 313.930573,136.024628 
	C313.878967,137.223282 313.093323,138.390335 312.225403,139.327911 
	C311.946503,134.717575 312.090118,130.351364 312.381866,125.671181 
	C312.453217,124.914940 312.376404,124.472656 312.299591,124.030373 
z"/>
<path fill="#091318" opacity="1.000000" stroke="none" 
	d="
M273.868134,103.033127 
	C273.831299,101.995323 273.794495,100.957527 273.695923,99.524300 
	C275.299255,99.494095 276.964355,99.859322 278.629456,100.224541 
	C278.009094,101.618668 277.174896,102.960930 276.821136,104.419701 
	C276.352448,106.352310 276.259369,108.375992 276.064850,109.892776 
	C280.108582,110.518242 283.283356,111.009293 286.582642,111.911926 
	C286.707123,112.323509 286.429443,112.630112 286.003998,112.594879 
	C281.589966,112.075485 277.601410,111.591331 273.612823,111.107178 
	C273.649597,109.385933 273.686340,107.664688 273.808594,105.189819 
	C273.885406,103.968498 273.876770,103.500816 273.868134,103.033127 
z"/>
<path fill="#091318" opacity="1.000000" stroke="none" 
	d="
M272.847076,97.937729 
	C270.471436,97.909729 268.055298,97.509918 265.321472,97.029480 
	C267.910004,93.294678 270.376495,95.200081 272.847076,97.937729 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M286.926697,94.193939 
	C288.183868,94.153175 289.292023,94.378662 290.728943,94.600502 
	C291.434113,94.824814 291.810547,95.052780 292.412903,95.520844 
	C290.685150,95.327362 288.731415,94.893776 286.926697,94.193939 
z"/>
<path fill="#091318" opacity="1.000000" stroke="none" 
	d="
M288.954956,113.795029 
	C288.894165,114.570320 288.680298,115.083771 288.133820,115.479233 
	C287.343933,114.450874 286.886688,113.540497 286.429443,112.630112 
	C286.429443,112.630112 286.707123,112.323509 286.845764,112.170486 
	C287.590240,112.522705 288.196106,113.027946 288.954956,113.795029 
z"/>
<path fill="#111A1E" opacity="1.000000" stroke="none" 
	d="
M278.005371,91.996078 
	C278.010742,91.992027 278.359222,92.218941 278.359222,92.218941 
	C278.359222,92.218941 278.090637,92.207199 278.041260,92.109161 
	C277.991882,92.011124 278.000000,92.000122 278.005371,91.996078 
z"/>
<path fill="#1799C0" opacity="1.000000" stroke="none" 
	d="
M275.567139,62.885525 
	C275.185120,62.186981 275.103699,61.491096 275.047729,60.445824 
	C275.073212,60.096436 275.514618,59.926201 275.751221,59.925232 
	C276.314270,60.700153 276.640747,61.476048 277.021912,62.560184 
	C276.673615,62.875011 276.270691,62.881596 275.567139,62.885525 
z"/>
<path fill="#EAF8F3" opacity="1.000000" stroke="none" 
	d="
M143.934509,387.426514 
	C143.914185,382.038330 144.096893,377.102814 144.017151,372.171539 
	C143.959595,368.611298 145.493454,367.047821 149.021011,367.081207 
	C155.275955,367.140472 161.532013,367.078857 168.424164,367.427795 
	C169.309067,369.947510 169.557434,372.107239 169.815765,374.314148 
	C169.825745,374.361328 169.919373,374.338165 169.494705,374.478790 
	C164.843826,375.000946 160.600098,375.257782 156.403030,375.846985 
	C155.277451,376.005005 154.294083,377.176025 153.245422,377.882050 
	C154.286179,378.378693 155.294922,379.204712 156.373749,379.309509 
	C159.457703,379.609100 162.570145,379.615723 165.667664,379.788757 
	C165.663971,379.839966 165.745392,379.902496 165.821503,380.349884 
	C166.721481,387.261108 166.366592,387.460266 160.038651,387.924103 
	C157.848145,388.084656 154.357559,385.921967 153.793518,390.083740 
	C153.747467,390.423523 158.037399,391.578217 160.376938,391.960785 
	C163.882721,392.534058 167.445572,392.758423 171.005341,393.509644 
	C171.145294,394.638367 171.264069,395.388550 171.382843,396.138763 
	C170.318359,399.362183 168.173294,400.856415 164.699005,400.767975 
	C159.926743,400.646484 155.139877,400.618225 150.374191,400.852905 
	C145.546326,401.090637 143.867157,398.830597 144.193420,394.296295 
	C144.346207,392.172882 144.093872,390.020325 143.934509,387.426514 
z"/>
<path fill="#0CACD8" opacity="1.000000" stroke="none" 
	d="
M165.745392,379.902496 
	C165.745392,379.902496 165.663971,379.839966 165.868866,379.443176 
	C167.355621,377.476959 168.637497,375.907562 169.919373,374.338165 
	C169.919373,374.338165 169.825745,374.361328 170.084625,373.988892 
	C170.530518,371.448212 170.717514,369.279999 170.904495,367.111755 
	C172.320282,367.086212 173.736084,367.060638 175.586990,367.415710 
	C176.779434,369.165588 177.536774,370.534851 178.523819,372.164978 
	C183.535797,381.553406 188.318054,390.680908 193.100311,399.808380 
	C191.641174,400.526825 190.182022,401.245270 188.126617,401.741638 
	C186.687927,401.345978 185.845490,401.172394 185.003067,400.998779 
	C184.835785,400.999207 184.668518,400.999603 184.250305,401.000000 
	C181.464996,400.525909 178.854050,400.287262 176.422501,399.496796 
	C174.737427,398.948944 173.299591,397.640625 171.567062,396.405457 
	C171.264069,395.388550 171.145294,394.638367 170.944977,393.152588 
	C168.068787,390.662720 166.472900,388.538422 167.214355,384.855347 
	C167.514709,383.363434 166.281021,381.562714 165.745392,379.902496 
z"/>
<path fill="#0CACD8" opacity="1.000000" stroke="none" 
	d="
M141.954269,388.439117 
	C141.937851,382.116089 141.937851,376.229584 141.937851,370.343109 
	C142.291000,370.343414 142.644150,370.343689 142.997299,370.343994 
	C142.997299,375.584686 142.997299,380.825409 142.997742,386.534271 
	C142.990540,389.725220 142.984741,392.447998 142.970108,395.170746 
	C142.969330,395.315918 142.895599,395.584686 142.853226,395.585175 
	C142.607986,395.588226 142.361954,395.527985 142.036163,395.238281 
	C141.960999,392.950165 141.965836,390.912903 141.954269,388.439117 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M180.671112,406.957153 
	C181.569153,407.754272 182.115265,408.576935 182.661377,409.399597 
	C181.364578,409.931671 180.067780,410.463745 178.386078,410.991089 
	C176.646988,408.420410 177.623337,407.203766 180.671112,406.957153 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M163.340576,407.637207 
	C162.679672,409.335510 161.780396,410.795288 160.881134,412.255066 
	C160.294724,411.719391 159.708313,411.183746 159.121902,410.648071 
	C160.448685,409.564941 161.775452,408.481812 163.340576,407.637207 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M157.770874,413.826294 
	C157.191940,414.314911 156.358658,414.553040 155.204163,414.897827 
	C154.493149,414.977203 154.103333,414.949890 153.425491,414.973419 
	C152.940781,414.905121 152.744125,414.785980 152.266861,414.465729 
	C153.829681,414.035004 155.673096,413.805420 157.770874,413.826294 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M169.733551,408.523010 
	C170.535049,408.190002 171.502762,408.103394 172.470474,408.016785 
	C172.412231,408.532471 172.353989,409.048157 172.295746,409.563812 
	C171.497070,409.299042 170.698410,409.034241 169.733551,408.523010 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M126.523415,400.709351 
	C126.274780,400.201202 126.187569,399.482574 126.200394,398.411652 
	C126.428566,398.872528 126.556702,399.685699 126.523415,400.709351 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M126.432617,387.875671 
	C126.328918,387.971436 126.224869,387.500366 126.295288,387.270020 
	C126.616295,387.246643 126.673164,387.493408 126.432617,387.875671 
z"/>
<path fill="#FCC611" opacity="1.000000" stroke="none" 
	d="
M68.048470,384.313812 
	C66.481758,381.872803 64.870735,379.757935 63.492970,377.333862 
	C66.402115,378.024994 69.077995,379.025299 73.496437,380.677032 
	C72.822372,376.199463 72.337212,372.976746 71.852058,369.754028 
	C73.096054,366.716125 74.340050,363.678223 76.238396,360.333313 
	C78.673637,359.963501 80.454529,359.900665 82.235428,359.837830 
	C82.235428,359.837830 82.166786,359.927948 82.113419,360.273254 
	C83.435753,363.041534 84.811447,365.464508 86.187141,367.887482 
	C86.187141,367.887482 86.095108,367.896759 86.165024,368.238922 
	C90.377655,369.994690 94.612694,371.334259 93.169678,377.227722 
	C90.909821,382.295258 94.293770,386.556671 95.220909,391.569641 
	C92.715279,391.001312 89.894089,390.439087 87.644768,388.989502 
	C83.992348,386.635712 80.891220,385.542755 77.068260,388.813110 
	C74.638565,390.891632 71.491600,392.131653 68.361282,393.922485 
	C68.183800,391.565338 68.311317,389.033417 68.188919,386.226379 
	C67.960716,385.514191 67.982437,385.077057 68.048470,384.313812 
z"/>
<path fill="#F5D026" opacity="1.000000" stroke="none" 
	d="
M93.440079,377.029297 
	C94.612694,371.334259 90.377655,369.994690 86.169678,368.192902 
	C90.453362,368.132660 94.802292,368.460602 99.587952,368.898163 
	C100.078186,369.866455 100.520020,371.137085 100.119774,371.514404 
	C98.104073,373.414673 95.868721,375.081879 93.440079,377.029297 
z"/>
<path fill="#E656A2" opacity="1.000000" stroke="none" 
	d="
M75.854210,257.930267 
	C76.871407,256.848938 77.888596,255.767624 79.034973,254.393890 
	C79.499748,254.088028 79.763855,253.943726 80.322311,253.339111 
	C80.910881,252.671631 81.133606,252.333633 81.356323,251.995636 
	C81.356323,251.995636 81.825722,251.875275 81.982300,251.689636 
	C83.503479,251.348801 84.868088,251.193619 86.587753,251.033112 
	C91.108162,254.758942 88.137482,257.316193 85.277092,260.232422 
	C83.111855,261.408356 81.148750,262.276733 78.732025,263.504089 
	C77.787994,264.177490 77.297569,264.491913 76.807144,264.806335 
	C76.807137,264.806305 76.924294,264.766815 76.650795,264.773193 
	C76.377296,264.779541 75.955139,264.734619 75.982529,264.368347 
	C75.958015,261.978149 75.906113,259.954224 75.854210,257.930267 
z"/>
<path fill="#182935" opacity="1.000000" stroke="none" 
	d="
M81.075195,252.119904 
	C81.133606,252.333633 80.910881,252.671631 80.399948,253.120178 
	C80.339180,252.901901 80.566620,252.573044 81.075195,252.119904 
z"/>
<path fill="#12242D" opacity="1.000000" stroke="none" 
	d="
M68.470047,276.069977 
	C68.568871,274.923828 68.983391,273.642456 70.058212,272.162537 
	C73.553108,271.380890 73.442200,269.892853 72.070358,267.679504 
	C73.138680,266.965729 74.079620,266.550781 75.150459,266.315765 
	C75.488670,266.713501 75.733757,266.875732 76.015625,266.982422 
	C76.843262,267.871704 77.694435,269.535828 78.494339,269.511536 
	C81.422791,269.422607 84.334190,268.771637 87.618507,268.171509 
	C90.405380,267.683319 92.825745,267.355957 95.496834,267.314514 
	C96.040871,268.907593 96.334190,270.214813 96.627510,271.522003 
	C95.957840,271.421509 95.288177,271.321014 94.039680,270.848724 
	C92.770447,270.428619 92.080048,270.380280 91.389648,270.331940 
	C83.320007,270.041077 75.497810,270.748535 68.470047,276.069977 
z"/>
<path fill="#182935" opacity="1.000000" stroke="none" 
	d="
M76.237457,266.765869 
	C75.733757,266.875732 75.488670,266.713501 75.290047,266.070618 
	C75.518211,265.341888 75.736671,265.038269 75.955139,264.734619 
	C75.955139,264.734619 76.377296,264.779541 76.588982,264.771759 
	C76.686882,265.359100 76.573090,265.954224 76.237457,266.765869 
z"/>
<path fill="#182935" opacity="1.000000" stroke="none" 
	d="
M77.126915,264.813293 
	C77.297569,264.491913 77.787994,264.177490 78.590103,263.809967 
	C78.416763,264.111298 77.931725,264.465759 77.126915,264.813293 
z"/>
<path fill="#172831" opacity="1.000000" stroke="none" 
	d="
M96.629303,271.502686 
	C96.334190,270.214813 96.040871,268.907593 95.870590,267.317841 
	C96.454079,267.041718 96.914520,267.048157 97.720352,267.089233 
	C98.192940,267.389679 98.374985,267.610199 98.797287,267.898865 
	C98.988281,268.759125 98.993881,269.506012 98.599953,270.656097 
	C97.687355,271.204224 97.174286,271.349121 96.661209,271.494049 
	C96.661217,271.494049 96.631096,271.483398 96.629303,271.502686 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M96.818321,271.732788 
	C97.174286,271.349121 97.687355,271.204224 98.598686,271.029663 
	C98.983238,271.500763 98.969536,272.001556 98.931824,272.877502 
	C98.263687,272.825592 97.619560,272.398560 96.818321,271.732788 
z"/>
<path fill="#1A2B2B" opacity="1.000000" stroke="none" 
	d="
M55.806793,264.371887 
	C56.137360,264.568634 56.464596,265.067108 56.735489,265.879333 
	C56.387257,265.686584 56.095360,265.180115 55.806793,264.371887 
z"/>
<path fill="#F5D026" opacity="1.000000" stroke="none" 
	d="
M71.462357,369.810669 
	C72.337212,372.976746 72.822372,376.199463 73.496437,380.677032 
	C69.077995,379.025299 66.402115,378.024994 63.345108,377.032349 
	C62.963993,377.040039 62.985695,377.012299 62.788307,376.779419 
	C62.000168,376.381927 61.409424,376.217285 60.818676,376.052673 
	C60.096661,375.710388 59.374649,375.368073 58.366898,374.935852 
	C58.037849,374.434845 57.994541,374.023773 57.971123,373.302155 
	C62.351559,371.950134 66.712112,370.908722 71.462357,369.810669 
z"/>
<path fill="#F5D026" opacity="1.000000" stroke="none" 
	d="
M82.255035,359.623322 
	C80.454529,359.900665 78.673637,359.963501 76.450348,360.052032 
	C77.024788,358.166229 78.041618,356.254761 79.136734,354.047180 
	C79.215019,353.751099 79.388420,353.632416 79.475037,353.572906 
	C79.595848,353.837616 79.737297,354.105164 80.189743,354.426147 
	C80.486694,354.855408 80.579910,355.174469 80.561066,355.967102 
	C81.011734,357.293976 81.574463,358.147278 82.137192,359.000580 
	C82.137192,359.000580 82.274635,359.408813 82.255035,359.623322 
z"/>
<path fill="#F5D026" opacity="1.000000" stroke="none" 
	d="
M86.153603,367.529541 
	C84.811447,365.464508 83.435753,363.041534 82.115356,360.216644 
	C83.487129,362.267029 84.803596,364.719330 86.153603,367.529541 
z"/>
<path fill="#C8B93E" opacity="1.000000" stroke="none" 
	d="
M82.181046,358.665680 
	C81.574463,358.147278 81.011734,357.293976 80.608620,356.212677 
	C80.768227,355.984680 81.182098,356.198334 81.389992,356.303284 
	C81.806892,357.049072 82.015892,357.689911 82.181046,358.665680 
z"/>
<path fill="#FCC611" opacity="1.000000" stroke="none" 
	d="
M61.038181,376.271515 
	C61.409424,376.217285 62.000168,376.381927 62.787563,376.787720 
	C62.408703,376.849426 61.833195,376.669891 61.038181,376.271515 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M91.421844,278.500671 
	C90.110016,278.864197 88.548958,278.930237 86.526291,278.991669 
	C86.035645,278.637390 86.006607,278.287720 85.967422,277.674988 
	C87.695724,277.675690 89.434158,277.939423 91.421844,278.500671 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M83.552643,277.145935 
	C82.208038,277.426056 80.453079,277.592468 78.402855,277.592468 
	C79.785820,277.294800 81.464066,277.163513 83.552643,277.145935 
z"/>
<path fill="#163643" opacity="1.000000" stroke="none" 
	d="
M91.658867,270.557098 
	C92.080048,270.380280 92.770447,270.428619 93.724976,270.711548 
	C93.302094,270.891510 92.615089,270.836884 91.658867,270.557098 
z"/>
<path fill="#ECBF19" opacity="1.000000" stroke="none" 
	d="
M68.942780,281.643005 
	C70.006447,281.521271 71.162971,281.496033 72.826439,281.878296 
	C78.213867,283.865479 83.094360,285.445129 88.165077,287.299011 
	C90.351753,289.277496 92.401886,290.923492 94.330666,292.701202 
	C96.600342,294.793091 96.681808,296.907654 94.073769,298.852570 
	C90.325562,296.192627 86.660095,293.404297 82.800552,290.917114 
	C80.151947,289.210297 77.233635,287.921997 74.003868,285.974182 
	C72.108627,284.322418 70.645401,283.143097 69.182175,281.963806 
	C69.182175,281.963806 69.035652,281.739532 68.942780,281.643005 
z"/>
<path fill="#C8B93E" opacity="1.000000" stroke="none" 
	d="
M94.245865,299.012634 
	C96.681808,296.907654 96.600342,294.793091 94.330666,292.701202 
	C92.401886,290.923492 90.351753,289.277496 88.299706,287.143311 
	C88.244110,286.713379 88.512543,286.401276 88.820786,286.500549 
	C89.731346,286.831757 90.333649,287.063660 90.935944,287.295532 
	C93.178207,289.665894 95.420464,292.036224 97.822250,294.698120 
	C97.950638,295.445465 97.919510,295.901184 97.989517,296.686157 
	C98.090645,297.015350 98.005020,297.461456 97.762062,297.819092 
	C97.296120,298.435791 97.073135,298.694885 96.850159,298.953979 
	C96.576256,298.930237 96.321899,298.989838 95.951416,299.276794 
	C95.349815,299.338104 94.883888,299.255402 94.245865,299.012634 
z"/>
<path fill="#C8B93E" opacity="1.000000" stroke="none" 
	d="
M68.796249,282.138367 
	C70.645401,283.143097 72.108627,284.322418 73.734695,285.753784 
	C71.638519,285.413727 68.033096,286.822113 68.796249,282.138367 
z"/>
<path fill="#E167B4" opacity="1.000000" stroke="none" 
	d="
M75.982529,264.368347 
	C75.736671,265.038269 75.518211,265.341888 75.160156,265.890686 
	C74.079620,266.550781 73.138680,266.965729 71.821350,267.748779 
	C71.294380,268.409790 71.143806,268.702759 70.993240,268.995728 
	C68.365906,269.380707 66.732491,268.579346 68.566383,265.910126 
	C70.484283,263.118622 72.982834,260.726074 75.540894,258.044708 
	C75.906113,259.954224 75.958015,261.978149 75.982529,264.368347 
z"/>
<path fill="#172831" opacity="1.000000" stroke="none" 
	d="
M70.959076,269.312439 
	C71.143806,268.702759 71.294380,268.409790 71.693970,268.047546 
	C73.442200,269.892853 73.553108,271.380890 70.358917,271.980042 
	C70.307854,271.207092 70.616379,270.418091 70.959076,269.312439 
z"/>
<path fill="#C8B93E" opacity="1.000000" stroke="none" 
	d="
M88.109482,286.869080 
	C83.094360,285.445129 78.213867,283.865479 73.147552,282.017029 
	C76.226929,282.306335 79.492126,282.864410 83.132858,283.497131 
	C83.508400,283.571777 83.629539,283.563873 83.838821,283.761353 
	C85.536247,284.772949 87.024399,285.587128 88.512543,286.401276 
	C88.512543,286.401276 88.244110,286.713379 88.109482,286.869080 
z"/>
<path fill="#111A1E" opacity="1.000000" stroke="none" 
	d="
M88.820786,286.500549 
	C87.024399,285.587128 85.536247,284.772949 83.863686,283.705994 
	C86.015762,284.510834 88.352264,285.568512 90.812363,286.960876 
	C90.333649,287.063660 89.731346,286.831757 88.820786,286.500549 
z"/>
<path fill="#152023" opacity="1.000000" stroke="none" 
	d="
M97.085304,298.837555 
	C97.073135,298.694885 97.296120,298.435791 97.746910,298.045715 
	C97.756630,298.183533 97.538536,298.452332 97.085304,298.837555 
z"/>
<path fill="#182A30" opacity="1.000000" stroke="none" 
	d="
M399.820068,237.834137 
	C399.869171,238.067657 399.918243,238.301178 400.041748,238.884674 
	C399.955017,239.797958 399.793915,240.361267 399.194855,240.947281 
	C398.623901,241.434097 398.490875,241.898224 398.357880,242.362335 
	C398.357880,242.362335 398.223480,242.197968 398.147156,242.252594 
	C398.070831,242.307236 397.767944,242.575745 397.767944,242.575745 
	C397.767944,242.575745 397.964905,242.988419 397.964905,242.988419 
	C396.748779,246.214035 395.532623,249.439651 394.439758,253.018890 
	C390.390625,253.571701 388.153473,256.020355 386.389099,260.008057 
	C383.469208,259.806854 381.234741,259.412781 378.750519,258.777893 
	C377.003296,258.682343 375.505859,258.827637 373.558533,259.095886 
	C366.744446,259.281494 360.380188,259.344177 354.006104,259.062378 
	C352.743561,257.557800 351.490814,256.397705 350.238098,255.237579 
	C349.487885,256.658478 348.737640,258.079376 347.628906,259.628906 
	C346.515320,259.725525 345.760254,259.693542 345.001953,259.301086 
	C344.248383,257.334991 343.498077,255.729370 342.747772,254.123718 
	C342.152527,254.359482 341.557251,254.595245 340.962006,254.831009 
	C341.322357,256.590118 341.682678,258.349243 341.761444,260.289978 
	C339.063599,262.315948 336.647369,264.160309 333.922485,265.998779 
	C333.408142,265.987885 333.202393,265.982819 332.939758,265.635864 
	C330.872589,264.010223 328.862335,262.726501 326.852051,261.442780 
	C325.517853,259.085510 324.061371,256.123901 321.748535,255.103790 
	C320.109314,254.380753 317.312836,256.281433 314.931366,256.629913 
	C314.756378,255.996536 314.682953,255.744247 314.484619,255.357056 
	C314.359741,255.222137 314.067993,254.998413 314.309570,254.617126 
	C314.690704,253.652618 314.830292,253.069382 315.046570,252.526550 
	C315.123260,252.566940 315.000092,252.446579 315.231018,252.066803 
	C315.604340,251.115280 315.746674,250.543549 316.037720,249.784668 
	C316.186401,249.597534 316.217010,249.120468 316.178101,249.095886 
	C316.139221,249.071304 316.147034,249.162994 316.404419,248.986298 
	C316.899170,248.240387 317.136536,247.671173 317.567200,246.975647 
	C317.908752,246.426468 318.057037,246.003601 318.441071,245.405106 
	C318.749878,245.047623 318.822937,244.865768 319.163605,244.506134 
	C324.230042,238.509720 330.131226,234.442505 338.108734,232.916840 
	C341.589172,232.942459 344.572998,232.936401 347.784973,232.809052 
	C348.338409,232.780014 348.663727,232.872269 349.246277,233.211182 
	C350.195465,233.598938 350.887482,233.740021 351.925659,233.839279 
	C356.604523,235.840408 360.937134,237.883392 365.350281,240.160400 
	C365.978455,240.698502 366.526123,241.002548 367.256897,241.544373 
	C367.621796,241.865677 367.803619,241.949173 367.985046,242.296967 
	C368.001343,242.736649 368.018066,242.912064 368.172028,243.281372 
	C368.536469,243.710052 368.808350,243.867783 369.459290,243.944916 
	C370.223511,244.054611 370.653412,244.167862 371.268341,244.429230 
	C371.950012,244.342682 372.446655,244.107971 372.971069,243.909546 
	C372.998871,243.945801 372.913055,243.914413 373.218750,243.898926 
	C373.996185,243.538025 374.467865,243.192596 374.950317,242.887451 
	C374.961060,242.927750 374.878571,242.939880 375.109741,242.813507 
	C375.533783,242.400406 375.726624,242.113678 376.007996,241.572678 
	C376.322876,240.844101 376.549194,240.369781 376.844238,239.897690 
	C376.912933,239.899933 376.883240,239.765717 377.164917,239.663559 
	C378.268066,238.687332 379.089478,237.813278 380.247040,236.947266 
	C381.025360,235.920166 381.467529,234.885040 381.982361,233.551834 
	C382.898163,232.117218 383.741302,230.980682 384.887573,229.724731 
	C385.438538,229.033936 385.686310,228.462524 385.982605,227.671249 
	C386.160248,227.160065 386.374084,226.958572 387.052856,226.819122 
	C387.896545,225.751099 388.359955,224.710876 388.895508,223.418991 
	C389.301880,222.773392 389.636078,222.379456 390.226501,221.994064 
	C390.653656,222.003677 390.824554,222.004776 391.138550,222.321915 
	C391.519592,223.754929 391.757477,224.871872 391.741699,226.212311 
	C390.584442,227.788452 389.883972,229.353287 388.736755,230.451401 
	C386.008759,233.062683 387.086121,234.254150 390.036591,235.355194 
	C389.008026,238.966949 387.872498,242.164108 387.217712,245.456879 
	C387.009766,246.502594 388.082214,247.802917 388.568756,248.986740 
	C389.690613,248.261948 390.975098,247.694260 391.878815,246.758728 
	C392.512451,246.102783 392.626678,244.945053 393.170349,243.862396 
	C393.298706,243.148315 393.229523,242.583221 393.112610,241.756989 
	C393.107788,241.324158 393.150696,241.152466 393.357300,240.806824 
	C393.707672,240.360596 393.799896,240.059372 393.810364,239.782593 
	C393.823090,239.835999 393.729523,239.778549 394.050232,239.840210 
	C394.586487,239.932724 394.802032,239.963577 395.316284,239.837921 
	C397.016632,239.065659 398.418365,238.449905 399.820068,237.834137 
M366.580353,255.623138 
	C368.226257,255.936981 369.872192,256.250824 371.518097,256.564667 
	C371.490479,255.793045 371.462860,255.021408 371.435272,254.249771 
	C369.913788,254.493637 368.392303,254.737488 366.580353,255.623138 
M363.154816,241.303497 
	C362.572998,241.838760 361.991180,242.374008 361.409363,242.909256 
	C362.099548,243.205322 362.789734,243.501389 363.479919,243.797455 
	C363.567596,243.059967 363.655304,242.322479 363.154816,241.303497 
M367.725677,248.428970 
	C367.546387,247.712677 367.367096,246.996368 367.187836,246.280060 
	C366.639008,246.462433 366.090179,246.644821 365.541351,246.827179 
	C366.087006,247.430603 366.632660,248.034027 367.725677,248.428970 
M370.505676,247.157486 
	C370.825867,247.096436 371.146027,247.035400 371.466217,246.974365 
	C371.148346,246.901779 370.830505,246.829193 370.505676,247.157486 
z"/>
<path fill="#E96D0B" opacity="1.000000" stroke="none" 
	d="
M373.849945,340.076233 
	C373.689453,340.022949 373.528992,339.969635 373.044556,339.441284 
	C370.218353,338.616547 367.644348,337.707550 365.226776,338.017761 
	C356.571045,339.128418 347.973358,340.684998 339.342651,342.000488 
	C336.607910,342.417328 333.838654,342.607849 330.685669,342.951019 
	C329.515503,342.799286 328.744873,342.598480 327.906494,342.182465 
	C327.252197,341.936127 326.665649,341.904999 325.734924,341.911041 
	C324.930420,341.947632 324.470093,341.947021 324.009796,341.946411 
	C324.009796,341.946411 323.633545,341.685059 323.388397,341.497864 
	C323.143250,341.310669 323.104797,340.937439 323.200195,340.894775 
	C323.295593,340.852142 323.272156,340.587067 323.272156,340.587067 
	C323.272156,340.587067 323.057709,340.921509 322.726685,340.940308 
	C321.955627,340.944916 321.515625,340.930725 321.075623,340.916504 
	C321.075623,340.916504 320.889557,340.529602 320.710754,340.318359 
	C320.354370,340.061188 320.187805,339.991150 320.032227,339.897003 
	C319.680573,339.789185 319.328918,339.681396 318.445923,338.983917 
	C315.922791,337.617523 313.930939,336.840820 311.954468,336.046387 
	C311.969788,336.028687 311.968170,336.070190 311.812012,335.833435 
	C311.143799,335.384705 310.631775,335.172699 310.075012,334.969574 
	C310.030304,334.978455 310.013428,334.888855 309.950195,334.547302 
	C308.799286,333.600647 307.711609,332.995575 306.623932,332.390503 
	C306.623932,332.390503 306.713226,332.316376 306.672302,332.014343 
	C305.452576,331.132050 304.273743,330.551788 303.060242,329.978424 
	C303.025574,329.985352 303.023956,329.914673 302.955139,329.611084 
	C302.244293,328.875092 301.602203,328.442749 300.728943,327.929596 
	C300.205139,327.656830 300.035736,327.387390 299.943298,326.729370 
	C299.289520,325.934418 298.682037,325.450562 298.042542,324.978455 
	C298.010498,324.990234 298.034241,324.926239 297.978516,324.625580 
	C297.471985,323.975494 297.021179,323.626068 296.622620,323.308716 
	C296.674835,323.340790 296.577179,323.414856 296.666382,323.062469 
	C296.157684,321.475281 295.559753,320.240479 295.249329,318.876160 
	C296.327850,318.501343 297.118927,318.256073 298.206482,318.167847 
	C301.673584,321.226715 304.844177,324.128479 308.146118,327.230164 
	C308.491669,327.678284 308.753571,327.856293 309.360718,328.130707 
	C313.486267,330.199341 317.166901,332.520599 321.168915,333.928192 
	C335.830688,339.085052 350.466248,337.649475 365.022400,333.159058 
	C366.716400,332.636475 368.575012,332.256836 370.322113,332.367371 
	C376.546722,332.761200 382.756134,333.395416 389.329895,333.951172 
	C391.093323,334.621277 392.498077,335.278809 393.902863,335.936340 
	C393.692261,336.501709 393.481689,337.067047 392.622040,337.817505 
	C386.393951,338.959717 380.814941,339.916809 375.235901,340.873932 
	C375.235901,340.873932 375.109467,340.960663 374.901917,340.725250 
	C374.451263,340.273590 374.169800,340.135712 373.849945,340.076233 
z"/>
<path fill="#F0F2E2" opacity="1.000000" stroke="none" 
	d="
M345.005219,259.661560 
	C345.760254,259.693542 346.515320,259.725525 348.050171,259.742432 
	C350.558624,259.620483 352.287292,259.513641 354.015961,259.406830 
	C360.380188,259.344177 366.744446,259.281494 373.964996,259.201416 
	C376.214294,259.128876 377.607300,259.073792 379.000275,259.018707 
	C381.234741,259.412781 383.469208,259.806854 386.075317,260.248718 
	C390.469543,262.977722 393.068298,266.452881 392.519165,271.937988 
	C392.307617,272.310577 392.014954,272.616699 391.850006,272.395203 
	C391.143890,272.072998 390.602722,271.972351 389.927429,271.553040 
	C386.604950,267.240631 382.267853,265.804810 377.409332,266.244843 
	C362.446381,267.600189 346.845917,263.816010 332.703064,271.328247 
	C333.226837,269.306488 333.728973,267.655579 334.231110,266.004639 
	C336.647369,264.160309 339.063599,262.315948 342.140289,260.326630 
	C343.535553,260.008301 344.270386,259.834930 345.005219,259.661560 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M392.014954,272.616699 
	C392.014954,272.616699 392.307617,272.310577 392.519226,272.300598 
	C393.143585,285.567444 393.550964,298.844421 393.972778,312.120941 
	C394.083740,315.613983 394.239319,319.105652 394.449341,323.408630 
	C394.628143,326.182281 394.732086,328.145172 394.836029,330.108093 
	C394.667847,331.854156 394.499664,333.600189 394.117157,335.641296 
	C392.498077,335.278809 391.093323,334.621277 389.402466,333.581726 
	C389.456909,329.441742 389.797455,325.683746 390.127441,321.496765 
	C390.087341,319.046600 390.057831,317.025482 390.047913,314.550537 
	C390.003662,311.070038 389.939789,308.043335 389.918396,304.678558 
	C389.930206,303.888062 389.899567,303.435699 389.905273,302.517090 
	C389.681458,293.133118 389.421265,284.215363 389.513000,275.212189 
	C390.581635,274.290100 391.298279,273.453400 392.014954,272.616699 
z"/>
<path fill="#16292F" opacity="1.000000" stroke="none" 
	d="
M390.925171,348.663269 
	C387.006073,351.261597 384.715088,347.757782 381.889648,346.221985 
	C380.629852,345.537140 379.614166,344.403320 378.636292,343.233582 
	C380.184509,342.676697 381.584106,342.359528 383.375153,341.937500 
	C384.843964,341.807770 385.921295,341.782928 386.998627,341.758057 
	C388.282166,343.948578 389.565674,346.139099 390.925171,348.663269 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M387.278320,341.580505 
	C385.921295,341.782928 384.843964,341.807770 382.970825,341.840149 
	C380.791016,341.843414 379.407074,341.839142 378.023102,341.834869 
	C377.271301,341.597687 376.519501,341.360474 375.501801,340.998596 
	C380.814941,339.916809 386.393951,338.959717 392.347900,337.970703 
	C391.001251,339.093506 389.279633,340.248230 387.278320,341.580505 
z"/>
<path fill="#102129" opacity="1.000000" stroke="none" 
	d="
M395.126678,329.791138 
	C394.732086,328.145172 394.628143,326.182281 394.606812,323.814636 
	C394.689423,319.488983 394.689423,315.568054 394.689423,311.647125 
	C394.932068,311.646515 395.174683,311.645905 395.417328,311.645294 
	C395.417328,317.588287 395.417328,323.531250 395.126678,329.791138 
z"/>
<path fill="#102129" opacity="1.000000" stroke="none" 
	d="
M395.611816,306.964386 
	C395.434662,306.710510 395.410187,306.362976 395.385742,306.015472 
	C395.512024,306.300568 395.638275,306.585663 395.611816,306.964386 
z"/>
<path fill="#4791AB" opacity="1.000000" stroke="none" 
	d="
M417.321747,315.856079 
	C416.876862,316.563110 416.216461,317.167999 415.205261,317.694397 
	C415.226349,317.073029 415.598267,316.530121 416.300354,315.888367 
	C416.630524,315.789490 417.106201,315.753937 417.321747,315.856079 
z"/>
<path fill="#60B3CB" opacity="1.000000" stroke="none" 
	d="
M425.088867,316.571960 
	C423.207825,315.863708 421.497253,314.953308 419.493256,313.876404 
	C418.938263,310.931763 418.489838,308.146149 418.601227,305.383087 
	C418.619507,304.928864 421.956238,303.873505 422.295288,304.289459 
	C424.138733,306.550781 425.550720,309.170135 427.034485,311.711090 
	C427.246399,312.073944 427.040802,312.680542 426.880798,313.773376 
	C426.733185,314.372375 426.725098,314.854462 426.725098,314.854462 
	C426.236511,315.359589 425.747894,315.864685 425.088867,316.571960 
z"/>
<path fill="#182D3B" opacity="1.000000" stroke="none" 
	d="
M426.903229,314.837830 
	C426.725098,314.854462 426.733185,314.372375 426.885468,314.185547 
	C427.138672,314.192963 427.259491,314.380768 427.325500,314.586243 
	C427.340729,314.633514 427.167755,314.741272 426.903229,314.837830 
z"/>
<path fill="#56B0CF" opacity="1.000000" stroke="none" 
	d="
M401.554932,265.156006 
	C402.031494,264.958557 402.205566,264.977509 402.639038,265.030884 
	C402.857697,266.462433 402.816986,267.859528 402.589233,269.545044 
	C402.018921,268.346436 401.635681,266.859436 401.554932,265.156006 
z"/>
<path fill="#1B2B35" opacity="1.000000" stroke="none" 
	d="
M446.323120,302.017090 
	C446.616333,300.811798 447.192505,299.610260 448.098633,298.289124 
	C448.146729,299.271851 447.864899,300.374207 447.377411,301.758179 
	C446.983185,302.033508 446.794647,302.027161 446.323120,302.017090 
z"/>
<path fill="#65E7FC" opacity="1.000000" stroke="none" 
	d="
M380.145416,356.066101 
	C377.709473,356.374573 375.278320,356.901855 372.836792,356.955170 
	C359.144745,357.254120 345.450195,357.438782 330.905060,357.805908 
	C311.684875,357.970062 293.316162,357.990814 274.860199,357.673706 
	C272.528717,355.111572 270.660278,355.691406 268.609619,358.106628 
	C254.795364,358.391541 241.408936,358.674347 227.681030,358.936005 
	C226.883621,358.928772 226.427719,358.942688 225.575577,358.937988 
	C224.122849,358.943634 223.066345,358.967896 222.009842,358.992188 
	C212.323090,358.998077 202.636337,359.003998 192.482819,359.008331 
	C192.028839,358.069305 192.041641,357.131805 192.058960,355.842621 
	C193.420853,355.432404 194.778229,355.373901 196.596619,355.635376 
	C197.726120,355.797729 198.394623,355.640106 199.063110,355.482513 
	C215.399033,355.333313 231.734940,355.184113 248.629242,355.253204 
	C249.459793,355.373413 249.731949,355.275360 250.004105,355.177246 
	C256.386963,354.957275 262.769806,354.737305 269.983917,354.510071 
	C272.874420,354.352417 274.933624,354.201965 276.992859,354.051514 
	C302.359283,354.021271 327.725677,353.991028 353.881836,354.278168 
	C359.808807,354.286011 364.946045,353.976471 370.083313,353.666931 
	C371.131592,353.549469 372.179840,353.432007 373.846558,353.330444 
	C374.635040,353.406281 374.805023,353.466217 375.113770,353.821960 
	C376.883484,354.767181 378.514435,355.416656 380.145416,356.066101 
z"/>
<path fill="#16292F" opacity="1.000000" stroke="none" 
	d="
M269.019989,329.006409 
	C268.848511,327.525726 268.677032,326.045013 268.253113,324.282471 
	C268.000641,324.000641 268.006470,324.006439 268.003174,323.537048 
	C268.000336,310.378448 268.000793,297.689240 268.353088,285.051208 
	C270.217010,285.614777 271.729034,286.127136 273.568329,286.750397 
	C273.752625,285.205017 273.885895,284.087769 274.270081,282.775299 
	C274.774445,282.239624 275.027893,281.899139 275.490051,281.901245 
	C277.236664,287.491699 278.774597,292.739532 280.317566,298.343445 
	C283.678223,304.988007 287.033875,311.276428 290.026428,317.623993 
	C288.160492,316.873108 286.657562,316.063080 284.635101,314.973053 
	C284.384003,316.564728 284.191986,317.781738 284.000000,318.998779 
	C277.897949,317.471161 276.738983,318.594116 275.901123,324.946686 
	C275.412598,328.650391 273.805634,332.206573 272.273560,335.585968 
	C270.903778,334.897034 269.962372,334.451233 269.020935,334.005463 
	C269.018677,333.252289 269.016418,332.499146 269.290039,331.317200 
	C269.851593,330.577393 270.137268,330.266418 270.422974,329.955444 
	C269.955322,329.639099 269.487640,329.322754 269.019989,329.006409 
M273.162659,292.352295 
	C273.881226,292.676239 274.599823,293.000183 275.318390,293.324158 
	C275.315918,292.440857 275.313446,291.557526 275.310974,290.674225 
	C274.594330,291.002563 273.877655,291.330902 273.162659,292.352295 
M276.637360,309.035797 
	C276.410797,308.567780 276.184265,308.099731 275.957703,307.631714 
	C275.741577,307.818512 275.316528,308.131073 275.341614,308.172729 
	C275.602386,308.606049 275.935547,308.995850 276.637360,309.035797 
M271.521271,310.142883 
	C272.042297,310.117889 272.563354,310.092926 273.084381,310.067963 
	C273.000488,309.795868 272.916595,309.523743 272.832703,309.251617 
	C272.409607,309.389069 271.986511,309.526520 271.521271,310.142883 
z"/>
<path fill="#16292F" opacity="1.000000" stroke="none" 
	d="
M299.989594,327.040466 
	C300.035736,327.387390 300.205139,327.656830 300.828217,328.208893 
	C301.780426,329.017548 302.402191,329.466095 303.023956,329.914673 
	C303.023956,329.914673 303.025574,329.985352 303.053833,330.190674 
	C303.082092,330.395966 303.086456,330.820831 303.086456,330.820831 
	C302.563202,331.666931 301.529327,332.558990 301.600677,333.351562 
	C302.020813,338.019928 300.065247,338.621094 295.730927,338.312775 
	C292.843903,338.107391 289.825348,340.535645 286.779572,341.518341 
	C284.522034,342.246704 281.726654,342.742615 281.246613,339.420746 
	C280.749603,335.981628 283.582184,336.117889 285.973785,335.984161 
	C291.646820,335.666931 294.613159,330.956146 293.128845,323.955048 
	C293.322601,322.620941 293.502808,322.082275 293.938110,321.686096 
	C294.987885,322.357361 295.782532,322.886108 296.577179,323.414856 
	C296.577179,323.414856 296.674835,323.340790 296.689850,323.594330 
	C297.147980,324.207306 297.591125,324.566772 298.034241,324.926239 
	C298.034241,324.926239 298.010498,324.990234 298.130829,325.259338 
	C298.830627,326.032471 299.410126,326.536469 299.989594,327.040466 
z"/>
<path fill="#16292F" opacity="1.000000" stroke="none" 
	d="
M327.974274,342.397644 
	C328.744873,342.598480 329.515503,342.799286 331.032104,343.153412 
	C337.514465,343.537903 343.250793,343.769073 348.987152,344.000244 
	C346.614716,346.661041 344.427460,351.348083 341.833588,351.585083 
	C335.267212,352.185120 327.539307,354.499176 322.513367,346.633270 
	C323.869141,346.201782 325.140472,345.797180 326.684937,345.305634 
	C325.633820,344.184265 324.889862,343.390564 324.077820,342.271606 
	C324.470093,341.947021 324.930420,341.947632 325.919220,342.102875 
	C326.956573,342.304230 327.465424,342.350922 327.974274,342.397644 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M349.223022,343.885315 
	C343.250793,343.769073 337.514465,343.537903 331.431641,343.104340 
	C333.838654,342.607849 336.607910,342.417328 339.342651,342.000488 
	C347.973358,340.684998 356.571045,339.128418 365.226776,338.017761 
	C367.644348,337.707550 370.218353,338.616547 372.852478,339.275635 
	C371.235535,339.234833 369.486755,338.884644 367.054291,338.731201 
	C365.809540,339.242493 365.248505,339.557068 364.687469,339.871643 
	C362.993622,340.444427 361.299744,341.017212 358.947998,341.740326 
	C357.388123,342.214844 356.486176,342.539001 355.584229,342.863159 
	C355.584229,342.863159 355.270996,342.771393 354.749756,342.832092 
	C352.977020,343.224426 351.725525,343.556030 350.473999,343.887604 
	C350.135620,343.848541 349.797241,343.809479 349.223022,343.885315 
z"/>
<path fill="#16292F" opacity="1.000000" stroke="none" 
	d="
M277.089539,353.699249 
	C274.933624,354.201965 272.874420,354.352417 270.386230,354.379425 
	C271.053070,353.597748 272.148926,352.939484 273.244781,352.281219 
	C272.900208,352.167786 272.555634,352.054321 272.211060,351.940857 
	C272.910065,349.981781 273.214722,346.657990 274.382263,346.320679 
	C277.826965,345.325378 281.436188,345.783264 281.702728,350.894684 
	C280.168365,351.727783 278.677277,352.537384 277.089539,353.699249 
z"/>
<path fill="#1B3640" opacity="1.000000" stroke="none" 
	d="
M389.898041,363.914307 
	C389.242126,366.323517 388.586212,368.732727 387.887390,371.519409 
	C385.090729,372.303223 383.858368,371.165009 383.992859,368.340942 
	C384.110260,365.875153 384.012817,363.399170 384.382141,360.464569 
	C385.497650,359.998199 386.241302,359.994904 387.005768,360.346008 
	C387.983704,361.771667 388.940857,362.842987 389.898041,363.914307 
z"/>
<path fill="#16292F" opacity="1.000000" stroke="none" 
	d="
M386.984985,359.991638 
	C386.241302,359.994904 385.497650,359.998199 384.382416,360.004486 
	C384.944489,357.978180 385.351532,355.175781 386.933441,354.098114 
	C389.393097,352.422455 392.627808,352.060394 394.552551,355.646851 
	C393.167725,356.613312 392.054901,357.222778 390.626312,357.912598 
	C389.202026,358.659180 388.093506,359.325409 386.984985,359.991638 
z"/>
<path fill="#54C8E0" opacity="1.000000" stroke="none" 
	d="
M369.631042,353.529297 
	C364.946045,353.976471 359.808807,354.286011 354.348267,354.342041 
	C359.076294,353.856262 364.127533,353.623962 369.631042,353.529297 
z"/>
<path fill="#182A30" opacity="1.000000" stroke="none" 
	d="
M293.683014,321.543579 
	C293.502808,322.082275 293.322601,322.620941 293.071198,323.580444 
	C290.241913,322.341003 287.483826,320.680817 284.362854,319.009705 
	C284.191986,317.781738 284.384003,316.564728 284.635101,314.973053 
	C286.657562,316.063080 288.160492,316.873108 290.026123,317.671143 
	C290.388824,317.659210 290.312103,317.713043 290.261078,318.071655 
	C291.292328,319.497375 292.374634,320.564514 293.501373,321.578674 
	C293.545837,321.525696 293.683014,321.543579 293.683014,321.543579 
z"/>
<path fill="#16292F" opacity="1.000000" stroke="none" 
	d="
M354.839478,349.940857 
	C353.827423,348.998749 352.914337,348.108459 352.661041,347.058899 
	C352.557678,346.630707 354.104767,345.221191 354.518066,345.364258 
	C355.612549,345.743103 356.498047,346.725708 357.466736,347.468018 
	C356.712463,348.288208 355.958191,349.108368 354.839478,349.940857 
z"/>
<path fill="#16292F" opacity="1.000000" stroke="none" 
	d="
M267.366333,338.626892 
	C267.056183,337.536072 266.981384,336.113251 266.921600,333.844635 
	C266.991516,330.020020 267.046417,327.041199 267.327271,324.042145 
	C267.553192,324.021942 268.006470,324.006439 268.006470,324.006439 
	C268.006470,324.006439 268.000641,324.000641 268.006805,324.006775 
	C267.875885,328.773529 267.738800,333.534210 267.366333,338.626892 
z"/>
<path fill="#54C8E0" opacity="1.000000" stroke="none" 
	d="
M380.389465,356.055725 
	C378.514435,355.416656 376.883484,354.767181 375.508301,353.712585 
	C376.854523,353.260376 377.944977,353.213318 379.035400,353.166260 
	C379.904419,353.918823 380.773438,354.671387 381.628448,355.723969 
	C381.614441,356.023987 381.123688,356.022339 381.123688,356.022339 
	C381.123688,356.022339 380.633484,356.045380 380.389465,356.055725 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M311.939117,336.064087 
	C313.930939,336.840820 315.922791,337.617523 317.978516,338.690826 
	C316.293243,338.336945 314.544098,337.686462 312.404572,336.768982 
	C312.014221,336.501953 311.939117,336.064087 311.939117,336.064087 
z"/>
<path fill="#16292F" opacity="1.000000" stroke="none" 
	d="
M367.391907,345.453430 
	C367.212311,344.401093 367.310760,343.477692 367.409210,342.554260 
	C368.083649,342.836182 368.758118,343.118103 369.432556,343.400055 
	C368.845032,344.127502 368.257507,344.854919 367.391907,345.453430 
z"/>
<path fill="#182A30" opacity="1.000000" stroke="none" 
	d="
M269.017700,334.353638 
	C269.962372,334.451233 270.903778,334.897034 271.923218,335.671387 
	C271.258270,336.456940 270.515320,336.913910 269.772400,337.370850 
	C269.519775,336.481171 269.267151,335.591492 269.017700,334.353638 
z"/>
<path fill="#1B3640" opacity="1.000000" stroke="none" 
	d="
M390.942078,357.832275 
	C392.054901,357.222778 393.167725,356.613312 394.641479,355.999420 
	C395.323822,356.484467 395.645203,356.973969 396.028259,357.760071 
	C396.089966,358.056732 395.956360,358.052307 395.492310,358.044617 
	C393.666168,357.968689 392.304138,357.900482 390.942078,357.832275 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M306.737732,332.661804 
	C307.711609,332.995575 308.799286,333.600647 309.699646,334.580139 
	C309.344177,334.965820 309.176025,334.977142 309.007843,334.988495 
	C308.289062,334.303375 307.570282,333.618256 306.737732,332.661804 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M303.406067,330.930908 
	C303.086456,330.820831 303.082092,330.395966 303.088501,330.183716 
	C304.273743,330.551788 305.452576,331.132050 306.686340,332.070648 
	C305.736053,331.966309 304.730865,331.503632 303.406067,330.930908 
z"/>
<path fill="#16292F" opacity="1.000000" stroke="none" 
	d="
M378.793030,352.901306 
	C377.944977,353.213318 376.854523,353.260376 375.369568,353.416809 
	C374.805023,353.466217 374.635040,353.406281 374.227722,353.216827 
	C375.510498,352.937012 377.030579,352.786682 378.793030,352.901306 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M378.021118,342.123108 
	C379.407074,341.839142 380.791016,341.843414 382.579346,341.945007 
	C381.584106,342.359528 380.184509,342.676697 378.407349,342.990845 
	C378.026215,342.795654 378.022675,342.603516 378.021118,342.123108 
z"/>
<path fill="#54C8E0" opacity="1.000000" stroke="none" 
	d="
M198.715530,355.354614 
	C198.394623,355.640106 197.726120,355.797729 196.996155,355.723358 
	C197.412445,355.403137 197.890198,355.314911 198.715530,355.354614 
z"/>
<path fill="#16292F" opacity="1.000000" stroke="none" 
	d="
M350.885864,343.908508 
	C351.725525,343.556030 352.977020,343.224426 354.616333,342.926086 
	C353.768677,343.282715 352.533173,343.606079 350.885864,343.908508 
z"/>
<path fill="#16292F" opacity="1.000000" stroke="none" 
	d="
M323.104797,340.937439 
	C323.104797,340.937439 323.143250,341.310669 323.159363,341.497467 
	C322.497345,341.624420 321.819214,341.564575 321.108368,341.210632 
	C321.515625,340.930725 321.955627,340.944916 322.724487,340.983032 
	C323.053314,341.006958 323.104797,340.937439 323.104797,340.937439 
z"/>
<path fill="#182A30" opacity="1.000000" stroke="none" 
	d="
M269.016052,329.337463 
	C269.487640,329.322754 269.955322,329.639099 270.422974,329.955444 
	C270.137268,330.266418 269.851593,330.577393 269.294098,330.940613 
	C269.018890,330.551422 269.015503,330.109985 269.016052,329.337463 
z"/>
<path fill="#16292F" opacity="1.000000" stroke="none" 
	d="
M355.963318,342.892029 
	C356.486176,342.539001 357.388123,342.214844 358.652679,341.907715 
	C358.124329,342.256836 357.233368,342.588867 355.963318,342.892029 
z"/>
<path fill="#16292F" opacity="1.000000" stroke="none" 
	d="
M311.954468,336.046387 
	C311.939117,336.064087 312.014221,336.501953 312.083893,336.712677 
	C311.567078,336.766052 310.980560,336.608704 310.240784,336.174866 
	C310.087463,335.898376 310.212585,335.777588 310.508423,335.815308 
	C311.192200,335.925446 311.580200,335.997833 311.968170,336.070190 
	C311.968170,336.070190 311.969788,336.028687 311.954468,336.046387 
z"/>
<path fill="#16292F" opacity="1.000000" stroke="none" 
	d="
M365.022400,339.872009 
	C365.248505,339.557068 365.809540,339.242493 366.695679,338.912354 
	C366.466278,339.222015 365.911804,339.547211 365.022400,339.872009 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M320.016418,340.029541 
	C320.187805,339.991150 320.354370,340.061188 320.619873,340.123840 
	C320.707825,340.140594 320.369324,340.315216 320.369324,340.315216 
	C320.369324,340.315216 320.000641,340.162079 320.016418,340.029541 
z"/>
<path fill="#54C8E0" opacity="1.000000" stroke="none" 
	d="
M249.750610,355.081787 
	C249.731949,355.275360 249.459793,355.373413 249.091736,355.331696 
	C249.162933,355.123352 249.330017,355.054810 249.750610,355.081787 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M373.943726,340.323059 
	C374.169800,340.135712 374.451263,340.273590 374.851624,340.667358 
	C374.685120,340.753204 374.361298,340.661530 373.943726,340.323059 
z"/>
<path fill="#16292F" opacity="1.000000" stroke="none" 
	d="
M309.124634,335.194641 
	C309.176025,334.977142 309.344177,334.965820 309.762878,334.921692 
	C310.013428,334.888855 310.030304,334.978455 310.082886,335.175476 
	C310.135498,335.372528 310.212585,335.777588 310.212585,335.777588 
	C310.212585,335.777588 310.087463,335.898376 310.021790,335.955505 
	C309.671722,335.862671 309.433502,335.658722 309.124634,335.194641 
z"/>
<path fill="#F7C40B" opacity="1.000000" stroke="none" 
	d="
M270.519012,205.219498 
	C268.896271,206.023499 267.013794,206.538040 265.715332,207.703384 
	C264.021301,209.223724 262.977722,211.454956 261.327820,213.040634 
	C253.344360,220.713409 242.857849,219.612106 236.705307,210.471024 
	C234.545670,207.262344 232.660187,203.869141 230.445587,200.309860 
	C231.959763,195.994019 233.677902,191.928513 235.741486,188.132050 
	C235.709122,190.893677 235.331284,193.386230 234.953445,195.878799 
	C239.415665,192.395508 239.190872,197.863358 241.184113,198.702576 
	C241.203033,198.758041 241.307281,198.811615 241.391373,199.122131 
	C244.028290,201.667908 245.715622,200.659653 247.113068,197.740479 
	C247.412323,197.166321 247.552963,196.822327 247.953613,196.230988 
	C248.786453,193.551056 249.359268,191.118484 250.319778,188.711151 
	C251.483994,188.751663 252.260513,188.766937 253.243408,189.006271 
	C254.029984,189.250809 254.610199,189.271286 255.477448,189.437561 
	C256.181122,189.680542 256.597748,189.777756 257.275085,190.143311 
	C261.827026,192.317581 260.488495,188.328461 261.744385,186.566772 
	C263.967468,188.039459 265.859222,189.654373 267.750946,191.269287 
	C267.750946,191.269287 267.794006,191.185242 267.830109,191.543091 
	C268.742798,194.467728 269.619385,197.034531 270.355469,200.082550 
	C270.300079,201.891541 270.385132,203.219330 270.544922,204.710663 
	C270.619720,204.874222 270.519012,205.219498 270.519012,205.219498 
z"/>
<path fill="#142122" opacity="1.000000" stroke="none" 
	d="
M210.135071,121.946198 
	C210.359634,122.469055 210.584198,122.991920 210.870438,124.161568 
	C210.952438,125.871742 210.972763,126.935135 210.860931,128.127045 
	C210.909912,128.513702 211.091049,128.771835 211.532990,129.121887 
	C212.197983,129.580276 212.602173,129.946747 213.150360,130.643127 
	C214.170715,131.089050 215.047058,131.205078 216.115082,131.164825 
	C216.738190,131.149261 217.169632,131.289963 217.692749,131.893600 
	C217.850174,133.906204 217.915909,135.455841 217.590576,137.006866 
	C211.706116,138.239212 208.160843,150.602737 212.459824,155.622238 
	C214.885468,158.454407 218.910019,159.917145 222.361847,162.287781 
	C222.743484,163.845703 222.977615,165.112595 223.188446,166.757904 
	C223.834152,168.074554 224.503143,169.012756 225.362228,170.078354 
	C225.771179,170.444092 225.872833,170.724884 225.572601,171.185791 
	C221.820282,173.310425 221.775879,173.437164 223.999390,175.000610 
	C222.364578,177.290909 220.239090,181.740204 219.179565,181.499390 
	C214.485809,180.432602 209.014069,185.308838 205.359100,179.341064 
	C206.432159,178.158188 207.197052,177.417236 208.094696,176.666351 
	C208.227448,176.656418 208.349640,176.892960 208.694733,176.734772 
	C209.331268,176.171783 209.622726,175.766998 209.785919,175.038757 
	C209.827515,173.812363 209.997391,172.909424 210.499725,171.894043 
	C211.210022,170.491150 211.587845,169.200684 211.887558,167.607056 
	C211.635727,166.524643 211.462021,165.745392 211.381561,164.842560 
	C211.536591,164.379807 211.397705,164.141983 210.883759,163.681793 
	C209.548035,158.894730 208.386734,154.431335 207.386047,149.795105 
	C207.401459,149.086166 207.256241,148.550034 206.917023,147.685120 
	C206.418198,142.263062 206.113373,137.169815 206.024689,131.914001 
	C205.878708,131.165115 205.516586,130.578766 205.147430,129.573578 
	C205.210068,127.771484 205.279709,126.388252 205.673370,124.983887 
	C207.376617,123.957230 208.755844,122.951714 210.135071,121.946198 
z"/>
<path fill="#0C8CB8" opacity="1.000000" stroke="none" 
	d="
M223.211746,166.379471 
	C222.977615,165.112595 222.743484,163.845703 222.371185,161.856674 
	C222.299988,159.711975 222.366959,158.289444 222.691467,157.076141 
	C224.611710,159.885513 226.274414,162.485626 228.127136,165.384155 
	C232.488281,166.691849 236.659424,167.701141 241.080734,168.992889 
	C242.887619,169.186737 244.464249,168.909256 245.994629,169.070511 
	C247.176895,169.195099 248.301208,169.869675 249.493027,170.346130 
	C249.534775,170.392548 249.538162,170.517334 249.207581,170.505325 
	C247.590698,171.307251 246.304428,172.121185 245.018158,172.935120 
	C239.364685,176.293213 233.684189,176.823975 227.522141,172.701813 
	C226.673248,171.886414 226.265274,171.467255 225.857285,171.048096 
	C225.872833,170.724884 225.771179,170.444092 225.305542,169.736084 
	C224.443085,168.304108 223.827423,167.341782 223.211746,166.379471 
z"/>
<path fill="#FCF9E4" opacity="1.000000" stroke="none" 
	d="
M253.037048,188.782196 
	C252.260513,188.766937 251.483994,188.751663 250.086151,188.543182 
	C247.981018,189.540298 246.497208,190.730637 244.775269,192.174194 
	C243.414642,192.634964 242.292130,192.842514 241.083344,192.694183 
	C240.998032,189.545624 240.998993,186.752930 240.999939,183.960251 
	C241.170898,183.670959 241.341858,183.381668 242.073654,183.037766 
	C243.426620,182.698746 244.218765,182.414322 245.430954,182.189789 
	C247.262360,182.272629 248.673706,182.295609 250.158096,182.449158 
	C250.231140,182.579712 250.652832,182.649521 250.652832,182.649521 
	C250.652832,182.649521 250.959152,182.354874 251.232666,182.308105 
	C252.040573,182.374329 252.574982,182.487305 253.238525,182.917572 
	C254.858719,185.296921 259.118683,185.838318 257.014374,189.874954 
	C256.597748,189.777756 256.181122,189.680542 255.373077,189.231552 
	C254.333450,188.847244 253.685257,188.814728 253.037048,188.782196 
z"/>
<path fill="#112026" opacity="1.000000" stroke="none" 
	d="
M258.001221,174.000610 
	C257.667725,176.084976 257.334198,178.169342 256.583435,180.630219 
	C252.467072,180.703064 248.767929,180.399429 244.820160,179.831253 
	C244.381226,178.380661 244.190918,177.194595 244.000610,176.008545 
	C244.962204,175.280746 245.923798,174.552917 247.151520,173.302063 
	C247.923660,172.510834 248.429642,172.242630 249.282715,172.029800 
	C252.420273,172.723663 255.210754,173.362137 258.001221,174.000610 
z"/>
<path fill="#EFE9A2" opacity="1.000000" stroke="none" 
	d="
M240.681580,184.047012 
	C240.998993,186.752930 240.998032,189.545624 241.000900,193.089203 
	C241.058212,195.442444 241.111710,197.044769 241.165207,198.647110 
	C239.190872,197.863358 239.415665,192.395508 234.953445,195.878799 
	C235.331284,193.386230 235.709122,190.893677 236.064972,187.951782 
	C237.483063,186.379562 238.923141,185.256668 240.681580,184.047012 
z"/>
<path fill="#14232A" opacity="1.000000" stroke="none" 
	d="
M243.631317,176.026154 
	C244.190918,177.194595 244.381226,178.380661 244.790070,180.048874 
	C245.008591,180.531036 244.924698,180.964722 244.556519,180.973755 
	C243.450409,180.998657 242.712494,181.014526 241.974564,181.030411 
	C240.757980,178.943283 239.958267,176.963791 243.631317,176.026154 
z"/>
<path fill="#14232A" opacity="1.000000" stroke="none" 
	d="
M225.572601,171.185791 
	C226.265274,171.467255 226.673248,171.886414 227.188522,172.583282 
	C226.388489,173.471115 225.481155,174.081238 224.286621,174.845978 
	C221.775879,173.437164 221.820282,173.310425 225.572601,171.185791 
z"/>
<path fill="#14232A" opacity="1.000000" stroke="none" 
	d="
M207.961960,176.676300 
	C207.197052,177.417236 206.432159,178.158188 205.332397,178.950851 
	C204.435028,178.784805 203.872482,178.567047 203.118484,178.107315 
	C203.449509,177.635498 203.972015,177.405655 205.111633,177.023712 
	C206.473175,176.806488 207.217560,176.741394 207.961960,176.676300 
z"/>
<path fill="#1589AF" opacity="1.000000" stroke="none" 
	d="
M248.935638,171.974426 
	C248.429642,172.242630 247.923660,172.510834 247.212463,172.983490 
	C246.563614,173.143097 246.119980,173.098236 245.347260,172.994247 
	C246.304428,172.121185 247.590698,171.307251 249.111633,170.750214 
	C249.209412,171.329559 249.072525,171.651993 248.935638,171.974426 
z"/>
<path fill="#112026" opacity="1.000000" stroke="none" 
	d="
M241.978394,181.343506 
	C242.712494,181.014526 243.450409,180.998657 244.588928,181.264465 
	C244.996628,181.740738 245.003769,181.935318 245.010895,182.129913 
	C244.218765,182.414322 243.426620,182.698746 242.317719,182.946899 
	C241.994690,182.492645 241.988449,182.074615 241.978394,181.343506 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M270.549957,205.609467 
	C270.519012,205.219498 270.619720,204.874222 270.745850,204.394470 
	C270.746613,202.476929 270.621277,201.039139 270.495941,199.601349 
	C269.619385,197.034531 268.742798,194.467728 267.783081,191.538651 
	C273.316864,194.534454 271.680145,200.133850 271.991638,205.933975 
	C270.996216,208.865860 270.019379,210.989304 268.968872,213.008026 
	C268.895142,212.903320 268.764496,212.683014 268.764496,212.683014 
	C269.369965,210.455154 269.975433,208.227295 270.549957,205.609467 
z"/>
<path fill="#1A2627" opacity="1.000000" stroke="none" 
	d="
M266.831635,215.845337 
	C267.310944,214.928696 267.790222,214.012070 268.516998,212.889221 
	C268.764496,212.683014 268.895142,212.903320 268.945374,213.337418 
	C268.358521,214.542099 267.721405,215.312698 267.021454,216.023438 
	C266.958618,215.963593 266.831635,215.845322 266.831635,215.845337 
z"/>
<path fill="#1A2627" opacity="1.000000" stroke="none" 
	d="
M262.900940,219.894867 
	C263.359314,219.266525 263.883362,218.706299 264.719421,218.088120 
	C265.031433,218.030167 265.107849,218.107590 265.018799,218.398880 
	C264.319672,219.159424 263.709595,219.628677 263.099487,220.097900 
	C263.099487,220.097900 262.966675,219.962997 262.900940,219.894867 
z"/>
<path fill="#1A2627" opacity="1.000000" stroke="none" 
	d="
M264.993652,217.991043 
	C265.412537,217.342529 265.869232,216.733124 266.578796,215.984528 
	C266.831635,215.845322 266.958618,215.963593 266.938538,216.316711 
	C266.314941,217.149078 265.711395,217.628326 265.107849,218.107590 
	C265.107849,218.107590 265.031433,218.030167 264.993652,217.991043 
z"/>
<path fill="#FBA907" opacity="1.000000" stroke="none" 
	d="
M265.836517,333.904572 
	C263.481537,334.948669 261.126556,335.992798 258.108643,336.779907 
	C250.388596,335.528961 250.620331,334.723999 250.015457,327.410645 
	C249.920273,326.259796 250.019058,325.092896 250.302887,323.706726 
	C254.884003,321.810547 259.190369,320.141052 263.825073,318.766052 
	C264.714478,324.008545 265.275482,328.956573 265.836517,333.904572 
z"/>
<path fill="#F69907" opacity="1.000000" stroke="none" 
	d="
M250.028152,323.933411 
	C250.019058,325.092896 249.920273,326.259796 250.015457,327.410645 
	C250.620331,334.723999 250.388596,335.528961 257.729919,336.779358 
	C252.514572,337.237732 246.897125,338.167969 241.569839,337.305634 
	C239.233093,336.927368 237.488266,332.892334 235.237152,330.259888 
	C235.023163,329.495392 235.046661,328.988739 235.399063,328.212830 
	C240.494690,326.606842 245.261429,325.270142 250.028152,323.933411 
z"/>
<path fill="#D59D4A" opacity="1.000000" stroke="none" 
	d="
M266.060608,333.904022 
	C265.275482,328.956573 264.714478,324.008545 263.969299,318.375244 
	C263.539154,314.490265 263.293152,311.290619 263.052124,307.649811 
	C262.969116,304.050079 262.881104,300.891449 262.932007,297.362793 
	C263.313202,294.002899 263.555481,291.013062 264.074463,288.176392 
	C264.870911,289.236572 265.390656,290.143585 265.981995,291.410278 
	C266.219055,292.477570 266.384521,293.185181 266.511963,294.301086 
	C266.516388,298.649414 266.558838,302.589386 266.410767,306.910248 
	C266.263275,309.844635 266.306274,312.398132 266.285919,315.297852 
	C266.270813,316.109467 266.319061,316.574799 266.245758,317.360931 
	C266.334137,318.546967 266.544098,319.412201 266.727997,320.657379 
	C266.835083,322.045654 266.968201,323.053986 267.101318,324.062347 
	C267.046417,327.041199 266.991516,330.020020 266.833313,333.425598 
	C266.729950,333.852356 266.284698,333.903473 266.060608,333.904022 
z"/>
<path fill="#182A30" opacity="1.000000" stroke="none" 
	d="
M265.018799,218.398880 
	C265.711395,217.628326 266.314941,217.149078 267.001404,216.376556 
	C267.721405,215.312698 268.358521,214.542099 269.019104,213.442139 
	C270.019379,210.989304 270.996216,208.865860 271.995056,206.370743 
	C278.151489,204.229706 276.011139,198.066910 278.996460,194.172699 
	C291.291290,197.900085 293.555054,202.166580 290.561554,214.165741 
	C290.343292,215.040634 291.079376,216.153625 291.302856,217.410767 
	C290.931610,218.097183 290.629211,218.528778 290.049500,219.143005 
	C289.549530,219.929504 289.326874,220.533371 289.087280,221.099792 
	C289.070374,221.062317 289.152374,221.068832 288.924866,221.217468 
	C288.556366,221.692398 288.415436,222.018692 288.254211,222.292969 
	C288.233917,222.240952 288.345520,222.243408 288.110718,222.356354 
	C287.791199,222.789932 287.706512,223.110565 287.594025,223.441086 
	C287.566223,223.450974 287.567078,223.509995 287.219482,223.610947 
	C286.653320,224.369385 286.434753,225.026855 286.160461,226.006973 
	C280.547882,235.881104 276.687714,246.045517 274.744263,257.055542 
	C274.373993,257.762665 274.283569,258.417633 274.157684,259.401184 
	C274.055267,260.163452 273.988281,260.597168 273.671204,261.001160 
	C273.253204,260.973114 273.085297,260.974823 272.591736,260.902344 
	C270.516907,259.882324 268.767731,258.936493 267.008362,257.523804 
	C266.980774,251.717697 266.963409,246.378448 266.978455,240.642395 
	C268.498230,233.665085 268.215851,233.206879 262.717346,233.278183 
	C262.717346,233.278183 262.853760,233.144318 262.792419,233.065582 
	C262.731110,232.986862 262.394897,232.746048 262.394897,232.746048 
	C262.394897,232.746048 262.018005,233.013336 262.018005,233.013336 
	C262.018005,233.013336 261.580078,233.075394 261.580078,233.075394 
	C261.580078,233.075394 261.152710,232.961411 260.985596,232.816422 
	C261.517456,228.729492 262.216492,224.787567 263.007507,220.471771 
	C263.709595,219.628677 264.319672,219.159424 265.018799,218.398880 
z"/>
<path fill="#FCFDF8" opacity="1.000000" stroke="none" 
	d="
M256.332031,242.367981 
	C258.243347,242.122635 260.154694,241.877274 262.791504,241.538773 
	C262.874329,243.422272 262.957062,245.303680 263.062317,247.630325 
	C263.084869,248.075546 263.026978,248.076401 262.659546,248.042526 
	C260.165314,249.213364 258.038513,250.418060 255.637344,251.817078 
	C254.135681,252.536804 252.908371,253.062225 251.302094,253.465485 
	C248.923813,254.454697 246.924500,255.566086 244.627029,256.858673 
	C243.225327,257.371155 242.121780,257.702393 240.777573,257.813721 
	C239.150421,257.593781 237.763931,257.593781 236.377441,257.593781 
	C236.030136,257.153687 235.682846,256.713593 235.335541,256.273499 
	C236.574631,254.780457 237.641525,253.085129 239.096863,251.846054 
	C240.879288,250.328476 242.986938,249.192856 245.210266,247.679459 
	C247.088608,246.778915 248.710678,246.091003 250.701782,245.528671 
	C252.382736,244.790375 253.694656,243.926514 255.200226,242.898941 
	C255.706589,242.612808 256.019318,242.490402 256.332031,242.367981 
z"/>
<path fill="#F8CD0D" opacity="1.000000" stroke="none" 
	d="
M256.554169,242.113525 
	C256.019318,242.490402 255.706589,242.612808 254.859573,242.887909 
	C252.994431,243.828064 251.663589,244.615570 250.332733,245.403076 
	C248.710678,246.091003 247.088608,246.778915 244.856400,247.689514 
	C242.907394,248.342743 241.568527,248.773270 240.229660,249.203827 
	C233.438293,250.269058 235.655991,244.450455 233.931473,241.118378 
	C242.972275,239.566299 252.342514,238.288177 261.712769,237.010071 
	C261.826080,237.540710 261.939392,238.071365 262.052673,238.602005 
	C260.293884,239.687698 258.535095,240.773392 256.554169,242.113525 
z"/>
<path fill="#AF7D35" opacity="1.000000" stroke="none" 
	d="
M265.910461,291.050568 
	C265.390656,290.143585 264.870911,289.236572 263.882202,288.091766 
	C263.413239,287.853943 263.105896,287.564514 263.205261,287.184387 
	C263.463531,282.572052 263.622467,278.339813 263.865723,273.774841 
	C263.761749,269.334991 263.573517,265.227936 263.328308,260.770996 
	C263.285156,256.519287 263.298981,252.617477 263.261658,248.546768 
	C263.210480,248.377884 263.026978,248.076401 263.026978,248.076401 
	C263.026978,248.076401 263.084869,248.075546 263.104584,248.053711 
	C263.517700,247.958069 263.911072,247.884262 264.797791,248.138763 
	C265.646057,259.622864 266.000977,270.778687 266.288422,282.322632 
	C266.273987,283.492157 266.327026,284.273560 266.268677,285.411896 
	C266.206238,286.512695 266.255219,287.256561 266.230957,288.378357 
	C266.075317,289.521027 265.992889,290.285797 265.910461,291.050568 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M266.355896,281.934509 
	C266.000977,270.778687 265.646057,259.622864 265.096130,248.073929 
	C264.928162,245.742233 264.955261,243.803680 265.350830,241.440765 
	C266.128174,241.024017 266.537109,241.031616 266.946014,241.039200 
	C266.963409,246.378448 266.980774,251.717697 266.997284,257.971710 
	C267.005829,265.261017 267.015228,271.635559 267.024689,278.353333 
	C266.801788,279.775909 266.578857,280.855194 266.355896,281.934509 
z"/>
<path fill="#1A2627" opacity="1.000000" stroke="none" 
	d="
M266.978455,240.642395 
	C266.537109,241.031616 266.128174,241.024017 265.412079,241.018768 
	C264.441467,238.640030 263.778076,236.258942 262.916016,233.578033 
	C268.215851,233.206879 268.498230,233.665085 266.978455,240.642395 
z"/>
<path fill="#1A2627" opacity="1.000000" stroke="none" 
	d="
M262.178711,233.154388 
	C262.018005,233.013336 262.394897,232.746048 262.394897,232.746048 
	C262.394897,232.746048 262.731110,232.986862 262.748840,233.150635 
	C262.766602,233.314407 262.339386,233.295441 262.178711,233.154388 
z"/>
<path fill="#F7C40B" opacity="1.000000" stroke="none" 
	d="
M263.781433,274.107605 
	C263.622467,278.339813 263.463531,282.572052 262.810272,287.275208 
	C254.908737,290.852509 247.501511,293.958832 240.094299,297.065155 
	C234.596680,295.202393 233.797195,291.328552 238.045319,284.802673 
	C246.863861,280.843689 255.322647,277.475647 263.781433,274.107605 
z"/>
<path fill="#FCF9E4" opacity="1.000000" stroke="none" 
	d="
M263.865723,273.774841 
	C255.322647,277.475647 246.863861,280.843689 238.178619,284.463501 
	C236.949875,283.857758 235.127426,283.034515 235.089859,282.136658 
	C234.941818,278.597412 233.283356,274.291016 239.057037,272.262177 
	C240.696487,271.434631 241.685425,270.848083 243.026154,270.332153 
	C250.047058,267.308777 256.716156,264.214813 263.385284,261.120850 
	C263.573517,265.227936 263.761749,269.334991 263.865723,273.774841 
z"/>
<path fill="#F8CD0D" opacity="1.000000" stroke="none" 
	d="
M263.328308,260.770996 
	C256.716156,264.214813 250.047058,267.308777 242.613098,270.262268 
	C239.897430,269.105835 237.946609,268.089874 235.995789,267.073914 
	C235.973175,265.664001 235.950562,264.254059 236.299683,262.369507 
	C239.790421,260.242462 242.909409,258.590057 246.306870,256.750122 
	C248.283920,255.570969 249.982498,254.579315 251.681076,253.587662 
	C252.908371,253.062225 254.135681,252.536804 255.988831,251.835251 
	C258.847382,250.677963 261.080109,249.696823 263.312805,248.715668 
	C263.298981,252.617477 263.285156,256.519287 263.328308,260.770996 
z"/>
<path fill="#FCF9E4" opacity="1.000000" stroke="none" 
	d="
M239.844635,297.282104 
	C247.501511,293.958832 254.908737,290.852509 262.710938,287.655334 
	C263.105896,287.564514 263.413239,287.853943 263.605530,287.938599 
	C263.555481,291.013062 263.313202,294.002899 262.584137,297.478577 
	C257.422211,299.923553 252.747070,301.882721 247.749146,303.838806 
	C246.897949,304.161011 246.369553,304.486267 245.881805,304.830261 
	C245.922455,304.849030 245.914337,304.759888 245.575928,304.730347 
	C244.463989,305.109650 243.690475,305.518524 242.947968,305.949554 
	C242.978958,305.971741 242.974884,305.895630 242.666199,305.886902 
	C241.849289,306.190735 241.341064,306.503265 240.872528,306.830444 
	C240.912216,306.845123 240.918594,306.760773 240.582230,306.737671 
	C239.476608,307.126068 238.707367,307.537567 237.966019,307.970398 
	C237.993927,307.991730 237.975220,307.924011 237.708755,307.903809 
	C237.283997,307.965332 237.125702,308.047058 236.967407,308.128815 
	C236.318466,307.909637 235.669525,307.690460 234.750839,307.254852 
	C234.957138,304.608276 235.433167,302.178131 235.951752,299.340546 
	C237.194519,298.455078 238.394745,297.977051 239.844635,297.282104 
z"/>
<path fill="#EDECCB" opacity="1.000000" stroke="none" 
	d="
M239.968536,249.444489 
	C241.568527,248.773270 242.907394,248.342743 244.600128,247.902161 
	C242.986938,249.192856 240.879288,250.328476 239.096863,251.846054 
	C237.641525,253.085129 236.574631,254.780457 235.335541,256.273499 
	C235.682846,256.713593 236.030136,257.153687 236.377441,257.593781 
	C237.763931,257.593781 239.150421,257.593781 240.549683,258.061768 
	C238.661758,259.328430 236.761063,260.127167 234.860382,260.925873 
	C231.386719,255.910782 232.786041,252.665634 239.968536,249.444489 
z"/>
<path fill="#ECBF19" opacity="1.000000" stroke="none" 
	d="
M235.007050,261.135803 
	C236.761063,260.127167 238.661758,259.328430 240.790344,258.281677 
	C242.121780,257.702393 243.225327,257.371155 244.900482,256.933716 
	C245.657532,256.864258 245.842957,256.900940 246.028381,256.937622 
	C242.909409,258.590057 239.790421,260.242462 236.299835,261.946503 
	C235.670059,261.780670 235.411880,261.563202 235.007050,261.135803 
z"/>
<path fill="#ECBF19" opacity="1.000000" stroke="none" 
	d="
M235.983368,267.439362 
	C237.946609,268.089874 239.897430,269.105835 242.261307,270.191650 
	C241.685425,270.848083 240.696487,271.434631 239.360229,272.055420 
	C237.998932,270.661377 236.984940,269.233093 235.983368,267.439362 
z"/>
<path fill="#FCF9E4" opacity="1.000000" stroke="none" 
	d="
M263.047180,308.090942 
	C263.293152,311.290619 263.539154,314.490265 263.640930,318.080750 
	C259.190369,320.141052 254.884003,321.810547 250.302887,323.706726 
	C245.261429,325.270142 240.494690,326.606842 235.431244,327.833771 
	C235.351456,325.173096 235.568390,322.622223 236.308578,319.621399 
	C241.344284,317.161346 245.856705,315.151184 250.723755,313.132690 
	C255.067978,311.446564 259.057587,309.768738 263.047180,308.090942 
z"/>
<path fill="#FCBE0A" opacity="1.000000" stroke="none" 
	d="
M263.052124,307.649811 
	C259.057587,309.768738 255.067978,311.446564 250.295532,313.090942 
	C244.987015,312.893250 240.815430,316.996368 236.069122,314.173004 
	C236.276215,312.684906 236.483307,311.196838 237.065613,309.230988 
	C237.707108,308.533630 237.885239,308.257233 237.975220,307.924011 
	C237.975220,307.924011 237.993927,307.991730 238.313263,307.998840 
	C239.394592,307.590881 240.156586,307.175812 240.918579,306.760742 
	C240.918594,306.760773 240.912216,306.845123 241.188507,306.861938 
	C241.968155,306.551056 242.471512,306.223328 242.974884,305.895630 
	C242.974884,305.895630 242.978958,305.971741 243.296066,305.978027 
	C244.380234,305.576141 245.147293,305.168030 245.914337,304.759888 
	C245.914337,304.759888 245.922455,304.849030 246.208252,304.859009 
	C247.020004,304.526642 247.545975,304.184296 248.071930,303.841919 
	C252.747070,301.882721 257.422211,299.923553 262.445251,297.848633 
	C262.881104,300.891449 262.969116,304.050079 263.052124,307.649811 
z"/>
<path fill="#E7B62A" opacity="1.000000" stroke="none" 
	d="
M236.050537,314.609375 
	C240.815430,316.996368 244.987015,312.893250 249.940918,313.099304 
	C245.856705,315.151184 241.344284,317.161346 236.497833,319.340149 
	C236.119873,318.021149 236.075912,316.533417 236.050537,314.609375 
z"/>
<path fill="#E7B62A" opacity="1.000000" stroke="none" 
	d="
M237.708755,307.903809 
	C237.885239,308.257233 237.707108,308.533630 237.232086,308.890015 
	C237.023346,309.026794 236.981613,308.578552 236.974518,308.353699 
	C237.125702,308.047058 237.283997,307.965332 237.708755,307.903809 
z"/>
<path fill="#E88213" opacity="1.000000" stroke="none" 
	d="
M150.023392,307.395935 
	C150.015762,299.931427 150.189163,292.851776 149.972046,285.784088 
	C149.842270,281.559357 151.205124,279.733856 155.671677,279.970428 
	C164.544418,280.440338 173.628952,278.803192 182.540771,283.336731 
	C184.002319,284.805450 185.005325,285.891968 186.008347,286.978485 
	C186.519531,288.168793 187.030731,289.359100 187.810760,291.117615 
	C188.407394,292.782898 188.735199,293.879974 189.063019,294.977081 
	C189.066360,296.371765 189.069717,297.766479 188.937714,299.943390 
	C187.406082,301.449432 186.009811,302.173248 184.517578,302.859009 
	C184.421616,302.820953 184.334915,302.633606 184.399353,302.125031 
	C185.762634,294.541565 184.459671,290.383362 179.183060,288.274139 
	C171.249939,285.103027 162.922806,285.430359 154.478973,287.322662 
	C154.370544,288.182098 154.413483,288.687836 154.330322,289.621887 
	C154.341797,293.968018 154.479370,297.885803 154.513245,302.232117 
	C154.455704,303.829590 154.501831,304.998596 154.398926,306.487000 
	C154.322052,307.489777 154.394180,308.173065 154.379425,309.087524 
	C154.292542,309.318695 154.123901,309.782898 153.845627,309.972717 
	C152.650421,311.083893 151.733505,312.005188 150.816574,312.926514 
	C150.557739,311.212952 150.298889,309.499420 150.023392,307.395935 
z"/>
<path fill="#F8BF06" opacity="1.000000" stroke="none" 
	d="
M184.613541,302.897064 
	C186.009811,302.173248 187.406082,301.449432 188.966522,300.360840 
	C191.075760,301.697540 193.331100,303.153534 194.907623,305.146515 
	C199.592331,311.068787 205.637024,313.480804 213.451294,312.989777 
	C212.762268,314.860901 211.639847,316.735107 210.255859,318.804657 
	C205.041382,316.139771 200.062027,314.997131 194.045944,315.670929 
	C189.277313,316.204956 184.193176,313.921661 179.106522,312.533752 
	C182.694534,310.209015 184.929810,307.329651 184.613541,302.897064 
z"/>
<path fill="#EDECCB" opacity="1.000000" stroke="none" 
	d="
M184.517578,302.859009 
	C184.929810,307.329651 182.694534,310.209015 178.717422,312.430878 
	C178.470947,312.683624 178.200745,312.688934 177.785248,312.854004 
	C177.051605,313.321106 176.733444,313.623138 176.415283,313.925201 
	C176.415298,313.925201 176.280762,313.667999 175.741028,313.714233 
	C172.806213,314.194183 170.411118,314.627930 168.016022,315.061707 
	C164.121323,315.166840 160.218048,315.463928 156.335815,315.290253 
	C154.581207,315.211792 152.868469,314.196899 150.976654,313.266663 
	C151.733505,312.005188 152.650421,311.083893 154.190018,309.948181 
	C155.938095,309.606110 157.063507,309.478424 158.628662,309.483582 
	C168.141251,309.643219 177.441528,310.492920 184.334915,302.633606 
	C184.334915,302.633606 184.421616,302.820953 184.517578,302.859009 
z"/>
<path fill="#F9B404" opacity="1.000000" stroke="none" 
	d="
M168.282227,315.294434 
	C170.411118,314.627930 172.806213,314.194183 175.606003,313.767365 
	C175.810562,315.098541 175.610443,316.422791 175.362839,318.061249 
	C172.668762,317.059387 170.608597,316.293274 168.282227,315.294434 
z"/>
<path fill="#F9B404" opacity="1.000000" stroke="none" 
	d="
M163.511902,316.520416 
	C163.483536,316.533142 163.540283,316.507690 163.511902,316.520416 
z"/>
<path fill="#F8BF06" opacity="1.000000" stroke="none" 
	d="
M176.727386,313.826477 
	C176.733444,313.623138 177.051605,313.321106 177.704407,312.962280 
	C177.705856,313.179565 177.372681,313.453644 176.727386,313.826477 
z"/>
<path fill="#EFEFD7" opacity="1.000000" stroke="none" 
	d="
M167.343414,260.565094 
	C162.311340,260.687927 157.614578,260.654602 152.457016,260.704285 
	C151.350098,259.749298 150.703964,258.711304 150.129791,257.084290 
	C150.275238,256.104095 150.348709,255.712936 150.611481,255.230377 
	C151.039078,254.966263 151.190964,254.736435 151.539276,254.430359 
	C152.177277,254.282501 152.532455,254.153778 153.355728,254.113159 
	C155.954895,254.279587 158.086014,254.425461 160.217010,254.423843 
	C167.007095,254.418686 173.795883,254.354691 179.086639,248.669800 
	C179.692307,248.580490 180.025848,248.457489 180.359390,248.334503 
	C180.954330,253.742081 178.930923,256.928345 173.354706,258.994720 
	C171.537552,259.668121 169.574844,259.948700 167.343414,260.565094 
z"/>
<path fill="#F57F06" opacity="1.000000" stroke="none" 
	d="
M151.256424,254.449478 
	C151.190964,254.736435 151.039078,254.966263 150.265259,255.129807 
	C149.493134,255.078384 149.256531,255.036118 149.019928,254.993866 
	C149.013458,254.545425 149.007004,254.096985 149.007538,252.943634 
	C149.000717,251.497253 148.986923,250.755783 148.973114,250.014328 
	C148.971085,242.983109 148.969040,235.951874 149.200272,228.310547 
	C149.611343,227.419785 149.789154,227.139130 149.966980,226.858475 
	C152.037521,226.563690 154.108078,226.268890 156.756989,226.183929 
	C157.899246,226.241074 158.463150,226.088394 159.027039,225.935699 
	C162.030838,225.917480 165.034637,225.899261 168.738647,226.157257 
	C172.034866,227.257355 174.630890,228.081268 177.226898,228.905182 
	C178.487213,230.298096 179.747543,231.691010 181.025360,233.827164 
	C181.022980,235.416229 181.003098,236.262024 180.693817,236.924332 
	C178.008072,235.023895 175.799240,232.273163 173.177750,231.797714 
	C167.065521,230.689163 160.751266,230.390350 154.527573,230.467514 
	C153.339172,230.482239 151.287521,233.679977 151.187683,235.494659 
	C150.841217,241.792618 151.178955,248.128204 151.256424,254.449478 
z"/>
<path fill="#F9F7CC" opacity="1.000000" stroke="none" 
	d="
M124.380569,247.679138 
	C124.123299,247.352905 124.004112,247.141510 123.619064,246.909698 
	C124.286179,243.589691 125.072571,240.471359 129.730637,242.585526 
	C130.589996,242.975586 132.099350,241.933548 133.970673,241.343933 
	C135.060837,243.537689 136.208771,245.847717 136.894653,248.463593 
	C135.907837,249.165436 135.383087,249.561401 134.858337,249.957352 
	C134.088089,250.282288 133.317841,250.607239 132.013397,250.846237 
	C129.314819,249.855713 127.150436,248.951141 124.986053,248.046570 
	C124.986053,248.046570 124.613693,247.740387 124.380569,247.679138 
z"/>
<path fill="#F9F7CC" opacity="1.000000" stroke="none" 
	d="
M206.275177,229.230392 
	C204.885330,230.943054 203.514145,232.429123 201.879791,233.521423 
	C201.668564,233.662582 199.204742,231.606552 199.340988,230.847839 
	C199.616425,229.313934 200.705185,227.190277 201.941055,226.786438 
	C203.021240,226.433472 204.762878,228.104797 206.275177,229.230392 
z"/>
<path fill="#F9F7CC" opacity="1.000000" stroke="none" 
	d="
M129.059601,268.768707 
	C129.103317,268.378418 129.147034,267.988098 129.155670,267.304291 
	C129.680695,266.661346 130.230011,266.035461 130.802948,266.013062 
	C132.703110,265.938690 134.671463,265.854370 136.468719,266.326111 
	C136.844421,266.424683 137.032776,269.870331 136.564774,270.074951 
	C134.913010,270.797180 132.938553,270.781372 130.748474,270.950989 
	C130.410477,270.856537 130.249176,270.395996 130.077423,269.918732 
	C129.623657,269.217224 129.341629,268.992950 129.059601,268.768707 
z"/>
<path fill="#E28614" opacity="1.000000" stroke="none" 
	d="
M180.983215,237.107834 
	C181.003098,236.262024 181.022980,235.416229 181.077118,234.142181 
	C185.942307,238.278564 185.963394,244.671860 180.759003,248.185791 
	C180.025848,248.457489 179.692307,248.580490 179.183502,248.206909 
	C179.270538,247.151611 179.532806,246.592911 180.071808,245.822723 
	C180.560089,242.776764 180.771652,239.942307 180.983215,237.107834 
z"/>
<path fill="#F7C40E" opacity="1.000000" stroke="none" 
	d="
M125.045761,248.389694 
	C127.150436,248.951141 129.314819,249.855713 131.743500,251.007416 
	C128.947922,252.966843 127.837349,252.561096 125.045761,248.389694 
z"/>
<path fill="#E28614" opacity="1.000000" stroke="none" 
	d="
M177.077423,228.588867 
	C174.630890,228.081268 172.034866,227.257355 169.187775,226.173401 
	C171.600449,226.699753 174.264206,227.486160 177.077423,228.588867 
z"/>
<path fill="#FAC604" opacity="1.000000" stroke="none" 
	d="
M148.669739,255.009567 
	C149.256531,255.036118 149.493134,255.078384 150.075974,255.221207 
	C150.348709,255.712936 150.275238,256.104095 150.062149,256.762573 
	C148.769821,257.208008 147.617081,257.386108 146.464355,257.564209 
	C147.082764,256.717896 147.701157,255.871582 148.669739,255.009567 
z"/>
<path fill="#E28614" opacity="1.000000" stroke="none" 
	d="
M158.848618,225.773224 
	C158.463150,226.088394 157.899246,226.241074 157.169495,226.199768 
	C157.559158,225.874100 158.114685,225.742432 158.848618,225.773224 
z"/>
<path fill="#FAC604" opacity="1.000000" stroke="none" 
	d="
M148.758606,250.214752 
	C148.986923,250.755783 149.000717,251.497253 148.984283,252.608017 
	C148.817383,252.123276 148.680740,251.269226 148.758606,250.214752 
z"/>
<path fill="#F7C40E" opacity="1.000000" stroke="none" 
	d="
M135.194641,249.927307 
	C135.383087,249.561401 135.907837,249.165436 136.775696,248.751923 
	C136.589523,249.121994 136.060242,249.509628 135.194641,249.927307 
z"/>
<path fill="#E28614" opacity="1.000000" stroke="none" 
	d="
M149.757523,226.999069 
	C149.789154,227.139130 149.611343,227.419785 149.226715,227.841461 
	C149.195953,227.701553 149.372009,227.420609 149.757523,226.999069 
z"/>
<path fill="#152023" opacity="1.000000" stroke="none" 
	d="
M154.512512,107.951241 
	C154.832581,107.823647 155.149323,107.896286 155.466049,107.968933 
	C155.147095,108.029778 154.828140,108.090622 154.512512,107.951241 
z"/>
<path fill="#F8BF06" opacity="1.000000" stroke="none" 
	d="
M185.996063,286.615540 
	C185.005325,285.891968 184.002319,284.805450 182.905640,283.408112 
	C183.869263,284.149048 184.926529,285.200806 185.996063,286.615540 
z"/>
<path fill="#F8BF06" opacity="1.000000" stroke="none" 
	d="
M189.284241,294.731323 
	C188.735199,293.879974 188.407394,292.782898 188.037994,291.343872 
	C188.499451,292.163177 189.002472,293.324371 189.284241,294.731323 
z"/>
<path fill="#FBCE07" opacity="1.000000" stroke="none" 
	d="
M129.149963,269.015320 
	C129.341629,268.992950 129.623657,269.217224 129.942017,269.715759 
	C129.732330,269.747345 129.486313,269.504639 129.149963,269.015320 
z"/>
<path fill="#3DBFE1" opacity="1.000000" stroke="none" 
	d="
M157.217407,164.096512 
	C160.652832,163.490662 164.384125,163.182465 168.575638,162.881042 
	C165.633087,166.740952 161.463638,164.729630 157.217407,164.096512 
z"/>
<path fill="#208DB1" opacity="1.000000" stroke="none" 
	d="
M190.273224,160.260925 
	C190.575867,160.897888 190.711945,161.913467 190.567688,163.001434 
	C190.227142,162.262390 190.166901,161.450958 190.273224,160.260925 
z"/>
<path fill="#1DBCEA" opacity="1.000000" stroke="none" 
	d="
M221.946655,359.355164 
	C223.066345,358.967896 224.122849,358.943634 225.740692,359.139832 
	C226.875534,359.225922 227.449020,359.091522 228.022507,358.957153 
	C241.408936,358.674347 254.795364,358.391541 268.869629,358.319092 
	C271.354126,358.356842 273.150787,358.184204 274.947418,358.011597 
	C293.316162,357.990814 311.684875,357.970062 330.520538,357.964783 
	C332.065765,362.174591 333.734039,362.990662 338.381592,361.429260 
	C342.309540,360.109589 347.240448,361.775269 351.716034,362.585327 
	C349.657898,362.925568 347.599792,363.265778 344.966797,364.041565 
	C341.416138,366.692200 338.440369,368.907227 335.129913,371.279480 
	C334.334442,373.211121 333.439087,375.001587 333.503845,376.756653 
	C333.636200,380.345276 332.751831,382.139160 328.791534,380.935181 
	C327.333374,380.491852 325.779175,380.364502 324.307526,380.100006 
	C324.505127,378.042633 324.674866,376.275726 324.897766,374.274872 
	C325.455963,373.662994 325.960999,373.285034 326.814026,372.715485 
	C327.101135,370.343018 327.040283,368.162079 326.886108,365.897400 
	C326.792786,365.813599 326.834717,365.566345 326.779480,365.252808 
	C326.474365,364.605530 326.224487,364.271759 325.648529,363.685760 
	C320.905853,363.288696 316.485748,362.962494 312.073669,363.048553 
	C307.382446,363.139984 302.698822,363.622070 298.001648,364.199982 
	C297.995483,364.644043 297.999573,364.821716 298.001831,364.999695 
	C298.000000,365.000000 297.999420,365.003448 297.742096,364.995667 
	C297.313202,364.992615 297.141632,364.997375 296.694183,365.229309 
	C294.628296,365.953033 292.838287,366.449554 290.974823,366.646423 
	C290.276794,365.895996 289.652222,365.445221 288.636780,364.962280 
	C287.219666,364.790497 286.193390,364.650818 285.412964,364.368164 
	C286.356781,364.004181 287.054779,363.783234 287.752777,363.562256 
	C287.625946,363.160095 287.499115,362.757935 287.372284,362.355774 
	C285.233063,363.150146 283.093811,363.944519 280.870605,364.894653 
	C280.786591,365.050415 280.437408,365.108063 280.022675,365.126526 
	C274.664703,365.783813 269.721497,366.422577 264.465942,366.908203 
	C261.670410,366.201569 259.187195,365.648102 256.305664,365.205627 
	C254.959610,367.603668 254.011871,369.890717 253.001968,372.327759 
	C251.707901,372.327759 250.120361,372.327759 249.521057,372.327759 
	C248.012192,370.225616 246.932190,368.720978 245.491547,367.019165 
	C237.185181,366.906372 229.239456,366.990692 221.033051,367.190857 
	C220.524918,367.591034 220.277466,367.875336 220.065704,368.125488 
	C220.101379,368.091309 220.167923,368.164368 219.790741,368.132019 
	C217.280243,367.735870 215.146912,367.372040 213.013580,367.008240 
	C214.222336,365.677002 215.294373,364.176788 216.675751,363.058838 
	C218.269196,361.769257 220.135590,360.816956 221.946655,359.355164 
z"/>
<path fill="#EAF8F3" opacity="1.000000" stroke="none" 
	d="
M193.384048,399.837830 
	C188.318054,390.680908 183.535797,381.553406 178.506653,371.799927 
	C177.829056,369.778198 177.398315,368.382446 176.967575,366.986664 
	C176.967575,366.986664 177.006790,366.996124 177.357056,366.819641 
	C182.643066,364.579895 185.769852,366.563843 187.946960,371.052643 
	C190.260864,375.823456 192.734940,380.516632 195.736511,386.418427 
	C197.268265,382.572723 198.336395,380.031219 199.293945,377.448730 
	C200.311996,374.703094 201.225250,371.918610 202.184265,369.151093 
	C202.184280,369.151093 202.136459,369.031464 202.354523,369.007935 
	C202.739807,368.657440 202.907013,368.330444 203.074219,368.003448 
	C203.074219,368.003448 203.007172,368.012482 203.236191,367.934174 
	C203.739700,367.666504 203.948380,367.421173 204.091248,367.119873 
	C206.803894,367.658356 209.516556,368.196869 212.348328,369.295837 
	C212.546417,370.326355 212.625381,370.796478 212.355972,371.412964 
	C210.331848,375.641113 208.656082,379.722900 206.745819,384.069275 
	C203.354187,389.604919 200.197067,394.875946 197.039948,400.147003 
	C196.543076,400.153778 196.046188,400.160553 195.031357,400.160767 
	C194.513397,400.154175 194.149612,400.172821 194.149612,400.172821 
	C193.989014,400.070984 193.828400,399.969116 193.384048,399.837830 
z"/>
<path fill="#11B6E1" opacity="1.000000" stroke="none" 
	d="
M212.704346,371.266571 
	C212.625381,370.796478 212.546417,370.326355 212.558807,369.618347 
	C212.754745,368.856964 212.859344,368.333527 212.988770,367.409149 
	C215.146912,367.372040 217.280243,367.735870 219.764130,368.588226 
	C220.164536,375.321777 220.214371,381.566772 220.243164,388.263794 
	C220.188522,390.837280 220.154953,392.958771 220.121368,395.080261 
	C217.360535,395.721649 214.599686,396.363037 211.434570,396.622009 
	C211.013138,394.169769 211.796173,391.551941 210.825729,390.134949 
	C208.129242,386.197632 209.158447,382.657806 210.576523,378.816223 
	C211.479126,376.371063 212.007935,373.787872 212.704346,371.266571 
z"/>
<path fill="#0FA6D3" opacity="1.000000" stroke="none" 
	d="
M201.740219,369.094971 
	C201.225250,371.918610 200.311996,374.703094 199.293945,377.448730 
	C198.336395,380.031219 197.268265,382.572723 195.736511,386.418427 
	C192.734940,380.516632 190.260864,375.823456 187.946960,371.052643 
	C185.769852,366.563843 182.643066,364.579895 177.346436,366.802429 
	C178.867111,363.899567 181.504333,363.818146 184.653030,364.715546 
	C190.163849,366.286133 195.744675,367.610992 201.740219,369.094971 
z"/>
<path fill="#0A97C2" opacity="1.000000" stroke="none" 
	d="
M170.535675,367.097778 
	C170.717514,369.279999 170.530518,371.448212 170.074646,373.941711 
	C169.557434,372.107239 169.309067,369.947510 168.873825,367.431152 
	C169.180252,367.077576 169.673553,367.080688 170.535675,367.097778 
z"/>
<path fill="#0FA6D3" opacity="1.000000" stroke="none" 
	d="
M176.725983,367.015625 
	C177.398315,368.382446 177.829056,369.778198 178.276947,371.539032 
	C177.536774,370.534851 176.779434,369.165588 176.011017,367.443359 
	C175.999908,367.090332 176.484390,367.044586 176.725983,367.015625 
z"/>
<path fill="#0FA6D3" opacity="1.000000" stroke="none" 
	d="
M203.927551,367.002197 
	C203.948380,367.421173 203.739700,367.666504 203.247681,367.966003 
	C203.274719,367.678925 203.519287,367.281769 203.927551,367.002197 
z"/>
<path fill="#0FA6D3" opacity="1.000000" stroke="none" 
	d="
M202.864136,368.147125 
	C202.907013,368.330444 202.739807,368.657440 202.294083,369.030212 
	C202.228409,368.814240 202.441238,368.552521 202.864136,368.147125 
z"/>
<path fill="#45D4F6" opacity="1.000000" stroke="none" 
	d="
M189.195404,134.018341 
	C188.641724,133.405670 188.517395,132.784866 188.591675,131.397430 
	C188.768814,128.777756 188.747330,126.924721 188.725830,125.071686 
	C191.433273,123.969742 194.140717,122.867805 197.701813,121.814766 
	C198.869370,121.847153 199.183243,121.830650 199.497116,121.814148 
	C200.441772,121.783653 201.386429,121.753151 202.664780,122.289078 
	C203.663361,131.908035 204.328247,140.960571 204.993134,150.013107 
	C204.551483,150.012802 204.109833,150.012482 203.337097,150.016754 
	C202.964920,140.383881 197.755280,134.150375 189.195404,134.018341 
z"/>
<path fill="#0FA6D3" opacity="1.000000" stroke="none" 
	d="
M208.094696,176.666351 
	C207.217560,176.741394 206.473175,176.806488 205.354370,176.868256 
	C202.229660,176.550552 199.452118,176.377853 196.741653,175.856354 
	C195.555328,175.628098 194.506165,174.686996 193.394714,174.069595 
	C194.080856,172.906906 194.767014,171.744232 195.666580,170.333954 
	C200.367661,169.655685 204.855301,169.224991 209.723480,168.841339 
	C210.104019,168.888382 210.206390,168.953705 210.155518,169.238007 
	C210.083450,169.713226 210.062271,169.904114 210.060898,170.414108 
	C210.109558,171.157639 210.138397,171.582047 210.167252,172.006470 
	C209.997391,172.909424 209.827515,173.812363 209.438263,175.198761 
	C208.929123,176.085800 208.639389,176.489380 208.349640,176.892960 
	C208.349640,176.892960 208.227448,176.656418 208.094696,176.666351 
z"/>
<path fill="#53D1F0" opacity="1.000000" stroke="none" 
	d="
M204.994507,150.429993 
	C204.328247,140.960571 203.663361,131.908035 203.005157,122.407822 
	C203.187897,121.925499 203.363937,121.890862 203.922668,122.196198 
	C204.653366,123.359123 205.001358,124.182076 205.349335,125.005028 
	C205.279709,126.388252 205.210068,127.771484 205.085663,129.874573 
	C205.290100,131.088486 205.549332,131.582520 205.808548,132.076553 
	C206.113373,137.169815 206.418198,142.263062 206.849487,148.012314 
	C207.059113,149.101517 207.142273,149.534729 207.225433,149.967941 
	C208.386734,154.431335 209.548035,158.894730 210.883270,163.935852 
	C211.134247,164.664459 211.211273,164.815308 211.288300,164.966156 
	C211.462021,165.745392 211.635727,166.524643 211.655487,167.731445 
	C211.275604,168.425354 211.049698,168.691681 210.669434,168.956696 
	C210.515091,168.955399 210.206390,168.953705 210.206390,168.953705 
	C210.206390,168.953705 210.104019,168.888382 210.067337,168.837753 
	C208.352402,162.807037 206.674149,156.826950 204.994507,150.429993 
z"/>
<path fill="#F9F7CC" opacity="1.000000" stroke="none" 
	d="
M466.903870,169.003433 
	C467.726227,169.587601 468.548584,170.171768 469.667572,171.292786 
	C469.950836,172.815140 469.937500,173.800644 469.924164,174.786133 
	C469.685974,175.010483 469.448212,175.235291 468.770508,175.701385 
	C467.951263,176.126511 467.572449,176.310776 467.193665,176.495056 
	C466.706665,176.376144 466.219696,176.257202 465.359863,176.088501 
	C464.844330,174.202667 464.701630,172.366562 464.975677,170.117905 
	C465.643738,169.493790 465.815887,169.230438 465.908844,168.915298 
	C465.908844,168.915298 465.878387,168.920197 466.133606,168.939270 
	C466.560486,168.973389 466.732178,168.988403 466.903870,169.003433 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M470.302124,174.823883 
	C469.937500,173.800644 469.950836,172.815140 469.921753,171.462646 
	C470.105927,171.077103 470.332550,171.058563 470.901733,171.027695 
	C471.796692,171.253082 472.349060,171.490814 473.124756,171.752457 
	C473.211151,172.543411 473.074310,173.310471 472.940918,174.371124 
	C472.189606,174.730362 471.434814,174.795990 470.302124,174.823883 
z"/>
<path fill="#A7D10C" opacity="1.000000" stroke="none" 
	d="
M467.385803,176.609680 
	C467.572449,176.310776 467.951263,176.126511 468.646881,175.913605 
	C468.501801,176.164749 468.039886,176.444519 467.385803,176.609680 
z"/>
<path fill="#F3F5E8" opacity="1.000000" stroke="none" 
	d="
M290.326843,218.960358 
	C290.629211,218.528778 290.931610,218.097183 291.626282,217.339111 
	C304.484772,204.250717 318.969574,195.259888 337.478394,192.999023 
	C344.288757,193.360825 350.638092,193.648712 357.371704,194.057251 
	C360.178253,194.731583 362.600525,195.285278 365.322205,196.035431 
	C371.081146,198.819946 376.540649,201.408035 382.000122,203.996155 
	C383.727325,205.291931 385.454559,206.587708 387.611511,208.305481 
	C388.993103,210.496124 389.944916,212.264801 390.563141,214.033432 
	C389.089478,214.877380 387.949402,215.721375 386.578613,216.708008 
	C386.018127,216.998230 385.688354,217.145828 385.136047,217.035263 
	C384.294617,216.137070 383.675720,215.497025 382.971680,214.643082 
	C382.699341,214.161606 382.444183,214.003860 381.840271,213.767075 
	C380.739014,212.801086 379.918610,212.023987 379.045837,211.142548 
	C378.993500,211.038193 378.577942,210.854462 378.288147,210.651001 
	C377.036865,209.764542 376.075439,209.081558 375.021301,208.254730 
	C374.755310,207.941620 374.547516,207.858429 373.982666,207.700104 
	C373.459137,207.406845 373.258209,207.274796 372.976379,207.042145 
	C372.751678,206.826736 372.588715,206.782501 372.320801,206.789185 
	C372.235016,206.769485 372.103668,206.652313 371.947632,206.452362 
	C371.322296,206.091843 370.852966,205.931290 370.029449,205.615204 
	C369.450073,205.333954 369.224915,205.208191 368.924072,204.996674 
	C368.848419,204.910904 368.409363,204.832245 368.058716,204.713806 
	C367.487122,204.485535 367.266174,204.375702 366.939514,204.148056 
	C366.649658,203.899490 366.447357,203.858826 365.946960,203.830414 
	C365.490265,203.673080 365.313568,203.593552 365.061462,203.294312 
	C364.350433,202.978210 363.714813,202.881790 362.727814,202.731171 
	C361.926025,202.532852 361.475586,202.388718 360.893158,202.132568 
	C360.542664,201.904663 360.317413,201.889618 359.715332,201.929916 
	C358.619141,201.718674 357.893005,201.552917 357.032227,201.154846 
	C355.956146,200.907028 355.014709,200.891541 353.637756,200.866989 
	C351.196350,200.662384 349.190430,200.466812 346.991821,200.040802 
	C345.140961,199.955154 343.482880,200.099945 341.428406,200.348480 
	C339.671295,200.603439 338.310547,200.754669 336.802612,200.735092 
	C336.040802,200.811432 335.426208,201.058578 334.422333,201.410706 
	C332.700165,201.708191 331.367340,201.900726 329.688995,202.035858 
	C328.172913,202.495926 327.002350,203.013397 325.578369,203.641983 
	C325.145966,203.797394 324.966949,203.841705 324.625854,203.881866 
	C324.240723,203.924927 324.066315,204.043610 323.634033,204.405869 
	C323.110931,204.667297 322.894318,204.756592 322.538635,204.844025 
	C322.208435,204.884598 322.059967,204.988190 321.633057,205.320068 
	C320.858643,205.652405 320.405365,205.817581 319.604584,205.970764 
	C317.805420,206.858658 316.353760,207.758545 314.630859,208.845184 
	C313.760040,209.299393 313.160522,209.566879 312.429749,209.849457 
	C312.123566,209.929245 311.998169,210.046829 311.620941,210.448166 
	C310.667542,211.068283 310.015625,211.457596 309.269379,211.857483 
	C309.175049,211.868088 308.934875,212.147324 308.651489,212.356964 
	C308.160278,212.676361 307.952454,212.786133 307.560974,212.936295 
	C307.135681,213.090363 306.973877,213.274292 306.623138,213.749084 
	C301.206482,219.880722 296.058624,225.791763 290.679230,231.853424 
	C290.306610,232.335358 290.165497,232.666672 290.008179,232.998322 
	C289.991913,232.998672 290.006409,233.027771 289.769897,233.209305 
	C289.331085,233.939209 289.128723,234.487595 288.718781,235.200424 
	C288.315582,235.843109 288.120026,236.321335 287.690491,237.008484 
	C287.274506,237.802338 287.092560,238.387314 286.948547,238.979248 
	C286.986450,238.986206 286.934784,238.928970 286.675110,239.150696 
	C285.894684,240.880417 285.373901,242.388397 284.604095,244.168030 
	C283.082092,249.937668 281.809113,255.435669 280.347626,261.179382 
	C280.069336,262.933197 279.979553,264.441254 279.884674,266.341583 
	C279.749542,267.763519 279.619446,268.793243 279.112793,269.853760 
	C277.804138,268.691315 276.418945,267.625092 276.040741,266.276489 
	C275.392548,263.965088 275.373901,261.477142 275.136658,258.718323 
	C275.129608,257.916687 275.076843,257.460052 275.024048,257.003418 
	C276.687714,246.045517 280.547882,235.881104 286.423157,225.806946 
	C287.016785,224.692856 287.291931,224.101425 287.567078,223.509995 
	C287.567078,223.509995 287.566223,223.450974 287.807373,223.310547 
	C288.293365,222.907959 288.392365,222.599045 288.345520,222.243408 
	C288.345520,222.243408 288.233917,222.240952 288.494446,222.156113 
	C288.887451,221.737122 289.019897,221.402969 289.152374,221.068832 
	C289.152374,221.068832 289.070374,221.062317 289.383118,220.924210 
	C289.906158,220.177521 290.116486,219.568939 290.326843,218.960358 
z"/>
<path fill="#1C2C2E" opacity="1.000000" stroke="none" 
	d="
M365.022766,195.838989 
	C362.600525,195.285278 360.178253,194.731583 357.279480,193.690735 
	C353.028076,192.458664 349.270599,191.264740 345.472626,191.117859 
	C342.681671,191.009918 339.837677,192.273010 337.017334,192.925110 
	C318.969574,195.259888 304.484772,204.250717 291.695160,217.084259 
	C291.079376,216.153625 290.343292,215.040634 290.561554,214.165741 
	C293.555054,202.166580 291.291290,197.900085 279.365143,194.054733 
	C281.196259,190.006927 283.207733,185.889893 285.736847,182.120117 
	C286.275116,181.317764 289.023010,181.163437 290.188782,181.778992 
	C293.212067,183.375336 296.287140,185.205765 298.662079,187.613083 
	C301.570709,190.561310 304.591064,191.540771 308.514191,190.667236 
	C318.117767,188.528870 327.710083,186.313797 337.376740,184.501968 
	C340.007416,184.008896 343.004211,184.414322 345.626526,185.146667 
	C351.455353,186.774582 357.230408,188.669571 362.882050,190.834976 
	C367.905396,192.759613 372.732819,195.195435 377.581909,197.917999 
	C375.654236,197.944733 373.807953,197.378174 371.924377,196.994217 
	C369.640289,196.528595 367.324707,196.217148 365.022766,195.838989 
z"/>
<path fill="#1D2C33" opacity="1.000000" stroke="none" 
	d="
M390.896729,214.033478 
	C389.944916,212.264801 388.993103,210.496124 387.919067,208.410736 
	C388.936737,205.091583 389.899689,202.002579 391.305420,199.130280 
	C391.850861,198.015747 393.396973,197.390945 394.980957,196.276215 
	C395.474670,196.009384 395.959595,195.892136 395.959595,195.892136 
	C395.841766,197.159637 395.961334,198.508347 395.560822,199.679153 
	C394.441437,202.951706 395.601715,204.238403 399.281006,203.982391 
	C405.129059,212.118546 403.056213,219.894424 397.925842,227.821579 
	C396.823975,228.519424 396.329590,228.948120 395.554016,229.378265 
	C394.898804,229.402100 394.524750,229.424484 394.092560,229.093735 
	C393.354736,227.823349 392.675049,226.906082 391.995392,225.988831 
	C391.757477,224.871872 391.519592,223.754929 391.138428,222.055054 
	C390.985901,221.294632 390.976624,221.117126 391.007996,220.612137 
	C391.030609,220.065430 391.012543,219.846191 391.068542,219.660645 
	C391.142670,219.694366 391.188812,219.850571 391.370209,219.777832 
	C391.769714,219.538620 391.903870,219.320450 392.042114,218.674500 
	C392.151001,217.527893 392.171783,216.757401 392.296814,215.857437 
	C392.455383,215.355652 392.291016,215.115067 391.746277,214.831421 
	C391.449066,214.345367 391.219788,214.137665 390.896729,214.033478 
z"/>
<path fill="#0A97C2" opacity="1.000000" stroke="none" 
	d="
M332.004517,171.025009 
	C331.609955,170.694885 331.215424,170.364761 330.505676,169.720123 
	C330.190460,169.405609 329.953064,168.988586 329.921875,168.729187 
	C329.608307,168.286041 329.325958,168.102280 329.038818,167.959030 
	C329.034027,167.999542 329.079285,167.931610 329.015930,167.650269 
	C328.175903,166.531174 327.399292,165.693405 326.527222,164.610580 
	C326.302551,164.247055 326.173309,164.128586 326.018463,163.631744 
	C325.997375,163.000565 326.001892,162.747772 326.299988,162.352661 
	C329.080963,163.224945 331.568329,164.239532 334.260376,165.509171 
	C340.037354,169.801529 345.956482,172.665588 353.551697,171.270325 
	C360.967072,171.763626 365.707703,167.214523 370.929871,164.102448 
	C369.640350,167.347748 368.332458,170.194550 367.024567,173.041336 
	C367.024567,173.041336 366.998016,173.001617 366.648743,173.019867 
	C365.550812,173.553680 364.802155,174.069229 364.053528,174.584778 
	C357.294739,178.093079 350.255432,178.785233 342.267578,176.428055 
	C338.774078,174.729980 335.951294,173.292007 333.128510,171.854034 
	C333.128510,171.854034 333.067291,171.908096 333.014923,171.665649 
	C332.643219,171.290451 332.323853,171.157730 332.004517,171.025009 
z"/>
<path fill="#142122" opacity="1.000000" stroke="none" 
	d="
M329.953064,168.988586 
	C329.953064,168.988586 330.190460,169.405609 330.262360,169.634521 
	C326.447601,175.226593 321.428192,178.825531 314.881012,180.383591 
	C313.761353,180.650040 312.712738,182.319550 312.139160,183.573242 
	C310.259796,187.680786 306.535461,189.200653 302.559418,187.241150 
	C298.635376,185.307266 298.368896,182.017685 301.651917,179.122147 
	C303.029846,177.906860 303.901794,176.117844 305.392700,174.218262 
	C306.515045,173.990982 307.248444,174.135086 308.004944,174.609650 
	C308.649506,175.504547 309.271057,176.068985 309.892578,176.633423 
	C310.262817,175.794479 310.633057,174.955536 311.315674,174.010574 
	C312.065521,173.886551 312.502960,173.868561 313.109985,174.065536 
	C313.854370,173.885757 314.429169,173.491028 315.271118,172.895096 
	C317.334167,171.126541 319.130127,169.559174 321.379150,167.993896 
	C324.247894,167.974548 326.663574,167.953079 329.079285,167.931610 
	C329.079285,167.931610 329.034027,167.999542 329.134338,168.180695 
	C329.415070,168.638474 329.654541,168.847382 329.953064,168.988586 
z"/>
<path fill="#1799C0" opacity="1.000000" stroke="none" 
	d="
M257.466858,156.017792 
	C257.816559,156.011536 258.166229,156.005264 258.920471,156.311417 
	C260.107574,157.897873 260.882263,159.176819 261.673920,160.445145 
	C265.183533,166.067963 270.398285,168.056747 277.458130,166.304626 
	C281.573151,165.223160 285.282440,164.153809 288.991730,163.084473 
	C288.639954,164.464523 288.288177,165.844574 287.602600,167.721054 
	C286.837891,168.807892 286.406952,169.398315 285.976013,169.988724 
	C276.115234,175.707047 271.319946,175.570892 263.002441,168.895554 
	C261.037994,166.291901 259.508881,164.141495 257.972137,161.706909 
	C257.677032,161.188599 257.389526,160.954483 257.095581,160.323944 
	C257.215057,158.624268 257.340973,157.321030 257.466858,156.017792 
z"/>
<path fill="#1589AF" opacity="1.000000" stroke="none" 
	d="
M307.981873,174.279205 
	C307.248444,174.135086 306.515045,173.990982 305.407074,173.840012 
	C301.788757,170.096680 298.544983,166.360214 295.202148,161.945526 
	C295.114594,159.526581 295.126068,157.785843 295.419861,156.049866 
	C296.060577,156.170944 296.419037,156.287277 296.797485,156.741882 
	C299.668579,164.748230 303.623871,167.475327 311.301880,166.828064 
	C314.144470,164.948898 316.628235,163.061081 319.339783,161.115906 
	C319.567535,161.058548 320.033325,160.997421 320.265656,161.130371 
	C320.751801,161.514343 321.005615,161.765381 321.234924,162.413559 
	C321.302338,163.865402 321.394287,164.920120 321.319763,166.287659 
	C321.077606,167.064255 321.001831,167.528030 320.926086,167.991791 
	C319.130127,169.559174 317.334167,171.126541 314.983368,172.883240 
	C313.932465,173.331894 313.436462,173.591217 312.940430,173.850555 
	C312.502960,173.868561 312.065521,173.886551 310.938049,173.944000 
	C309.492676,174.082031 308.737274,174.180618 307.981873,174.279205 
z"/>
<path fill="#142122" opacity="1.000000" stroke="none" 
	d="
M257.099731,155.826172 
	C257.340973,157.321030 257.215057,158.624268 257.177368,160.575790 
	C257.503632,161.479752 257.741669,161.735428 257.979736,161.991104 
	C259.508881,164.141495 261.037994,166.291901 262.687073,168.750031 
	C261.425720,170.654953 260.044403,172.252136 258.332153,173.924973 
	C255.210754,173.362137 252.420273,172.723663 249.282715,172.029816 
	C249.072525,171.651993 249.209412,171.329559 249.442230,170.762222 
	C249.538162,170.517334 249.534775,170.392548 249.808167,170.238525 
	C253.458237,166.950317 253.100296,163.021530 252.434128,159.027176 
	C252.251541,157.456604 252.293854,155.818512 251.846191,154.327530 
	C250.186356,148.799332 250.786789,143.730972 254.824432,139.079224 
	C255.360107,139.841080 255.532623,140.679962 255.467545,142.165955 
	C255.492783,145.208984 255.755615,147.604904 256.018433,150.000839 
	C256.256470,151.878738 256.494537,153.756638 257.099731,155.826172 
z"/>
<path fill="#16272D" opacity="1.000000" stroke="none" 
	d="
M295.137543,156.045120 
	C295.126068,157.785843 295.114594,159.526581 295.063904,161.629044 
	C294.153229,162.635147 293.281708,163.279510 291.976410,164.244614 
	C291.332520,162.027573 290.818451,160.257523 289.847351,157.938354 
	C288.930542,155.938660 288.470734,154.488098 288.019653,152.612183 
	C289.703705,144.461868 289.888519,136.739243 287.991760,129.019913 
	C288.818329,127.755447 289.644897,126.490982 290.471436,125.226517 
	C290.885132,125.346207 291.298828,125.465904 291.712524,125.585594 
	C292.141266,131.551956 292.557495,137.519257 293.003693,143.484314 
	C293.256714,146.867096 293.558899,150.246231 294.315369,154.139603 
	C294.907043,155.116516 295.022308,155.580811 295.137543,156.045120 
z"/>
<path fill="#E5368F" opacity="1.000000" stroke="none" 
	d="
M396.128967,195.533356 
	C395.959595,195.892136 395.474670,196.009384 395.228699,196.051224 
	C392.795410,194.560730 390.539062,193.115463 388.449738,191.459503 
	C387.395233,190.623779 386.643219,189.406387 385.754456,188.361542 
	C386.726379,187.605148 387.634644,186.741486 388.685394,186.117828 
	C390.210846,185.212387 391.839172,184.480164 393.657532,183.345245 
	C395.438629,183.205551 396.985870,183.394714 398.770416,184.193085 
	C399.337097,186.501053 399.666443,188.199814 399.703003,189.983490 
	C399.661926,191.619385 399.913666,193.170349 400.165405,194.721313 
	C398.876404,194.872406 397.587372,195.023499 396.128967,195.533356 
z"/>
<path fill="#4BDAFA" opacity="1.000000" stroke="none" 
	d="
M289.144348,162.737427 
	C285.282440,164.153809 281.573151,165.223160 277.449738,165.840027 
	C277.655670,162.026520 278.275757,158.665482 279.292572,155.216293 
	C282.866486,156.333618 286.043610,157.539093 289.220764,158.744568 
	C289.246155,159.959839 289.271576,161.175125 289.144348,162.737427 
z"/>
<path fill="#16272D" opacity="1.000000" stroke="none" 
	d="
M333.091125,172.260498 
	C335.951294,173.292007 338.774078,174.729980 341.844421,176.408875 
	C342.193237,178.247498 342.294495,179.845215 342.338837,180.544952 
	C339.385040,181.314285 336.889618,182.126602 334.329834,182.543961 
	C333.418976,182.692490 331.792908,182.135956 331.482513,181.452225 
	C330.973541,180.331070 331.022827,178.789703 331.293060,177.522888 
	C331.648834,175.854950 332.446228,174.281204 333.091125,172.260498 
z"/>
<path fill="#B3E8ED" opacity="1.000000" stroke="none" 
	d="
M289.348877,158.562256 
	C286.043610,157.539093 282.866486,156.333618 278.938599,155.131195 
	C276.811218,154.076523 275.434540,153.018784 274.429077,151.947540 
	C275.043915,151.888687 275.287567,151.843369 275.879456,152.010681 
	C280.127533,152.898026 284.347778,148.936874 288.010925,153.037521 
	C288.470734,154.488098 288.930542,155.938660 289.555725,157.698212 
	C289.721069,158.007172 289.476959,158.379959 289.348877,158.562256 
z"/>
<path fill="#249EC0" opacity="1.000000" stroke="none" 
	d="
M367.361389,172.976440 
	C368.332458,170.194550 369.640350,167.347748 370.951233,163.766357 
	C372.331238,161.082611 373.708282,159.133453 375.085327,157.184296 
	C375.446533,158.023254 375.807770,158.862213 376.354797,159.929657 
	C376.081268,166.134399 372.509033,169.952332 367.361389,172.976440 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M245.430954,182.189789 
	C245.003769,181.935318 244.996628,181.740738 244.957092,181.255432 
	C244.924698,180.964722 245.008591,180.531036 245.038696,180.313416 
	C248.767929,180.399429 252.467072,180.703064 256.582825,181.035675 
	C260.604553,184.225601 264.209656,187.386566 267.782867,190.908417 
	C265.859222,189.654373 263.967468,188.039459 261.712463,186.579407 
	C261.349213,186.734268 261.310303,186.792328 261.369812,186.436340 
	C261.007477,185.951279 260.585663,185.822220 260.121063,185.758820 
	C260.078308,185.824478 260.217590,185.896240 260.202362,185.531693 
	C259.764221,184.953751 259.341339,184.740372 258.634155,184.347549 
	C256.603088,183.645508 254.856232,183.122894 253.109390,182.600296 
	C252.574982,182.487305 252.040573,182.374329 251.017670,182.222046 
	C250.529190,182.182739 250.085052,182.318588 250.085052,182.318588 
	C248.673706,182.295609 247.262360,182.272629 245.430954,182.189789 
z"/>
<path fill="#1D2C33" opacity="1.000000" stroke="none" 
	d="
M365.322205,196.035431 
	C367.324707,196.217148 369.640289,196.528595 371.924377,196.994217 
	C373.807953,197.378174 375.654236,197.944733 377.758453,198.216797 
	C382.924591,197.113693 380.820374,201.377060 381.946014,203.654236 
	C376.540649,201.408035 371.081146,198.819946 365.322205,196.035431 
z"/>
<path fill="#EF3B85" opacity="1.000000" stroke="none" 
	d="
M387.543030,149.392166 
	C390.309784,152.441605 388.965881,155.630432 386.673706,158.901886 
	C386.703522,155.954544 387.056244,152.881119 387.543030,149.392166 
z"/>
<path fill="#EF3B85" opacity="1.000000" stroke="none" 
	d="
M382.973846,173.373810 
	C384.887482,174.882278 386.858795,176.726456 388.799377,178.938858 
	C386.856262,177.441208 384.943909,175.575348 382.973846,173.373810 
z"/>
<path fill="#16272D" opacity="1.000000" stroke="none" 
	d="
M286.296783,170.058044 
	C286.406952,169.398315 286.837891,168.807892 287.502014,168.069489 
	C288.672516,168.773163 289.609833,169.624817 290.547180,170.476471 
	C289.237305,170.360107 287.927429,170.243729 286.296783,170.058044 
z"/>
<path fill="#EF3B85" opacity="1.000000" stroke="none" 
	d="
M380.816650,168.229736 
	C381.680115,169.479095 382.322388,170.922684 382.960266,172.724014 
	C382.169067,171.529160 381.382263,169.976562 380.816650,168.229736 
z"/>
<path fill="#EF3B85" opacity="1.000000" stroke="none" 
	d="
M388.912354,179.511230 
	C390.125244,179.890930 391.498871,180.551331 392.940063,181.561508 
	C391.696106,181.204834 390.384583,180.498367 388.912354,179.511230 
z"/>
<path fill="#EF3B85" opacity="1.000000" stroke="none" 
	d="
M385.044922,164.552032 
	C384.699768,164.945053 384.358856,164.952209 383.764435,164.947800 
	C384.080322,163.519409 384.649689,162.102600 385.518036,160.509827 
	C385.561066,161.611298 385.305115,162.888748 385.044922,164.552032 
z"/>
<path fill="#EF3B85" opacity="1.000000" stroke="none" 
	d="
M386.092224,145.277466 
	C386.549377,145.495667 387.048035,146.005295 387.387451,146.713928 
	C386.863373,146.464920 386.498566,146.016907 386.092224,145.277466 
z"/>
<path fill="#249EC0" opacity="1.000000" stroke="none" 
	d="
M364.396790,174.523071 
	C364.802155,174.069229 365.550812,173.553680 366.669006,173.032257 
	C366.272400,173.504715 365.506226,173.983032 364.396790,174.523071 
z"/>
<path fill="#16272D" opacity="1.000000" stroke="none" 
	d="
M332.150421,171.226501 
	C332.323853,171.157730 332.643219,171.290451 333.053650,171.678589 
	C332.815704,171.842865 332.532898,171.674210 332.150421,171.226501 
z"/>
<path fill="#D2EEEF" opacity="1.000000" stroke="none" 
	d="
M322.963104,130.710587 
	C324.083252,128.182709 325.203430,125.654831 326.664856,122.956284 
	C328.787109,121.545624 330.465942,120.110985 332.372498,119.110138 
	C335.414001,117.513466 338.606995,116.205315 342.307251,114.360558 
	C345.585602,113.633087 348.292664,113.320221 350.999695,113.007362 
	C354.582855,114.668991 358.218567,116.228828 361.724945,118.039154 
	C363.265137,118.834335 364.541840,120.139862 365.774719,121.681381 
	C365.363190,122.359550 365.182434,122.617188 364.731140,122.852028 
	C362.307678,121.949249 360.222076,121.116455 357.757538,120.075928 
	C351.581238,120.352753 345.783936,120.837311 339.639282,121.352234 
	C336.546387,123.014442 333.800781,124.646286 330.765564,126.376556 
	C329.418549,127.676788 328.361115,128.878616 327.045044,130.111053 
	C326.621948,130.197357 326.457520,130.253036 325.876526,130.363953 
	C324.627716,130.516327 323.795410,130.613464 322.963104,130.710587 
z"/>
<path fill="#162225" opacity="1.000000" stroke="none" 
	d="
M351.013672,112.613983 
	C348.292664,113.320221 345.585602,113.633087 342.570984,114.039917 
	C340.705292,113.960251 339.147217,113.786621 337.286255,113.579247 
	C340.806519,107.872124 345.068665,105.525734 351.583923,107.684021 
	C351.406769,109.128891 351.217224,110.674744 351.013672,112.613983 
z"/>
<path fill="#88C6D8" opacity="1.000000" stroke="none" 
	d="
M365.069000,122.922012 
	C365.182434,122.617188 365.363190,122.359550 365.817932,122.028687 
	C367.986847,123.955650 369.949066,126.003044 372.141602,128.294861 
	C372.945740,130.448380 373.519531,132.357468 373.736694,134.645538 
	C373.028625,135.000198 372.677185,134.975845 372.244263,134.592346 
	C369.819275,131.366562 367.475830,128.499939 365.133820,125.295044 
	C365.113159,124.278511 365.091064,123.600258 365.069000,122.922012 
z"/>
<path fill="#3FCDEE" opacity="1.000000" stroke="none" 
	d="
M374.800232,157.050964 
	C373.708282,159.133453 372.331238,161.082611 370.932831,163.367859 
	C365.707703,167.214523 360.967072,171.763626 353.475739,170.887665 
	C351.618164,165.091797 347.621246,163.437317 342.546539,163.796173 
	C342.360840,160.154388 342.202209,157.043121 342.346436,154.101196 
	C344.410461,155.538422 346.171570,156.806320 348.174347,158.366562 
	C351.114410,158.999680 354.092896,160.237396 356.454742,159.500046 
	C361.864471,157.811157 367.047943,155.499115 372.903717,156.088470 
	C373.440857,156.364853 373.978027,156.641235 374.800232,157.050964 
z"/>
<path fill="#26A5CE" opacity="1.000000" stroke="none" 
	d="
M372.325775,134.951508 
	C372.677185,134.975845 373.028625,135.000198 373.643982,135.015366 
	C373.945770,135.170242 373.983582,135.334274 374.092529,135.740601 
	C374.135406,136.435318 374.107147,136.887772 374.164886,137.670471 
	C373.188782,140.711456 372.126678,143.422180 370.341370,146.245712 
	C367.379486,147.351334 365.140808,148.344147 362.493164,149.478943 
	C357.058594,149.997330 352.033020,150.373734 346.899902,150.637939 
	C346.472107,150.385651 346.205139,150.460800 345.545654,150.784729 
	C343.633850,150.585251 342.167999,150.352219 340.552429,149.968262 
	C339.877655,149.556961 339.352570,149.296585 338.641693,148.770233 
	C339.757782,146.196045 341.059692,143.887833 342.787598,141.643219 
	C347.147400,141.827148 351.081177,141.947495 354.782898,142.318512 
	C351.829071,144.176971 349.107330,145.784760 345.490753,147.921112 
	C347.287415,148.399582 348.469177,149.105286 349.484802,148.916565 
	C353.709045,148.131744 358.284637,147.835144 361.953552,145.891357 
	C365.106567,144.220901 367.203064,140.556381 369.946838,137.510254 
	C370.859863,136.481781 371.592804,135.716644 372.325775,134.951508 
z"/>
<path fill="#163643" opacity="1.000000" stroke="none" 
	d="
M347.007416,150.750122 
	C352.033020,150.373734 357.058594,149.997330 362.831665,149.572174 
	C364.735229,149.487717 365.891327,149.452011 367.047394,149.416306 
	C367.032776,150.031021 367.018158,150.645721 366.650635,151.640823 
	C360.176025,154.038879 354.054382,156.056549 347.932709,158.074219 
	C346.171570,156.806320 344.410461,155.538422 342.246460,153.844086 
	C341.528259,153.242722 341.212952,153.067810 340.844269,152.433563 
	C340.761322,151.355881 340.731720,150.737534 340.702118,150.119186 
	C342.167999,150.352219 343.633850,150.585251 345.799652,150.844238 
	C346.668823,150.830185 346.838104,150.790161 347.007416,150.750122 
z"/>
<path fill="#D2EEEF" opacity="1.000000" stroke="none" 
	d="
M348.174347,158.366562 
	C354.054382,156.056549 360.176025,154.038879 366.665894,151.947968 
	C368.964691,153.048126 370.895325,154.221542 372.864807,155.741714 
	C367.047943,155.499115 361.864471,157.811157 356.454742,159.500046 
	C354.092896,160.237396 351.114410,158.999680 348.174347,158.366562 
z"/>
<path fill="#208DB1" opacity="1.000000" stroke="none" 
	d="
M367.331665,149.217621 
	C365.891327,149.452011 364.735229,149.487717 363.240662,149.430176 
	C365.140808,148.344147 367.379486,147.351334 369.977234,146.274643 
	C369.429565,147.133484 368.522736,148.076218 367.331665,149.217621 
z"/>
<path fill="#EF3B85" opacity="1.000000" stroke="none" 
	d="
M388.816162,131.042633 
	C388.837585,131.195663 388.621735,131.454987 388.184021,131.837891 
	C388.167755,131.690598 388.373322,131.419754 388.816162,131.042633 
z"/>
<path fill="#FB418F" opacity="1.000000" stroke="none" 
	d="
M393.718140,128.191422 
	C393.788391,128.390640 393.573029,128.619843 393.064270,128.873962 
	C393.009247,128.687836 393.229828,128.462021 393.718140,128.191422 
z"/>
<path fill="#EF3B85" opacity="1.000000" stroke="none" 
	d="
M399.995789,189.898560 
	C399.666443,188.199814 399.337097,186.501053 399.015991,184.428238 
	C400.221313,183.637833 402.014618,183.582581 402.520386,182.747498 
	C406.284607,176.532608 411.625122,179.078033 416.797791,180.479660 
	C416.239563,181.654694 415.568207,182.264618 414.896881,182.874542 
	C414.896881,182.874542 414.914703,182.942963 414.598145,182.940506 
	C413.970123,183.252731 413.658630,183.567398 413.347107,183.882050 
	C413.347107,183.882050 413.224335,183.698593 413.116760,183.721497 
	C410.169159,184.224152 407.344940,184.912216 404.485016,185.129440 
	C401.128998,185.384354 401.130676,186.896606 402.267578,189.622421 
	C401.361694,189.964310 400.678772,189.931442 399.995789,189.898560 
z"/>
<path fill="#E656A2" opacity="1.000000" stroke="none" 
	d="
M399.703003,189.983505 
	C400.678772,189.931442 401.361694,189.964310 402.386078,190.000061 
	C403.358978,191.365097 403.990479,192.727234 404.621948,194.089386 
	C403.372375,194.408401 402.122772,194.727402 400.519287,194.883850 
	C399.913666,193.170349 399.661926,191.619385 399.703003,189.983505 
z"/>
<path fill="#112026" opacity="1.000000" stroke="none" 
	d="
M398.573059,242.242737 
	C398.490875,241.898224 398.623901,241.434097 398.932800,241.094635 
	C399.143127,241.570648 399.036316,241.871933 398.573059,242.242737 
z"/>
<path fill="#112026" opacity="1.000000" stroke="none" 
	d="
M398.146057,242.866119 
	C397.964905,242.988419 397.767944,242.575745 397.767944,242.575745 
	C397.767944,242.575745 398.070831,242.307236 398.236359,242.310150 
	C398.401886,242.313080 398.327240,242.743820 398.146057,242.866119 
z"/>
<path fill="#274248" opacity="1.000000" stroke="none" 
	d="
M395.835266,229.376816 
	C396.329590,228.948120 396.823975,228.519424 397.655029,228.045166 
	C398.072205,228.718506 398.152618,229.437424 398.234070,230.848862 
	C398.837555,232.206375 399.440002,232.871353 400.042419,233.536346 
	C399.996277,234.734100 399.950134,235.931854 399.862030,237.481873 
	C398.418365,238.449905 397.016632,239.065659 395.290100,239.559494 
	C394.693939,238.968063 394.422577,238.498550 394.140747,237.650574 
	C394.698578,234.640366 395.266907,232.008591 395.835266,229.376816 
z"/>
<path fill="#5297AD" opacity="1.000000" stroke="none" 
	d="
M400.155579,233.325165 
	C399.440002,232.871353 398.837555,232.206375 398.331421,231.197845 
	C398.952576,231.235153 399.477417,231.615982 400.118774,232.316406 
	C400.235291,232.635986 400.268707,233.113983 400.155579,233.325165 
z"/>
<path fill="#EDECCB" opacity="1.000000" stroke="none" 
	d="
M466.901611,168.579498 
	C466.732178,168.988403 466.560486,168.973389 466.132629,168.923813 
	C464.189728,166.340637 462.280670,163.897736 460.981506,161.164886 
	C460.678131,160.526718 462.444244,158.904694 463.621338,157.831909 
	C464.956085,161.337524 465.927704,164.746536 466.901611,168.579498 
z"/>
<path fill="#EDECCB" opacity="1.000000" stroke="none" 
	d="
M465.655396,168.982590 
	C465.815887,169.230438 465.643738,169.493790 465.189301,169.829742 
	C465.124817,169.652725 465.263397,169.351303 465.655396,168.982590 
z"/>
<path fill="#18262D" opacity="1.000000" stroke="none" 
	d="
M415.286072,182.910217 
	C415.568207,182.264618 416.239563,181.654694 417.075012,180.825623 
	C417.407379,180.549103 417.575592,180.491730 418.130249,180.162979 
	C418.736542,179.660446 418.887146,179.390594 418.968445,179.082031 
	C420.391510,178.390350 421.814606,177.698669 423.584473,176.949158 
	C421.179291,178.909515 418.427246,180.927719 415.286072,182.910217 
z"/>
<path fill="#18262D" opacity="1.000000" stroke="none" 
	d="
M413.663086,183.800140 
	C413.658630,183.567398 413.970123,183.252731 414.625061,182.917557 
	C414.638702,183.170792 414.308868,183.444519 413.663086,183.800140 
z"/>
<path fill="#2F3234" opacity="1.000000" stroke="none" 
	d="
M427.368286,172.927902 
	C427.537567,172.141174 428.018707,171.298370 428.792877,170.382660 
	C428.617279,171.163773 428.148682,172.017792 427.368286,172.927902 
z"/>
<path fill="#2F3234" opacity="1.000000" stroke="none" 
	d="
M425.405640,174.851654 
	C425.158112,174.622452 425.251801,174.393295 425.738464,174.241425 
	C425.872833,174.462524 425.744629,174.640167 425.405640,174.851654 
z"/>
<path fill="#2F3234" opacity="1.000000" stroke="none" 
	d="
M426.408020,173.828430 
	C426.158203,173.600159 426.259705,173.344849 426.761597,173.117920 
	C426.907928,173.331360 426.773651,173.549728 426.408020,173.828430 
z"/>
<path fill="#EF3B85" opacity="1.000000" stroke="none" 
	d="
M418.778015,179.194000 
	C418.887146,179.390594 418.736542,179.660446 418.291260,179.950470 
	C418.174744,179.726639 418.348663,179.492188 418.778015,179.194000 
z"/>
<path fill="#E4AE17" opacity="1.000000" stroke="none" 
	d="
M419.389191,273.780212 
	C423.254913,273.159302 426.771271,272.602448 430.295227,272.098938 
	C432.607178,271.768646 434.392242,272.030670 433.163391,275.457245 
	C431.232605,276.635284 429.328308,277.470612 427.173126,278.533569 
	C424.048462,280.471893 421.222961,282.272644 418.277893,283.850281 
	C417.050476,284.507812 414.529633,285.272980 414.372589,284.993042 
	C413.427979,283.309265 411.934479,280.636444 412.614166,279.602142 
	C414.137848,277.283478 416.827911,275.731262 419.389191,273.780212 
z"/>
<path fill="#0F222F" opacity="1.000000" stroke="none" 
	d="
M435.303101,276.282959 
	C437.063141,275.565430 439.086426,275.096222 441.505646,274.561493 
	C439.789825,275.174438 437.678101,275.852844 435.303101,276.282959 
z"/>
<path fill="#53CEE8" opacity="1.000000" stroke="none" 
	d="
M295.419861,156.049866 
	C295.022308,155.580811 294.907043,155.116516 294.638763,154.327942 
	C294.343109,143.767685 294.181732,133.531921 294.067627,123.295616 
	C294.025177,119.489906 294.093262,115.682968 294.234924,111.420013 
	C298.848572,115.743584 302.340637,121.946899 310.515137,122.024315 
	C310.929352,122.470322 310.958923,122.911240 310.737915,123.965530 
	C310.288239,126.590729 310.089111,128.602554 309.750458,132.024597 
	C310.962128,129.077774 311.597931,127.531456 312.233734,125.985138 
	C312.090118,130.351364 311.946503,134.717575 311.890198,139.533066 
	C311.921967,143.694275 311.866364,147.406235 311.874878,151.905457 
	C312.624329,153.460892 313.309692,154.229050 314.330872,155.116760 
	C316.455597,157.156693 318.244446,159.077057 320.033325,160.997421 
	C320.033325,160.997421 319.567535,161.058548 319.076233,160.873657 
	C317.058807,159.782440 315.532623,158.876114 313.825958,157.673370 
	C309.509949,154.367889 309.183075,149.875214 309.035889,145.354828 
	C308.940765,142.432999 309.018005,139.505554 309.018005,136.580612 
	C306.227386,138.539688 305.213989,141.002060 303.376038,142.251053 
	C298.227539,145.749817 295.908722,150.252945 296.777466,156.403625 
	C296.419037,156.287277 296.060577,156.170944 295.419861,156.049866 
z"/>
<path fill="#16272D" opacity="1.000000" stroke="none" 
	d="
M320.265656,161.130371 
	C318.244446,159.077057 316.455597,157.156693 314.339600,154.742279 
	C314.008331,153.499084 314.004150,152.749939 314.000000,152.000793 
	C314.642273,150.377182 315.284546,148.753571 315.926819,147.129959 
	C317.272156,148.246628 318.617523,149.363281 320.450928,150.816040 
	C321.016815,151.468613 321.172882,151.740128 321.378235,152.241440 
	C321.553619,153.019424 321.757935,153.522614 321.940918,154.356628 
	C322.151459,155.049698 322.383362,155.411957 322.576416,156.110657 
	C323.225800,158.107208 323.914062,159.767303 324.194580,161.559006 
	C322.944366,161.799210 322.101898,161.907822 321.259430,162.016434 
	C321.005615,161.765381 320.751801,161.514343 320.265656,161.130371 
z"/>
<path fill="#9EECF4" opacity="1.000000" stroke="none" 
	d="
M322.703308,130.900238 
	C323.795410,130.613464 324.627716,130.516327 325.806213,130.702301 
	C324.753357,134.036728 323.354279,137.088028 321.660126,140.107727 
	C320.972137,140.032822 320.579254,139.989517 320.186340,139.946213 
	C320.938721,136.994095 321.691101,134.041992 322.703308,130.900238 
z"/>
<path fill="#6AC8E0" opacity="1.000000" stroke="none" 
	d="
M320.054321,140.388443 
	C320.579254,139.989517 320.972137,140.032822 321.668976,140.545898 
	C321.784332,144.666000 321.595764,148.316330 321.407196,151.966644 
	C321.172882,151.740128 321.016815,151.468613 320.796509,150.962097 
	C320.410156,147.458252 320.166260,144.144470 320.054321,140.388443 
z"/>
<path fill="#091318" opacity="1.000000" stroke="none" 
	d="
M313.675537,151.998779 
	C314.004150,152.749939 314.008331,153.499084 314.003784,154.622726 
	C313.309692,154.229050 312.624329,153.460892 311.996277,152.353729 
	C312.486115,152.008774 312.918610,152.002777 313.675537,151.998779 
z"/>
<path fill="#6AC8E0" opacity="1.000000" stroke="none" 
	d="
M332.775146,105.451324 
	C332.998871,104.936195 333.393555,104.598518 333.832062,104.333221 
	C333.873291,104.308281 334.190338,104.739197 334.379333,104.958466 
	C333.905609,105.188538 333.431854,105.418610 332.775146,105.451324 
z"/>
<path fill="#2D5662" opacity="1.000000" stroke="none" 
	d="
M390.626312,357.912598 
	C392.304138,357.900482 393.666168,357.968689 395.428680,358.024109 
	C393.998138,359.812836 392.167145,361.614349 390.117096,363.665100 
	C388.940857,362.842987 387.983704,361.771667 387.005768,360.346008 
	C388.093506,359.325409 389.202026,358.659180 390.626312,357.912598 
z"/>
<path fill="#11B6E1" opacity="1.000000" stroke="none" 
	d="
M291.048248,366.946075 
	C292.838287,366.449554 294.628296,365.953033 296.744812,365.592957 
	C297.127686,365.967560 297.184021,366.205719 297.314178,366.623962 
	C297.438843,367.077637 297.377441,367.323090 297.138947,367.904968 
	C297.323547,369.164795 297.572937,370.060028 297.974365,371.306763 
	C299.686981,376.962128 304.698944,373.600647 307.417877,375.168671 
	C307.994049,382.946198 308.548584,390.431519 309.073730,397.944214 
	C309.044342,397.971558 308.988953,398.029724 308.988953,398.029724 
	C304.577423,395.714966 295.713287,396.939484 291.822418,400.311584 
	C291.185974,400.863159 290.236633,401.053680 289.344299,400.909027 
	C289.477386,399.577515 289.698181,398.748108 289.945862,397.937622 
	C289.972748,397.956604 289.917542,397.991760 290.179749,397.745361 
	C291.441315,395.186646 292.440613,392.874329 293.295074,390.897186 
	C292.484009,389.127411 291.202362,387.576782 291.142212,385.980133 
	C290.903564,379.643280 291.046631,373.291992 291.048248,366.946075 
z"/>
<path fill="#15B1E0" opacity="1.000000" stroke="none" 
	d="
M324.844574,374.508789 
	C324.674866,376.275726 324.505127,378.042633 324.307526,380.100006 
	C325.779175,380.364502 327.333374,380.491852 328.791534,380.935181 
	C332.751831,382.139160 333.636200,380.345276 333.503845,376.756653 
	C333.439087,375.001587 334.334442,373.211121 335.074066,371.680969 
	C335.586060,374.436829 335.819275,376.948395 336.145020,379.766602 
	C336.520752,380.381744 336.803986,380.690308 337.051544,380.985229 
	C337.015900,380.971558 337.046326,380.901520 337.087402,381.227539 
	C337.778137,382.017944 338.427765,382.482330 339.042725,382.937073 
	C339.008057,382.927460 339.075928,382.903564 339.041901,383.308197 
	C338.688812,385.303009 338.369751,386.893158 337.918152,388.711060 
	C337.026825,389.328064 336.268097,389.717377 335.143860,390.111511 
	C333.998779,390.745117 333.219177,391.373901 332.439545,392.002655 
	C333.055145,392.613312 333.670746,393.223969 334.291229,393.850708 
	C334.296143,393.866760 334.309479,393.824554 334.235840,394.127380 
	C334.134918,394.636230 334.107635,394.842224 334.080383,395.048218 
	C333.089935,395.629944 332.129456,396.644867 331.103607,396.715912 
	C327.344635,396.976227 323.563934,396.922577 319.358276,396.969269 
	C318.925110,396.951385 318.851288,396.884918 318.865601,396.446106 
	C318.520264,391.565002 318.107391,387.126251 317.812836,382.679657 
	C317.308533,375.067230 317.339630,375.065155 324.844574,374.508789 
z"/>
<path fill="#11B6E1" opacity="1.000000" stroke="none" 
	d="
M273.342010,406.000793 
	C271.920990,405.338989 270.829163,404.671387 269.368042,404.002167 
	C268.689026,403.524475 268.379303,403.048431 268.290955,402.033081 
	C272.355499,400.642090 276.198639,399.790405 280.470398,398.948242 
	C281.471588,398.938293 282.044189,398.918793 282.699219,398.863281 
	C282.781677,398.827301 282.882019,398.976685 282.912781,399.232361 
	C282.962372,399.658691 282.981171,399.829346 283.006165,400.255676 
	C283.019806,400.681702 283.027222,400.852051 283.034607,401.022400 
	C280.368164,402.422058 277.701721,403.821747 275.011414,405.591064 
	C274.548767,405.972137 274.109955,405.983551 273.342010,406.000793 
z"/>
<path fill="#11B6E1" opacity="1.000000" stroke="none" 
	d="
M316.034149,400.032715 
	C313.144470,402.654236 310.765472,402.592651 309.050812,398.372559 
	C308.988953,398.029724 309.044342,397.971558 309.420532,398.112793 
	C311.849976,398.461121 313.903259,398.668213 315.971985,399.164917 
	C316.003021,399.647247 316.018585,399.839996 316.034149,400.032715 
z"/>
<path fill="#17ABD3" opacity="1.000000" stroke="none" 
	d="
M283.458160,401.020325 
	C283.027222,400.852051 283.019806,400.681702 283.174438,400.196716 
	C283.558990,399.790161 283.758148,399.661957 284.330017,399.459045 
	C286.457001,398.919983 288.187958,398.419312 289.918945,397.918640 
	C289.698181,398.748108 289.477386,399.577515 289.114624,400.701965 
	C287.275665,401.004089 285.578674,401.011169 283.458160,401.020325 
z"/>
<path fill="#26A5CE" opacity="1.000000" stroke="none" 
	d="
M339.977692,398.014832 
	C338.373749,397.268799 336.842926,396.438721 335.208862,395.246033 
	C335.105560,394.883423 335.218964,394.758881 335.543610,394.822632 
	C337.310760,395.844818 338.753265,396.803223 340.195770,397.761627 
	C340.195770,397.761627 340.050842,397.930817 339.977692,398.014832 
z"/>
<path fill="#15B1E0" opacity="1.000000" stroke="none" 
	d="
M316.363037,399.974731 
	C316.018585,399.839996 316.003021,399.647247 316.202271,398.979919 
	C317.228485,397.965179 318.039886,397.425049 318.851288,396.884918 
	C318.851288,396.884918 318.925110,396.951385 318.959625,396.986908 
	C318.226715,397.987183 317.459290,398.951965 316.363037,399.974731 
z"/>
<path fill="#15B1E0" opacity="1.000000" stroke="none" 
	d="
M345.121338,400.258636 
	C345.292175,400.165131 345.558716,400.336182 345.921417,400.772308 
	C345.750580,400.866150 345.483734,400.694733 345.121338,400.258636 
z"/>
<path fill="#26A5CE" opacity="1.000000" stroke="none" 
	d="
M334.308105,395.037231 
	C334.107635,394.842224 334.134918,394.636230 334.455688,394.203369 
	C335.013275,394.172729 335.169861,394.433533 335.218964,394.758881 
	C335.218964,394.758881 335.105560,394.883423 335.048523,394.945618 
	C334.991455,395.007843 334.535828,395.026306 334.308105,395.037231 
z"/>
<path fill="#0CACD8" opacity="1.000000" stroke="none" 
	d="
M212.355972,371.412964 
	C212.007935,373.787872 211.479126,376.371063 210.576523,378.816223 
	C209.158447,382.657806 208.129242,386.197632 210.825729,390.134949 
	C211.796173,391.551941 211.013138,394.169769 211.021774,396.622864 
	C208.454590,400.901337 204.439072,399.825439 200.522705,399.698669 
	C202.496643,394.198456 204.738480,389.001556 206.980316,383.804688 
	C208.656082,379.722900 210.331848,375.641113 212.355972,371.412964 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M239.824692,412.756104 
	C236.587646,413.186157 233.182861,413.406189 229.347778,413.466431 
	C230.369949,412.878235 231.822433,412.449738 233.636185,412.007019 
	C235.690048,410.120026 234.364899,405.388641 238.529617,406.289764 
	C238.943588,408.587158 239.300262,410.566620 239.824692,412.756104 
z"/>
<path fill="#208DB1" opacity="1.000000" stroke="none" 
	d="
M206.745804,384.069275 
	C204.738480,389.001556 202.496643,394.198456 200.082092,399.694824 
	C199.084900,400.162445 198.260422,400.330536 197.237946,400.322815 
	C200.197067,394.875946 203.354187,389.604919 206.745804,384.069275 
z"/>
<path fill="#0FA6D3" opacity="1.000000" stroke="none" 
	d="
M244.957138,400.970276 
	C243.674423,401.493134 242.393326,402.456512 241.108719,402.461212 
	C236.050461,402.479675 230.991104,402.196716 225.465088,402.039429 
	C224.155090,401.841125 223.312119,401.629028 222.722488,401.195740 
	C230.302917,400.973114 237.630035,400.971710 244.957138,400.970276 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M225.771698,407.173859 
	C227.640839,407.714783 229.287170,408.460785 230.932556,409.588928 
	C229.111404,410.136047 227.291214,410.301056 225.195557,409.987976 
	C224.920105,409.509918 224.868286,409.034943 224.868286,409.034943 
	C225.095154,408.482941 225.322021,407.930939 225.771698,407.173859 
z"/>
<path fill="#4BAAC9" opacity="1.000000" stroke="none" 
	d="
M244.970261,400.978149 
	C237.630035,400.971710 230.302917,400.973114 222.513626,400.939941 
	C222.051437,400.905365 222.013580,400.456299 222.087631,400.145935 
	C222.161682,399.835571 222.337753,399.662506 222.774750,399.751404 
	C230.464096,399.828400 237.716461,399.816498 244.975983,400.095215 
	C244.982437,400.579590 244.981735,400.773376 244.981018,400.967194 
	C244.981018,400.967194 244.983383,400.985992 244.970261,400.978149 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M211.675140,406.989624 
	C211.590790,408.216064 211.089783,409.427246 210.308792,410.833435 
	C209.078033,410.233032 208.127274,409.437592 207.176514,408.642212 
	C208.537155,408.086243 209.897812,407.530304 211.675140,406.989624 
z"/>
<path fill="#4BAAC9" opacity="1.000000" stroke="none" 
	d="
M220.131393,395.432129 
	C220.154953,392.958771 220.188522,390.837280 220.572662,388.524292 
	C221.394730,392.109344 221.866241,395.885925 222.337753,399.662506 
	C222.337753,399.662506 222.161682,399.835571 222.072617,399.921082 
	C221.369507,398.599060 220.755463,397.191528 220.131393,395.432129 
z"/>
<path fill="#1A9EBF" opacity="1.000000" stroke="none" 
	d="
M225.958405,413.027954 
	C225.284851,413.353943 224.484695,413.455475 223.297913,413.421387 
	C223.884796,413.125061 224.858307,412.964294 225.958405,413.027954 
z"/>
<path fill="#17ABD3" opacity="1.000000" stroke="none" 
	d="
M185.142517,401.256165 
	C185.845490,401.172394 186.687927,401.345978 187.760986,401.749756 
	C187.088394,401.824493 186.185196,401.669037 185.142517,401.256165 
z"/>
<path fill="#208DB1" opacity="1.000000" stroke="none" 
	d="
M194.210037,400.256866 
	C194.149612,400.172821 194.513397,400.154175 194.668091,400.248749 
	C194.822800,400.343323 194.539108,400.451294 194.539108,400.451294 
	C194.539108,400.451294 194.270462,400.340912 194.210037,400.256866 
z"/>
<path fill="#1DB4DC" opacity="1.000000" stroke="none" 
	d="
M224.758743,409.140076 
	C224.868286,409.034943 224.920105,409.509918 224.931030,409.748230 
	C224.620956,409.810455 224.523376,409.563324 224.758743,409.140076 
z"/>
<path fill="#EDFAF5" opacity="1.000000" stroke="none" 
	d="
M289.945862,397.937622 
	C288.187958,398.419312 286.457001,398.919983 284.091797,399.278381 
	C283.265717,399.083008 283.073883,399.029846 282.882019,398.976685 
	C282.882019,398.976685 282.781677,398.827301 282.535126,398.541992 
	C277.322327,393.474609 272.356079,388.692566 267.437836,383.956757 
	C266.887909,388.918671 266.333282,393.923126 265.837646,398.905640 
	C265.896667,398.883728 265.836243,398.773254 265.478699,398.810120 
	C263.743988,399.269867 262.366882,399.692810 260.744324,400.033142 
	C260.326874,399.965790 260.154877,399.981049 259.982910,399.996277 
	C259.618286,399.677399 259.253662,399.358521 258.581543,398.543945 
	C257.750732,387.063721 257.227356,376.079163 256.704010,365.094635 
	C259.187195,365.648102 261.670410,366.201569 264.598755,367.228394 
	C269.532867,371.510681 274.021820,375.319580 279.905975,380.312317 
	C280.115845,374.307892 280.276642,369.707977 280.437408,365.108063 
	C280.437408,365.108063 280.786591,365.050415 281.293304,364.904968 
	C282.922394,364.676727 284.044739,364.593964 285.167114,364.511169 
	C286.193390,364.650818 287.219666,364.790497 288.721375,365.370270 
	C289.437073,376.537506 289.677307,387.264648 289.917542,397.991760 
	C289.917542,397.991760 289.972748,397.956604 289.945862,397.937622 
z"/>
<path fill="#10B2DD" opacity="1.000000" stroke="none" 
	d="
M256.305664,365.205627 
	C257.227356,376.079163 257.750732,387.063721 258.352875,398.460999 
	C255.199158,398.577576 251.961380,396.074860 248.363800,398.693054 
	C247.983826,398.314240 247.976410,398.139313 248.024429,397.618591 
	C247.669312,395.522675 247.258743,393.772522 246.905670,392.009583 
	C246.963181,391.996735 246.986603,392.112213 246.852051,391.883789 
	C246.438232,391.443970 246.158966,391.232605 245.933884,391.059692 
	C245.988068,391.098145 245.908264,391.204376 245.885864,390.785797 
	C245.549957,387.574738 245.236465,384.782257 244.931091,381.611023 
	C244.983261,378.855499 245.027283,376.478729 245.266083,373.768005 
	C245.591293,371.361450 245.721741,369.288879 245.852173,367.216309 
	C246.932190,368.720978 248.012192,370.225616 249.521057,372.327759 
	C250.120361,372.327759 251.707901,372.327759 253.001968,372.327759 
	C254.011871,369.890717 254.959610,367.603668 256.305664,365.205627 
z"/>
<path fill="#17ABD3" opacity="1.000000" stroke="none" 
	d="
M280.041809,398.938721 
	C276.198639,399.790405 272.355499,400.642090 268.253296,401.742615 
	C267.441071,401.789886 266.887817,401.588379 266.143646,400.798279 
	C265.913910,399.730896 265.875092,399.252075 265.836243,398.773254 
	C265.836243,398.773254 265.896667,398.883728 266.186829,398.755951 
	C267.644440,395.754181 268.811920,392.880188 270.617584,388.435150 
	C274.311218,392.551788 277.176514,395.745239 280.041809,398.938721 
z"/>
<path fill="#0FA6D3" opacity="1.000000" stroke="none" 
	d="
M247.968979,397.964386 
	C247.976410,398.139313 247.983826,398.314240 248.002167,398.751801 
	C247.215118,399.650238 246.417160,400.286041 245.300110,400.944519 
	C244.981735,400.773376 244.982437,400.579590 245.171310,399.911743 
	C245.895355,398.957672 246.431229,398.477661 247.217651,397.991638 
	C247.635132,397.978516 247.802063,397.971436 247.968979,397.964386 
z"/>
<path fill="#249EC0" opacity="1.000000" stroke="none" 
	d="
M265.478699,398.810120 
	C265.875092,399.252075 265.913910,399.730896 265.951050,400.568268 
	C264.898651,400.949707 263.847900,400.972626 262.202637,400.886902 
	C261.351715,400.604370 261.145599,400.383514 260.989777,400.115723 
	C262.366882,399.692810 263.743988,399.269867 265.478699,398.810120 
z"/>
<path fill="#17ABD3" opacity="1.000000" stroke="none" 
	d="
M260.744324,400.033142 
	C261.145599,400.383514 261.351715,400.604370 261.807373,400.889313 
	C261.366486,400.846954 260.726379,400.693542 260.034607,400.268188 
	C260.154877,399.981049 260.326874,399.965790 260.744324,400.033142 
z"/>
<path fill="#E9F9F5" opacity="1.000000" stroke="none" 
	d="
M335.543610,394.822632 
	C335.169861,394.433533 335.013275,394.172729 334.529358,393.900513 
	C334.309479,393.824554 334.296143,393.866760 334.239136,393.504822 
	C334.624542,392.130829 335.066956,391.118744 335.509338,390.106689 
	C336.268097,389.717377 337.026825,389.328064 338.310791,388.551514 
	C340.195526,388.464294 341.542145,388.861511 342.917267,389.044403 
	C345.944489,389.446991 348.986053,389.741364 352.021790,390.079926 
	C352.206299,389.268372 352.390808,388.456848 352.575317,387.645325 
	C348.075531,386.064728 343.575714,384.484161 339.075928,382.903564 
	C339.075928,382.903564 339.008057,382.927460 338.957764,382.646973 
	C338.287109,381.878174 337.666718,381.389862 337.046326,380.901520 
	C337.046326,380.901520 337.015900,380.971558 336.965363,380.679138 
	C336.627380,380.077820 336.339935,379.768890 336.052460,379.459991 
	C335.819275,376.948395 335.586060,374.436829 335.408722,371.523743 
	C338.440369,368.907227 341.416138,366.692200 344.703125,364.322937 
	C347.706116,364.154724 350.397949,364.140717 353.849823,364.242554 
	C356.395935,364.962097 358.182007,365.565796 359.968048,366.169464 
	C359.968048,366.169464 360.021179,366.059875 360.017639,366.314270 
	C360.341278,366.739716 360.668488,366.910797 360.995697,367.081879 
	C360.995697,367.081879 360.932220,367.026459 360.901733,367.055939 
	C361.196167,368.129120 361.521088,369.172852 361.853027,370.551758 
	C361.248657,371.342957 360.637268,371.798981 359.901367,372.513733 
	C357.711029,372.919006 355.559692,373.499573 353.598846,373.113220 
	C350.425751,372.488098 347.324402,370.827118 344.206970,374.827667 
	C346.223633,375.566681 347.829010,376.191833 349.459229,376.743652 
	C351.809448,377.539154 354.279999,378.058838 356.519104,379.088043 
	C363.287567,382.199097 364.356476,386.969421 359.931396,394.456116 
	C354.070770,399.086670 347.736359,399.673035 340.602692,397.839233 
	C338.753265,396.803223 337.310760,395.844818 335.543610,394.822632 
z"/>
<path fill="#17ABD3" opacity="1.000000" stroke="none" 
	d="
M359.822388,365.853851 
	C358.182007,365.565796 356.395935,364.962097 354.297577,364.199646 
	C355.882416,364.540009 357.779602,365.039124 359.822388,365.853851 
z"/>
<path fill="#17ABD3" opacity="1.000000" stroke="none" 
	d="
M360.847778,366.871399 
	C360.668488,366.910797 360.341278,366.739716 359.962891,366.287537 
	C360.174438,366.224579 360.437164,366.442749 360.847778,366.871399 
z"/>
<path fill="#14232A" opacity="1.000000" stroke="none" 
	d="
M205.673370,124.983887 
	C205.001358,124.182076 204.653366,123.359123 204.191772,122.192383 
	C204.021851,121.138397 203.965530,120.428207 203.933929,119.357773 
	C205.824020,118.136803 207.689392,117.276062 209.844238,116.227951 
	C210.142242,116.781456 210.150757,117.522339 210.291382,118.610046 
	C210.372635,119.705620 210.321732,120.454391 210.202942,121.574677 
	C208.755844,122.951714 207.376617,123.957230 205.673370,124.983887 
z"/>
<path fill="#249EC0" opacity="1.000000" stroke="none" 
	d="
M188.330353,125.073883 
	C188.747330,126.924721 188.768814,128.777756 188.621155,131.078522 
	C186.979706,129.621796 185.507385,127.717354 183.971405,125.462738 
	C185.250107,125.100395 186.592484,125.088234 188.330353,125.073883 
z"/>
<path fill="#53D1F0" opacity="1.000000" stroke="none" 
	d="
M199.289993,121.638748 
	C199.183243,121.830650 198.869370,121.847153 198.081467,121.857040 
	C198.099258,121.721390 198.591064,121.592369 199.289993,121.638748 
z"/>
<path fill="#34D2F5" opacity="1.000000" stroke="none" 
	d="
M227.937119,165.085739 
	C226.274414,162.485626 224.611710,159.885513 222.557602,156.722992 
	C221.646667,154.491486 221.127121,152.822388 220.633423,150.750244 
	C220.439880,146.566879 220.220505,142.786560 220.101074,138.696014 
	C219.893326,137.891891 219.585648,137.398010 219.232391,136.495239 
	C219.121155,134.722366 219.055527,133.358414 219.112503,131.728577 
	C219.336151,131.295700 219.437195,131.128708 219.810791,130.772980 
	C221.470367,130.677231 223.107193,130.323120 224.203705,130.935898 
	C231.156143,134.821136 231.116730,134.891647 235.926010,129.979065 
	C235.999329,130.247513 236.116119,130.495483 236.459198,130.818115 
	C236.644196,131.706238 236.646332,132.499207 236.507019,133.740540 
	C236.246429,134.348862 236.138947,134.516296 236.040649,135.122681 
	C236.410492,138.031006 236.782822,140.507874 237.155151,142.984756 
	C237.341629,144.390839 237.528122,145.796921 237.507294,147.954529 
	C236.653259,152.266571 236.739212,155.499924 241.020309,157.290619 
	C243.152466,165.375305 241.398071,167.120743 233.583939,165.396744 
	C231.767242,164.995926 229.822784,165.174026 227.937119,165.085739 
z"/>
<path fill="#41E1FC" opacity="1.000000" stroke="none" 
	d="
M228.127136,165.384155 
	C229.822784,165.174026 231.767242,164.995926 233.583939,165.396744 
	C241.398071,167.120743 243.152466,165.375305 241.472977,157.293640 
	C244.823868,156.959534 247.788269,157.023422 250.752686,157.087311 
	C250.801727,157.287842 250.850769,157.488373 250.863174,158.365570 
	C251.727432,162.708008 249.492996,164.632248 246.730865,166.112930 
	C244.842575,167.125168 242.802689,167.854584 240.830566,168.710449 
	C236.659424,167.701141 232.488281,166.691849 228.127136,165.384155 
z"/>
<path fill="#88C6D8" opacity="1.000000" stroke="none" 
	d="
M250.791626,156.699829 
	C247.788269,157.023422 244.823868,156.959534 241.406784,156.892624 
	C236.739212,155.499924 236.653259,152.266571 237.472900,148.381485 
	C238.380798,152.526978 240.366074,154.925903 245.357712,153.164825 
	C248.152451,152.178864 250.213989,153.162781 250.791626,156.699829 
z"/>
<path fill="#1589AF" opacity="1.000000" stroke="none" 
	d="
M241.080734,168.992874 
	C242.802689,167.854584 244.842575,167.125168 246.730865,166.112930 
	C249.492996,164.632248 251.727432,162.708008 250.890717,158.674011 
	C251.268188,158.500580 251.581497,158.695358 252.164459,158.958649 
	C253.100296,163.021530 253.458237,166.950317 249.766418,170.192123 
	C248.301208,169.869675 247.176895,169.195099 245.994629,169.070511 
	C244.464249,168.909256 242.887619,169.186737 241.080734,168.992874 
z"/>
<path fill="#88C6D8" opacity="1.000000" stroke="none" 
	d="
M237.269073,142.606384 
	C236.782822,140.507874 236.410492,138.031006 236.220642,135.238525 
	C236.403137,134.922913 236.820816,135.005341 236.820816,135.005341 
	C237.008209,137.412903 237.195602,139.820465 237.269073,142.606384 
z"/>
<path fill="#1999B8" opacity="1.000000" stroke="none" 
	d="
M236.858246,134.760254 
	C236.820816,135.005341 236.403137,134.922913 236.223129,134.807068 
	C236.138947,134.516296 236.246429,134.348862 236.507233,134.137024 
	C236.648865,134.085144 236.895676,134.515182 236.858246,134.760254 
z"/>
<path fill="#142122" opacity="1.000000" stroke="none" 
	d="
M248.346893,129.879013 
	C248.243805,129.944412 248.259796,129.516464 248.375916,129.337463 
	C248.645355,129.390594 248.631332,129.608978 248.346893,129.879013 
z"/>
<path fill="#41E1FC" opacity="1.000000" stroke="none" 
	d="
M257.974518,125.037285 
	C257.775696,124.999130 257.576904,124.960968 257.086700,124.829941 
	C258.128601,122.305550 259.457275,119.871452 260.796295,117.443092 
	C263.059692,113.338318 266.977875,115.562073 270.932800,115.146851 
	C273.074127,115.621292 274.450409,115.949089 275.982361,116.585876 
	C279.745941,117.305740 283.353821,117.716606 286.992126,118.533119 
	C287.276184,121.413658 286.567627,123.058685 283.747009,123.412331 
	C280.482147,123.821701 277.249969,124.491943 273.922852,124.696609 
	C268.998840,120.112900 265.556549,120.004578 260.591156,123.819710 
	C259.846649,124.391747 258.852936,124.639519 257.974518,125.037285 
z"/>
<path fill="#B3E8ED" opacity="1.000000" stroke="none" 
	d="
M275.826660,116.276886 
	C274.450409,115.949089 273.074127,115.621292 271.382507,115.136261 
	C271.682220,113.688934 272.297363,112.398827 273.262665,111.107948 
	C277.601410,111.591331 281.589966,112.075485 286.003998,112.594879 
	C286.886688,113.540497 287.343933,114.450874 287.907806,115.648895 
	C288.014404,115.936539 287.795715,115.840271 287.247955,115.896591 
	C283.075684,116.060905 279.451172,116.168900 275.826660,116.276886 
z"/>
<path fill="#B3E8ED" opacity="1.000000" stroke="none" 
	d="
M273.505249,103.049362 
	C273.876770,103.500816 273.885406,103.968498 273.744629,104.764313 
	C273.040466,104.687943 272.485748,104.283432 271.827759,103.640137 
	C272.197113,103.289436 272.669739,103.177521 273.505249,103.049362 
z"/>
<path fill="#24CFF1" opacity="1.000000" stroke="none" 
	d="
M235.978439,129.612213 
	C231.116730,134.891647 231.156143,134.821136 224.203705,130.935898 
	C223.107193,130.323120 221.470367,130.677231 219.467117,130.737549 
	C218.464188,131.071609 218.077499,131.252396 217.668488,131.429596 
	C217.646149,131.426025 217.601074,131.430649 217.601074,131.430649 
	C217.169632,131.289963 216.738190,131.149261 215.941544,130.875153 
	C214.719681,130.598907 213.863022,130.456070 213.006363,130.313232 
	C212.602173,129.946747 212.197983,129.580276 211.548111,128.840942 
	C211.199310,128.311554 211.096191,128.155029 210.993088,127.998520 
	C210.972763,126.935135 210.952438,125.871742 211.008850,124.414413 
	C215.525742,127.290672 218.097275,126.847137 221.285095,122.261284 
	C223.609299,118.917778 230.832611,118.072174 235.029938,120.779968 
	C236.690765,121.851395 238.146362,123.240913 239.867889,124.789139 
	C238.703857,126.476913 237.367355,127.861130 235.978439,129.612213 
z"/>
<path fill="#34D2F5" opacity="1.000000" stroke="none" 
	d="
M257.993744,125.397644 
	C258.852936,124.639519 259.846649,124.391747 260.591156,123.819710 
	C265.556549,120.004578 268.998840,120.112900 273.709198,124.958191 
	C273.679596,127.048424 273.782898,128.524750 273.916412,130.382736 
	C273.951447,131.529358 273.956299,132.294296 273.794861,133.111481 
	C273.462372,133.322556 273.308289,133.492401 273.129333,134.119232 
	C272.748657,137.696991 272.404999,140.828766 271.727051,144.035492 
	C267.281067,147.411469 263.169373,150.712479 259.057678,154.013489 
	C258.980194,149.750977 259.015198,145.822693 264.465057,144.320206 
	C266.846191,143.663757 266.497192,137.723770 264.416107,134.933243 
	C262.187622,131.945007 260.139709,128.822128 257.993744,125.397644 
z"/>
<path fill="#29D5FA" opacity="1.000000" stroke="none" 
	d="
M259.050476,154.346619 
	C263.169373,150.712479 267.281067,147.411469 271.735016,144.457993 
	C272.737488,147.190720 273.397675,149.575882 274.057892,151.961060 
	C275.434540,153.018784 276.811218,154.076523 278.541840,155.219345 
	C278.275757,158.665482 277.655670,162.026520 277.044006,165.852142 
	C270.398285,168.056747 265.183533,166.067963 261.673920,160.445145 
	C260.882263,159.176819 260.107574,157.897873 259.183472,156.318726 
	C259.042358,155.569000 259.042847,155.124390 259.050476,154.346619 
z"/>
<path fill="#112026" opacity="1.000000" stroke="none" 
	d="
M256.089294,149.565033 
	C255.755615,147.604904 255.492783,145.208984 255.384872,142.481537 
	C255.746567,144.476440 255.953354,146.802841 256.089294,149.565033 
z"/>
<path fill="#88EEF8" opacity="1.000000" stroke="none" 
	d="
M306.121521,92.289413 
	C306.309875,92.137672 306.588470,92.275261 306.952942,92.707771 
	C306.765625,92.852791 306.489960,92.711479 306.121521,92.289413 
z"/>
<path fill="#111A1E" opacity="1.000000" stroke="none" 
	d="
M273.961182,133.059250 
	C273.956299,132.294296 273.951447,131.529358 274.361023,130.278412 
	C278.741364,129.467712 282.707245,129.143005 286.673157,128.818298 
	C286.892365,128.855820 287.111542,128.893356 287.661255,128.975403 
	C289.888519,136.739243 289.703705,144.461868 288.019653,152.612183 
	C284.347778,148.936874 280.127533,152.898026 275.815216,151.590668 
	C274.925018,148.286179 274.226746,145.630524 274.028473,142.938065 
	C273.809326,139.961884 274.048157,136.951965 274.147766,133.719452 
	C274.207977,133.482147 273.961182,133.059250 273.961182,133.059250 
z"/>
<path fill="#1799C0" opacity="1.000000" stroke="none" 
	d="
M286.820374,128.441360 
	C282.707245,129.143005 278.741364,129.467712 274.330841,129.896744 
	C273.782898,128.524750 273.679596,127.048424 273.789917,125.310516 
	C277.249969,124.491943 280.482147,123.821701 283.747009,123.412331 
	C286.567627,123.058685 287.276184,121.413658 287.197205,118.464462 
	C287.371826,117.990150 287.802185,117.970879 287.802185,117.970879 
	C287.524017,121.335396 287.245819,124.699913 286.820374,128.441360 
z"/>
<path fill="#71F0F7" opacity="1.000000" stroke="none" 
	d="
M287.820740,117.621140 
	C287.802185,117.970879 287.371826,117.990150 287.166779,118.058807 
	C283.353821,117.716606 279.745941,117.305740 275.982361,116.585884 
	C279.451172,116.168900 283.075684,116.060905 287.134033,115.932480 
	C287.658325,116.365166 287.748810,116.818275 287.820740,117.621140 
z"/>
<path fill="#57E3FB" opacity="1.000000" stroke="none" 
	d="
M312.381866,125.671181 
	C311.597931,127.531456 310.962128,129.077774 309.750458,132.024597 
	C310.089111,128.602554 310.288239,126.590729 310.740967,124.297287 
	C311.285034,123.998871 311.575653,123.986214 312.083008,124.004036 
	C312.376404,124.472656 312.453217,124.914940 312.381866,125.671181 
z"/>
<path fill="#249EC0" opacity="1.000000" stroke="none" 
	d="
M165.821503,380.349884 
	C166.281021,381.562714 167.514709,383.363434 167.214355,384.855347 
	C166.472900,388.538422 168.068787,390.662720 170.923798,392.774109 
	C167.445572,392.758423 163.882721,392.534058 160.376938,391.960785 
	C158.037399,391.578217 153.747467,390.423523 153.793518,390.083740 
	C154.357559,385.921967 157.848145,388.084656 160.038651,387.924103 
	C166.366592,387.460266 166.721481,387.261108 165.821503,380.349884 
z"/>
<path fill="#0A97C2" opacity="1.000000" stroke="none" 
	d="
M169.494720,374.478790 
	C168.637497,375.907562 167.355621,377.476959 165.872559,379.391968 
	C162.570145,379.615723 159.457703,379.609100 156.373749,379.309509 
	C155.294922,379.204712 154.286179,378.378693 153.245422,377.882050 
	C154.294083,377.176025 155.277451,376.005005 156.403030,375.846985 
	C160.600098,375.257782 164.843826,375.000946 169.494720,374.478790 
z"/>
<path fill="#172D33" opacity="1.000000" stroke="none" 
	d="
M315.032959,257.010986 
	C317.312836,256.281433 320.109314,254.380753 321.748535,255.103790 
	C324.061371,256.123901 325.517853,259.085510 326.852051,261.442780 
	C328.862335,262.726501 330.872589,264.010223 332.675964,265.882080 
	C330.151306,270.545807 329.472595,274.697021 331.950378,279.323425 
	C332.702362,283.066895 333.395844,286.477997 334.192383,290.126923 
	C334.580658,290.551422 334.865906,290.738037 335.101776,290.916504 
	C335.052368,290.908295 335.080750,290.812256 335.202240,291.057892 
	C335.632477,291.485840 335.941254,291.668152 336.194366,291.859039 
	C336.138733,291.867615 336.146881,291.755341 336.241547,292.014954 
	C336.637451,292.471924 336.938690,292.669281 337.179871,292.871887 
	C337.119812,292.877197 337.131958,292.757172 337.235535,293.015137 
	C337.652496,293.453217 337.965912,293.633301 338.202393,293.813843 
	C338.125427,293.814270 338.152985,293.662872 338.291809,293.957947 
	C339.024933,294.421295 339.619202,294.589569 340.161346,294.782837 
	C340.109192,294.807861 340.086914,294.694305 340.325623,295.009766 
	C344.066132,295.879028 347.570190,296.877991 351.069214,296.860687 
	C354.532227,296.843597 357.061890,297.423370 358.218994,301.246002 
	C358.418884,301.798859 358.386017,302.113953 358.150604,302.855560 
	C358.048828,303.884827 358.012299,304.473206 357.741974,305.285706 
	C356.678314,305.698303 355.848480,305.886810 354.691833,305.819458 
	C349.051025,305.456909 343.737030,305.350189 338.076752,305.219482 
	C333.490631,302.779114 329.250854,300.362671 324.950806,297.610535 
	C323.879395,296.586517 322.868256,295.898224 321.851471,295.187317 
	C321.845856,295.164703 321.799347,295.162201 321.802643,294.838501 
	C321.374054,294.109589 320.942169,293.704407 320.570282,293.299255 
	C320.630280,293.299316 320.570160,293.403168 320.651367,293.035583 
	C319.808929,291.443146 318.885223,290.218292 317.753052,288.801392 
	C317.261993,287.924164 316.979431,287.238983 316.765747,286.114990 
	C316.234070,276.121124 315.633514,266.566071 315.032959,257.010986 
z"/>
<path fill="#F57F06" opacity="1.000000" stroke="none" 
	d="
M367.985443,242.032669 
	C367.803619,241.949173 367.621796,241.865677 367.202789,241.326447 
	C366.679688,240.523499 366.393768,240.176285 366.000122,239.510818 
	C351.941467,226.431641 330.857819,227.588455 319.332764,241.623718 
	C318.813599,242.255951 317.065399,241.878998 315.915771,241.642166 
	C315.945831,240.870789 315.949799,240.429367 316.242676,239.829102 
	C318.031158,237.803360 319.530701,235.936447 321.341736,234.009338 
	C322.447174,233.305069 323.241119,232.661011 324.349640,231.870850 
	C328.109283,230.084579 331.554352,228.444366 335.283783,226.814240 
	C336.047913,226.555450 336.527679,226.286575 337.474548,226.024292 
	C343.915314,226.377319 349.889008,226.723755 356.097534,227.307190 
	C357.554565,228.039932 358.776825,228.535660 360.271759,229.202026 
	C361.677643,229.948105 362.810822,230.523544 364.126831,231.381714 
	C369.806915,237.404465 370.214050,237.409637 377.096466,232.090012 
	C377.155334,234.825638 377.019287,237.295670 376.883240,239.765717 
	C376.883240,239.765717 376.912933,239.899933 376.511902,239.964233 
	C373.402405,240.696579 370.693939,241.364624 367.985443,242.032669 
z"/>
<path fill="#E67A14" opacity="1.000000" stroke="none" 
	d="
M315.889679,241.972107 
	C317.065399,241.878998 318.813599,242.255951 319.332764,241.623718 
	C330.857819,227.588455 351.941467,226.431641 365.791138,239.543503 
	C365.689941,239.894440 365.269775,239.926361 365.269775,239.926361 
	C360.937134,237.883392 356.604523,235.840408 351.657959,233.598877 
	C350.359039,233.255051 349.674042,233.109787 348.989044,232.964508 
	C348.663727,232.872269 348.338409,232.780014 347.421936,232.559998 
	C343.757874,232.583206 340.684998,232.734177 337.612122,232.885162 
	C330.131226,234.442505 324.230042,238.509720 318.986694,244.476959 
	C318.429840,244.943939 318.317596,245.262344 318.205322,245.580734 
	C318.057037,246.003601 317.908752,246.426468 317.271851,247.118622 
	C316.571136,247.979584 316.359070,248.571289 316.147034,249.162994 
	C316.147034,249.162994 316.139221,249.071304 316.011475,249.128967 
	C315.883759,249.186646 315.647675,249.566544 315.647675,249.566544 
	C315.647675,249.566544 315.889008,249.971817 315.889008,249.971817 
	C315.746674,250.543549 315.604340,251.115280 315.247498,252.072906 
	C315.033020,252.458786 314.969879,252.486145 314.969879,252.486145 
	C314.830292,253.069382 314.690704,253.652618 314.183105,254.662811 
	C313.733093,255.170380 313.572235,255.265121 313.583679,255.329041 
	C313.620087,255.532516 313.720642,255.724503 313.806580,256.323639 
	C313.557556,257.480042 313.298401,258.233704 312.914185,259.044830 
	C312.713501,259.191772 312.558655,259.304443 312.576813,259.366455 
	C312.636414,259.570374 312.758820,259.755981 312.893982,260.386536 
	C312.687134,262.551025 312.443451,264.277588 311.978027,266.139252 
	C310.028473,265.575378 312.583435,271.070984 309.160828,267.568268 
	C309.356781,262.740204 309.621979,258.364777 309.943909,253.667709 
	C310.039459,252.912659 310.078247,252.479233 310.352173,251.797424 
	C311.052460,250.016785 311.517578,248.484512 312.271240,246.793411 
	C313.669739,245.080429 314.779694,243.526276 315.889679,241.972107 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M377.164948,239.663559 
	C377.019287,237.295670 377.155334,234.825638 377.173340,231.792435 
	C377.670624,230.150345 378.285919,229.071411 379.077484,227.746429 
	C379.788483,226.658112 380.323242,225.815842 380.970032,224.639160 
	C382.507538,221.967636 383.933044,219.630524 385.358582,217.293411 
	C385.688354,217.145828 386.018127,216.998230 386.896912,216.833496 
	C388.628754,217.753220 389.811584,218.690079 390.994446,219.626938 
	C391.012543,219.846191 391.030609,220.065430 390.749573,220.682007 
	C390.290405,221.381409 390.130341,221.683472 389.970276,221.985535 
	C389.636078,222.379456 389.301880,222.773392 388.626404,223.607605 
	C387.747650,224.980881 387.210114,225.913895 386.672546,226.846893 
	C386.374084,226.958572 386.160248,227.160065 385.719635,227.833038 
	C385.133575,228.757843 384.859009,229.300980 384.584442,229.844147 
	C383.741302,230.980682 382.898163,232.117218 381.727692,233.735535 
	C380.903870,235.124619 380.407379,236.031906 379.910889,236.939209 
	C379.089478,237.813278 378.268066,238.687332 377.164948,239.663559 
z"/>
<path fill="#D6D5C5" opacity="1.000000" stroke="none" 
	d="
M395.554047,229.378265 
	C395.266907,232.008591 394.698578,234.640366 394.020752,237.928497 
	C393.850677,238.982758 393.790100,239.380661 393.729553,239.778549 
	C393.729523,239.778549 393.823090,239.835999 393.582886,239.913574 
	C393.292969,240.321014 393.243286,240.650879 393.193634,240.980759 
	C393.150696,241.152466 393.107788,241.324158 393.012817,242.080399 
	C392.964752,243.113770 392.968781,243.562592 392.972839,244.011414 
	C392.626678,244.945053 392.512451,246.102783 391.878815,246.758728 
	C390.975098,247.694260 389.690613,248.261948 388.568756,248.986740 
	C388.082214,247.802917 387.009766,246.502594 387.217712,245.456879 
	C387.872498,242.164108 389.008026,238.966949 390.232422,235.109924 
	C391.721832,232.806992 392.936279,231.126938 394.150726,229.446869 
	C394.524750,229.424484 394.898804,229.402100 395.554047,229.378265 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M312.199768,266.004150 
	C312.443451,264.277588 312.687134,262.551025 313.034851,260.259247 
	C313.274628,259.434662 313.241425,259.199127 313.039246,258.987366 
	C313.298401,258.233704 313.557556,257.480042 314.013855,256.224030 
	C314.210999,255.721725 314.609528,255.491959 314.609528,255.491959 
	C314.682953,255.744247 314.756378,255.996536 314.931396,256.629913 
	C315.633514,266.566071 316.234070,276.121124 316.470428,285.858521 
	C314.804077,279.361908 313.501923,272.683044 312.199768,266.004150 
z"/>
<path fill="#2F3234" opacity="1.000000" stroke="none" 
	d="
M394.092560,229.093735 
	C392.936279,231.126938 391.721832,232.806992 390.311554,234.732330 
	C387.086121,234.254150 386.008759,233.062683 388.736755,230.451401 
	C389.883972,229.353287 390.584442,227.788452 391.741699,226.212311 
	C392.675049,226.906082 393.354736,227.823349 394.092560,229.093735 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M354.006104,259.062378 
	C352.287292,259.513641 350.558624,259.620483 348.408691,259.613800 
	C348.737640,258.079376 349.487885,256.658478 350.238098,255.237579 
	C351.490814,256.397705 352.743561,257.557800 354.006104,259.062378 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M331.891907,278.991028 
	C329.472595,274.697021 330.151306,270.545807 332.732849,266.223969 
	C333.202393,265.982819 333.408142,265.987885 333.922485,265.998779 
	C333.728973,267.655579 333.226837,269.306488 332.528503,271.613434 
	C332.334503,272.922577 332.336761,273.575684 332.194275,274.592896 
	C331.997009,276.301666 331.944458,277.646332 331.891907,278.991028 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M345.001953,259.301086 
	C344.270386,259.834930 343.535553,260.008301 342.421875,260.145020 
	C341.682678,258.349243 341.322357,256.590118 340.962006,254.831009 
	C341.557251,254.595245 342.152527,254.359482 342.747772,254.123718 
	C343.498077,255.729370 344.248383,257.334991 345.001953,259.301086 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M366.725586,255.302246 
	C368.392303,254.737488 369.913788,254.493637 371.435272,254.249771 
	C371.462860,255.021408 371.490479,255.793045 371.518097,256.564667 
	C369.872192,256.250824 368.226257,255.936981 366.725586,255.302246 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M367.985046,242.296967 
	C370.693939,241.364624 373.402405,240.696579 376.443207,239.961990 
	C376.549194,240.369781 376.322876,240.844101 375.761108,241.680878 
	C375.243317,242.342163 375.060944,242.641022 374.878571,242.939880 
	C374.878571,242.939880 374.961060,242.927750 374.649414,242.902176 
	C373.862885,243.222549 373.387970,243.568497 372.913055,243.914429 
	C372.913055,243.914413 372.998871,243.945801 372.647430,243.900299 
	C371.891754,243.996902 371.487549,244.139008 371.083313,244.281097 
	C370.653412,244.167862 370.223511,244.054611 369.335144,243.736542 
	C368.650513,243.280380 368.369873,243.132294 368.034760,243.087463 
	C368.018066,242.912064 368.001343,242.736649 367.985046,242.296967 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M363.448914,241.444244 
	C363.655304,242.322479 363.567596,243.059967 363.479919,243.797455 
	C362.789734,243.501389 362.099548,243.205322 361.409363,242.909256 
	C361.991180,242.374008 362.572998,241.838760 363.448914,241.444244 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M367.451965,248.533203 
	C366.632660,248.034027 366.087006,247.430603 365.541351,246.827179 
	C366.090179,246.644821 366.639008,246.462433 367.187836,246.280060 
	C367.367096,246.996368 367.546387,247.712677 367.451965,248.533203 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M338.108734,232.916840 
	C340.684998,232.734177 343.757874,232.583206 347.193787,232.681290 
	C344.572998,232.936401 341.589172,232.942459 338.108734,232.916840 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M380.247040,236.947250 
	C380.407379,236.031906 380.903870,235.124619 381.655029,234.033600 
	C381.467529,234.885040 381.025360,235.920166 380.247040,236.947250 
z"/>
<path fill="#2F3234" opacity="1.000000" stroke="none" 
	d="
M387.052856,226.819122 
	C387.210114,225.913895 387.747650,224.980881 388.554260,223.859253 
	C388.359955,224.710876 387.896545,225.751099 387.052856,226.819122 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M378.750519,258.777893 
	C377.607300,259.073792 376.214294,259.128876 374.414856,259.078430 
	C375.505859,258.827637 377.003296,258.682343 378.750519,258.777893 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M370.509155,246.957047 
	C370.830505,246.829193 371.148346,246.901779 371.466217,246.974365 
	C371.146027,247.035400 370.825867,247.096436 370.509155,246.957047 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M365.350281,240.160400 
	C365.269775,239.926361 365.689941,239.894440 365.898895,239.861755 
	C366.393768,240.176285 366.679688,240.523499 367.019714,241.088654 
	C366.526123,241.002548 365.978455,240.698502 365.350281,240.160400 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M393.170349,243.862396 
	C392.968781,243.562592 392.964752,243.113770 393.060547,242.341553 
	C393.229523,242.583221 393.298706,243.148315 393.170349,243.862396 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M371.268341,244.429230 
	C371.487549,244.139008 371.891754,243.996902 372.619629,243.864044 
	C372.446655,244.107971 371.950012,244.342682 371.268341,244.429230 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M373.218750,243.898941 
	C373.387970,243.568497 373.862885,243.222549 374.638672,242.861877 
	C374.467865,243.192596 373.996185,243.538025 373.218750,243.898941 
z"/>
<path fill="#2F3234" opacity="1.000000" stroke="none" 
	d="
M384.887573,229.724731 
	C384.859009,229.300980 385.133575,228.757843 385.671143,228.052917 
	C385.686310,228.462524 385.438538,229.033936 384.887573,229.724731 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M349.246277,233.211182 
	C349.674042,233.109787 350.359039,233.255051 351.311768,233.640717 
	C350.887482,233.740021 350.195465,233.598938 349.246277,233.211182 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M394.050262,239.840210 
	C393.790100,239.380661 393.850677,238.982758 394.031250,238.306946 
	C394.422577,238.498550 394.693939,238.968063 394.991455,239.716003 
	C394.802032,239.963577 394.586487,239.932724 394.050262,239.840210 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M314.484619,255.357056 
	C314.609528,255.491959 314.210999,255.721725 314.003723,255.821320 
	C313.720642,255.724503 313.620087,255.532516 313.583679,255.329041 
	C313.572235,255.265121 313.733093,255.170380 313.941528,255.044083 
	C314.067993,254.998413 314.359741,255.222137 314.484619,255.357056 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M316.404419,248.986298 
	C316.359070,248.571289 316.571136,247.979584 317.078552,247.244934 
	C317.136536,247.671173 316.899170,248.240387 316.404419,248.986298 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M375.109741,242.813507 
	C375.060944,242.641022 375.243317,242.342163 375.672607,241.935120 
	C375.726624,242.113678 375.533783,242.400406 375.109741,242.813507 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M368.172028,243.281372 
	C368.369873,243.132294 368.650513,243.280380 369.000854,243.740082 
	C368.808350,243.867783 368.536469,243.710052 368.172028,243.281372 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M318.441071,245.405106 
	C318.317596,245.262344 318.429840,244.943939 318.719055,244.654739 
	C318.822937,244.865768 318.749878,245.047623 318.441071,245.405106 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M316.037720,249.784668 
	C315.889008,249.971817 315.647675,249.566544 315.647675,249.566544 
	C315.647675,249.566544 315.883759,249.186646 316.050385,249.153564 
	C316.217010,249.120468 316.186401,249.597534 316.037720,249.784668 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M315.046570,252.526550 
	C314.969879,252.486145 315.033020,252.458786 315.016541,252.452682 
	C315.000092,252.446579 315.123260,252.566940 315.046570,252.526550 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M393.357300,240.806824 
	C393.243286,240.650879 393.292969,240.321014 393.570129,239.860168 
	C393.799896,240.059372 393.707672,240.360596 393.357300,240.806824 
z"/>
<path fill="#2F3234" opacity="1.000000" stroke="none" 
	d="
M390.226501,221.994049 
	C390.130341,221.683472 390.290405,221.381409 390.708893,221.009491 
	C390.976624,221.117126 390.985901,221.294632 390.995331,221.738983 
	C390.824554,222.004776 390.653656,222.003677 390.226501,221.994049 
z"/>
<path fill="#FAB204" opacity="1.000000" stroke="none" 
	d="
M390.138031,321.925751 
	C389.797455,325.683746 389.456909,329.441742 389.043823,333.569214 
	C382.756134,333.395416 376.546722,332.761200 370.322113,332.367371 
	C368.575012,332.256836 366.716400,332.636475 365.022400,333.159058 
	C350.466248,337.649475 335.830688,339.085052 321.168915,333.928192 
	C317.166901,332.520599 313.486267,330.199341 309.227600,327.931335 
	C308.584473,327.316528 308.323700,327.138184 308.014771,327.030273 
	C304.844177,324.128479 301.673584,321.226715 298.075531,317.863586 
	C296.090210,314.937958 294.532379,312.473755 293.365387,310.002991 
	C302.019348,308.448944 301.921051,308.510468 307.030731,316.677185 
	C307.798157,317.903809 309.915039,318.682220 311.519135,318.921906 
	C319.155060,320.062897 327.024292,319.830627 333.620331,325.161133 
	C336.975647,327.872681 340.937866,328.618469 344.961639,325.216858 
	C346.579590,323.849060 349.325134,323.183594 351.525177,323.251068 
	C358.919098,323.477844 366.305023,324.646423 373.681305,324.543610 
	C379.188202,324.466949 385.633331,328.668274 390.138031,321.925751 
z"/>
<path fill="#DF760F" opacity="1.000000" stroke="none" 
	d="
M292.974518,310.009521 
	C294.532379,312.473755 296.090210,314.937958 297.779022,317.706482 
	C297.118927,318.256073 296.327850,318.501343 294.959778,318.710022 
	C289.924469,311.760895 285.466187,304.848389 280.993622,297.594482 
	C280.497070,295.107300 279.742493,292.862488 282.945221,292.384949 
	C286.333435,298.495270 289.653992,304.252380 292.974518,310.009521 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M281.007935,297.935883 
	C285.466187,304.848389 289.924469,311.760895 294.672272,318.839539 
	C295.559753,320.240479 296.157684,321.475281 296.666382,323.062469 
	C295.782532,322.886108 294.987885,322.357361 293.938110,321.686096 
	C293.683014,321.543579 293.545837,321.525696 293.441589,321.265930 
	C292.328949,319.908447 291.320526,318.810730 290.312103,317.713043 
	C290.312103,317.713043 290.388824,317.659210 290.389160,317.612061 
	C287.033875,311.276428 283.678223,304.988007 280.491272,298.329102 
	C280.659943,297.958618 281.007935,297.935883 281.007935,297.935883 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M327.906494,342.182465 
	C327.465424,342.350922 326.956573,342.304230 326.263428,342.065674 
	C326.665649,341.904999 327.252197,341.936127 327.906494,342.182465 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M302.955139,329.611084 
	C302.402191,329.466095 301.780426,329.017548 301.059387,328.289703 
	C301.602203,328.442749 302.244293,328.875092 302.955139,329.611084 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M299.943298,326.729370 
	C299.410126,326.536469 298.830627,326.032471 298.162872,325.247589 
	C298.682037,325.450562 299.289520,325.934418 299.943298,326.729370 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M310.508423,335.815308 
	C310.212585,335.777588 310.135498,335.372528 310.127625,335.166626 
	C310.631775,335.172699 311.143799,335.384705 311.812012,335.833435 
	C311.580200,335.997833 311.192200,335.925446 310.508423,335.815308 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M297.978516,324.625580 
	C297.591125,324.566772 297.147980,324.207306 296.637634,323.562256 
	C297.021179,323.626068 297.471985,323.975494 297.978516,324.625580 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M323.055511,340.964233 
	C323.057709,340.921509 323.272156,340.587067 323.272156,340.587067 
	C323.272156,340.587067 323.295593,340.852142 323.200195,340.894775 
	C323.104797,340.937439 323.053314,341.006958 323.055511,340.964233 
z"/>
<path fill="#DF760F" opacity="1.000000" stroke="none" 
	d="
M308.146118,327.230164 
	C308.323700,327.138184 308.584473,327.316528 308.930145,327.764648 
	C308.753571,327.856293 308.491669,327.678284 308.146118,327.230164 
z"/>
<path fill="#FBCB06" opacity="1.000000" stroke="none" 
	d="
M332.338989,274.228821 
	C332.336761,273.575684 332.334503,272.922577 332.506836,271.984253 
	C346.845917,263.816010 362.446381,267.600189 377.409332,266.244843 
	C382.267853,265.804810 386.604950,267.240631 389.780884,271.879211 
	C389.566010,273.448608 389.363556,274.373108 389.161072,275.297577 
	C389.421265,284.215363 389.681458,293.133118 389.647736,302.578979 
	C389.527832,303.743591 389.701874,304.380127 389.875916,305.016663 
	C389.939789,308.043335 390.003662,311.070038 389.638733,314.547913 
	C384.572449,314.177216 379.752960,315.502106 375.332031,311.950867 
	C371.819580,309.129364 367.013885,307.917847 362.639282,305.830597 
	C362.274261,305.311432 362.052307,304.953186 362.056152,304.287933 
	C362.491943,302.998169 362.702026,302.015442 363.154724,300.814575 
	C362.957794,299.072296 362.518311,297.548157 362.036865,295.624512 
	C361.941833,290.316437 359.423157,288.382965 354.635925,288.964264 
	C352.994354,289.163635 351.307495,288.956879 349.644379,289.018341 
	C342.968689,289.265045 338.071716,286.774628 335.533142,280.264557 
	C334.711609,278.157806 333.417114,276.235504 332.338989,274.228821 
z"/>
<path fill="#F8BF06" opacity="1.000000" stroke="none" 
	d="
M389.513031,275.212189 
	C389.363556,274.373108 389.566010,273.448608 389.915009,272.197906 
	C390.602722,271.972351 391.143890,272.072998 391.850006,272.395203 
	C391.298279,273.453400 390.581635,274.290100 389.513031,275.212189 
z"/>
<path fill="#F8BF06" opacity="1.000000" stroke="none" 
	d="
M362.782349,305.991547 
	C367.013885,307.917847 371.819580,309.129364 375.332031,311.950867 
	C379.752960,315.502106 384.572449,314.177216 389.619080,315.001740 
	C390.057831,317.025482 390.087341,319.046600 390.127441,321.496765 
	C385.633331,328.668274 379.188202,324.466949 373.681305,324.543610 
	C366.305023,324.646423 358.919098,323.477844 351.525177,323.251068 
	C349.325134,323.183594 346.579590,323.849060 344.961639,325.216858 
	C340.937866,328.618469 336.975647,327.872681 333.620331,325.161133 
	C327.024292,319.830627 319.155060,320.062897 311.519135,318.921906 
	C309.915039,318.682220 307.798157,317.903809 307.030731,316.677185 
	C301.921051,308.510468 302.019348,308.448944 293.365387,310.002991 
	C289.653992,304.252380 286.333435,298.495270 282.919434,291.997192 
	C281.557404,287.846283 280.288818,284.436310 279.009705,281.013428 
	C278.999146,281.000519 278.973145,280.979523 278.923798,280.672913 
	C278.252747,279.589813 277.631073,278.813293 277.016907,277.673950 
	C277.846069,274.815063 278.667725,272.319000 279.489380,269.822937 
	C279.619446,268.793243 279.749542,267.763519 280.155090,266.083557 
	C280.465759,263.933441 280.500946,262.433533 280.536133,260.933655 
	C281.809113,255.435669 283.082092,249.937668 284.869568,244.414551 
	C285.955994,245.995239 286.527893,247.601044 287.036682,249.505066 
	C287.069427,251.172516 287.165344,252.541733 286.936798,254.081696 
	C277.277679,266.922028 282.371033,280.162689 292.481750,288.685730 
	C294.599854,290.471222 296.541229,292.475800 298.725281,294.171814 
	C303.895844,298.186890 308.600311,303.783447 317.004608,300.017944 
	C315.308899,294.838165 313.673615,289.842987 312.378906,284.911316 
	C314.472626,286.948944 316.225739,288.923035 318.233124,291.071960 
	C319.181641,291.965546 319.875916,292.684357 320.570160,293.403168 
	C320.570160,293.403168 320.630280,293.299316 320.649719,293.653748 
	C320.866455,294.454315 321.063782,294.900452 321.039001,295.463562 
	C320.349274,300.550812 322.041656,304.203308 327.268982,306.046448 
	C327.821228,306.466156 328.170135,306.662781 328.505615,307.018463 
	C328.492188,307.177521 328.749664,307.363251 328.749664,307.363251 
	C328.749664,307.363251 329.030334,307.214111 329.299500,307.426666 
	C329.774200,307.739258 329.979767,307.839325 330.242737,308.129150 
	C330.300171,308.318878 330.636108,308.527435 330.636108,308.527435 
	C330.636108,308.527435 331.024048,308.450287 331.300751,308.606384 
	C331.779022,308.827423 331.980621,308.892365 332.248962,309.128143 
	C332.315674,309.299011 332.647003,309.453644 332.647003,309.453644 
	C332.647003,309.453644 332.983063,309.309204 333.319397,309.471191 
	C334.137939,309.753326 334.620148,309.873474 335.193726,310.160461 
	C335.285126,310.327301 335.633759,310.476959 335.633759,310.476959 
	C335.633759,310.476959 335.989899,310.345825 336.357452,310.523987 
	C343.258636,312.979523 349.977539,313.611115 356.511292,311.334503 
	C358.928711,310.492188 360.710602,307.825928 362.782349,305.991547 
z"/>
<path fill="#F8BF06" opacity="1.000000" stroke="none" 
	d="
M389.918396,304.678558 
	C389.701874,304.380127 389.527832,303.743591 389.611359,303.045166 
	C389.899567,303.435699 389.930206,303.888062 389.918396,304.678558 
z"/>
<path fill="#54C8E0" opacity="1.000000" stroke="none" 
	d="
M274.860199,357.673737 
	C273.150787,358.184204 271.354126,358.356842 269.297485,358.316986 
	C270.660278,355.691406 272.528717,355.111572 274.860199,357.673737 
z"/>
<path fill="#26BAE4" opacity="1.000000" stroke="none" 
	d="
M227.681030,358.935974 
	C227.449020,359.091522 226.875534,359.225922 226.136932,359.158447 
	C226.427719,358.942688 226.883621,358.928772 227.681030,358.935974 
z"/>
<path fill="#D99E1F" opacity="1.000000" stroke="none" 
	d="
M279.020264,281.026367 
	C280.288818,284.436310 281.557404,287.846283 282.851746,291.643982 
	C279.742493,292.862488 280.497070,295.107300 280.993622,297.594482 
	C281.007935,297.935883 280.659943,297.958618 280.486237,297.972992 
	C278.774597,292.739532 277.236664,287.491699 275.528320,281.518372 
	C275.363251,279.240356 275.368591,277.687836 275.593628,276.263336 
	C276.211975,276.939819 276.610687,277.488312 277.009369,278.036804 
	C277.631073,278.813293 278.252747,279.589813 278.814667,280.724304 
	C278.754883,281.082336 278.757385,281.401520 278.757385,281.401520 
	C278.757385,281.401520 279.020264,281.026367 279.020264,281.026367 
z"/>
<path fill="#091318" opacity="1.000000" stroke="none" 
	d="
M266.288452,282.322632 
	C266.578857,280.855194 266.801788,279.775909 267.263733,278.632324 
	C267.669800,280.045807 267.836792,281.523499 267.999207,283.334351 
	C267.996826,284.111633 267.999023,284.555817 268.001221,285.000000 
	C268.000793,297.689240 268.000336,310.378448 268.003174,323.537048 
	C268.006470,324.006439 267.553192,324.021942 267.327271,324.042145 
	C266.968201,323.053986 266.835083,322.045654 266.825714,320.327332 
	C266.755463,318.758270 266.561401,317.899231 266.367340,317.040161 
	C266.319061,316.574799 266.270813,316.109467 266.498596,314.958984 
	C266.716888,311.692383 266.659088,309.110870 266.601288,306.529358 
	C266.558838,302.589386 266.516388,298.649414 266.665039,293.970825 
	C266.916962,291.477600 266.977814,289.722961 267.037842,287.660248 
	C266.817993,286.586426 266.599030,285.820709 266.380066,285.054962 
	C266.327026,284.273560 266.273987,283.492157 266.288452,282.322632 
z"/>
<path fill="#091318" opacity="1.000000" stroke="none" 
	d="
M275.373932,276.135345 
	C275.368591,277.687836 275.363251,279.240356 275.319641,281.175751 
	C275.027893,281.899139 274.774445,282.239624 274.268951,282.405212 
	C274.005249,281.488586 273.993591,280.746918 273.986938,279.577271 
	C273.633789,273.091705 273.275604,267.034088 272.917419,260.976501 
	C273.085297,260.974823 273.253204,260.973114 273.726410,261.455872 
	C274.479126,266.671997 274.926544,271.403687 275.373932,276.135345 
z"/>
<path fill="#182A30" opacity="1.000000" stroke="none" 
	d="
M273.981934,280.005219 
	C273.993591,280.746918 274.005249,281.488586 274.018005,282.600403 
	C273.885895,284.087769 273.752625,285.205017 273.568329,286.750397 
	C271.729034,286.127136 270.217010,285.614777 268.353119,285.051208 
	C267.999023,284.555817 267.996826,284.111633 268.359375,283.322906 
	C270.476746,281.987305 272.229340,280.996277 273.981934,280.005219 
z"/>
<path fill="#182A30" opacity="1.000000" stroke="none" 
	d="
M273.161835,292.005768 
	C273.877655,291.330902 274.594330,291.002563 275.310974,290.674225 
	C275.313446,291.557526 275.315918,292.440857 275.318390,293.324158 
	C274.599823,293.000183 273.881226,292.676239 273.161835,292.005768 
z"/>
<path fill="#182A30" opacity="1.000000" stroke="none" 
	d="
M276.442993,309.216766 
	C275.935547,308.995850 275.602386,308.606049 275.341614,308.172729 
	C275.316528,308.131073 275.741577,307.818512 275.957703,307.631714 
	C276.184265,308.099731 276.410797,308.567780 276.442993,309.216766 
z"/>
<path fill="#182A30" opacity="1.000000" stroke="none" 
	d="
M271.542358,309.903412 
	C271.986511,309.526520 272.409607,309.389069 272.832703,309.251648 
	C272.916595,309.523743 273.000488,309.795868 273.084381,310.067963 
	C272.563354,310.092926 272.042297,310.117889 271.542358,309.903412 
z"/>
<path fill="#16292F" opacity="1.000000" stroke="none" 
	d="
M290.261078,318.071655 
	C291.320526,318.810730 292.328949,319.908447 293.397156,321.318909 
	C292.374634,320.564514 291.292328,319.497375 290.261078,318.071655 
z"/>
<path fill="#FCFDF8" opacity="1.000000" stroke="none" 
	d="
M241.184113,198.702576 
	C241.111710,197.044769 241.058212,195.442444 241.087158,193.445084 
	C242.292130,192.842514 243.414642,192.634964 245.157501,192.288818 
	C246.416428,193.592941 247.055008,195.035645 247.693604,196.478348 
	C247.552963,196.822327 247.412323,197.166321 246.752991,197.789307 
	C244.591965,198.316055 242.949615,198.563828 241.307281,198.811615 
	C241.307281,198.811615 241.203033,198.758041 241.184113,198.702576 
z"/>
<path fill="#EFE9A2" opacity="1.000000" stroke="none" 
	d="
M253.238525,182.917572 
	C254.856232,183.122894 256.603088,183.645508 258.702576,184.692383 
	C259.442688,185.443161 259.830139,185.669708 260.217590,185.896240 
	C260.217590,185.896240 260.078308,185.824478 260.135101,186.087570 
	C260.564697,186.497894 260.937500,186.645111 261.310303,186.792328 
	C261.310303,186.792328 261.349213,186.734268 261.381104,186.721634 
	C260.488495,188.328461 261.827026,192.317581 257.275085,190.143311 
	C259.118683,185.838318 254.858719,185.296921 253.238525,182.917572 
z"/>
<path fill="#EFE9A2" opacity="1.000000" stroke="none" 
	d="
M247.953613,196.230988 
	C247.055008,195.035645 246.416428,193.592941 245.395630,192.035599 
	C246.497208,190.730637 247.981018,189.540298 249.698456,188.517944 
	C249.359268,191.118484 248.786453,193.551056 247.953613,196.230988 
z"/>
<path fill="#EFE9A2" opacity="1.000000" stroke="none" 
	d="
M241.391373,199.122131 
	C242.949615,198.563828 244.591965,198.316055 246.594391,198.019470 
	C245.715622,200.659653 244.028290,201.667908 241.391373,199.122131 
z"/>
<path fill="#EFE9A2" opacity="1.000000" stroke="none" 
	d="
M253.243408,189.006256 
	C253.685257,188.814728 254.333450,188.847244 255.086029,189.085754 
	C254.610199,189.271286 254.029984,189.250809 253.243408,189.006256 
z"/>
<path fill="#E28614" opacity="1.000000" stroke="none" 
	d="
M270.355469,200.082550 
	C270.621277,201.039139 270.746613,202.476929 270.671051,204.230911 
	C270.385132,203.219330 270.300079,201.891541 270.355469,200.082550 
z"/>
<path fill="#14232A" opacity="1.000000" stroke="none" 
	d="
M219.277954,136.904144 
	C219.585648,137.398010 219.893326,137.891891 219.835358,139.011246 
	C219.325607,143.358292 219.168121,147.079636 219.103745,150.802582 
	C219.101883,150.910782 220.084183,151.036011 220.607590,151.153290 
	C221.127121,152.822388 221.646667,154.491486 222.300079,156.513733 
	C222.366959,158.289444 222.299988,159.711975 222.223663,161.565643 
	C218.910019,159.917145 214.885468,158.454407 212.459824,155.622238 
	C208.160843,150.602737 211.706116,138.239212 217.914795,136.982086 
	C218.846069,136.938644 219.062012,136.921402 219.277954,136.904144 
z"/>
<path fill="#14232A" opacity="1.000000" stroke="none" 
	d="
M210.823792,168.958008 
	C211.049698,168.691681 211.275604,168.425354 211.733582,168.034622 
	C211.587845,169.200684 211.210022,170.491150 210.499725,171.894043 
	C210.138397,171.582047 210.109558,171.157639 210.314117,170.318359 
	C210.639618,169.588333 210.731705,169.273178 210.823792,168.958008 
z"/>
<path fill="#091318" opacity="1.000000" stroke="none" 
	d="
M219.232376,136.495239 
	C219.062012,136.921402 218.846069,136.938644 218.305893,136.980698 
	C217.915909,135.455841 217.850174,133.906204 217.692749,131.893600 
	C217.601074,131.430649 217.646149,131.426025 217.977539,131.608963 
	C218.535919,131.859421 218.762909,131.926926 218.989883,131.994446 
	C219.055527,133.358414 219.121155,134.722366 219.232376,136.495239 
z"/>
<path fill="#14232A" opacity="1.000000" stroke="none" 
	d="
M213.150360,130.643127 
	C213.863022,130.456070 214.719681,130.598907 215.749878,131.031433 
	C215.047058,131.205078 214.170715,131.089050 213.150360,130.643127 
z"/>
<path fill="#14232A" opacity="1.000000" stroke="none" 
	d="
M206.024689,131.914001 
	C205.549332,131.582520 205.290100,131.088486 205.092667,130.293442 
	C205.516586,130.578766 205.878708,131.165115 206.024689,131.914001 
z"/>
<path fill="#14232A" opacity="1.000000" stroke="none" 
	d="
M223.188446,166.757904 
	C223.827423,167.341782 224.443085,168.304108 225.115448,169.608704 
	C224.503143,169.012756 223.834152,168.074554 223.188446,166.757904 
z"/>
<path fill="#14232A" opacity="1.000000" stroke="none" 
	d="
M207.386063,149.795105 
	C207.142273,149.534729 207.059113,149.101517 207.043488,148.341110 
	C207.256241,148.550034 207.401459,149.086166 207.386063,149.795105 
z"/>
<path fill="#14232A" opacity="1.000000" stroke="none" 
	d="
M211.381561,164.842560 
	C211.211273,164.815308 211.134247,164.664459 211.057709,164.259537 
	C211.397705,164.141983 211.536591,164.379807 211.381561,164.842560 
z"/>
<path fill="#14232A" opacity="1.000000" stroke="none" 
	d="
M210.860931,128.127045 
	C211.096191,128.155029 211.199310,128.311554 211.287308,128.749023 
	C211.091049,128.771835 210.909912,128.513702 210.860931,128.127045 
z"/>
<path fill="#14232A" opacity="1.000000" stroke="none" 
	d="
M208.694733,176.734772 
	C208.639389,176.489380 208.929123,176.085800 209.566528,175.522217 
	C209.622726,175.766998 209.331268,176.171783 208.694733,176.734772 
z"/>
<path fill="#EFE9A2" opacity="1.000000" stroke="none" 
	d="
M250.158096,182.449158 
	C250.085052,182.318588 250.529190,182.182739 250.744171,182.268799 
	C250.959152,182.354874 250.652832,182.649521 250.652832,182.649521 
	C250.652832,182.649521 250.231140,182.579712 250.158096,182.449158 
z"/>
<path fill="#2F3234" opacity="1.000000" stroke="none" 
	d="
M266.410767,306.910248 
	C266.659088,309.110870 266.716888,311.692383 266.562012,314.612762 
	C266.306274,312.398132 266.263275,309.844635 266.410767,306.910248 
z"/>
<path fill="#2F3234" opacity="1.000000" stroke="none" 
	d="
M267.038696,287.968323 
	C266.977814,289.722961 266.916962,291.477600 266.703064,293.562500 
	C266.384521,293.185181 266.219055,292.477570 265.982025,291.410278 
	C265.992889,290.285797 266.075317,289.521027 266.414337,288.369263 
	C266.670898,287.982269 267.038666,287.968323 267.038696,287.968323 
z"/>
<path fill="#2F3234" opacity="1.000000" stroke="none" 
	d="
M266.245758,317.360931 
	C266.561401,317.899231 266.755463,318.758270 266.851776,319.947388 
	C266.544098,319.412201 266.334137,318.546967 266.245758,317.360931 
z"/>
<path fill="#16292F" opacity="1.000000" stroke="none" 
	d="
M273.986938,279.577271 
	C272.229340,280.996277 270.476746,281.987305 268.363953,282.989807 
	C267.836792,281.523499 267.669800,280.045807 267.263733,278.289093 
	C267.015228,271.635559 267.005829,265.261017 267.007507,258.438568 
	C268.767731,258.936493 270.516907,259.882324 272.591736,260.902344 
	C273.275604,267.034088 273.633789,273.091705 273.986938,279.577271 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M275.593628,276.263336 
	C274.926544,271.403687 274.479126,266.671997 273.976501,261.485596 
	C273.988281,260.597168 274.055267,260.163452 274.381592,259.366699 
	C274.640930,259.003632 275.090912,259.063324 275.090912,259.063324 
	C275.373901,261.477142 275.392548,263.965088 276.040741,266.276489 
	C276.418945,267.625092 277.804138,268.691315 279.112823,269.853760 
	C278.667725,272.319000 277.846069,274.815063 277.016907,277.673950 
	C276.610687,277.488312 276.211975,276.939819 275.593628,276.263336 
z"/>
<path fill="#091318" opacity="1.000000" stroke="none" 
	d="
M275.136658,258.718323 
	C275.090912,259.063324 274.640930,259.003632 274.417023,259.038116 
	C274.283569,258.417633 274.373993,257.762665 274.744263,257.055542 
	C275.076843,257.460052 275.129608,257.916687 275.136658,258.718323 
z"/>
<path fill="#1C2C2E" opacity="1.000000" stroke="none" 
	d="
M287.219482,223.610947 
	C287.291931,224.101425 287.016785,224.692856 286.478882,225.484314 
	C286.434753,225.026855 286.653320,224.369385 287.219482,223.610947 
z"/>
<path fill="#1C2C2E" opacity="1.000000" stroke="none" 
	d="
M290.049500,219.143005 
	C290.116486,219.568939 289.906158,220.177521 289.400024,220.961670 
	C289.326874,220.533371 289.549530,219.929504 290.049500,219.143005 
z"/>
<path fill="#1C2C2E" opacity="1.000000" stroke="none" 
	d="
M288.924866,221.217468 
	C289.019897,221.402969 288.887451,221.737122 288.514740,222.208130 
	C288.415436,222.018692 288.556366,221.692398 288.924866,221.217468 
z"/>
<path fill="#1C2C2E" opacity="1.000000" stroke="none" 
	d="
M288.110718,222.356354 
	C288.392365,222.599045 288.293365,222.907959 287.835175,223.300659 
	C287.706512,223.110565 287.791199,222.789932 288.110718,222.356354 
z"/>
<path fill="#EDECCB" opacity="1.000000" stroke="none" 
	d="
M263.261658,248.546783 
	C261.080109,249.696823 258.847382,250.677963 256.263184,251.640930 
	C258.038513,250.418060 260.165314,249.213364 262.659546,248.042526 
	C263.026978,248.076401 263.210480,248.377884 263.261658,248.546783 
z"/>
<path fill="#EDECCB" opacity="1.000000" stroke="none" 
	d="
M246.306885,256.750122 
	C245.842957,256.900940 245.657532,256.864258 245.198639,256.752502 
	C246.924500,255.566086 248.923813,254.454697 251.302094,253.465485 
	C249.982498,254.579315 248.283920,255.570969 246.306885,256.750122 
z"/>
<path fill="#EDECCB" opacity="1.000000" stroke="none" 
	d="
M250.701782,245.528656 
	C251.663589,244.615570 252.994431,243.828064 254.665924,243.051605 
	C253.694656,243.926514 252.382736,244.790375 250.701782,245.528656 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M267.037811,287.660248 
	C267.038666,287.968323 266.670898,287.982269 266.487549,287.991333 
	C266.255219,287.256561 266.206238,286.512695 266.268677,285.411896 
	C266.599030,285.820709 266.817993,286.586426 267.037811,287.660248 
z"/>
<path fill="#E7B62A" opacity="1.000000" stroke="none" 
	d="
M245.575928,304.730347 
	C245.147293,305.168030 244.380234,305.576141 243.265076,305.955811 
	C243.690475,305.518524 244.463989,305.109650 245.575928,304.730347 
z"/>
<path fill="#E7B62A" opacity="1.000000" stroke="none" 
	d="
M240.582214,306.737671 
	C240.156586,307.175812 239.394592,307.590881 238.285355,307.977539 
	C238.707367,307.537567 239.476608,307.126068 240.582214,306.737671 
z"/>
<path fill="#E7B62A" opacity="1.000000" stroke="none" 
	d="
M242.666199,305.886902 
	C242.471512,306.223328 241.968155,306.551056 241.148834,306.847290 
	C241.341064,306.503265 241.849289,306.190735 242.666199,305.886902 
z"/>
<path fill="#E7B62A" opacity="1.000000" stroke="none" 
	d="
M247.749146,303.838837 
	C247.545975,304.184296 247.020004,304.526642 246.167587,304.840271 
	C246.369553,304.486267 246.897949,304.161011 247.749146,303.838837 
z"/>
<path fill="#1C2C30" opacity="1.000000" stroke="none" 
	d="
M184.399353,302.125031 
	C177.441528,310.492920 168.141251,309.643219 158.381531,309.150330 
	C157.130234,303.195404 156.578720,297.705261 155.996582,292.218353 
	C155.642960,288.885315 157.233368,287.805267 160.400848,287.960144 
	C164.106583,288.141327 167.827972,288.002533 171.542587,288.002533 
	C171.539734,287.658020 171.536880,287.313477 171.534042,286.968964 
	C165.899475,286.968964 160.264923,286.968964 154.630356,286.968964 
	C162.922806,285.430359 171.249939,285.103027 179.183060,288.274139 
	C184.459671,290.383362 185.762634,294.541565 184.399353,302.125031 
z"/>
<path fill="#091318" opacity="1.000000" stroke="none" 
	d="
M154.478973,287.322662 
	C160.264923,286.968964 165.899475,286.968964 171.534042,286.968964 
	C171.536880,287.313477 171.539734,287.658020 171.542587,288.002533 
	C167.827972,288.002533 164.106583,288.141327 160.400848,287.960144 
	C157.233368,287.805267 155.642960,288.885315 155.996582,292.218353 
	C156.578720,297.705261 157.130234,303.195404 157.941803,309.017456 
	C157.063507,309.478424 155.938095,309.606110 154.468292,309.758362 
	C154.123901,309.782898 154.292542,309.318695 154.512634,308.885559 
	C154.671127,307.690796 154.609543,306.929199 154.547943,306.167572 
	C154.501831,304.998596 154.455704,303.829590 154.651398,301.875641 
	C154.747604,297.124969 154.602020,293.159271 154.456421,289.193573 
	C154.413483,288.687836 154.370544,288.182098 154.478973,287.322662 
z"/>
<path fill="#1C2C30" opacity="1.000000" stroke="none" 
	d="
M154.330322,289.621887 
	C154.602020,293.159271 154.747604,297.124969 154.755066,301.447144 
	C154.479370,297.885803 154.341797,293.968018 154.330322,289.621887 
z"/>
<path fill="#1C2C30" opacity="1.000000" stroke="none" 
	d="
M154.398926,306.487000 
	C154.609543,306.929199 154.671127,307.690796 154.599518,308.654419 
	C154.394180,308.173065 154.322052,307.489777 154.398926,306.487000 
z"/>
<path fill="#18292E" opacity="1.000000" stroke="none" 
	d="
M179.795074,246.034210 
	C179.532806,246.592911 179.270538,247.151611 178.911392,248.173203 
	C173.795883,254.354691 167.007095,254.418686 160.217010,254.423843 
	C158.086014,254.425461 155.954895,254.279587 153.307892,253.645432 
	C152.827026,249.615051 152.855469,246.140381 152.898361,242.665894 
	C153.031113,231.911697 153.033234,231.911743 163.590591,231.800613 
	C175.332611,231.677017 178.791687,234.715378 179.795074,246.034210 
z"/>
<path fill="#C97624" opacity="1.000000" stroke="none" 
	d="
M180.071808,245.822723 
	C178.791687,234.715378 175.332611,231.677017 163.590591,231.800613 
	C153.033234,231.911743 153.031113,231.911697 152.898361,242.665894 
	C152.855469,246.140381 152.827026,249.615051 152.839783,253.557343 
	C152.532455,254.153778 152.177277,254.282501 151.539276,254.430359 
	C151.178955,248.128204 150.841217,241.792618 151.187683,235.494659 
	C151.287521,233.679977 153.339172,230.482239 154.527573,230.467514 
	C160.751266,230.390350 167.065521,230.689163 173.177750,231.797714 
	C175.799240,232.273163 178.008072,235.023895 180.693817,236.924332 
	C180.771652,239.942307 180.560089,242.776764 180.071808,245.822723 
z"/>
<path fill="#E9F9F5" opacity="1.000000" stroke="none" 
	d="
M246.967117,397.997650 
	C246.431229,398.477661 245.895355,398.957672 245.164139,399.621155 
	C237.716461,399.816498 230.464096,399.828400 222.774750,399.751404 
	C221.866241,395.885925 221.394730,392.109344 220.593704,388.072266 
	C220.214371,381.566772 220.164536,375.321777 220.141312,368.620544 
	C220.167923,368.164368 220.101379,368.091309 220.347458,368.035065 
	C220.826935,367.677551 221.060333,367.376282 221.293732,367.075012 
	C229.239456,366.990692 237.185181,366.906372 245.491547,367.019165 
	C245.721741,369.288879 245.591293,371.361450 244.825165,373.794556 
	C241.371765,374.378204 238.517380,374.396118 235.748291,374.891083 
	C233.637390,375.268463 230.218643,373.440155 230.077972,377.469604 
	C230.064529,377.854675 233.978256,379.005798 235.898880,378.707367 
	C240.972137,377.919128 243.620697,379.439545 243.171509,385.192993 
	C239.630737,386.046661 236.285828,386.377899 233.029053,387.104279 
	C231.924362,387.350647 231.042160,388.594635 230.058289,389.382751 
	C231.052261,389.971558 232.023926,391.019623 233.044113,391.069183 
	C237.324265,391.277008 241.618912,391.185974 245.908264,391.204346 
	C245.908264,391.204376 245.988068,391.098145 246.020325,391.324005 
	C246.363937,391.737305 246.675262,391.924774 246.986603,392.112213 
	C246.986603,392.112213 246.963181,391.996735 246.937180,392.438812 
	C246.929825,394.586487 246.948471,396.292053 246.967117,397.997650 
z"/>
<path fill="#E9F9F5" opacity="1.000000" stroke="none" 
	d="
M318.865601,396.446106 
	C318.039886,397.425049 317.228485,397.965179 316.186829,398.690308 
	C313.903259,398.668213 311.849976,398.461121 309.449921,398.085449 
	C308.548584,390.431519 307.994049,382.946198 307.417877,375.168671 
	C304.698944,373.600647 299.686981,376.962128 298.028381,370.958862 
	C297.688232,369.353149 297.445984,368.446777 297.203735,367.540436 
	C297.377441,367.323090 297.438843,367.077637 297.502075,366.419250 
	C297.743927,365.690765 297.871674,365.347107 297.999420,365.003448 
	C297.999420,365.003448 298.000000,365.000000 298.407837,364.958710 
	C300.686890,364.682983 302.558105,364.448547 304.767700,364.303986 
	C306.264648,364.337097 307.423248,364.280365 308.791809,364.359192 
	C309.470215,364.402161 309.938660,364.309601 310.672302,364.301025 
	C311.483765,364.327759 312.030090,364.270538 312.978638,364.390991 
	C317.865509,364.901215 322.350098,365.233795 326.834717,365.566345 
	C326.834717,365.566345 326.792786,365.813599 326.892578,366.334167 
	C326.816895,368.872162 326.641479,370.889618 326.466064,372.907043 
	C325.960999,373.285034 325.455963,373.662994 324.897766,374.274872 
	C317.339630,375.065155 317.308533,375.067230 317.812836,382.679657 
	C318.107391,387.126251 318.520264,391.565002 318.865601,396.446106 
z"/>
<path fill="#10B2DD" opacity="1.000000" stroke="none" 
	d="
M280.022675,365.126526 
	C280.276642,369.707977 280.115845,374.307892 279.905975,380.312317 
	C274.021820,375.319580 269.532867,371.510681 264.911072,367.381561 
	C269.721497,366.422577 274.664703,365.783813 280.022675,365.126526 
z"/>
<path fill="#17ABD3" opacity="1.000000" stroke="none" 
	d="
M290.179749,397.745361 
	C289.677307,387.264648 289.437073,376.537506 289.112244,365.402405 
	C289.652222,365.445221 290.276794,365.895996 290.974823,366.646423 
	C291.046631,373.291992 290.903564,379.643280 291.142212,385.980133 
	C291.202362,387.576782 292.484009,389.127411 293.295074,390.897186 
	C292.440613,392.874329 291.441315,395.186646 290.179749,397.745361 
z"/>
<path fill="#0CACD8" opacity="1.000000" stroke="none" 
	d="
M308.581879,364.223663 
	C307.423248,364.280365 306.264648,364.337097 304.306427,364.217255 
	C301.675201,364.004974 299.843536,363.969269 298.011902,363.933594 
	C302.698822,363.622070 307.382446,363.139984 312.073669,363.048553 
	C316.485748,362.962494 320.905853,363.288696 325.185364,363.690643 
	C320.890961,364.036285 316.733704,364.124786 312.576416,364.213318 
	C312.030090,364.270538 311.483765,364.327759 310.214966,364.256348 
	C309.188934,364.159698 308.885406,364.191681 308.581879,364.223663 
z"/>
<path fill="#2EBCE5" opacity="1.000000" stroke="none" 
	d="
M312.978638,364.390991 
	C316.733704,364.124786 320.890961,364.036285 325.511414,363.942871 
	C326.224487,364.271759 326.474365,364.605530 326.779480,365.252838 
	C322.350098,365.233795 317.865509,364.901215 312.978638,364.390991 
z"/>
<path fill="#10B2DD" opacity="1.000000" stroke="none" 
	d="
M285.412964,364.368164 
	C284.044739,364.593964 282.922394,364.676727 281.377319,364.749207 
	C283.093811,363.944519 285.233063,363.150146 287.372284,362.355774 
	C287.499115,362.757935 287.625946,363.160095 287.752777,363.562256 
	C287.054779,363.783234 286.356781,364.004181 285.412964,364.368164 
z"/>
<path fill="#2EBCE5" opacity="1.000000" stroke="none" 
	d="
M326.814026,372.715485 
	C326.641479,370.889618 326.816895,368.872162 326.985901,366.417969 
	C327.040283,368.162079 327.101135,370.343018 326.814026,372.715485 
z"/>
<path fill="#2EBCE5" opacity="1.000000" stroke="none" 
	d="
M298.001648,364.199982 
	C299.843536,363.969269 301.675201,364.004974 303.968079,364.127411 
	C302.558105,364.448547 300.686890,364.682983 298.409668,364.958405 
	C297.999573,364.821716 297.995483,364.644043 298.001648,364.199982 
z"/>
<path fill="#11B6E1" opacity="1.000000" stroke="none" 
	d="
M221.033035,367.190857 
	C221.060333,367.376282 220.826935,367.677551 220.311768,368.069244 
	C220.277466,367.875336 220.524918,367.591034 221.033035,367.190857 
z"/>
<path fill="#2EBCE5" opacity="1.000000" stroke="none" 
	d="
M297.742096,364.995667 
	C297.871674,365.347107 297.743927,365.690765 297.428284,366.239166 
	C297.184021,366.205719 297.127686,365.967560 297.020691,365.365784 
	C297.141632,364.997375 297.313202,364.992615 297.742096,364.995667 
z"/>
<path fill="#41C6EE" opacity="1.000000" stroke="none" 
	d="
M210.669449,168.956696 
	C210.731705,169.273178 210.639618,169.588333 210.294312,169.999237 
	C210.062271,169.904114 210.083450,169.713226 210.155518,169.238007 
	C210.206390,168.953705 210.515091,168.955399 210.669449,168.956696 
z"/>
<path fill="#FBD106" opacity="1.000000" stroke="none" 
	d="
M385.136047,217.035263 
	C383.933044,219.630524 382.507538,221.967636 380.656830,224.564392 
	C378.610260,223.174881 377.236847,221.070190 375.293579,220.012299 
	C373.558167,219.067566 370.575928,218.302734 369.220612,219.160263 
	C364.910034,221.887665 361.427063,220.616196 357.716125,218.314438 
	C356.806519,217.750198 355.584930,217.300964 354.552795,217.382355 
	C344.336517,218.188110 334.131165,219.131073 323.920135,220.004700 
	C318.495209,220.468826 312.388184,225.102982 313.264954,229.305344 
	C314.464172,235.053314 311.091583,238.306015 308.380646,242.080170 
	C304.875153,246.960526 305.063629,248.523972 310.117065,252.045807 
	C310.078247,252.479233 310.039459,252.912659 309.524597,253.670380 
	C305.971558,254.323364 302.894531,254.652054 299.799744,254.628479 
	C298.187134,252.450821 296.592316,250.625412 294.883759,248.467834 
	C290.379089,246.580933 286.355255,244.713776 286.934784,238.928970 
	C286.934784,238.928970 286.986450,238.986206 287.197998,238.783966 
	C287.581146,237.987656 287.752808,237.393616 287.924469,236.799576 
	C288.120026,236.321335 288.315582,235.843109 288.954681,235.016495 
	C289.600922,234.121323 289.803680,233.574554 290.006409,233.027771 
	C290.006409,233.027771 289.991913,232.998672 290.220184,232.832001 
	C290.602570,232.344498 290.756653,232.023651 290.910767,231.702789 
	C296.058624,225.791763 301.206482,219.880722 306.829590,213.757080 
	C307.595337,213.425934 307.741943,213.209747 307.744659,212.895889 
	C307.952454,212.786133 308.160278,212.676361 308.777405,212.443512 
	C309.186707,212.320419 309.431641,212.142502 309.431641,212.142502 
	C309.431641,212.142502 309.363708,211.846878 309.363708,211.846893 
	C310.015625,211.457596 310.667542,211.068283 311.778595,210.520615 
	C312.519501,210.293121 312.627258,210.117172 312.560974,209.834366 
	C313.160522,209.566879 313.760040,209.299393 314.962341,208.869385 
	C317.027405,207.798828 318.489746,206.890808 319.952087,205.982788 
	C320.405365,205.817581 320.858643,205.652405 321.786011,205.417816 
	C322.558167,205.313187 322.697357,205.145691 322.677673,204.845901 
	C322.894318,204.756592 323.110931,204.667297 323.808594,204.491806 
	C324.595367,204.366592 324.761475,204.193390 324.787903,203.886017 
	C324.966949,203.841705 325.145966,203.797394 325.905151,203.694183 
	C327.668396,203.121277 328.851440,202.607269 330.034485,202.093262 
	C331.367340,201.900726 332.700165,201.708191 334.697510,201.539474 
	C335.891266,201.344162 336.420563,201.125031 336.949829,200.905899 
	C338.310547,200.754669 339.671295,200.603439 341.852844,200.522797 
	C344.177277,200.486008 345.680908,200.378632 347.184540,200.271240 
	C349.190430,200.466812 351.196350,200.662384 353.875549,201.104553 
	C355.421509,201.363159 356.294220,201.375153 357.166901,201.387161 
	C357.893005,201.552917 358.619141,201.718674 359.819214,202.100800 
	C360.293182,202.317169 360.674530,202.436523 360.674530,202.436523 
	C360.674530,202.436523 361.025177,202.244568 361.025177,202.244568 
	C361.475586,202.388718 361.926025,202.532852 362.908630,202.968170 
	C364.006195,203.344238 364.571564,203.429123 365.136902,203.514008 
	C365.313568,203.593552 365.490265,203.673080 366.010376,204.010376 
	C366.353790,204.268143 366.699951,204.427719 366.699951,204.427719 
	C366.699951,204.427719 367.045258,204.265884 367.045258,204.265884 
	C367.266174,204.375702 367.487122,204.485535 368.081024,204.874390 
	C368.453979,205.153397 368.748383,205.284866 368.748383,205.284866 
	C368.748383,205.284866 368.999756,205.082428 368.999756,205.082428 
	C369.224915,205.208191 369.450073,205.333954 370.138306,205.863373 
	C371.102112,206.395462 371.602905,206.523880 372.103668,206.652313 
	C372.103668,206.652313 372.235016,206.769485 372.348877,206.958191 
	C372.462708,207.146881 372.761078,207.310486 372.761078,207.310486 
	C372.761078,207.310486 373.057312,207.142746 373.057312,207.142746 
	C373.258209,207.274796 373.459137,207.406845 374.027771,207.894165 
	C374.395477,208.249420 374.723511,208.474014 374.723511,208.474014 
	C374.723511,208.474014 375.113983,208.398575 375.113983,208.398575 
	C376.075439,209.081558 377.036865,209.764542 378.272827,210.811310 
	C378.547363,211.175079 378.801910,211.369293 378.801910,211.369293 
	C378.801910,211.369293 379.098175,211.246887 379.098175,211.246887 
	C379.918610,212.023987 380.739014,212.801086 381.904541,213.996994 
	C382.436157,214.713821 382.705231,214.860870 383.056854,214.856964 
	C383.675720,215.497025 384.294617,216.137070 385.136047,217.035263 
z"/>
<path fill="#091318" opacity="1.000000" stroke="none" 
	d="
M337.478394,192.999023 
	C339.837677,192.273010 342.681671,191.009918 345.472626,191.117859 
	C349.270599,191.264740 353.028076,192.458664 356.895172,193.570099 
	C350.638092,193.648712 344.288757,193.360825 337.478394,192.999023 
z"/>
<path fill="#F8CD0D" opacity="1.000000" stroke="none" 
	d="
M286.675110,239.150696 
	C286.355255,244.713776 290.379089,246.580933 294.541321,248.591980 
	C291.908325,249.101135 289.504059,249.153992 287.099792,249.206848 
	C286.527893,247.601044 285.955994,245.995239 285.118591,244.142914 
	C285.373901,242.388397 285.894684,240.880417 286.675110,239.150696 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M391.068542,219.660645 
	C389.811584,218.690079 388.628754,217.753220 387.127625,216.690857 
	C387.949402,215.721375 389.089478,214.877380 390.563141,214.033432 
	C391.219788,214.137665 391.449066,214.345367 391.747620,215.096771 
	C392.004639,215.686920 392.098572,215.836914 392.192535,215.986893 
	C392.171783,216.757401 392.151001,217.527893 391.818939,218.698669 
	C391.173065,219.252655 391.066772,219.503189 391.188812,219.850571 
	C391.188812,219.850571 391.142670,219.694366 391.068542,219.660645 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M346.991821,200.040802 
	C345.680908,200.378632 344.177277,200.486008 342.249207,200.419067 
	C343.482880,200.099945 345.140961,199.955154 346.991821,200.040802 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M319.604584,205.970764 
	C318.489746,206.890808 317.027405,207.798828 315.233582,208.682648 
	C316.353760,207.758545 317.805420,206.858658 319.604584,205.970764 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M280.347656,261.179382 
	C280.500946,262.433533 280.465759,263.933441 280.160156,265.691345 
	C279.979553,264.441254 280.069336,262.933197 280.347656,261.179382 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M329.688995,202.035873 
	C328.851440,202.607269 327.668396,203.121277 326.158539,203.583069 
	C327.002350,203.013397 328.172913,202.495926 329.688995,202.035873 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M357.032227,201.154846 
	C356.294220,201.375153 355.421509,201.363159 354.311066,201.113602 
	C355.014709,200.891541 355.956146,200.907028 357.032227,201.154846 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M365.061462,203.294312 
	C364.571564,203.429123 364.006195,203.344238 363.260010,203.022369 
	C363.714813,202.881790 364.350433,202.978210 365.061462,203.294312 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M336.802612,200.735092 
	C336.420563,201.125031 335.891266,201.344162 335.086823,201.434509 
	C335.426208,201.058578 336.040802,200.811432 336.802612,200.735092 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M371.947632,206.452362 
	C371.602905,206.523880 371.102112,206.395462 370.492493,206.018875 
	C370.852966,205.931290 371.322296,206.091843 371.947632,206.452362 
z"/>
<path fill="#F8CD0D" opacity="1.000000" stroke="none" 
	d="
M289.769897,233.209305 
	C289.803680,233.574554 289.600922,234.121323 289.162292,234.852036 
	C289.128723,234.487595 289.331085,233.939209 289.769897,233.209305 
z"/>
<path fill="#F8CD0D" opacity="1.000000" stroke="none" 
	d="
M287.690491,237.008484 
	C287.752808,237.393616 287.581146,237.987656 287.160065,238.777008 
	C287.092560,238.387314 287.274506,237.802338 287.690491,237.008484 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M360.893158,202.132568 
	C361.025177,202.244568 360.674530,202.436523 360.674530,202.436523 
	C360.674530,202.436523 360.293182,202.317169 360.189301,202.146286 
	C360.317413,201.889618 360.542664,201.904663 360.893158,202.132568 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M382.971680,214.643082 
	C382.705231,214.860870 382.436157,214.713821 382.185364,214.185883 
	C382.444183,214.003860 382.699341,214.161606 382.971680,214.643082 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M307.560974,212.936295 
	C307.741943,213.209747 307.595337,213.425934 307.098358,213.536469 
	C306.973877,213.274292 307.135681,213.090363 307.560974,212.936295 
z"/>
<path fill="#F8CD0D" opacity="1.000000" stroke="none" 
	d="
M290.679230,231.853424 
	C290.756653,232.023651 290.602570,232.344498 290.236450,232.831665 
	C290.165497,232.666672 290.306610,232.335358 290.679230,231.853424 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M368.924072,204.996674 
	C368.999756,205.082428 368.748383,205.284866 368.748383,205.284866 
	C368.748383,205.284866 368.453979,205.153397 368.431671,204.992828 
	C368.409363,204.832245 368.848419,204.910904 368.924072,204.996674 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M324.625854,203.881866 
	C324.761475,204.193390 324.595367,204.366592 324.115051,204.319687 
	C324.066315,204.043610 324.240723,203.924927 324.625854,203.881866 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M366.939514,204.148056 
	C367.045258,204.265884 366.699951,204.427719 366.699951,204.427719 
	C366.699951,204.427719 366.353790,204.268143 366.290375,204.088196 
	C366.447357,203.858826 366.649658,203.899490 366.939514,204.148056 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M322.538635,204.844025 
	C322.697357,205.145691 322.558167,205.313187 322.107178,205.250656 
	C322.059967,204.988190 322.208435,204.884598 322.538635,204.844025 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M372.976379,207.042145 
	C373.057312,207.142746 372.761078,207.310486 372.761078,207.310486 
	C372.761078,207.310486 372.462708,207.146881 372.434631,206.977875 
	C372.588715,206.782501 372.751678,206.826736 372.976379,207.042145 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M375.021301,208.254730 
	C375.113983,208.398575 374.723511,208.474014 374.723511,208.474014 
	C374.723511,208.474014 374.395477,208.249420 374.350372,208.055359 
	C374.547516,207.858429 374.755310,207.941620 375.021301,208.254730 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M312.429749,209.849457 
	C312.627258,210.117172 312.519501,210.293121 312.080078,210.289795 
	C311.998169,210.046829 312.123566,209.929245 312.429749,209.849457 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M379.045837,211.142548 
	C379.098175,211.246887 378.801910,211.369293 378.801910,211.369293 
	C378.801910,211.369293 378.547363,211.175079 378.562653,211.014771 
	C378.577942,210.854462 378.993500,211.038193 379.045837,211.142548 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M309.269379,211.857483 
	C309.363708,211.846878 309.431641,212.142502 309.431641,212.142502 
	C309.431641,212.142502 309.186707,212.320419 309.060791,212.233871 
	C308.934875,212.147324 309.175049,211.868088 309.269379,211.857483 
z"/>
<path fill="#2F3234" opacity="1.000000" stroke="none" 
	d="
M392.296814,215.857437 
	C392.098572,215.836914 392.004639,215.686920 391.909363,215.271576 
	C392.291016,215.115067 392.455383,215.355652 392.296814,215.857437 
z"/>
<path fill="#2F3234" opacity="1.000000" stroke="none" 
	d="
M391.370209,219.777832 
	C391.066772,219.503189 391.173065,219.252655 391.730835,219.074768 
	C391.903870,219.320450 391.769714,219.538620 391.370209,219.777832 
z"/>
<path fill="#45D4F6" opacity="1.000000" stroke="none" 
	d="
M340.897614,152.892899 
	C341.212952,153.067810 341.528259,153.242722 341.943604,153.674744 
	C342.202209,157.043121 342.360840,160.154388 342.546539,163.796173 
	C347.621246,163.437317 351.618164,165.091797 353.055176,170.900146 
	C345.956482,172.665588 340.037354,169.801529 334.244659,165.072174 
	C333.214172,161.368469 332.820343,158.157379 331.495758,155.392151 
	C329.544922,151.319427 327.907959,147.651123 328.707855,142.720734 
	C329.361877,138.689346 327.853394,134.307114 327.303680,130.080429 
	C328.361115,128.878616 329.418549,127.676788 330.815247,126.710144 
	C332.178650,131.082870 335.529388,131.627106 339.018616,132.428223 
	C339.212341,133.371780 339.369751,133.900620 339.546692,134.650360 
	C339.587494,135.171082 339.551544,135.463898 339.169342,135.962433 
	C336.531738,140.237000 341.859406,139.354156 342.361603,141.579620 
	C341.059692,143.887833 339.757782,146.196045 338.686646,149.108139 
	C339.577454,150.772324 340.237518,151.832611 340.897614,152.892899 
z"/>
<path fill="#4BDAFA" opacity="1.000000" stroke="none" 
	d="
M327.045044,130.111053 
	C327.853394,134.307114 329.361877,138.689346 328.707855,142.720734 
	C327.907959,147.651123 329.544922,151.319427 331.495758,155.392151 
	C332.820343,158.157379 333.214172,161.368469 334.039948,164.817139 
	C331.568329,164.239532 329.080963,163.224945 326.133484,162.313858 
	C325.673309,162.417374 325.345551,162.516647 325.304413,162.585419 
	C325.263275,162.654205 325.419495,162.690109 325.399719,162.423218 
	C325.118622,161.931808 324.857330,161.707291 324.589539,161.468903 
	C324.583069,161.455048 324.602325,161.427414 324.602325,161.427414 
	C323.914062,159.767303 323.225800,158.107208 322.593048,155.778671 
	C322.419800,154.748764 322.191040,154.387283 321.962280,154.025803 
	C321.757935,153.522614 321.553619,153.019424 321.378235,152.241440 
	C321.595764,148.316330 321.784332,144.666000 321.964050,140.577499 
	C323.354279,137.088028 324.753357,134.036728 326.222717,130.647064 
	C326.457520,130.253036 326.621948,130.197357 327.045044,130.111053 
z"/>
<path fill="#16272D" opacity="1.000000" stroke="none" 
	d="
M321.379120,167.993912 
	C321.001831,167.528030 321.077606,167.064255 321.682922,166.267822 
	C323.682556,165.575333 325.152588,165.215485 326.622650,164.855637 
	C327.399292,165.693405 328.175903,166.531174 329.015930,167.650269 
	C326.663574,167.953079 324.247894,167.974548 321.379120,167.993912 
z"/>
<path fill="#142122" opacity="1.000000" stroke="none" 
	d="
M326.527222,164.610580 
	C325.152588,165.215485 323.682556,165.575333 321.849365,165.955017 
	C321.394287,164.920120 321.302338,163.865402 321.234924,162.413559 
	C322.101898,161.907822 322.944366,161.799210 324.194580,161.559006 
	C324.602325,161.427414 324.583069,161.455048 324.556030,161.759109 
	C324.825806,162.272156 325.122650,162.481125 325.419495,162.690094 
	C325.419495,162.690109 325.263275,162.654205 325.306427,162.878540 
	C325.581055,163.405304 325.812531,163.707718 326.044037,164.010132 
	C326.173309,164.128586 326.302551,164.247055 326.527222,164.610580 
z"/>
<path fill="#16272D" opacity="1.000000" stroke="none" 
	d="
M326.018463,163.631744 
	C325.812531,163.707718 325.581055,163.405304 325.347565,162.809769 
	C325.345551,162.516647 325.673309,162.417374 325.839844,162.456177 
	C326.001892,162.747772 325.997375,163.000565 326.018463,163.631744 
z"/>
<path fill="#16272D" opacity="1.000000" stroke="none" 
	d="
M329.921875,168.729187 
	C329.654541,168.847382 329.415070,168.638474 329.139130,168.140167 
	C329.325958,168.102280 329.608307,168.286041 329.921875,168.729187 
z"/>
<path fill="#18262D" opacity="1.000000" stroke="none" 
	d="
M308.004944,174.609650 
	C308.737274,174.180618 309.492676,174.082031 310.625671,174.050018 
	C310.633057,174.955536 310.262817,175.794479 309.892578,176.633423 
	C309.271057,176.068985 308.649506,175.504547 308.004944,174.609650 
z"/>
<path fill="#16272D" opacity="1.000000" stroke="none" 
	d="
M313.109985,174.065521 
	C313.436462,173.591217 313.932465,173.331894 314.716248,173.084412 
	C314.429169,173.491028 313.854370,173.885757 313.109985,174.065521 
z"/>
<path fill="#112026" opacity="1.000000" stroke="none" 
	d="
M257.972137,161.706909 
	C257.741669,161.735428 257.503632,161.479752 257.183777,160.972229 
	C257.389526,160.954483 257.677032,161.188599 257.972137,161.706909 
z"/>
<path fill="#45D4F6" opacity="1.000000" stroke="none" 
	d="
M296.797485,156.741882 
	C295.908722,150.252945 298.227539,145.749817 303.376038,142.251053 
	C305.213989,141.002060 306.227386,138.539688 309.018005,136.580612 
	C309.018005,139.505554 308.940765,142.432999 309.035889,145.354828 
	C309.183075,149.875214 309.509949,154.367889 313.665466,157.969940 
	C311.072968,160.750961 309.781433,163.377762 310.943024,166.819397 
	C303.623871,167.475327 299.668579,164.748230 296.797485,156.741882 
z"/>
<path fill="#34D2F5" opacity="1.000000" stroke="none" 
	d="
M311.301880,166.828064 
	C309.781433,163.377762 311.072968,160.750961 313.846008,158.266373 
	C315.532623,158.876114 317.058807,159.782440 318.848480,160.931015 
	C316.628235,163.061081 314.144470,164.948898 311.301880,166.828064 
z"/>
<path fill="#69CBE9" opacity="1.000000" stroke="none" 
	d="
M274.087524,133.956741 
	C274.048157,136.951965 273.809326,139.961884 274.028473,142.938065 
	C274.226746,145.630524 274.925018,148.286179 275.466980,151.378052 
	C275.287567,151.843369 275.043915,151.888687 274.429077,151.947540 
	C273.397675,149.575882 272.737488,147.190720 272.069336,144.383057 
	C272.404999,140.828766 272.748657,137.696991 273.349548,134.221954 
	C273.606842,133.878693 274.087555,133.956741 274.087524,133.956741 
z"/>
<path fill="#F7C40B" opacity="1.000000" stroke="none" 
	d="
M260.202362,185.531693 
	C259.830139,185.669708 259.442688,185.443161 258.986816,184.871796 
	C259.341339,184.740372 259.764221,184.953751 260.202362,185.531693 
z"/>
<path fill="#F7C40B" opacity="1.000000" stroke="none" 
	d="
M261.369812,186.436340 
	C260.937500,186.645111 260.564697,186.497894 260.177856,186.021912 
	C260.585663,185.822220 261.007477,185.951279 261.369812,186.436340 
z"/>
<path fill="#57E3FB" opacity="1.000000" stroke="none" 
	d="
M364.731140,122.852028 
	C365.091064,123.600258 365.113159,124.278511 364.926392,125.616074 
	C361.180847,128.341248 360.285492,131.166107 362.076904,134.909958 
	C362.845184,136.515610 363.336761,138.253662 363.766418,140.160797 
	C363.023590,140.587357 362.468414,140.784683 361.742920,141.092377 
	C361.357544,141.371811 361.183258,141.575729 360.744019,141.775833 
	C360.251831,141.650787 360.065430,141.564392 359.664368,141.247955 
	C359.016724,138.976303 358.583740,136.934692 358.273865,134.549988 
	C357.908325,131.536270 357.419647,128.865662 356.947357,125.809952 
	C357.354675,123.711128 357.745575,121.997391 358.136505,120.283661 
	C360.222076,121.116455 362.307678,121.949249 364.731140,122.852028 
z"/>
<path fill="#47C6E6" opacity="1.000000" stroke="none" 
	d="
M338.982300,132.013504 
	C335.529388,131.627106 332.178650,131.082870 331.104828,126.611717 
	C333.800781,124.646286 336.546387,123.014442 339.859741,121.546555 
	C341.635742,122.405174 342.843964,123.099831 343.919342,124.125320 
	C342.185089,126.975266 340.583710,129.494385 338.982300,132.013504 
z"/>
<path fill="#88EEF8" opacity="1.000000" stroke="none" 
	d="
M344.052185,123.794495 
	C342.843964,123.099831 341.635742,122.405174 340.207062,121.516190 
	C345.783936,120.837311 351.581238,120.352753 357.757538,120.075928 
	C357.745575,121.997391 357.354675,123.711128 356.575439,125.689682 
	C352.142181,125.234497 348.097168,124.514496 344.052185,123.794495 
z"/>
<path fill="#3BE8FA" opacity="1.000000" stroke="none" 
	d="
M363.954071,139.931549 
	C363.336761,138.253662 362.845184,136.515610 362.076904,134.909958 
	C360.285492,131.166107 361.180847,128.341248 364.924957,125.954361 
	C367.475830,128.499939 369.819275,131.366562 372.244263,134.592346 
	C371.592804,135.716644 370.859863,136.481781 369.616699,137.599762 
	C367.389008,138.612259 365.671539,139.271912 363.954071,139.931549 
z"/>
<path fill="#9EECF4" opacity="1.000000" stroke="none" 
	d="
M358.150757,134.893097 
	C358.583740,136.934692 359.016724,138.976303 359.254639,141.257111 
	C357.711395,141.686829 356.363159,141.877335 355.014954,142.067841 
	C351.081177,141.947495 347.147400,141.827148 342.787598,141.643219 
	C341.859406,139.354156 336.531738,140.237000 339.603333,135.996552 
	C345.934113,135.520569 351.541901,135.223251 357.400024,134.920319 
	C357.817169,134.907501 357.983978,134.900299 358.150757,134.893097 
z"/>
<path fill="#249EC0" opacity="1.000000" stroke="none" 
	d="
M354.782898,142.318512 
	C356.363159,141.877335 357.711395,141.686829 359.469299,141.487152 
	C360.065430,141.564392 360.251831,141.650787 360.949310,141.715164 
	C361.711731,141.520111 361.862701,141.283066 361.913239,140.982010 
	C362.468414,140.784683 363.023590,140.587357 363.766418,140.160797 
	C365.671539,139.271912 367.389008,138.612259 369.436615,137.863098 
	C367.203064,140.556381 365.106567,144.220901 361.953552,145.891357 
	C358.284637,147.835144 353.709045,148.131744 349.484802,148.916565 
	C348.469177,149.105286 347.287415,148.399582 345.490753,147.921112 
	C349.107330,145.784760 351.829071,144.176971 354.782898,142.318512 
z"/>
<path fill="#208DB1" opacity="1.000000" stroke="none" 
	d="
M340.844269,152.433563 
	C340.237518,151.832611 339.577454,150.772324 338.872437,149.374115 
	C339.352570,149.296585 339.877655,149.556961 340.552429,149.968262 
	C340.731720,150.737534 340.761322,151.355881 340.844269,152.433563 
z"/>
<path fill="#208DB1" opacity="1.000000" stroke="none" 
	d="
M346.899902,150.637939 
	C346.838104,150.790161 346.668823,150.830185 346.245544,150.810699 
	C346.205139,150.460800 346.472107,150.385651 346.899902,150.637939 
z"/>
<path fill="#6AC8E0" opacity="1.000000" stroke="none" 
	d="
M321.940918,154.356628 
	C322.191040,154.387283 322.419800,154.748764 322.631897,155.442230 
	C322.383362,155.411957 322.151459,155.049698 321.940918,154.356628 
z"/>
<path fill="#2EBCE5" opacity="1.000000" stroke="none" 
	d="
M297.138947,367.904968 
	C297.445984,368.446777 297.688232,369.353149 297.876404,370.607391 
	C297.572937,370.060028 297.323547,369.164795 297.138947,367.904968 
z"/>
<path fill="#249EC0" opacity="1.000000" stroke="none" 
	d="
M339.041901,383.308197 
	C343.575714,384.484161 348.075531,386.064728 352.575317,387.645325 
	C352.390808,388.456848 352.206299,389.268372 352.021790,390.079926 
	C348.986053,389.741364 345.944489,389.446991 342.917267,389.044403 
	C341.542145,388.861511 340.195526,388.464294 338.443359,388.323792 
	C338.369751,386.893158 338.688812,385.303009 339.041901,383.308197 
z"/>
<path fill="#26A5CE" opacity="1.000000" stroke="none" 
	d="
M335.143860,390.111511 
	C335.066956,391.118744 334.624542,392.130829 334.234253,393.488770 
	C333.670746,393.223969 333.055145,392.613312 332.439545,392.002655 
	C333.219177,391.373901 333.998779,390.745117 335.143860,390.111511 
z"/>
<path fill="#249EC0" opacity="1.000000" stroke="none" 
	d="
M337.087402,381.227539 
	C337.666718,381.389862 338.287109,381.878174 338.992432,382.656616 
	C338.427765,382.482330 337.778137,382.017944 337.087402,381.227539 
z"/>
<path fill="#249EC0" opacity="1.000000" stroke="none" 
	d="
M336.145020,379.766602 
	C336.339935,379.768890 336.627380,380.077820 337.001007,380.692810 
	C336.803986,380.690308 336.520752,380.381744 336.145020,379.766602 
z"/>
<path fill="#249EC0" opacity="1.000000" stroke="none" 
	d="
M280.470398,398.948242 
	C277.176514,395.745239 274.311218,392.551788 270.617584,388.435150 
	C268.811920,392.880188 267.644440,395.754181 266.127808,398.777893 
	C266.333282,393.923126 266.887909,388.918671 267.437836,383.956757 
	C272.356079,388.692566 277.322327,393.474609 282.452667,398.577972 
	C282.044189,398.918793 281.471588,398.938293 280.470398,398.948242 
z"/>
<path fill="#249EC0" opacity="1.000000" stroke="none" 
	d="
M282.912781,399.232361 
	C283.073883,399.029846 283.265717,399.083008 283.695740,399.316833 
	C283.758148,399.661957 283.558990,399.790161 283.168243,399.941040 
	C282.981171,399.829346 282.962372,399.658691 282.912781,399.232361 
z"/>
<path fill="#26A5CE" opacity="1.000000" stroke="none" 
	d="
M243.401855,384.825226 
	C243.620697,379.439545 240.972137,377.919128 235.898880,378.707367 
	C233.978256,379.005798 230.064529,377.854675 230.077972,377.469604 
	C230.218643,373.440155 233.637390,375.268463 235.748291,374.891083 
	C238.517380,374.396118 241.371765,374.378204 244.630386,374.128540 
	C245.027283,376.478729 244.983261,378.855499 244.732208,381.800507 
	C244.150726,383.187592 243.776291,384.006409 243.401855,384.825226 
z"/>
<path fill="#1999B8" opacity="1.000000" stroke="none" 
	d="
M243.171509,385.192993 
	C243.776291,384.006409 244.150726,383.187592 244.724075,382.179260 
	C245.236465,384.782257 245.549957,387.574738 245.885864,390.785767 
	C241.618912,391.185974 237.324265,391.277008 233.044113,391.069183 
	C232.023926,391.019623 231.052261,389.971558 230.058289,389.382751 
	C231.042160,388.594635 231.924362,387.350647 233.029053,387.104279 
	C236.285828,386.377899 239.630737,386.046661 243.171509,385.192993 
z"/>
<path fill="#1999B8" opacity="1.000000" stroke="none" 
	d="
M247.217651,397.991638 
	C246.948471,396.292053 246.929825,394.586487 246.879669,392.451660 
	C247.258743,393.772522 247.669312,395.522675 248.024429,397.618591 
	C247.802063,397.971436 247.635132,397.978516 247.217651,397.991638 
z"/>
<path fill="#1999B8" opacity="1.000000" stroke="none" 
	d="
M246.852051,391.883789 
	C246.675262,391.924774 246.363937,391.737305 245.966156,391.285522 
	C246.158966,391.232605 246.438232,391.443970 246.852051,391.883789 
z"/>
<path fill="#091318" opacity="1.000000" stroke="none" 
	d="
M220.633423,150.750244 
	C220.084183,151.036011 219.101883,150.910782 219.103745,150.802582 
	C219.168121,147.079636 219.325607,143.358292 219.735428,139.321472 
	C220.220505,142.786560 220.439880,146.566879 220.633423,150.750244 
z"/>
<path fill="#14232A" opacity="1.000000" stroke="none" 
	d="
M219.112503,131.728577 
	C218.762909,131.926926 218.535919,131.859421 217.999878,131.612549 
	C218.077499,131.252396 218.464188,131.071609 219.194550,130.926270 
	C219.437195,131.128708 219.336151,131.295700 219.112503,131.728577 
z"/>
<path fill="#1799C0" opacity="1.000000" stroke="none" 
	d="
M274.147766,133.719452 
	C274.087555,133.956741 273.606842,133.878693 273.386597,133.775970 
	C273.308289,133.492401 273.462372,133.322556 273.794861,133.111481 
	C273.961182,133.059250 274.207977,133.482147 274.147766,133.719452 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M362.912109,301.032715 
	C362.702026,302.015442 362.491943,302.998169 361.824402,304.307831 
	C360.770355,304.674225 360.173828,304.713684 359.456024,304.432251 
	C358.961823,303.545776 358.588837,302.980225 358.215851,302.414673 
	C358.386017,302.113953 358.418884,301.798859 358.280853,300.818878 
	C358.090698,298.730408 357.934235,297.292542 357.758148,295.674622 
	C351.632111,295.334778 345.859497,295.014526 340.086914,294.694305 
	C340.086914,294.694305 340.109192,294.807861 339.978546,294.537811 
	C339.282928,294.066132 338.717957,293.864502 338.152985,293.662872 
	C338.152985,293.662872 338.125427,293.814270 338.111328,293.546539 
	C337.775482,293.104919 337.453735,292.931061 337.131958,292.757172 
	C337.131958,292.757172 337.119812,292.877197 337.098175,292.603149 
	C336.766663,292.137848 336.456757,291.946594 336.146881,291.755341 
	C336.146881,291.755341 336.138733,291.867615 336.107361,291.580261 
	C335.744232,291.132721 335.412506,290.972473 335.080750,290.812256 
	C335.080750,290.812256 335.052368,290.908295 334.972229,290.699738 
	C334.624481,290.290466 334.356934,290.089783 334.089355,289.889069 
	C333.395844,286.477997 332.702362,283.066895 331.950378,279.323425 
	C331.944458,277.646332 331.997009,276.301666 332.194275,274.592896 
	C333.417114,276.235504 334.711609,278.157806 335.533142,280.264557 
	C338.071716,286.774628 342.968689,289.265045 349.644379,289.018341 
	C351.307495,288.956879 352.994354,289.163635 354.635925,288.964264 
	C359.423157,288.382965 361.941833,290.316437 362.013489,296.001404 
	C362.325378,298.196136 362.618744,299.614410 362.912109,301.032715 
z"/>
<path fill="#EFEFD7" opacity="1.000000" stroke="none" 
	d="
M359.577271,304.753143 
	C360.173828,304.713684 360.770355,304.674225 361.598633,304.614868 
	C362.052307,304.953186 362.274261,305.311432 362.639282,305.830597 
	C360.710602,307.825928 358.928711,310.492188 356.511292,311.334503 
	C349.977539,313.611115 343.258636,312.979523 336.236816,310.410034 
	C335.549683,309.990936 335.334229,309.949493 335.102325,309.993591 
	C334.620148,309.873474 334.137939,309.753326 333.212280,309.364319 
	C332.591003,308.974396 332.395447,308.928345 332.182220,308.957275 
	C331.980621,308.892365 331.779022,308.827423 331.198242,308.470276 
	C330.637756,308.019043 330.426514,307.939514 330.185333,307.939423 
	C329.979767,307.839325 329.774200,307.739258 329.235291,307.343323 
	C328.901917,307.047394 328.519043,306.859406 328.519043,306.859406 
	C328.170135,306.662781 327.821228,306.466156 327.215027,305.726654 
	C326.781860,304.317230 326.605957,303.450714 326.374084,302.308533 
	C330.776978,303.635712 334.687286,304.814423 339.042419,306.041382 
	C344.664398,306.084869 349.841522,306.080109 355.018646,306.075317 
	C355.848480,305.886810 356.678314,305.698303 358.154510,305.271149 
	C359.059662,304.939392 359.318451,304.846252 359.577271,304.753143 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M317.978851,290.897156 
	C316.225739,288.923035 314.472626,286.948944 312.157959,284.626038 
	C311.031281,281.189514 310.466156,278.101715 309.912048,274.724121 
	C309.658203,273.938110 309.393341,273.441925 309.128174,272.534332 
	C309.161987,270.755585 309.196045,269.388245 309.230103,268.020905 
	C312.583435,271.070984 310.028473,265.575378 311.978027,266.139252 
	C313.501923,272.683044 314.804077,279.361908 316.401550,286.297302 
	C316.979431,287.238983 317.261993,287.924164 317.745972,289.119690 
	C317.957886,290.052399 317.968384,290.474792 317.978851,290.897156 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M338.597595,305.993134 
	C334.687286,304.814423 330.776978,303.635712 326.374084,302.308533 
	C326.605957,303.450714 326.781860,304.317230 327.011719,305.503540 
	C322.041656,304.203308 320.349274,300.550812 321.178833,295.437561 
	C321.540741,295.294586 321.799347,295.162201 321.799347,295.162201 
	C321.799347,295.162201 321.845856,295.164703 321.802246,295.537872 
	C322.842743,296.589447 323.926910,297.267822 325.011047,297.946228 
	C329.250854,300.362671 333.490631,302.779114 338.123108,305.406128 
	C338.515778,305.616669 338.597595,305.993134 338.597595,305.993134 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M340.325623,295.009766 
	C345.859497,295.014526 351.632111,295.334778 357.758148,295.674622 
	C357.934235,297.292542 358.090698,298.730408 358.185333,300.595428 
	C357.061890,297.423370 354.532227,296.843597 351.069214,296.860687 
	C347.570190,296.877991 344.066132,295.879028 340.325623,295.009766 
z"/>
<path fill="#2F3234" opacity="1.000000" stroke="none" 
	d="
M339.042419,306.041382 
	C338.597595,305.993134 338.515778,305.616669 338.469421,305.430054 
	C343.737030,305.350189 349.051025,305.456909 354.691833,305.819458 
	C349.841522,306.080109 344.664398,306.084869 339.042419,306.041382 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M318.233124,291.071960 
	C317.968384,290.474792 317.957886,290.052399 317.954468,289.311737 
	C318.885223,290.218292 319.808929,291.443146 320.651367,293.035583 
	C319.875916,292.684357 319.181641,291.965546 318.233124,291.071960 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M324.950806,297.610535 
	C323.926910,297.267822 322.842743,296.589447 321.807861,295.560486 
	C322.868256,295.898224 323.879395,296.586517 324.950806,297.610535 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M359.456024,304.432251 
	C359.318451,304.846252 359.059662,304.939392 358.388336,305.047058 
	C358.012299,304.473206 358.048828,303.884827 358.150604,302.855560 
	C358.588837,302.980225 358.961823,303.545776 359.456024,304.432251 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M338.291809,293.957947 
	C338.717957,293.864502 339.282928,294.066132 340.030701,294.512787 
	C339.619202,294.589569 339.024933,294.421295 338.291809,293.957947 
z"/>
<path fill="#1B2A2E" opacity="1.000000" stroke="none" 
	d="
M321.802643,294.838501 
	C321.799347,295.162201 321.540741,295.294586 321.400940,295.320587 
	C321.063782,294.900452 320.866455,294.454315 320.589722,293.653687 
	C320.942169,293.704407 321.374054,294.109589 321.802643,294.838501 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M334.192383,290.126923 
	C334.356934,290.089783 334.624481,290.290466 335.021606,290.707947 
	C334.865906,290.738037 334.580658,290.551422 334.192383,290.126923 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M335.202240,291.057892 
	C335.412506,290.972473 335.744232,291.132721 336.162994,291.571716 
	C335.941254,291.668152 335.632477,291.485840 335.202240,291.057892 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M336.241547,292.014954 
	C336.456757,291.946594 336.766663,292.137848 337.158264,292.597839 
	C336.938690,292.669281 336.637451,292.471924 336.241547,292.014954 
z"/>
<path fill="#262619" opacity="1.000000" stroke="none" 
	d="
M337.235535,293.015137 
	C337.453735,292.931061 337.775482,293.104919 338.188293,293.546082 
	C337.965912,293.633301 337.652496,293.453217 337.235535,293.015137 
z"/>
<path fill="#FACB04" opacity="1.000000" stroke="none" 
	d="
M310.352173,251.797424 
	C305.063629,248.523972 304.875153,246.960526 308.380646,242.080170 
	C311.091583,238.306015 314.464172,235.053314 313.264954,229.305344 
	C312.388184,225.102982 318.495209,220.468826 323.920135,220.004700 
	C334.131165,219.131073 344.336517,218.188110 354.552795,217.382355 
	C355.584930,217.300964 356.806519,217.750198 357.716125,218.314438 
	C361.427063,220.616196 364.910034,221.887665 369.220612,219.160263 
	C370.575928,218.302734 373.558167,219.067566 375.293579,220.012299 
	C377.236847,221.070190 378.610260,223.174881 380.544861,224.898804 
	C380.323242,225.815842 379.788483,226.658112 378.648254,227.747925 
	C373.343201,229.029968 368.643585,230.064484 363.944000,231.098999 
	C362.810822,230.523544 361.677643,229.948105 360.178192,228.850037 
	C358.495514,227.908325 357.179138,227.489258 355.862732,227.070190 
	C349.889008,226.723755 343.915314,226.377319 337.298615,225.833664 
	C336.103577,226.025696 335.551514,226.414932 334.999420,226.804169 
	C331.554352,228.444366 328.109283,230.084579 323.997803,231.876953 
	C322.564331,232.709274 321.797272,233.389389 321.030243,234.069534 
	C319.530701,235.936447 318.031158,237.803360 315.910126,239.913086 
	C314.186676,242.421341 313.084717,244.686798 311.982727,246.952240 
	C311.517578,248.484512 311.052460,250.016785 310.352173,251.797424 
z"/>
<path fill="#FAC604" opacity="1.000000" stroke="none" 
	d="
M364.126831,231.381714 
	C368.643585,230.064484 373.343201,229.029968 378.472015,227.993958 
	C378.285919,229.071411 377.670624,230.150345 376.978424,231.526855 
	C370.214050,237.409637 369.806915,237.404465 364.126831,231.381714 
z"/>
<path fill="#FAC604" opacity="1.000000" stroke="none" 
	d="
M312.271240,246.793411 
	C313.084717,244.686798 314.186676,242.421341 315.621216,240.071915 
	C315.949799,240.429367 315.945831,240.870789 315.915771,241.642181 
	C314.779694,243.526276 313.669739,245.080429 312.271240,246.793411 
z"/>
<path fill="#FAC604" opacity="1.000000" stroke="none" 
	d="
M356.097534,227.307190 
	C357.179138,227.489258 358.495514,227.908325 359.905487,228.679382 
	C358.776825,228.535660 357.554565,228.039932 356.097534,227.307190 
z"/>
<path fill="#FAC604" opacity="1.000000" stroke="none" 
	d="
M321.341736,234.009338 
	C321.797272,233.389389 322.564331,232.709274 323.683228,232.023041 
	C323.241119,232.661011 322.447174,233.305069 321.341736,234.009338 
z"/>
<path fill="#FAC604" opacity="1.000000" stroke="none" 
	d="
M335.283783,226.814255 
	C335.551514,226.414932 336.103577,226.025696 336.831543,225.827057 
	C336.527679,226.286575 336.047913,226.555450 335.283783,226.814255 
z"/>
<path fill="#FACB04" opacity="1.000000" stroke="none" 
	d="
M309.901031,275.013947 
	C310.466156,278.101715 311.031281,281.189514 311.817383,284.562561 
	C313.673615,289.842987 315.308899,294.838165 317.004608,300.017944 
	C308.600311,303.783447 303.895844,298.186890 298.725281,294.171814 
	C296.541229,292.475800 294.599854,290.471222 292.481750,288.685730 
	C282.371033,280.162689 277.277679,266.922028 287.266113,254.179153 
	C289.055206,254.932358 290.403168,255.578812 291.284668,256.620178 
	C293.627930,259.388519 295.725098,261.600098 297.961517,256.547272 
	C298.256134,255.881683 299.183289,255.496048 299.817505,254.980743 
	C302.894531,254.652054 305.971558,254.323364 309.467896,253.992004 
	C309.621979,258.364777 309.356781,262.740204 309.160858,267.568268 
	C309.196045,269.388245 309.161987,270.755585 308.916290,272.709595 
	C309.103424,273.868805 309.502228,274.441376 309.901031,275.013947 
z"/>
<path fill="#DA7B13" opacity="1.000000" stroke="none" 
	d="
M312.914185,259.044830 
	C313.241425,259.199127 313.274628,259.434662 312.997986,259.821350 
	C312.758820,259.755981 312.636414,259.570374 312.576813,259.366455 
	C312.558655,259.304443 312.713501,259.191772 312.914185,259.044830 
z"/>
<path fill="#F8BF06" opacity="1.000000" stroke="none" 
	d="
M363.154724,300.814575 
	C362.618744,299.614410 362.325378,298.196136 362.055420,296.400909 
	C362.518311,297.548157 362.957794,299.072296 363.154724,300.814575 
z"/>
<path fill="#F9F7CC" opacity="1.000000" stroke="none" 
	d="
M299.799744,254.628479 
	C299.183289,255.496048 298.256134,255.881683 297.961517,256.547272 
	C295.725098,261.600098 293.627930,259.388519 291.284668,256.620178 
	C290.403168,255.578812 289.055206,254.932358 287.590576,254.008392 
	C287.165344,252.541733 287.069427,251.172516 287.036682,249.505066 
	C289.504059,249.153992 291.908325,249.101135 294.655029,248.924149 
	C296.592316,250.625412 298.187134,252.450821 299.799744,254.628479 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M328.505615,307.018463 
	C328.519043,306.859406 328.901917,307.047394 328.966125,307.130737 
	C329.030334,307.214111 328.749664,307.363251 328.749664,307.363251 
	C328.749664,307.363251 328.492188,307.177521 328.505615,307.018463 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M330.242737,308.129150 
	C330.426514,307.939514 330.637756,308.019043 330.921570,308.314148 
	C331.024048,308.450287 330.636108,308.527435 330.636108,308.527435 
	C330.636108,308.527435 330.300171,308.318878 330.242737,308.129150 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M332.248962,309.128143 
	C332.395447,308.928345 332.591003,308.974396 332.875946,309.202332 
	C332.983063,309.309204 332.647003,309.453644 332.647003,309.453644 
	C332.647003,309.453644 332.315674,309.299011 332.248962,309.128143 
z"/>
<path fill="#E2DD9B" opacity="1.000000" stroke="none" 
	d="
M335.193726,310.160461 
	C335.334229,309.949493 335.549683,309.990936 335.869263,310.231873 
	C335.989899,310.345825 335.633759,310.476959 335.633759,310.476959 
	C335.633759,310.476959 335.285126,310.327301 335.193726,310.160461 
z"/>
<path fill="#F8BF06" opacity="1.000000" stroke="none" 
	d="
M279.009705,281.013428 
	C279.020264,281.026367 278.757385,281.401520 278.757385,281.401520 
	C278.757385,281.401520 278.754883,281.082336 278.864014,281.030945 
	C278.973145,280.979523 278.999146,281.000519 279.009705,281.013428 
z"/>
<path fill="#2EBCE5" opacity="1.000000" stroke="none" 
	d="
M308.791809,364.359192 
	C308.885406,364.191681 309.188934,364.159698 309.949829,364.172363 
	C309.938660,364.309601 309.470215,364.402161 308.791809,364.359192 
z"/>
<path fill="#249EC0" opacity="1.000000" stroke="none" 
	d="
M358.273865,134.549988 
	C357.983978,134.900299 357.817169,134.907501 357.286255,134.578430 
	C355.524414,132.991074 354.325806,131.278595 352.692383,130.573715 
	C347.381134,128.281754 343.204285,130.692093 339.527130,134.429459 
	C339.369751,133.900620 339.212341,133.371780 339.018616,132.428223 
	C340.583710,129.494385 342.185089,126.975266 343.919342,124.125320 
	C348.097168,124.514496 352.142181,125.234497 356.559082,126.074768 
	C357.419647,128.865662 357.908325,131.536270 358.273865,134.549988 
z"/>
<path fill="#0F303A" opacity="1.000000" stroke="none" 
	d="
M339.546692,134.650360 
	C343.204285,130.692093 347.381134,128.281754 352.692383,130.573715 
	C354.325806,131.278595 355.524414,132.991074 357.035889,134.584045 
	C351.541901,135.223251 345.934113,135.520569 339.892334,135.783783 
	C339.551544,135.463898 339.587494,135.171082 339.546692,134.650360 
z"/>
<path fill="#16272D" opacity="1.000000" stroke="none" 
	d="
M325.399719,162.423218 
	C325.122650,162.481125 324.825806,162.272156 324.562500,161.772980 
	C324.857330,161.707291 325.118622,161.931808 325.399719,162.423218 
z"/>
<path fill="#3BE8FA" opacity="1.000000" stroke="none" 
	d="
M361.742920,141.092377 
	C361.862701,141.283066 361.711731,141.520111 361.255066,141.753815 
	C361.183258,141.575729 361.357544,141.371811 361.742920,141.092377 
z"/>
<path fill="#F8BF06" opacity="1.000000" stroke="none" 
	d="
M309.912048,274.724121 
	C309.502228,274.441376 309.103424,273.868805 308.916565,273.120972 
	C309.393341,273.441925 309.658203,273.938110 309.912048,274.724121 
z"/>
</svg>
    </SvgIcon>*/}