import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText, Divider } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pt: { xs: 14, sm: 20 },
        pb: { xs: 8, sm: 12 }
      }}
    >
      <Box>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>

        <Typography variant="body1" gutterBottom>
          At Little Big Events, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our website and services.
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Box>
          <Typography variant="h6" gutterBottom>
            1. Information We Collect
          </Typography>
          <Typography variant="body2" gutterBottom>
            We collect information to provide better services to all our users. The types of information we collect include:
          </Typography>
          <List>
            <ListItem>
              <ListItemText>
                a. **Personal Information**: Such as your name, email address, phone number, and event details when you use our platform to contact vendors.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                b. **Vendor Information**: For vendors, we may collect information about your business, including your service offerings, contact details, and payment information.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                c. **Usage Data**: We collect information about your interactions with our website, such as pages visited, time spent on the site, and search queries.
              </ListItemText>
            </ListItem>
          </List>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box>
          <Typography variant="h6" gutterBottom>
            2. How We Use Your Information
          </Typography>
          <Typography variant="body2" gutterBottom>
            We use the information we collect to:
          </Typography>
          <List>
            <ListItem>
              <ListItemText>
                a. Provide and maintain our services, ensuring smooth connections between vendors and event organizers.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                b. Improve our platform by analyzing usage trends and feedback.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                c. Communicate with you, including sending updates, newsletters, and marketing materials (with your consent).
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                d. Process transactions and handle billing for services such as paid vendor listings.
              </ListItemText>
            </ListItem>
          </List>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box>
          <Typography variant="h6" gutterBottom>
            3. Sharing Your Information
          </Typography>
          <Typography variant="body2" gutterBottom>
            We do not share your personal information with third parties except in the following circumstances:
          </Typography>
          <List>
            <ListItem>
              <ListItemText>
                a. **With Vendors**: When you inquire about or book services, your contact information is shared with the relevant vendors.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                b. **Service Providers**: We may share information with third-party service providers who help us operate our platform, such as payment processors.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                c. **Legal Requirements**: We may disclose your information if required by law or to protect our rights and safety.
              </ListItemText>
            </ListItem>
          </List>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box>
          <Typography variant="h6" gutterBottom>
            4. Data Security
          </Typography>
          <Typography variant="body2" gutterBottom>
            We are committed to protecting your personal data. We use industry-standard security measures to safeguard your information from unauthorized access, alteration, or disclosure. However, no method of transmission over the internet is 100% secure, so we cannot guarantee absolute security.
          </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box>
          <Typography variant="h6" gutterBottom>
            5. Your Rights
          </Typography>
          <Typography variant="body2" gutterBottom>
            You have the right to access, update, or delete your personal information. If you wish to exercise these rights or have any questions about your data, please contact us.
          </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box>
          <Typography variant="h6" gutterBottom>
            6. Cookies
          </Typography>
          <Typography variant="body2" gutterBottom>
            Our website uses cookies to improve your experience. Cookies are small text files stored on your device that help us analyze traffic and customize content. You can manage cookie settings in your browser.
          </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box>
          <Typography variant="h6" gutterBottom>
            7. Third-Party Links
          </Typography>
          <Typography variant="body2" gutterBottom>
            Our platform may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. We encourage users to review the privacy policies of any third-party sites they visit.
          </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box>
          <Typography variant="h6" gutterBottom>
            8. Changes to this Privacy Policy
          </Typography>
          <Typography variant="body2" gutterBottom>
            We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we will notify you of significant changes. Your continued use of the platform after such changes constitutes your acceptance of the updated policy.
          </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box>
          <Typography variant="h6" gutterBottom>
            9. Contact Us
          </Typography>
          <Typography variant="body2" gutterBottom>
            If you have any questions or concerns about this Privacy Policy, please contact us at privacy@littlebigevents.com.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
