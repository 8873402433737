import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import EventIcon from '@mui/icons-material/Event';
import CelebrationIcon from '@mui/icons-material/Celebration';
import SchoolIcon from '@mui/icons-material/School';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CakeIcon from '@mui/icons-material/Cake';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import { useTheme } from '@mui/system';

const eventUseCases = [
    {
        title: 'Stress-Free Kids Parties',
        description:
            "From selecting the venue to booking entertainment, we make planning children's events easy and magical. Let us handle the details while you enjoy the day with your family.",
        icon: <CelebrationIcon fontSize="large" sx={{ color: 'primary.main' }} />,
    },
    {
        title: 'Community and School Events',
        description:
            "For large-scale events like school fun days, we help you find the best suppliers for everything from bouncy castles to food trucks. Make your school or community event unforgettable!",
        icon: <SchoolIcon fontSize="large" sx={{ color: 'primary.main' }} />,
    },
    {
        title: 'Support for Event Planners',
        description:
            "We provide expert vendor connections and event management for professional planners. Whether it's a small gathering or a large corporate event, let us be your trusted partner.",
        icon: <BusinessCenterIcon fontSize="large" sx={{ color: 'primary.main' }} />,
    },
    {
        title: 'Boost Your Event Business',
        description:
            "Partner with us to expand your event business. Reach more clients for family events, corporate functions, and more. Let us help your business grow with tailored support.",
        icon: <EventIcon fontSize="large" sx={{ color: 'primary.main' }} />,
    },
    {
        title: 'Birthday Parties',
        description:
            "Create unforgettable birthday memories with personalized entertainers, activities, and venues that fit your needs. Let us help you find the perfect vendors to plan an amazing birthday party for your child, making it a day they'll always cherish.",
        icon: <CakeIcon fontSize="large" sx={{ color: 'primary.main' }} />,
    },
    {
        title: 'Perfect Nursery Events',
        description:
            "Planning a nursery event? We’ve got you covered with the best entertainers and activities for young children. From small gatherings to larger events, we ensure everything runs smoothly.",
        icon: <ChildFriendlyIcon fontSize="large" sx={{ color: 'primary.main' }} />,
    },
];

export default function PerfectEvent() {
    const theme = useTheme()

    return (
        <Container
            id="perfect-event"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 3, sm: 6 },
            }}
        >
            <Box
                sx={{
                    width: { sm: '100%', md: '60%' },
                    textAlign: { sm: 'left', md: 'center' },
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                        component="span"
                        variant="h2"
                        gutterBottom
                        sx={{
                            fontSize: "clamp(3rem, 10vw, 3.5rem)",
                            color: "text.secondary", // Default color for "The Perfect Event."
                            display: "inline-block", // Ensures inline block for better control
                            verticalAlign: "middle",  // Aligns vertically in the middle
                        }}
                    >
                        The Perfect Event.{' '}
                        <Typography
                            component="span"
                            sx={{
                                fontSize: "inherit", // Inherit the size from the parent
                                color: theme.palette.mode === 'dark' ? 'primary.dark' : 'primary.main', // Conditional color
                            }}
                        >
                            Always.
                        </Typography>
                    </Typography>

                </Box>


                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    Explore how we can help create the perfect event for you, whether it’s a
                    children’s party, a school event, or a corporate gathering. Discover our
                    tailored services for all your event needs.
                </Typography>
            </Box>
            <Grid container spacing={2}>
                {eventUseCases.map((useCase, index) => (
                    <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index} sx={{ display: 'flex' }}>
                        <Card
                            variant="outlined"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                flexGrow: 1,
                            }}
                        >
                            <CardContent sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                {useCase.icon}
                                <Box>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        sx={{ color: 'text.primary' }}
                                    >
                                        {useCase.title}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{ color: 'text.secondary' }}
                                    >
                                        {useCase.description}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
