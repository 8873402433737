import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import HowItWorksDetails from './HowItWorksDetails'; // Adjust the path as needed


const introVendors = "We connect vendors with opportunities for events and venues to showcase your services."
const introOrganizers = "We connect you with the ideal vendors to bring your event to life, ensuring every detail is covered—from finding the perfect venue and entertainment to arranging catering, decorations, and more. With our expertise, we help you craft a seamless, memorable experience tailored to your vision. "

const howDataVendors = [
    {
        title: '1) Vendor Registration',
        description: 'Contact us to join our network. Let us know your specialties and the types of events you cater to. We thoroughly vet all vendors to ensure quality, helping you stand out in our trusted network. ',
    },
    {
        title: '2) Visibility to Potential Clients',
        description:
            'Once added in our netword, your business becomes visible to a range of clients—parents, schools, and institutions—looking for quality services for kids’ events. We market your offerings to attract inquiries and bookings.',
    },
    {
        title: '3) Client Matching',
        description:
            'When clients reach out to us with specific needs, we match them with the most suitable vendors from our network. We consider your specialties, availability, and past performance to ensure the right fit for each event.',
    },
    {
        title: '4) Booking and Coordination',
        description:
            'When a client chooses you, we facilitate the booking process, handling all communication and logistics. You focus on delivering your service; we’ll manage the details, ensuring a smooth experience for everyone involved.',
    },
    {
        title: '5) Event Support',
        description: 'We’re here for you throughout the event preparation. We maintain communication with both you and the client to ensure all details are aligned, providing assistance with any last-minute adjustments. '
    },
    {
        title: '6) Feedback and Growth',
        description: 'After the event, we follow up to gather feedback from clients. This information not only helps us improve our service but also provides you with valuable insights to enhance your offerings and grow your business. '
    },
];

const howDataOrganizers = [

    {
        title: '1) Initial Inquiry',
        description: 'Fill out the form to reach out to us with your specific event needs. Whether you’re planning a birthday party, school event, or any other kid-focused gathering, we’ll gather all the essential details, including the type of services you’re looking for, your budget, and any special requests. '
    },
    {
        title: '2) Tailored Vendor Recommendations',
        description: 'Once we understand your requirements, we leverage our network of vetted vendors to provide you with tailored recommendations. We’ll connect you with the best options for venues, entertainers, caterers, decorators, and more, ensuring they meet your event’s theme and expectations. '
    },
    {
        title: '3) Streamlined Booking Process',
        description: 'After you review the vendor options, we help facilitate the booking process. We handle all communication with the vendors to confirm availability, negotiate terms, and secure contracts, making the process seamless and hassle-free for you. '
    },
    {
        title: '4) Ongoing Support',
        description: 'As your event date approaches, we provide continuous support, checking in with both you and the vendors to ensure all details are aligned. We’re here to address any last-minute changes or questions you may have, ensuring everything runs smoothly.'
    },
    {
        title: '5) Post-Event Feedback',
        description: 'After your event, we follow up to gather your feedback and insights. Your experiences help us refine our service and improve vendor matches for future events, ensuring we continue to meet your needs effectively.'
    },
];

export default function HowItWorks() {
    const [selectedTab, setSelectedTab] = React.useState(0);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Container
            id="howitworks"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 3, sm: 6 },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    pt: { xs: 8, sm: 6 },
                }}
            >
                <Typography
                    variant="h1"
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        alignItems: "center",
                        fontSize: "clamp(2.5rem, 8vw, 3.5rem)",
                        pb: { xs: 2, sm: 2 },
                    }}
                >
                    How It&nbsp;
                    <Typography component="span" variant="h1" sx={{ fontSize: "inherit", color: "primary.main" }}>
                        Works
                    </Typography>
                </Typography>
                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    Learn how our service works, whether you're a children's event organizer or a vendor in the event industry.
                </Typography>
            </Box>

            {/* Tab Navigation */}
            <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTab} onChange={handleChange} centered>
                    <Tab label="For Organizers" />
                    <Tab label="For Vendors" />
                </Tabs>
            </Box>

            {/* Tab Content */}
            <Box sx={{ mt: 0, width: '100%' }}>
                {selectedTab === 0 && <HowItWorksDetails intro={introOrganizers} howData={howDataOrganizers} />}
                {selectedTab === 1 && <HowItWorksDetails intro={introVendors} howData={howDataVendors} />}
            </Box>
        </Container>
    );
}
