import React, { useState } from "react";
import { Box, Button, TextField, Typography, Modal, Link } from "@mui/material";
import axios from "axios";
import SendIcon from '@mui/icons-material/Send';  // Import the Send icon
import DescriptionIcon from "@mui/icons-material/Description"; // Icon for additional details

// Modal style
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TailorSearchComponent = () => {
  const [details, setDetails] = useState("");
  const [email, setEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email || !details) {
      setError("Please fill out both fields.");
      return;
    }

    try {
      // Send the request to the backend
      await axios.post(process.env.REACT_APP_BACKEND_ENDPOINT + "/api/requests/send", {
        "isTailored": true,
        email,
        details,
      },
        { headers: { "Content-Type": "application/json" } }
      );

      // Open modal on successful request
      setIsModalOpen(true);
      setError(""); // Clear any error
    } catch (err) {
      console.error(err);
      setError("Something went wrong. Please try again.");
    }
  };

  // Handle modal close
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setDetails("");
    setEmail("");
  };

  return (

    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 3,
        px: { xs: 2, sm: 4 }, // Add padding on the sides
        mx: "auto", // Center the Box horizontally
        maxWidth: { xs: "100%", sm: 600 }, // Optional: limit the width
      }}
    >
      <Box sx={{ mb: 1, mt: 1 }}> {/* New Box for top text */}
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            color: "text.primary",
            textAlign: "justify" // Justify text
          }}
        >
          Together, we'll explore the best options for your kids' event and connect you with the ideal vendors to make it unforgettable!
        </Typography>
      </Box>

      <TextField
        label="Event Details"
        placeholder="Share your event details—date, type, location, audience, themes, and more"
        value={details}
        onChange={(e) => setDetails(e.target.value)}
        multiline
        rows={6}
        variant="outlined"
        fullWidth
        required
        InputProps={{
          startAdornment: <DescriptionIcon sx={{ mr: 1 }} />,
        }}
      />
      <TextField
        label="Your Email"
        placeholder="Enter your email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        variant="outlined"
        fullWidth
        required
      />
      <Typography variant="body2" sx={{ color: "text.secondary", mb: 1 }}>
        We'll get back to you within 1-2 days to provide and discuss our recommendations.
      </Typography>

      {error && (
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      )}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        startIcon={<SendIcon />}  // Add the Send icon to the button
        sx={{ width: "100%", maxWidth: 350 }}
      >
        Submit Request
      </Button>
      <Typography
        variant="caption"
        color="text.secondary"
        sx={{ textAlign: "center" }}
      >
        By Submitting your Request, you agree to our&nbsp;
        <Link href="/terms" color="primary">
          Terms & Conditions
        </Link>
        .
      </Typography>

      {/* Confirmation Modal */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6" component="h2">
            Thank you!
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            We have received your request. We will get in touch with you soon.
          </Typography>
          <Button onClick={handleCloseModal} sx={{ mt: 2 }} variant="contained">
            Close
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default TailorSearchComponent;
