import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import MuiChip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { styled } from "@mui/material/styles";

import FamilyRestroomRoundedIcon from "@mui/icons-material/FamilyRestroomRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import { useTheme } from '@mui/system';

const items = [
  {
    icon: <FamilyRestroomRoundedIcon />,
    title: "For Parents",
    description:
      "Be connected to everything and everyone you need to organize your kid's perfect party, from venues to entertainers.",
    imageLight:
      'url("/images/marketing/us/IMG_0067.jpg")',
    imageDark: 'url("/images/marketing/us/IMG_0067.jpg")'
  },
  {
    icon: <SchoolRoundedIcon />,
    title: "For Organisations",
    description:
      "Whether you're a school, nursery, or other organisation, we assist you in finding the best providers for your activities.",
    imageLight:
      'url("/images/marketing/us/IMG_0669.JPG")',
    imageDark:
      'url("/images/marketing/us/IMG_0669.JPG")'
  },
  {
    icon: <BusinessCenterRoundedIcon />,
    title: "For Businesses",
    description:
      "Get more exposure and get connected to opportunities for events to showcase your services.",
    imageLight:
      'url("/images/marketing/us/IMG_0998.JPG")',
    imageDark:
      'url("/images/marketing/us/IMG_0998.JPG")'
  }
];

const Chip = styled(MuiChip)(({ theme }) => ({
  variants: [
    {
      props: ({ selected }) => selected,
      style: {
        background:
          "linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))",
        color: "hsl(0, 0%, 100%)",
        borderColor: theme.palette.primary.light,
        "& .MuiChip-label": {
          color: "hsl(0, 0%, 100%)"
        },
        ...theme.applyStyles("dark", {
          borderColor: theme.palette.primary.dark
        })
      }
    }
  ]
}));

function MobileLayout({ selectedItemIndex, handleItemClick, selectedFeature }) {
  if (!items[selectedItemIndex]) {
    return null;
  }

  return (
    <Box
      sx={{
        display: { xs: "flex", sm: "none" },
        flexDirection: "column",
        gap: 2
      }}
    >
      <Box sx={{ display: "flex", gap: 2, overflow: "auto" }}>
        {items.map(({ title }, index) => (
          <Chip
            size="medium"
            key={index}
            label={title}
            onClick={() => handleItemClick(index)}
            selected={selectedItemIndex === index}
          />
        ))}
      </Box>
      <Card variant="outlined">
        <Box
          sx={theme => ({
            mb: 2,
            backgroundSize: "contain",  // Ensures the image fits within the box without repeating
            backgroundPosition: "center", // Centers the image
            backgroundRepeat: "no-repeat",  // Prevents image repetition
            minHeight: 280,
            backgroundImage: "var(--items-imageLight)",
            ...theme.applyStyles("dark", {
              backgroundImage: "var(--items-imageDark)"
            })
          })}
          style={
            items[selectedItemIndex]
              ? {
                  "--items-imageLight": items[selectedItemIndex].imageLight,
                  "--items-imageDark": items[selectedItemIndex].imageDark
                }
              : {}
          }
        />
        <Box sx={{ px: 2, pb: 2 }}>
          <Typography
            gutterBottom
            sx={{ color: "text.primary", fontWeight: "medium" }}
          >
            {selectedFeature.title}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary", mb: 1.5 }}>
            {selectedFeature.description}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}

MobileLayout.propTypes = {
  handleItemClick: PropTypes.func.isRequired,
  selectedFeature: PropTypes.shape({
    description: PropTypes.string.isRequired,
    icon: PropTypes.element,
    imageDark: PropTypes.string.isRequired,
    imageLight: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  selectedItemIndex: PropTypes.number.isRequired
};

export { MobileLayout };

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);
  const theme = useTheme();

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: "center",
          margin: "0 auto",
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            fontSize: "clamp(3rem, 10vw, 3.5rem)",
            color: "text.secondary",
            ...theme.applyStyles("dark", {
              color: "primary.dark"
            })
          }}
        >
          The Perfect Event. 
          <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: "inherit",
                color: "primary.main",
                ...theme.applyStyles("dark", {
                  color: "primary.light"
                })
              })}
            >
              Always.
            </Typography>
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "text.secondary", mb: { xs: 2, sm: 4 } }}
        >
          We're here to connect you with the perfect suppliers for unforgettable
          events, from private parties to exciting large gatherings! Plus, we
          help kids' entertainers and activity providers find fantastic new
          opportunities!
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row-reverse" },
          gap: 2
        }}
      >
        <div>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              gap: 2,
              height: "100%"
            }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Box
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={[
                  (theme) => ({
                    p: 2,
                    height: "100%",
                    width: "100%",
                    "&:hover": {
                      backgroundColor: theme.palette.action.hover
                    }
                  }),
                  selectedItemIndex === index && {
                    backgroundColor: "action.selected"
                  }
                ]}
              >
                <Box
                  sx={[
                    {
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                      gap: 1,
                      textAlign: "left",
                      textTransform: "none",
                      color: "text.secondary"
                    },
                    selectedItemIndex === index && {
                      color: "text.primary"
                    }
                  ]}
                >
                  {icon}

                  <Typography variant="h6">{title}</Typography>
                  <Typography variant="body2">{description}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <MobileLayout
            selectedItemIndex={selectedItemIndex}
            handleItemClick={handleItemClick}
            selectedFeature={selectedFeature}
          />
        </div>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            width: { xs: "100%", md: "70%" },
            height: "var(--items-image-height)"
          }}
        >
          <Card
            variant="outlined"
            sx={{
              height: "100%",
              width: "100%",
              display: { xs: "none", sm: "flex" },
              pointerEvents: "none"
            }}
          >
            <Box
              sx={(theme) => ({
                m: "auto",
                width: 420,
                height: 500,
                backgroundSize: "contain",  // Ensure the image is contained within the Box
                backgroundPosition: "center", // Center the image
                backgroundRepeat: "no-repeat",  // Prevent repetition
                backgroundImage: "var(--items-imageLight)",
                ...theme.applyStyles("dark", {
                  backgroundImage: "var(--items-imageDark)"
                })
              })}
              style={
                items[selectedItemIndex]
                  ? {
                      "--items-imageLight": items[selectedItemIndex].imageLight,
                      "--items-imageDark": items[selectedItemIndex].imageDark
                    }
                  : {}
              }
            />
          </Card>
        </Box>
      </Box>
    </Container>
  );
}
